import Typography from '@mui/material/Typography';
import QuickActions from 'App/MainApp/TreeView/components/ProjectTree/QuickActions';
import TreeMenu from 'App/MainApp/TreeView/components/ProjectTree/TreeMenu';
import { doesSupportAddToView } from 'App/MainApp/TreeView/menu/objectMenus';
import { ProjectTreeNode, ProjectTreeObjectNode, ProjectTreeSource } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import {
    FailedOrProcessingProjectObjectStatusTypes,
    OBJECT_CLASS_NAMES,
    ProcessingProjectObjectStatusTypes,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import GenericIconButton from 'Common/components/Core/GenericIconButton';
import {
    moreButtonDataCy,
    nodeLabelDataCy,
    progressIndicatorDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { ReactDNDItemTypes } from 'Common/types/ReactDND/Constants';
import React, { RefObject } from 'react';
import { useDrag } from 'react-dnd';
import { tss } from 'tss-react/mui';
import ChevronRightIcon from '../../../../../../Common/components/icons/ChevronRightIcon';
import { useProjectObject } from 'Common/customHooks/useProjectObject';
import { TreeContext } from 'App/MainApp/TreeView/components/ProjectTree/TreeContext';
import NodeIcon from 'App/MainApp/TreeView/components/ProjectTree/nodeLabel/NodeIcon';
import clsx from 'clsx';
import LinearProgress from '@mui/material/LinearProgress';
import { useAppSelector } from 'App/Redux/hooks';
import { selectEnabledXyzTraceObjectIdentifiers } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { showMainTreeRowAsDisplayed } from 'App/util/projectObjectsUtils.ts/objectUtils';
import { Option } from 'prelude-ts';
import { selectSceneObjectFocusNodeId } from 'App/Redux/features/spotLight/sceneObjectFocusSlice';
import GenericCheckbox from 'App/MainApp/Dialogs/Shared/GenericCheckbox';

const QUICK_ACTIONS_DISPLAY_VISIBLE = 'flex';
const QUICK_ACTIONS_DISPLAY_NONE = 'none';

const useStyles = tss.withNestedSelectors<'backgroundSelection' | 'quickActions'>().create(({ theme, classes }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        [`&:hover .${classes.backgroundSelection}`]: {
            background: theme.palette.grey[50],
        },

        [`&:hover .${classes.quickActions}`]: {
            display: QUICK_ACTIONS_DISPLAY_VISIBLE,
        },
    },

    selected: {
        [`.${classes.backgroundSelection}, &:hover .${classes.backgroundSelection}`]: {
            background: theme.palette.grey[100],
        },
    },

    displayed: {
        [`.${classes.backgroundSelection}`]: {
            background: theme.palette.grey[50],
        },
    },

    icon: {
        marginRight: '4px',
        display: 'flex',
        alignItems: 'center',
        zIndex: '1',

        svg: {
            fontSize: '16px',
        },
    },

    nodeLabelContainer: {
        width: '100%',
        display: 'flex',
        gap: '0',
        height: '24px',
        marginLeft: '4px',
        alignItems: 'center',
        position: 'relative',
    },

    nodeName: {
        width: '1px',
        fontSize: '13px',
        flexGrow: 1,
        flexShrink: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        zIndex: '1',
        color: theme.palette.grey[700],
    },
    quickActions: {
        display: QUICK_ACTIONS_DISPLAY_NONE,
        flexDirection: 'row',
        height: '100%',
    },
    quickActionsForceVisible: {
        display: QUICK_ACTIONS_DISPLAY_VISIBLE,
    },
    menuButton: {
        height: '100%',
        width: '27px',
        zIndex: '1',
        flexShrink: 0,
        flexGrow: 0,
    },
    backgroundSelection: {
        position: 'absolute',
        height: '100%',
        width: '200vw',
        zIndex: '0',
        left: '-100%',
    },

    progressContainer: {
        margin: '3px 15px 7px',
    },
}));

export default function NodeLabel(props: {
    node: ProjectTreeNode;
    checkBoxNode?: boolean;
    treeSource?: ProjectTreeSource;
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    const { classes } = useStyles();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [hovering, setHovering] = React.useState(false);
    const menuAnchor = React.useRef(null);

    const { toggleNodeSelect, isSelectedNode, selectedNodes, unselectAll, selectOnlyNode, selectConsecutiveNodes } =
        React.useContext(TreeContext);

    const enabledXyzTraceObjectIdentifiers = useAppSelector(selectEnabledXyzTraceObjectIdentifiers);

    const isSelected = isSelectedNode(props.node.nodeId);
    // const notInMultiSelect = !isSelected && selectedNodes.length > 1;

    const { projectObject } = useProjectObject(props.node);
    const status = projectObject?.status;

    const isInProgress = ProcessingProjectObjectStatusTypes.includes(status);
    const isDisabled = FailedOrProcessingProjectObjectStatusTypes.includes(status);

    const isLeaf = !props.node.subsections;

    const isDisplayed =
        showMainTreeRowAsDisplayed(Option.of(projectObject), props.node as ProjectTreeObjectNode) &&
        (props.node as ProjectTreeObjectNode)?.id &&
        enabledXyzTraceObjectIdentifiers.some(
            (objectIdentifier) =>
                objectIdentifier.id === (props.node as ProjectTreeObjectNode)?.id &&
                objectIdentifier.objectClassName === (props.node as ProjectTreeObjectNode)?.className
        );

    const sceneObjectFocusNodeId = useAppSelector(selectSceneObjectFocusNodeId);

    const isSpotlightOpen = props.node.nodeId === sceneObjectFocusNodeId;

    const showMenuButton =
        ((props.node.rightClickMenu ?? []).length && (hovering || isDisplayed || isSpotlightOpen)) || isSelected;

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!('id' in props.node)) {
            // not a project object
            unselectAll();
            return;
        }

        // for these objects we don't support delete
        const isSourceFile = props.node.className === OBJECT_CLASS_NAMES.SourceFile;
        const isDomainSamples = props.node.className === OBJECT_CLASS_NAMES.Domain;
        const isDomainGrid = props.node.className === OBJECT_CLASS_NAMES.Domain_GridDefinition;

        if (isLeaf && !isSourceFile && !isDomainSamples && !isDomainGrid) {
            event.preventDefault();
            event.stopPropagation();

            if (event.ctrlKey || event.metaKey) {
                toggleNodeSelect(props.node);
            } else if (event.shiftKey) {
                selectConsecutiveNodes(props.node);
            } else {
                selectOnlyNode(props.node);
            }
        }
    };

    const onMenuButtonClick = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
        if (!isSelected) {
            // not a project object
            unselectAll();
        }
        event.preventDefault();
        event.stopPropagation();
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const isDraggable = doesSupportAddToView(props.node.rightClickMenu);

    const [, drag] = useDrag(
        () => ({
            type: ReactDNDItemTypes.TREENODE,
            item: props.node,
            collect: function collect(monitor) {
                return {
                    isDragging: !!monitor.isDragging(),
                };
            },
            canDrag: function canDrag() {
                return isDraggable;
            },
        }),
        [isDraggable, props.node]
    );

    const onMouseEnterRow = React.useCallback(() => {
        setHovering(true);
    }, []);

    const onMouseLeaveRow = React.useCallback(() => {
        setHovering(false);
    }, []);

    const onCheckBoxClick = (event: React.SyntheticEvent) => {
        // to prevent the nodes to expand-collapse on checkbox click
        event.stopPropagation();
    };

    const forceQuickActionsVisible = isDisplayed || isSpotlightOpen;

    // notice all the spans: we can't use divs inside Accordion title
    return (
        <span
            className={clsx(classes.root, {
                [classes.selected]: isSelected,
                [classes.displayed]: isDisplayed && !isSelected,
            })}
            onClick={onClick}
            onContextMenu={onMenuButtonClick}
            data-cy={nodeLabelDataCy}
            ref={drag as unknown as RefObject<HTMLSpanElement>}
            onMouseEnter={onMouseEnterRow}
            onMouseLeave={onMouseLeaveRow}
        >
            <span className={classes.nodeLabelContainer} data-cy_status={status}>
                {!props.checkBoxNode && <span className={classes.backgroundSelection} />}
                <span className={classes.icon}>
                    {props.checkBoxNode && (
                        <GenericCheckbox
                            checked={props.checked}
                            onChange={props.onChange}
                            disabled={isDisabled}
                            onClick={onCheckBoxClick}
                        />
                    )}
                    <NodeIcon projectObject={projectObject} node={props.node} />
                </span>
                <Typography component="span" className={classes.nodeName} title={props.node.section}>
                    {props.node.section}
                </Typography>
                {'id' in props.node && (
                    <QuickActions
                        treeSource={props.treeSource}
                        checkBoxNode={props.checkBoxNode}
                        spotLightOpened={isSpotlightOpen}
                        displayable={isDraggable}
                        node={props.node}
                        className={clsx(classes.quickActions, {
                            [classes.quickActionsForceVisible]: forceQuickActionsVisible,
                        })}
                        displayed={isDisplayed}
                    />
                )}
                {!props.checkBoxNode && (
                    <GenericIconButton
                        ref={menuAnchor}
                        className={classes.menuButton}
                        Icon={ChevronRightIcon}
                        onClick={onMenuButtonClick}
                        size="small"
                        dataCy={moreButtonDataCy}
                        iconProps={{ sx: { fontSize: 7 } }}
                        noHover
                        hidden={!showMenuButton}
                    />
                )}
                {!props.checkBoxNode && menuOpen && (
                    <TreeMenu
                        anchorEl={menuAnchor.current}
                        open={menuOpen}
                        handleClose={closeMenu}
                        node={props.node}
                        multipleNodes={selectedNodes}
                    />
                )}
            </span>
            {!props.checkBoxNode && isInProgress && (
                <span className={classes.progressContainer} data-cy={progressIndicatorDataCy}>
                    <LinearProgress
                        variant="determinate"
                        value={Math.max(1, (projectObject?.progress_status || 0) * 100)}
                    />
                </span>
            )}
        </span>
    );
}
