import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { DataContext } from 'App/DataContext';
import PrimaryButton from '../Core/PrimaryButton';
import { hasAccessToOrg } from 'Common/api/AuthUtils';
import { getSession } from 'App/Login/Session';
import { isTimeToRefresh } from 'Common/api/AuthApi';
import { NoAccessToOrgDialogDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function checkLoggedInButNoAccessToOrg() {
    const session = getSession();
    const loggedInButNoAccessToOrg = Boolean(
        !hasAccessToOrg() && session?.oauth?.accessToken && !isTimeToRefresh(session?.oauth?.expiresAt)
    );

    return loggedInButNoAccessToOrg;
}

export default function NoAccessToOrgDialog() {
    const { loggedInButNoAccessToOrg } = React.useContext(DataContext);

    function reloadPage() {
        window.location.href = '/';
    }

    return (
        <Dialog
            open={loggedInButNoAccessToOrg}
            onClose={undefined}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title" data-cy={NoAccessToOrgDialogDataCy}>
                You don't have access to this organization
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
                    Your login session has been terminated. Please go to the sign-in page to login again...
                </DialogContentText> */}
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={reloadPage} color="warning">
                    Sign in again
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}
