import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { ProjectUpdateMessageType } from 'App/util/ProjectDataTypes/APIResponseTypes';
import { ProjectType } from '../../../util/ProjectDataTypes/ProjectMigrations';
import { AppDispatch, AppState, AppStoreStateGetter } from '../../store';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
//   }

const initialState: {
    [id: string]: ProjectType;
} = {};

export const allProjectsSlice = createSlice({
    name: 'allProjects',
    initialState,
    reducers: {
        setProjectId2Project: (state, action: PayloadAction<{ [id: string]: ProjectType }>) =>
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            action.payload,
    },
});

// Action creators are generated for each case reducer function
export const { setProjectId2Project } = allProjectsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useAppSelector((state: RootState) => state.counter.value)`
export const selectProjectId2Project = (state: AppState) => state.allProjects;

export function applyCloneProjectUpdates(res: ProjectUpdateMessageType) {
    return function applyCloneProjectUpdatesThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const oldProjectId2ProjectDict = selectProjectId2Project(getState());
        const updatedProject = res.project_update as ProjectType;
        const updatedProjectId = updatedProject.id;
        const newProjectId2ProjectDict = produce<{ [id: string]: ProjectType }>(
            oldProjectId2ProjectDict,
            (oldProjectId2ProjectDictDraft) => {
                // Websocket Update rule 1
                if (updatedProject.deleted || updatedProject.$deletedObject) {
                    delete oldProjectId2ProjectDictDraft[updatedProjectId];
                } else {
                    // Websocket Update rule 3
                    if (oldProjectId2ProjectDict && oldProjectId2ProjectDict.hasOwnProperty(updatedProjectId)) {
                        oldProjectId2ProjectDictDraft[updatedProjectId] = {
                            ...oldProjectId2ProjectDict[updatedProjectId],
                            ...updatedProject,
                        } as ProjectType;
                    } else {
                        // Websocket Update rule 2
                        oldProjectId2ProjectDictDraft[updatedProjectId] = updatedProject;
                    }
                }
            }
        );
        dispatch(setProjectId2Project(newProjectId2ProjectDict));
    };
}

export default allProjectsSlice.reducer;
