const UUID_REGEX = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;

export function getWorkspaceIdFromUrl() {
    return getDataFromUrl(/workspaces\/[a-zA-Z\d-]*/, 'workspaces/');
}

export function getOrgIdFromUrl() {
    const { pathname } = window.location;
    const path = pathname?.split?.('/');
    if (`/${path?.[1]}/` === '/org/') {
        // example: /org/<org-id>/workspaces/<workspace-id>/....
        return path?.[2];
    }
    if (UUID_REGEX.test(`${path?.[1]}`)) {
        // example: /<org-id>/workspaces/<workspace-id>/....
        return path?.[1];
    }
    return undefined;
}

function isNotSsr() {
    return typeof window !== 'undefined' && window.location.href;
}

function getDataFromUrl(regex: RegExp, stringToReplace: string) {
    if (isNotSsr()) {
        const { href } = window.location;
        const result = href.match(regex);

        if (result && result[0]) {
            const orgId = result[0].replace(stringToReplace, '');
            return orgId;
        } else {
            return null;
        }
    } else {
        return null;
    }
}
