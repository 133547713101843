import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    errorLabel: {
        background: 'white',
        boxShadow: '#c0c0c0 0px 0px 7px',
        borderRadius: '3px',
        padding: '3px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    wrapper: {
        height: '26px',
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function ErrorLabel(props: { errorText: string; show: boolean; dataCy: string; className?: string }) {
    const { classes } = useStyles();

    return (
        <div className={clsx(classes.wrapper, props.className)}>
            {props.show ? (
                <Typography
                    variant="caption"
                    color="error"
                    className={classes.errorLabel}
                    component="div"
                    data-cy={props.dataCy}
                >
                    {props.errorText}
                </Typography>
            ) : null}
        </div>
    );
}
