import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import { Workspace } from 'Common/api/WorkspaceApi';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
//   }

const initialState: {
    [id: string]: Workspace;
} = {};

export const allWorkspacesSlice = createSlice({
    name: 'allWorkspaces',
    initialState,
    reducers: {
        setWorkspaceId2Workspace: (state, action: PayloadAction<{ [id: string]: Workspace }>) =>
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            action.payload,
    },
});

// Action creators are generated for each case reducer function
export const { setWorkspaceId2Workspace } = allWorkspacesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useAppSelector((state: RootState) => state.counter.value)`
export const selectWorkspaceId2Workspace = (state: AppState) => state.allWorkspaces;

export default allWorkspacesSlice.reducer;
