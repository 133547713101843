import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { fileMenuIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function FileMenuIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={fileMenuIconDataCy}>
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.57757 15.5543H12.4237C12.7563 15.5543 12.9567 15.2568 12.9567 14.9881V7.18062H9.46293C8.77718 7.18062 8.21934 6.58791 8.21934 5.85931V2.13359L8.21615 2.12981H3.57757C3.32601 2.12981 3.04461 2.24043 3.04461 2.69609V14.9881C3.04461 15.3357 3.32601 15.5543 3.57757 15.5543ZM12.2468 6.42558L8.92997 2.89089V5.85931C8.92997 6.31497 9.21137 6.42558 9.46293 6.42558H12.2468ZM12.9567 6.13565V6.13376L13.5685 6.78536C13.5685 6.78536 13.6243 6.85104 13.6435 6.91711C13.6663 6.99677 13.6673 7.07227 13.6673 7.07227V14.9881C13.6673 15.7167 13.1095 16.3094 12.4237 16.3094H3.57757C2.89182 16.3094 2.33398 15.7167 2.33398 14.9881V2.69609C2.33398 1.96748 2.89182 1.37478 3.57757 1.37478H8.21934V1.37402L8.22041 1.37478C8.22041 1.37478 8.34299 1.37327 8.44035 1.41253C8.52669 1.44726 8.62369 1.53145 8.62369 1.53145L9.18792 2.12981H9.1865L12.9567 6.13565ZM5.33106 8.81407H8.51393C8.80848 8.81407 9.0469 8.71214 9.0469 8.3988C9.0469 8.08546 8.80848 7.98353 8.51393 7.98353H5.33106C5.03651 7.98353 4.7981 8.08546 4.7981 8.3988C4.7981 8.71214 5.03651 8.81407 5.33106 8.81407ZM5.35668 10.2373H10.6444C10.939 10.2373 11.1774 10.3392 11.1774 10.6526C11.1774 10.9659 10.939 11.0678 10.6444 11.0678H5.35668C5.06248 11.0678 4.82371 10.9659 4.82371 10.6526C4.82371 10.3392 5.06248 10.2373 5.35668 10.2373ZM10.6188 13.4424H5.33106C5.03651 13.4424 4.7981 13.3367 4.7981 13.0271C4.7981 12.7137 5.03651 12.6118 5.33106 12.6118H10.6188C10.913 12.6118 11.1518 12.7137 11.1518 13.0271C11.1518 13.3367 10.913 13.4424 10.6188 13.4424Z"
                    fill="#231F20"
                />
            </svg>
        </SvgIcon>
    );
}

export default FileMenuIcon;
