import IconButton from '@mui/material/IconButton';
import { SvgIconProps } from '@mui/material/SvgIcon';
import WidgetTooltip from 'Common/components/Core/WidgetTooltip';
import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(({ theme }) => {
    const activeStyles = {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[0],
    };

    return {
        wrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },

        button: {
            color: theme.palette.grey[600],
            height: '100%',
            width: '100%',
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
            },
            borderRadius: 0,
        },

        noHover: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },

        forceHover: {
            backgroundColor: theme.palette.action.hover,
        },

        active: {
            ...activeStyles,
            '&:hover': activeStyles,
        },
        hidden: {
            visibility: 'hidden',
        },
    };
});

type GenericIconButtonProps = React.ComponentProps<typeof IconButton> & {
    Icon: React.ComponentType;
    tooltipText?: string;
    dataCy: string;
    active?: boolean;
    noHover?: boolean;
    forceHover?: boolean;
    children?: React.ReactNode;
    iconProps?: SvgIconProps;
    buttonClassName?: string;
    largeIcon?: boolean;
    hidden?: boolean;
    styles?: CSSProperties;
    disablePortal?: boolean;
};

const GenericIconButton = React.forwardRef((props: GenericIconButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const {
        Icon,
        tooltipText,
        dataCy,
        active,
        noHover,
        forceHover,
        iconProps,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        largeIcon,
        buttonClassName,
        children,
        hidden,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        disablePortal,
        ...iconButtonProps
    } = props;
    const { classes } = useStyles();
    return (
        <WidgetTooltip
            title={tooltipText || ''}
            placement="bottom"
            className={clsx(iconButtonProps.className, classes.wrapper)}
            disablePortal={props.disablePortal}
        >
            <span>
                <IconButton
                    {...iconButtonProps}
                    className={clsx(classes.button, buttonClassName, {
                        [classes.active]: active,
                        [classes.noHover]: noHover,
                        [classes.hidden]: Boolean(hidden),
                        [classes.forceHover]: forceHover,
                    })}
                    data-cy={!hidden && dataCy}
                    ref={ref}
                >
                    {children}
                    <Icon fontSize={props.styles ? 'inherit' : 'small'} {...iconProps} />
                </IconButton>
            </span>
        </WidgetTooltip>
    );
});

export default GenericIconButton;
