import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { BoundsObject, CoordinatesObject } from 'Common/types/geometryTypes';
import { getDistances } from 'Common/utils/geometryHelpers';
import { getClosestBase10 } from 'Common/utils/utils';

export function getTextboxIncrementsForBounds(bounds: BoundsObject): CoordinatesObject {
    const distances = getDistances(bounds);
    return Object.fromEntries(
        Object.entries(distances).map(([key, value]) => [key, getClosestBase10(value / 100)])
    ) as CoordinatesObject;
}

export const expandAnisotropyNodes = (
    onExpandChange: (nodes: ObjectIDType[], expand: boolean) => void,
    domainIds: string[]
) => {
    const anisotropySectionId = SectionIds[SectionNames.Anisotropy];
    const domainsIdsToExpand = domainIds.map((domainId) => `${anisotropySectionId}_${domainId}`);

    const nodesToExpand = [anisotropySectionId, ...domainsIdsToExpand];

    onExpandChange(nodesToExpand, true);
};
