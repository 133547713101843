import { Vector3 } from '@seequent/xyz';

export function getLighterColor(rgb: Vector3): Vector3 {
    if (rgb === undefined || rgb === null || !Array.isArray(rgb) || rgb.length !== 3) return [0, 0, 0];
    const [r, g, b] = rgb;
    const lighterR = Math.min(r + 100, 255);
    const lighterG = Math.min(g + 100, 255);
    const lighterB = Math.min(b + 100, 255);
    return [lighterR, lighterG, lighterB];
}
