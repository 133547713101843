import { Point3D } from 'Common/types/geometryTypes';
import { OBJECT_CLASS_NAMES, ObjectIDType, ObjectStatusTypes } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { Dependants } from './utilClasses/Dependants';
import { formatFloatWithSignificantDigits } from 'App/util/core';

export interface ZoneResultMetadata {
    num_samples: number;
    mean: number;
    std: number;
    hist: number[];
    hist_first_two_bin_edges: [number, number];
    hist_NUM_BINS: number;
    'cluster_summaries-hvhc': {
        mean: number;
        std: number;
        num_samples: number;
    }[];
    subblock_origin: Point3D;
    subblock_block_count: Point3D;
}

export interface ZoneType extends BackendJsonifiedProjectObject {
    name: string;
    pointEstimationId: ObjectIDType;
    dataAttribute_in_drill: string;
    status: ObjectStatusTypes;
    parameters: {
        namesSuffix: string;
        power: string;
        max_samples_per_octant: string;
        max_samples_per_drill_hole: string;
        anisotropy_analysis_type: string;
        interpolation_method: string;
    };
    result_meta_data: ZoneResultMetadata;
    object_class_name: OBJECT_CLASS_NAMES.Zone;
}

export class Zone extends BaseProjectObject implements ZoneType {
    name: string;

    pointEstimationId: ObjectIDType;

    dataAttribute_in_drill: string;

    parameters: {
        namesSuffix: string;
        power: string;
        max_samples_per_octant: string;
        max_samples_per_drill_hole: string;
        anisotropy_analysis_type: string;
        interpolation_method: string;
    };

    result_meta_data: ZoneResultMetadata;

    object_class_name: OBJECT_CLASS_NAMES.Zone;

    getDependants = () => {
        const allOverlaps = this.allObjects[OBJECT_CLASS_NAMES.Overlap];

        const dependentOverlaps = Object.values(allOverlaps).filter((overlap) => {
            return overlap.zoneIds && overlap.zoneIds.some((zoneId) => zoneId === this.id);
        });

        const dependants = new Dependants();

        dependentOverlaps.forEach((overlap) => {
            dependants.addDependantAndItsDependantsRaw(overlap, this.axiosDriverFlask, this.allObjects);
        });

        return dependants;
    };

    getPointEstimation = () => this.projectObjects.getPointEstimation(this.pointEstimationId);

    getGridDefinition = () => {
        const pointEstimation = this.getPointEstimation();
        return pointEstimation.getGridDefinition();
    };

    getDomain = () => this.getPointEstimation().getDomain();

    getDomainName = () => (this.getDomain() ? this.getDomain().getName() : 'Merged domain');

    getDomainGridDefinition = () => this.getPointEstimation().getDomainGridDefinition();

    getBlockSize = () => this.getPointEstimation().getBlockSize();

    getBlockCount = () => this.result_meta_data?.subblock_block_count?.map?.((v) => Number(v)) as Point3D;

    getOrigin = () => this.result_meta_data?.subblock_origin?.map?.((v) => Number(v)) as Point3D;

    getAverageConcentrationInsideFormatted = () => {
        const concentrationString = formatFloatWithSignificantDigits(this.result_meta_data.mean);
        const errorString =
            this.result_meta_data.std && ` \u00B1 ${formatFloatWithSignificantDigits(this.result_meta_data.std)}`;

        return `${concentrationString}${errorString}`;
    };

    getAverageConcentrationOutsideFormatted = () => {
        return formatFloatWithSignificantDigits(this.result_meta_data['outside_summaries-hvhc'].mean);
    };

    isMergedDomain = () => {
        return !this.getDomain();
    };
}
