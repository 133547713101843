import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { zoneIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function ZoneIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={zoneIconDataCy}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <path
                    fill="currentColor"
                    d="M23.75,8.07l-14-8C9.53-0.06,9.25,0,9.1,0.2l-9,12c-0.1,0.13-0.13,0.31-0.07,0.47l4,11
	C4.1,23.87,4.29,24,4.5,24c0.04,0,0.08,0,0.12-0.02l16-4c0.18-0.04,0.31-0.18,0.36-0.35l3-11C24.04,8.41,23.95,8.18,23.75,8.07z"
                />
            </svg>
        </SvgIcon>
    );
}

export default ZoneIcon;
