import { BoundsObject, BoundsStringsObject } from 'Common/types/geometryTypes';
import { getDistances, stringsObjectToBoundsObject } from 'Common/utils/geometryHelpers';
import { roundDownTo2Digits, roundUpTo2Digits } from '../../../util/core';
import { TEXTBOX_ERROR_MESSAGES, getDefaultValidationResult } from '../../../util/validationUtils';
import { BoundsValidationResults } from '../DefineGrid/gridLimitsHelpers';

export function getReferenceBounds(bounds: BoundsObject): BoundsObject {
    const distances = getDistances(bounds);
    const coefficient = 2;
    const referenceBounds = {
        minX: bounds.minX - distances.x * coefficient,
        minY: bounds.minY - distances.y * coefficient,
        minZ: bounds.minZ - distances.z * coefficient,
        maxX: bounds.maxX + distances.x * coefficient,
        maxY: bounds.maxY + distances.y * coefficient,
        maxZ: bounds.maxZ + distances.z * coefficient,
    };

    return referenceBounds;
}

export function checkIsEmpty(bounds: BoundsStringsObject, validationResults: BoundsValidationResults) {
    for (const boundName in bounds) {
        if (!bounds[boundName]) {
            validationResults[boundName].isValid = false;
            validationResults[boundName].error = TEXTBOX_ERROR_MESSAGES.cannotBeEmpty;
        }
    }
}

export function checkMinSmallerThanMax(bounds: BoundsStringsObject, validationResults: BoundsValidationResults) {
    const numberBounds = stringsObjectToBoundsObject(bounds);
    // in case the field is already invalid, we're not checking it

    if (validationResults.minX.isValid && validationResults.maxX.isValid && numberBounds.minX >= numberBounds.maxX) {
        validationResults.minX.isValid = false;
        validationResults.minX.error = TEXTBOX_ERROR_MESSAGES.getShouldBeSmallerThan(
            roundDownTo2Digits(Number(bounds.maxX))
        );
    }

    if (validationResults.minY.isValid && validationResults.maxY.isValid && numberBounds.minY >= numberBounds.maxY) {
        validationResults.minY.isValid = false;
        validationResults.minY.error = TEXTBOX_ERROR_MESSAGES.getShouldBeSmallerThan(
            roundDownTo2Digits(Number(bounds.maxY))
        );
    }

    if (validationResults.minZ.isValid && validationResults.maxZ.isValid && numberBounds.minZ >= numberBounds.maxZ) {
        validationResults.minZ.isValid = false;
        validationResults.minZ.error = TEXTBOX_ERROR_MESSAGES.getShouldBeSmallerThan(
            roundDownTo2Digits(Number(bounds.maxZ))
        );
    }
}

export function checkMinGreaterOrEqualToDefault(
    bounds: BoundsStringsObject,
    referenceBounds: BoundsObject,
    validationResults: BoundsValidationResults
) {
    const numberBounds = stringsObjectToBoundsObject(bounds);

    ['minX', 'minY', 'minZ'].forEach((key) => {
        // in case the field is already invalid, we're not checking it
        if (validationResults[key].isValid && numberBounds[key] < referenceBounds[key]) {
            validationResults[key].isValid = false;
            validationResults[key].error = TEXTBOX_ERROR_MESSAGES.getShouldBeGreaterThanOrEqual(
                roundUpTo2Digits(referenceBounds[key])
            );
        }
    });
}

export function checkMaxSmallerOrEqualToDefault(
    bounds: BoundsStringsObject,
    referenceBounds: BoundsObject,
    validationResults: BoundsValidationResults
) {
    const numberBounds = stringsObjectToBoundsObject(bounds);

    // in case the field is already invalid, we're not checking it

    ['maxX', 'maxY', 'maxZ'].forEach((key) => {
        if (validationResults[key].isValid && numberBounds[key] > referenceBounds[key]) {
            validationResults[key].isValid = false;
            validationResults[key].error = TEXTBOX_ERROR_MESSAGES.getShouldBeSmallerThanOrEqual(
                roundDownTo2Digits(referenceBounds[key])
            );
        }
    });
}

export function getInitialValidationResults(): BoundsValidationResults {
    const validationResults = {
        minX: {},
        minY: {},
        minZ: {},
        maxX: {},
        maxY: {},
        maxZ: {},
    } as BoundsValidationResults;
    Object.keys(validationResults).forEach((key) => (validationResults[key] = getDefaultValidationResult()));
    return validationResults;
}

export function isAllBoundsValid(validationResults: BoundsValidationResults) {
    return !Object.keys(validationResults).some((key) => !validationResults[key].isValid);
}

export function isValidBounds(bounds: BoundsStringsObject, defaults: BoundsObject): BoundsValidationResults {
    const validationResults = getInitialValidationResults();

    const referenceBounds = getReferenceBounds(defaults);

    checkIsEmpty(bounds, validationResults);

    checkMinSmallerThanMax(bounds, validationResults);

    checkMinGreaterOrEqualToDefault(bounds, referenceBounds, validationResults);

    checkMaxSmallerOrEqualToDefault(bounds, referenceBounds, validationResults);

    return validationResults;
}
