import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { CloseIcon } from '@local/web-design-system/dist/icons';
import { useStyles } from './PanelHeader.styles';
import { PanelHeaderProps } from './PanelHeader.types';
import Tooltip from '@mui/material/Tooltip';

export default function PanelHeader({ title, adornment, onClose, icon }: PanelHeaderProps) {
    const { classes } = useStyles();

    const titleComponent = (
        <Tooltip title={title} placement="top">
            <Typography className={classes.filterHeader}>{title}</Typography>
        </Tooltip>
    );

    const titlePanel: JSX.Element = icon ? (
        <Grid container alignItems="center">
            {icon}
            {titleComponent}
        </Grid>
    ) : (
        titleComponent
    );

    return (
        <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap" className={classes.root}>
            <Grid
                item
                sx={{
                    overflow: 'hidden',
                }}
            >
                {titlePanel}
            </Grid>
            <Grid
                container
                item
                justifyContent="flex-end"
                alignItems="center"
                wrap="nowrap"
                className={classes.adornmentCloseButtonWrapper}
            >
                {adornment}
                <IconButton className={classes.closeButton} onClick={onClose} size="large">
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </Grid>
        </Grid>
    );
}
