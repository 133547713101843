import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type ResetButtonProps = {
    onClick: () => void;
    tooltipText: string;
    disabled: boolean;
    dataCy?: string;
};

function ResetButton({ onClick, tooltipText, disabled, dataCy }: ResetButtonProps) {
    return (
        <Tooltip disableInteractive title={tooltipText}>
            <span>
                <IconButton onClick={onClick} disabled={disabled} size="small" data-cy={dataCy || 'reset-button'}>
                    <RefreshIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
}

export default ResetButton;
