import Grid from '@mui/material/Grid';
import React from 'react';
import { telemetryLogs } from 'App/util/Logging/TelemetryLogs';

interface PropsType {
    children: React.ReactNode;
}

interface StateType {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        try {
            telemetryLogs.error(error, errorInfo);
        } catch (error: any) {
            console.error('there was a frontend error', error);
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Grid
                    container
                    style={{
                        padding: '30px',
                        maxWidth: '100%',
                        rowGap: '20px',
                        flexDirection: 'column',
                    }}
                >
                    <h1>Something went wrong.</h1>
                </Grid>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
