import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'App/Redux/store';
import { XyzTraceId } from 'Common/Xyz/XyzTraces/XyzTrace';
import { XyzTraces, selectEnabledXyzTraces } from './xyzTracesSlice';

type PlotLegendsSlice = {
    plotLegendId2enabled: {
        [id: XyzTraceId]: boolean;
    };
};

const initialState: PlotLegendsSlice = {
    plotLegendId2enabled: {},
};

export const plotLegendsSlice = createSlice({
    name: 'plotLegendsSlice',
    initialState,
    reducers: {
        disableAllPlotLegends: (state) => {
            Object.keys(state.plotLegendId2enabled).forEach((xyzTraceId) => {
                state.plotLegendId2enabled[xyzTraceId] = false;
            });
        },
        disablePlotLegend: (state, action: PayloadAction<XyzTraceId>) => {
            const xyzTraceId = action.payload;
            if (state.plotLegendId2enabled[xyzTraceId]) {
                state.plotLegendId2enabled[xyzTraceId] = false;
            }
        },
        enablePlotLegend: (state, action: PayloadAction<XyzTraceId>) => {
            const xyzTraceId = action.payload;
            state.plotLegendId2enabled[xyzTraceId] = true;
        },
    },
});

export default plotLegendsSlice.reducer;

export const { disableAllPlotLegends, disablePlotLegend, enablePlotLegend } = plotLegendsSlice.actions;

export const selectPlotLegendsSlice = (state: AppState) => state.plotLegendsSlice;
export const selectplotLegendId2enabled = (state: AppState) => state.plotLegendsSlice.plotLegendId2enabled;
export const selectEnabledPlotLegendIds = createSelector(
    [selectplotLegendId2enabled, selectEnabledXyzTraces],
    (plotLegendId2enabled: PlotLegendsSlice['plotLegendId2enabled'], enabledXyzTraces: XyzTraces) =>
        Object.keys(plotLegendId2enabled).filter(
            (xyzTraceId) => plotLegendId2enabled[xyzTraceId] && enabledXyzTraces[xyzTraceId]
        )
);

export const makeSelectEnabledPlotLegend = () =>
    createSelector(
        selectplotLegendId2enabled,
        (state: AppState, xyzTraceId: XyzTraceId) => xyzTraceId,
        (plotLegendId2enabled: PlotLegendsSlice['plotLegendId2enabled'], xyzTraceId: XyzTraceId) =>
            plotLegendId2enabled[xyzTraceId]
    );
