import { getDefaultValidationResult } from 'App/util/validationUtils';
import { hasEditorPermissions } from 'App/util/permissionUtils';
import { AxiosDriverFlaskInstance } from '../../util/axiosErrorHandlers';
import { Workspace } from 'Common/api/WorkspaceApi';

export function sendSoftDeleteRequest(url: string, axiosDriverFlask: AxiosDriverFlaskInstance) {
    const formData = new FormData();
    formData.append('hidden', 'true');
    return axiosDriverFlask.patch(url, formData, {
        responseType: 'json',
    });
}

export function isDeleteDisabled(currentWorkspace: Workspace) {
    const validationResult = getDefaultValidationResult();

    if (!hasEditorPermissions(currentWorkspace)) {
        validationResult.isValid = false;
        validationResult.error = 'Has no permissions to delete';
    }

    return validationResult;
}
