import { BoundsObject } from 'Common/types/geometryTypes';
import { getTextboxIncrementsForBounds } from './dialogUtils';

// hook for easy calculation of bounds textboxes increments based on drilling (or any other) bounds
export function useCalculateBoundsIncrements(originalBounds: BoundsObject) {
    const increments = getTextboxIncrementsForBounds(originalBounds);
    const minIncrements = {
        minX: increments.x,
        minY: increments.y,
        minZ: increments.z,
    };
    const maxIncrements = {
        maxX: increments.x,
        maxY: increments.y,
        maxZ: increments.z,
    };

    return { minIncrements, maxIncrements };
}
