import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import {
    applyWebsocketModificationsToCurrentProject,
    selectActiveGrids,
    selectActiveSourceFiles,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import React from 'react';
import { tss } from 'tss-react/mui';
import { DataContext } from 'App/DataContext';
import { selectProjectTreeUnderPath } from 'App/Redux/features/globalContext/projectTreeSlice';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import { ProjectTreeObjectNode, ProjectTreeSource, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { anisotropySectionSelector } from 'App/Redux/utils';
import { ValidationResult, getDefaultValidationResult } from 'App/util/validationUtils';
import {
    anisotropiesTreeDataCy,
    gridsTreeDataCy,
    nameSuffixTextboxDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import { produce } from 'immer';
import {
    isMaxSamplesPerDrillholeValid,
    isMaxSamplesPerOctantValid,
    isMaxSamplesValid,
    isMinSamplesValid,
    isPowerParameterValid,
} from './PointEstimationsValidationService';
import LabeledTextField from '../Shared/LabeledTextField';
import {
    PointEstimationMethod,
    PointEstimationMethods,
    expandPointEstimationNodes,
    getParamsForPointEstimation,
    runPointEstimations,
} from './pointEstimationsService';
import { useSelectableTree } from 'App/MainApp/TreeView/components/checkboxTree/useSelectableTree';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { OBJECT_CLASS_NAMES } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { useSessionContext } from 'App/context/SessionContext';
import ProjectTree from 'App/MainApp/TreeView/components/ProjectTree/ProjectTree';
import { getFirstLevelNodeIds } from 'App/MainApp/TreeView/treeUtils';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create({
    treeTopParent: {
        height: 240,
        flexGrow: 1,
        overflowY: 'auto',
        marginBottom: 0,
    },
    fieldsGrid: {
        marginTop: '1%',
        width: '700px',
    },
    formField: {
        display: 'grid',
        gridTemplateColumns: '33% 36%',
    },
    nestedField: {
        display: 'grid',
        gridTemplateColumns: '30% 70%',
    },
});

const DEFAULTS = {
    namesSuffix: '',
    powerParameter: '2',
    sampleLimitsMin: '4',
    sampleLimitsMax: '30',
    maxSamplesPerDrillHole: '',
    maxSamplesPerOctant: '',
};

type PointEstimationDialogProps = {
    namesSuffixPrefix: string;
    handleClose: () => void;
    pointEstimationMethod: PointEstimationMethod;
    includePowerParameter?: boolean;
};

function PointEstimationDialog(props: PointEstimationDialogProps) {
    const xyz = useXyz();
    const { classes } = useStyles();
    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const dispatch = useAppDispatch();

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [namesSuffix, setNamesSuffix] = React.useState(DEFAULTS.namesSuffix);

    const { powerParameter, sampleLimitsMin, sampleLimitsMax, maxSamplesPerDrillHole, maxSamplesPerOctant } = DEFAULTS;

    const isMaxSamplesPerDrillholeActive = false;
    const isMaxSamplesPerOctantActive = false;

    const [powerParameterValidationResult, setPowerParameterValidationResult] =
        React.useState(getDefaultValidationResult());
    const [samplesMinValidationResult, setSamplesMinValidationResult] = React.useState(getDefaultValidationResult());
    const [samplesMaxValidationResult, setSamplesMaxValidationResult] = React.useState(getDefaultValidationResult());
    const [maxSamplesPerDrillholeValidationResult, setMaxSamplesPerDrillholeValidationResult] =
        React.useState(getDefaultValidationResult());
    const [maxSamplesPerOctantValidationResult, setMaxSamplesPerOctantValidationResult] =
        React.useState(getDefaultValidationResult());

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const drillingObject = Object.values(useAppSelector(selectActiveSourceFiles))[0];

    const currentProjectId = useAppSelector(selectCurrentProjectId);

    const treeSource =
        props.pointEstimationMethod === PointEstimationMethods.OrdinaryKriging
            ? ProjectTreeSource.POINT_ESTIMATION_OK
            : ProjectTreeSource.POINT_ESTIMATION_ID;

    const { onExpandChange } = useExpandedNodes();

    const anisotropySectionTemp = useAppSelector(
        selectProjectTreeUnderPath(anisotropySectionSelector)
    ) as ProjectTreeObjectNode;

    const anisotropySection = React.useMemo(
        () =>
            produce(anisotropySectionTemp, (oldAnisotropySection) => {
                Object.keys(oldAnisotropySection.subsections).forEach((domainId) => {
                    const domainSubtree = oldAnisotropySection.subsections[domainId];
                    if (domainSubtree.subsections) {
                        domainSubtree.subsections = Object.fromEntries(
                            Object.entries(domainSubtree.subsections).map(([id, anisotropyNode]) => {
                                return [id, { ...anisotropyNode, canDisplaySpotlight: false }];
                            })
                        );
                    }
                });
            }),
        [anisotropySectionTemp]
    );

    const anisotropySubsections = React.useMemo(() => ({ Anisotropy: anisotropySection }), [anisotropySection]);

    const grids = useAppSelector(selectActiveGrids);

    const gridsSubsections = React.useMemo(() => {
        const gridsSubsections = {};

        Object.values(grids ?? {}).forEach((grid) => {
            gridsSubsections[grid.id] = {
                section: grid.name,
                dataCy: grid.name,
                rightClickMenu: [],
                id: grid.id,
                className: grid.object_class_name,
                nodeId: grid.id,
            };
        });

        const gridsSection: Subsections = {
            Grids: {
                section: SectionNames.Grids,
                dataCy: SectionNames.Grids,
                nodeId: SectionIds[SectionNames.Grids],
                subsections: gridsSubsections,
                path: [],
            },
        };

        return gridsSection;
    }, [grids]);

    // required for node expansion after job submit
    const requiredObjects = [OBJECT_CLASS_NAMES.Domain_GridDefinition];

    const {
        nodeSelectionChecker: anisotropyNodesSelectionChecker,
        selectedLeafNodes: selectedAnisotropies,
        requiredNodeObjects,
        onChange: onAnisotropiesCheckboxChange,
    } = useSelectableTree(anisotropySubsections, requiredObjects, SectionNames.Anisotropy);

    const {
        nodeSelectionChecker: gridNodesSelectionChecker,
        selectedLeafNodes: selectedGrids,
        onChange: onGridsCheckboxChange,
    } = useSelectableTree(gridsSubsections, null, null);

    const numOfSelectedAnisotropies = selectedAnisotropies.length;

    const NumOfSelectedGrids = selectedGrids.length;

    const onNamesSuffixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNamesSuffix(event.target.value);
    };

    const runPowerParameterValidation = (): ValidationResult => {
        const validationResult = isPowerParameterValid(powerParameter);
        setPowerParameterValidationResult(validationResult);
        return validationResult;
    };

    const runSamplesMinValidation = (): ValidationResult => {
        const validationResult = isMinSamplesValid(sampleLimitsMin, sampleLimitsMax);
        setSamplesMinValidationResult(validationResult);
        return validationResult;
    };

    const runSamplesMaxValidation = (): ValidationResult => {
        const validationResult = isMaxSamplesValid(sampleLimitsMax, sampleLimitsMin);
        setSamplesMaxValidationResult(validationResult);
        return validationResult;
    };

    const runMaxSamplesPerDrillholeValidation = (): ValidationResult => {
        const validationResult = isMaxSamplesPerDrillholeValid(maxSamplesPerDrillHole, isMaxSamplesPerDrillholeActive);
        setMaxSamplesPerDrillholeValidationResult(validationResult);
        return validationResult;
    };

    const runMaxSamplesPerOctantValidation = (): ValidationResult => {
        const validationResult = isMaxSamplesPerOctantValid(maxSamplesPerOctant, isMaxSamplesPerOctantActive);
        setMaxSamplesPerOctantValidationResult(validationResult);
        return validationResult;
    };

    const isAllValid =
        samplesMaxValidationResult.isValid &&
        samplesMinValidationResult.isValid &&
        maxSamplesPerDrillholeValidationResult.isValid &&
        maxSamplesPerOctantValidationResult.isValid &&
        powerParameterValidationResult.isValid;

    const disableSubmit = !isAllValid || isSubmitting || numOfSelectedAnisotropies === 0 || NumOfSelectedGrids === 0;

    const checkAllValid = (): boolean => {
        const isSamplesMinValid = runSamplesMinValidation().isValid;
        const isSamplesMaxValid = runSamplesMaxValidation().isValid;
        const isMaxSamplesPerDrillholeValid = runMaxSamplesPerDrillholeValidation().isValid;
        const isMaxSamplesPerOctantValid = runMaxSamplesPerOctantValidation().isValid;
        const isPowerParameterValid = runPowerParameterValidation().isValid;

        return (
            isSamplesMinValid &&
            isSamplesMaxValid &&
            isMaxSamplesPerDrillholeValid &&
            isMaxSamplesPerOctantValid &&
            isPowerParameterValid
        );
    };

    const onSubmit = () => {
        const areAllInputsValid = checkAllValid();

        if (!areAllInputsValid) {
            return;
        }

        const gridIds = selectedGrids.map((gridNode: ProjectTreeObjectNode) => gridNode.id);

        const { anisotropyGlobalIds, anisotropyGridIds, anisotropyEstimationIds, domainGridDefinitionIds } =
            getParamsForPointEstimation(selectedAnisotropies, gridIds, requiredNodeObjects);

        const drillSourceId = drillingObject.id;

        let powerParameterForBackend: string;

        if (props.includePowerParameter) {
            powerParameterForBackend = powerParameter;
        }

        void runPointEstimations(
            axiosDriverFlask,
            currentProjectId,
            namesSuffix,
            props.namesSuffixPrefix,
            sampleLimitsMin,
            sampleLimitsMax,
            maxSamplesPerDrillHole,
            maxSamplesPerOctant,
            props.pointEstimationMethod,
            anisotropyGlobalIds,
            anisotropyGridIds,
            anisotropyEstimationIds,
            gridIds,
            drillSourceId,
            currentOrgUuid,
            currentWorkspaceId,
            powerParameterForBackend
        )
            .then((projectUpdateJson) => {
                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'submit inverse distance',
                        tokenProvider
                    )
                );
                expandPointEstimationNodes(onExpandChange, gridIds, domainGridDefinitionIds);
                props.handleClose();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
        setIsSubmitting(true);
    };

    React.useEffect(() => {
        const anisotropyTreeNodeIds = getFirstLevelNodeIds(anisotropySubsections);
        const gridsTreeNodeIds = getFirstLevelNodeIds(gridsSubsections);
        const nodeIds = [...anisotropyTreeNodeIds, ...gridsTreeNodeIds];
        onExpandChange(nodeIds, true, treeSource, true);
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs={6} style={{ borderRight: '1px solid #D3D3D3', paddingRight: '1%' }}>
                    <ProjectTree
                        checkboxTree
                        className={classes.treeTopParent}
                        dataCy={anisotropiesTreeDataCy}
                        tree={anisotropySubsections}
                        onChange={onAnisotropiesCheckboxChange}
                        nodeSelectionChecker={anisotropyNodesSelectionChecker}
                        treeSource={treeSource}
                    />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: '5%' }}>
                    <ProjectTree
                        checkboxTree
                        className={classes.treeTopParent}
                        dataCy={gridsTreeDataCy}
                        tree={gridsSubsections}
                        onChange={onGridsCheckboxChange}
                        nodeSelectionChecker={gridNodesSelectionChecker}
                        treeSource={treeSource}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.fieldsGrid}>
                <LabeledTextField
                    className={classes.formField}
                    title="Name suffix:"
                    value={namesSuffix}
                    prefix={props.namesSuffixPrefix}
                    onChange={onNamesSuffixChange}
                    dataCy={nameSuffixTextboxDataCy}
                />
            </Grid>

            <GenericDialogActions
                onCancel={props.handleClose}
                onSubmit={onSubmit}
                disabled={disableSubmit}
                showSpinner={isSubmitting}
                tooltipText={
                    isSubmitting
                        ? 'Submitting...'
                        : numOfSelectedAnisotropies === 0
                          ? 'You must select at least one anisotopy.'
                          : NumOfSelectedGrids === 0
                            ? 'You must select at least one grid.'
                            : ''
                }
            />
        </>
    );
}

export default PointEstimationDialog;
