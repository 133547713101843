import { Action, ConfigureStoreOptions, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setAutoFreeze } from 'immer';
import currentProjectReducer from './features/globalContext/currentProjectSlice';
import projectTreeReducer from './features/globalContext/projectTreeSlice';
import sceneObjectFocusReducer from './features/spotLight/sceneObjectFocusSlice';
import rightClickActiveReducer from './features/rightClickMenu/rightClickActiveSlice';
import publishToEvoSpinnerActiveReducer from './features/rightClickMenu/publishToEvoSpinnerActiveSlice';
import openWorkspaceReducer from './features/plot/openWorkspaceSlice';
import plotOverlaysReducer from './features/plot/plotOverlaysSlice';
import openSpotlightReducer from './features/spotLight/openSpotlightSlice';
import xyzTracesReducer from './features/Xyz/xyzTracesSlice';
import xyzPlotOverlaysReducer from './features/Xyz/xyzPlotOverlaysSlice';
import plotLegendsReducer from './features/Xyz/plotLegendsSlice';
import showOverlapsWindowReducer from './features/overlaps/showOverlapsWindowSlice';
import focusedWindowReducer from './features/DraggableWindows/focusedWindowSlice';
import dialogsReducer from './features/DraggableWindows/dialogsSlice';
import projectSocketConnectionStateReducer from './features/SocketIO/ProjectSocketConnectionState';
import groupSocketConnectionStateReducer from './features/SocketIO/GroupSocketConnectionState';
import websocketUpdatesReducer from './features/SocketIO/websocketUpdatesSlice';
import authReducer from './features/auth/authSlice';
import allProjectsReducer from './features/globalContext/allProjectsSlice';
import allWorkspacesReducer from './features/globalContext/allWorkspacesSlice';
import projectTreeExpandedNodesReducer from './features/globalContext/projectTreeExpandedNodesSlice';
import projectLoadProgressReducer from './features/globalContext/projectLoadProgressSlice';
import projectObjectReducer from './features/globalContext/projectObjectSlice';

setAutoFreeze(false); // TODO: This is done only because of the mutations I make to the mainPlotData. I need to remove this line later.

// Create the root reducer independently to obtain the RootState type
const rootReducer = combineReducers({
    currentProject: currentProjectReducer,
    projectTree: projectTreeReducer,
    sceneObjectFocus: sceneObjectFocusReducer,
    rightClickActive: rightClickActiveReducer,
    publishToEvoSpinnerActive: publishToEvoSpinnerActiveReducer,
    openWorkspace: openWorkspaceReducer,
    plotOverlays: plotOverlaysReducer,
    openSpotlight: openSpotlightReducer,
    xyzTraces: xyzTracesReducer,
    xyzPlotOverlays: xyzPlotOverlaysReducer,
    plotLegendsSlice: plotLegendsReducer,
    showOverlapsWindow: showOverlapsWindowReducer,
    focusedWindow: focusedWindowReducer,
    dialogs: dialogsReducer,
    projectSocketConnectionState: projectSocketConnectionStateReducer,
    groupSocketConnectionState: groupSocketConnectionStateReducer,
    websocketUpdates: websocketUpdatesReducer,
    auth: authReducer,
    allProjects: allProjectsReducer,
    allWorkspaces: allWorkspacesReducer,
    projectTreeExpandedNodes: projectTreeExpandedNodesReducer,
    projectLoadProgress: projectLoadProgressReducer,
    projectObject: projectObjectReducer,
});

function setupReduxStore(options?: { preloadedState?: ConfigureStoreOptions['preloadedState'] }) {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
            }), // TODO: This is done only because of the mutations I make to the mainPlotData. I need to remove this line later.
        preloadedState: options?.preloadedState,
    });
}

// export type AppState = ReturnType<typeof temp_store.getState>
export type AppState = ReturnType<typeof rootReducer>;

// export type AppDispatch = typeof temp_store.dispatch
export type AppDispatch = AppStore['dispatch'];

export type AppStore = ReturnType<typeof setupReduxStore>;
export type AppStoreStateGetter = AppStore['getState'];

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;

export default setupReduxStore;
