import Typography from '@mui/material/Typography';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import React, { MouseEventHandler } from 'react';
import { tss } from 'tss-react';

const useStyles = tss.create(() => ({
    text: {
        textAlign: 'center',
        padding: 10,
    },
}));

interface UpdateDrillingPromptProps {
    title: string;
    open?: boolean;
    handleClose: () => void;
    onCancel: () => void;
    onConfirm: MouseEventHandler<HTMLElement>;
    submitText: string;
    disabled?: boolean;
    dataCy: string;
}

export default function UpdateDrillingPrompt({ handleClose, onCancel, onConfirm, dataCy }: UpdateDrillingPromptProps) {
    const { classes } = useStyles();

    return (
        <GenericDialogShell
            title="Data Update"
            maxWidth="xs"
            handleClose={handleClose}
            onCancel={onCancel}
            submitText="Yes"
            centerBottons
            onSubmit={onConfirm}
            dataCy={dataCy}
        >
            <Typography className={classes.text}>
                Updating this data file will cause all data objects to rerun. You will not be able to interact with your
                data during this time.
            </Typography>
            <Typography className={classes.text}>Do you want to continue?</Typography>
        </GenericDialogShell>
    );
}
