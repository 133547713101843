import {
    getDefaultValidationResult,
    validateStringLength,
    validateFileSystemRelatedString,
} from 'App/util/validationUtils';

const maxSuffixLength = 15;

export function validateNameSuffix(nameSuffix: string) {
    const validationResult = getDefaultValidationResult();
    validateStringLength(nameSuffix, maxSuffixLength, validationResult);
    validateFileSystemRelatedString(nameSuffix, validationResult);

    return validationResult;
}
