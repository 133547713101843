import { getCurrentUserInfo } from 'App/Login/Session';
import { telemetryLogs } from 'App/util/Logging/TelemetryLogs';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { LDClient, LDContext } from 'launchdarkly-react-client-sdk';

type LDUserContext = {
    kind: 'user';
    key: string;
    email: string;
    org: string;
};

export const anonymousLDContext = { kind: 'user', anonymous: true, key: 'anonymous', org: null };

export function setLaunchDarklyContext(ldClient: LDClient, currentOrgUuid: ObjectIDType) {
    const currentUser = getCurrentUserInfo();
    const oldLDUser = ldClient?.getContext() as LDUserContext;

    if (oldLDUser?.key !== currentUser?.uuid || oldLDUser?.org !== currentOrgUuid) {
        const isAnonymous = !currentUser;

        const ldUser: LDContext = {
            kind: 'user',
            key: currentUser?.uuid,
            email: currentUser?.email,
            org: currentOrgUuid,
        };

        const context = isAnonymous ? anonymousLDContext : ldUser;

        return ldClient?.identify(context, undefined, (error) => {
            if (error) {
                telemetryLogs.error(error, `Error identifying/setting LD user: ${ldUser.key} ${error.message}`);
            }
        });
    }
}
