import { Point3D } from 'Common/types/geometryTypes';
import { ObjectIDType, ObjectStatusTypes, OBJECT_CLASS_NAMES } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { Dependants } from './utilClasses/Dependants';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';

export type GridParametersType = {
    blocksize: [string, string, string];
    maxXYZ: Point3D;
    minXYZ: Point3D;
    name: string;
};

export interface GridDefinitionType extends BackendJsonifiedProjectObject {
    name: string;
    id: ObjectIDType;
    status: ObjectStatusTypes;
    parameters: GridParametersType;
    object_class_name: OBJECT_CLASS_NAMES.GridDefinition;
    error_message_for_users: string;
    hidden: boolean;
    result_meta_data: object;
}

export class Grid extends BaseProjectObject implements GridDefinitionType {
    parameters: GridParametersType;

    object_class_name: OBJECT_CLASS_NAMES.GridDefinition;

    error_message_for_users: string;

    hidden: boolean;

    result_meta_data: object;

    getDependants = () => {
        const pointEstimations = this.allObjects[OBJECT_CLASS_NAMES.PointEstimation];

        const dependants = new Dependants();

        Object.values(pointEstimations).forEach((pointEstimation) => {
            const pointEstimationInstance = wrapObjectInClass(pointEstimation, null, this.allObjects);
            const grid = pointEstimationInstance.getGridDefinition();
            if (grid.id === this.id) {
                dependants.addDependantAndItsDependantsRaw(pointEstimation, this.axiosDriverFlask, this.allObjects);
            }
        });

        return dependants;
    };

    getBlockSize = () => this.parameters.blocksize.map((v) => Number(v)) as Point3D;

    getBlockCount = () =>
        this.parameters.maxXYZ.map((maxV, i) =>
            Math.trunc((Number(maxV) - Number(this.parameters.minXYZ[i])) / Number(this.parameters.blocksize[i]) + 0.1)
        ) as Point3D;

    getOrigin = () => this.parameters.minXYZ.map((v) => Number(v)) as Point3D;
}
