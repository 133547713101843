import { OverlapPlotData } from 'App/MainApp/Plot/plotDataTypes';
import {
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
    ObjectStatusTypes,
    ProcessingProjectObjectStatusTypes,
    ProjectObject,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { getHubUrl } from 'Common/api/AuthUtils';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { ValidationResult, getDefaultValidationResult } from 'App/util/validationUtils';
import { urlWithParams } from 'Common/UrlUtils';

function getUnsavedOverlapDownloadUrl(overlapData: OverlapPlotData, axiosDriverFlask: AxiosDriverFlaskInstance) {
    return axiosDriverFlask.getUri({
        url: `${overlapData._activeOverlapProfile_url}/overlapsPlotData`,
        params: {
            download: 'True',
            overlaps: [JSON.stringify(overlapData._overlap_objs)],
            gridDefinitionId: overlapData.gridId,
        },
    });
}

function getDownloadUrl(
    projectObject: ProjectObject,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    ott: string,
    currentOrgUuid: string,
    options?: { overlapData: OverlapPlotData }
) {
    if (!projectObject) {
        if (options.overlapData) {
            return getUnsavedOverlapDownloadUrl(options.overlapData, axiosDriverFlask);
        } else {
            throw new Error('Cannot download project object that is null');
        }
    }

    let url = `${getHubUrl()}${projectObject.url}`;
    switch (projectObject.object_class_name) {
        case OBJECT_CLASS_NAMES.AnisotropyEstimation:
        case OBJECT_CLASS_NAMES.AnisotropyGrid:
        case OBJECT_CLASS_NAMES.AnisotropyGlobal:
            url += '?download_csv=True';
            break;
        case OBJECT_CLASS_NAMES.PointEstimation:
            url += '?download_csv_txt=True';
            break;
        case OBJECT_CLASS_NAMES.Zone:
            url += '?download_csv_txt_and_dxf=True';
            break;
        case OBJECT_CLASS_NAMES.ZoneFromAnisotropy:
            url += '?download_obj=True';
            break;
        default:
            url += '?download=True';
    }

    return urlWithParams(url, { ott });
}

interface OttResponse {
    ott: string;
}

export async function getNewOtt(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentProjectUrl: string
): Promise<string> {
    const ottResponse = await axiosDriverFlask.post<OttResponse>(`${currentProjectUrl}/ott`);
    return ottResponse.data.ott;
}

export async function downloadObject(
    projectObject: ProjectObject,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentProjectUrl: string,
    currentOrgUuid: string,
    options?: { overlapData: OverlapPlotData }
) {
    const ott = await getNewOtt(axiosDriverFlask, currentProjectUrl);
    const url = getDownloadUrl(projectObject, axiosDriverFlask, ott, currentOrgUuid, options);
    window.location.href = url;
}

export function isPublishDisabled(
    projectObject: ProjectObject,
    allProjectObjects: ObjectClassName2Id2Obj,
    publishStr: string
): ValidationResult {
    const validationResult = getDefaultValidationResult();

    if (projectObject.status !== ObjectStatusTypes.SUCCESS) {
        validationResult.isValid = false;
        validationResult.error = `Only can ${publishStr} objects with status success`;
    } else if (projectObject.object_class_name === OBJECT_CLASS_NAMES.SourceFile) {
        const domainsMap = allProjectObjects[OBJECT_CLASS_NAMES.Domain];
        const isAnyDomainProcessing = Object.values(domainsMap).some((domain) =>
            ProcessingProjectObjectStatusTypes.includes(domain.status)
        );
        if (isAnyDomainProcessing) {
            validationResult.isValid = false;
            validationResult.error = `Cannot ${publishStr} drilling while domains are being processed`;
        }
    }

    return validationResult;
}
