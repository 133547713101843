import Logger, { ILogLevel } from 'js-logger';
import { datadogLogs } from '@datadog/browser-logs';
import { LogLevels, Severity, printWithLogLevel } from './logging_utils';
import { isIntegrationEnv, isProductionEnv, isStagingEnv, isTestingEnv } from '../../../Common/utils/utils';
/* eslint-disable-next-line */
import packageJson from '../../../package.json';

export const telemetryLogs = Logger.get('telemetryLogs');

if (isTestingEnv()) {
    // TODO: remove once logs working properly
    global.datadogLogs = datadogLogs;
}

function isLogToDataDogEnbled() {
    return isProductionEnv() || isTestingEnv() || isStagingEnv() || isIntegrationEnv();
}

function setInitialTelemetryLogsLogLevel() {
    if (process.env.NEXT_PUBLIC_INFORMATIONAL_LOGS_LEVEL) {
        const LogLevel = LogLevels?.[process.env.NEXT_PUBLIC_INFORMATIONAL_LOGS_LEVEL];
        if (LogLevel) {
            telemetryLogs.setLevel(LogLevel);
            return;
        }
    }
    telemetryLogs.setLevel(Logger.WARN);
}
setInitialTelemetryLogsLogLevel();

const allTelemetryLogs: (string | ILogLevel | string[])[][] = [];

export function handleTelemetryLogs(
    messages: string[],
    context: {
        level: ILogLevel;
        name?: string;
    }
) {
    if (context.name === 'telemetryLogs') {
        const loggedObj = [context.level, `${new Date().toLocaleTimeString()} - ${context.level.name}`, messages];
        allTelemetryLogs.push(loggedObj);
    }

    if (isLogToDataDogEnbled() && context.level.value >= LogLevels.ERROR.value) {
        const messageStr = (messages ?? [])?.map((v) => `${v}`)?.join(' ,');
        datadogLogs.logger.error('Frontend error', {}, new Error(messageStr));
    }
}

global.printTelemetryLogs = () => {
    // type-coverage:ignore-line
    for (const loggedObj of allTelemetryLogs) {
        const [logLevel, time, message] = loggedObj;
        printWithLogLevel(logLevel as ILogLevel, '[telemetryLogs]', time, message);
    }
};

global.clearTelemetryLogs = () => {
    // type-coverage:ignore-line
    allTelemetryLogs.splice(0, allTelemetryLogs.length);
};

global.set_telemetryLogs_logLevel = (level: ILogLevel) => {
    // type-coverage:ignore-line
    telemetryLogs.setLevel(level);
};

if (isLogToDataDogEnbled()) {
    datadogLogs.init({
        clientToken: 'pub664860a9f6308bfbcdea1684ac97e97e',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: 'driver-frontend',
        env: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME,
        version: packageJson.version,
    });
}

export function logException(
    error: Error | string,
    severity: Severity = Severity.Error,
    additionalInfo?: Record<string, any>
): void {
    const context = {
        level: severity,
        extra: additionalInfo ?? undefined,
    };

    if (error instanceof Error) {
        console.error(error, context);
    } else {
        const e = new Error(error);
        console.error(e, context);
    }
}
