import { getDrillingBounds } from 'App/MainApp/Dialogs/DefineGrid/defineGridService';
import { StringCoordinatesObject } from 'App/MainApp/Dialogs/DefineGrid/gridLimitsHelpers';
import { SpacingValidationResults } from 'App/MainApp/Dialogs/SCMAnisotropy/CreateAnisotropyGrid';
import { APIResponseWithProjectUpdate } from 'App/util/ProjectDataTypes/APIResponseTypes';
import { ProjectTreeNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { Id2AnisotropyEstimationObject, ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { MeshFileType } from 'App/util/ProjectDataTypes/MeshFile';
import { SourceFileType } from 'App/util/ProjectDataTypes/SourceFile';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { TEXTBOX_ERROR_MESSAGES, getDefaultValidationResult } from 'App/util/validationUtils';
import { BoundsObject, CoordinateKeys, CoordinatesObject } from 'Common/types/geometryTypes';
import { coordinatesArrayToObject, getDistances } from 'Common/utils/geometryHelpers';
import { backendProjectUrl } from 'App/Redux/features/globalContext/currentProjectSlice';
import { anisotropyGridSuffixPrefix } from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyConsts';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { getNodePath } from 'App/MainApp/TreeView/treeData/treeDataUtils';

type AnisotropyGridJobData = {
    anisotropy_estimation_ids: ObjectIDType[];
    name_suffix: string;
    x_spacing: number;
    y_spacing: number;
    z_spacing: number;
};

export function runAnisotropyGrid(
    data: {
        anisotropyIds: ObjectIDType[];
        nameSuffix: string;
        xSpacing: number;
        ySpacing: number;
        zSpacing: number;
    },
    axiosDriverFlask: AxiosDriverFlaskInstance,
    projectId: ObjectIDType,
    orgId: string,
    workspaceId: string
) {
    const dataForServer: AnisotropyGridJobData = {
        anisotropy_estimation_ids: data.anisotropyIds,
        name_suffix: `${anisotropyGridSuffixPrefix}${data.nameSuffix}`,
        x_spacing: data.xSpacing,
        y_spacing: data.ySpacing,
        z_spacing: data.zSpacing,
    };

    const formData = new FormData();
    formData.append('parameters', JSON.stringify(dataForServer));

    return axiosDriverFlask.post<APIResponseWithProjectUpdate>(
        `${backendProjectUrl(orgId, workspaceId, projectId)}/anisotropyGrid`,
        formData,
        {
            withCredentials: true,
            responseType: 'json',
        }
    );
}

export function buildAnisotropySubsections(
    anisotropies: Id2AnisotropyEstimationObject,
    selectedDomainId: ObjectIDType
) {
    const anisotropyLabel = 'Anisotropy';

    const anisotropyNode: ProjectTreeNode = {
        section: anisotropyLabel,
        dataCy: anisotropyLabel,
        nodeId: SectionIds[SectionNames.Anisotropy],
        path: [],
    };

    const subsections: Subsections = {};

    Object.values(anisotropies)
        .filter((anisotropy) => anisotropy.domainId === selectedDomainId)
        .forEach((anisotropy) => {
            subsections[anisotropy.id] = {
                id: anisotropy.id,
                nodeId: anisotropy.id,
                className: anisotropy.object_class_name,
                section: anisotropy.name,
                dataCy: anisotropy.name,
                path: getNodePath(anisotropyNode),
            };
        });

    anisotropyNode.subsections = subsections;

    return {
        [anisotropyLabel]: anisotropyNode,
    };
}

export function calculateNumberOfCentersInDimension(spacing: number, distance: number) {
    return Math.ceil(distance / spacing);
}

export function calculateNumberOfCentersInSpace(spacings: CoordinatesObject, bounds: BoundsObject) {
    const distances = getDistances(bounds);
    const xNumberOfCenters = calculateNumberOfCentersInDimension(spacings.x, distances.x);
    const yNumberOfCenters = calculateNumberOfCentersInDimension(spacings.y, distances.y);
    const zNumberOfCenters = calculateNumberOfCentersInDimension(spacings.z, distances.z);

    const totalNumberOfCenters = xNumberOfCenters * yNumberOfCenters * zNumberOfCenters;
    return totalNumberOfCenters;
}

function getBounds(mesh: MeshFileType, drilling: SourceFileType) {
    let bounds: BoundsObject;

    if (mesh.meta_data && mesh.meta_data.vertices_min && mesh.meta_data.vertices_max) {
        const minPoint = coordinatesArrayToObject(mesh.meta_data.vertices_min);
        const maxPoint = coordinatesArrayToObject(mesh.meta_data.vertices_max);

        bounds = {
            minX: minPoint.x,
            minY: minPoint.y,
            minZ: minPoint.z,
            maxX: maxPoint.x,
            maxY: maxPoint.y,
            maxZ: maxPoint.z,
        };
    } else {
        bounds = getDrillingBounds(drilling);
    }

    return bounds;
}

export function calculateNumberOfCenters(spacings: CoordinatesObject, mesh: MeshFileType, drilling: SourceFileType) {
    const bounds = getBounds(mesh, drilling);
    const centersNumber = calculateNumberOfCentersInSpace(spacings, bounds);
    return centersNumber;
}

export function validateSpacing(spacingString: string, maxDistance: number) {
    const validationResult = getDefaultValidationResult();

    if (spacingString === '') {
        // assumption is that number field won't allow strings that aren't numbers
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.cannotBeEmpty;
        return validationResult;
    }

    const spacing = Number(spacingString);

    if (spacing > maxDistance) {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.getShouldBeSmallerThanOrEqual(maxDistance);
    } else if (spacing <= 0) {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.getShouldBeGreaterThan(0);
    }

    return validationResult;
}

export function validateSpacings(
    spacingsStrings: StringCoordinatesObject,
    mesh: MeshFileType,
    drilling: SourceFileType
) {
    const bounds = getBounds(mesh, drilling);

    const validationResults = {
        [CoordinateKeys.x]: validateSpacing(spacingsStrings.x, bounds.maxX - bounds.minX),
        [CoordinateKeys.y]: validateSpacing(spacingsStrings.y, bounds.maxY - bounds.minY),
        [CoordinateKeys.z]: validateSpacing(spacingsStrings.z, bounds.maxZ - bounds.minZ),
    };

    return validationResults;
}

export function isValidSpacing(spacingValidationResults: SpacingValidationResults) {
    return (
        spacingValidationResults.x.isValid && spacingValidationResults.y.isValid && spacingValidationResults.z.isValid
    );
}

const MAX_CENTERS_NUMBER = 100000;
const CENTERS_ERROR_MESSAGE = `Number of centers is limited to ${MAX_CENTERS_NUMBER.toLocaleString()}`;

export function validateNumberOfCenters(numberOfCenters: number, spacingValidationResults: SpacingValidationResults) {
    const validationResult = getDefaultValidationResult();

    if (isValidSpacing(spacingValidationResults)) {
        if (numberOfCenters > MAX_CENTERS_NUMBER) {
            validationResult.isValid = false;
            validationResult.error = CENTERS_ERROR_MESSAGE;
        }
    }

    return validationResult;
}
