import React from 'react';
import { tss } from 'tss-react/mui';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import update from 'immutability-helper';
import WidgetSelect from 'Common/components/Core/WidgetSelect';
import WidgetTextField from 'Common/components/Core/WidgetTextField';

const useStyles = tss.create(({ theme }) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    textField: {
        marginLeft: '0.2em',
        maxWidth: 63,
        minWidth: '3.5em',
    },
    menuRoot: {
        maxWidth: '100px',
    },
    mainDiv: {
        display: 'inline-flex',
    },
    selectRoot: {
        fontSize: '0.8rem',
    },
}));

export type MultiUnitTextFieldOptionsType = {
    typeDisplayText: string;
    typeValue: number | string;
    value: string;
    showTextField?: boolean;
    selected?: boolean;
    error?: string;
    estimatedValue?: number;
    estimatedValuePrefix?: string;
    estimatedValueText?: string;
    textBoxWidth?: number;
}[];

export function onMultiUnitTextFieldChange(
    stateSetter: React.Dispatch<React.SetStateAction<MultiUnitTextFieldOptionsType>>,
    selected_optionInfo_index: number,
    newValue: string | number
) {
    stateSetter((oldState: MultiUnitTextFieldOptionsType) => {
        const updateCommand = {};
        for (let optionInfo_index = 0; optionInfo_index < oldState.length; optionInfo_index++) {
            if (optionInfo_index === selected_optionInfo_index) {
                updateCommand[optionInfo_index] = {
                    selected: {
                        $set: true,
                    },
                };
                if (newValue !== undefined) {
                    updateCommand[optionInfo_index].value = {
                        $set: newValue,
                    };

                    const optionInfo = oldState[optionInfo_index];

                    updateCommand[optionInfo_index].error = {
                        $set: false,
                    };
                    if (['Percentile', 'Concentration'].includes(optionInfo.typeValue as string)) {
                        const newValueFloatParsed = Number(newValue);
                        if (isNaN(newValueFloatParsed) || newValue === '') {
                            updateCommand[optionInfo_index].error = {
                                $set: true,
                            };
                        } else if (
                            optionInfo.typeValue === 'Percentile' &&
                            (newValueFloatParsed > 100 || newValueFloatParsed < 0)
                        ) {
                            updateCommand[optionInfo_index].error = {
                                $set: true,
                            };
                        }
                    }
                }
            } else {
                updateCommand[optionInfo_index] = {
                    selected: {
                        $set: false,
                    },
                };
            }
        }
        return update(oldState, updateCommand);
    });
}

function MultiUnitTextField(props: {
    optionInfos: MultiUnitTextFieldOptionsType;
    onChange?: (optionInfo_index: number, newValue: string | number) => void;
    classes?: {
        menuPaper?: string;
    };
    dataCy?: string;
}) {
    const { classes } = useStyles();
    /* eslint-disable-next-line */
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const selectedMenuTextFieldRef = React.useRef(null);

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, optionInfo_index: number) => {
        if (props.onChange) {
            props.onChange(optionInfo_index, undefined);
        }

        setAnchorEl(null);
    };

    const selected_optionInfo_index_temp = props.optionInfos.findIndex((optionInfo) => optionInfo.selected);
    const selected_optionInfo_index = selected_optionInfo_index_temp < 0 ? 0 : selected_optionInfo_index_temp;
    const selected_optionInfo = props.optionInfos[selected_optionInfo_index];

    return (
        <Box className={classes.mainDiv} data-cy={props.dataCy}>
            <WidgetSelect
                variant="outlined"
                classes={{ select: classes.selectRoot }}
                value={selected_optionInfo.typeDisplayText}
            >
                {props.optionInfos.map((optionInfo, optionInfo_index) => (
                    <MenuItem
                        key={optionInfo.typeDisplayText}
                        value={optionInfo.typeDisplayText}
                        onClick={(event) => handleMenuItemClick(event, optionInfo_index)}
                        data-cy={optionInfo.typeDisplayText.toLowerCase().split(' ').join('-')}
                    >
                        {optionInfo.typeDisplayText}
                    </MenuItem>
                ))}
            </WidgetSelect>

            {selected_optionInfo.showTextField !== false && (
                <WidgetTextField
                    placeholder="Amount"
                    variant="outlined"
                    error={Boolean(selected_optionInfo.error)}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(selected_optionInfo_index, e.target.value);
                        }
                    }}
                    classes={{ root: classes.textField }}
                    size="small"
                    value={selected_optionInfo.value}
                    data-cy="textbox"
                />
            )}
            {selected_optionInfo.estimatedValue &&
                selected_optionInfo.estimatedValuePrefix &&
                selected_optionInfo.estimatedValuePrefix}
            {selected_optionInfo.estimatedValue && selected_optionInfo.estimatedValueText}
        </Box>
    );
}

export default MultiUnitTextField;
