import {
    AnisotropySectionMenuItems,
    DomainSectionMenuItems,
    DrillingSectionMenuItemNames,
    GeneralMenuItems,
    GridMenuItemNames,
    GridsSectionMenuItemNames,
    InterpolationSectionMenuItems,
    MeshesSectionMenuItemNames,
    OverlapFolderMenuItems,
    OverlapSectionMenuItems,
    PublishToEvoMenuItems,
    SecondaryMenuItems,
    ZonesFromAnisotropySectionMenuItems,
    ZonesSectionMenuItems,
} from 'App/MainApp/TreeView/treeData/treeConsts';
import { MeshTypes } from 'App/util/ProjectDataTypes/MeshFile';
import { OBJECT_CLASS_NAMES, ProjectObject } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import EvoMenuIcon from 'Common/components/icons/menuIcons/EvoMenuIcon';
import FileMenuIcon from 'Common/components/icons/menuIcons/FileMenuIcon';
import CreateObjectMenuIcon from 'Common/components/icons/menuIcons/CreateObjectMenuIcon';
import AnisotropyGlobalIcon from 'Common/components/icons/objects/AnisotropyGlobalIcon';
import AnisotropyGridIcon from 'Common/components/icons/objects/AnisotropyGridIcon';
import AnisotropyIcon from 'Common/components/icons/objects/AnisotropyIcon';
import BlockModelIcon from 'Common/components/icons/objects/BlockModelIcon';
import ClosedMeshIcon from 'Common/components/icons/objects/ClosedMeshIcon';
import DrillingIcon from 'Common/components/icons/objects/DrillingIcon';
import GridIcon from 'Common/components/icons/objects/GridIcon';
import OpenMeshIcon from 'Common/components/icons/objects/OpenMeshIcon';
import OverlapIcon from 'Common/components/icons/objects/OverlapIcon';
import ZoneIcon from 'Common/components/icons/objects/ZoneIcon';
import UploadFileMenuIcon from 'Common/components/icons/menuIcons/UploadFileMenuIcon';
import PlusAddToViewIcon from 'Common/components/icons/menuIcons/PlusAddToViewIcon';
import DownloadObjectMenuIcon from 'Common/components/icons/menuIcons/DownloadObjectMenuIcon';
import DeleteObjectMenuIcon from 'Common/components/icons/menuIcons/DeleteObjectMenuIcon';
import PublishObjectMenuIcon from 'Common/components/icons/menuIcons/PublishObjectMenuIcon';
import DiscIcon from 'Common/components/icons/objects/DiscIcon';
import LineationIcon from 'Common/components/icons/objects/LineationIcon';

export const ObjectIconMap = {
    [OBJECT_CLASS_NAMES.SourceFile]: DrillingIcon,
    [OBJECT_CLASS_NAMES.Domain]: DrillingIcon,
    [OBJECT_CLASS_NAMES.Domain_GridDefinition]: GridIcon,
    [OBJECT_CLASS_NAMES.GridDefinition]: GridIcon,
    [OBJECT_CLASS_NAMES.MeshFile]: ClosedMeshIcon,
    [OBJECT_CLASS_NAMES.AnisotropyEstimation]: AnisotropyIcon,
    [OBJECT_CLASS_NAMES.AnisotropyGrid]: AnisotropyGridIcon,
    [OBJECT_CLASS_NAMES.AnisotropyGlobal]: AnisotropyGlobalIcon,
    [OBJECT_CLASS_NAMES.PointEstimation]: BlockModelIcon,
    [OBJECT_CLASS_NAMES.Zone]: ZoneIcon,
    [OBJECT_CLASS_NAMES.ZoneFromAnisotropy]: ZoneIcon,
    [OBJECT_CLASS_NAMES.Overlap]: OverlapIcon,
};

export const MenuItemIconMap = {
    [DrillingSectionMenuItemNames.UploadDrilling]: UploadFileMenuIcon,
    [DrillingSectionMenuItemNames.UpdateDrilling]: UploadFileMenuIcon,
    [MeshesSectionMenuItemNames.UploadClosedMesh]: UploadFileMenuIcon,
    [MeshesSectionMenuItemNames.UploadOpenMesh]: UploadFileMenuIcon,
    [MeshesSectionMenuItemNames.MeshFromRegion]: CreateObjectMenuIcon,
    [MeshesSectionMenuItemNames.ClipRegionWithSurface]: CreateObjectMenuIcon,
    [DomainSectionMenuItems.DefineDomains]: CreateObjectMenuIcon,
    [GridsSectionMenuItemNames.CreateGrid]: CreateObjectMenuIcon,
    [GridMenuItemNames.UpdateGrid]: CreateObjectMenuIcon,
    [AnisotropySectionMenuItems.NewAnisotropyEstimation]: CreateObjectMenuIcon,
    [AnisotropySectionMenuItems.NewAnisotropyGrid]: CreateObjectMenuIcon,
    [AnisotropySectionMenuItems.NewAnisotropyGlobal]: CreateObjectMenuIcon,
    [InterpolationSectionMenuItems.NewInverseDistance]: CreateObjectMenuIcon,
    [InterpolationSectionMenuItems.NewOrdinaryKriging]: CreateObjectMenuIcon,
    [InterpolationSectionMenuItems.NewMergedInterpolation]: CreateObjectMenuIcon,
    [ZonesSectionMenuItems.NewZone]: CreateObjectMenuIcon,
    [ZonesFromAnisotropySectionMenuItems.ZoneFromAnisotropy]: CreateObjectMenuIcon,
    [OverlapSectionMenuItems.CreateFolder]: CreateObjectMenuIcon,
    [OverlapFolderMenuItems.CreateOverlaps]: CreateObjectMenuIcon,
    [OverlapFolderMenuItems.RenameFolder]: CreateObjectMenuIcon,
    [SecondaryMenuItems.ToEvo]: EvoMenuIcon,
    [SecondaryMenuItems.FromEvo]: EvoMenuIcon,
    [SecondaryMenuItems.ToLocal]: FileMenuIcon,
    [SecondaryMenuItems.FromLocal]: FileMenuIcon,
    [PublishToEvoMenuItems.Ellipsoids]: AnisotropyIcon,
    [PublishToEvoMenuItems.StructuralData]: DiscIcon,
    [PublishToEvoMenuItems.LineationData]: LineationIcon,
    [GeneralMenuItems.addToView]: PlusAddToViewIcon,
    [GeneralMenuItems.download]: DownloadObjectMenuIcon,
    [GeneralMenuItems.delete]: DeleteObjectMenuIcon,
    [GeneralMenuItems.publishToEvo]: EvoMenuIcon,
    [GeneralMenuItems.publishToLocal]: FileMenuIcon,
    [GeneralMenuItems.publish]: PublishObjectMenuIcon,
};

export function getObjectIcon(projectObject: ProjectObject): React.FunctionComponent {
    if (projectObject.object_class_name === OBJECT_CLASS_NAMES.MeshFile && projectObject.type === MeshTypes.open) {
        return OpenMeshIcon;
    }

    return ObjectIconMap[projectObject.object_class_name] || null;
}

export function getMenuIcon(sectionName: string, className: string): JSX.Element {
    let menuIcon = null;
    if (MenuItemIconMap[sectionName]) {
        menuIcon = MenuItemIconMap[sectionName]({ className: className });
    }
    return menuIcon;
}
