import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppState } from '../../store';
import {
    OBJECT_CLASS_NAMES,
    ObjectIDType,
    ProjectObject,
} from '../../../util/ProjectDataTypes/ProjectObjectsDataTypes';
import { AxiosDriverFlaskInstance } from '../../../util/axiosErrorHandlers';

export type SceneObjectFocusType = {
    nodeId: string;
    objectId: ObjectIDType;
    objectClassName: OBJECT_CLASS_NAMES;
    name?: string; // useful when refering to a part of a project object, like a drilling attribute inside source file
    sceneObjectFocus?: ProjectObject; // only for displaying previous versions of objects that aren't present in currentProject; assumption: old versions are never modified
};

const initialState: SceneObjectFocusType = {
    nodeId: null,
    objectId: null,
    objectClassName: null,
    sceneObjectFocus: null,
    name: '',
};

export const sceneObjectFocusSlice = createSlice({
    name: 'sceneObjectFocus',
    initialState,
    reducers: {
        setSceneObjectFocus: (state, action: PayloadAction<SceneObjectFocusType>) => {
            const newState: SceneObjectFocusType = { ...initialState };
            if (action.payload) {
                newState.objectId = action.payload.objectId;
                newState.objectClassName = action.payload.objectClassName;
                newState.nodeId = action.payload.nodeId;
                newState.name = action.payload.name;

                if (action.payload.sceneObjectFocus) {
                    const objectCopy = { ...action.payload.sceneObjectFocus };
                    Object.freeze(objectCopy); // Freeze large object to improve immer performance
                    newState.sceneObjectFocus = objectCopy;
                } else {
                    newState.sceneObjectFocus = null;
                }
            }
            return newState;
        },
    },
});

export const { setSceneObjectFocus } = sceneObjectFocusSlice.actions;

export const selectSceneObjectFocus = (state: AppState) => {
    const unsavedObject = state.sceneObjectFocus.sceneObjectFocus;
    if (unsavedObject) {
        return unsavedObject;
    } else {
        if (state.sceneObjectFocus.objectClassName && state.sceneObjectFocus.objectId) {
            return state.currentProject.object_class_name2id2obj[state.sceneObjectFocus.objectClassName][
                state.sceneObjectFocus.objectId
            ];
        } else {
            return null;
        }
    }
};

export const selectSceneObjectFocusNodeId = (state: AppState) => state.sceneObjectFocus.nodeId;
export const selectSceneObjectFocusId = (state: AppState) => state.sceneObjectFocus.objectId;
export const selectSceneObjectFocusClassName = (state: AppState) => state.sceneObjectFocus.objectClassName;
export const selectSceneObjectFocusName = (state: AppState) => state.sceneObjectFocus.name;

export const setSceneObjectFocusUsingUrl = (axiosDriverFlask: AxiosDriverFlaskInstance, objectURL: string) =>
    function setSceneObjectFocusUsingUrlThunk(dispatch: AppDispatch) {
        axiosDriverFlask
            .get<ProjectObject>(objectURL, {
                responseType: 'json',
                withCredentials: true,
            })
            .then((res) => {
                const newObject: ProjectObject = res.data;
                dispatch(
                    setSceneObjectFocus({
                        sceneObjectFocus: newObject,
                        nodeId: null,
                        objectId: null,
                        objectClassName: null,
                        name: '',
                    })
                );
            })
            .catch((e) => {
                throw e;
            });
    };

export default sceneObjectFocusSlice.reducer;
