import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import {
    applyWebsocketModificationsToCurrentProject,
    backendProjectUrl,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
    selectObjectClassname2Id2Obj,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import React from 'react';
import { tss } from 'tss-react/mui';
import InputAdornment from '@mui/material/InputAdornment';
import WidgetTextField from 'Common/components/Core/WidgetTextField';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import {
    interpolationsSectionDataCy,
    minConcentrationThresholdDataCy,
    minSizeInBlocksTextboxDataCy,
    nameSuffixTextboxDataCy,
    zonesDialogDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import { APIResponseWithProjectUpdate } from '../../../util/ProjectDataTypes/APIResponseTypes';
import MultiUnitTextField, {
    MultiUnitTextFieldOptionsType,
    onMultiUnitTextFieldChange,
} from '../Shared/MultiUnitTextField';
import { findSectionAmongSubsections, getFirstLevelNodeIds } from '../../TreeView/treeUtils';
import { DataContext } from '../../../DataContext';
import { ZONE_SUFFIX, expandZoneNodes, getDomainGridIdsAndGridIds } from './CreateNewZonesService';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { useSelectableTree } from 'App/MainApp/TreeView/components/checkboxTree/useSelectableTree';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { buildInterpolationsGridSubtrees } from 'App/MainApp/TreeView/treeData/interpolationsSectionService';
import { ProjectTreeSource, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useSessionContext } from 'App/context/SessionContext';
import ProjectTree from 'App/MainApp/TreeView/components/ProjectTree/ProjectTree';
import { commonStyles } from 'styles/commonStyles';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create(({ theme }) => ({
    treeTopParent: {
        height: 240,
        flexGrow: 1,
        overflowY: 'auto',
        marginBottom: 20,
    },
    multiUnitTextField_MenuPaper: {
        width: '18em',
    },
    defaultText: {
        ...commonStyles({ theme }).defaultText,
    },
}));

function CreateNewZones(props: { handleClose: () => void }) {
    const { addMessage } = useDriverMessagesContext();
    const { classes } = useStyles();
    const xyz = useXyz();
    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const [allowOnlyOneDataAttribute, setAllowOnlyOneDataAttribute] = React.useState(true);
    const dispatch = useAppDispatch();
    const DEFAULTS = {
        namesSuffix: '',
        namesSuffixMandatory: ZONE_SUFFIX,
        manual_minimum_concentration_threshold: '0',
        manual_minimum_concentration_threshold_type: 'Concentration',
        identify_depleted_zones: false,
        min_cluster_points: '3',
    };
    const [formResults, setFormResults] = React.useState(DEFAULTS);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const { onExpandChange } = useExpandedNodes();

    const [manualMinimumConcentrationThresholdOptions, setManualMinimumConcentrationThresholdOptions] =
        React.useState<MultiUnitTextFieldOptionsType>([
            {
                typeDisplayText: 'Percentile',
                typeValue: 'Percentile',
                value: '50',
            },
            {
                typeDisplayText: 'Concentration',
                typeValue: 'Concentration',
                value: '0',
            },
            {
                typeDisplayText: 'Mean',
                typeValue: 'Mean',
                value: 'N/A',
                showTextField: false,
                selected: true,
            },
        ]);

    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const allObjects = useAppSelector(selectObjectClassname2Id2Obj);

    const interpolationsSubsections: Subsections = React.useMemo(() => {
        const interpolationsSubsections: Subsections = {
            [SectionNames.Interpolations]: {
                section: SectionNames.Interpolations,
                nodeId: SectionIds[SectionNames.Interpolations],
                dataCy: interpolationsSectionDataCy,
                path: [],
                subsections: {},
            },
        };

        buildInterpolationsGridSubtrees(
            allObjects,
            interpolationsSubsections[SectionNames.Interpolations],
            false,
            true
        );

        return interpolationsSubsections;
    }, [allObjects]);

    const { onChange, nodeSelectionChecker, selectedLeafNodes } = useSelectableTree(
        interpolationsSubsections,
        null,
        null
    );

    React.useEffect(() => {
        let shouldAllowOnlyOneDataAttribute = false;
        const concentrationOptionForMinThrehsold = findSectionAmongSubsections(
            manualMinimumConcentrationThresholdOptions,
            'Concentration',
            'typeValue'
        );
        if (concentrationOptionForMinThrehsold.selected) {
            shouldAllowOnlyOneDataAttribute = true;
        }
        if (shouldAllowOnlyOneDataAttribute !== allowOnlyOneDataAttribute) {
            setAllowOnlyOneDataAttribute(shouldAllowOnlyOneDataAttribute);
        }
    }, [manualMinimumConcentrationThresholdOptions]);

    const onSubmit = () => {
        const formData = new FormData();

        if (selectedLeafNodes.length === 0) {
            addMessage({
                message: 'You must select at least one Interpolation!',
                type: NotificationType.ERROR,
            });
            return;
        }

        if (allowOnlyOneDataAttribute && selectedLeafNodes.length > 1) {
            addMessage({
                message:
                    'At most one pointEstimation can be selected because you are applying a concentration-based threshold!',
                type: NotificationType.ERROR,
            });
            return;
        }

        formData.append('pointEstimationsIDs', JSON.stringify(selectedLeafNodes.map((node) => node.id)));

        const selectedOptionForMinThrehsold = findSectionAmongSubsections(
            manualMinimumConcentrationThresholdOptions,
            true,
            'selected'
        );

        if (selectedOptionForMinThrehsold.error) {
            addMessage({
                message: 'Error in one of the inputs!',
                type: NotificationType.ERROR,
            });
            return;
        }

        const formResultsCopy = {
            ...formResults,
            ...{
                manual_minimum_concentration_threshold: selectedOptionForMinThrehsold.value,
                manual_minimum_concentration_threshold_type: selectedOptionForMinThrehsold.typeValue,
                analysis_type: 'cutoff',
            },
        };

        formData.append('parameters', JSON.stringify(formResultsCopy));

        const { gridIds, domainGridDefinitionIds } = getDomainGridIdsAndGridIds(selectedLeafNodes);

        void axiosDriverFlask
            .post<APIResponseWithProjectUpdate>(
                `${backendProjectUrl(currentOrgUuid, currentWorkspaceId, currentProjectId)}/zoneRequest`,
                formData,
                {
                    withCredentials: true,
                    responseType: 'json',
                }
            )
            .then((projectUpdateJson) => {
                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'submit cutoff zone',
                        tokenProvider
                    )
                );

                expandZoneNodes(onExpandChange, gridIds, domainGridDefinitionIds);

                props.handleClose();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
        setIsSubmitting(true);
    };

    React.useEffect(() => {
        const pointEstimationNodeIds = getFirstLevelNodeIds(interpolationsSubsections);
        onExpandChange(pointEstimationNodeIds, true, ProjectTreeSource.ZONES, true);
    }, []);

    return (
        <GenericDialogShell
            aria-labelledby="draggable-dialog-title"
            dataCy={zonesDialogDataCy}
            title="Create Zones"
            maxWidth="sm"
            handleClose={props.handleClose}
        >
            <ProjectTree
                checkboxTree
                className={classes.treeTopParent}
                tree={interpolationsSubsections}
                onChange={onChange}
                nodeSelectionChecker={nodeSelectionChecker}
                treeSource={ProjectTreeSource.ZONES}
            />
            <Grid container spacing={1} sx={{ paddingTop: 1 }}>
                <Grid container item spacing={3}>
                    <Grid className={classes.defaultText} item xs={7}>
                        Name suffix:
                    </Grid>
                    <Grid item xs={5}>
                        <WidgetTextField
                            id="outlined-basic"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">{formResults.namesSuffixMandatory}</InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setFormResults({
                                    ...formResults,
                                    ...{ namesSuffix: e.target.value },
                                });
                            }}
                            size="small"
                            value={formResults.namesSuffix}
                            data-cy={nameSuffixTextboxDataCy}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={7} container alignItems="center">
                        <Grid className={classes.defaultText} item>
                            Minimum concentration threshold
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <MultiUnitTextField
                            dataCy={minConcentrationThresholdDataCy}
                            classes={{
                                menuPaper: classes.multiUnitTextField_MenuPaper,
                            }}
                            optionInfos={manualMinimumConcentrationThresholdOptions}
                            onChange={(optionInfoIndex, newValue) =>
                                onMultiUnitTextFieldChange(
                                    setManualMinimumConcentrationThresholdOptions,
                                    optionInfoIndex,
                                    newValue
                                )
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={7} container alignItems="center">
                        <Grid className={classes.defaultText} item>
                            Min cluster size (in blocks)
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <WidgetTextField
                            id="outlined-basic"
                            placeholder={DEFAULTS.min_cluster_points}
                            variant="outlined"
                            onChange={(e) => {
                                const v = e.target.value;
                                setFormResults({
                                    ...formResults,
                                    ...{ min_cluster_points: v },
                                });
                            }}
                            value={formResults.min_cluster_points}
                            data-cy={minSizeInBlocksTextboxDataCy}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <GenericDialogActions
                onCancel={props.handleClose}
                onSubmit={onSubmit}
                disabled={isSubmitting || selectedLeafNodes.length === 0}
                showSpinner={isSubmitting}
                tooltipText={
                    isSubmitting
                        ? 'Submitting...'
                        : selectedLeafNodes.length === 0
                          ? 'You need to select at least one pointEstimation.'
                          : ''
                }
            />
        </GenericDialogShell>
    );
}

export default CreateNewZones;
