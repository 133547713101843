import { getActiveObjects } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { getDefaultValidationResult } from 'App/util/validationUtils';

export function checkInterpolationItemsDisabled(allObjects: ObjectClassName2Id2Obj) {
    const validationResult = getDefaultValidationResult();

    // const grids = allObjects[OBJECT_CLASS_NAMES.GridDefinition];

    const grids = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.GridDefinition);

    const anisotropyGrids = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.AnisotropyGrid);
    const anisotropyGlobals = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.AnisotropyGlobal);
    const anisotropyEstimations = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.AnisotropyEstimation);

    const numOfAnisotropies =
        Object.values(anisotropyGrids).length +
        Object.values(anisotropyGlobals).length +
        Object.values(anisotropyEstimations).length;

    if (!Object.values(grids).length || !numOfAnisotropies) {
        validationResult.isValid = false;
        validationResult.error = 'Requires 3D grid, and at least one anisotropy object';
    }

    return validationResult;
}
