import { alphabeticSorter } from 'Common/utils/utils';
import {
    applyWebsocketModificationsToCurrentProject,
    backendProjectUrl,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { AppDispatch } from 'App/Redux/store';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { OverlapFolderType } from 'App/util/ProjectDataTypes/OverlapFolder';
import { DomainGridDefinitionType } from 'App/util/ProjectDataTypes/DomainGridDefinition';
import { plotUnsavedOverlapObject } from 'Common/DRIVER2XYZ/plotUnsavedOverlapObject';
import { AxiosDriverFlaskInstance } from '../../../util/axiosErrorHandlers';
import { APIResponseWithProjectUpdate } from '../../../util/ProjectDataTypes/APIResponseTypes';
import { Id2DomainObject, Id2ZoneObject, ObjectIDType } from '../../../util/ProjectDataTypes/ProjectObjectsDataTypes';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { Message } from '@local/messages/dist/Messages.types';

export type ObjectsVolumesHvolumesType = [string[], number, string][];

export function expandOverlapsNode(
    onExpandChange: (nodes: ObjectIDType[], expand: boolean) => void,
    overlapProfileId: ObjectIDType
) {
    const overlapSectionId = SectionIds[SectionNames.Overlaps];
    const overlapIdNode = `${overlapSectionId}_${overlapProfileId}`;

    const nodesToExpand = [overlapSectionId, overlapIdNode];

    onExpandChange(nodesToExpand, true);
}

export function getOverlapObjectsError(obj: string) {
    return `Each overlap indentifier must start with either a 'z' or 'd' , but this one is: ${obj}`;
}

export interface GetOverlapCombinationsResponse {
    objects_volumes_hvolumes: ObjectsVolumesHvolumesType;
}

export async function addOverlapToView(
    activeOverlapFolder: OverlapFolderType,
    objectIds: string[],
    gridDefinitionId: string,
    dispatch: AppDispatch,
    xyz: UseXyz,
    currentOrgUuid: string
) {
    await dispatch(plotUnsavedOverlapObject(xyz, currentOrgUuid, objectIds, gridDefinitionId, activeOverlapFolder.id));
}

export async function saveOverlapToFolder(
    xyz: UseXyz,
    dispatch: AppDispatch,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    activeOverlapFolder: OverlapFolderType,
    addMessage: (message: Message) => void,
    objectIds: string[],
    gridDefinitionId: number | string,
    overlapVolume: number,
    onExpandChange: (nodes: ObjectIDType[], expand: boolean) => void,
    tokenProvider: () => Promise<string>
) {
    const formData = new FormData();
    const zoneIds = [];
    const domainGridDefinitionIds = [];

    objectIds.forEach((obj) => {
        switch (obj[0]) {
            case 'z': {
                zoneIds.push(obj.slice(2));
                break;
            }
            case 'd': {
                domainGridDefinitionIds.push(obj.slice(2));
                break;
            }
            default: {
                throw new Error(getOverlapObjectsError(obj));
            }
        }
    });
    formData.append('zoneIds', JSON.stringify(zoneIds));
    formData.append('domain_gridDefinitionIds', JSON.stringify(domainGridDefinitionIds));
    formData.append('gridDefinitionId', gridDefinitionId.toString());
    formData.append('volume', overlapVolume.toString());

    return axiosDriverFlask
        .post<APIResponseWithProjectUpdate>(`${activeOverlapFolder.url}/overlap`, formData, {
            withCredentials: true,
            responseType: 'json',
        })
        .then((projectUpdateJson) => {
            void dispatch(
                applyWebsocketModificationsToCurrentProject(
                    xyz,
                    axiosDriverFlask,
                    projectUpdateJson.data,
                    activeOverlapFolder.projectId,
                    'saveOverlapToProfile',
                    tokenProvider
                )
            );
            expandOverlapsNode(onExpandChange, activeOverlapFolder.id);
        })
        .catch(() => {
            addMessage({
                message: 'Overlap could not be saved!',
                type: NotificationType.ERROR,
            });
        });
}

export function getCombinations(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentProjectId: string,
    overlapsFolderId: ObjectIDType,
    gridDefinitionId: ObjectIDType,
    zoneIds: ObjectIDType[],
    domainGridDefinitionIds: ObjectIDType[],
    signal: AbortSignal,
    orgId: string,
    workspaceId: string
) {
    return axiosDriverFlask.get<GetOverlapCombinationsResponse>(
        `${backendProjectUrl(orgId, workspaceId, currentProjectId)}/overlapProfile/${overlapsFolderId}/overlapCombinations`,
        {
            params: {
                zoneIds,
                domain_gridDefinitionIds: domainGridDefinitionIds,
                gridDefinitionId,
            },
            withCredentials: true,
            responseType: 'json',
            signal,
        }
    );
}

export type RawOverlapType = [objects: string[], volume: number, formattedVolume: string];

export type OverlapType = {
    objects: string[];
    volume: number;
    formattedVolume: string;
};

export type FormattedOverlapType = {
    name: string;
    objectsString: string;
    volume: number;
};

function rawOverlapToObject(rawCombination: RawOverlapType): OverlapType {
    return {
        objects: rawCombination[0],
        volume: rawCombination[1],
        formattedVolume: rawCombination[2],
    };
}

export function formatOverlaps(
    rawOverlaps: RawOverlapType[],
    zonesMap: Id2ZoneObject,
    domainGridMap: { [id: ObjectIDType]: DomainGridDefinitionType },
    domainsMap: Id2DomainObject
) {
    return rawOverlaps.map((rawOverlap) => formatOverlap(rawOverlap, zonesMap, domainGridMap, domainsMap));
}

function formatOverlap(
    rawOverlap: RawOverlapType,
    zonesMap: Id2ZoneObject,
    domainGridMap: { [id: ObjectIDType]: DomainGridDefinitionType },
    domainsMap: Id2DomainObject
): FormattedOverlapType {
    const overlap = rawOverlapToObject(rawOverlap);
    const nameArray = [];
    overlap.objects.forEach((obj) => {
        switch (obj[0]) {
            case 'z': {
                const zone = zonesMap[obj.slice(2)];
                if (zone) {
                    nameArray.push(zone.name.replace(/^_+/, '').split('_')[0]);
                }
                break;
            }
            case 'd': {
                const domainGrid = domainGridMap[obj.slice(2)];
                if (domainGrid) {
                    const domain = domainsMap[domainGrid.domainId];
                    const locationOfLastDot = domain.name.lastIndexOf('.');
                    const fileNameStem = domain.name.substring(
                        0,
                        locationOfLastDot > 0 ? locationOfLastDot : domain.name.length
                    );
                    nameArray.push(fileNameStem);
                }
                break;
            }
            default: {
                throw new Error(getOverlapObjectsError(obj));
            }
        }
    });

    const name = `${nameArray.sort(alphabeticSorter).join(', ')} - ${overlap.formattedVolume}M3`;

    const objectsString = rawOverlap[0].sort(alphabeticSorter).join(',');

    return {
        name,
        objectsString,
        volume: overlap.volume,
    };
}
