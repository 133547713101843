import { MinMaxRange } from 'Common/utils/validationTypeUtils';

export enum XyzPredefinedColorMaps {
    JET = 'JET',
    HOT = 'HOT',
    VIRIDIS = 'VIRIDIS',
}

export const XYZ_MAPCONTINUOUS_GRADIENT_CONTROL_VALUES = [0, 0, 1, 1];
export const XYZ_MAPCONTINUOUS_VISIBILITY = Array(5).fill(true); // This means we want to show all values, including the ones beyond the limits of colorMap range.

export function colorMapRange2dataControlValues(colorMapRange: MinMaxRange): number[] {
    const { min, max } = colorMapRange;
    return [min, min, max, max];
}

export function dataControlValues2colorMapRange(dataControlValues: number[]): MinMaxRange {
    return {
        min: dataControlValues?.[0] ?? 0,
        max: dataControlValues?.[(dataControlValues?.length ?? 1) - 1] ?? 1,
    };
}
