import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { ProjectTreeNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { ProjectObject } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import React from 'react';

export function useProjectObject(node: ProjectTreeNode) {
    const allObjects = useAppSelector(selectObjectClassname2Id2Obj);

    const projectObject = React.useMemo(() => {
        let projectObject: ProjectObject;

        if ('id' in node) {
            projectObject = allObjects[node.className][node.id];
        }

        return projectObject;
    }, [node]);

    return {
        projectObject,
    };
}
