import { Overlap } from 'App/util/ProjectDataTypes/Overlap';
import { ObjectIDType, ObjectStatusTypes, OBJECT_CLASS_NAMES } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { DrillingAttributeType } from './SourceFile';
import { Dependants } from './utilClasses/Dependants';

export interface DomainType extends BackendJsonifiedProjectObject {
    name: string;
    meshFileId: ObjectIDType;
    sourceFileId: ObjectIDType;
    status: ObjectStatusTypes;
    result_meta_data: DomainResultMetaData;
    hidden: boolean;
    object_class_name: OBJECT_CLASS_NAMES.Domain;
}

export interface DomainResultMetaData {
    num_samples: number;
    dataAttribute2info: {
        [dataAttribute: string]: DrillingAttributeType;
    };
}

export class Domain extends BaseProjectObject implements DomainType {
    meshFileId: ObjectIDType;

    sourceFileId: ObjectIDType;

    result_meta_data: DomainResultMetaData;

    hidden: boolean;

    object_class_name: OBJECT_CLASS_NAMES.Domain;

    getDependantsViaAnisotropyEstimations = () => {
        const anisotropies = this.allObjects[OBJECT_CLASS_NAMES.AnisotropyEstimation];

        const dependants = new Dependants();

        Object.values(anisotropies).forEach((anisotropy) => {
            if (anisotropy.domainId === this.id) {
                dependants.addDependantAndItsDependantsRaw(anisotropy, this.axiosDriverFlask, this.allObjects);
            }
        });

        return dependants;
    };

    getDependantsViaOverlaps = () => {
        const overlaps = this.allObjects[OBJECT_CLASS_NAMES.Overlap];

        const dependants = new Dependants();

        Object.values(overlaps).forEach((overlap) => {
            const overlapInstance = new Overlap(overlap, this.axiosDriverFlask, this.allObjects);

            const isDirectDependant = overlapInstance.getParentDomainIds().includes(this.id);

            if (isDirectDependant) {
                dependants.addDependantAndItsDependantsRaw(overlap, this.axiosDriverFlask, this.allObjects);
            }
        });

        return dependants;
    };

    getSourceFile = () => this.projectObjects.getSourceFile(this.sourceFileId);

    getMesh = () => this.projectObjects.getMesh(this.meshFileId);

    getDependants = () => {
        const dependants = this.getDependantsViaAnisotropyEstimations();
        dependants.addDependants(this.getDependantsViaOverlaps());

        return dependants;
    };
}
