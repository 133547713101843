import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import React from 'react';
import { BoundsKey, BoundsObject, BoundsStringsObject } from 'Common/types/geometryTypes';
import InfoTooltip from 'Common/components/InfoTooltip/InfoTooltip';
import {
    blocksModeRadioDataCy,
    blocksResetButtonDataCy,
    coordinatesModeRadioDataCy,
    maxResetButtonDataCy,
    minResetButtonDataCy,
    totalSizeInBlocksDataCy,
    totalSizeInBlocksErrorLabelDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { OppositeBoundsMap, isMinBound, stringsObjectToBoundsObject } from 'Common/utils/geometryHelpers';
import { ValidationResult } from 'App/util/validationUtils';
import ErrorLabel from 'Common/components/ErrorHandling/ErrorLabel';
import { tss } from 'tss-react/mui';
import {
    BlocksDimensionsStrings,
    BlocksDimensionsValidationResult,
    BoundsValidationResults,
} from './gridLimitsHelpers';
import ResetButton from './ResetButton';
import BoundsRow from '../Shared/BoundsRow';
import { maxKeys, minKeys, minMaxKeysToTitles } from '../Shared/consts';
import { useCalculateBoundsIncrements } from '../Shared/useCalculateBoundsIncrementsHook';
import { commonStyles } from 'styles/commonStyles';

const useStyles = tss.create(({ theme }) => ({
    totalBlocksErrorLabel: {
        position: 'absolute',
    },
    defaultText: {
        ...commonStyles({ theme }).defaultText,
    },
}));

const originTooltip = 'Position of the lower left corner of the first block.';

const blocksKeysToTitles = {
    xBlocks: 'X:',
    yBlocks: 'Y:',
    zBlocks: 'Z:',
};

const blocksKeys = ['xBlocks', 'yBlocks', 'zBlocks'];

function getBoundBounds(bounds: BoundsObject, referenceBounds: BoundsObject, key: BoundsKey) {
    if (isMinBound(key)) {
        return {
            min: referenceBounds[key],
            max: bounds[OppositeBoundsMap[key]],
        };
    }
    return {
        min: bounds[OppositeBoundsMap[key]],
        max: referenceBounds[key],
    };
}

function generateBoundsBounds(bounds: BoundsObject, referenceBounds: BoundsObject) {
    return (key: BoundsKey) => getBoundBounds(bounds, referenceBounds, key);
}

export enum Modes {
    blocksMode = 'blocksMode',
    maxBoundMode = 'maxBoundMode',
}

type GridBoundsProps = {
    bounds: BoundsStringsObject;
    originalBounds: BoundsObject;
    referenceBounds: BoundsObject;
    generateOnBoundsChange: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    boundsValidationResult: BoundsValidationResults;
    generateOnMinBoundsBlur: (key: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
    generateOnMaxBoundsBlur: (key: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
    sizeInBlocks: BlocksDimensionsStrings;
    originalSizeInBlocks: BlocksDimensionsStrings;
    generateOnBlocksChange: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    generateOnSizeInBlocksBlur: (key: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
    isBlocksMode: boolean;
    onModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onMinBoundsReset: () => void;
    onMaxBoundsReset: () => void;
    classes: Record<'title' | 'boundsRow' | 'textField' | 'radio', string>;
    totalBlocks: string;
    sizesInBlocksValidationResult: BlocksDimensionsValidationResult;
    totalSizeInBlocksValidationResult: ValidationResult;
};

function GridBounds({
    bounds,
    originalBounds,
    referenceBounds,
    originalSizeInBlocks,
    generateOnBoundsChange,
    generateOnBlocksChange,
    boundsValidationResult,
    isBlocksMode,
    generateOnMinBoundsBlur,
    generateOnMaxBoundsBlur,
    sizeInBlocks,
    generateOnSizeInBlocksBlur,
    onModeChange,
    onMinBoundsReset,
    onMaxBoundsReset,
    classes,
    totalBlocks,
    sizesInBlocksValidationResult,
    totalSizeInBlocksValidationResult,
}: GridBoundsProps) {
    const actualSizeInBlocks = sizeInBlocks || {
        xBlocks: '',
        yBlocks: '',
        zBlocks: '',
    };
    const { minIncrements, maxIncrements } = useCalculateBoundsIncrements(originalBounds);
    const { classes: cssClasses } = useStyles();

    return (
        <Grid item container direction="column">
            <Grid item container className={classes.title}>
                <Typography className={cssClasses.defaultText}>Origin (base)</Typography>
                <InfoTooltip text={originTooltip} />
                <ResetButton
                    tooltipText="Reset origin to last saved values"
                    onClick={onMinBoundsReset}
                    disabled={false}
                    dataCy={minResetButtonDataCy}
                />
            </Grid>
            <BoundsRow
                keys={minKeys}
                keysToTitles={minMaxKeysToTitles}
                validationResults={boundsValidationResult}
                values={bounds}
                generateOnChange={generateOnBoundsChange}
                generateOnBlur={generateOnMinBoundsBlur}
                increments={minIncrements}
                getTextFieldBounds={generateBoundsBounds(stringsObjectToBoundsObject(bounds), referenceBounds)}
                placeholders={originalBounds}
                className={classes.boundsRow}
                textFieldClassName={classes.textField}
            />

            <Grid item container className={classes.title}>
                <Radio
                    checked={isBlocksMode}
                    onChange={onModeChange}
                    value={Modes.blocksMode}
                    size="small"
                    className={classes.radio}
                    data-cy={blocksModeRadioDataCy}
                />
                <Typography className={cssClasses.defaultText} fontWeight="bold">
                    Number of grid nodes
                </Typography>
                <ResetButton
                    tooltipText="Reset number of grid nodes to last saved values"
                    onClick={onMaxBoundsReset}
                    disabled={false}
                    dataCy={blocksResetButtonDataCy}
                />
            </Grid>
            <BoundsRow
                keys={blocksKeys}
                keysToTitles={blocksKeysToTitles}
                values={actualSizeInBlocks}
                generateOnChange={generateOnBlocksChange}
                generateOnBlur={generateOnSizeInBlocksBlur}
                placeholders={originalSizeInBlocks}
                isRowDisabled={!isBlocksMode}
                className={classes.boundsRow}
                textFieldClassName={classes.textField}
                validationResults={sizesInBlocksValidationResult}
            />
            <Grid item data-cy={totalSizeInBlocksDataCy} className={classes.boundsRow}>
                {`(= ${totalBlocks} in total)`}
                <ErrorLabel
                    show={!totalSizeInBlocksValidationResult.isValid}
                    errorText={totalSizeInBlocksValidationResult.error}
                    dataCy={totalSizeInBlocksErrorLabelDataCy}
                    className={cssClasses.totalBlocksErrorLabel}
                />
            </Grid>

            <Grid item container className={classes.title}>
                <Radio
                    checked={!isBlocksMode}
                    onChange={onModeChange}
                    value={Modes.maxBoundMode}
                    size="small"
                    className={classes.radio}
                    data-cy={coordinatesModeRadioDataCy}
                />
                <Typography className={cssClasses.defaultText} fontWeight="bold">
                    End (top)
                </Typography>
                <ResetButton
                    tooltipText="Reset end to last saved values"
                    onClick={onMaxBoundsReset}
                    disabled={false}
                    dataCy={maxResetButtonDataCy}
                />
            </Grid>
            <BoundsRow
                keys={maxKeys}
                keysToTitles={minMaxKeysToTitles}
                values={bounds}
                generateOnChange={generateOnBoundsChange}
                generateOnBlur={generateOnMaxBoundsBlur}
                placeholders={originalBounds}
                isRowDisabled={isBlocksMode}
                className={classes.boundsRow}
                textFieldClassName={classes.textField}
                validationResults={boundsValidationResult}
                increments={maxIncrements}
                getTextFieldBounds={generateBoundsBounds(stringsObjectToBoundsObject(bounds), referenceBounds)}
            />
        </Grid>
    );
}

export default GridBounds;
