/* Generic */
export const submitButtonDataCy = 'submit-button';
export const cancelButtonDataCy = 'cancel-button';
export const saveButtonDataCy = 'save-button';
export const errorLabelDataCy = 'error-label';
export const errorNotificationDataCy = 'error-notification';
export const topParentTreeDataCy = 'top-parent-tree';
export const nameSuffixTextboxDataCy = 'name-suffix-text-field';
export const globalDownloadAnchorDataCy = 'globalDownloadHiddenAnchor';
export const globalUploadInputDataCy = 'global-upload-input';
export const updateDrillingDialogDataCy = 'update-drilling-dialog';
export const alertSnackBarDataCy = 'alert-snack-bar';
export const tableSkeletonDataCy = 'table-skeleton';
export const skeletonDataCy = 'skeleton';
export const checkboxItemDataCy = 'checkbox-item';
export const searchBoxDataCy = 'search-box';
export const loadingContainerDataCy = 'loading-container';
export const NoAccessToOrgDialogDataCy = 'no-access-to-org-dialog';

/* Navbar */
export const userButtonDataCy = 'user-profile-button';
export const helpMenuItemDataCy = 'help-menu-item';

/* Upload drilling dialogs */
export const importGoDialogDataCy = 'import-go-dialog';
export const uploadDrillingDialogDataCy = 'upload-drilling-dialog';
export const xColumnDropdownDataCy = 'x-column-dropdown';
export const yColumnDropdownDataCy = 'y-column-dropdown';
export const zColumnDropdownDataCy = 'z-column-dropdown';
export const drillholeIdColumnDropdownDataCy = 'drillhole-id-column-dropdown';

/* Upload mesh dialog */
export const uploadMeshDialogDataCy = 'upload-mesh-dialog';

/* Grid dialog */
export const gridDialogDataCy = 'define-grid-dialog';

export const gridNameTextBoxDataCy = 'grid-name-textbox';

export const minXDataCy = 'minx-textbox';
export const minYDataCy = 'miny-textbox';
export const minZDataCy = 'minz-textbox';
export const maxXDataCy = 'maxx-textbox';
export const maxYDataCy = 'maxy-textbox';
export const maxZDataCy = 'maxz-textbox';

export const xBlockSizeDataCy = 'x-block-size-textbox';
export const yBlockSizeDataCy = 'y-block-size-textbox';
export const zBlockSizeDataCy = 'z-block-size-textbox';

export const xNumOfBlocksDataCy = 'xblocks-textbox';
export const yNumOfBlocksDataCy = 'yblocks-textbox';
export const zNumOfBlocksDataCy = 'zblocks-textbox';

export const coordinatesModeRadioDataCy = 'coordinates-mode-radio';
export const blocksModeRadioDataCy = 'blocks-mode-radio';

export const totalSizeInBlocksDataCy = 'total-blocks-size';
export const totalSizeInBlocksErrorLabelDataCy = 'total-blocks-size-error-label';

export const blocksSizeResetButtonDataCy = 'blocks-sizes-reset-button';
export const minResetButtonDataCy = 'min-reset-button';
export const maxResetButtonDataCy = 'max-reset-button';
export const blocksResetButtonDataCy = 'blocks-reset-button';

/* Meshes dialogs */
export const meshFromRegionDialogDataCy = 'box-from-region-dialog';
export const clipRegionDialogDataCy = 'clip-region-dialog';
export const openMeshesDropdownDataCy = 'open-meshes-dropdown';
export const aboveMeshNameDataCy = 'above-mesh-name';
export const belowMeshNameDataCy = 'below-mesh-name';

/* Anisotropy dialogs */
export const anisotropyEstimationDialogDataCy = 'anisotropy-estimation-dialog';
export const anisotropyGridDialogDataCy = 'anisotropy-grid-dialog';
export const anisotropyGlobalDialogDataCy = 'anisotropy-global-dialog';
export const drillingTreeDataCy = 'drilling-tree';
export const domainsTreeDataCy = 'domains-tree';
export const evaluateDataAboveFieldDataCy = 'evaluate-data-above-field';
export const meshBoundariesDropdownDataCy = 'mesh-boundaries-dropdown';
export const normalScoreTransformCheckboxDataCy = 'normal-score-transform-checkbox';
export const thresholdDropdownDataCy = 'threshold-dropdown';
export const epsilonDropdownDataCy = 'epsilon-dropdown';
export const thresholdTextboxDataCy = 'threshold-textbox';
export const epsilonTextboxDataCy = 'epsilon-textbox';
export const resolutionDropdownDataCy = 'resolution-dropdown';
export const outputFormatDropdownDataCy = 'output-format-dropdown';
export const uploadFileButtonDataCy = 'upload-file-button';
export const removeFileButtonDataCy = 'remove-file-button';
export const fileNameDataCy = 'file-name';
export const domainsDropdownDataCy = 'domains-dropdown';
export const xTextboxDataCy = 'x-textbox';
export const yTextboxDataCy = 'y-textbox';
export const zTextboxDataCy = 'z-textbox';
export const centersErrorLabelDataCy = 'centers-error-label';
export const evaluationText = 'evaluation-text';
export const emptyAnisotropiesMessageDataCy = 'empty-anisotropies-message';

/* Interpolations dialogs */
export const anisotropiesTreeDataCy = 'anisotropies-tree';
export const gridsTreeDataCy = 'grids-tree';
export const powerParameterDataCy = 'power-parameter-textbox';
export const mergedInterpolationDialogDataCy = 'merged-interpolations-dialog';
export const interpolationsTreeDataCy = 'interpolations-tree-data-cy';
export const drillAttributesDropdownDataCy = 'drill-attributes-dropdown';
export const gridsDropdownDataCy = 'grids-dropdown';
export const emptyPointEstimationsMessageDataCy = 'empty-point-estimations-message';

/* Zones dialogs */
export const zonesDialogDataCy = 'zones-dialog';
export const minConcentrationThresholdDataCy = 'min-concentration-threshold';
export const minSizeInBlocksTextboxDataCy = 'min-size-in-blocks';

/* Overlaps dialogs */
export const overlapsFolderDialogDataCy = 'overlap-folder-dialog';

/* Scene manager rows */
export const drillRowDataCy = 'drilling-row';
export const meshRowDataCy = 'mesh-row';
export const lvaRowDataCy = 'lva-row';
export const overlapRowDataCy = 'overlaps-row';

/* Scene manager */
export const rowIconDataCy = 'row-icon';
export const objectNameDataCy = 'object-name';
export const objectNameDropdownDataCy = 'object-name-dropdown';
export const opacityButtonDataCy = 'opacity-button';
export const downloadButtonDataCy = 'download-button';
export const anisotropyDisplayShapeIconButtonDataCy = 'anisotropy-display-shape-button';
export const colorPickerButtonDataCy = 'color-picker-button';
export const colorMapButtonDataCy = 'color-map-button';
export const visibilityButtonDataCy = 'visibility-button';
export const isVisibleIconDataCy = 'is-visibile-icon';
export const isInvisibleIconDataCy = 'is-invisibility-icon';
export const removeButtonDataCy = 'remove-button';
export const displayModeButtonDataCy = 'display-mode-button';
export const uvwButtonDataCy = 'uvw-button';
export const displayRangeButtonDataCy = 'display-range-button';
export const colorBarButtonDataCy = 'color-bar-button';
export const pointSizeButtonDataCy = 'point-size-button';
export const normalizeLvaRangesButtonDataCy = 'normalize-lva-ranges-button';
export const downloadingStatusContainerDataCy = 'downloading-status-container';
export const xyzPlotRow = 'xyz-plot-row';

export const opacityPopupDataCy = 'opacity-popup';
export const displayModePopupDataCy = 'display-mode-popup';
export const pointSizePopupDataCy = 'point-size-popup';

/* UVW popup */
export const uvwPopupDataCy = 'uvw-popup';
export const uPlanePanelDataCy = 'u-plane-panel';
export const vPlanePanelDataCy = 'v-plane-panel';
export const wPlanePanelDataCy = 'w-plane-panel';
export const uvwSliderDataCy = 'uvw-slider';
export const uSliderDataCy = 'u-slider';
export const vSliderDataCy = 'v-slider';
export const wSliderDataCy = 'w-slider';
export const uIncrementDataCy = 'u-increment';
export const uDecrementDataCy = 'u-decrement';
export const vIncrementDataCy = 'v-increment';
export const vDecrementDataCy = 'v-decrement';
export const wIncrementDataCy = 'w-increment';
export const wDecrementDataCy = 'w-decrement';
export const uMaxIncrementDataCy = 'u-max-increment';
export const uMaxDecrementDataCy = 'u-max-decrement';
export const vMaxIncrementDataCy = 'v-max-increment';
export const vMaxDecrementDataCy = 'v-max-decrement';
export const wMaxIncrementDataCy = 'w-max-increment';
export const wMaxDecrementDataCy = 'w-max-decrement';
export const incrementButtonDataCy = 'increment-button';
export const decrementButtonDataCy = 'decrement-button';
export const sliderMaxFineTunerDataCy = 'slider-max-fine-tuner';
export const sliderFineTunerDataCy = 'slider-fine-tuner';
export const uvwToggleButtonDataCy = 'uvw-toggle-button';
export const uToggleButtonDataCy = 'u-toggle-button';
export const vToggleButtonDataCy = 'v-toggle-button';
export const wToggleButtonDataCy = 'w-toggle-button';

/* Color map popup */
export const colorMapPopupDataCy = 'colorMapPopup';
export const colorMapMinInputDataCy = 'color-map-min-input';
export const colorMapMaxInputDataCy = 'color-map-max-input';
export const colorMapDropdownDataCy = 'color-map-dropdown';
export const resetButtonDataCy = 'reset-button';
export const colorMapBarDataCy = 'color-map-bar';

/* Display range popup */
export const displayRangePopupDataCy = 'display-range-popup';
export const minInputDataCy = 'min-input';
export const maxInputDataCy = 'max-input';
export const displayRangeSliderDataCy = 'display-range-slider';

/* Main tree */
export const projectTreeContainerDataCy = 'drawer';
export const expandButtonDataCy = 'expand-button';
export const moreButtonDataCy = 'more-button';
export const nodeLabelDataCy = 'node-label';
export const treeMenuDataCy = 'tree-menu';
export const secondaryMenuDataCy = 'secondary-menu';

export const downloadMenuItemDataCy = 'download';
export const addToViewDataCy = 'add-to-view';
export const deleteMenuItemDataCy = 'delete';
export const publishMenuItemDataCy = 'publish';
export const publishToLocalMenuItemDataCy = 'publish-to-local';
export const publishToEvoMenuItemDataCy = 'publish-to-evo';
export const publishStructuralDataToEvoDataCy = 'publish-structural-data-to-evo';
export const publishEllipsoidsToEvoDataCy = 'publish-ellipsoids-to-evo';
export const publishLineationDataToEvoDataCy = 'publish-lineation-data-to-evo';

export const toEvoDataCy = 'to-evo';
export const fromEvoDataCy = 'from-evo';
export const toLocalDataCy = 'to-local';
export const fromLocalDataCy = 'from-local';

export const setupSectionDataCy = 'setup';
export const meshesSectionDataCy = 'meshes';
export const drillingSectionDataCy = 'drilling';
export const anisotropySectionDataCy = 'anisotropy';
export const interpolationsSectionDataCy = 'interpolations';
export const zonesSectionDataCy = 'zones';
export const zonesFromAnisotropySectionDataCy = 'zones-from-anisotropy';
export const overlapsSectionDataCy = 'overlaps';

export const meshesSubsectionDataCy = 'meshes';
export const closedMeshesSubsectionDataCy = 'closed-meshes';
export const openMeshesSubsectionDataCy = 'open-meshes';
export const domainSamplesSectionDataCy = 'domain-samples';
export const gridsSubsectionDataCy = 'grid-root';
export const drillingSubsectionDataCy = 'drilling';
export const uploadDrillingSubsectionDataCy = 'upload-drilling-subsection';

export const uploadDrillingDataCy = 'upload-drilling';
export const uploadDrillingFromLocalDataCy = 'upload-drilling-from-local';
export const uploadDrillingFromEvoDataCy = 'upload-drilling-from-evo';
export const defineGridDataCy = 'define-grid';

export const uploadClosedMeshDataCy = 'upload-closed-mesh';
export const uploadClosedMeshFromLocalDataCy = 'upload-closed-mesh-from-local';
export const uploadClosedMeshFromEvoDataCy = 'upload-closed-mesh-from-evo';
export const uploadOpenMeshDataCy = 'upload-open-mesh';
export const uploadOpenMeshFromLocalDataCy = 'upload-open-mesh-from-local';
export const uploadOpenMeshFromEvoDataCy = 'upload-open-mesh-from-evo';

export const clipRegionWithSurfaceDataCy = 'clip-region-with-surface';
export const meshFromRegionDataCy = 'mesh-from-region';

export const createAnisotropyDataCy = 'new-anisotropy';
export const createAnisotropyGridDataCy = 'new-anisotropy-grid';
export const createAnisotropyGlobalDataCy = 'new-anisotropy-global';
export const createGlobalEstimationDataCy = 'new-global-estimation';
export const createLocalEstimationDataCy = 'new-local-estimation';
export const anisotropySmoothingDataCy = 'new-anisotropy-smoothing';
export const isotropicModelDataCy = 'new-isotropic-model';

export const inverseDistanceDataCy = 'new-inverse-distance';
export const ordinaryKrigingDataCy = 'new-ordinary-kriging';
export const mergedInterpolationDataCy = 'merged-interpolation';
export const mergedInterpolationsDomainDataCy = 'merged-interpolations-domain';

export const autoGenerateZonesDataCy = 'auto-generate-cluster-analysis';
export const zonesUsingCutoffsDataCy = 'cluster-analysis-using-cutoffs';
export const newZonesDataCy = 'new-zone-button';

export const newOverlapFolderDataCy = 'new-overlap-folder';
export const createOverlapsDataCy = 'create-overlaps';
export const renameOverlapsFolderDataCy = 'rename-folder';

export const objectsToDeleteDataCy = 'objects-to-delete';
export const dependantsToDeleteDataCy = 'dependants-to-delete';

export const searchProjectsTreeTextFieldDataCy = 'search-projects-tree-text-field';

/* Info square / spotlight */
export const objectStatusDialogDataCy = 'object-status-dialog';
export const spotlightButtonDataCy = 'spotlight-button';
export const spotlightWindowDataCy = 'spotlight-window';
export const historyDropdownDataCy = 'history-dropdown';

/* Anisotropy centers table in spotlight */
export const indexDataCy = 'index-value';
export const xDataCy = 'x-value';
export const yDataCy = 'y-value';
export const zDataCy = 'z-value';
export const errorDataCy = 'error-value';
export const drillholeSupportDataCy = 'drillhole-support-value';
export const ellipticityDataCy = 'ellipticity-value';
export const enabledDataCy = 'enabled-value';
export const centersTableDataCy = 'centers-table';
export const centersTableSectionDataCy = 'centers-table-section';

/* Anisotropy stereonet in spotlight */
export const stereonetSectionDataCy = 'stereonet-section';
export const stereonetSkeletonDataCy = 'stereonet-skeleton';
export const stereonetDataCy = 'stereonet';
export const stereonetTypeDropdownDataCy = 'stereonet-type-dropdown';

/* Anisotropy stereonet checboxes in spotlight */
export const lineationsCheckboxDataCy = 'lineations-checkbox';
export const planarCheckboxDataCy = 'planar-checkbox';

/* Anisotropy resample section */
export const smoothingSliderDataCy = 'smoothing-slider';
export const resampleSectionDataCy = 'resample-section';

/* Anisotropy cluster section */
export const clusterSliderDataCy = 'cluster-slider';
export const clusterSectionDataCy = 'cluster-section';

/* Xyz Plot */
export const xyzPlotWindowDataCy = 'xyz-plot-window';
export const xyzPlotInitializedStatusDataCy = 'xyz-plot-initialed';
export const plotContainerDataCy = 'plot-container';

/* Xyz Overlay */
export const xyzOrientationOverlayDataCy = 'xyz-orientation-overlay';

/* Workspaces */
export const SelectWorkspaceDialogDataCy = 'select-workspace-dialog';

/* Login screen */
export const loginButtonDataCy = 'login-button';
export const selectButtonDataCy = 'select-button';
export const orgsDropdownDataCy = 'orgs-dropdown';
export const hubsDropdownDataCy = 'hubs-dropdown';
export const saveSelectionCheckboxDataCy = 'save-selection-checkbox';

/* Projects */
export const createNewProjectDataCy = 'create-new-project-button';
export const projectsDialogDataCy = 'projects-dialog';
export const projectNameTextboxDataCy = 'new-project-name-textbox';
export const projectDescriptionTextboxDataCy = 'new-project-description-textbox';
export const cloneProjectDialogDataCy = 'clone-project-dialog';
export const createProjectDialogDataCy = 'create-project-dialog';
export const editProjectDialogDataCy = 'edit-project-dialog';
export const deleteProjectDialogDataCy = 'delete-project-dialog';
export const projectCardActionAreaDataCy = 'project-card-action-area';
export const progressIndicatorDataCy = 'circularProgressSpinner';
export const circularProgressSpinnerWithLabelDataCy = 'circularProgressSpinnerWithLabel';

/* Icons */
export const drillingIconDataCy = 'drilling-icon';
export const closedMeshIconDataCy = 'closed-mesh-icon';
export const openMeshIconDataCy = 'open-mesh-icon';
export const gridIconDataCy = 'grid-icon';
export const anisotropyIconDataCy = 'anisotropy-icon';
export const anisotropyGlobalIconDataCy = 'anisotropy-global-icon';
export const anisotropyGridIconDataCy = 'anisotropy-grid-icon';
export const interpolationIconDataCy = 'interpolation-icon';
export const zoneIconDataCy = 'zone-icon';
export const overlapIconDataCy = 'overlap-icon';
export const addToViewIconDataCy = 'add-to-view-icon';
export const closeButtonDataCy = 'close-button-datacy';
export const discAnisotropyDataCy = 'disc-anisotropy';

/* Menu Icons */
export const createObjectMenuIconDataCy = 'create-object-menu-icon';
export const deleteObjectMenuIconDataCy = 'delete-object-menu-icon';
export const downloadObjectMenuIconDataCy = 'download-object-menu-icon';
export const publishObjectMenuIconDataCy = 'publish-object-menu-icon';
export const plusAddToViewIconDataCy = 'plus-add-to-view-icon';
export const uploadFileMenuIconDataCy = 'upload-file-menu-icon';
export const structuralDataMenuIconDataCy = 'structural-data-menu-icon';
export const ellipsoidsMenuIconDataCy = 'ellipsoids-menu-icon';
export const evoMenuIconDataCy = 'evo-menu-icon';
export const fileMenuIconDataCy = 'file-menu-icon';

/* Empty Project State images */
export const emptyStateArrowImageDataCy = 'empty-state-arrow-image';
export const emptyStateLayersImageDataCy = 'empty-state-layers-image';

/* Progress tray */
export const progressTrayBadgeDataCy = 'badge';
export const progressTrayButtonDataCy = 'progress-tray-button';
export const progressTrayJobCancelButtonDataCy = 'progress-tray-job-cancel-button';

/* Zones from anisotropy */
export const zonesFromAnisotropyDataCy = 'zones-from-anisotropy-button';
export const zonesFromAnisotropyDialogDataCy = 'zones-from-anisotropy-dialog';
