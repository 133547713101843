import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { PointEstimation, PointEstimationType } from 'App/util/ProjectDataTypes/PointEstimation';
import { ProjectObjectIdentifier } from 'App/util/ProjectDataTypes/ProjectObjectIdentifier';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { BlockModelTrace, BlockModelTraceSnapshot } from 'Common/Xyz/XyzTraces/BlockModelTrace';
import { InValidateAllColorValueSummaries, XyzTraceClassNames, XyzTraceId } from 'Common/Xyz/XyzTraces/XyzTrace';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';

// const COLOR_ATTRIBUTE_ID2DISPLAY_NAME = {
//     drillhole_support: 'drillhole support',
//     misclassification_score: 'error rate',
//     enabled: 'enabled',
// };

function getplotName(interpolation: PointEstimation, colorAttribute?: string) {
    const domainName = interpolation.getDomainName();
    const basicName = `${interpolation.name} D[${domainName}]`;
    if (colorAttribute) {
        return `${basicName} - ${colorAttribute}`;
    }
    return basicName;
}

function createInitialBlockModelSnapshot(
    xyzTraceId: string,
    interpolationObject: PointEstimationType,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string
): BlockModelTraceSnapshot {
    const interpolation = wrapObjectInClass(interpolationObject, undefined, objectClassName2id2obj) as PointEstimation;

    const blockModelAttributeTraces = {} as BlockModelTraceSnapshot['colorAttributeTraces'];

    const projectObjectIdentifier = new ProjectObjectIdentifier({
        objectClassName: interpolationObject.object_class_name,
        id: interpolationObject.id,
    });
    const jobUrl = projectObjectIdentifier.getObjectUrl(currentOrgUuid, objectClassName2id2obj);

    const basePlotName = getplotName(interpolation);
    blockModelAttributeTraces[basePlotName] = {
        colorAttributeName: basePlotName,
        useSingleColor: false,
        colorValueSummariesValid: false,
        colorValueSummaries: undefined,
        colorArrayUrl: `${jobUrl}?download_bytes_array=concentration`,
    };

    if (
        interpolationObject.object_class_name === OBJECT_CLASS_NAMES.PointEstimation &&
        interpolationObject.parameters.pointEstimation_method === 'OK'
    ) {
        const plotName = getplotName(interpolation, 'variance');
        blockModelAttributeTraces[plotName] = {
            colorAttributeName: plotName,
            useSingleColor: false,
            colorValueSummariesValid: false,
            colorValueSummaries: undefined,
            colorArrayUrl: `${jobUrl}?download_bytes_array=variance`,
        };
    }

    const blockSize = interpolation.getBlockSize();
    const blockCount = interpolation.getBlockCount();
    const origin = interpolation.getOrigin();

    return {
        id: xyzTraceId,
        className: XyzTraceClassNames.BlockModelTrace,
        colorAttributeTraces: blockModelAttributeTraces,
        enabled: true,
        visible: true,
        projectObjectIdentifier: {
            objectClassName: interpolationObject.object_class_name,
            id: interpolationObject.id,
        },
        selectedColorAttribute: basePlotName,
        objectHash: interpolationObject.updated_at,
        blockSize: blockSize,
        blockCount: blockCount,
        origin: origin,
        blockSizeUrl: undefined,
        blockCountUrl: `${jobUrl}?result_meta_data_key=subblock_block_count`,
        originUrl: `${jobUrl}?result_meta_data_key=subblock_origin`,
    };
}

async function createInitialXyzBlockModelTrace(
    xyzTraceId: string,
    dispatch: AppDispatch,
    interpolationObject: PointEstimationType,
    xyz: UseXyz,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    tokenProvider: () => Promise<string>
) {
    const initialLvaSnapshot = createInitialBlockModelSnapshot(
        xyzTraceId,
        interpolationObject,
        objectClassName2id2obj,
        currentOrgUuid
    );

    const blockModelTrace = new BlockModelTrace(xyz, initialLvaSnapshot, tokenProvider);
    await dispatch(blockModelTrace.plotAndSave());
}

export function plotBlockModelObject(
    interpolationObject: PointEstimationType,
    xyzTraceId: XyzTraceId,
    xyz: UseXyz,
    currentOrgUuid: string,
    tokenProvider: () => Promise<string>
) {
    return async function plotLvaObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshot = selectXyzTrace(state, xyzTraceId);
        if (xyzTraceSnapshot) {
            const blockModelTrace = new BlockModelTrace(
                xyz,
                xyzTraceSnapshot as BlockModelTraceSnapshot,
                tokenProvider
            );
            await blockModelTrace.incrementObjectHash(dispatch, true, InValidateAllColorValueSummaries.No); // if the object was already enabled, we will increment the objectHash to force redownload of the data.
        } else {
            const objectClassName2id2obj = selectObjectClassname2Id2Obj(state);
            await createInitialXyzBlockModelTrace(
                xyzTraceId,
                dispatch,
                interpolationObject,
                xyz,
                objectClassName2id2obj,
                currentOrgUuid,
                tokenProvider
            );
        }
    };
}
