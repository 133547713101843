import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { APIResponseWithProjectUpdate } from 'App/util/ProjectDataTypes/APIResponseTypes';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { AxiosProgressEvent } from 'axios';

const drillholeIdColumns = ['drilling_id', 'drill_id', 'drill_ID', 'hole_id'];
const drillholeIdColumnsSubstrings = ['drill', 'hole', 'bh'];

const xColumns = ['mid_x', 'x', 'start_x'];
const xColumnsSubstrings = ['_x', 'x_'];

const yColumns = ['mid_y', 'y', 'start_y'];
const yColumnsSubstrings = ['_y', 'y_'];

const zColumns = ['mid_z', 'z', 'start_z'];
const zColumnsSubstrings = ['_z', 'z_'];

export function findClosestColumnName(columnNames: string[], defaults: string[], likeDefaults: string[]) {
    let name: string;

    name = columnNames.find((columnName) => {
        return defaults.map((name) => name.toLowerCase()).includes(columnName.toLowerCase());
    });

    if (!name) {
        name = columnNames.find((columnName) => {
            return likeDefaults.find((substring) => {
                return columnName.toLowerCase().includes(substring.toLowerCase());
            });
        });
    }

    return name;
}

export function findDrillholeIdColumnName(columns: string[]) {
    return findClosestColumnName(columns, drillholeIdColumns, drillholeIdColumnsSubstrings);
}

export function findXColumnName(columns: string[]) {
    return findClosestColumnName(columns, xColumns, xColumnsSubstrings);
}

export function findYColumnName(columns: string[]) {
    return findClosestColumnName(columns, yColumns, yColumnsSubstrings);
}

export function findZColumnName(columns: string[]) {
    return findClosestColumnName(columns, zColumns, zColumnsSubstrings);
}

const unitsSubstrings = ['pct', 'ppb', 'ppm', 'unit', 'gpt', 'mol', 'gpl'];

export function findAttributesColumnNames(columns: string[]) {
    return columns.filter((column) => {
        return !!unitsSubstrings.find((substring) => {
            return column.toLowerCase().includes(substring);
        });
    });
}

export function parseDrillingFile(
    file: File,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    projectUrl: string,
    unCanceledRequestsRef: React.MutableRefObject<symbol[]>,
    setProgress: (progress: number | boolean) => void
) {
    const formData = new FormData();
    formData.append('file', file);

    const requestId = Symbol();
    unCanceledRequestsRef.current.push(requestId);

    return axiosDriverFlask.post<{
        columns: string[];
    }>(`${projectUrl}/util/parse_csv`, formData, {
        withCredentials: true,
        responseType: 'json',
        onUploadProgress(progressEvent: AxiosProgressEvent) {
            if (progressEvent.total !== 0) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            } else {
                setProgress(false);
            }
        },
    });
}

export const expandDrillNodes = (onExpandChange: (nodes: ObjectIDType[], expand: boolean) => void) => {
    const drillSectionId = SectionIds[SectionNames.Drilling];
    const meshSectionId = SectionIds[SectionNames.Meshes];
    const closedMeshSectionId = SectionIds[SectionNames.ClosedMeshes];

    const nodesToExpand = [drillSectionId, meshSectionId, closedMeshSectionId];

    onExpandChange(nodesToExpand, true);
};

type DrillingFileData = {
    dataAttributes: string[];
    xColumn: string;
    yColumn: string;
    zColumn: string;
    drillholeIdColumn: string;
    fileName: string;
    file: File;
    isGoose: boolean;
    gooseObjectId?: ObjectIDType;
};

export function uploadDrilling(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    data: DrillingFileData,
    url: string,
    updateExisting: boolean
) {
    const metadata = {
        dataAttributes: data.dataAttributes,
        xyzColumns: {
            x: data.xColumn,
            y: data.yColumn,
            z: data.zColumn,
            holeid: data.drillholeIdColumn,
        },
    };

    const formData = new FormData();

    formData.append('meta_data', JSON.stringify(metadata));
    formData.append('type', 'drill');
    formData.append('file_name', data.fileName);

    if (data.isGoose) {
        formData.append('goose_object', `${data.isGoose}`);
        formData.append('goose_object_id', data.gooseObjectId);
    } else {
        formData.append('file', data.file);
    }

    if (updateExisting) {
        return axiosDriverFlask.put<APIResponseWithProjectUpdate>(url, formData, {
            withCredentials: true,
            responseType: 'json',
        });
    } else {
        return axiosDriverFlask.post<APIResponseWithProjectUpdate>(url, formData, {
            withCredentials: true,
            responseType: 'json',
        });
    }
}
