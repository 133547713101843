import { FolderIcon } from '@local/web-design-system/dist/icons';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { ProjectTreeNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import {
    FailedProjectObjectStatusTypes,
    OBJECT_CLASS_NAMES,
    ProjectObject,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import FailedStatusIcon, { DEFAULT_ERROR_MESSAGE } from 'Common/components/icons/FailedStatusIcon';
import UploadFileMenuIcon from 'Common/components/icons/menuIcons/UploadFileMenuIcon';
import { getObjectIcon } from 'Common/utils/objectIconUtils';
import { ReactElement } from 'react';

export default function NodeIcon(props: { projectObject: ProjectObject; node: ProjectTreeNode }) {
    let Icon: null | ReactElement = null;

    if (props.node.iconOverride) {
        Icon = <props.node.iconOverride />;
    } else if (props.node.nodeId === SectionIds[SectionNames.UploadDrilling]) {
        Icon = <UploadFileMenuIcon />;
    } else if (props.projectObject) {
        if (FailedProjectObjectStatusTypes.includes(props.projectObject.status)) {
            Icon = (
                <FailedStatusIcon
                    errorMessage={`Failed status: ${
                        props.projectObject.error_message_for_users || DEFAULT_ERROR_MESSAGE
                    }`}
                />
            );
        } else if (props.projectObject.object_class_name === OBJECT_CLASS_NAMES.OverlapFolder) {
            Icon = <FolderIcon />;
        } else {
            const IconClass = getObjectIcon(props.projectObject);
            Icon = <IconClass />;
        }
    } else {
        Icon = <FolderIcon />;
    }

    return Icon;
}
