import { getHubUrl } from './api/AuthUtils';

export function jobUrl2BackendUrl(currentOrgUuid: string, jobUrl: string) {
    const hubUrl = getHubUrl();
    if (hubUrl === '/') {
        return jobUrl;
    }
    return hubUrl + jobUrl;
}

export function urlWithParams(urlStr: string, params: { [key: string]: string }) {
    let ret = urlStr;
    Object.keys(params).forEach((keyOriginal) => {
        const key = String(keyOriginal);
        const val = String(params[key]);

        if (val === '' || key === '') {
            return;
        }
        if (ret.includes('?')) {
            if (ret[ret.length - 1] === '?') {
                ret += `${key}=${val}`;
                return;
            }
            ret += `&${key}=${val}`;
            return;
        }
        ret += `?${key}=${val}`;
    });

    return ret;
}
