import { FieldDefinition } from '@local/web-design-system/dist/components/GenericListing';
import Grid from '@mui/material/Grid';
import { tss } from 'tss-react/mui';
import React, { ReactNode } from 'react';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import ListView from 'Common/components/List/ListView';
import { FilterSearch } from '@local/web-design-system/dist/components/FilterSearch';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { searchBoxDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import TileView from './TileView';

export const useStyles = tss.create(({ theme }) => ({
    header: {
        paddingTop: `${theme.spacing(3)}`,
        paddingBottom: `${theme.spacing(3)}`,
        justifyContent: 'stretch',
    },
    titleContainer: {
        flex: '1 1 30%',
    },
    searchContainer: {
        justifyContent: 'flex-end',
        flex: '1 1 60%',
    },
    list: {
        padding: `${theme.spacing(3)} 0`,
    },
}));

export enum ListViewModes {
    list = 'list',
    tile = 'tile',
}

export default function List(props: {
    items: any[];
    listFields: FieldDefinition[];
    isLoading?: boolean;
    isError?: boolean;
    selectedItem?: ObjectIDType;
    onItemClick: (itemId: ObjectIDType) => void;
    noItemsMessage: string;
    searchPlaceholder?: string;
    filterByKey?: string; // by what item field to filter using search textbox
    title?: string;
    titleComponent?: ReactNode;
    className?: string;
    headerPaddingX?: number;
    viewMode: ListViewModes;
}) {
    const { classes } = useStyles();

    const [searchTerm, setSearchTerm] = React.useState('');

    const filteredItems = props.items?.filter((item) =>
        item[props.filterByKey || 'name'].toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Grid container direction="column" className={props.className}>
            <Grid item container className={classes.header} alignItems="center" sx={{ paddingX: props.headerPaddingX }}>
                {props.title && (
                    <Grid container item className={classes.titleContainer}>
                        <Typography variant="h3">{props.title}</Typography>
                    </Grid>
                )}
                {props.titleComponent}
                <Grid container item className={classes.searchContainer}>
                    <Grid item>
                        <FilterSearch
                            searchBarOnly
                            disabled={props.isLoading}
                            onSearchCallBack={setSearchTerm}
                            searchPlaceHolderValue={props.searchPlaceholder || 'Search'}
                            data-cy={searchBoxDataCy}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            {props.viewMode === ListViewModes.list && (
                <Grid className={classes.list} container direction="column">
                    <ListView
                        items={filteredItems}
                        fields={props.listFields}
                        onItemClick={props.onItemClick}
                        noItemsMessage={props.noItemsMessage}
                        selectedItem={props.selectedItem}
                        isLoading={props.isLoading}
                    />
                </Grid>
            )}
            {props.viewMode === ListViewModes.tile && (
                <Grid container item>
                    <TileView
                        items={filteredItems}
                        onItemClick={props.onItemClick}
                        noItemsMessage={props.noItemsMessage}
                        selectedItem={props.selectedItem}
                        isLoading={props.isLoading}
                        isError={props.isError}
                    />
                </Grid>
            )}
        </Grid>
    );
}
