import { getOverlapObjectsError } from 'App/MainApp/Dialogs/OverlapsPanel/OverlapPanelUtils';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { ObjectIDType } from './ProjectObjectsDataTypes';

export interface UnsavedOverlapType extends BackendJsonifiedProjectObject {
    parentObjIds: string[];

    gridDefinitionId: ObjectIDType;

    overlapFolderId: ObjectIDType;
}

export class UnsavedOverlap extends BaseProjectObject implements UnsavedOverlapType {
    parentObjIds: string[];

    gridDefinitionId: ObjectIDType;

    overlapFolderId: ObjectIDType;

    getName = () => {
        const overlapNameList = [];
        this.parentObjIds.forEach((obj) => {
            switch (obj[0]) {
                case 'z':
                    const zoneId = obj.substring(2);
                    overlapNameList.push(this.projectObjects.getZone(zoneId).dataAttribute_in_drill);
                    break;
                case 'd':
                    const domainGridDefinitionId = obj.substring(2);
                    overlapNameList.push(this.projectObjects.getDomainGrid(domainGridDefinitionId).getName());
                    break;
                default:
                    throw new Error(getOverlapObjectsError(obj));
            }
        });
        return overlapNameList.join(', ');
    };

    getOverlapFolder = () => this.projectObjects.getOverlapFolder(this.overlapFolderId);

    getPlotDataUrl = (currentOrgUuid: string) =>
        `${this.getOverlapFolder().getFullBackendUrl(currentOrgUuid)}/overlapsPlotData?overlaps[]=${[
            JSON.stringify(this.parentObjIds),
        ]}&gridDefinitionId=${this.gridDefinitionId}`;
}
