import { DomainGridDefinitionType } from 'App/util/ProjectDataTypes/DomainGridDefinition';
import { DrillingAttributeType } from 'App/util/ProjectDataTypes/SourceFile';

export function getDomainGridDefinitionGridId(domainGrid: DomainGridDefinitionType) {
    return domainGrid.gridDefinitionId;
}

export function getMinMaxDataAttributeConcentration(drillingAttribute: DrillingAttributeType): {
    min: number;
    max: number;
} {
    const minConcentration = drillingAttribute.hist_first_two_bin_edges[0];
    const concentrationDelta =
        drillingAttribute.hist_first_two_bin_edges[1] - drillingAttribute.hist_first_two_bin_edges[0];
    const maxEdge = minConcentration + concentrationDelta * drillingAttribute.hist_NUM_BINS; // not necessarily the actual max concentration, but >=

    return {
        min: minConcentration,
        max: maxEdge,
    };
}
