import { ProjectObject } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { PublishToEvoFormats } from 'App/util/projectObjectsUtils.ts/objectUtils';

export async function publishObject(
    projectObject: ProjectObject,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentOrgUuid: string,
    workspaceId: string,
    publishObjectFormat?: PublishToEvoFormats
) {
    const formData = new FormData();
    formData.append('object_class_name', projectObject.object_class_name);
    formData.append('publish_object_format', publishObjectFormat);

    return axiosDriverFlask.post(
        `/orgs/${currentOrgUuid}/workspaces/${workspaceId}/goose/${projectObject.id}`,
        formData,
        {
            responseType: 'json',
            withCredentials: true,
        }
    );
}
