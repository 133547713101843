import { EvoDiscoveryResponse, EvoHub, EvoOrg, EvoServiceAccess } from 'Common/api/EvoApi';
import { Buffer } from 'buffer'; // fix for webpack 5

interface SessionUser {
    uuid: string;
    name: string;
    email: string;
}

export interface SessionOrganization {
    uuid: string;
    name: string;
    hubUrl: string;
}

interface SessionOAuth {
    accessToken: string;
    refreshToken: string;
    tokenType: string;
    expiresAt: number; // time in ms
    issuedAt: number;
}

export interface Session {
    oauth: SessionOAuth;

    user: SessionUser;

    organizations: EvoOrg[];

    serviceAccesses: EvoServiceAccess[];

    hubs: EvoHub[];
}

export function setAuthSessionOauthAndUser(oauth: SessionOAuth, user: SessionUser) {
    const session = getSession() ?? ({} as Session);
    session.oauth = oauth;
    session.user = user;
    setAuthSession(session);
}

export function setAuthSessionOrgsAndHubAccesses(discovery: EvoDiscoveryResponse) {
    const session = getSession() ?? ({} as Session);
    session.organizations = discovery?.organizations;
    session.serviceAccesses = discovery?.service_access;
    session.hubs = discovery?.hubs;
    setAuthSession(session);
}

export function clearAuthSessionOrgsAndHubAccesses() {
    const session = getSession() ?? ({} as Session);
    session.organizations = [];
    session.serviceAccesses = [];
    session.hubs = [];
    setAuthSession(session);
}

const EVO_DRIVER_SESSION_KEY = '__evo__driver__session';

const setAuthSession = (session: Session) => {
    localStorage.setItem(EVO_DRIVER_SESSION_KEY, Buffer.from(JSON.stringify(session)).toString('base64'));
};

export function hasSessionChanged(event: StorageEvent) {
    return event.key === EVO_DRIVER_SESSION_KEY;
}

export const getSession = (mockLocalStorage?: object): Session | undefined => {
    let session: Session;

    let sessionString: string;

    if (mockLocalStorage) {
        sessionString = `${mockLocalStorage?.[EVO_DRIVER_SESSION_KEY]}`;
    } else {
        sessionString = localStorage.getItem(EVO_DRIVER_SESSION_KEY);
    }

    try {
        session = JSON.parse(Buffer.from(sessionString, 'base64')?.toString('utf8'));
    } catch (e) {
        return undefined;
    }

    if (!session) {
        // eslint-disable-next-line no-console
        console.warn('null/undefined session');
        return;
    }

    return session;
};

export function getCurrentUserInfo() {
    const session = getSession();

    if (session) {
        return session.user;
    } else {
        return null;
    }
}

export function getAccessToken() {
    const session = getSession();
    return session?.oauth.accessToken;
}

global.getSession = getSession;
global.corruptAccessToken = () => {
    const session = getSession();
    if (session?.oauth.accessToken) {
        session.oauth.expiresAt = Date.now() - 1;
        session.oauth.accessToken = 'IntentionallyCorruptedAccessToken';
        setAuthSession(session);
    }
    // eslint-disable-next-line no-console
    console.log('session', session);
};
global.corruptAccessAndRefreshToken = () => {
    const session = getSession();
    if (session?.oauth.accessToken) {
        session.oauth.expiresAt = Date.now() - 1;
        session.oauth.accessToken = 'IntentionallyCorruptedAccessToken';
        session.oauth.refreshToken = 'IntentionallyCorruptedRefreshToken';
        setAuthSession(session);
    }
    // eslint-disable-next-line no-console
    console.log('session', session);
};

global.deleteAccessToAllOrgs = () => {
    const session = getSession();
    if (session?.organizations) {
        session.organizations = [];
        setAuthSession(session);
    }
    // eslint-disable-next-line no-console
    console.log('session', session);
};

export const clearAuthSession = () => {
    // dispatch(closeProject()) // TODO(Ali): how to close project
    localStorage.removeItem(EVO_DRIVER_SESSION_KEY);
};

export const getAuthorizationToken = (): string | undefined => {
    const session = getSession();
    return session?.oauth?.accessToken;
};
