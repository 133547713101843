import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function LineationIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className}>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.65527 24.625C1.68941 24.7492 1.69699 24.8752 1.75061 24.9975L14.8 51.8007C15.4174 53.2126 16.7659 53.6682 17.9597 53.4104L11.4973 27.1931C8.54033 27.9073 3.48841 25.39 1.65527 24.625Z"
                    fill="#E7EFF4"
                />
                <path
                    d="M9.73474 20.042C5.88166 21.0149 3.68244 23.0992 3.92091 24.0666C4.15935 25.0339 7.0753 25.8574 10.9391 24.9282L10.3369 22.4851L9.73474 20.042Z"
                    fill="#C2D8E3"
                />
                <path
                    d="M9.81846 20.0175C9.78916 20.0247 9.76466 20.0348 9.73535 20.0421L10.3375 22.4851L10.9397 24.9282C10.969 24.921 10.9954 24.9186 11.0248 24.9114C14.9319 23.9483 17.1619 21.8368 16.9217 20.8622C16.6815 19.8876 13.7256 19.0544 9.81846 20.0175Z"
                    fill="#E7EFF4"
                />
                <path
                    d="M11.5832 27.1768C11.5544 27.1839 11.5269 27.1863 11.498 27.1931L17.9604 53.4104C19.2117 53.1406 20.293 52.0864 20.1786 50.4751L19.2761 20.6784C19.2667 20.5449 19.2148 20.4298 19.1873 20.3037C17.9071 21.8484 14.5744 26.4395 11.5832 27.1768Z"
                    fill="#C2D8E3"
                />
                <path
                    d="M11.5826 27.1768C6.58473 28.4088 2.31769 27.312 1.65538 24.625C0.993058 21.9381 4.26155 18.9839 9.25943 17.7519C14.2573 16.52 18.5243 17.6168 19.1866 20.3037C19.849 22.9907 16.5804 25.9449 11.5826 27.1768ZM9.81786 20.0174C5.91072 20.9805 3.68069 23.092 3.9209 24.0666C4.16112 25.0412 7.11701 25.8744 11.0242 24.9113C14.9313 23.9482 17.1613 21.8367 16.9211 20.8621C16.6809 19.8876 13.725 19.0544 9.81786 20.0174Z"
                    fill="#265C7F"
                />
                <path
                    d="M21.9463 17.6476C22.0626 17.7028 22.163 17.7794 22.2907 17.8187L51.1111 25.4397C52.5836 25.8943 53.8092 25.1705 54.3935 24.0981L30.3185 11.8711C28.9283 14.5769 23.7227 16.7589 21.9463 17.6476Z"
                    fill="#E7EFF4"
                />
                <path
                    d="M23.7522 8.53613C21.9728 12.0895 22.1151 15.1161 23.0034 15.5673C23.8918 16.0184 26.4196 14.3479 28.2391 10.8149L25.9957 9.67552L23.7522 8.53613Z"
                    fill="#C2D8E3"
                />
                <path
                    d="M23.7876 8.45709C23.7739 8.48402 23.7656 8.50917 23.752 8.5361L25.9954 9.67547L28.2389 10.8148C28.2525 10.7879 28.268 10.7663 28.2816 10.7394C30.1038 7.15155 29.961 4.0838 29.0661 3.62929C28.1712 3.17478 25.6098 4.8692 23.7876 8.45709Z"
                    fill="#E7EFF4"
                />
                <path
                    d="M30.3624 11.796C30.349 11.8224 30.3328 11.8448 30.3191 11.8711L54.3942 24.0981C55.007 22.9743 54.9149 21.4669 53.6198 20.5013L30.4649 1.72625C30.3576 1.64622 30.2365 1.61031 30.123 1.54883C30.4569 3.52712 31.7574 9.04919 30.3624 11.796Z"
                    fill="#C2D8E3"
                />
                <path
                    d="M21.6318 31.4035C21.6991 31.4721 21.7497 31.5516 21.829 31.612L40.0565 44.3757C40.971 45.0735 42.0161 44.8822 42.7017 44.2814L28.9853 29.5134C27.3143 31.0509 23.1053 31.2385 21.6318 31.4035Z"
                    fill="#E7EFF4"
                />
                <path
                    d="M25.2442 25.4854C23.0821 27.5164 22.4021 29.6734 22.9082 30.2183C23.4144 30.7632 25.6156 30.2441 27.8005 28.2377L26.5223 26.8615L25.2442 25.4854Z"
                    fill="#C2D8E3"
                />
                <path
                    d="M25.2895 25.4393C25.273 25.4546 25.2607 25.4701 25.2441 25.4854L26.5223 26.8616L27.8005 28.2377C27.817 28.2224 27.8334 28.2113 27.8499 28.1959C30.0507 26.1518 30.741 23.9658 30.2311 23.4169C29.7212 22.8679 27.4903 23.3951 25.2895 25.4393Z"
                    fill="#E7EFF4"
                />
                <path
                    d="M29.035 29.472C29.0188 29.487 29.0017 29.4986 28.9854 29.5135L42.7018 44.2814C43.4207 43.6521 43.7444 42.5723 43.0859 41.5621L31.7009 22.4438C31.6464 22.3601 31.5708 22.3038 31.5071 22.2314C31.2313 23.7034 30.72 27.907 29.035 29.472Z"
                    fill="#C2D8E3"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.0647 44.3639C40.9791 45.0616 42.0242 44.8703 42.7097 44.2694C43.4286 43.6401 43.7523 42.5602 43.0938 41.5504L31.7088 22.4318C31.674 22.3787 31.6311 22.3366 31.5879 22.2944C31.5746 22.2814 31.5613 22.2684 31.5482 22.255L31.5159 22.2195C31.5158 22.2195 31.5159 22.2196 31.5159 22.2195C30.11 20.7062 26.928 21.5362 24.1127 24.151C21.2976 26.7657 20.2348 29.8778 21.6405 31.3914C21.6404 31.3914 21.6405 31.3915 21.6405 31.3914C21.6614 31.4129 21.6803 31.4353 21.6993 31.4578C21.7026 31.4617 21.7059 31.4657 21.7093 31.4696C21.7481 31.5158 21.7869 31.5618 21.8372 31.6L40.0647 44.3639ZM22.9167 30.2063C22.9167 30.2063 22.9167 30.2063 22.9167 30.2063C22.9571 30.2499 23.0084 30.2866 23.0695 30.3164C23.0722 30.3177 23.0749 30.319 23.0777 30.3203C23.3053 30.4272 23.6661 30.4401 24.1172 30.3481C25.089 30.1498 26.4795 29.4644 27.8583 28.1838C29.2366 26.9038 30.0225 25.568 30.2922 24.6135C30.4179 24.1685 30.4315 23.8064 30.3406 23.5714C30.3403 23.5707 30.3409 23.5721 30.3406 23.5714C30.3279 23.5388 30.3124 23.5067 30.2956 23.4792C30.2825 23.4575 30.2681 23.4375 30.2525 23.4191C30.2491 23.4151 30.2456 23.4111 30.2421 23.4072C30.2413 23.4064 30.2405 23.4055 30.2397 23.4047C30.2397 23.4047 30.2398 23.4047 30.2397 23.4047C30.2375 23.4023 30.2352 23.3999 30.233 23.3976C30.2151 23.3792 30.1952 23.362 30.1734 23.3461C29.5585 22.8985 27.4144 23.4615 25.2979 25.4272C23.1824 27.3922 22.4626 29.4882 22.8629 30.1353C22.8711 30.1485 22.8797 30.1611 22.8888 30.1731C22.8944 30.1805 22.9002 30.1877 22.9062 30.1946C22.9096 30.1986 22.9132 30.2025 22.9167 30.2063ZM29.4463 29.8934C30.4346 28.9755 31.2044 27.9995 31.7489 27.0596L29.4463 29.8934ZM31.7489 27.0596L40.5665 41.8668L26.4495 31.9813C27.4272 31.5076 28.4577 30.8116 29.4463 29.8934"
                    fill="#265C7F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.67464 24.6991L1.68722 24.7616C1.70272 24.8408 1.71821 24.92 1.75224 24.9976L14.8017 51.8007C15.419 53.2127 16.7674 53.668 17.9612 53.4102C19.2123 53.1403 20.2933 52.0861 20.179 50.475L19.2764 20.6783C19.2705 20.5936 19.2474 20.5162 19.2243 20.4388C19.2219 20.431 19.2196 20.4233 19.2173 20.4155C19.2094 20.3896 19.2016 20.3636 19.1947 20.3372C19.1918 20.3261 19.1891 20.315 19.1866 20.3037C18.875 19.0396 17.7655 18.1274 16.1574 17.6441C14.379 17.1097 11.9908 17.0998 9.39754 17.7184C9.35156 17.7294 9.30553 17.7405 9.25943 17.7519C9.21333 17.7633 9.16738 17.7748 9.12158 17.7865C6.53705 18.4441 4.42648 19.5633 3.10026 20.8635C1.91131 22.0292 1.35273 23.3405 1.64816 24.5951M1.65537 24.625C1.65806 24.636 1.66081 24.6468 1.66362 24.6577C1.66718 24.6715 1.67089 24.6855 1.67464 24.6991M16.9183 20.8514C16.8194 20.4811 16.3285 20.1322 15.5428 19.8956C14.2896 19.5183 12.2864 19.4269 9.927 19.9909C9.8907 19.9996 9.85433 20.0084 9.81786 20.0174C9.81786 20.0174 9.81787 20.0174 9.81786 20.0174C9.7814 20.0264 9.74508 20.0355 9.70891 20.0447C7.35657 20.6421 5.62476 21.6547 4.69079 22.5716C4.10225 23.1493 3.83054 23.689 3.92 24.0629M16.9183 20.8514C16.9193 20.855 16.9202 20.8586 16.9211 20.8622C16.9225 20.8679 16.9238 20.8736 16.9251 20.8793C17.1352 21.8613 14.9085 23.9539 11.0242 24.9113C7.13598 25.8697 4.18984 25.0492 3.92459 24.0808C3.92329 24.0761 3.92207 24.0713 3.9209 24.0666M17.0625 24.6542C17.0211 24.6866 16.9794 24.7187 16.9375 24.7505C15.6379 25.7356 13.805 26.629 11.5826 27.1768C9.3602 27.7246 7.32205 27.7854 5.71351 27.5171C5.66264 27.5086 5.61183 27.4997 5.56111 27.4905L16.9205 50.8223L16.9396 50.866C16.986 50.9721 17.0306 51.0267 17.0565 51.0531C17.0828 51.08 17.1085 51.0972 17.1369 51.1102C17.1988 51.1386 17.3152 51.1626 17.4685 51.1294C17.6303 51.0945 17.7357 51.0169 17.7839 50.9586C17.8056 50.9324 17.8215 50.9044 17.833 50.8683C17.8442 50.833 17.8601 50.7619 17.8515 50.6402L17.8481 50.593L17.0625 24.6542Z"
                    fill="#265C7F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M53.5936 20.4716L30.5731 1.532C30.5053 1.48071 30.4319 1.44714 30.3584 1.41354C30.3526 1.41091 30.3468 1.40828 30.3411 1.40565C30.3153 1.39394 30.2895 1.38219 30.264 1.36942C30.2532 1.36402 30.2425 1.35845 30.232 1.35262C29.0753 0.754778 27.6582 0.989262 26.2356 1.88144C24.6624 2.86807 23.0826 4.65902 21.8407 7.01811C21.8187 7.05993 21.7968 7.10191 21.775 7.14409C21.7532 7.18627 21.7316 7.22845 21.7102 7.27059C20.5035 9.64883 19.9562 11.9742 20.0616 13.8285C20.1559 15.4878 20.7729 16.7699 21.9076 17.375M29.1504 3.42032C28.8066 3.25118 28.2208 3.39091 27.5256 3.82643C26.4165 4.52121 25.0287 5.96876 23.8997 8.11589C23.8824 8.14892 23.8651 8.18211 23.8478 8.21547C23.8478 8.21547 23.8478 8.21547 23.8478 8.21547C23.8306 8.24883 23.8135 8.28216 23.7966 8.31543C22.6974 10.4793 22.3193 12.4494 22.3944 13.756C22.4417 14.5794 22.669 15.1393 23.0093 15.3181C23.0104 15.3187 23.0116 15.3193 23.0127 15.3199M29.1766 3.43405C30.0536 3.92336 30.162 6.97658 28.3254 10.5298C26.4851 14.0904 23.9248 15.7674 23.0234 15.3253M29.1606 3.42544C29.1659 3.42822 29.1713 3.43109 29.1766 3.43405L29.1606 3.42544ZM32.1077 5.81609C32.1048 5.86851 32.1015 5.92093 32.0979 5.97333C31.9837 7.60008 31.4492 9.56785 30.3983 11.6012C29.3473 13.6346 28.051 15.2086 26.79 16.2426C26.7499 16.2755 26.7095 16.3081 26.6689 16.3403L51.7091 23.1531L51.7546 23.1675C51.8651 23.2025 51.9355 23.2048 51.9725 23.2027C52.0101 23.2007 52.0401 23.1926 52.0686 23.1798C52.1307 23.1519 52.2254 23.08 52.3013 22.943C52.3816 22.7982 52.3928 22.6674 52.3807 22.5927C52.3752 22.5591 52.3646 22.5287 52.345 22.4963C52.3258 22.4646 52.2828 22.4059 52.1855 22.3322L52.1477 22.3036L32.1077 5.81609ZM21.9968 17.4206C22.0196 17.4332 22.0421 17.4462 22.0646 17.4593C22.1344 17.4997 22.2043 17.5402 22.2851 17.5658L51.0505 25.3921C52.5197 25.8571 53.7504 25.1421 54.3423 24.0738C54.9631 22.9545 54.8817 21.4465 53.5936 20.4716M21.9968 17.4206L21.9417 17.3929L21.9968 17.4206Z"
                    fill="#265C7F"
                />
            </svg>
        </SvgIcon>
    );
}

export default LineationIcon;
