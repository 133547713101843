import ClipRegionWithSurface from 'App/MainApp/Dialogs/Meshes/ClipRegionWithSurface';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { selectSuccessfulOpenMeshes } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { MeshesSectionMenuItemNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { clipRegionWithSurfaceDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function ClipRegionWithSurfaceButton(props: GeneralMenuItemProps) {
    const successfulOpenMeshes = useAppSelector(selectSuccessfulOpenMeshes);
    const disabled = !Object.values(successfulOpenMeshes).length;
    const tooltipText = disabled ? 'Requires at least one open mesh and drilling data' : '';

    return (
        <DialogOpenerMenuItem
            name={MeshesSectionMenuItemNames.ClipRegionWithSurface}
            tooltipText={tooltipText}
            dataCy={clipRegionWithSurfaceDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            dialogComponent={ClipRegionWithSurface}
        />
    );
}
