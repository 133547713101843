import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GenericCheckbox from 'App/MainApp/Dialogs/Shared/GenericCheckbox';
import { checkboxItemDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import classNames from 'classnames';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        flexDirection: 'column',
    },
}));

export type SelectedItems = { [itemName: string]: boolean };

export default function SelectItemsList(props: {
    items: string[];
    selectedItems: SelectedItems;
    setSelectedItems: (selectedItems: SelectedItems) => void;
    className?: string;
}) {
    const { classes } = useStyles();

    const onItemChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        props.setSelectedItems({ ...props.selectedItems, [item]: event.target.checked });
    };

    return (
        <Grid container className={classNames(classes.root, props.className)}>
            {props.items.map((item) => {
                return (
                    <Grid item container sx={{ flexWrap: 'nowrap' }} key={item} data-cy={checkboxItemDataCy}>
                        <GenericCheckbox
                            checked={props.selectedItems[item] || false}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onItemChange(event, item)}
                        />
                        <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {item}
                        </Typography>
                    </Grid>
                );
            })}
        </Grid>
    );
}
