import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { XyzTraceClassNames } from './XyzTrace';
import { LinesTrace, LinesTraceSnapshot } from './LinesTrace';
import { Point3D } from 'Common/types/geometryTypes';
import { ElementClass } from '@seequent/xyz';
import { floatArraysEqual } from 'App/util/core';
import { AppDispatch } from 'App/Redux/store';
import { produce } from 'immer';
import { getBoundingBoxVerticesSegments } from '../XyzUtils/BoundingBoxGeometry';

export const XYZ_BOUNDING_BOX_TRACE_ID = 'BoundingBoxTraceID';

export interface BoundingBoxTraceSnapshot extends LinesTraceSnapshot {
    /**
     * Snapshot is saved in Redux state. Must not duplicate XYZ state that is already stored in XYZ.
     * I mean when a plot is saved into XYZ, then basically all the currrent state of the plot is saved into XYZ.
     * Therefore there is a blurry line between what should and should not be saved in Redux through Snapshot.
     * We mainly want to store data that can help us easily extract all required information from XYZ state.
     */

    readonly id: string;

    readonly className: XyzTraceClassNames.BoundingBoxTrace;

    readonly minCorner: Point3D;

    readonly maxCorner: Point3D;

    readonly vertices: Float64Array;

    readonly segments: Float64Array;
}

export class BoundingBoxTrace extends LinesTrace implements BoundingBoxTraceSnapshot {
    readonly id: string;

    readonly className: XyzTraceClassNames.BoundingBoxTrace;

    readonly minCorner: Point3D;

    readonly maxCorner: Point3D;

    readonly vertices: Float64Array;

    readonly segments: Float64Array;

    constructor(xyz: UseXyz, snapshot: BoundingBoxTraceSnapshot) {
        if (snapshot.className !== XyzTraceClassNames.BoundingBoxTrace) {
            throw `An incorrect spanshot is being used to create this instance of BoundingBoxTrace: ${snapshot}`;
        }
        if (!snapshot.minCorner || !snapshot.maxCorner) {
            throw 'minCorner and maxCorner must be defined';
        }
        if (snapshot.verticesUrl || snapshot.segmentsUrl) {
            throw 'BoundingBoxTrace object must not have defined verticesUrl and segmentsUrl';
        }
        if (snapshot.id && snapshot.id !== XYZ_BOUNDING_BOX_TRACE_ID) {
            throw `The id of a BoundingBoxTrace object can be only be ${XYZ_BOUNDING_BOX_TRACE_ID}. (Or you can leave it as undefined and it will be set to ${XYZ_BOUNDING_BOX_TRACE_ID})`;
        }
        super(xyz, snapshot);

        this.id = XYZ_BOUNDING_BOX_TRACE_ID;
        if (!this.vertices || !this.segments) {
            const { vertices, segments } = getBoundingBoxVerticesSegments(this.minCorner, this.maxCorner);
            this.vertices = vertices;
            this.segments = segments;
        }
    }

    showInSceneManager = () => false;

    setMinCorner = async (dispatch: AppDispatch, newMinCorner: Point3D) =>
        this.setMinMaxCorners(dispatch, newMinCorner, undefined);

    setMaxCorner = async (dispatch: AppDispatch, newMaxCorner: Point3D) =>
        this.setMinMaxCorners(dispatch, undefined, newMaxCorner);

    setMinMaxCorners = async (dispatch: AppDispatch, newMinCorner: Point3D, newMaxCorner: Point3D) => {
        let minCorner: Point3D;
        if (!newMinCorner || newMinCorner.length !== 3 || floatArraysEqual(newMinCorner, this.minCorner, 1e-5)) {
            minCorner = this.minCorner;
        } else {
            minCorner = newMinCorner;
            dispatch(
                this.updateReduxState(
                    produce<BoundingBoxTraceSnapshot>((oldBoundingBoxTraceSnapshot) => {
                        oldBoundingBoxTraceSnapshot.minCorner = minCorner;
                    })
                )
            );
        }

        let maxCorner: Point3D;
        if (!newMaxCorner || newMaxCorner.length !== 3 || floatArraysEqual(newMaxCorner, this.minCorner, 1e-5)) {
            maxCorner = this.maxCorner;
        } else {
            maxCorner = newMaxCorner;
            dispatch(
                this.updateReduxState(
                    produce<BoundingBoxTraceSnapshot>((oldBoundingBoxTraceSnapshot) => {
                        oldBoundingBoxTraceSnapshot.maxCorner = maxCorner;
                    })
                )
            );
        }

        if (minCorner === this.minCorner && maxCorner === this.maxCorner) {
            return;
        }

        const { vertices, segments } = getBoundingBoxVerticesSegments(minCorner, maxCorner);
        await this.applyNewVerticesAndTriangles(vertices, segments);
    };

    applyNewVerticesAndTriangles = async (vertices: Float64Array, segments: Float64Array) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.linesElementEntityId()]: {
                vertices: vertices,
                segments: segments,
                __class__: ElementClass.Lines,
            },
        });
    };

    takeSnapshot = (): BoundingBoxTraceSnapshot => {
        const superSnapshot = super.takeSnapshot();

        return {
            ...superSnapshot,
            className: this.className,
            minCorner: this.minCorner,
            maxCorner: this.maxCorner,
        };
    };
}
