import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import { runAnisotropy } from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyService';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import { ProjectTreeSource, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import {
    anisotropyEstimationDialogDataCy,
    domainsTreeDataCy,
    drillingTreeDataCy,
    epsilonDropdownDataCy,
    epsilonTextboxDataCy,
    fileNameDataCy,
    outputFormatDropdownDataCy,
    removeFileButtonDataCy,
    resolutionDropdownDataCy,
    thresholdDropdownDataCy,
    thresholdTextboxDataCy,
    uploadFileButtonDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import { tss } from 'tss-react/mui';
import WidgetSelect from 'Common/components/Core/WidgetSelect';
import { getDefaultValidationResult } from 'App/util/validationUtils';
import {
    isValidNumOfSelectedItemsForConcentration,
    isEvaluateDataAboveConcentrationValid,
    isEvaluateDataAbovePercentileValid,
    isEpsilonValueValid,
} from 'App/MainApp/Dialogs/Anisotropy/globalEstimationValidationService';
import { DrillingAttributeType, SourceFileType } from 'App/util/ProjectDataTypes/SourceFile';
import {
    applyWebsocketModificationsToCurrentProject,
    selectActiveDomains,
    selectActiveDrilling,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
    selectDomains,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { DataContext } from 'App/DataContext';
import LabeledNumberField from 'App/MainApp/Dialogs/Shared/LabeledNumberField';
import GenericIconButton from 'Common/components/Core/GenericIconButton';
import { CloseIcon } from '@local/web-design-system/dist/icons';
import SecondaryButton from 'Common/components/Core/SecondaryButton';
import { FeatureFlags } from 'Common/utils/featureFlags';
import { useNameSuffix } from 'Common/components/NameSuffixField/useNameSuffix';
import { NameSuffixField } from 'Common/components/NameSuffixField/NameSuffixField';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import {
    ThresholdTypes,
    defaultConcentrationThreshold,
    defaultPercentileThreshold,
    thresholdOptions,
    resolutionOptions,
    outputFormatOptions,
    anisotropySuffixPrefix,
    epsilonOptions,
    EpsilonTypes,
    defaultEpsilonValue,
} from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyConsts';
import { useSelectableTree } from 'App/MainApp/TreeView/components/checkboxTree/useSelectableTree';
import { SectionNames, SectionIds } from 'App/MainApp/TreeView/treeData/treeConsts';
import { Id2DomainObject } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { getDrillingNode } from 'App/MainApp/TreeView/treeData/setupNodesService';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { expandAnisotropyNodes } from '../Shared/dialogUtils';
import { useSessionContext } from 'App/context/SessionContext';
import ProjectTree from 'App/MainApp/TreeView/components/ProjectTree/ProjectTree';
import { getFirstLevelNodeIds } from 'App/MainApp/TreeView/treeUtils';
import { commonStyles } from 'styles/commonStyles';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create(({ theme }) => ({
    tree: {
        height: 240,
        flexGrow: 1,
        overflowY: 'auto',
        marginBottom: 0,
    },
    fieldsGrid: {
        width: '700px',
    },
    formField: {
        display: 'grid',
        gridTemplateColumns: '28% 36% 10%',
        columnGap: '10px',
    },
    uploadCentersFormField: {
        display: 'grid',
        gridTemplateColumns: '28% 72%',
        columnGap: '10px',
    },
    nestedField: {
        display: 'grid',
        gridTemplateColumns: '30% 70%',
    },
    removeFileButton: {
        height: '20px',
        width: '20px',
        marginLeft: '10px',
    },
    removeFileIcon: {
        fontSize: '13px',
    },
    fileName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: '0 1 auto',
    },
    defaultText: {
        ...commonStyles({ theme }).defaultText,
    },
}));

function getDomainSection(domains: Id2DomainObject) {
    const domainsSubsections = {};

    Object.values(domains ?? {}).forEach((domain) => {
        domainsSubsections[domain.id] = {
            section: domain.name,
            dataCy: domain.name,
            rightClickMenu: [],
            id: domain.id,
            className: domain.object_class_name,
            nodeId: domain.id,
        };
    });

    const domainsSection: Subsections = {
        [SectionIds[SectionNames.ClosedMeshes]]: {
            section: SectionNames.ClosedMeshes,
            dataCy: SectionNames.ClosedMeshes,
            subsections: domainsSubsections,
            nodeId: SectionIds[SectionNames.ClosedMeshes],
            path: [],
        },
    };

    return domainsSection;
}

function getDrillingSection(drillingObject: SourceFileType): Subsections {
    const drillingNode = getDrillingNode(drillingObject, null);

    return {
        [drillingObject.original_file_name]: {
            ...drillingNode,
            canDisplaySpotlight: false,
            subsections: Object.fromEntries(
                Object.entries(drillingNode.subsections).map(([id, dataAttributeNode]) => {
                    return [id, { ...dataAttributeNode, canDisplaySpotlight: false }];
                })
            ),
        },
    };
}

export function CreateAnisotropy(props: { handleClose: () => void }) {
    const { classes } = useStyles();
    const xyz = useXyz();
    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const dispatch = useAppDispatch();

    const anisotropyClustering = useFlags()?.anisotropyClustering;
    const scmCoreUpdate = useFlags()?.scmCoreUpdate;

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const drillingData = useAppSelector(selectActiveDrilling);

    const drillingFileSection = React.useMemo(() => {
        return getDrillingSection(drillingData);
    }, [drillingData]);

    const domains = useAppSelector(selectActiveDomains);

    const domainsSection = React.useMemo(() => {
        return getDomainSection(domains);
    }, [domains]);

    const {
        onChange: onDrillingCheckboxChange,
        selectedLeafNodes: selectedDataAttributes,
        nodeSelectionChecker: dataAttributeNodeSelectionChecker,
    } = useSelectableTree(drillingFileSection, null, null);

    const {
        onChange: onDomainsCheckboxChange,
        selectedLeafNodes: selectedDomains,
        nodeSelectionChecker: domainNodeSelectionChecker,
    } = useSelectableTree(domainsSection, null, null);

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const numOfSelectedDataAttributes = selectedDataAttributes.length;
    const numOfSelectedDomains = selectedDomains.length;

    const { nameSuffix, nameSuffixValidationResult, onNameSuffixChange, onNameSuffixBlur } = useNameSuffix();

    const [epsilon, setEpsilon] = React.useState<string>(EpsilonTypes.AUTO);
    const [epsilonValue, setEpsilonValue] = React.useState('');
    const showEpsilonTextbox = epsilon === EpsilonTypes.VALUE;
    const [epsilonValidationResult, setEpsilonValidationResult] = React.useState(getDefaultValidationResult());

    const [threshold, setThreshold] = React.useState<string>(ThresholdTypes.MEAN);
    const [thresholdValue, setThresholdValue] = React.useState('');
    const showThresholdTextbox = threshold === ThresholdTypes.PERCENTILE || threshold === ThresholdTypes.CONCENTRATION;
    const [thresholdValidationResult, setThresholdValidationResult] = React.useState(getDefaultValidationResult());

    const [centersFileName, setCentersFileName] = React.useState('');
    const [centersFile, setCentersFile] = React.useState<File>(null);
    const fileInput = React.useRef<HTMLInputElement>();

    const [resolution, setResolution] = React.useState('high');
    const disableResolution = !!centersFileName;

    const [outputFormat, setOutputFormat] = React.useState('ellipsoid');

    const allDomains = useAppSelector(selectDomains);
    const { onExpandChange } = useExpandedNodes();

    const runJobOnLambda = useFlags()?.runJobOnLambda;

    const onThresholdTypeChange = (event: SelectChangeEvent) => {
        const newThresholdType = event.target.value;
        setThreshold(newThresholdType);
        setThresholdValidationResult(getDefaultValidationResult());

        if (newThresholdType === ThresholdTypes.CONCENTRATION) {
            setThresholdValue(defaultConcentrationThreshold);
        } else if (newThresholdType === ThresholdTypes.PERCENTILE) {
            setThresholdValue(defaultPercentileThreshold);
        }
    };

    const onEpsilonTypeChange = (event: SelectChangeEvent) => {
        const newEpsilonType = event.target.value;
        setEpsilon(newEpsilonType);
        setEpsilonValidationResult(getDefaultValidationResult());

        if (newEpsilonType === EpsilonTypes.VALUE) {
            setEpsilonValue(defaultEpsilonValue);
        }
    };

    const onResolutionChange = (event: SelectChangeEvent) => {
        setResolution(event.target.value);
    };

    const onOutputFormatChange = (event: SelectChangeEvent) => {
        setOutputFormat(event.target.value);
    };

    const isValidNumOfItemsSelected =
        threshold === ThresholdTypes.CONCENTRATION
            ? isValidNumOfSelectedItemsForConcentration(numOfSelectedDataAttributes, numOfSelectedDomains)
            : numOfSelectedDataAttributes > 0 && numOfSelectedDomains > 0;

    const disableSubmit =
        isSubmitting ||
        !nameSuffixValidationResult.isValid ||
        !thresholdValidationResult.isValid ||
        !epsilonValidationResult.isValid ||
        !isValidNumOfItemsSelected;

    const runConcentrationThresholdValidation = (concentration: string) => {
        let attributeInDomain: DrillingAttributeType;

        if (isValidNumOfSelectedItemsForConcentration(numOfSelectedDataAttributes, numOfSelectedDomains)) {
            const selectedDataAttribute = selectedDataAttributes[0];
            const selectedDomainNode = selectedDomains[0];
            const selectedDomain = allDomains[selectedDomainNode.id];
            attributeInDomain = selectedDomain.result_meta_data.dataAttribute2info[selectedDataAttribute.section];
        }

        return isEvaluateDataAboveConcentrationValid(
            concentration,
            numOfSelectedDataAttributes,
            numOfSelectedDomains,
            attributeInDomain
        );
    };

    const runThresholdValidation = () => {
        let validationResult = getDefaultValidationResult();

        if (threshold === ThresholdTypes.CONCENTRATION) {
            validationResult = runConcentrationThresholdValidation(thresholdValue);
        } else if (threshold === ThresholdTypes.PERCENTILE) {
            validationResult = isEvaluateDataAbovePercentileValid(thresholdValue);
        }

        setThresholdValidationResult(validationResult);

        return validationResult;
    };

    const runEpsilonValidation = () => {
        let validationResult = getDefaultValidationResult();

        if (epsilon === EpsilonTypes.VALUE) {
            validationResult = isEpsilonValueValid(epsilonValue);
        }

        setEpsilonValidationResult(validationResult);

        return validationResult;
    };

    const onEpsilonValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEpsilonValue(event.target.value);
    };

    const onThresholdValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setThresholdValue(event.target.value);
    };

    const onThresholdValueBlur = () => {
        runThresholdValidation();
    };

    const onEpsilonValueBlur = () => {
        runEpsilonValidation();
    };

    const onUploadFileClick = () => {
        if (fileInput?.current) {
            fileInput.current.click();
        }
    };

    const onCentersFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            setCentersFile(file);

            const fileName = file.name;
            setCentersFileName(fileName);
        }
    };

    const onCentersFileRemove = () => {
        setCentersFileName('');
        setCentersFile(null);
    };

    // check that valid number of data attrs and domains are chosen to use the concentration threshold
    React.useEffect(() => {
        if (threshold === ThresholdTypes.CONCENTRATION) {
            runThresholdValidation();
        }
    }, [selectedDataAttributes, selectedDomains]);

    React.useEffect(() => {
        const drillTreeNodeIds = getFirstLevelNodeIds(drillingFileSection);
        const domainTreeNodeIds = getFirstLevelNodeIds(domainsSection);
        const nodeIds = [...drillTreeNodeIds, ...domainTreeNodeIds];
        onExpandChange(nodeIds, true, ProjectTreeSource.ANISOTROPY_ESTIMATION, true);
    }, []);

    const onSubmit = () => {
        const thresholdValidationResult = runThresholdValidation();

        const epsilonValidationResult = runEpsilonValidation();

        if (
            !isValidNumOfItemsSelected ||
            !nameSuffixValidationResult.isValid ||
            !thresholdValidationResult.isValid ||
            !epsilonValidationResult.isValid
        ) {
            return;
        }
        const drillSourceId = drillingData.id;

        const domainIds = selectedDomains.map((domainNode) => domainNode.id);

        void runAnisotropy(
            {
                drillingAttrs: selectedDataAttributes.map((drillingAttrNode) => drillingAttrNode.section),
                domains: domainIds,
                nameSuffix,
                thresholdType: threshold,
                thresholdValue: Number(thresholdValue),
                resolution: disableResolution ? '' : resolution,
                epsilonType: epsilon,
                epsilonValue: Number(epsilonValue),
                outputFormat,
                centersFile,
            },
            axiosDriverFlask,
            currentProjectId,
            drillSourceId,
            currentOrgUuid,
            currentWorkspaceId,
            anisotropyClustering,
            scmCoreUpdate,
            runJobOnLambda
        )
            .then((projectUpdateJson) => {
                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'submit scm anisotropy',
                        tokenProvider
                    )
                );
                expandAnisotropyNodes(onExpandChange, domainIds);
                props.handleClose();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
        setIsSubmitting(true);
    };

    return (
        <GenericDialogShell
            title="New Anisotropy Estimation"
            dataCy={anisotropyEstimationDialogDataCy}
            maxWidth="md"
            handleClose={props.handleClose}
        >
            <DialogContent>
                <Grid container>
                    <Grid item xs={6} style={{ borderRight: '1px solid #D3D3D3', paddingRight: '1%' }}>
                        <ProjectTree
                            checkboxTree
                            tree={drillingFileSection}
                            onChange={onDrillingCheckboxChange}
                            dataCy={drillingTreeDataCy}
                            className={classes.tree}
                            nodeSelectionChecker={dataAttributeNodeSelectionChecker}
                            treeSource={ProjectTreeSource.ANISOTROPY_ESTIMATION}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: '5%' }}>
                        <ProjectTree
                            checkboxTree
                            tree={domainsSection}
                            onChange={onDomainsCheckboxChange}
                            dataCy={domainsTreeDataCy}
                            className={classes.tree}
                            nodeSelectionChecker={domainNodeSelectionChecker}
                            treeSource={ProjectTreeSource.ANISOTROPY_ESTIMATION}
                        />
                    </Grid>
                    <Grid style={{ marginTop: '1%' }} container spacing={1}>
                        <NameSuffixField
                            className={classes.formField}
                            prefix={anisotropySuffixPrefix}
                            value={nameSuffix}
                            onChange={onNameSuffixChange}
                            validationResult={nameSuffixValidationResult}
                            onBlur={onNameSuffixBlur}
                        />

                        <Grid item container className={classes.formField}>
                            <Typography className={classes.defaultText}>Threshold:</Typography>
                            <WidgetSelect
                                value={threshold}
                                onChange={onThresholdTypeChange}
                                data-cy={thresholdDropdownDataCy}
                            >
                                {thresholdOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value} data-cy={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </WidgetSelect>
                            {showThresholdTextbox && (
                                <LabeledNumberField
                                    value={thresholdValue}
                                    validationResult={thresholdValidationResult}
                                    dataCy={thresholdTextboxDataCy}
                                    onChange={onThresholdValueChange}
                                    onBlur={onThresholdValueBlur}
                                />
                            )}
                        </Grid>

                        <Grid item container className={classes.formField}>
                            <Typography className={classes.defaultText}>Resolution:</Typography>
                            <WidgetSelect
                                value={resolution}
                                onChange={onResolutionChange}
                                data-cy={resolutionDropdownDataCy}
                                disabled={disableResolution}
                            >
                                {resolutionOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value} data-cy={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </WidgetSelect>
                        </Grid>
                        <Grid item container className={classes.formField}>
                            <Typography className={classes.defaultText}>Output format:</Typography>
                            <WidgetSelect
                                value={outputFormat}
                                onChange={onOutputFormatChange}
                                data-cy={outputFormatDropdownDataCy}
                            >
                                {outputFormatOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value} data-cy={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </WidgetSelect>
                        </Grid>

                        {scmCoreUpdate && (
                            <Grid item container className={classes.formField}>
                                <Typography className={classes.defaultText}>Tolerance:</Typography>
                                <WidgetSelect
                                    value={epsilon}
                                    onChange={onEpsilonTypeChange}
                                    data-cy={epsilonDropdownDataCy}
                                >
                                    {epsilonOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value} data-cy={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </WidgetSelect>
                                {showEpsilonTextbox && (
                                    <LabeledNumberField
                                        value={epsilonValue}
                                        validationResult={epsilonValidationResult}
                                        dataCy={epsilonTextboxDataCy}
                                        onChange={onEpsilonValueChange}
                                        onBlur={onEpsilonValueBlur}
                                    />
                                )}
                            </Grid>
                        )}

                        {FeatureFlags.uploadCentersFile && (
                            <Grid
                                item
                                container
                                className={centersFileName ? classes.uploadCentersFormField : classes.formField}
                            >
                                <Typography className={classes.defaultText}>Upload centers:</Typography>

                                {centersFileName ? (
                                    <Grid item container alignItems="center" flexWrap="nowrap">
                                        <Typography
                                            data-cy={fileNameDataCy}
                                            className={`${classes.fileName} ${classes.defaultText}`}
                                        >
                                            {centersFileName}
                                        </Typography>
                                        <GenericIconButton
                                            Icon={CloseIcon}
                                            dataCy={removeFileButtonDataCy}
                                            onClick={onCentersFileRemove}
                                            className={classes.removeFileButton}
                                            iconProps={{ className: classes.removeFileIcon }}
                                        />
                                    </Grid>
                                ) : (
                                    <>
                                        <SecondaryButton onClick={onUploadFileClick} data-cy={uploadFileButtonDataCy}>
                                            Upload file
                                            <input
                                                type="file"
                                                accept=".csv"
                                                onChange={onCentersFileChange}
                                                ref={fileInput}
                                                hidden
                                            />
                                        </SecondaryButton>
                                    </>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <GenericDialogActions
                onSubmit={onSubmit}
                onCancel={props.handleClose}
                showSpinner={isSubmitting}
                disabled={disableSubmit}
            />
        </GenericDialogShell>
    );
}
