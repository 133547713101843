import { ProjectTreeNode, ProjectTreeObjectNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import {
    FailedOrProcessingProjectObjectStatusTypes,
    ObjectClassName2Id2Obj,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';

export type Selections = { [id: string]: boolean };

export function selectNodeRecursive(
    node: ProjectTreeNode,
    selections: Selections,
    tree: Subsections,
    allObjects: ObjectClassName2Id2Obj
) {
    if (isSelectable) {
        selections[node.nodeId] = true;
        selectAllChildrenRecursive(node, selections, allObjects);
        syncParentsSelectionRecursive(tree, selections, allObjects);
    }
}

export function unselectNodeRecursive(
    node: ProjectTreeNode,
    selections: Selections,
    tree: Subsections,
    allObjects: ObjectClassName2Id2Obj
) {
    if (isSelectable) {
        selections[node.nodeId] = false;
        unselectAllChildrenRecursive(node, selections, allObjects);
        syncParentsSelectionRecursive(tree, selections, allObjects);
    }
}

export function getRequiredNodeObjectsRecursive(
    tree: Subsections,
    allObjects: ObjectClassName2Id2Obj,
    requiredObjects: string[],
    sectionName?: string
) {
    let requiredObjectsArr = [];

    if (Object.keys(tree)[0] === sectionName) {
        requiredObjects &&
            requiredObjects.map((objectName) => {
                requiredObjectsArr = requiredObjectsArr.concat(Object.values(allObjects[objectName]));
            });
    }

    return requiredObjectsArr;
}

export function getSelectedLeafNodesRecursive(
    selections: Selections,
    tree: Subsections,
    allObjects: ObjectClassName2Id2Obj,
    node?: ProjectTreeNode
) {
    if (node) {
        if (!node.subsections) {
            if (isSelected(node, selections, allObjects) && 'id' in node) {
                return [node];
            } else {
                return [];
            }
        } else if (Object.values(node.subsections).length) {
            let selectedLeafNodes: ProjectTreeObjectNode[] = [];
            Object.values(node.subsections).forEach((childNode) => {
                const childSelectedLeafNodes = getSelectedLeafNodesRecursive(selections, tree, allObjects, childNode);
                selectedLeafNodes = selectedLeafNodes.concat(childSelectedLeafNodes);
            });
            return selectedLeafNodes;
        } else {
            return [];
        }
    } else {
        let selectedLeafNodes: ProjectTreeObjectNode[] = [];
        Object.values(tree).forEach((childNode) => {
            const childSelectedLeafNodes = getSelectedLeafNodesRecursive(selections, tree, allObjects, childNode);
            selectedLeafNodes = selectedLeafNodes.concat(childSelectedLeafNodes);
        });

        return selectedLeafNodes;
    }
}

export function isSelected(node: ProjectTreeNode, selections: Selections, allObjects: ObjectClassName2Id2Obj) {
    if (isSelectable(node, allObjects)) {
        return !!selections[node.nodeId];
    } else {
        return false;
    }
}

function isSelectable(node: ProjectTreeNode, allObjects: ObjectClassName2Id2Obj) {
    if ('id' in node) {
        const object = allObjects[node.className][node.id];
        if (object && FailedOrProcessingProjectObjectStatusTypes.includes(object.status)) {
            return false;
        } else {
            return true;
        }
    } else {
        return true;
    }
}

// this can have performance implications as instead of calculating effect of a specific child's
// state change on all its ancestors, we check this for all leaf nodes, traversing the whole tree.
// for small and shallow trees that would be negligible, but if we notice performance
// issues, we might want to instead implement backwards pointers in ProjectTreeNode.
export function syncParentsSelectionRecursive(
    tree: Subsections,
    selections: Selections,
    allObjects: ObjectClassName2Id2Obj,
    root?: ProjectTreeNode,
    parent?: ProjectTreeNode
) {
    const childrenMap = root ? root?.subsections : tree;

    const doesNodesParentHaveSibling = parent && parent.subsections && Object.values(parent.subsections).length > 1;

    if (childrenMap) {
        const childrenMapKeys = Object.keys(childrenMap);
        const childrenMapValues = Object.values(childrenMap);
        childrenMapValues.forEach((node) => {
            syncParentsSelectionRecursive(tree, selections, allObjects, node, root);
            if (!doesNodesParentHaveSibling && childrenMapKeys.length === 1 && !node.subsections) {
                selections[node.nodeId] = true;
            }
            if (isAllImmediateChildrenSelected(node, selections, allObjects)) {
                selections[node.nodeId] = true;
            } else if (node.subsections && Object.keys(node.subsections).length) {
                selections[node.nodeId] = false;
            }
        });
    }
}

function isAllImmediateChildrenSelected(
    node: ProjectTreeNode,
    selections: Selections,
    allObjects: ObjectClassName2Id2Obj
) {
    if (!node.subsections || !Object.values(node.subsections).length) {
        return false;
    } else {
        const allChildrenUnselectable = Object.values(node.subsections).every((childNode) => {
            return !isSelectable(childNode, allObjects);
        });

        if (allChildrenUnselectable) {
            return false;
        }

        const areAnyUnselectedNodes = Object.values(node.subsections).some((node) => {
            const isSelectableAndUnselected = isNodeSelectableAndUnselected(node, selections, allObjects);

            return isSelectableAndUnselected;
        });

        return !areAnyUnselectedNodes;
    }
}

function isNodeSelectableAndUnselected(
    node: ProjectTreeNode,
    selections: Selections,
    allObjects: ObjectClassName2Id2Obj
) {
    const isNodeSelectable = isSelectable(node, allObjects);
    const isNodeUnselected = !isSelected(node, selections, allObjects);

    return isNodeSelectable && isNodeUnselected;
}

function selectAllChildrenRecursive(node: ProjectTreeNode, selections: Selections, allObjects: ObjectClassName2Id2Obj) {
    if (node.subsections) {
        Object.values(node.subsections).forEach((childNode) => {
            if (isSelectable(childNode, allObjects)) {
                selections[childNode.nodeId] = true;
                selectAllChildrenRecursive(childNode, selections, allObjects);
            }
        });
    }
}

function unselectAllChildrenRecursive(
    node: ProjectTreeNode,
    selections: Selections,
    allObjects: ObjectClassName2Id2Obj
) {
    if (node.subsections) {
        Object.values(node.subsections).forEach((childNode) => {
            if (isSelectable(childNode, allObjects)) {
                selections[childNode.nodeId] = false;
                unselectAllChildrenRecursive(childNode, selections, allObjects);
            }
        });
    }
}
