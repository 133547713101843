import { ObjectIDType, ObjectStatusTypes, OBJECT_CLASS_NAMES } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { Dependants } from './utilClasses/Dependants';
import { formatFloatWithSignificantDigits } from '../core';

export interface Overlap_Domain_GridDefinitionType {
    overlapId: ObjectIDType;
    domain_gridDefinitionId: ObjectIDType;
}

export interface Overlap_ZoneType {
    overlapId: ObjectIDType;
    zoneId: ObjectIDType;
}

export interface OverlapMetaData {
    volume: number;
}

export interface OverlapType extends BackendJsonifiedProjectObject {
    status: ObjectStatusTypes;
    parameters: object;
    result_meta_data: object;
    meta_data: OverlapMetaData;
    domain_gridDefinitionsIds: ObjectIDType[];
    zoneIds: ObjectIDType[];
    object_class_name: OBJECT_CLASS_NAMES.Overlap;
    gridDefinitionId: ObjectIDType;
    overlapProfileId: ObjectIDType;
}

export class Overlap extends BaseProjectObject implements OverlapType {
    parameters: object;

    result_meta_data: object;

    meta_data: OverlapMetaData;

    domain_gridDefinitionsIds: ObjectIDType[];

    zoneIds: ObjectIDType[];

    object_class_name: OBJECT_CLASS_NAMES.Overlap;

    gridDefinitionId: ObjectIDType;

    overlapProfileId: ObjectIDType;

    delete = () =>
        this.axiosDriverFlask.delete(this.url, {
            responseType: 'json',
        });

    getUniqueZoneIds = () => {
        const zonesMap: { [id: ObjectIDType]: ObjectIDType } = {};
        this.zoneIds.forEach((zoneId) => {
            zonesMap[zoneId] = zoneId;
        });

        return Object.values(zonesMap);
    };

    getDependants = () => new Dependants();

    private getFormattedVolume = () => {
        if (this.meta_data.volume) {
            return ` - ${formatFloatWithSignificantDigits(this.meta_data.volume, 2)} M3`;
        } else {
            return '';
        }
    };

    private getZonesString = () => {
        return this.getUniqueZoneIds()
            .map((zoneId) => {
                const zone = this.projectObjects.getZone(zoneId);
                return zone.dataAttribute_in_drill;
            })
            .join(', ');
    };

    private getDomainsGridDefinitionsString = () => {
        return (this.domain_gridDefinitionsIds || [])
            .map((domainGridDefinitionsId) => {
                const domainGrid = this.projectObjects.getDomainGrid(domainGridDefinitionsId);
                return domainGrid.getDomainName();
            })
            .join(', ');
    };

    getName = () => {
        const parentsStrings = [this.getZonesString(), this.getDomainsGridDefinitionsString()]
            .filter((parentString) => !!parentString)
            .join(', ');

        const overlapName = `${parentsStrings}${this.getFormattedVolume()}`;

        return overlapName;
    };

    getParentDomainIds = () => {
        return (this.domain_gridDefinitionsIds || []).map((domainGridDefinitionsId) => {
            const domainGrid = this.projectObjects.getDomainGrid(domainGridDefinitionsId);
            return domainGrid.domainId;
        });
    };
}
