/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-properties */
import React from 'react';
import './Logging/LoggerConfigs'; // import this file to setup the Logger configs
import { Vector2, Vector3 } from '@seequent/xyz/lib/types/src/types';

export function getFullHostname() {
    const retVal = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    return retVal;
}

export function getFullUrl() {
    const url = new URL(window.location.href);
    return url;
}

export function roundDownTo2Digits(value: number) {
    return Math.floor(value * 100) / 100;
}

export function roundUpTo2Digits(value: number) {
    return Math.ceil(value * 100) / 100;
}

const millnames = ['', 'K', 'M', 'B', 'T', 'Q'];
export function formatFloatWithSignificantDigits(x: number | string, p = 3, greaterThan1000Support = true): string {
    const s = Number.parseFloat(x as string).toPrecision(p);
    const f = Number.parseFloat(s);
    if (greaterThan1000Support && f >= 1000 && f < 1e18) {
        // eslint-disable-next-line no-plusplus
        for (let i = millnames.length - 1; i >= 0; i--) {
            const millname = millnames[i];
            const k = i * 3;

            if (Math.round(Math.abs(f / 10 ** k)).toString().length >= p) {
                return `${f / 10 ** k}${millname}`;
            }
        }
    }
    return String(f);
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    let newBytes = bytes;

    do {
        newBytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(newBytes) * r) / r >= thresh && u < units.length - 1);

    return `${newBytes.toFixed(dp)} ${units[u]}`;
}

export function formatTo6SignificantDigits(value: number): string {
    return Number.isInteger(value) ? `${value}` : Number.parseFloat(value.toPrecision(6)).toString();
}

export function fromHumanReadableNumber(n: number | string) {
    if (typeof n === 'string') {
        const millname = n[n.length - 1].toUpperCase();
        const millIndex = millnames.indexOf(millname);
        if (millIndex >= 0) {
            const parsed = Number(n.substring(0, n.length - 1));
            if (Number.isNaN(parsed)) {
                return null;
            }
            return parsed * Math.pow(10, millIndex * 3);
        }
        const nStr = String(n);
        const parsed = Number(nStr);
        if (Number.isNaN(parsed)) {
            return null;
        }
        return parsed;
    }
    return n;
}

export function humanReadableFileSize(size: number) {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return Math.round(size / Math.pow(1024, i)) + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

/**
 *
 * @param loaded
 * @param total
 *
 * returns:
 *  - String ("X%" if loaded and total are both positive or "XB" if only loaded is positive )
 *  - True if loaded is z ero.
 */
export function getProgressAsPercentageOrFileSize(loaded: number, total: number): undefined | string {
    if (loaded <= 0) {
        return undefined;
    }
    if (total === 0) {
        return humanReadableFileSize(loaded);
    }
    return `${Math.round((loaded * 100) / total)}%`;
}

export function getMax(arr: number[]) {
    let len = arr.length;
    let max = -Infinity;

    while (len--) {
        const val = arr[len];
        max = !isNaN(val) && val > max ? val : max;
    }
    return max;
}
/**
 * You can actually do Math.min(...arr) but that doesn't work if arr has millions of items.
 * Example:
 *   getMin(arr, -999);
 * @param arr
 * @param ignoreValuesBelow
 * @ret urns
 */
export function getMin(arr: number[], ignoreValuesBelow: number = undefined) {
    let len = arr.length;
    let min = Infinity;

    if (!ignoreValuesBelow) {
        while (len--) {
            const val = arr[len];
            min = !isNaN(val) && val < min ? val : min;
        }
    } else {
        while (len--) {
            const val = arr[len];
            if (val > ignoreValuesBelow) {
                min = !isNaN(val) && val < min ? val : min;
            }
        }
    }

    return min;
}

export function domainFullName(objectName: string, domainName: string) {
    return `${objectName} D[${domainName}]`;
}

export function ravelListOfLists<T>(l: T[][]): T[] {
    const ret = [];
    for (const obj of l) {
        ret.push(...obj);
    }
    return ret;
}

export type GarbageCollectionTestType = {
    desc: string;
};

export function GarbageCollectionTest(this: { desc: string }, desc: string) {
    this.desc = desc;
}

/**
 * Finds the key-value pairs that are all the same in all the provided objects
 * @param objs a list of obj ects
 */
export function intersectionOfObjects(objs: object[]) {
    const intersection = {};
    const obj = objs[0];

    if (obj) {
        for (const key of Object.keys(obj)) {
            const val = obj[key];
            let keyValInAllObjects = true;
            for (let j = 1; j < objs.length; j++) {
                const obj2 = objs[j];
                if (obj2[key] !== val) {
                    keyValInAllObjects = false;
                    break;
                }
            }
            if (keyValInAllObjects === true) {
                intersection[key] = val;
            }
        }
    }
    return intersection;
}

export function sleepForMS(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function usePrevious<T>(value: T, initialValue: T = undefined): T {
    const ref = React.useRef(initialValue);
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function differentKeys(obj1: object, obj2: object): string[] {
    const diffKeys = [];
    for (const key of Object.keys(obj2)) {
        if (obj2?.[key] !== obj1?.[key]) {
            diffKeys.push(key);
        }
    }
    return diffKeys;
}

export function arraysEqual(a: any[], b: any[]) {
    if (a === b) return true;
    if (!a || !b) return false;
    if (a.length !== b.length) return false;

    return !a.some((v1, i) => v1 !== b[i]);

    // for (let i = 0; i < a.length; ++i) {
    //     if (a[i] !== b[i]) return false;
    // }
    // return true;
}

export function floatArraysEqual(a: any[], b: any[], precisionPercent = 0) {
    if (a === b) return true;
    if (!a || !b) return false;
    if (a.length !== b.length) return false;

    return !a.some((v1, i) => Math.abs(v1 - b[i]) > (precisionPercent / 100) * b[i]);
}

export function floatsEqual(a: number, b: number, precisionPercent = 0) {
    if (a === b) return true;
    if (!a || !b) return false;
    if (Math.abs(a - b) < (precisionPercent / 100) * b) {
        return true;
    }
    return false;
}
export function objectAssignExceptKeys(target: object, exceptKeys: string[], ...sources: object[]): void {
    for (const source of sources) {
        for (const key of Object.keys(source)) {
            if (exceptKeys.findIndex((x) => x === key) >= 0) {
                continue;
            }
            target[key] = source[key];
        }
    }
}

export function shallowCompare(obj1: object, obj2: object) {
    return (
        Object.keys(obj1).length === Object.keys(obj2).length &&
        // eslint-disable-next-line no-prototype-builtins
        Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key])
    );
}

export function generateRandomId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getRandomInt(): number {
    return Math.floor(Math.random() * 1000000);
}

/**
 * This is can be used when updating a textbox string state. For example, say the textbox says: "0.000", and the new value
 * that you want to set the textbox to is "0". In this case this function can detect that the new value is the same as the
 * value in the textbox and it will return the original textbox value of "0.000".
 *
 * Basically, this is mainly used to keep the trailing zeros that the user has typed in.
 *
 * @param oldStringValue
 * @param oldValue
 * @param newStringValue
 * @param newValue
 * @ret urns
 */
export function keepTextboxStringIfNewValueIsSame<T extends string | number | boolean>(
    oldStringValue: string,
    oldValue: T,
    newStringValue: string,
    newValue: T
): string {
    if (newValue === oldValue) {
        if (oldStringValue === '') {
            return newStringValue;
        }
        return oldStringValue;
    }
    return newStringValue;
}

export function computeModulusAroundInterval(wrapInterval: Vector2, value: number): number {
    const [wrapMin, wrapMax] = wrapInterval;
    const modValue = value % wrapMax;
    if (value < wrapMin) {
        return modValue > 0 ? modValue + wrapMin : modValue + wrapMax;
    }
    if (value > wrapMax) {
        return modValue + wrapMin;
    }
    return value;
}

/**
 * calculates distance between 2 vectors (3 dimensional locations)
 * @param a
 * @param b
 */
export function distance(a: Vector3, b: Vector3) {
    return Math.hypot(a[0] - b[0], a[1] - b[1], a[2] - b[2]);
}

export function string2bool(s: string) {
    return ['1', 'true'].includes(String(s).toLowerCase());
}

export function filterObjectKeys<T>(obj: T, keys: string[]) {
    return Object.fromEntries(Object.entries(obj ?? {}).filter(([key]) => keys.includes(key))) as Partial<T>;
}

export function isUndefined(v) {
    return typeof v === 'undefined';
}

export function isDefined(v) {
    return typeof v !== 'undefined';
}

export const hashCode = (s: string) => {
    let hash = 0;
    if (s.length === 0) return hash;
    for (let i = 0; i < s.length; i++) {
        const chr = s.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }
    return hash;
};
