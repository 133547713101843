import { CreateAnisotropyButton } from 'App/MainApp/TreeView/menu/menuItems/anisotropy/CreateAnisotropyButton';
import { CreateAnisotropyGlobalButton } from 'App/MainApp/TreeView/menu/menuItems/anisotropy/CreateAnisotropyGlobalButton';
import { CreateAnisotropyGridButton } from 'App/MainApp/TreeView/menu/menuItems/anisotropy/CreateAnisotropyGridButton';
import { CreateOverlapsFolderDialogButton } from 'App/MainApp/TreeView/menu/menuItems/overlaps/OverlapsFolderDialogButtons';
import { ClipRegionWithSurfaceButton } from 'App/MainApp/TreeView/menu/menuItems/setup/uploadMeshes/ClipRegionWithSurfaceButton';
import { CreateGridDialogButton } from 'App/MainApp/TreeView/menu/menuItems/setup/CreateGridDialogButton';
import { MeshFromRegionButton } from 'App/MainApp/TreeView/menu/menuItems/setup/uploadMeshes/MeshFromRegionButton';
import {
    UploadClosedMeshButton,
    UploadOpenMeshButton,
} from 'App/MainApp/TreeView/menu/menuItems/setup/uploadMeshes/UploadMeshButton';
import { UploadDrillingButton } from 'App/MainApp/TreeView/menu/menuItems/setup/uploadDrilling/UploadDrillingButton';
import CreateZonesButton from 'App/MainApp/TreeView/menu/menuItems/zone/CreateZonesButton';
import { UploadDrillingFromLocalButton } from './menuItems/setup/uploadDrilling/UploadDrillingFromLocalButton';
import { UploadDrillingFromEvoButton } from './menuItems/setup/uploadDrilling/UploadDrillingFromEvoButton';
import { getMakeInterpolationsMenuItems } from './objectMenus';
import CreateZonesFromAnisotropyButton from './menuItems/zoneFromAnisotropy/CreateZonesFromAnisotropyButton';

export function getSetupSectionMenu() {
    const setupSectionMenu = [UploadDrillingButton, UploadClosedMeshButton, UploadOpenMeshButton];
    return setupSectionMenu;
}

export function getDrillingSectionMenu() {
    const drillingSectionMenu = [UploadDrillingButton];
    return drillingSectionMenu;
}

export function getEmptyProjectDrillingSectionMenu() {
    const emptyProjectDrillingSectionMenu = [UploadDrillingFromLocalButton, UploadDrillingFromEvoButton];
    return emptyProjectDrillingSectionMenu;
}

export const gridSectionMenu = [CreateGridDialogButton];
export const meshesSectionMenu = [
    UploadClosedMeshButton,
    MeshFromRegionButton,
    UploadOpenMeshButton,
    ClipRegionWithSurfaceButton,
];
export const closedMeshesSectionMenu = [UploadClosedMeshButton, MeshFromRegionButton, ClipRegionWithSurfaceButton];
export const openMeshesSectionMenu = [UploadOpenMeshButton];
export const anisotropySectionMenu = [CreateAnisotropyButton, CreateAnisotropyGridButton, CreateAnisotropyGlobalButton];

export function getInterpolationsSectionMenu() {
    return [CreateGridDialogButton, ...getMakeInterpolationsMenuItems()];
}

export function getZoneSectionMenu() {
    const zonesSectionMenu = [CreateZonesButton, CreateZonesFromAnisotropyButton];
    return zonesSectionMenu;
}

export const overlapsSectionMenu = [CreateOverlapsFolderDialogButton];
