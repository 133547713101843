import { OBJECT_CLASS_NAMES, ObjectIDType, ObjectStatusTypes } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { Anisotropy } from './AnisotropyEstimation';
import { AnisotropyGrid } from './AnisotropyGrid';
import { AnisotropyGlobal } from './AnisotropyGlobal';
import { Dependants } from './utilClasses/Dependants';

export interface ZoneFromAnisotropyResultMetadata {
    resolution: number;
    volume: number;
    threshold: number;
}

export interface ZoneFromAnisotropyType extends BackendJsonifiedProjectObject {
    name: string;

    anisotropyEstimationId: ObjectIDType;
    anisotropyGridId: ObjectIDType;
    anisotropyGlobalId: ObjectIDType;

    dataAttribute_in_drill: string;
    status: ObjectStatusTypes;
    parameters: {};
    result_meta_data: ZoneFromAnisotropyResultMetadata;
    object_class_name: OBJECT_CLASS_NAMES.ZoneFromAnisotropy;
}

export class ZoneFromAnisotropy extends BaseProjectObject implements ZoneFromAnisotropyType {
    name: string;

    anisotropyEstimationId: ObjectIDType;

    anisotropyGridId: ObjectIDType;

    anisotropyGlobalId: ObjectIDType;

    dataAttribute_in_drill: string;

    parameters: {};

    result_meta_data: ZoneFromAnisotropyResultMetadata;

    object_class_name: OBJECT_CLASS_NAMES.ZoneFromAnisotropy;

    getDependants = () => {
        const dependants = new Dependants();
        return dependants;
    };

    getAnisotropyEstimation = () => {
        return this.projectObjects.getAnisotropyEstimation(this.anisotropyEstimationId);
    };

    getAnisotropyGrid = () => {
        return this.projectObjects.getAnisotropyGrid(this.anisotropyGridId);
    };

    getAnisotropyGlobal = () => {
        return this.projectObjects.getAnisotropyGlobal(this.anisotropyGlobalId);
    };

    getDomain = () => {
        const anisotropy = this.getAnisotropy();
        return anisotropy.getDomain();
    };

    getDomainName = () => {
        const domain = this.getDomain();
        return domain.name;
    };

    getAnisotropy = () => {
        let anisotropy: Anisotropy | AnisotropyGrid | AnisotropyGlobal = null;
        if (this.anisotropyEstimationId) {
            anisotropy = this.getAnisotropyEstimation();
        } else if (this.anisotropyGridId) {
            anisotropy = this.getAnisotropyGrid();
        } else if (this.anisotropyGlobalId) {
            anisotropy = this.getAnisotropyGlobal();
        }
        return anisotropy;
    };
}
