import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function DiscIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className}>
            <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2738_71034)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M42.8299 8.69666C43.1092 7.54088 42.971 6.77885 42.6879 6.27256C42.4047 5.76628 41.8315 5.25633 40.7125 4.91081C39.5774 4.56033 38.0198 4.43071 36.088 4.62379C32.2306 5.00935 27.3259 6.63729 22.3669 9.51072C17.408 12.3842 13.5289 15.8459 11.234 19.0253C10.0847 20.6175 9.39912 22.0469 9.11577 23.2193C8.83643 24.3751 8.9746 25.1371 9.25776 25.6434C9.54092 26.1497 10.1141 26.6596 11.2331 27.0051C12.3682 27.3556 13.9258 27.4853 15.8576 27.2922C19.7151 26.9066 24.6197 25.2787 29.5787 22.4052C34.5377 19.5318 38.4167 16.0701 40.7116 12.8907C41.8609 11.2985 42.5465 9.86906 42.8299 8.69666ZM44.7157 5.09758C47.3447 9.79822 41.0845 18.4712 30.7331 24.4692C20.3817 30.4673 9.85899 31.519 7.22998 26.8184C4.60096 22.1177 10.8612 13.4448 21.2126 7.44673C31.564 1.4487 42.0866 0.396949 44.7157 5.09758Z"
                        fill="#265C7F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M47.7677 42.5824C48.4907 41.6413 48.6391 40.9276 48.5797 40.4178C48.5202 39.908 48.2118 39.2494 47.2926 38.5066C46.3627 37.7552 44.943 37.0391 43.0394 36.4721C39.2412 35.3408 33.9656 34.9365 28.1493 35.639C22.333 36.3414 17.2968 37.9911 13.8642 39.9957C12.1439 41.0004 10.9282 42.0349 10.1969 42.9869C9.47396 43.928 9.32555 44.6417 9.38498 45.1515C9.44441 45.6613 9.7528 46.3199 10.672 47.0627C11.602 47.8141 13.0216 48.5302 14.9253 49.0972C18.7234 50.2285 23.9991 50.6328 29.8153 49.9303C35.6316 49.2279 40.6678 47.5782 44.1004 45.5736C45.8208 44.5689 47.0364 43.5344 47.7677 42.5824ZM50.8967 40.1379C51.5086 45.3869 42.1933 50.827 30.0903 52.2888C17.9873 53.7505 7.67981 50.6804 7.06791 45.4314C6.45601 40.1824 15.7714 34.7423 27.8744 33.2805C39.9774 31.8188 50.2848 34.8889 50.8967 40.1379Z"
                        fill="#265C7F"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2738_71034">
                        <rect width="56" height="57" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}

export default DiscIcon;
