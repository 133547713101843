import { GenericDialogTitles } from 'App/MainApp/Dialogs/Shared/consts';
import SelectObjectsFromEvo from 'App/MainApp/Dialogs/Uploads/Evo/SelectObjectsFromEvo';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GooseKnownSchemas, SecondaryMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import {
    uploadClosedMeshFromEvoDataCy,
    uploadOpenMeshFromEvoDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function UploadMeshFromEvoButton(props: { handleMenuClose(): void; isClosedMesh: boolean }) {
    const dialogTitle = props.isClosedMesh ? GenericDialogTitles.UploadClosedMesh : GenericDialogTitles.UploadOpenMesh;
    const dataCy = props.isClosedMesh ? uploadClosedMeshFromEvoDataCy : uploadOpenMeshFromEvoDataCy;

    return (
        <DialogOpenerMenuItem
            name={SecondaryMenuItems.FromEvo}
            tooltipText=""
            dataCy={dataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={false}
            dialogComponent={SelectObjectsFromEvo}
            dialogProps={{
                gooseObjectAttributes: {
                    objectType: GooseKnownSchemas.TriangleMesh,
                    isClosedMesh: props.isClosedMesh,
                },
                title: dialogTitle,
            }}
        />
    );
}
