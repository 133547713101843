import React from 'react';
import { tss } from 'tss-react/mui';
import MenuItem from '@mui/material/MenuItem';
import update from 'immutability-helper';
import WidgetSelect from 'Common/components/Core/WidgetSelect';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InfoTooltip from 'Common/components/InfoTooltip/InfoTooltip';
import { ValidationResult, getDefaultValidationResult } from '../../../util/validationUtils';
import LabeledTextField from './LabeledTextField';

export enum MultiUnitTextFieldValueTypes {
    PERCENTILE = 'Percentile',
    CONCENTRATION = 'Concentration',
    NONE = 'None',
    DISTANCE = 'Distance',
}

export type MultiUnitTextFieldValueType = MultiUnitTextFieldValueTypes | string;

const useStyles = tss.create(({ theme }) => ({
    textField: {
        marginLeft: '5px',
        maxWidth: 100,
        minWidth: '3.5em',
    },
    mainDiv: {
        display: 'inline-flex',
    },
    dropdown: {
        flex: '1 1 100%',
    },
    selectRoot: {
        fontSize: '0.8rem',
        flex: '1 0 100%',
    },
    disabledOption: {
        color: theme.palette.text.disabled,
    },
}));

export type MultiUnitTextFieldOptionType = {
    typeDisplayText: string; // how option will be displayed to user
    type: MultiUnitTextFieldValueType; // type of option
    value: string;
    showTextField?: boolean;
    selected?: boolean;
    estimatedValue?: number;
    estimatedValuePrefix?: string;
    estimatedValueText?: string;
    validationResult: ValidationResult;
    isNumberField?: boolean;
    disabled?: boolean;
    tooltipText?: string;
};

// basically setter that is returned from React.useState for MultiUnitTextFieldOptionsType
export type MultiUnitTextFieldOptionsSetter = (
    updateFunc: (oldState: MultiUnitTextFieldOptionsType) => MultiUnitTextFieldOptionsType
) => void;

export type MultiUnitTextFieldOptionsType = MultiUnitTextFieldOptionType[];

function onChange(stateSetter: MultiUnitTextFieldOptionsSetter, selectedIndex: number, newValue: string) {
    stateSetter((oldState: MultiUnitTextFieldOptionsType) => {
        const updateCommand = {};
        updateCommand[selectedIndex] = {
            value: {
                $set: newValue,
            },
        };

        return update(oldState, updateCommand);
    });
}

export function updateOptionsSelection(oldOptions: MultiUnitTextFieldOptionsType, newValue: string) {
    const updateCommand = {};

    oldOptions.forEach((option, index) => {
        updateCommand[index] = {
            selected: {
                $set: option.typeDisplayText === newValue,
            },
            validationResult: {
                // let user start "fresh" on option change
                $set: getDefaultValidationResult(),
            },
        };
    });

    return update(oldOptions, updateCommand);
}

function selectOption(
    event: React.ChangeEvent<HTMLInputElement>,
    stateSetter: MultiUnitTextFieldOptionsSetter,
    options: MultiUnitTextFieldOptionsType
) {
    const newValue = event.target.value;
    const newlySelectedOption = options.find((option) => option.typeDisplayText === newValue);

    if (!newlySelectedOption.disabled) {
        stateSetter((oldState) => updateOptionsSelection(oldState, newValue));
    }
}

function MultiUnitTextField(props: {
    options: MultiUnitTextFieldOptionsType;
    optionsSetter: MultiUnitTextFieldOptionsSetter;
    onBlur: (selectedIndex: number) => void;
    dataCy?: string;
    className?: string;
    title?: string;
    infoTooltip?: string;
}) {
    const { classes } = useStyles();

    const selectedIndex = props.options.findIndex((optionInfo) => optionInfo.selected);
    const selectedOption = props.options[selectedIndex];

    const onDropdownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        selectOption(event, props.optionsSetter, props.options);
    };

    const onTextboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(props.optionsSetter, selectedIndex, event.target.value);
    };

    const onTextboxBlur = () => {
        props.onBlur(selectedIndex);
    };

    return (
        <Grid item container className={props.className} flexWrap="nowrap" alignItems="center" data-cy={props.dataCy}>
            {props.title && (
                <Grid item container alignItems="center">
                    {props.title ? <Typography>{props.title}</Typography> : null}
                    {props.infoTooltip ? <InfoTooltip text={props.infoTooltip} /> : null}
                </Grid>
            )}
            <Grid item container>
                <WidgetSelect
                    classes={{ select: classes.selectRoot }}
                    value={selectedOption.typeDisplayText}
                    onChange={onDropdownChange}
                >
                    {props.options.map((option) => (
                        <MenuItem
                            key={option.typeDisplayText}
                            value={option.typeDisplayText}
                            data-cy={option.typeDisplayText.toLowerCase().split(' ').join('-')}
                        >
                            <Tooltip disableInteractive title={option.tooltipText || ''} placement="right">
                                <div className={option.disabled ? classes.disabledOption : null}>
                                    {option.typeDisplayText}
                                </div>
                            </Tooltip>
                        </MenuItem>
                    ))}
                </WidgetSelect>
            </Grid>
            {selectedOption.showTextField && (
                <LabeledTextField
                    validationResult={selectedOption.validationResult}
                    value={selectedOption.value}
                    dataCy="textbox"
                    onChange={onTextboxChange}
                    placeholder="Amount"
                    onBlur={onTextboxBlur}
                    type={selectedOption.isNumberField === true ? 'number' : 'text'}
                    className={classes.textField}
                    errorLabelOnRight
                />
            )}
            {selectedOption.estimatedValue && (
                <Grid item container paddingLeft="5px" data-cy="multi-unit-estimated-value">
                    {selectedOption.estimatedValue &&
                        selectedOption.estimatedValuePrefix &&
                        selectedOption.estimatedValuePrefix}
                    {selectedOption.estimatedValue && selectedOption.estimatedValueText}
                </Grid>
            )}
        </Grid>
    );
}

export default MultiUnitTextField;
