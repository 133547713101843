import { XyzInstance, init } from '@seequent/xyz';
import { noop } from 'ts-essentials';
import { logException } from '../../../util/Logging/TelemetryLogs';
import { AuthApi } from 'Common/api/AuthApi';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { getAccessToken } from 'App/Login/Session';

const GRIDLINES_ID = 'gridlines';

export function makeTokenProvider(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    setLoginSessionTerminated: React.Dispatch<React.SetStateAction<boolean>>
) {
    return async function tokenProvider() {
        await AuthApi.refreshTokenIfNeededAndSetPromise(axiosDriverFlask, () => setLoginSessionTerminated(true));
        return getAccessToken();
    };
}

export function addXyzDebugInstance(instance: XyzInstance) {
    const globalWindow: any = window;
    if (!globalWindow.xyzDebug) {
        globalWindow.xyzDebug = { instances: [] };
    }
    globalWindow.xyzDebug.instances.push(instance);
    // (instance as any).highlight = async function highlight() {
    //     const beforeBorder = container.style.border;
    //     container.style.border = '5px solid red';
    //     await delayFrames(60);
    //     container.style.border = beforeBorder;
    // };
}

export function removeXyzDebugInstance(instance: XyzInstance) {
    const globalWindow: any = window;
    const instances = globalWindow.xyzDebug.instances as XyzInstance[];
    if (instances) {
        globalWindow.xyzDebug.instances = instances.filter((xyzInstance) => xyzInstance === instance);
    }
}

export async function initializeVisualization(
    element: HTMLElement,
    allEntitiesList: string[],
    allViewIds: string[],
    tokenProvider: () => Promise<string>
): Promise<{
    xyzInstance: XyzInstance;
    destroyUi: () => void;
}> {
    try {
        const prefix = '_next/static/xyz/wasm';
        const xyzInstance = await init(element, { wasmPrefix: prefix, tokenProvider: tokenProvider });
        addXyzDebugInstance(xyzInstance);
        const { unsubToPlotWidthChange, stateUpdateUnsub, plotUnsub } = subscribeToListeners(
            xyzInstance,
            allEntitiesList,
            allViewIds
        );

        if (xyzInstance) {
            xyzInstance.setStateFromSnapshot({
                [GRIDLINES_ID]: { active: false },
            });
        }

        const destroyUi = () => {
            removeXyzDebugInstance(xyzInstance);
            plotUnsub();
            unsubToPlotWidthChange();
            stateUpdateUnsub();
            allEntitiesList.splice(0, allEntitiesList.length);
            allViewIds.splice(0, allEntitiesList.length);
        };

        return {
            xyzInstance,
            destroyUi,
        };
    } catch (error) {
        logException(error);
        return undefined;
    }
}

function subscribeToListeners(
    xyzInstance: XyzInstance,
    allEntitiesList: string[],
    allViewIds: string[]
): {
    unsubToPlotWidthChange: (..._args: unknown[]) => void;
    stateUpdateUnsub: (..._args: unknown[]) => void;
    plotUnsub: (..._args: unknown[]) => void;
} {
    let stateUpdateUnsub = noop;
    let unsubToPlotWidthChange = noop;
    let scalebarState = null;
    let plotUnsub = noop;
    if (xyzInstance) {
        unsubToPlotWidthChange = xyzInstance.screenLengthStore.subscribe(
            async ({ screenLengthInThree, screenLengthInPix }) => {
                scalebarState = computeScaleBarState(screenLengthInThree, screenLengthInPix);
                await xyzInstance.setStateFromSnapshot(
                    {
                        [GRIDLINES_ID]: {
                            spacing: scalebarState.numSegs * scalebarState.snapLengthPix,
                        },
                    },
                    {}
                );
            }
        );

        stateUpdateUnsub = xyzInstance.listenForStateUpdates((update) => {
            const eids = Object.keys(update);
            let updated = false;
            let i = 0;
            for (i = 0; i < eids.length; i++) {
                const eid = eids[i];
                if (allEntitiesList.indexOf(eid) === -1) {
                    allEntitiesList.push(eid);
                    if (!updated) {
                        updated = true;
                    }
                }
            }
        });

        plotUnsub = xyzInstance.listenForEntityUpdate('plot', (state) => {
            const { views: viewsUpdate } = state;
            if (viewsUpdate) {
                allViewIds.splice(0, allViewIds.length);
                allViewIds.push(...viewsUpdate);
            }
        });
    }
    return {
        unsubToPlotWidthChange,
        stateUpdateUnsub,
        plotUnsub,
    };
}

const NUMBER_OF_SEGMENTS = [2, 3];
const SCALEBAR_SCALE = 0.3;
const MAX_SCALEBAR_LENGTH = 10000000;
const SCALEBAR_DEFAULT_STATE = {
    snapLength: 1,
    snapLengthPix: 1,
    numSegs: 2,
};

/**
 * TODO: Memoize this function
 * @param maxLength
 * @returns
 */
function generateScalebarSnapLengths(maxLength) {
    let currentLength = 1;
    const snapLengths = [];
    while (currentLength * 10 <= maxLength) {
        snapLengths.push(Math.floor(currentLength), Math.floor(currentLength * 2.5), Math.floor(currentLength * 5));
        currentLength *= 10;
    }
    return snapLengths;
}

function computeScaleBarState(screenLengthInThree, screenLengthInPix) {
    if (Number.isNaN(screenLengthInThree) || screenLengthInThree === 0 || Number.isNaN(screenLengthInPix)) {
        return {
            ...SCALEBAR_DEFAULT_STATE,
        };
    }
    const numberOfSegments = NUMBER_OF_SEGMENTS;
    const snapLengths = generateScalebarSnapLengths(MAX_SCALEBAR_LENGTH);
    const ratio = screenLengthInPix / screenLengthInThree;
    const scalebarWidth = screenLengthInPix * SCALEBAR_SCALE;
    // Find the #segments + segment length which is closest to the goal width
    const storage = {};
    let found = { snapIndex: -1, numSegs: -1, value: 0 };
    for (let i = 0; i < snapLengths.length; i++) {
        storage[i] = {};

        for (let j = numberOfSegments[0]; j <= numberOfSegments[1]; j++) {
            storage[i][j] = {
                snapIndex: i,
                numSegs: j,
                value: Math.abs(scalebarWidth - Math.floor(snapLengths[i] * j * ratio)),
            };
            if (i > 0) {
                if (storage[i - 1][j].value < storage[i][j].value) {
                    storage[i][j] = storage[i - 1][j];
                    found = storage[i][j];
                    break;
                }
            }
            if (j > numberOfSegments[0]) {
                if (storage[i][j - 1].value < storage[i][j].value) {
                    storage[i][j] = storage[i][j - 1];
                    found = storage[i][j];
                    break;
                }
            }
        }
    }
    const { numSegs, snapIndex } = found;

    return {
        snapLength: snapLengths[snapIndex],
        snapLengthPix: snapLengths[snapIndex] * ratio,
        numSegs,
        visible: true,
    };
}
