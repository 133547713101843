import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TreeNodeSelectorPathType, TreePathType } from 'Common/types/MainTreePathType';
import { AppState } from '../../store';
import { findNodeUnderPath, findNodeAtTreePath } from '../../../MainApp/TreeView/treeUtils';
import { MainTreeType } from '../../../util/ProjectDataTypes/MainTreeDataTypes';

const initialState: MainTreeType = null;

export const projectTreeSlice = createSlice({
    name: 'projectTree',
    initialState,
    reducers: {
        setProjectTree: (state, action: PayloadAction<MainTreeType>) => {
            Object.freeze(action.payload); // Freeze large object to improve immer performance
            try {
                validateUniqueTreeNodeIds(action.payload);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log('Duplicate nodeId found in this tree:', action.payload);
                throw e;
            }
            return action.payload;
        },
    },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function validateUniqueTreeNodeIds(tree: MainTreeType, nodeIdsSet: Set<string> = new Set<string>()) {
    Object.values(tree ?? {}).forEach((node) => {
        const { nodeId } = node;
        if (nodeIdsSet.has(nodeId)) {
            throw new Error(
                `Duplicate nodeId ${nodeId}. Do not delete this error. Fix your code to make sure no duplicate ids are generated because some code relies on having unique ids.`
            );
        }
        nodeIdsSet.add(nodeId);
        if (node.subsections) {
            validateUniqueTreeNodeIds(node.subsections, nodeIdsSet);
        }
    });
}

export const { setProjectTree } = projectTreeSlice.actions;

export const selectProjectTree = (state: AppState): MainTreeType => state.projectTree;

export const useSelectProjectTreeUnderTreePath = (treePath: TreePathType) =>
    function selectProjectTreeUnderTreePath(state: AppState) {
        const { projectTree } = state;
        return findNodeAtTreePath(projectTree, treePath);
    };

export const selectProjectTreeUnderPath = (path: TreeNodeSelectorPathType) => (state: AppState) => {
    const { projectTree } = state;
    return findNodeUnderPath(projectTree, path, false);
};

export default projectTreeSlice.reducer;
