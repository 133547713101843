import { getZoneSectionMenu } from 'App/MainApp/TreeView/menu/sectionMenus';
import { buildZoneFromAnisotropySubtrees } from 'App/MainApp/TreeView/treeData/commonNodesService';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { getActiveObjects } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { ProjectTreeNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';
import { ZoneFromAnisotropyType } from 'App/util/ProjectDataTypes/ZoneFromAnisotropy';
import { zonesSectionDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { getZoneFromAnisotropyNode } from './zonesFromAnisotropyNodeService';

export function buildZonesFromAnisotropySubtree(allObjects: ObjectClassName2Id2Obj, tree: Subsections) {
    if (!tree.hasOwnProperty(SectionNames.Zones)) {
        tree[SectionNames.Zones] = {
            section: SectionNames.Zones,
            dataCy: zonesSectionDataCy,
            rightClickMenu: getZoneSectionMenu(),
            subsections: {},
            nodeId: SectionIds[SectionNames.Zones],
            path: [],
        };
    }

    buildZonesAnisotropySubtree(allObjects, tree[SectionNames.Zones]);
}

function buildZonesAnisotropySubtree(allObjects: ObjectClassName2Id2Obj, parentNode: ProjectTreeNode) {
    const zones = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.ZoneFromAnisotropy);

    buildZoneFromAnisotropySubtrees(zones, allObjects, parentNode);
    buildZoneFromAnisotropyNodes(zones, allObjects, parentNode.subsections);
}

function buildZoneFromAnisotropyNodes(
    zones: ZoneFromAnisotropyType[],
    allObjects: ObjectClassName2Id2Obj,
    subtree: Subsections
) {
    zones.forEach((zone) => {
        const zoneInstance = wrapObjectInClass(zone, null, allObjects);

        const domainNode = subtree[zoneInstance.getDomain().id];

        const zoneFromAnisotropyNode = getZoneFromAnisotropyNode(zone, domainNode);

        domainNode.subsections[zone.id] = zoneFromAnisotropyNode;
    });
}
