import { gridObjectMenu, interpolationObjectMenu, zoneObjectMenu } from 'App/MainApp/TreeView/menu/objectMenus';
import { SectionNames, mergedDomainId } from 'App/MainApp/TreeView/treeData/treeConsts';
import { getActiveObjects, getCommonData, getNodePath } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { DomainType } from 'App/util/ProjectDataTypes/Domain';
import { DomainGrid, DomainGridDefinitionType } from 'App/util/ProjectDataTypes/DomainGridDefinition';
import { Grid, GridDefinitionType } from 'App/util/ProjectDataTypes/GridDefinition';
import { ProjectTreeNode, ProjectTreeObjectNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { PointEstimation, PointEstimationType } from 'App/util/ProjectDataTypes/PointEstimation';
import {
    Id2AnisotropyObject,
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
    ObjectIDType,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { Zone, ZoneType } from 'App/util/ProjectDataTypes/Zone';
import { ZoneFromAnisotropyType } from 'App/util/ProjectDataTypes/ZoneFromAnisotropy';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';
import ClosedMeshIcon from 'Common/components/icons/objects/ClosedMeshIcon';
import { mergedInterpolationsDomainDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { buildAnisotropyDomainNodes } from './anisotropySectionService';

export function getDomainGroupNode(domain: DomainType, parentNode: ProjectTreeNode) {
    const domainNode: ProjectTreeObjectNode = {
        ...getCommonData(domain, parentNode),
        dataCy: domain.name,
        rightClickMenu: [],
        section: domain.name,
        subsections: {},
        iconOverride: ClosedMeshIcon,
    };

    return domainNode;
}

export function getGridGroupNode(
    grid: GridDefinitionType,
    showGridRightClickMenu: boolean,
    noSpotlight = false,
    parentNode: ProjectTreeNode
) {
    const gridNode: ProjectTreeObjectNode = {
        ...getCommonData(grid, parentNode),
        dataCy: grid.name,
        section: grid.name,
        rightClickMenu: showGridRightClickMenu ? gridObjectMenu : [],
        canDisplaySpotlight: !noSpotlight,
        subsections: {},
    };

    return gridNode;
}

export function getDomainGridGroupNode(
    domainGrid: DomainGridDefinitionType,
    domain: DomainType,
    parentNode: ProjectTreeNode
) {
    const domainGridNode = {
        ...getCommonData(domainGrid, parentNode),
        dataCy: domain.name,
        rightClickMenu: [],
        section: domain.name,
        subsections: {},
        iconOverride: ClosedMeshIcon,
    };

    return domainGridNode;
}

function getMergedDomainNode(subTreeType: GridsSubtreeType, parentNode: ProjectTreeNode, gridId: ObjectIDType) {
    const mergedDomainNode: ProjectTreeObjectNode = {
        id: `${parentNode.nodeId}_${mergedDomainId}`,
        nodeId: getMergedDomainNodeId(subTreeType, gridId),
        section: SectionNames.MergedInterpolations,
        className: OBJECT_CLASS_NAMES.Domain,
        dataCy: mergedInterpolationsDomainDataCy,
        rightClickMenu: [],
        subsections: {},
        path: getNodePath(parentNode),
    };

    return mergedDomainNode;
}

export function getMergedDomainNodeId(subTreeType: GridsSubtreeType, gridId: string): string {
    return `${subTreeType}-${gridId}-${mergedDomainId}`;
}

function getAllUsedGridsAndDomainGrids(objectInstances: (PointEstimation | Zone)[]) {
    const gridsMap: { [id: ObjectIDType]: Grid } = {};
    const domainGridsMap: { [id: ObjectIDType]: DomainGrid } = {};
    const objectsWithMergedDomain: (PointEstimation | Zone)[] = [];
    const objectsWithRegularDomain: (PointEstimation | Zone)[] = [];

    objectInstances.forEach((object) => {
        const grid = object.getGridDefinition();
        gridsMap[grid.id] = grid;

        const domainGrid = object.getDomainGridDefinition();
        if (object.isMergedDomain()) {
            objectsWithMergedDomain.push(object);
        } else {
            objectsWithRegularDomain.push(object);
            domainGridsMap[domainGrid.id] = domainGrid;
        }
    });

    return {
        grids: Object.values(gridsMap),
        domainGrids: Object.values(domainGridsMap),
        objectsWithMergedDomain,
        objectsWithRegularDomain,
    };
}

export enum GridsSubtreeType {
    Interpolation = 'Interpolation',
    Zone = 'Zone',
}

function getAnisotropiesHavingZones(zones: ZoneFromAnisotropyType[], allObjects: ObjectClassName2Id2Obj) {
    const anisotropyMap: Id2AnisotropyObject = {};

    zones.forEach((zone) => {
        const zoneInstance = wrapObjectInClass(zone, null, allObjects);
        let anisotropy = null;
        if (zoneInstance.anisotropyEstimationId) {
            anisotropy = zoneInstance.getAnisotropyEstimation();
        } else if (zoneInstance.anisotropyGridId) {
            anisotropy = zoneInstance.getAnisotropyGrid();
        } else if (zoneInstance.anisotropyGlobalId) {
            anisotropy = zoneInstance.getAnisotropyGlobal();
        }
        anisotropyMap[anisotropy.id] = anisotropy;
    });

    return Object.values(anisotropyMap);
}

export function buildZoneFromAnisotropySubtrees(
    objects: ZoneFromAnisotropyType[],
    allObjects: ObjectClassName2Id2Obj,
    parentNode: ProjectTreeNode
) {
    const objectInstances = objects.map((object) => wrapObjectInClass(object, null, allObjects));
    const anisotropiesWithZones = getAnisotropiesHavingZones(objectInstances, allObjects);
    buildAnisotropyDomainNodes(anisotropiesWithZones, allObjects, parentNode.subsections, parentNode);
}

export function buildGridsSubtrees(
    objects: (PointEstimationType | ZoneType)[],
    allObjects: ObjectClassName2Id2Obj,
    parentNode: ProjectTreeNode,
    subTreeType: GridsSubtreeType,
    showAllGrids: boolean = false,
    noSpotlight = false
) {
    const objectInstances = objects.map((object) => wrapObjectInClass(object, null, allObjects));
    const {
        grids: usedGrids,
        domainGrids,
        objectsWithMergedDomain,
        objectsWithRegularDomain,
    } = getAllUsedGridsAndDomainGrids(objectInstances);

    const allGrids = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.GridDefinition).map((grid) =>
        wrapObjectInClass(grid, null, allObjects)
    );

    const grids = showAllGrids ? allGrids : usedGrids;

    const gridsSubtree = parentNode.subsections;

    attachGridNodes(grids, gridsSubtree, parentNode, showAllGrids, noSpotlight);

    attachDomainGridNodes(domainGrids, gridsSubtree);

    attachObjectNodes(objectsWithRegularDomain, gridsSubtree, noSpotlight);

    attachObjectsWithMergedDomain(subTreeType, objectsWithMergedDomain, gridsSubtree, noSpotlight);
}

function attachGridNodes(
    grids: Grid[],
    subtree: Subsections,
    parentNode: ProjectTreeNode,
    showGridRightClickMenu: boolean,
    noSpotlight = false
) {
    grids.forEach((grid) => {
        subtree[grid.id] = getGridGroupNode(grid, showGridRightClickMenu, noSpotlight, parentNode);
    });
}

function attachDomainGridNodes(domainGrids: DomainGrid[], subtree: Subsections) {
    domainGrids.forEach((domainGrid) => {
        const grid = domainGrid.getGridDefinition();
        const gridNode = subtree[grid.id];
        gridNode.subsections[domainGrid.id] = getDomainGridGroupNode(domainGrid, domainGrid.getDomain(), gridNode);
    });
}

function attachObjectNodes(objectInstances: (PointEstimation | Zone)[], subtree: Subsections, noSpotlight = false) {
    objectInstances.forEach((object) => {
        const grid = object.getGridDefinition();
        const domainGrid = object.getDomainGridDefinition();

        const domainNode = subtree[grid.id].subsections[domainGrid.id];

        domainNode.subsections[object.id] = getPointEstimationOrZoneNode(object, noSpotlight, domainNode);
    });
}

function getPointEstimationOrZoneNode(
    object: PointEstimation | Zone,
    noSpotlight = false,
    parentNode: ProjectTreeNode
) {
    const node: ProjectTreeObjectNode = {
        ...getCommonData(object, parentNode),
        dataCy: object.name,
        rightClickMenu: object instanceof PointEstimation ? interpolationObjectMenu : zoneObjectMenu,
        section: object.name,
        canDisplaySpotlight: !noSpotlight,
    };

    return node;
}

function attachObjectsWithMergedDomain(
    subTreeType: GridsSubtreeType,
    objectInstances: (PointEstimation | Zone)[],
    subtree: Subsections,
    noSpotlight = false
) {
    objectInstances.forEach((object) => {
        const grid = object.getGridDefinition();
        attachMergedDomainNodeIfNeeded(subTreeType, grid, subtree);

        const mergedDomainsNode = subtree[grid.id].subsections[getMergedDomainNodeId(subTreeType, grid.id)];
        mergedDomainsNode.subsections[object.id] = getPointEstimationOrZoneNode(object, noSpotlight, mergedDomainsNode);
    });
}

function attachMergedDomainNodeIfNeeded(subTreeType: GridsSubtreeType, grid: Grid, subtree: Subsections) {
    const gridNode = subtree[grid.id];
    if (!gridNode.subsections[getMergedDomainNodeId(subTreeType, grid.id)]) {
        gridNode.subsections[getMergedDomainNodeId(subTreeType, grid.id)] = getMergedDomainNode(
            subTreeType,
            gridNode,
            grid.id
        );
    }
}
