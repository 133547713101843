import update from 'immutability-helper';
import { getMinMaxDataAttributeConcentration } from 'App/Redux/features/globalContext/currentProjectUtils';
import { DrillingAttributeType } from 'App/util/ProjectDataTypes/SourceFile';
import {
    ValidationResult,
    getDefaultValidationResult,
    validateGreaterOrEqual,
    validateGreaterThan,
    validateNotEmpty,
    validateSmallerOrEqual,
} from '../../../util/validationUtils';
import {
    MultiUnitTextFieldOptionsSetter,
    MultiUnitTextFieldOptionsType,
    MultiUnitTextFieldValueTypes,
} from '../Shared/MultiUnitTextFieldNew';

export const seedThresholdErrorMessage =
    'Concentration threshold can be used only on a single element in a single domain.';

export function isMaxFeatureLengthValid(
    maxFeatureLength: string,
    drillingCornerToCornerDistance: number
): ValidationResult {
    const validationResult = getDefaultValidationResult();

    validateNotEmpty(maxFeatureLength, validationResult);

    if (validationResult.isValid) {
        const maxFeatureLengthNumber = Number(maxFeatureLength);

        validateGreaterThan(maxFeatureLengthNumber, 0, validationResult);
        validateSmallerOrEqual(maxFeatureLengthNumber, drillingCornerToCornerDistance, validationResult);
    }

    return validationResult;
}

export function isValidNumOfSelectedItemsForConcentration(
    numOfDataAttributesSelected: number,
    numOfDomainsSelected: number
): boolean {
    return numOfDataAttributesSelected === 1 && numOfDomainsSelected === 1;
}

function validateConcentrationThreshold(
    concentration: number,
    attributeInDomain: DrillingAttributeType,
    validationResult: ValidationResult
) {
    const minMaxConcentration = getMinMaxDataAttributeConcentration(attributeInDomain);
    validateGreaterOrEqual(concentration, minMaxConcentration.min, validationResult);
    validateSmallerOrEqual(concentration, minMaxConcentration.max, validationResult);
}

export function isEvaluateDataAboveConcentrationValid(
    concentration: string,
    numOfDataAttributesSelected: number,
    numOfDomainsSelected: number,
    attributeInDomain: DrillingAttributeType
): ValidationResult {
    const validationResult = getDefaultValidationResult();

    validateNotEmpty(concentration, validationResult);

    if (!isValidNumOfSelectedItemsForConcentration(numOfDataAttributesSelected, numOfDomainsSelected)) {
        validationResult.isValid = false;
        validationResult.error = seedThresholdErrorMessage;
    }

    if (validationResult.isValid) {
        const concentrationNumber = Number(concentration);
        validateConcentrationThreshold(concentrationNumber, attributeInDomain, validationResult);
    }

    return validationResult;
}

export function isEpsilonValueValid(epsilonValue: string): ValidationResult {
    const validationResult = getDefaultValidationResult();

    validateNotEmpty(epsilonValue, validationResult);

    if (validationResult.isValid) {
        const epsilonValueNumber = Number(epsilonValue);

        validateGreaterThan(epsilonValueNumber, 0, validationResult);
    }

    return validationResult;
}

export function isEvaluateDataAbovePercentileValid(percentile: string): ValidationResult {
    const validationResult = getDefaultValidationResult();

    validateNotEmpty(percentile, validationResult);

    if (validationResult.isValid) {
        const percentileNumber = Number(percentile);

        validateGreaterThan(percentileNumber, 0, validationResult);
        validateSmallerOrEqual(percentileNumber, 100, validationResult);
    }

    return validationResult;
}

export function disableConcentrationOptionWhenNeeded(
    set_seed_value_threshold_options: MultiUnitTextFieldOptionsSetter,
    seed_value_threshold_options: MultiUnitTextFieldOptionsType,
    num_dataAttributes_selected: number,
    num_domains_selected: number
) {
    set_seed_value_threshold_options((oldState: MultiUnitTextFieldOptionsType) => {
        const concentrationOptionIndex = seed_value_threshold_options.findIndex(
            (option) => option.type === MultiUnitTextFieldValueTypes.CONCENTRATION
        );
        const updateCommand = {};
        const disableConcentration = !isValidNumOfSelectedItemsForConcentration(
            num_dataAttributes_selected,
            num_domains_selected
        );
        updateCommand[concentrationOptionIndex] = {
            disabled: {
                $set: disableConcentration,
            },
            tooltipText: {
                $set: disableConcentration ? seedThresholdErrorMessage : '',
            },
        };

        return update(oldState, updateCommand);
    });
}
