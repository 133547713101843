import { Message } from '@local/messages/dist/Messages.types';
import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';

const ONE_YEAR_MS = 365 * 24 * 60 * 60 * 1000;

export function useDriverMessagesContext() {
    const messagesContext = useMessagesContext();

    const addDriverMessage: ReturnType<typeof useMessagesContext>['addMessage'] = (message: Message, ...rest) => {
        const finalMessage: Message = message;

        if (message.type === NotificationType.ERROR) {
            finalMessage.autoDismissMs = ONE_YEAR_MS;
        }

        messagesContext.addMessage(finalMessage, ...rest);
    };
    return {
        ...messagesContext,
        addMessage: addDriverMessage,
    };
}
