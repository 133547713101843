import { BoundsObject } from 'Common/types/geometryTypes';
import { getDistanceBetweenPoints } from 'Common/utils/geometryHelpers';
import { TEXTBOX_ERROR_MESSAGES, ValidationResult } from '../../../util/validationUtils';
import { AxiosDriverFlaskInstance } from '../../../util/axiosErrorHandlers';
import { APIResponseWithProjectUpdate } from '../../../util/ProjectDataTypes/APIResponseTypes';
import { backendProjectUrl } from 'App/Redux/features/globalContext/currentProjectSlice';

export interface ClipRegionWithSurfaceArgs {
    bounds: {
        xmin: number;
        ymin: number;
        zmin: number;
        xmax: number;
        ymax: number;
        zmax: number;
    };
    chosenOpenMesh: string | number;
    resolution: number;
    retainBelow: boolean;
    retainAbove: boolean;
    retainBox: boolean;
    belowMeshName: string;
    aboveMeshName: string;
    boxMeshName: string;
}

export async function clipRegionWithSurface(
    data: ClipRegionWithSurfaceArgs,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentProjectId: string,
    orgId: string,
    workspaceId: string
) {
    try {
        const response = await axiosDriverFlask.post<APIResponseWithProjectUpdate>(
            `${backendProjectUrl(orgId, workspaceId, currentProjectId)}/mesh/from_region_clip_surface`,
            data,
            {
                withCredentials: true,
                responseType: 'json',
            }
        );
        return response.data;
    } catch {
        return null;
    }
}

export function convertBoundKeys(bounds: BoundsObject) {
    const newBounds = {
        xmin: bounds.minX,
        ymin: bounds.minY,
        zmin: bounds.minZ,
        xmax: bounds.maxX,
        ymax: bounds.maxY,
        zmax: bounds.maxZ,
    };

    return newBounds;
}

export function getDefaultResolution(bounds: BoundsObject) {
    const cornerToCornerDistance = getDistanceBetweenPoints(bounds);
    const defaulResolution = Math.round(cornerToCornerDistance / 100);
    return defaulResolution;
}

export function isResolutionValid(resolution: string, defaulResolution: number): ValidationResult {
    if (!resolution) {
        return {
            isValid: false,
            error: TEXTBOX_ERROR_MESSAGES.cannotBeEmpty,
        };
    }

    const resolutionNumber = Number(resolution);
    const minResolution = defaulResolution * 100;

    if (resolutionNumber > 0 && resolutionNumber <= minResolution) {
        return {
            isValid: true,
            error: '',
        };
    }

    return {
        isValid: resolutionNumber > 0 && resolutionNumber <= minResolution,
        error: `Resolution must be between 0 and ${minResolution}`,
    };
}
