import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import DeleteButton from 'App/MainApp/TreeView/menu/menuItems/generic/DeleteButton';
import { ProjectTreeNode, ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { treeMenuDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create({
    popper: {
        zIndex: 10000,
    },
    clickAwayListener: {
        zIndex: 10000,
    },
});

interface PropsType {
    open: boolean;
    anchorEl: HTMLElement;
    handleClose(): void;
    secondaryMenu?: boolean;
    node: ProjectTreeNode;
    multipleNodes?: ProjectTreeObjectNode[];
}

export default function TreeMenu(props: PropsType) {
    const { classes } = useStyles();
    const menuItemComponents = props.node.rightClickMenu ?? [];

    const multipleNodes = props.multipleNodes && props.multipleNodes.length > 1 ? props.multipleNodes : null;

    const onContextMenu = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const popperPlacement =
        props.anchorEl && window.innerHeight - props.anchorEl.getBoundingClientRect().bottom < 100
            ? props.secondaryMenu
                ? 'right'
                : 'top-start'
            : 'right';

    const modifiers = props.secondaryMenu
        ? [
              {
                  name: 'offset',
                  options: {
                      offset: [20, 1],
                  },
              },
          ]
        : [
              {
                  name: 'offset',
                  options: {
                      offset: [0, 10],
                  },
              },
          ];

    return (
        <Popper
            id={treeMenuDataCy}
            className={classes.popper}
            placement={popperPlacement}
            open={props.open}
            anchorEl={props.anchorEl}
            transition
            modifiers={modifiers}
            onClick={onClick}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <Paper onContextMenu={onContextMenu}>
                        <ClickAwayListener
                            disableReactTree
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={props.handleClose}
                        >
                            <MenuList autoFocusItem={Boolean(props.anchorEl)} data-cy={treeMenuDataCy}>
                                {multipleNodes /* only currently support delete with multi select */ && (
                                    <DeleteButton
                                        handleMenuClose={props.handleClose}
                                        nodeData={null}
                                        multipleNodes={multipleNodes}
                                    />
                                )}
                                {!multipleNodes &&
                                    menuItemComponents.map((MenuItemComponent, index) => {
                                        return (
                                            <MenuItemComponent
                                                nodeData={'id' in props.node ? props.node : null}
                                                handleMenuClose={props.handleClose}
                                                key={index}
                                            />
                                        );
                                    })}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
}
