import { CloseIcon } from '@local/web-design-system/dist/icons';
import Paper, { PaperProps } from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import ErrorBoundary from 'Common/components/ErrorHandling/ErrorBoundary';
import GenericDialogActions, { GenericDialogActionsProps } from 'Common/components/GenericDialog/GenericDialogActions';
import classNames from 'classnames';
import React from 'react';
import Draggable from 'react-draggable';
import { tss } from 'tss-react/mui';
import { closeButtonDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

const useStyles = tss.create(({ theme }) => ({
    header: {
        cursor: 'move',
        padding: '30px',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },

    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '16px',
        margin: '15px',
        cursor: 'pointer',
    },

    content: {
        // a dirty tss hack to increase selector specificity and make sure we override original styles
        '&&': {
            paddingTop: '20px',
        },
    },

    footer: {
        flexDirection: 'column',
        rowGap: '40px',
        paddingBottom: '30px',
    },

    divider: {
        width: '100%',
    },
    greyBackground: {
        backgroundColor: theme.palette.grey[50],
    },
}));

function PaperComponent(props: PaperProps) {
    const draggableChildRef = React.useRef(null);
    return (
        <Draggable
            nodeRef={draggableChildRef}
            handle="#draggable-dialog-title"
            cancel='[class*="MuiDialogContent-root"]'
            bounds="parent"
        >
            <Paper ref={draggableChildRef} {...props} />
        </Draggable>
    );
}

type PropTypes = {
    title: string | React.ReactNode;
    children: React.ReactNode;
    maxWidth?: DialogProps['maxWidth'];
    dataCy: string;
    subtitle?: string;
    handleClose: () => void;
    sx?: DialogProps['sx'];
    greyBackground?: boolean;
};

export default function GenericDialogShell(props: PropTypes & Partial<GenericDialogActionsProps>) {
    const { classes } = useStyles();

    const showActions = props.onSubmit || props.onCancel;

    return (
        <ErrorBoundary>
            <Dialog
                open
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                maxWidth={props.maxWidth}
                fullWidth
                data-cy={props.dataCy}
                sx={props.sx}
            >
                <DialogTitle
                    className={classes.header}
                    id="draggable-dialog-title"
                    {
                        ...({
                            component: 'div',
                        } as any) /* hack because `component` is not in DialogTitle's prop defs despite what the docs say */
                    }
                >
                    <CloseIcon
                        onClick={props.handleClose}
                        className={classes.closeButton}
                        data-cy={closeButtonDataCy}
                    />
                    <Typography variant="h3">{props.title}</Typography>
                    {props.subtitle && <Typography variant="caption">{props.subtitle}</Typography>}
                </DialogTitle>
                <DialogContent
                    className={classNames(classes.content, { [classes.greyBackground]: props.greyBackground })}
                    sx={{
                        // backgroundColor: props.greyBackground ? '#FAFAFA' : undefined,
                        paddingBottom: showActions ? 7 : undefined,
                    }}
                >
                    {props.children}
                </DialogContent>
                {showActions && (
                    <Grid container className={classes.footer}>
                        <Divider className={classes.divider} />
                        <GenericDialogActions
                            onSubmit={props.onSubmit}
                            onCancel={props.onCancel}
                            submitText={props.submitText}
                            cancelText={props.cancelText}
                            showSpinner={props.showSpinner}
                            disabled={props.disabled}
                            className={props.className}
                            noCancel={props.noCancel}
                            noSubmit={props.noSubmit}
                            centerBottons={props.centerBottons}
                        />
                    </Grid>
                )}
            </Dialog>
        </ErrorBoundary>
    );
}
