// import { useCallback } from 'react';
// import { useDispatch, useStore } from 'react-redux';

import { Snapshot, UpdateSnapshot } from '@seequent/xyz';

import React from 'react';
import { useBaseXyz } from './useBaseXyz';
import { DEFAULT_TWEEN } from '../constants';

function createSnapshotForPlotViews(views: string[]): UpdateSnapshot {
    return { plot: { views } };
}

function createSnapshotForPlotTags(tags: string[]): UpdateSnapshot {
    return { plot: { tags } };
}

export function useXyz() {
    /**
     * This hook wraps the xyz instance and extends useBaseXyz, with
     * the addition of a redux store for accumulating the snapshot updates
     * which were sent to xyz + redux for computing selectors based on the
     * `artifact` state
     */
    const baseXyz = useBaseXyz();
    // const dispatch = useDispatch();
    // const store = useStore();

    global.xyz = baseXyz;

    const { clearSlice, dispose, disposeEntity, destroy, setStateFromSnapshot, getState } = baseXyz;
    const updateVisualization = React.useCallback(
        (snapshot: UpdateSnapshot, tween = DEFAULT_TWEEN) => setStateFromSnapshot(snapshot, { tween }),
        [setStateFromSnapshot]
    );

    const updateVisualizationWithoutTween = React.useCallback(
        (snapshot: UpdateSnapshot) => {
            return setStateFromSnapshot?.(snapshot, {});
        },
        [setStateFromSnapshot]
    );

    const clearSelectedVisualizationEntities = React.useCallback(
        (entities: string[]) => {
            // dispatch(clearSelectedVisualizationSnapshotEntities(entities));
            entities.forEach((entityId) => {
                disposeEntity(entityId);
            });
        },
        [disposeEntity]
    );

    const clearVisualization = React.useCallback(() => {
        clearSlice();
        // dispatch(clearVisualizationSnapshotEntities(noTreeCollapse));
        return dispose();
    }, [clearSlice, dispose]);

    const teardownVisualization = React.useCallback(
        () =>
            // dispatch(destroyVisualizationSnapshotState());
            destroy(),
        [destroy]
    );

    const addViewToPlot = React.useCallback(
        (viewId: string) => {
            const views = plotViews(getState?.());
            if (views.includes(viewId)) {
                return Promise.resolve();
            }
            const snapshot = createSnapshotForPlotViews([...views, viewId]);
            return updateVisualizationWithoutTween(snapshot);
        },
        [getState, createSnapshotForPlotViews, updateVisualizationWithoutTween]
    );

    const reloadViewInPlot = React.useCallback(
        async (viewId: string) => {
            if (!getState) {
                return;
            }
            const existingViews = plotViews(getState());
            const viewsToKeep = existingViews.filter((view) => view !== viewId);
            await updateVisualizationWithoutTween(createSnapshotForPlotViews(viewsToKeep));
            return updateVisualizationWithoutTween(createSnapshotForPlotViews(existingViews));
        },
        [getState, createSnapshotForPlotViews, updateVisualizationWithoutTween]
    );

    const removeViewsFromPlot = React.useCallback(
        (viewIds: string[]) => {
            if (!getState) {
                return;
            }
            const existingViews = plotViews(getState());
            const viewsToKeep = existingViews.filter((view) => !viewIds.includes(view));
            return updateVisualizationWithoutTween(createSnapshotForPlotViews(viewsToKeep));
        },
        [getState, createSnapshotForPlotViews, updateVisualizationWithoutTween]
    );

    const addTagToPlot = React.useCallback(
        (tagId: string) => {
            const tags = plotTags(getState());
            if (tags.includes(tagId)) {
                return;
            }
            const snapshot = createSnapshotForPlotTags([...tags, tagId]);
            return updateVisualizationWithoutTween(snapshot);
        },
        [getState, createSnapshotForPlotTags, updateVisualizationWithoutTween]
    );

    const removeTagFromPlot = React.useCallback(
        (tagId: string) => {
            if (!getState) {
                return;
            }
            const tags = plotTags(getState()).filter((id) => id !== tagId);
            const snapshot = createSnapshotForPlotTags(tags);
            return updateVisualizationWithoutTween(snapshot);
        },
        [getState, createSnapshotForPlotTags, updateVisualizationWithoutTween]
    );

    const ret = React.useMemo(
        () => ({
            ...baseXyz,
            clearSlice,
            updateVisualization,
            updateVisualizationWithoutTween,
            clearVisualization,
            teardownVisualization,
            clearSelectedVisualizationEntities,
            addViewToPlot,
            reloadViewInPlot,
            addTagToPlot,
            removeTagFromPlot,
            removeViewsFromPlot,
        }),
        [
            baseXyz,
            clearSlice,
            updateVisualization,
            updateVisualizationWithoutTween,
            clearVisualization,
            teardownVisualization,
            clearSelectedVisualizationEntities,
            addViewToPlot,
            reloadViewInPlot,
            addTagToPlot,
            removeTagFromPlot,
            removeViewsFromPlot,
        ]
    );

    if (!baseXyz) {
        return undefined;
    }

    return ret;
}

function plotViews(xyzSnapshot: Snapshot): string[] {
    return xyzSnapshot?.plot?.views ?? [];
}

function plotTags(xyzSnapshot: Snapshot): string[] {
    return xyzSnapshot?.plot?.tags ?? [];
}
