import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
//   }

export type plotOverlays = {
    scaleBar: {
        visible: boolean;
    };
    orientationWidget: {
        visible: boolean;
    };
};

const initialState: plotOverlays = {
    scaleBar: {
        visible: true,
    },
    orientationWidget: {
        visible: true,
    },
};

export const plotOverlaysSlice = createSlice({
    name: 'plotOverlays',
    initialState,
    reducers: {
        setscaleBarVisible: (state, action: PayloadAction<boolean>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.scaleBar.visible = action.payload;
        },
        // The `extraReducers` field lets the slice handle actions defined elsewhere,
        // including actions generated by createAsyncThunk or in other slices.
        // extraReducers: (builder) => {
        //   builder
        //     .addCase(incrementAsync.pending, (state) => {
        //       state.status = 'loading'
        //     })
        //     .addCase(incrementAsync.fulfilled, (state, action) => {
        //       state.status = 'idle'
        //       state.value += action.payload
        //     })
        // },
    },
});

// Action creators are generated for each case reducer function
export const { setscaleBarVisible } = plotOverlaysSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useAppSelector((state: RootState) => state.counter.value)`
export const isScalebarVisible = (state: AppState) => state.plotOverlays.scaleBar.visible;
export const isOrientationVisible = (state: AppState) => state.plotOverlays.orientationWidget.visible;

export default plotOverlaysSlice.reducer;
