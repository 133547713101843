import { createSlice } from '@reduxjs/toolkit';
import React from 'react';
import { AppState } from '../../store';

export interface DialogComponentData<DialogComponentType extends React.FC<object> = React.FC<object>> {
    dialogComponent: DialogComponentType;
    dialogProps?: React.ComponentProps<DialogComponentType>;
}

export type DialogData<DialogComponentType extends React.FC<object> = React.FC<object>> =
    DialogComponentData<DialogComponentType> & {
        dialogId: string;
    };

type DialogsMap = { [key: string]: DialogData };

const dialogsSlice = createSlice({
    name: 'dialogs',
    initialState: {} as DialogsMap,
    reducers: {
        openDialog: (state, action: { payload: DialogData }) => {
            state[action.payload.dialogId] = action.payload;
        },
        closeDialog: (state, action: { payload: { dialogId: string } }) => {
            delete state[action.payload.dialogId];
        },
        closeAllDialogs: (state) => {
            for (const key in state) {
                delete state[key];
            }
        },
    },
});

export const selectNumberOfDialogs = (state: AppState) => Object.keys(state.dialogs).length;

export const { openDialog, closeDialog, closeAllDialogs } = dialogsSlice.actions;

export const selectDialogsSlice = (state: AppState) => state.dialogs;

export default dialogsSlice.reducer;
