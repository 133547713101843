import { ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { spotlightButtonDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { setOpenSpotlight } from 'App/Redux/features/spotLight/openSpotlightSlice';
import { setSceneObjectFocus } from 'App/Redux/features/spotLight/sceneObjectFocusSlice';
import { useAppDispatch } from 'App/Redux/hooks';
import SpotlightIcon from 'Common/components/icons/SpotlightIcon';
import GenericIconButton from 'Common/components/Core/GenericIconButton';

export default function SpotlightButton(props: {
    nodeData: ProjectTreeObjectNode;
    visible: boolean;
    className?: string;
    forceHover?: boolean;
}) {
    const dispatch = useAppDispatch();

    const handleShowSpotlightButtonClick = () => {
        dispatch(
            setSceneObjectFocus({
                nodeId: props.nodeData.nodeId,
                objectId: props.nodeData.id,
                objectClassName: props.nodeData.className,
                name: props.nodeData.section,
            })
        );
        dispatch(setOpenSpotlight(true));
    };

    return (
        <GenericIconButton
            dataCy={spotlightButtonDataCy}
            Icon={SpotlightIcon}
            iconProps={{ sx: { fontSize: 12 } }}
            tooltipText="Show spotlight"
            onClick={handleShowSpotlightButtonClick}
            style={{
                visibility: props.visible ? 'visible' : 'hidden',
                height: '100%',
                padding: '0.4rem',
            }}
            disablePortal
            buttonClassName={props.className}
            forceHover={props.forceHover}
        />
    );
}
