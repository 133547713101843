import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';

export interface WidgetTooltipProps extends TooltipProps {
    forceClose?: boolean;
    disablePortal?: boolean;
}

export default function WidgetTooltip(props: WidgetTooltipProps) {
    const [open, setOpen] = React.useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { forceClose, disablePortal, ...restProps } = props;
    // const onMouseOver = React.useCallback(()=>{
    //     setOpen(true);
    // }, [])

    const onMouseEnter = React.useCallback(() => {
        setOpen(true);
    }, []);

    const onMouseLeave = React.useCallback(() => {
        setOpen(false);
    }, []);

    const handleClick = React.useCallback(() => {
        setOpen(false);
    }, []);

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open);
        }
    }, [props.open]);

    React.useEffect(() => {
        setOpen(false);
    }, [props.title]);

    return (
        <Tooltip
            // onMouseOver={onMouseOver}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={handleClick}
            disableInteractive
            {...restProps}
            open={!forceClose && open}
            PopperProps={{
                disablePortal: !!props.disablePortal,
            }}
        />
    );
}
