import { useContext, createContext, useState, useMemo } from 'react';
import { setAxiosInterceptors, AxiosDriverFlaskInstance } from '../util/axiosErrorHandlers';
import axios from 'axios';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useLaunchDarkly } from 'Common/customHooks/useLaunchDarkly';
import { getCurrentOrgId } from 'Common/api/AuthUtils';

const SessionsContext = createContext<{
    axiosDriverFlask: AxiosDriverFlaskInstance | null;
    loginSessionTerminated: boolean;
    setAnonymousUser: () => void;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setLoginSessionTerminated: (value: boolean) => void;
    terminateLoginSession: () => void;
}>({
    axiosDriverFlask: null,
    loginSessionTerminated: false,
    setAnonymousUser: () => {},
    setLoginSessionTerminated: () => {},
    terminateLoginSession: () => {},
});

function SessionProvider({ children, axiosDriverFlask }) {
    const [loginSessionTerminated, setLoginSessionTerminated] = useState<boolean>(false);

    const { addMessage } = useDriverMessagesContext();
    const currentOrgUuid = getCurrentOrgId();
    const { setAnonymousUser } = useLaunchDarkly(currentOrgUuid);

    const terminateLoginSession = () => {
        setLoginSessionTerminated(true);
        setAnonymousUser();
    };

    const axiosDriverFlaskFinal = useMemo(() => {
        let instance: AxiosDriverFlaskInstance;

        if (axiosDriverFlask) {
            instance = axiosDriverFlask;
        } else {
            instance = axios.create();
        }

        setAxiosInterceptors(instance, addMessage, terminateLoginSession);
        return instance;
    }, [currentOrgUuid]);

    return (
        <SessionsContext.Provider
            value={{
                axiosDriverFlask: axiosDriverFlaskFinal,
                loginSessionTerminated,
                setAnonymousUser,
                setLoginSessionTerminated,
                terminateLoginSession,
            }}
        >
            {children}
        </SessionsContext.Provider>
    );
}

const useSessionContext = () => useContext(SessionsContext);

export { useSessionContext, SessionProvider, SessionsContext };
