import React from 'react';
import { tss } from 'tss-react/mui';
import clsx from 'clsx';
import { mergeRefs } from '../../utils/ReactUtils';
import { isOverflowEllipsisActive } from '../../utils/DOMUtils';
import WidgetTooltip from './WidgetTooltip';
import Select, { SelectProps } from '@mui/material/Select';

const useStyles = tss.create(() => ({
    selectFocus: {
        padding: 5,
    },

    wrapper: {
        display: 'flex',
    },

    mainSelect: {
        maxHeight: '2.5em',
        // fontSize: '1em',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        flex: '1 1 100%',
        backgroundColor: 'white',
        fontSize: '0.75rem',
    },

    selectInner: {
        fontSize: '0.8rem',
    },
}));

export type WidgetSelectProps<T> = SelectProps<T> & {
    disableOverFlownTextTooltip?: boolean;
};

function WidgetSelect<T>(props: WidgetSelectProps<T>) {
    const { classes } = useStyles();
    const { disableOverFlownTextTooltip, ...restProps } = props;
    const ref = React.useRef<HTMLElement>();
    const [overFlownTextTooltip, setOverFlownTextTooltip] = React.useState<string>('');
    const [open, setOpen] = React.useState<boolean>(false);

    const clickableElementProps = { 'data-cy': 'dropdown-button' };

    React.useEffect(() => {
        const nodeWithText = ref.current?.firstChild as HTMLElement;
        try {
            if (
                !disableOverFlownTextTooltip &&
                (isOverflowEllipsisActive(nodeWithText) || nodeWithText?.textContent?.length > 20)
            ) {
                setOverFlownTextTooltip(nodeWithText.textContent ?? '');
            } else {
                setOverFlownTextTooltip('');
            }
        } catch (error: unknown) {
            console.error(error);
        }
    }, [props]);

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open);
        }
    }, [props.open]);

    const handleClose = React.useCallback(
        (event: React.SyntheticEvent<Element, Event>) => {
            setOpen(false);
            props.onClose?.(event);
        },
        [props.onClose]
    );

    const handleOpen = React.useCallback(
        (event: React.SyntheticEvent<Element, Event>) => {
            setOpen(true);
            props.onOpen?.(event);
        },
        [props.onOpen]
    );

    return (
        <WidgetTooltip disableInteractive title={overFlownTextTooltip} forceClose={open}>
            <span className={clsx(classes.mainSelect, classes.wrapper)}>
                <Select
                    ref={mergeRefs(props?.ref, ref)}
                    className={classes.mainSelect}
                    classes={{ select: classes.selectInner }}
                    inputProps={{ select: classes.selectFocus }}
                    variant="outlined"
                    data-cy="select"
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */ // that's a very special case and we shouldn't ignore ts errors
                    /* @ts-ignore */
                    MenuProps={{ 'data-cy': 'mui-menu' }} // there's a ts error but "data-" attributes should be allowed on components
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    /* @ts-ignore because data- attributes should be allowed */
                    SelectDisplayProps={clickableElementProps}
                    {...restProps}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                />
            </span>
        </WidgetTooltip>
    );
}

export default WidgetSelect;
