import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { anisotropyGridIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function AnisotropyGridIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={anisotropyGridIconDataCy}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <path
                    fill="currentColor"
                    d="M9.78,1L9.78,1c0.31,0,0.54,0.07,0.7,0.21c0.75,0.65,0.25,3.42-2.24,6.28c-1.97,2.25-4.08,3.28-5.22,3.28
	c-0.31,0-0.54-0.07-0.7-0.21C1.57,9.9,2.07,7.13,4.56,4.28C6.53,2.03,8.65,1,9.78,1 M9.78,0c-1.65,0-4,1.36-5.97,3.62
	c-2.62,3-3.58,6.44-2.15,7.69c0.35,0.31,0.82,0.45,1.36,0.45c1.65,0,4-1.36,5.97-3.62c2.62-3,3.58-6.44,2.15-7.69
	C10.79,0.15,10.32,0,9.78,0L9.78,0z"
                />
                <path
                    fill="currentColor"
                    d="M9.78,13.23L9.78,13.23c0.31,0,0.54,0.07,0.7,0.21c0.75,0.65,0.25,3.42-2.24,6.28C6.27,21.97,4.15,23,3.02,23
	c-0.31,0-0.54-0.07-0.7-0.21c-0.75-0.65-0.25-3.42,2.24-6.28C6.53,14.26,8.65,13.24,9.78,13.23 M9.78,12.23
	c-1.65,0-4,1.36-5.97,3.62c-2.62,3-3.58,6.44-2.15,7.69C2.02,23.85,2.48,24,3.02,24c1.65,0,4-1.36,5.97-3.62
	c2.62-3,3.58-6.44,2.15-7.69C10.79,12.38,10.32,12.23,9.78,12.23L9.78,12.23z"
                />
                <path
                    fill="currentColor"
                    d="M21.12,1L21.12,1c0.31,0,0.54,0.07,0.7,0.21c0.75,0.65,0.25,3.42-2.24,6.28c-1.97,2.25-4.08,3.28-5.22,3.28
	c-0.31,0-0.54-0.07-0.7-0.21c-0.75-0.65-0.25-3.42,2.24-6.28C17.87,2.03,19.99,1,21.12,1 M21.12,0c-1.65,0-4,1.36-5.97,3.62
	c-2.62,3-3.58,6.44-2.15,7.69c0.35,0.31,0.82,0.45,1.36,0.45c1.65,0,4-1.36,5.97-3.62c2.62-3,3.58-6.44,2.15-7.69
	C22.13,0.15,21.66,0,21.12,0L21.12,0z"
                />
                <path
                    fill="currentColor"
                    d="M21.12,13.23L21.12,13.23c0.31,0,0.54,0.07,0.7,0.21c0.75,0.65,0.25,3.42-2.24,6.28
	C17.61,21.97,15.49,23,14.36,23c-0.31,0-0.54-0.07-0.7-0.21c-0.75-0.65-0.25-3.42,2.24-6.28C17.87,14.26,19.99,13.24,21.12,13.23
	 M21.12,12.23c-1.65,0-4,1.36-5.97,3.62c-2.62,3-3.58,6.44-2.15,7.69c0.35,0.31,0.82,0.45,1.36,0.45c1.65,0,4-1.36,5.97-3.62
	c2.62-3,3.58-6.44,2.15-7.69C22.13,12.38,21.66,12.23,21.12,12.23L21.12,12.23z"
                />
            </svg>
        </SvgIcon>
    );
}

export default AnisotropyGridIcon;
