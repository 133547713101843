import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { evoMenuIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function EvoMenuIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={evoMenuIconDataCy}>
            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.6488 5.73563C13.6333 5.01862 13.1356 4.41128 12.7078 4.14978L12.4356 3.96421L10.328 2.62299C9.43365 2.08312 8.29041 1.35769 7.65269 0.994966C6.9683 0.60694 6.62611 0.7166 5.93394 1.07932L0.979917 4.14978C0.552176 4.41128 0.0544399 5.01862 0.0311085 5.73563L0 12.3911C0.0311085 13.0238 0.256645 13.842 1.11991 14.3987L5.68508 17.368C5.88728 17.4776 6.08171 17.5704 6.26836 17.621C6.63388 17.7644 7.0694 17.815 7.4116 17.6379C7.60603 17.5535 7.79268 17.4692 7.99488 17.368L12.5601 14.3987C13.4233 13.842 13.6488 13.0238 13.68 12.3911V12.3742L13.6488 5.73563ZM9.52697 13.2178H9.5192L9.43365 13.3106L9.42587 13.319H4.26186L4.16854 13.2178V11.7838L4.17631 11.7753L4.26186 11.6741H9.43365L9.5192 11.7753L9.52697 11.7838V13.2178ZM4.17631 9.97017V8.53616L4.26186 8.43494H6.85942L6.95275 8.53616V9.97017L6.85942 10.0714H4.26186L4.17631 9.97017ZM9.52697 6.731H9.5192L9.43365 6.83222H4.26186L4.16854 6.731V5.29699L4.26186 5.19577H9.43365L9.52697 5.29699V6.731Z"
                    fill="#1D1F22"
                />
            </svg>
        </SvgIcon>
    );
}

export default EvoMenuIcon;
