import NewOverlapsFolder from 'App/MainApp/Dialogs/Overlaps/NewProfile';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps, ObjectMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { OverlapFolderMenuItems, OverlapSectionMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { newOverlapFolderDataCy, renameOverlapsFolderDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function CreateOverlapsFolderDialogButton(props: GeneralMenuItemProps) {
    return <OverlapsFolderDialogButton nodeData={null} handleMenuClose={props.handleMenuClose} rename={false} />;
}

export function RenameOverlapsFolderDialogButton(props: ObjectMenuItemProps) {
    return <OverlapsFolderDialogButton nodeData={props.nodeData} handleMenuClose={props.handleMenuClose} rename />;
}

function OverlapsFolderDialogButton(props: ObjectMenuItemProps & { rename: boolean }) {
    const dialogProps = {
        folderName: props.nodeData?.section,
        rename: props.rename,
        folderId: props.nodeData?.id,
    };

    const depreciateOverlaps = useFlags()?.depreciateOverlaps;

    if (depreciateOverlaps) {
        return null;
    }

    return (
        <DialogOpenerMenuItem
            name={props.rename ? OverlapFolderMenuItems.RenameFolder : OverlapSectionMenuItems.CreateFolder}
            dataCy={props.rename ? renameOverlapsFolderDataCy : newOverlapFolderDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={false}
            dialogComponent={NewOverlapsFolder}
            dialogProps={dialogProps}
        />
    );
}
