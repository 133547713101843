import { batch } from 'react-redux';
import { PayloadAction, createSelector, createSlice, current, original } from '@reduxjs/toolkit';
import update, { Spec } from 'immutability-helper';
import { redownloadXyzUrlsForJob } from 'Common/DRIVER2XYZ/plotJobObject';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { MeshFileType, MeshTypes } from 'App/util/ProjectDataTypes/MeshFile';
import { GridDefinitionType } from 'App/util/ProjectDataTypes/GridDefinition';
import { SourceFileType } from 'App/util/ProjectDataTypes/SourceFile';
import { BackendJsonifiedProjectObject } from 'App/util/ProjectDataTypes/BackendJsonifiedProjectObject';
import { AppDispatch, AppState, AppStoreStateGetter } from '../../store';

import { setProjectTree } from './projectTreeSlice';
import {
    fill_in_unmodified_keys_in_websocket_updated_object,
    newObjectHasMoreDataThanOldObject,
} from '../../../MainApp/SocketIO/ProjectSocketConnection/util';

import { telemetryLogs } from '../../../util/Logging/TelemetryLogs';
import { appendReceivedProjectWebsocketUpdateTimestamps } from '../SocketIO/websocketUpdatesSlice';
import { informationalLogs } from '../../../util/Logging/InformationalLogs';
import {
    CurrentProjectType,
    Id2AnisotropyEstimationObject,
    Id2AnisotropyGlobalObject,
    Id2AnisotropyGridObject,
    Id2DomainGridObject,
    Id2DomainObject,
    Id2GridObject,
    Id2MeshObject,
    Id2Object,
    Id2OverlapObject,
    Id2PointEstimationObject,
    Id2SourceFileObject,
    Id2ZoneFromAnisotropyObject,
    Id2ZoneObject,
    OBJECT_CLASS_NAME,
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
    ObjectIDType,
    ObjectStatusTypes,
    ProjectObject,
    SOURCE_FILE_KINDS,
} from '../../../util/ProjectDataTypes/ProjectObjectsDataTypes';
import { filterObjects, isSuccessfulObject } from '../../utils';
import { AxiosDriverFlaskInstance } from '../../../util/axiosErrorHandlers';
import {
    APIResponseWithProjectUpdate,
    ProjectUpdateMessageType,
} from '../../../util/ProjectDataTypes/APIResponseTypes';
import { addRecentlyReceivedProjectUpdate } from '../../../MainApp/SocketIO/ProjectSocketConnection/recentlyReceivedProjectUpdates';
import { buildProjectTree } from 'App/MainApp/TreeView/treeData/treeDataService';
import { isInactiveObject } from '../../../util/ProjectDataTypes/projectObjectUtils';

global.original = original; // type-coverage:ignore-line
global.current = current; // type-coverage:ignore-line

const initialState: CurrentProjectType = null;

export const currentProjectSlice = createSlice({
    name: 'currentProject',
    initialState,
    reducers: {
        setCurrentProject: (state, action: PayloadAction<CurrentProjectType>) => {
            Object.freeze(action.payload); // Freeze large object to improve immer performance
            return action.payload;
        },
        updateCurrentProjectName: (state, action: PayloadAction<{ name: string }>) => {
            state.name = action.payload.name;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCurrentProject, updateCurrentProjectName } = currentProjectSlice.actions;

export const selectCurrentProject = (state: AppState) => state.currentProject;
export const selectCurrentProjectName = (state: AppState) => (!state.currentProject ? null : state.currentProject.name);
export const selectCurrentProjectId = (state: AppState) => (!state.currentProject ? null : state.currentProject.id);
export const selectcurrentProjectUrl = (state: AppState) => (!state.currentProject ? null : state.currentProject.url);
export const selectObjectClassname2Id2Obj = (state: AppState): ObjectClassName2Id2Obj =>
    state.currentProject?.object_class_name2id2obj;

export const filterActiveObjects = <T extends BackendJsonifiedProjectObject>(objectsMap: Id2Object<T>): Id2Object<T> =>
    /* eslint-disable-next-line */
    filterObjects(objectsMap, ([id, object]) => !isInactiveObject(object));

/** A very weird thing that is needed for combining selectors into a memoized one.
 * This is the official recipe from Redux.
 */
const selectId = (state: AppState, id: ObjectIDType) => id;

const makeSelectObjectByClassnameAndId = createSelector(
    selectObjectClassname2Id2Obj,
    (_, className: OBJECT_CLASS_NAME) => className,
    (_, __, id: ObjectIDType) => id,
    (objectsMap: ObjectClassName2Id2Obj, className: OBJECT_CLASS_NAME, id: ObjectIDType) =>
        objectsMap?.[className]?.[id]
);

export const selectObjectByClassnameAndId =
    <ClassName extends keyof ObjectClassName2Id2Obj, Id extends ObjectIDType>(className: ClassName, id: Id) =>
    (state: AppState): ObjectClassName2Id2Obj[ClassName][Id] => {
        const object = makeSelectObjectByClassnameAndId(state, className, id) as ObjectClassName2Id2Obj[ClassName][Id];
        return object;
    };

export const selectSourceFiles = (state: AppState): Id2SourceFileObject =>
    state.currentProject?.object_class_name2id2obj?.[OBJECT_CLASS_NAMES.SourceFile];
export const selectActiveSourceFiles = createSelector([selectSourceFiles], (sourceFiles) =>
    filterActiveObjects(sourceFiles)
);

export const selectGrids = (state: AppState): Id2GridObject =>
    state.currentProject?.object_class_name2id2obj?.[OBJECT_CLASS_NAMES.GridDefinition];
export const selectActiveGrids = createSelector([selectGrids], (grids) => filterActiveObjects(grids));

const selectGridByIdThunk = createSelector(
    [selectGrids, selectId],
    (gridsMap: Id2GridObject, id: ObjectIDType): GridDefinitionType => gridsMap?.[id]
);

export function selectGridById(id: ObjectIDType) {
    return (state: AppState) => selectGridByIdThunk(state, id);
}

export const selectDomainGrids = (state: AppState): Id2DomainGridObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.Domain_GridDefinition];

export const selectMeshes = (state: AppState): Id2MeshObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.MeshFile];
export const selectActiveMeshes = createSelector([selectMeshes], (meshes) => filterActiveObjects(meshes));

export const selectActiveClosedMeshes = createSelector(
    [selectActiveMeshes],
    <Type extends MeshFileType>(meshes: Id2Object<Type>) =>
        /* eslint-disable-next-line */
        filterObjects(meshes, ([id, mesh]) => mesh.type === MeshTypes.closed) as Id2Object<Type>
);

export const selectActiveDrilling = createSelector([selectActiveSourceFiles], (activeSourceFiles) =>
    Object.values(activeSourceFiles).find((sourceFile) => sourceFile.type === SOURCE_FILE_KINDS.Drilling)
);

export const selectActiveDrills = createSelector([selectActiveSourceFiles], (activeSourceFiles) =>
    /* eslint-disable-next-line */
    filterObjects(activeSourceFiles, ([id, sourceFile]) => sourceFile.type === SOURCE_FILE_KINDS.Drilling)
);

export const selectActiveOpenMeshes = createSelector(
    [selectActiveMeshes],
    <Type extends MeshFileType>(meshes: Id2Object<Type>) =>
        /* eslint-disable-next-line */
        filterObjects(meshes, ([id, mesh]) => mesh.type === MeshTypes.open) as Id2Object<Type>
);

export const selectSuccessfulClosedMeshes = createSelector(
    [selectActiveClosedMeshes],
    <Type extends MeshFileType>(meshes: Id2Object<Type>) => filterObjects(meshes, isSuccessfulObject) as Id2Object<Type>
);

export const selectSuccessfulOpenMeshes = createSelector(
    [selectActiveOpenMeshes],
    <Type extends MeshFileType>(meshes: Id2Object<Type>) => filterObjects(meshes, isSuccessfulObject) as Id2Object<Type>
);

export const selectSuccessfulActiveDrills = createSelector([selectActiveDrills], (drills) =>
    filterObjects(drills, isSuccessfulObject)
);

const selectMeshByIdThunk = createSelector(
    [selectMeshes, selectId],
    (meshesMap: Id2MeshObject, id: ObjectIDType) => meshesMap[id]
);

export function selectMeshById(id: ObjectIDType) {
    return (state: AppState) => selectMeshByIdThunk(state, id);
}

export const selectDomains = (state: AppState): Id2DomainObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.Domain];
export const selectActiveDomains = createSelector([selectDomains], (domains) => filterActiveObjects(domains));

export const selectAnisotropyEstimations = (state: AppState): Id2AnisotropyEstimationObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.AnisotropyEstimation];
export const selectActiveAnisotropyEstimations = createSelector(
    [selectAnisotropyEstimations],
    (anisotropyEstimations) => filterActiveObjects(anisotropyEstimations)
);

export const selectAnisotropyGrids = (state: AppState): Id2AnisotropyGridObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.AnisotropyGrid];
export const selectActiveAnisotropyGrids = createSelector([selectAnisotropyEstimations], (anisotropyEstimations) =>
    filterActiveObjects(anisotropyEstimations)
);

export const selectAnisotropyGlobals = (state: AppState): Id2AnisotropyGlobalObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.AnisotropyGlobal];
export const selectActiveAnisotropyGlobals = createSelector([selectAnisotropyEstimations], (anisotropyEstimations) =>
    filterActiveObjects(anisotropyEstimations)
);

export const selectPointEstimations = (state: AppState): Id2PointEstimationObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.PointEstimation];
export const selectActivePointEstimations = createSelector([selectPointEstimations], (interpolations) =>
    filterActiveObjects(interpolations)
);

export const selectZones = (state: AppState): Id2ZoneObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.Zone];

export const selectZonesFromAnisotropy = (state: AppState): Id2ZoneFromAnisotropyObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.ZoneFromAnisotropy];

export const selectActiveZones = createSelector([selectZones], (interpolations) => filterActiveObjects(interpolations));

export const selectOverlaps = (state: AppState): Id2OverlapObject =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.Overlap];
export const selectActiveOverlaps = createSelector([selectOverlaps], (overlaps) => filterActiveObjects(overlaps));

export const selectOverlapsFolders = (state: AppState) =>
    state.currentProject?.object_class_name2id2obj[OBJECT_CLASS_NAMES.OverlapFolder];

export function applyWebsocketModificationsToCurrentProject(
    xyz: UseXyz,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    projectUpdateMessageStr: APIResponseWithProjectUpdate,
    updatedProjectId: number | string,
    LOG_DESCRIPTION = '',
    tokenProvider: () => Promise<string>
) {
    return function applyWebsocketModificationsToCurrentProjectThunk(
        dispatch: AppDispatch,
        getState: AppStoreStateGetter
    ) {
        const currentState = getState();
        const currentProject = selectCurrentProject(currentState);

        let projectUpdateMessage: ProjectUpdateMessageType;

        if (typeof projectUpdateMessageStr === 'string') {
            try {
                projectUpdateMessage = JSON.parse(projectUpdateMessageStr);
                // eslint-disable-next-line no-empty
            } catch {}
        } else {
            projectUpdateMessage = projectUpdateMessageStr as ProjectUpdateMessageType;
        }

        telemetryLogs.info(
            LOG_DESCRIPTION,
            'WS Mod',
            'updated_project_id, project_update_message:',
            updatedProjectId,
            projectUpdateMessage,
            currentProject?.id
        );

        addRecentlyReceivedProjectUpdate(projectUpdateMessage);

        if (String(updatedProjectId) !== String(currentProject?.id)) {
            return currentProject;
        }

        const projectUpdateDict = projectUpdateMessage?.project_update ?? {};

        const currentProjectUpdateCommand = {};

        Object.keys(projectUpdateDict).forEach((key) => {
            currentProjectUpdateCommand[key] = {};
            const projectKeyUpc = currentProjectUpdateCommand[key];
            //
            switch (key) {
                case 'object_class_name2id2obj':
                    const newObjectClassName2Id2Obj = projectUpdateDict[key];
                    const oldProjectObjectClassName2Id2Obj = currentProject.object_class_name2id2obj;

                    Object.keys(newObjectClassName2Id2Obj).forEach((modelType) => {
                        projectKeyUpc[modelType] = {};
                        const objectId2objUpc = projectKeyUpc[modelType];
                        const newObjectId2obj: ObjectClassName2Id2Obj = newObjectClassName2Id2Obj[modelType];
                        const oldProjectObjectId2obj = oldProjectObjectClassName2Id2Obj[modelType];

                        Object.keys(newObjectId2obj).forEach(async (newObjId) => {
                            const newObj: ProjectObject = newObjectId2obj[newObjId];
                            // Does newObj exist in currentProject:
                            const oldObj = oldProjectObjectId2obj[newObj.id];
                            // const oldObjIndex = currentProject_objects.findIndex((obj)=>obj.id===newObj.id);
                            telemetryLogs.info(
                                LOG_DESCRIPTION,
                                'WS Mod',
                                'currentProject_obj:',
                                modelType,
                                newObj,
                                oldObj
                            );
                            if (oldObj !== undefined) {
                                if (isInactiveObject(newObj)) {
                                    // Object is deleted so we just keep a note that it is deleted.
                                    objectId2objUpc[newObj.id] = {
                                        $set: newObj,
                                    };
                                    // modelType_upc['$splice'].push([oldObjIndex, 1]);
                                } else {
                                    // Handle $unmodified keys
                                    if (
                                        oldObj?.__jsonified_at &&
                                        newObj?.__jsonified_at &&
                                        new Date(oldObj?.__jsonified_at) >= new Date(newObj?.__jsonified_at) &&
                                        !newObjectHasMoreDataThanOldObject(newObj, oldObj)
                                    ) {
                                        return;
                                    }

                                    if (
                                        oldObj?.updated_at &&
                                        newObj?.updated_at &&
                                        new Date(oldObj?.updated_at) >= new Date(newObj?.updated_at) &&
                                        !newObjectHasMoreDataThanOldObject(newObj, oldObj)
                                    ) {
                                        return;
                                    }

                                    fill_in_unmodified_keys_in_websocket_updated_object(newObj, oldObj, true);
                                    objectId2objUpc[newObj.id] = {
                                        $set: newObj,
                                    };
                                    // modelType_upc['$splice'].push([oldObjIndex, 1, newObj]);
                                }
                            } else {
                                // newObj doesn't exist in currentProject:
                                if (newObj.previousVersionId) {
                                    // Try to delete it's older version
                                    const currentProjectOlderObj = oldProjectObjectId2obj[newObj.previousVersionId];
                                    // const olderObjIndex = currentProject_objects.findIndex((obj)=>obj.id===newObj.previousVersionId)
                                    if (currentProjectOlderObj !== undefined) {
                                        objectId2objUpc[newObj.id] = {
                                            $set: newObj,
                                        };
                                        // objectId2obj_upc['$unset'].push(newObj.previousVersionId)
                                        // modelType_upc['$splice'].push([olderObjIndex, 1]);
                                    }
                                }
                                if (!isInactiveObject(newObj)) {
                                    // push the new object in the list of objects.
                                    objectId2objUpc[newObj.id] = {
                                        $set: newObj,
                                    };
                                    // modelType_upc['$push'].push(newObj)
                                }
                            }

                            if (newObj.status === ObjectStatusTypes.SUCCESS) {
                                await dispatch(redownloadXyzUrlsForJob(newObj, xyz, tokenProvider));
                            }
                        });
                    });

                    break;
                default:
                    projectKeyUpc.$set = projectUpdateDict[key];
            }
        });

        telemetryLogs.info(LOG_DESCRIPTION, 'WS Mod', 'currentProjectUpdateCommand:', currentProjectUpdateCommand);
        const newCurrentProject = update(currentProject, currentProjectUpdateCommand);
        dispatch(setCurrentProjectAndUpdateProjectTree(newCurrentProject));
        dispatch(appendReceivedProjectWebsocketUpdateTimestamps(projectUpdateMessage?.broadCastTime));
    };
}

export const selectCurrentWorkspace = (state: AppState) => state.allWorkspaces?.[state.currentProject?.workspace_id];
export const selectCurrentWorkspaceName = (state: AppState) =>
    state.allWorkspaces?.[state.currentProject?.workspace_id]?.name;
export const selectCurrentWorkspaceId = (state: AppState) => state.currentProject?.workspace_id;

export function setCurrentProjectAndUpdateProjectTree(currentProject: CurrentProjectType) {
    return function setCurrentProjectAndUpdateProjectTreeThunk(dispatch: AppDispatch) {
        if (currentProject) {
            const objectClassName2id2obj: ObjectClassName2Id2Obj = currentProject.object_class_name2id2obj;

            Object.values(OBJECT_CLASS_NAMES).forEach((objectClassName) => {
                if (
                    objectClassName !== OBJECT_CLASS_NAMES.Project &&
                    currentProject.object_class_name2id2obj[objectClassName] === undefined
                ) {
                    currentProject.object_class_name2id2obj[objectClassName] = {};
                }
            });

            informationalLogs.log('setCurrentProject_and_update_projectTree setCurrentProject:', currentProject);

            const projectTree = buildProjectTree(objectClassName2id2obj);

            batch(() => {
                dispatch(setCurrentProject(currentProject));
                dispatch(setProjectTree(projectTree));
            });
        } else {
            dispatch(setCurrentProject(null));
            dispatch(setProjectTree(null));
        }
    };
}

export function deleteMockDrillObject(mockDrillId: string) {
    return function deleteMockDrillObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const oldCurrentProject = selectCurrentProject(getState());
        const mockObj = oldCurrentProject.object_class_name2id2obj[OBJECT_CLASS_NAMES.SourceFile][mockDrillId];
        if (mockObj !== undefined) {
            dispatch(
                setCurrentProjectAndUpdateProjectTree(
                    update(oldCurrentProject, {
                        object_class_name2id2obj: {
                            [OBJECT_CLASS_NAMES.SourceFile]: {
                                $unset: [mockDrillId],
                            },
                        },
                    })
                )
            );
        }
    };
}

export function addMockDrillObject(mockDrillId: string, uploadedFileName: string) {
    return function addMockDrillObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const oldCurrentProject = selectCurrentProject(getState());
        dispatch(
            setCurrentProjectAndUpdateProjectTree(
                update(oldCurrentProject, {
                    object_class_name2id2obj: {
                        [OBJECT_CLASS_NAMES.SourceFile]: {
                            [mockDrillId]: {
                                $set: {
                                    id: mockDrillId,
                                    object_class_name: OBJECT_CLASS_NAMES.SourceFile,
                                    original_file_name: uploadedFileName,
                                    type: 'drill',
                                    status: 'RUNNING',
                                    meta_data: {
                                        dataAttribute2info: {},
                                        dataAttributes: [],
                                    },
                                },
                            } as Spec<SourceFileType>,
                        },
                    },
                })
            )
        );
    };
}

export function backendProjectUrl(orgId: string, workspaceId: string, projectId: string): string {
    return `/orgs/${orgId}/workspaces/${workspaceId}/project/${projectId}`;
}

export function frontendProjectUrl(
    orgId: string,
    workspaceId: string,
    projectId: string,
    newUrlScheme: boolean
): string {
    return `${newUrlScheme ? '' : '/org'}/${orgId}/workspaces/${workspaceId}/project/${projectId}`;
}

export function frontendNoProjectUrl(orgId: string, newUrlScheme: boolean): string {
    return `${newUrlScheme ? '' : '/org'}/${orgId}/projects`;
}

export default currentProjectSlice.reducer;
