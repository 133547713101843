import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import { selectProjectTreeUnderPath } from 'App/Redux/features/globalContext/projectTreeSlice';
import { interpolationsSectionSelector } from 'App/Redux/utils';
import WidgetSelect from 'Common/components/Core/WidgetSelect';
import {
    selectActiveDrilling,
    selectActiveGrids,
    selectCurrentProjectId,
    selectPointEstimations,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import WidgetTextField from 'Common/components/Core/WidgetTextField';
import { tss } from 'tss-react/mui';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import { DataContext } from '../../../../DataContext';
import {
    createMergedPointEstimation,
    expandMergedPointEstimationNodes,
    getFilteredPointEstimationsTree,
} from './mergedPointEstimationsService';
import { ProjectTreeSectionNode, ProjectTreeSource } from '../../../../util/ProjectDataTypes/MainTreeDataTypes';
import { ObjectIDType } from '../../../../util/ProjectDataTypes/ProjectObjectsDataTypes';
import {
    drillAttributesDropdownDataCy,
    emptyPointEstimationsMessageDataCy,
    gridsDropdownDataCy,
    interpolationsTreeDataCy,
    mergedInterpolationDialogDataCy,
    nameSuffixTextboxDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { MERGED_INTERPOLATION_SUFFIX } from 'App/MainApp/Dialogs/PointEstimations/pointEstimationsConsts';
import { useSelectableTree } from 'App/MainApp/TreeView/components/checkboxTree/useSelectableTree';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { useSessionContext } from 'App/context/SessionContext';
import ProjectTree from 'App/MainApp/TreeView/components/ProjectTree/ProjectTree';
import { getFirstLevelNodeIds } from 'App/MainApp/TreeView/treeUtils';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create({
    label: {
        width: '120px',
        marginRight: '10px',
        display: 'inline-block',
    },
    treeTopParent: {
        height: 240,
        flexGrow: 1,
        overflowY: 'auto',
    },
});

type MergePointEstimationsProps = {
    handleClose: () => void;
};

export default function MergePointEstimations(props: MergePointEstimationsProps) {
    const { classes } = useStyles();
    const xyz = useXyz();

    const activeGrids = Object.values(useAppSelector(selectActiveGrids));
    const drillingObject = useAppSelector(selectActiveDrilling);
    const elements = [...drillingObject.meta_data.dataAttributes];
    const pointEstimationsSection = useAppSelector(
        selectProjectTreeUnderPath(interpolationsSectionSelector)
    ) as ProjectTreeSectionNode;

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const [gridId, setGridId] = React.useState<ObjectIDType>(activeGrids?.[0]?.id);
    const [elementName, setElementName] = React.useState(elements?.[0]);
    const [pointEstimationSuffix, setPointEstimationSuffix] = React.useState('');

    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useAppDispatch();
    const allPointEstimations = useAppSelector(selectPointEstimations);
    const { onExpandChange } = useExpandedNodes();

    const tree = getFilteredPointEstimationsTree(pointEstimationsSection, gridId, elementName, allPointEstimations);
    const { onChange, nodeSelectionChecker, selectedLeafNodes } = useSelectableTree(tree, null, null);

    const onElementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setElementName(event.target.value);
    };

    const onGridChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGridId(event.target.value);
    };

    const onPointEstimationSuffixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPointEstimationSuffix(event.target.value);
    };

    const chosenPointEstimations = selectedLeafNodes;

    const numberOfFilteredPointEstimations = React.useMemo(() => {
        let numberOfFilteredPointEstimations = 0;

        Object.values(tree).forEach((domain) => {
            numberOfFilteredPointEstimations += Object.keys(domain.subsections ?? {}).length;
        });

        return numberOfFilteredPointEstimations;
    }, [tree]);

    const areEnoughPointEstimationsChosen = chosenPointEstimations.length >= 2;

    const isSubmitDisabled = () => isLoading || !areEnoughPointEstimationsChosen;

    const submitTooltip = areEnoughPointEstimationsChosen ? '' : 'Should choose at least 2 pointEstimations';

    const pointEstimationNamePrefix = `${elementName}${MERGED_INTERPOLATION_SUFFIX}`;

    const getPointEstimationName = () => `${pointEstimationNamePrefix}${pointEstimationSuffix}`;

    const onSubmit = async () => {
        setIsLoading(true);
        const result = await createMergedPointEstimation(
            xyz,
            chosenPointEstimations,
            gridId,
            elementName,
            getPointEstimationName(),
            axiosDriverFlask,
            currentProjectId,
            dispatch,
            currentOrgUuid,
            currentWorkspaceId,
            tokenProvider
        );

        setIsLoading(false);

        expandMergedPointEstimationNodes(onExpandChange, [gridId]);

        if (result) {
            props.handleClose();
        }
    };

    React.useEffect(() => {
        const pointEstimationNodeIds = getFirstLevelNodeIds(tree);
        onExpandChange(pointEstimationNodeIds, true, ProjectTreeSource.MERGED_POINT_ESTIMATIONS, true);
    }, []);

    return (
        <GenericDialogShell
            title="Create Merged PointEstimation"
            dataCy={mergedInterpolationDialogDataCy}
            handleClose={props.handleClose}
        >
            <Grid container direction="column" rowGap="10px">
                <Grid item xs={12}>
                    <Typography className={classes.label}>Drilling attribute:</Typography>
                    <WidgetSelect
                        value={elementName}
                        onChange={onElementChange}
                        data-cy={drillAttributesDropdownDataCy}
                    >
                        {elements.map((element) => (
                            <MenuItem value={element} key={element}>
                                {element}
                            </MenuItem>
                        ))}
                    </WidgetSelect>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.label}>Grid:</Typography>
                    <WidgetSelect value={gridId} onChange={onGridChange} data-cy={gridsDropdownDataCy}>
                        {Object.values(activeGrids).map((grid) => (
                            <MenuItem value={grid.id} key={grid.id}>
                                {grid.name}
                            </MenuItem>
                        ))}
                    </WidgetSelect>
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.label}>Interpolations:</Typography>
                    {numberOfFilteredPointEstimations ? (
                        <ProjectTree
                            checkboxTree
                            className={classes.treeTopParent}
                            tree={tree}
                            onChange={onChange}
                            dataCy={interpolationsTreeDataCy}
                            nodeSelectionChecker={nodeSelectionChecker}
                            treeSource={ProjectTreeSource.MERGED_POINT_ESTIMATIONS}
                        />
                    ) : (
                        <Grid
                            item
                            container
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.treeTopParent}
                        >
                            <Typography data-cy={emptyPointEstimationsMessageDataCy} variant="caption">
                                No pointEstimations for chosen attribute with chosen grid have been found.
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} style={{ marginTop: '15px' }}>
                    <Typography className={classes.label}>Name suffix:</Typography>
                    <WidgetTextField
                        data-cy={nameSuffixTextboxDataCy}
                        id="outlined-basic"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{ fontSize: 13 }}>
                                    {pointEstimationNamePrefix}
                                </InputAdornment>
                            ),
                        }}
                        value={pointEstimationSuffix}
                        onChange={onPointEstimationSuffixChange}
                    />
                </Grid>
            </Grid>
            <GenericDialogActions
                onCancel={props.handleClose}
                onSubmit={onSubmit}
                disabled={isSubmitDisabled()}
                showSpinner={isLoading}
                tooltipText={submitTooltip}
            />
        </GenericDialogShell>
    );
}
