import {
    OBJECT_CLASS_NAME,
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
    ObjectIDType,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';

class ProjectObjects {
    allObjects: ObjectClassName2Id2Obj;

    axiosDriverFlask: AxiosDriverFlaskInstance;

    constructor(axiosDriverFlask: AxiosDriverFlaskInstance, allObjects: ObjectClassName2Id2Obj) {
        this.axiosDriverFlask = axiosDriverFlask;
        this.allObjects = allObjects;
    }

    getObjectSnapshots = (objectClassName: OBJECT_CLASS_NAME) =>
        this.allObjects[objectClassName] as BackendJsonifiedProjectObject;

    getObjects = (objectClassName: OBJECT_CLASS_NAME) =>
        Object.values(this.getObjectSnapshots(objectClassName)).map((obj) =>
            wrapObjectInClass(obj, this.axiosDriverFlask, this.allObjects)
        );

    getObject = (objectClassName: OBJECT_CLASS_NAME, id: ObjectIDType) =>
        wrapObjectInClass(this.allObjects[objectClassName][id], this.axiosDriverFlask, this.allObjects);

    getDrilling = (id: ObjectIDType) => this.getObject(OBJECT_CLASS_NAMES.SourceFile, id) as Drilling;

    getSourceFile = (id: ObjectIDType) => this.getDrilling(id);

    getGrid = (id: ObjectIDType) => this.getObject(OBJECT_CLASS_NAMES.GridDefinition, id) as Grid;

    getMesh = (id: ObjectIDType) => this.getObject(OBJECT_CLASS_NAMES.MeshFile, id) as Mesh;

    getDomain = (id: ObjectIDType) => this.getObject(OBJECT_CLASS_NAMES.Domain, id) as Domain;

    getDomainGrid = (id: ObjectIDType) => this.getObject(OBJECT_CLASS_NAMES.Domain_GridDefinition, id) as DomainGrid;

    getAnisotropyEstimation = (id: ObjectIDType) =>
        this.getObject(OBJECT_CLASS_NAMES.AnisotropyEstimation, id) as Anisotropy;

    getAnisotropyGrid = (id: ObjectIDType) => this.getObject(OBJECT_CLASS_NAMES.AnisotropyGrid, id) as AnisotropyGrid;

    getAnisotropyGlobal = (id: ObjectIDType) =>
        this.getObject(OBJECT_CLASS_NAMES.AnisotropyGlobal, id) as AnisotropyGlobal;

    getPointEstimations = () => this.getObjects(OBJECT_CLASS_NAMES.PointEstimation) as PointEstimation[];

    getPointEstimation = (id: ObjectIDType) =>
        this.getObject(OBJECT_CLASS_NAMES.PointEstimation, id) as PointEstimation;

    getZone = (id: ObjectIDType) => this.getObject(OBJECT_CLASS_NAMES.Zone, id) as Zone;

    getOverlap = (id: ObjectIDType) => this.getObject(OBJECT_CLASS_NAMES.Overlap, id) as Overlap;

    getOverlapFolder = (id: ObjectIDType) => this.getObject(OBJECT_CLASS_NAMES.OverlapFolder, id) as OverlapFolder;
}

export default ProjectObjects;

import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { Anisotropy } from '../AnisotropyEstimation';
import { AnisotropyGlobal } from '../AnisotropyGlobal';
import { AnisotropyGrid } from '../AnisotropyGrid';
import { Domain } from '../Domain';
import { DomainGrid } from '../DomainGridDefinition';
import { Drilling } from '../SourceFile';
import { Grid } from '../GridDefinition';
import { Mesh } from '../MeshFile';
import { Overlap } from '../Overlap';
import { OverlapFolder } from '../OverlapFolder';
import { PointEstimation } from '../PointEstimation';
import { Zone } from '../Zone';
import { wrapObjectInClass } from './objectWrapper';
import { BackendJsonifiedProjectObject } from '../BackendJsonifiedProjectObject';
