import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppState, AppStoreStateGetter } from '../../store';
import { OBJECT_CLASS_NAME, ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { produce } from 'immer';

export type publishFormatType = {
    [publishFormat: string]: boolean;
};

type PublishToEvoSpinnerActiveState = {
    [className: string]: {
        [id: string]: boolean | publishFormatType;
    };
};

const initialState: PublishToEvoSpinnerActiveState = {};

export const publishToEvoSpinnerActiveSlice = createSlice({
    name: 'publishToEvoSpinnerActive',
    initialState,
    reducers: {
        setPublishToEvoSpinnerActive: (state, action: PayloadAction<PublishToEvoSpinnerActiveState>) => action.payload,
    },
});

export const { setPublishToEvoSpinnerActive } = publishToEvoSpinnerActiveSlice.actions;

export const selectPublishToEvoSpinnerActiveByIdThunk = (state: AppState, className: string, id: ObjectIDType) =>
    state.publishToEvoSpinnerActive?.[className]?.[id];

export function selectPublishToEvoSpinnerActiveById(className: OBJECT_CLASS_NAME, id: ObjectIDType) {
    return (state: AppState) => selectPublishToEvoSpinnerActiveByIdThunk(state, className, id);
}

export const selectPublishToEvoSpinnerActive = (state: AppState) => state.publishToEvoSpinnerActive;

export function setCurrentPublishToEvoSpinnerObject(
    objectClassName: string,
    objectId: ObjectIDType,
    spinnerState: boolean,
    publishObjectFormat: string
) {
    return function setCurrentProjectAndUpdateProjectTreeThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const oldPublishToEvoSpinnerObject = selectPublishToEvoSpinnerActive(getState());

        const newPublishToEvoSpinnerObject = produce<PublishToEvoSpinnerActiveState>(
            oldPublishToEvoSpinnerObject,
            (oldPublishToEvoSpinnerObjectDraft) => {
                if (oldPublishToEvoSpinnerObject.hasOwnProperty(objectClassName)) {
                    if (oldPublishToEvoSpinnerObject[objectClassName].hasOwnProperty(objectId)) {
                        if (publishObjectFormat) {
                            if (
                                oldPublishToEvoSpinnerObjectDraft[objectClassName][objectId].hasOwnProperty(
                                    publishObjectFormat
                                )
                            ) {
                                oldPublishToEvoSpinnerObjectDraft[objectClassName][objectId][publishObjectFormat] =
                                    spinnerState;
                            } else {
                                oldPublishToEvoSpinnerObjectDraft[objectClassName][objectId] = {
                                    ...(oldPublishToEvoSpinnerObject[objectClassName][objectId] as object),
                                    [publishObjectFormat]: spinnerState,
                                };
                            }
                        } else {
                            oldPublishToEvoSpinnerObjectDraft[objectClassName][objectId] = spinnerState;
                        }
                    } else {
                        oldPublishToEvoSpinnerObjectDraft[objectClassName] = {
                            ...oldPublishToEvoSpinnerObject[objectClassName],
                            [objectId]: spinnerState,
                        };
                    }
                } else {
                    if (publishObjectFormat) {
                        oldPublishToEvoSpinnerObjectDraft[objectClassName] = {
                            [objectId]: {
                                [publishObjectFormat]: spinnerState,
                            },
                        };
                    } else {
                        oldPublishToEvoSpinnerObjectDraft[objectClassName] = {
                            [objectId]: spinnerState,
                        };
                    }
                }
            }
        );
        dispatch(setPublishToEvoSpinnerActive(newPublishToEvoSpinnerObject));
    };
}

export default publishToEvoSpinnerActiveSlice.reducer;
