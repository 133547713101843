import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { selectEnabledXyzTraces, selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import React from 'react';
import { useAppSelector } from 'App/Redux/hooks';
import { DrillTrace, DrillTraceSnapshot } from '../DrillTrace';
import { LvaTrace, LvaTraceSnapshot } from '../LvaTrace';
import { MeshTrace, MeshTraceSnapshot } from '../MeshTrace';
import { XyzTraceClassNames, XyzTraceId } from '../XyzTrace';
import { BlockModelTrace, BlockModelTraceSnapshot } from '../BlockModelTrace';
import { BoundingBoxTrace, BoundingBoxTraceSnapshot } from '../BoundingBoxTrace';
import { LinesTrace, LinesTraceSnapshot } from '../LinesTrace';
import { setXyzSliceToolOpen } from 'App/Redux/features/Xyz/xyzPlotOverlaysSlice';

export type AnyXyzTraceSnapshot =
    | DrillTraceSnapshot
    | MeshTraceSnapshot
    | LvaTraceSnapshot
    | BlockModelTraceSnapshot
    | LinesTraceSnapshot
    | BoundingBoxTraceSnapshot;
export type AnyXyzTrace = DrillTrace | MeshTrace | LvaTrace | BlockModelTrace | LinesTrace | BoundingBoxTrace;

export function createXyzTraceObjectFromSnapshot(
    xyz: UseXyz,
    snapshot: AnyXyzTraceSnapshot,
    tokenProvider: () => Promise<string>
): AnyXyzTrace {
    switch (snapshot?.className) {
        case undefined:
            return undefined;

        case XyzTraceClassNames.DrillTrace:
            return new DrillTrace(xyz, snapshot, tokenProvider);

        case XyzTraceClassNames.MeshTrace:
            return new MeshTrace(xyz, snapshot);

        case XyzTraceClassNames.LvaTrace:
            return new LvaTrace(xyz, snapshot, tokenProvider);

        case XyzTraceClassNames.BlockModelTrace:
            return new BlockModelTrace(xyz, snapshot, tokenProvider);

        case XyzTraceClassNames.BoundingBoxTrace:
            return new BoundingBoxTrace(xyz, snapshot as BoundingBoxTraceSnapshot);

        case XyzTraceClassNames.LinesTrace:
            return new LinesTrace(xyz, snapshot);

        default: {
            const impossibleSnapshot: never = snapshot; // we should have exhausive cases for all class options, so the type of this has to be never.
            throw new Error(`createXyzTraceObjectFromSnapshot: Unhandled snapshot className ${impossibleSnapshot}`);
        }
    }
}

export function useXyzTrace(xyz: UseXyz, xyzTraceId: XyzTraceId, tokenProvider: () => Promise<string>) {
    const xyzTraceSnapShot = useAppSelector((state) => selectXyzTrace(state, xyzTraceId));
    const xyzTrace = React.useMemo(
        () => createXyzTraceObjectFromSnapshot(xyz, xyzTraceSnapShot, tokenProvider),
        [xyzTraceSnapShot]
    );
    return xyzTrace;
}

export function deleteAllXyzTraces(xyz: UseXyz, tokenProvider: () => Promise<string>) {
    return async function deleteAllXyzTracesThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshots = selectEnabledXyzTraces(state);

        xyz?.clearSlice?.();

        dispatch(setXyzSliceToolOpen(false));

        return Promise.all(
            Object.keys(xyzTraceSnapshots).map(async (xyzTraceId) => {
                const xyzTrace = xyzTraceSnapshots[xyzTraceId];
                const trace = createXyzTraceObjectFromSnapshot(xyz, xyzTrace, tokenProvider);
                await trace.setEnabled(dispatch, false);
            })
        );
    };
}
