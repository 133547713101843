import {
    domainGridMenu,
    domainSamplesMenu,
    drillingAttributesMenu,
    drillingObjectMenu,
    gridObjectMenu,
    meshObjectMenu,
} from 'App/MainApp/TreeView/menu/objectMenus';
import { SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { getActiveObjects, getCommonData } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { DomainType } from 'App/util/ProjectDataTypes/Domain';
import { DomainGridDefinitionType } from 'App/util/ProjectDataTypes/DomainGridDefinition';
import { GridDefinitionType } from 'App/util/ProjectDataTypes/GridDefinition';
import { ProjectTreeNode, ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { MeshFileType } from 'App/util/ProjectDataTypes/MeshFile';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { SourceFileType } from 'App/util/ProjectDataTypes/SourceFile';
import { domainSamplesSectionDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { name2DataCy } from 'Common/testUtils/genericTestUtils/dataCyUtils';

export function getDrillingNode(drillingObject: SourceFileType, parentNode: ProjectTreeNode): ProjectTreeObjectNode {
    const drillingNode: ProjectTreeObjectNode = {
        ...getCommonData(drillingObject, parentNode),
        dataCy: drillingObject.original_file_name,
        section: drillingObject.original_file_name,
        rightClickMenu: drillingObjectMenu,
        canDisplaySpotlight: true,
    };

    const { dataAttributes } = drillingObject.meta_data;

    const dataAttributesSubsections = dataAttributes.reduce((subsections, drillingAttributeName) => {
        subsections[drillingAttributeName] = {
            ...getCommonData(drillingObject, drillingNode),
            nodeId: `${drillingNode?.nodeId}_${drillingAttributeName}`,
            section: drillingAttributeName,
            dataCy: drillingAttributeName,
            rightClickMenu: drillingAttributesMenu,
            canDisplaySpotlight: true,
        };

        return subsections;
    }, {});

    drillingNode.subsections = dataAttributesSubsections;

    return drillingNode;
}

export function getGridNode(grid: GridDefinitionType, parentNode: ProjectTreeNode): ProjectTreeObjectNode {
    return {
        ...getCommonData(grid, parentNode),
        section: grid.name,
        dataCy: grid.name,
        rightClickMenu: gridObjectMenu,
        canDisplaySpotlight: true,
    };
}

export function getMeshNode(mesh: MeshFileType, parentNode: ProjectTreeNode): ProjectTreeObjectNode {
    return {
        ...getCommonData(mesh, parentNode),
        section: mesh.original_file_name,
        dataCy: name2DataCy(mesh.original_file_name),
        rightClickMenu: meshObjectMenu,
        canDisplaySpotlight: true,
    };
}

export function getMeshAndDomainNode(
    mesh: MeshFileType,
    allObjects: ObjectClassName2Id2Obj,
    parentNode: ProjectTreeNode
): ProjectTreeObjectNode {
    const meshNode = {
        ...getCommonData(mesh, parentNode),
        section: mesh.original_file_name,
        dataCy: name2DataCy(mesh.original_file_name),
        rightClickMenu: meshObjectMenu,
        canDisplaySpotlight: true,
        subsections: {},
    };

    const domain: DomainType = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.Domain).find(
        (domain) => domain.meshFileId === mesh.id
    );

    if (domain) {
        meshNode.subsections[SectionNames.Samples] = getDomainSamplesNode(domain, meshNode);

        const domainGrids = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.Domain_GridDefinition).filter(
            (domainGrid) => {
                return domainGrid.domainId === domain.id;
            }
        );

        domainGrids.forEach((domainGrid) => {
            meshNode.subsections[domainGrid.id] = getDomainGridNode(
                domainGrid,
                allObjects[OBJECT_CLASS_NAMES.GridDefinition][domainGrid.gridDefinitionId],
                meshNode
            );
        });
    }

    return meshNode;
}

function getDomainDataAttributeNode(dataAttribute: string, domain: DomainType, parentNode: ProjectTreeNode) {
    const dataAttributeNode: ProjectTreeObjectNode = {
        section: dataAttribute,
        ...getCommonData(domain, parentNode),
        nodeId: `${parentNode.nodeId}_${dataAttribute}`,
        dataCy: dataAttribute,
        rightClickMenu: domainSamplesMenu,
        canDisplaySpotlight: true,
    };

    return dataAttributeNode;
}

function getDomainSamplesNode(domain: DomainType, parentNode: ProjectTreeNode) {
    const samplesNode: ProjectTreeObjectNode = {
        section: SectionNames.Samples,
        dataCy: domainSamplesSectionDataCy,
        ...getCommonData(domain, parentNode),
        rightClickMenu: domainSamplesMenu,
        canDisplaySpotlight: true,
    };

    const samplesSubsections = {};

    const dataAttributeMap = domain.result_meta_data?.dataAttribute2info ?? {};

    Object.keys(dataAttributeMap).forEach((dataAttribute) => {
        samplesSubsections[dataAttribute] = getDomainDataAttributeNode(dataAttribute, domain, samplesNode);
    });

    samplesNode.subsections = samplesSubsections;

    return samplesNode;
}

function getDomainGridNode(
    domainGrid: DomainGridDefinitionType,
    grid: GridDefinitionType,
    parentNode: ProjectTreeNode
) {
    const domainGridNode: ProjectTreeObjectNode = {
        dataCy: name2DataCy(grid.name),
        ...getCommonData(domainGrid, parentNode),
        section: grid.name,
        rightClickMenu: domainGridMenu,
        canDisplaySpotlight: true,
    };

    return domainGridNode;
}

export function getDomainNode(
    domain: DomainType,
    allObjects: ObjectClassName2Id2Obj,
    parentNode: ProjectTreeNode
): ProjectTreeObjectNode {
    const domainNode: ProjectTreeObjectNode = {
        ...getCommonData(domain, parentNode),
        section: domain.name,
        dataCy: name2DataCy(domain.name),
        rightClickMenu: [],
        canDisplaySpotlight: true,
        subsections: {},
    };

    domainNode.subsections[SectionNames.Samples] = getDomainSamplesNode(domain, domainNode);

    const domainGrids = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.Domain_GridDefinition).filter((domainGrid) => {
        return domainGrid.domainId === domain.id;
    });

    domainGrids.forEach((domainGrid) => {
        domainNode.subsections[domainGrid.id] = getDomainGridNode(
            domainGrid,
            allObjects[OBJECT_CLASS_NAMES.GridDefinition][domainGrid.gridDefinitionId],
            domainNode
        );
    });

    return domainNode;
}
