import { TreePathType } from 'Common/types/MainTreePathType';
import { OBJECT_CLASS_NAME, ObjectIDType } from './ProjectObjectsDataTypes';
import { GeneralMenuItemProps, ObjectMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';

export type TreePath = string[]; // should be an array of ancestors' nodeIds not including current object's id

export type MainTreeType = Subsections;

export type RightClickMenu = React.FunctionComponent<GeneralMenuItemProps | ObjectMenuItemProps>[];

export interface Subsections<T = ProjectTreeNode> {
    [id: ObjectIDType]: T;
}

export type ProjectTreeSectionNode = {
    section: string; // name, basically
    subsections?: Subsections;
    treePath?: TreePathType;
    dataCy: string;
    rightClickMenu?: RightClickMenu;
    leftCheckBoxChecked?: boolean;
    always_show_when_searching_in_MainTree?: boolean;
    canDisplaySpotlight?: boolean;
    nodeId: string; // needs to be unique in tree and deterministic (build tree from same data -> get same nodeIds)
    iconOverride?: React.FunctionComponent;
    path: TreePath;
};

export type ProjectTreeObjectNode = ProjectTreeSectionNode & {
    id: ObjectIDType; // for objects usually would be the uuid
    className: OBJECT_CLASS_NAME;
};

export type ProjectTreeNode = ProjectTreeSectionNode | ProjectTreeObjectNode;

export enum ProjectTreeSource {
    MAIN_TREE = 'MAIN_TREE',
    ANISOTROPY_ESTIMATION = 'ANISOTROPY_ESTIMATION',
    ANISOTROPY_GRID = 'ANISOTROPY_GRID',
    ANISOTROPY_GLOBAL = 'ANISOTROPY_GLOBAL',
    POINT_ESTIMATION_ID = 'POINT_ESTIMATION_ID',
    POINT_ESTIMATION_OK = 'POINT_ESTIMATION_OK',
    MERGED_POINT_ESTIMATIONS = 'MERGED_POINT_ESTIMATIONS',
    ZONES = 'ZONES',
    ZONES_FROM_ANISOTROPY = 'ZONES_FROM_ANISOTROPY',
    OVERLAPS = 'OVERLAPS',
}
