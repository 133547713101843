import { ProjectObjectActionType } from 'App/Redux/features/globalContext/projectObjectSlice';
import { OBJECT_CLASS_NAMES, ObjectStatusTypes } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';

export const handleUploadOrPublishFileProgress = (
    objectId: string,
    objectClassName: OBJECT_CLASS_NAMES,
    status: ObjectStatusTypes,
    progress_status: number,
    fileName: string,
    projectUploadObject: { [id: string]: ProjectObjectActionType },
    isEvo: boolean = false
) => {
    const projectObjectAction: ProjectObjectActionType = {
        id: objectId,
        object_class_name: objectClassName,
        status: status,
        url: null,
        progress_status: progress_status,
        original_file_name: fileName,
        evo: isEvo,
        uploadingOrPublishing: true,
    };

    projectUploadObject[objectId] = projectObjectAction;

    return projectUploadObject;
};

export const resetUploadOrPublishFileProgress = (
    objectId: string,
    projectUploadObject: { [id: string]: ProjectObjectActionType },
    status: ObjectStatusTypes,
    progress_status: number
) => {
    setTimeout(() => {
        projectUploadObject[objectId].status = status;
        projectUploadObject[objectId].progress_status = progress_status;
    }, 2000);

    return projectUploadObject;
};
