import { SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { Domain, DomainType } from 'App/util/ProjectDataTypes/Domain';
import { ProjectObjectIdentifier } from 'App/util/ProjectDataTypes/ProjectObjectIdentifier';
import { ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';
import { DrillTrace, DrillTraceSnapshot } from 'Common/Xyz/XyzTraces/DrillTrace';
import { InValidateAllColorValueSummaries, XyzTraceClassNames, XyzTraceId } from 'Common/Xyz/XyzTraces/XyzTrace';

function getDomainSamplesName(domain: Domain, drillingAttribute: string) {
    return `${drillingAttribute} D[${domain.getName()}]`;
}

function createInitialDrillSnapshot(
    xyzTraceId: string,
    domain: Domain,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    drillingAttribute?: string
): DrillTraceSnapshot {
    const { dataAttribute2info } = domain.result_meta_data;
    const drillAttributeTraces = {} as DrillTraceSnapshot['colorAttributeTraces'];

    const projectObjectIdentifier = new ProjectObjectIdentifier({
        objectClassName: domain.object_class_name,
        id: domain.id,
    });
    const jobUrl = projectObjectIdentifier.getObjectUrl(currentOrgUuid, objectClassName2id2obj);

    const mainDomainAttributeName = getDomainSamplesName(domain, SectionNames.Samples);

    drillAttributeTraces[mainDomainAttributeName] = {
        colorAttributeName: mainDomainAttributeName,
        useSingleColor: true,
        colorValueSummariesValid: false,
        colorValueSummaries: undefined,
        colorArrayUrl: undefined,
    };
    Object.keys(dataAttribute2info).forEach((drillingAttributeName) => {
        const domainDrillAttributeName = getDomainSamplesName(domain, drillingAttributeName);
        drillAttributeTraces[domainDrillAttributeName] = {
            colorAttributeName: domainDrillAttributeName,
            spotLightAttributeName: drillingAttributeName,
            useSingleColor: false,
            colorValueSummariesValid: false,
            colorValueSummaries: undefined,
            colorArrayUrl: `${jobUrl}?bytes_array_for_assay=${drillingAttributeName}`,
        };
        return drillAttributeTraces;
    });

    return {
        id: xyzTraceId,
        className: XyzTraceClassNames.DrillTrace,
        colorAttributeTraces: drillAttributeTraces,
        enabled: true,
        visible: true,
        projectObjectIdentifier: {
            objectClassName: domain.object_class_name,
            id: domain.id,
        },
        selectedColorAttribute: drillingAttribute
            ? getDomainSamplesName(domain, drillingAttribute)
            : getDomainSamplesName(domain, SectionNames.Samples),
        verticesUrl: `${jobUrl}?xyz_bytes_array=true`,
        objectHash: domain.updated_at,
    };
}

async function createInitialXyzDrillTrace(
    xyzTraceId: string,
    dispatch: AppDispatch,
    domain: Domain,
    xyz: UseXyz,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    drillingAttribute?: string,
    tokenProvider?: () => Promise<string>
) {
    const initialDrillSnapshot = createInitialDrillSnapshot(
        xyzTraceId,
        domain,
        objectClassName2id2obj,
        currentOrgUuid,
        drillingAttribute
    );

    const drillTrace = new DrillTrace(xyz, initialDrillSnapshot, tokenProvider);
    await dispatch(drillTrace.plotAndSave());
}

export function plotDomainSamples(
    domainSnapshot: DomainType,
    xyzTraceId: XyzTraceId,
    xyz: UseXyz,
    currentOrgUuid: string,
    drillingAttribute?: string,
    tokenProvider?: () => Promise<string>
) {
    return async function plotDrillObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshot = selectXyzTrace(state, xyzTraceId);
        const objectClassName2id2obj = selectObjectClassname2Id2Obj(state);
        const domain = wrapObjectInClass(domainSnapshot, undefined, objectClassName2id2obj) as Domain;
        if (xyzTraceSnapshot) {
            const drillTrace = new DrillTrace(xyz, xyzTraceSnapshot as DrillTraceSnapshot, tokenProvider);
            const drillAttributeFinal = drillingAttribute
                ? getDomainSamplesName(domain, drillingAttribute)
                : Object.values(drillTrace.colorAttributeTraces).find(
                      (drillAttributeTrace) => drillAttributeTrace.useSingleColor
                  ).colorAttributeName;
            const newDrillTrace = await drillTrace.updateSelectedAttribute(dispatch, drillAttributeFinal);
            if (drillTrace.isEnabled() && drillAttributeFinal === drillTrace.selectedColorAttribute) {
                await newDrillTrace.incrementObjectHash(dispatch, true, InValidateAllColorValueSummaries.No); // if the object was already enabled, we will increment the objectHash to force redownload of the data.
            }
        } else {
            await createInitialXyzDrillTrace(
                xyzTraceId,
                dispatch,
                domain,
                xyz,
                objectClassName2id2obj,
                currentOrgUuid,
                drillingAttribute,
                tokenProvider
            );
        }
    };
}
