import { AxiosProgressEvent } from 'axios';
import { MeshTypes } from 'App/util/ProjectDataTypes/MeshFile';
import { APIResponseWithProjectUpdate } from '../../../../util/ProjectDataTypes/APIResponseTypes';
import { AxiosDriverFlaskInstance } from '../../../../util/axiosErrorHandlers';
import { GooseKnownSchemas, SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';

export type MeshFileData = {
    files?: File[];
    lastMeshType: 'closed' | 'open';
    gridDefinitionId: string;
    abortController: AbortController;
    isGoose: boolean;
    gooseObjectId?: ObjectIDType;
};

export async function uploadMeshFile(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    data: MeshFileData,
    url: string,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    runJobOnLambda: boolean = false
) {
    const formData = new FormData();

    if (data.isGoose) {
        formData.append('goose_object', `${data.isGoose}`);
        formData.append('goose_object_id', data.gooseObjectId);
    } else {
        for (let i = 0; i < data.files.length; i++) {
            const file = data.files[i];
            formData.append('files[]', file);
        }
    }

    if (data.lastMeshType === MeshTypes.closed) {
        formData.append('gridDefinitionId', `${data.gridDefinitionId}`);
    }
    formData.append('type', data.lastMeshType);

    if (runJobOnLambda) {
        formData.append('feature_flag_prefer_run_in_lambdas', 'true');
    }

    const config = {
        withCredentials: true,
        responseType: 'json' as const,
        signal: data.abortController.signal,
        onUploadProgress,
    };

    return axiosDriverFlask.post<APIResponseWithProjectUpdate>(url, formData, config);
}

export async function getMeshGooseFile(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentOrgUuid: string,
    workspaceId: string,
    gooseObjectId: string
) {
    const params = {
        objectType: GooseKnownSchemas.TriangleMesh,
    };

    return axiosDriverFlask.get(`/orgs/${currentOrgUuid}/workspaces/${workspaceId}/goose/${gooseObjectId}`, {
        params,
        responseType: 'json',
        withCredentials: true,
    });
}

export const expandMeshNodes = (
    onExpandChange: (nodes: ObjectIDType[], expand: boolean) => void,
    meshType: MeshTypes
) => {
    const isOpenMesh = meshType === MeshTypes.open;

    const meshSectionId = SectionIds[SectionNames.Meshes];
    const meshTypeSectionId = isOpenMesh ? SectionIds[SectionNames.OpenMeshes] : SectionIds[SectionNames.ClosedMeshes];

    const nodesToExpand = [meshSectionId, meshTypeSectionId];

    onExpandChange(nodesToExpand, true);
};
