import { getSession } from 'App/Login/Session';
import { appConfig } from 'Common/config';
import { EvoHub, EvoOrg, EvoServiceAccess } from './EvoApi';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { getOrgIdFromUrl } from 'Common/utils/urlUtils';

export const CURRENT_ORG_KEY = 'evo_driver_savedOrgChoice';
// const SAVE_SELECTED_ORG = 'evo_driver_saveSelectedOrg';

export const CURRENT_HUB_KEY = 'evo_driver_savedHubChoice';
// const SAVE_SELECTED_HUB = 'evo_driver_saveSelectedHub';

const SESSION_STORAGE_HUB_CODE_KEY = 'evo_driver_currentHubCode';

export function saveOrgChoice(orgId: ObjectIDType) {
    if (orgId) {
        localStorage.setItem(CURRENT_ORG_KEY, orgId);
    } else {
        localStorage.removeItem(CURRENT_ORG_KEY);
    }
}

export function saveHubChoice(hubId: ObjectIDType) {
    if (hubId) {
        localStorage.setItem(CURRENT_HUB_KEY, hubId);
    } else {
        localStorage.removeItem(CURRENT_HUB_KEY);
    }
}

export function getSavedOrgChoice() {
    const savedOrgChoiceId = localStorage.getItem(CURRENT_ORG_KEY);
    return savedOrgChoiceId;
}

export function getCurrentOrgId() {
    return getOrgIdFromUrl() || getSavedOrgChoice();
}

export const parseJwt = (token: string) => {
    const tokenPart = token.split('.')[1];
    const decoded = Buffer.from(tokenPart, 'base64').toString();
    return JSON.parse(decoded);
};

export const hasAccessToOrg = () => {
    const currentHubCode = getCurrentHubCode();
    return currentHubCode !== undefined;
};

function getOrgInfo(currentOrgUuid: string) {
    const session = getSession();
    const orgInfo = session?.organizations?.find((org) => org.id === currentOrgUuid);
    return orgInfo;
}

function getHubInfo(currentHubCode: string) {
    const session = getSession();
    const hubInfo = session?.hubs?.find((hub) => hub.code === currentHubCode);
    return hubInfo;
}

export function getOrgName(currentOrgUuid: string) {
    const name = getOrgInfo(currentOrgUuid)?.display_name;
    return name;
}

export function getCurrentHubCode() {
    const sessionStorageHubCode = sessionStorage.getItem(SESSION_STORAGE_HUB_CODE_KEY);
    if (sessionStorageHubCode) {
        return sessionStorageHubCode;
    }

    const session = getSession();
    const currentOrgUuid = getCurrentOrgId();

    const availableHubs = getAvailableHubs(session?.serviceAccesses, session?.hubs, currentOrgUuid);
    const primitiveHubChoice = getPrimitiveHubChoice(availableHubs);

    if (primitiveHubChoice) {
        return primitiveHubChoice.code;
    }
    return undefined;
}

export function setCurrentHubCode(hubCode: string) {
    sessionStorage.setItem(SESSION_STORAGE_HUB_CODE_KEY, hubCode);
}

export const getHubUrl = () => {
    if (appConfig.forceBackendUrl) {
        return appConfig.forceBackendUrl;
    }
    const currentHubCode = getCurrentHubCode();
    let hubUrl = getHubInfo(currentHubCode)?.url;
    if (hubUrl === undefined) {
        hubUrl = `/driver`;
    } else {
        hubUrl = `${hubUrl}/driver`;
    }
    return hubUrl;
};

function hasChangeOrgParam() {
    const params = new URLSearchParams(window.location.search.substring(1));
    return params.has('changeOrg');
}

export function getPrimitiveOrgChoice(availableOrgs: EvoOrg[]): EvoOrg {
    if (hasChangeOrgParam()) {
        return undefined;
    } else if (availableOrgs.length === 1) {
        return availableOrgs[0];
    } else {
        const savedOrgChoiceId = getSavedOrgChoice();
        const savedOrgChoice = availableOrgs.find((org) => org.id === savedOrgChoiceId);
        if (savedOrgChoice) {
            return savedOrgChoice;
        }
        return undefined;
    }
}

export function getPrimitiveHubChoice(availableHubs: EvoHub[]): EvoHub {
    if (!availableHubs) {
        return undefined;
    }
    if (availableHubs.length === 1) {
        return availableHubs[0];
    } else if (hasChangeHubParam()) {
        return undefined;
    } else {
        const savedHubChoiceId = getSavedHubChoice();
        const savedHubChoice = availableHubs.find((hub) => hub.code === savedHubChoiceId);
        if (savedHubChoice) {
            return savedHubChoice;
        }
        return undefined;
    }
}

function hasChangeHubParam() {
    return hasChangeOrgParam();
}

export function getSavedHubChoice() {
    const savedHubChoiceId = localStorage.getItem(CURRENT_HUB_KEY);
    return savedHubChoiceId;
}

export function getAvailableHubs(serviceAccesses: EvoServiceAccess[], hubs: EvoHub[], orgId: string): EvoHub[] {
    if (!serviceAccesses || !hubs) {
        return [];
    }
    const filteredServiceAccesses = serviceAccesses.filter((serviceAccess) => serviceAccess.org_id === orgId);
    const filteredHubs = hubs.filter(
        (hub) => filteredServiceAccesses.findIndex((serviceAccess) => serviceAccess.hub_code === hub.code) >= 0
    );
    return filteredHubs;
}
