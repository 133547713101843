import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { DataContext } from 'App/DataContext';
import { ObjectMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import { displayObject } from 'App/MainApp/services/objectDisplayService';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import { ObjectStatusTypes } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { GeneralMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { addToViewDataCy, downloadMenuItemDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MenuItemIcon from '../../MenuItemIcon';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useSessionContext } from 'App/context/SessionContext';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

export default function AddToViewMenuItem(props: ObjectMenuItemProps) {
    const { addMessage } = useDriverMessagesContext();
    const allObjects = useAppSelector(selectObjectClassname2Id2Obj);
    const object = allObjects[props.nodeData.className][props.nodeData.id];
    const disabled = object.status !== ObjectStatusTypes.SUCCESS;
    const tooltip = disabled && 'Can only display successful objects';

    const { menuItemClass, currentOrgUuid } = React.useContext(DataContext);

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);
    const xyz = useXyz();
    const dispatch = useAppDispatch();

    const anisotropyClustering = useFlags()?.anisotropyClustering;

    const onClick = async () => {
        await displayObject(
            object,
            dispatch,
            xyz,
            currentOrgUuid,
            props.nodeData,
            addMessage,
            anisotropyClustering,
            tokenProvider
        );

        props.handleMenuClose();
    };

    return (
        <Tooltip disableInteractive title={tooltip} placement="right">
            <div>
                <MenuItem
                    className={menuItemClass.menuItem}
                    id={downloadMenuItemDataCy}
                    onClick={onClick}
                    disabled={disabled}
                    data-cy={addToViewDataCy}
                >
                    <MenuItemIcon name={GeneralMenuItems.addToView} />
                    {GeneralMenuItems.addToView}
                </MenuItem>
            </div>
        </Tooltip>
    );
}
