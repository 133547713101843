/* eslint-disable max-classes-per-file */
import { parseJwt } from './AuthUtils';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer'; // fix for webpack 5
import { appConfig } from 'Common/config';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { Session, clearAuthSession, getSession, setAuthSessionOauthAndUser } from 'App/Login/Session';
import { minToMs, secToMs } from 'Common/utils/dateTimeUtils';

interface AuthState {
    url: string;
    state: string;
    verifier: string;
    createdAt: number;
}

interface AuthIdToken {
    sub: string;
    // aud: string
    // iss: string
    // exp: string
    given_name: string;
    // iat: string
    family_name: string;
    email: string;
}

interface RawAuthResponse {
    access_token: string;
    remember: boolean;
    refresh_token: string;
    scope: string;
    id_token: string;
    token_type: string;
    expires_in: number;
    issued_at: number;
}

interface AuthUserInfo {
    sub: string;
    name: string;
    email: string;
}

const AUTH_STATE_MAX_TIME = 60 * 5;

export class AuthError extends Error {}

const toB64Url = (b64: string) => {
    return b64.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '-');
};
// const fromB64Url = (b64url: string) => {
//   return b64url.replaceAll("-", "+").replace("_", "/")
// }

export class AuthApi {
    /** The URL where tokens can be acquired from */
    static readonly TOKEN_URL = `${appConfig.idpBaseUrl}/connect/token`;

    /** The URL where the browser will be redirected to when the user logs in/out */
    static readonly REDIRECT_URI = appConfig.idpRedirectUri;

    /** The URL where a token can be inspected */
    static readonly INTROSPECT_URL = `${appConfig.idpBaseUrl}/connect/introspect`;

    /** URL where user information can be requested */
    static readonly USERINFO_URL = `${appConfig.idpBaseUrl}/connect/introspect`;

    static collectGarbage = () => {
        const now = Date.now() / 1000;
        for (let i = 0; i < sessionStorage.length; ++i) {
            const key = sessionStorage.key(i);
            if (key.startsWith('auth-')) {
                const authState: AuthState | null = JSON.parse(sessionStorage.getItem(key));

                if (typeof authState.createdAt === 'undefined' || now - authState.createdAt >= AUTH_STATE_MAX_TIME) {
                    sessionStorage.removeItem(key);
                }
            }
        }
    };

    private static getAuthState = (state: string): AuthState | null => {
        this.collectGarbage();

        const key = `auth-${state}`;
        const authState: AuthState | null = JSON.parse(sessionStorage.getItem(key));

        sessionStorage.removeItem(key);

        return authState;
    };

    private static setAuthState = (state: string, data: AuthState) => {
        const key = `auth-${state}`;
        sessionStorage.setItem(key, JSON.stringify(data));
    };

    static startLogin = async (extraScopes: Array<string> = []) => {
        const state = uuidv4();

        // We don't really need verifier in base64, this is just an easy way to "convert" it into pure ASCII
        const verifier = toB64Url(Buffer.from(crypto.getRandomValues(new Uint8Array(40))).toString('base64'));
        const challenge = toB64Url(
            Buffer.from(await crypto.subtle.digest('SHA-256', Buffer.from(verifier)))
                .toString('base64')
                .slice(0, 43)
        );

        const url = new URL(appConfig.idpAuthzUrl);

        const scopes = Array.from(new Set(['profile', 'email', 'openid', ...extraScopes]));

        url.searchParams.set('response_type', 'code');
        url.searchParams.set('client_id', appConfig.idpClientId);
        url.searchParams.set('redirect_uri', this.REDIRECT_URI);
        url.searchParams.set('scope', scopes.join(' '));
        url.searchParams.set('code_challenge_method', 'S256');
        url.searchParams.set('code_challenge', challenge);
        url.searchParams.set('state', state);

        const authState: AuthState = {
            url: url.toString(),
            state: state,
            verifier: verifier,
            createdAt: Date.now() / 1000,
        };

        this.setAuthState(state, authState);
        return url.toString();
    };

    static continueLogin = async (axiosDriverFlask: AxiosDriverFlaskInstance, state, code) => {
        const authState = this.getAuthState(state);

        if (authState === null) {
            return new AuthError('Stale state');
        }

        const formData = new URLSearchParams();
        formData.set('grant_type', 'authorization_code');
        formData.set('redirect_uri', this.REDIRECT_URI);
        formData.set('client_id', appConfig.idpClientId);
        formData.set('code_verifier', authState.verifier);
        formData.set('code', code);

        let response: Response;
        try {
            response = await fetch(this.TOKEN_URL, {
                method: 'POST',
                body: formData,
            });
        } catch (e: any) {
            console.error('Token request failed.', e);
            throw new AuthError('Token request failed', { cause: e });
        }

        return this.createSesssionFromAuthResponse(await response.json());
    };

    private static createSesssionFromAuthResponse = async (response: RawAuthResponse) => {
        const idToken: AuthIdToken = parseJwt(response.id_token);

        return <Session>{
            oauth: {
                accessToken: response.access_token,
                refreshToken: response.refresh_token,
                tokenType: response.token_type,
                expiresAt: Date.now() + secToMs(response.expires_in),
                issuedAt: response.issued_at,
            },
            user: {
                uuid: idToken.sub,
                name: `${idToken.given_name} ${idToken.family_name}`,
                email: idToken.email,
            },
        };
    };

    static logoutUrl = () => {
        const url = new URL(`${appConfig.idpBaseUrl}/connect/endsession`);
        url.searchParams.set('oauthConsumerKey', appConfig.idpClientId);
        url.searchParams.set('logoutCallback', appConfig.idpRedirectUri);
        return url.toString();
    };

    static refreshToken = async (axiosDriverFlask: AxiosDriverFlaskInstance, refreshToken: string) => {
        const formData = new URLSearchParams();
        formData.set('grant_type', 'refresh_token');
        formData.set('refresh_token', refreshToken);
        formData.set('client_id', appConfig.idpClientId);

        let response;
        try {
            response = await axiosDriverFlask.post(this.TOKEN_URL, formData);
        } catch (e: any) {
            throw new AuthError('Token request failed', { cause: e });
        }

        return this.createSesssionFromAuthResponse(response.data);
    };

    static userInfo = async (axiosDriverFlask: AxiosDriverFlaskInstance, accessToken: string) => {
        let info: AuthUserInfo;

        try {
            info = await axiosDriverFlask.get(this.USERINFO_URL, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!info.email) {
                throw new AuthError('User has no email');
            }

            return info;
        } catch (e: any) {
            if (e.response?.status_code === 401) {
                return new AuthError('Unauthorized', { cause: e });
            }
            throw e;
        }
    };

    static introspect = async (axiosDriverFlask: AxiosDriverFlaskInstance, accessToken: string) => {
        const formData = new URLSearchParams({
            client_id: appConfig.idpClientId,
            // client_secret: varifyConfig.idpClientSecret,
            token: accessToken,
        });

        return axiosDriverFlask.post(`${this.INTROSPECT_URL}`, formData);
    };

    static refreshTokenIfNeeded = async (
        axiosDriverFlask: AxiosDriverFlaskInstance,
        terminateLoginSession: () => void
    ) => {
        const session = getSession();

        if (session && session?.oauth?.expiresAt && session?.oauth?.accessToken) {
            if (isTimeToRefresh(session.oauth.expiresAt)) {
                try {
                    const newSession = await this.refreshToken(axiosDriverFlask, session.oauth.refreshToken);
                    setAuthSessionOauthAndUser(newSession.oauth, newSession.user);
                } catch {
                    // axios interceptor will take care of it
                    clearAuthSession();
                    terminateLoginSession();
                }
            }
        } else {
            terminateLoginSession();
        }
    };

    static refreshTokenPromise = null;

    static refreshTokenIfNeededAndSetPromise = async (
        axiosDriverFlask: AxiosDriverFlaskInstance,
        terminateLoginSession: () => void
    ) => {
        if (!AuthApi.refreshTokenPromise) {
            AuthApi.refreshTokenPromise = AuthApi.refreshTokenIfNeeded(axiosDriverFlask, terminateLoginSession);
        }
        await AuthApi.refreshTokenPromise;
        AuthApi.refreshTokenPromise = null;
    };
}

const TOKEN_REFRESH_TIME_BUFFER_MINUTES = 7;

export function isTimeToRefresh(expiresAt: number) {
    return expiresAt < Date.now() + minToMs(TOKEN_REFRESH_TIME_BUFFER_MINUTES);
}
