import { getZoneSectionMenu } from 'App/MainApp/TreeView/menu/sectionMenus';
import { buildGridsSubtrees, GridsSubtreeType } from 'App/MainApp/TreeView/treeData/commonNodesService';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { getActiveObjects } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { ProjectTreeNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { zonesSectionDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function buildZonesSubtree(allObjects: ObjectClassName2Id2Obj, tree: Subsections) {
    if (!tree.hasOwnProperty(SectionNames.Zones)) {
        tree[SectionNames.Zones] = {
            section: SectionNames.Zones,
            dataCy: zonesSectionDataCy,
            rightClickMenu: getZoneSectionMenu(),
            subsections: {},
            nodeId: SectionIds[SectionNames.Zones],
            path: [],
        };
    }

    buildZonesGridSubtrees(allObjects, tree[SectionNames.Zones]);
}

function buildZonesGridSubtrees(allObjects: ObjectClassName2Id2Obj, parentNode: ProjectTreeNode) {
    const zones = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.Zone);

    buildGridsSubtrees(zones, allObjects, parentNode, GridsSubtreeType.Zone);
}
