import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import {
    applyWebsocketModificationsToCurrentProject,
    backendProjectUrl,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
    selectObjectByClassnameAndId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import React from 'react';
import WidgetTextField from 'Common/components/Core/WidgetTextField';
import { overlapsFolderDialogDataCy, submitButtonDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import PrimaryButton from 'Common/components/Core/PrimaryButton';
import SecondaryButton from 'Common/components/Core/SecondaryButton';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import { OBJECT_CLASS_NAMES, ObjectIDType } from '../../../util/ProjectDataTypes/ProjectObjectsDataTypes';
import { APIResponseWithProjectUpdate } from '../../../util/ProjectDataTypes/APIResponseTypes';
import { DataContext } from '../../../DataContext';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { useSessionContext } from 'App/context/SessionContext';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

export type OverlapFolderDialogProps = {
    rename: boolean;
    handleClose(): void;
    folderName: string;
    folderId?: ObjectIDType;
};

function NewOverlapsFolder(props: OverlapFolderDialogProps) {
    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const xyz = useXyz();

    const DEFAULTS = {
        name: props.rename ? props.folderName : '',
    };
    const [formSelections, setFormSelections] = React.useState(DEFAULTS);
    const [spinner, setSpinner] = React.useState(false);

    const dispatch = useAppDispatch();

    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const folderObject = useAppSelector(selectObjectByClassnameAndId(OBJECT_CLASS_NAMES.OverlapFolder, props.folderId));

    const { onExpandChange } = useExpandedNodes();

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const expandOverlapsProfileNode = () => {
        const overlapSectionId = SectionIds[SectionNames.Overlaps];
        const nodesToExpand = [overlapSectionId];

        onExpandChange(nodesToExpand, true);
    };

    function cancelFolder() {
        props.handleClose();
    }

    const onSubmit = () => {
        const formData = new FormData();
        formData.append('name', formSelections.name);
        setSpinner(true);
        axiosDriverFlask
            .post<APIResponseWithProjectUpdate>(
                `${backendProjectUrl(currentOrgUuid, currentWorkspaceId, currentProjectId)}/overlapProfile`,
                formData,
                {
                    withCredentials: true,
                    responseType: 'json',
                }
            )
            .then((projectUpdateJson) => {
                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'submit overlap folder',
                        tokenProvider
                    )
                );
                expandOverlapsProfileNode();

                props.handleClose();
                setSpinner(false);
            })
            .catch((e) => {
                throw e;
            });
    };

    const onRenameSubmit = () => {
        const formData = new FormData();
        formData.append('name', formSelections.name);
        setSpinner(true);
        axiosDriverFlask
            .patch<APIResponseWithProjectUpdate>(folderObject?.url, formData, {
                withCredentials: true,
                responseType: 'json',
            })
            .then((projectUpdateJson) => {
                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'submit rename overlap folder',
                        tokenProvider
                    )
                );
                props.handleClose();
                setSpinner(false);
            })
            .catch((e) => {
                throw e;
            });
    };

    return (
        <GenericDialogShell
            maxWidth="xs"
            aria-labelledby="draggable-dialog-title"
            dataCy={overlapsFolderDialogDataCy}
            title={!props.rename ? 'Create New Folder' : 'Rename Folder'}
            handleClose={props.handleClose}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    Folder Name
                </Grid>
                <Grid item xs={6}>
                    <WidgetTextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        value={formSelections.name}
                        onChange={(e) =>
                            setFormSelections({
                                ...formSelections,
                                ...{ name: e.target.value },
                            })
                        }
                        disabled={spinner}
                        data-cy="folder-name-textbox"
                    />
                </Grid>
            </Grid>
            <DialogActions>
                {spinner && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 18,
                            width: '100%',
                        }}
                    >
                        <CircularProgress variant="indeterminate" size={15} />
                        <Typography variant="caption" style={{ marginLeft: 10 }}>
                            {!props.rename ? 'Saving' : 'Renaming'} folder please wait...
                        </Typography>
                    </div>
                )}
                <SecondaryButton autoFocus onClick={() => cancelFolder()}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton
                    onClick={!props.rename ? onSubmit : onRenameSubmit}
                    disabled={spinner}
                    data-cy={submitButtonDataCy}
                >
                    Submit
                </PrimaryButton>
            </DialogActions>
        </GenericDialogShell>
    );
}

export default NewOverlapsFolder;
