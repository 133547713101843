import {
    ValidationResult,
    getDefaultValidationResult,
    validateGreaterOrEqual,
    validateGreaterThan,
    validateIsInteger,
    validateNotEmpty,
    validateSmallerOrEqual,
} from '../../../util/validationUtils';

export function isPowerParameterValid(value: string): ValidationResult {
    const validationResult = getDefaultValidationResult();

    validateNotEmpty(value, validationResult);

    if (validationResult.isValid) {
        const numberValue = Number(value);
        validateGreaterThan(numberValue, 0, validationResult);
        validateSmallerOrEqual(numberValue, 5, validationResult);
    }

    return validationResult;
}

export function isMinSamplesValid(value: string, maxSamples: string): ValidationResult {
    const validationResult = getDefaultValidationResult();

    validateNotEmpty(value, validationResult);

    if (validationResult.isValid) {
        const numberValue = Number(value);
        validateGreaterThan(numberValue, 0, validationResult);
        validateSmallerOrEqual(numberValue, 1000, validationResult);

        if (maxSamples) {
            const maxSamplesNumber = Number(maxSamples);
            validateSmallerOrEqual(
                numberValue,
                maxSamplesNumber,
                validationResult,
                'Should be smaller than or equal to upper samples limit'
            );
        }

        validateIsInteger(numberValue, validationResult);
    }
    return validationResult;
}

export function isMaxSamplesValid(value: string, minSamples: string): ValidationResult {
    const validationResult = getDefaultValidationResult();

    validateNotEmpty(value, validationResult);

    if (validationResult.isValid) {
        const numberValue = Number(value);
        validateGreaterThan(numberValue, 0, validationResult);

        if (minSamples) {
            const minSamplesNumber = Number(minSamples);
            validateGreaterOrEqual(
                numberValue,
                minSamplesNumber,
                validationResult,
                'Should be greater than or equal to lower samples limit'
            );
        }

        validateSmallerOrEqual(numberValue, 1000, validationResult);
        validateIsInteger(numberValue, validationResult);
    }
    return validationResult;
}

export function isMaxSamplesPerDrillholeValid(
    value: string,
    isMaxSamplesPerDrillholeActive: boolean
): ValidationResult {
    const validationResult = getDefaultValidationResult();

    if (!isMaxSamplesPerDrillholeActive) {
        return validationResult;
    }

    validateNotEmpty(value, validationResult);

    if (validationResult.isValid) {
        const numberValue = Number(value);
        validateGreaterThan(numberValue, 0, validationResult);
        validateSmallerOrEqual(numberValue, 10000, validationResult);
        validateIsInteger(numberValue, validationResult);
    }

    return validationResult;
}

export function isMaxSamplesPerOctantValid(value: string, isMaxSamplesPerOctantActive: boolean): ValidationResult {
    const validationResult = getDefaultValidationResult();

    if (!isMaxSamplesPerOctantActive) {
        return validationResult;
    }

    validateNotEmpty(value, validationResult);

    if (validationResult.isValid) {
        const numberValue = Number(value);
        validateGreaterThan(numberValue, 0, validationResult);
        validateSmallerOrEqual(numberValue, 10000, validationResult);
        validateIsInteger(numberValue, validationResult);
    }

    return validationResult;
}
