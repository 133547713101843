import React from 'react';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { SecondaryMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { uploadDrillingFromEvoDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import UploadDrillingConfirmation, { UploadButtons } from './UploadDrillingConfirmation';

export function UploadDrillingFromEvoButton(props: { handleMenuClose(): void }) {
    return (
        <DialogOpenerMenuItem
            name={SecondaryMenuItems.FromEvo}
            tooltipText=""
            dataCy={uploadDrillingFromEvoDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={false}
            dialogComponent={UploadDrillingConfirmation}
            dialogProps={{
                source: UploadButtons.EVO,
            }}
        />
    );
}
