import { getInterpolationsSectionMenu } from 'App/MainApp/TreeView/menu/sectionMenus';
import { buildGridsSubtrees, GridsSubtreeType } from 'App/MainApp/TreeView/treeData/commonNodesService';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { getActiveObjects } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { ProjectTreeNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { interpolationsSectionDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function buildInterpolationsSubtree(allObjects: ObjectClassName2Id2Obj, tree: Subsections) {
    const nodeId = SectionIds[SectionNames.Interpolations];

    tree[SectionNames.Interpolations] = {
        section: SectionNames.Interpolations,
        dataCy: interpolationsSectionDataCy,
        rightClickMenu: getInterpolationsSectionMenu(),
        subsections: {},
        nodeId,
        path: [],
    };

    buildInterpolationsGridSubtrees(allObjects, tree[SectionNames.Interpolations], true);
}

export function buildInterpolationsGridSubtrees(
    allObjects: ObjectClassName2Id2Obj,
    parentNode: ProjectTreeNode,
    showAllGrids: boolean = false,
    noSpotlight = false
) {
    const pointEstimations = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.PointEstimation);

    buildGridsSubtrees(
        pointEstimations,
        allObjects,
        parentNode,
        GridsSubtreeType.Interpolation,
        showAllGrids,
        noSpotlight
    );
}
