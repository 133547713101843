import Logger, { ILogLevel } from 'js-logger';
import { LogLevels } from './logging_utils';

export const informationalLogs = Logger.get('informationalLogs');

function set_initial_informationalLogs_log_level() {
    if (process.env.NEXT_PUBLIC_INFORMATIONAL_LOGS_LEVEL) {
        const LogLevel = LogLevels?.[process.env.NEXT_PUBLIC_INFORMATIONAL_LOGS_LEVEL];
        if (LogLevel) {
            informationalLogs.setLevel(LogLevel);
            return;
        }
    }
    informationalLogs.setLevel(Logger.WARN);
}
set_initial_informationalLogs_log_level();

global.set_informationalLogs_logLevel = (level: ILogLevel) => {
    // type-coverage:ignore-line
    informationalLogs.setLevel(level);
};

global.set_informationalLogs_logLevel_to_INFO = () => {
    // type-coverage:ignore-line
    informationalLogs.setLevel(Logger.INFO);
};
global.set_informationalLogs_logLevel_to_WARN = () => {
    // type-coverage:ignore-line
    informationalLogs.setLevel(Logger.WARN);
};

global.informationalLogs = informationalLogs; // type-coverage:ignore-line
