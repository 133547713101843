import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import Typography from '@mui/material/Typography';
import { clearAuthSession, getSession } from 'App/Login/Session';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { AuthApi } from 'Common/api/AuthApi';
import { getHubUrl } from 'Common/api/AuthUtils';
import { checkLoggedInButNoAccessToOrg } from 'Common/components/ErrorHandling/NoAccessToOrgDialog';
import { Message } from '@local/messages/dist/Messages.types';

interface DRIVERDefaultErrorResponse {
    error_type: string;
    error_message: string;
}

export function showErrorSnackBarForAxiosError(
    error: AxiosError<DRIVERDefaultErrorResponse, Request>,
    addMessage: (message: Message) => void
) {
    // suppress auth error notifications for the user as we handle them in a different way
    if ([401, 403].includes(error?.response?.status)) {
        return;
    }

    if (!error.config) {
        // This error is not a axios request error!
        addMessage({
            message: error.message,
            type: NotificationType.ERROR,
        });
        return;
    }

    if (error.response) {
        // client received an error response (5xx, 4xx)
        if (error.response.status === 580) {
            const errorType = error.response.data ? error.response.data.error_type : 'Unknown Error';
            const errorMessage = error.response.data ? error.response.data.error_message : '';
            let displayErrorType = errorType;
            switch (errorType) {
                case 'AlertException':
                    displayErrorType = '';
                    break;
                case 'InsufficientPermissionException':
                    displayErrorType = 'Insufficient permission';
                    break;
            }
            addMessage({
                message: (
                    <>
                        <Typography>Request failed: {displayErrorType}</Typography>
                        <Typography>{errorMessage}</Typography>
                    </>
                ),
                type: NotificationType.ERROR,
            });
        } else if (error.response.status === 409) {
            const errorMessage = error.response.data ? error.response.data.error_message : '';
            addMessage({
                message: (
                    <>
                        <Typography>{errorMessage}</Typography>
                    </>
                ),
                type: NotificationType.ERROR,
            });
        } else if (error.code === 'ERR_NETWORK') {
            addMessage({
                message: 'Lost connection to server',
                type: NotificationType.ERROR,
            });
        } else {
            addMessage({
                message: (
                    <>
                        <Typography>Request failed: </Typography>
                        <Typography>{error.response.statusText}</Typography>
                    </>
                ),
                type: NotificationType.ERROR,
            });
        }
    } else if (error.request) {
        // client never received a response, or request never left
        addMessage({
            message: 'Network error',
            type: NotificationType.ERROR,
        });
    } else {
        // anything else
        addMessage({
            message: 'Request code not be sent',
            type: NotificationType.ERROR,
        });
    }
}

export type AxiosDriverFlaskInstance = AxiosInstance;

export function setAxiosInterceptors(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    addMessage: (message: Message) => void,
    terminateLoginSession: () => void
) {
    axiosDriverFlask.interceptors.request.use(async (config) => {
        if (config.url.includes(AuthApi.TOKEN_URL)) {
            return config;
        }

        await AuthApi.refreshTokenIfNeededAndSetPromise(axiosDriverFlask, terminateLoginSession);

        const session = getSession();
        config.headers.Authorization = config.headers.Authorization || `Bearer ${session?.oauth.accessToken}`;
        config.withCredentials = config.withCredentials !== false; // send true unless was intentionally set to false
        config.baseURL = getHubUrl();
        return config;
    });

    axiosDriverFlask.interceptors.response.use(null, (error) => {
        if (axios.isCancel(error)) {
            // If the request was canceled, do nothing.
        } else if (
            ((error?.response?.status === 401 || error?.response?.status === 403) &&
                !checkLoggedInButNoAccessToOrg()) ||
            (error?.response?.status === 400 && error?.config?.url?.includes(AuthApi.TOKEN_URL))
        ) {
            clearAuthSession();
            terminateLoginSession();
        } else {
            showErrorSnackBarForAxiosError(error, addMessage);
        }
        return Promise.reject(error);
    });
}
