import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { setOpenWorkSpace } from 'App/Redux/features/plot/openWorkspaceSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { AnisotropyEstimationType } from 'App/util/ProjectDataTypes/AnisotropyEstimation';
import { AnisotropyGlobalType } from 'App/util/ProjectDataTypes/AnisotropyGlobal';
import { AnisotropyGridType } from 'App/util/ProjectDataTypes/AnisotropyGrid';
import { OBJECT_CLASS_NAMES, ProjectObject } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { MeshFileType } from 'App/util/ProjectDataTypes/MeshFile';
import { SourceFileType } from 'App/util/ProjectDataTypes/SourceFile';
import { createPlotRowId } from 'App/MainApp/Plot/downloadPlotServices/commonPlotDownloadService';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { createXyzTraceObjectFromSnapshot } from 'Common/Xyz/XyzTraces/utils/AnyXyzTrace';
import { PointEstimationType } from 'App/util/ProjectDataTypes/PointEstimation';
import { ZoneType } from 'App/util/ProjectDataTypes/Zone';
import { OverlapType } from 'App/util/ProjectDataTypes/Overlap';
import { plotAnisotropyEstimationOrGridObject } from './plotAnisotropyEstimationOrGridObject';
import { plotAnisotropyGlobalObject } from './plotAnisotropyGlobalObject';
import { plotDrillObject } from './plotDrillObject';
import { plotMeshObject } from './plotMeshObject';
import { plotBlockModelObject } from './plotInterpolation';
import { plotZoneObject } from './plotZoneObject';
import { plotOverlapObject } from './plotOverlapObject';
import { DomainType } from 'App/util/ProjectDataTypes/Domain';
import { plotDomainSamples } from './plotDomainSamples';
import { DomainGridDefinitionType } from 'App/util/ProjectDataTypes/DomainGridDefinition';
import { plotDomainGridDefinitionBlockModel } from './plotDomainGridDefinitionBlockModel';
import { AdditionalActionParams } from 'App/util/projectObjectsUtils.ts/objectUtils';
import { InValidateAllColorValueSummaries } from 'Common/Xyz/XyzTraces/XyzTrace';
import { ZoneFromAnisotropyType } from 'App/util/ProjectDataTypes/ZoneFromAnisotropy';

export function plotJobObject(
    projectObject: ProjectObject,
    xyz: UseXyz,
    currentOrgUuid: string,
    additionalParams: AdditionalActionParams,
    anisotropyClustering: boolean,
    tokenProvider: () => Promise<string>
) {
    return async function plotJobObjectThunk(dispatch: AppDispatch) {
        dispatch(setOpenWorkSpace('open'));
        const xyzTraceId = createPlotRowId(projectObject);
        switch (projectObject.object_class_name) {
            case OBJECT_CLASS_NAMES.SourceFile:
                await dispatch(
                    plotDrillObject(
                        projectObject as SourceFileType,
                        xyzTraceId,
                        xyz,
                        currentOrgUuid,
                        additionalParams?.drillingAttribute,
                        tokenProvider
                    )
                );
                break;

            case OBJECT_CLASS_NAMES.Domain:
                await dispatch(
                    plotDomainSamples(
                        projectObject as DomainType,
                        xyzTraceId,
                        xyz,
                        currentOrgUuid,
                        additionalParams?.drillingAttribute,
                        tokenProvider
                    )
                );
                break;
            case OBJECT_CLASS_NAMES.Domain_GridDefinition:
                await dispatch(
                    plotDomainGridDefinitionBlockModel(
                        projectObject as DomainGridDefinitionType,
                        xyzTraceId,
                        xyz,
                        currentOrgUuid,
                        tokenProvider
                    )
                );
                break;
            case OBJECT_CLASS_NAMES.MeshFile:
                await dispatch(plotMeshObject(projectObject as MeshFileType, xyzTraceId, xyz, currentOrgUuid));
                break;
            case OBJECT_CLASS_NAMES.AnisotropyEstimation:
            case OBJECT_CLASS_NAMES.AnisotropyGrid:
                await dispatch(
                    plotAnisotropyEstimationOrGridObject(
                        projectObject as AnisotropyEstimationType | AnisotropyGridType,
                        xyzTraceId,
                        xyz,
                        currentOrgUuid,
                        anisotropyClustering,
                        tokenProvider
                    )
                );
                break;
            case OBJECT_CLASS_NAMES.AnisotropyGlobal:
                await dispatch(
                    plotAnisotropyGlobalObject(
                        projectObject as AnisotropyGlobalType,
                        xyzTraceId,
                        xyz,
                        currentOrgUuid,
                        tokenProvider
                    )
                );
                break;
            case OBJECT_CLASS_NAMES.PointEstimation:
                await dispatch(
                    plotBlockModelObject(
                        projectObject as PointEstimationType,
                        xyzTraceId,
                        xyz,
                        currentOrgUuid,
                        tokenProvider
                    )
                );
                break;
            case OBJECT_CLASS_NAMES.Zone:
                await dispatch(plotZoneObject(projectObject as ZoneType, xyzTraceId, xyz, currentOrgUuid));
                break;
            case OBJECT_CLASS_NAMES.ZoneFromAnisotropy:
                await dispatch(
                    plotZoneObject(projectObject as ZoneFromAnisotropyType, xyzTraceId, xyz, currentOrgUuid)
                );
                break;
            case OBJECT_CLASS_NAMES.Overlap:
                await dispatch(plotOverlapObject(projectObject as OverlapType, xyzTraceId, xyz, currentOrgUuid));
                break;
        }
    };
}

export function redownloadXyzUrlsForJob(job: ProjectObject, xyz: UseXyz, tokenProvider: () => Promise<string>) {
    return async function redownloadXyzUrlsForJobThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceId = createPlotRowId(job);
        const xyzTraceSnapshot = selectXyzTrace(state, xyzTraceId);
        if (xyzTraceSnapshot) {
            const xyzTrace = createXyzTraceObjectFromSnapshot(xyz, xyzTraceSnapshot, tokenProvider);
            await dispatch(
                xyzTrace.updateObjectHash(job.finish_time, xyzTrace.isEnabled(), InValidateAllColorValueSummaries.Yes)
            );
        }
    };
}
