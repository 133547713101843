import { ProjectTreeObjectNode, ProjectTreeSource } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import React from 'react';

type TreeContextType = {
    onExpandChange: (nodeIds: ObjectIDType[], isExpanded: boolean, treeSource: ProjectTreeSource) => void;
    isExpanded: (nodeId: ObjectIDType, treeSource: ProjectTreeSource) => boolean;
    selectNode: (node: ProjectTreeObjectNode) => void;
    selectOnlyNode: (node: ProjectTreeObjectNode) => void;
    unselectNode: (node: ProjectTreeObjectNode) => void;
    unselectAll: () => void;
    isSelectedNode: (nodeId: ObjectIDType) => boolean;
    selectedNodes: ProjectTreeObjectNode[];
    toggleNodeSelect: (node: ProjectTreeObjectNode) => void;
    selectConsecutiveNodes: (node: ProjectTreeObjectNode) => void;
    searchKeywords?: string[];
};

export const TreeContext = React.createContext<TreeContextType>(null);
