import { TreeNodeSelectorPathType } from 'Common/types/MainTreePathType';
import { SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { SourceFileType } from 'App/util/ProjectDataTypes/SourceFile';
import { BackendJsonifiedProjectObject } from 'App/util/ProjectDataTypes/BackendJsonifiedProjectObject';
import { ProjectTreeSectionNode } from '../util/ProjectDataTypes/MainTreeDataTypes';
import { Id2Object, ObjectStatusTypes } from '../util/ProjectDataTypes/ProjectObjectsDataTypes';

export const drillingSectionSelector: TreeNodeSelectorPathType = [['section', 'Drilling']];

export const drillingSubsectionsSelector: TreeNodeSelectorPathType = [...drillingSectionSelector, 'subsections'];

export const drillingDataSelector: TreeNodeSelectorPathType = [...drillingSectionSelector, 'subsections'];

export const meshesSubsectionsSelector: TreeNodeSelectorPathType = [['section', 'Meshes'], 'subsections'];

export const closedMeshesSelector: TreeNodeSelectorPathType = [
    ...meshesSubsectionsSelector,
    ['section', SectionNames.ClosedMeshes],
    'subsections',
];

export const openMeshesSelector: TreeNodeSelectorPathType = [
    ...meshesSubsectionsSelector,
    ['section', SectionNames.OpenMeshes],
    'subsections',
];

export const anisotropySectionSelector: TreeNodeSelectorPathType = [['section', 'Anisotropy']];

export const anisotropyDomainsSelector: TreeNodeSelectorPathType = [...anisotropySectionSelector, 'subsections'];

export const interpolationsSectionSelector: TreeNodeSelectorPathType = [['section', 'Interpolations']];

export const interpolationsGridsSelector: TreeNodeSelectorPathType = [...interpolationsSectionSelector, 'subsections'];

export const zonesGridsSelector: TreeNodeSelectorPathType = [['section', 'Zones'], 'subsections'];

export const overlapsSelector: TreeNodeSelectorPathType = [['section', 'Overlaps'], 'subsections'];

export function getDrillingElementsFromDrillingData(drillingData: SourceFileType): string[] {
    return drillingData?.meta_data?.dataAttributes || [];
}

type ObjectFilterPredicate<ObjectType> = (value: [id: string | number, object: ObjectType]) => boolean;

export function filterObjects<ObjectType>(
    objectsMap: Id2Object<ObjectType>,
    predicate: ObjectFilterPredicate<ObjectType>
): Id2Object<ObjectType> {
    return Object.fromEntries(Object.entries(objectsMap ?? {}).filter(predicate));
}

/* eslint-disable-next-line */
export function isSuccessfulObject([id, object]: [number, BackendJsonifiedProjectObject]) {
    return object.status === ObjectStatusTypes.SUCCESS;
}

export function getSamplesSection(
    domainsSection: ProjectTreeSectionNode,
    domainId: number | string
): ProjectTreeSectionNode {
    const domainNode = domainsSection.subsections[domainId];
    const domainSamplesNode = domainNode.subsections[`$Domain:${domainId}`];
    return domainSamplesNode;
}

export function getAttributeNode(domainSamplesSection: ProjectTreeSectionNode, attributeName: string) {
    return domainSamplesSection.subsections[attributeName];
}
