import Typography from '@mui/material/Typography';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import {
    applyWebsocketModificationsToCurrentProject,
    selectCurrentProjectId,
    selectObjectClassname2Id2Obj,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { selectSceneObjectFocus, setSceneObjectFocus } from 'App/Redux/features/spotLight/sceneObjectFocusSlice';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import { BaseProjectObject } from 'App/util/ProjectDataTypes/BackendJsonifiedProjectObject';
import { ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { Dependants } from 'App/util/ProjectDataTypes/utilClasses/Dependants';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import { dependantsToDeleteDataCy, objectsToDeleteDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useSessionContext } from 'App/context/SessionContext';
import React from 'react';
import { tss } from 'tss-react/mui';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create(() => ({
    dependenciesMessage: {
        marginTop: '10px',
    },
    message3: {
        marginTop: '10px',
    },
}));

function getConfirmationString(objects: BaseProjectObject[]) {
    const baseString = 'Are you sure you want to delete ';
    return `${baseString}${objects.length === 1 ? 'this object' : 'these objects'}:`;
}

function getDependantsConfirmationString(objects: BaseProjectObject[], dependants: Dependants) {
    const singleObject = objects.length === 1;
    const singleDependant = dependants.list().length === 1;

    const firstPart = `and ${singleDependant ? '' : 'all '}${singleObject ? 'its' : 'their'} `;
    const secondPart = `dependent ${singleDependant ? 'object' : 'objects'}: `;

    return `${firstPart}${secondPart}`;
}

export default function DeletionConfirmation(props: { nodes: ProjectTreeObjectNode[]; handleClose: () => void }) {
    const { classes } = useStyles();

    const [isDeleting, setIsDeleting] = React.useState(false);

    const allObjects = useAppSelector(selectObjectClassname2Id2Obj);
    const xyz = useXyz();

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const currentProjectId = useAppSelector(selectCurrentProjectId);

    const spotlightObject = useAppSelector(selectSceneObjectFocus);
    const dispatch = useAppDispatch();

    const objectInstances = React.useMemo(() => {
        return props.nodes
            .map((node) => {
                const object = allObjects[node.className][node.id];
                return wrapObjectInClass(object, axiosDriverFlask, allObjects);
            })
            .filter((object) => !object.isInactive());
    }, [allObjects, props.nodes]);

    const dependants = React.useMemo(() => {
        const dependants = new Dependants();

        objectInstances.forEach((objectInstance) => {
            dependants.addDependants(objectInstance.getDependants());
        });

        return dependants;
    }, [objectInstances]);

    const haveDependants = dependants.list().length > 0;

    const objectNamesString = `${objectInstances.map((objectInstance) => objectInstance.getName()).join(', ')}${
        haveDependants ? '' : '?'
    }`;
    const dependantsString = `${dependants
        .list()
        .map((objectInstance) => objectInstance.getName())
        .join(', ')}?`;

    const submitText = `Yes, delete${[...objectInstances, ...dependants.list()].length > 1 ? ' all' : ''}`;

    const onSubmit = async () => {
        setIsDeleting(true);

        const allInstances = [...objectInstances, ...dependants.list()];

        await Promise.all(
            allInstances.map((object) => {
                return object.delete().then((projectUpdateJson) => {
                    dispatch(
                        applyWebsocketModificationsToCurrentProject(
                            xyz,
                            axiosDriverFlask,
                            projectUpdateJson.data,
                            currentProjectId,
                            'Delete object',
                            tokenProvider
                        )
                    );
                });
            })
        )
            .then(() => {
                props.handleClose();

                const objectInSpotlight = allInstances.find((instance) => instance.id === spotlightObject?.id);

                if (objectInSpotlight) {
                    dispatch(setSceneObjectFocus(null));
                }
            })
            .finally(() => {
                setIsDeleting(false);
            });
    };

    return (
        <GenericDialogShell
            title="Delete Objects"
            dataCy="delete-confirmation-dialog"
            maxWidth="md"
            handleClose={props.handleClose}
        >
            <Typography>{getConfirmationString(objectInstances)}</Typography>
            <Typography data-cy={objectsToDeleteDataCy}>{objectNamesString}</Typography>
            {haveDependants && (
                <>
                    <Typography className={classes.dependenciesMessage}>
                        {getDependantsConfirmationString(objectInstances, dependants)}
                    </Typography>
                    <Typography data-cy={dependantsToDeleteDataCy}>{dependantsString}</Typography>
                </>
            )}
            <Typography className={classes.message3}>This cannot be undone.</Typography>
            <GenericDialogActions
                onSubmit={onSubmit}
                onCancel={props.handleClose}
                submitText={submitText}
                disabled={isDeleting}
            />
        </GenericDialogShell>
    );
}
