import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { overlapIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function OverlapIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={overlapIconDataCy}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <path
                    fill="currentColor"
                    d="M23.26,11.94L17.9,8.88l0.63-2.32C18.59,6.34,18.5,6.12,18.3,6L7.91,0.06C7.69-0.06,7.42,0,7.27,0.2L0.59,9.11
	c-0.1,0.13-0.12,0.31-0.07,0.46l2.97,8.17c0.07,0.2,0.26,0.32,0.46,0.32c0.04,0,0.08,0,0.12-0.01l2.14-0.53l2.24,6.17
	C8.52,23.87,8.71,24,8.91,24c0.04,0,0.08,0,0.12-0.01l11.88-2.97c0.17-0.04,0.31-0.18,0.35-0.35l2.23-8.17
	C23.55,12.28,23.46,12.06,23.26,11.94z M5.48,15.51l0.39,1.07l-1.61,0.4l-2.73-7.5L7.8,1.13l9.69,5.54l-0.47,1.71L12.87,6
	c-0.21-0.12-0.49-0.07-0.64,0.13l-6.68,8.91C5.45,15.18,5.42,15.35,5.48,15.51z M20.39,20.13L9.22,22.93l-2.06-5.66l8.79-2.2
	c0.17-0.04,0.31-0.18,0.35-0.35l1.33-4.87l4.81,2.75L20.39,20.13z"
                />
            </svg>
        </SvgIcon>
    );
}

export default OverlapIcon;
