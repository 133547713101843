import { Workspace, WorkspaceRole } from 'Common/api/WorkspaceApi';

const permissionDict = {
    [WorkspaceRole.OWNER]: [WorkspaceRole.OWNER],
    [WorkspaceRole.EDITOR]: [WorkspaceRole.OWNER, WorkspaceRole.EDITOR],
    [WorkspaceRole.VIEWER]: [WorkspaceRole.OWNER, WorkspaceRole.EDITOR, WorkspaceRole.VIEWER],
};

export function hasEditorPermissions(workspace: Workspace) {
    const allowedRoles = permissionDict[WorkspaceRole.EDITOR];

    if (allowedRoles.includes(workspace?.current_user_role)) {
        return true;
    }

    return false;
}
