import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { closedMeshIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
        fill: '#9CB7C7',
    },
}));

export function ClosedMeshIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={closedMeshIconDataCy}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <polygon points="0.5,12.5 4.5,23.5 20.5,19.5 23.5,8.5 9.5,0.5 " />
                <path
                    fill="currentColor"
                    d="M24,8.54c0-0.04,0-0.08,0-0.12c0-0.01,0-0.02,0-0.03c0-0.01-0.01-0.02-0.01-0.03
	c-0.01-0.04-0.03-0.07-0.05-0.11c-0.01-0.02-0.02-0.04-0.03-0.05c-0.03-0.03-0.06-0.06-0.09-0.08c-0.02-0.01-0.03-0.03-0.04-0.04
	c0,0-0.01-0.01-0.01-0.01l-14.01-8C9.73,0.05,9.72,0.05,9.7,0.04C9.67,0.03,9.65,0.02,9.62,0.01C9.58,0.01,9.55,0,9.52,0
	C9.49,0,9.46,0,9.44,0c-0.04,0-0.07,0.02-0.1,0.03C9.31,0.04,9.29,0.04,9.28,0.05c-0.01,0-0.01,0.01-0.02,0.01
	C9.23,0.08,9.2,0.1,9.17,0.13C9.15,0.15,9.13,0.16,9.11,0.18c0,0-0.01,0.01-0.01,0.01l-9,12c0,0,0,0,0,0.01
	c-0.04,0.06-0.07,0.13-0.08,0.2c0,0.02,0,0.05,0,0.07c0,0.03-0.01,0.06-0.01,0.1c0,0.02,0.02,0.04,0.02,0.06c0,0.01,0,0.02,0,0.04
	l4,11c0,0.01,0.01,0.01,0.02,0.02c0.03,0.08,0.08,0.15,0.15,0.2c0,0,0.01,0,0.01,0C4.29,23.96,4.39,24,4.5,24
	c0.04,0,0.08,0,0.12-0.02l16.01-4c0.03-0.01,0.05-0.03,0.08-0.05c0.02-0.01,0.05-0.01,0.07-0.02c0.02-0.01,0.03-0.03,0.04-0.04
	c0.02-0.02,0.03-0.03,0.05-0.05c0.04-0.05,0.07-0.1,0.09-0.16c0-0.01,0.02-0.02,0.02-0.03l3-11c0-0.01,0-0.02,0-0.02
	C24,8.58,24,8.56,24,8.54z M20.31,18.31l-4.99-7.48l7.48-1.66L20.31,18.31z M1.18,12.9l12.18-1.74l-8.7,11.31L1.18,12.9z M9.41,1.44
	l4.33,8.66L1.62,11.83L9.41,1.44z M14.78,9.92l-4.09-8.18l11.45,6.54L14.78,9.92z M14.47,11.35l5.22,7.83L5.77,22.67L14.47,11.35z"
                />
            </svg>
        </SvgIcon>
    );
}

export default ClosedMeshIcon;
