import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { DataContext } from 'App/DataContext';
import { anisotropyGlobalSuffixPrefix } from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyConsts';
import { runAnisotropyGlobal } from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyGlobalService';

import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import {
    selectCurrentProjectId,
    applyWebsocketModificationsToCurrentProject,
    selectActiveDomains,
    selectActiveAnisotropyEstimations,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector, useAppDispatch } from 'App/Redux/hooks';
import { ProjectTreeNode, ProjectTreeSource, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { OBJECT_CLASS_NAMES } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import { NameSuffixField } from 'Common/components/NameSuffixField/NameSuffixField';
import { useNameSuffix } from 'Common/components/NameSuffixField/useNameSuffix';
import { anisotropiesTreeDataCy, anisotropyGlobalDialogDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import { tss } from 'tss-react/mui';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { useSelectableTree } from 'App/MainApp/TreeView/components/checkboxTree/useSelectableTree';
import { getNodePath } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { expandAnisotropyNodes } from '../Shared/dialogUtils';
import { useSessionContext } from 'App/context/SessionContext';
import ProjectTree from 'App/MainApp/TreeView/components/ProjectTree/ProjectTree';
import { getFirstLevelNodeIds } from 'App/MainApp/TreeView/treeUtils';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create({
    tree: {
        height: 240,
        flexGrow: 1,
        overflowY: 'auto',
        marginBottom: 0,
    },
    formField: {
        display: 'grid',
        gridTemplateColumns: '45% 45%',
        columnGap: '10px',
        '&:last-child': {
            marginBottom: '20px',
        },
    },
});

export function CreateAnisotropyGlobal(props: { handleClose: () => void }) {
    const { classes } = useStyles();
    const xyz = useXyz();
    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const dispatch = useAppDispatch();

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const anisotropies = useAppSelector(selectActiveAnisotropyEstimations);
    const domains = useAppSelector(selectActiveDomains);

    const anisotropySubsections = React.useMemo(() => {
        const anisotropyLabel = 'Anisotropy';

        const anisotropyNode: ProjectTreeNode = {
            section: anisotropyLabel,
            dataCy: anisotropyLabel,
            nodeId: SectionIds[SectionNames.Anisotropy],
            path: [],
        };

        const subsections: Subsections = {};

        Object.values(anisotropies).forEach((anisotropy) => {
            // add domain node if needed
            if (!subsections[anisotropy.domainId]) {
                const { name } = domains[anisotropy.domainId];

                subsections[anisotropy.domainId] = {
                    section: name,
                    id: anisotropy.domainId,
                    nodeId: anisotropy.domainId,
                    className: OBJECT_CLASS_NAMES.Domain,
                    subsections: {},
                    dataCy: name,
                    path: getNodePath(anisotropyNode),
                };
            }

            subsections[anisotropy.domainId].subsections[anisotropy.id] = {
                id: anisotropy.id,
                nodeId: anisotropy.id,
                className: anisotropy.object_class_name,
                section: anisotropy.name,
                dataCy: anisotropy.name,
                path: getNodePath(subsections[anisotropy.domainId]),
            };
        });

        anisotropyNode.subsections = subsections;

        return {
            [anisotropyLabel]: anisotropyNode,
        };
    }, [anisotropies]);

    const { onChange, nodeSelectionChecker, selectedLeafNodes } = useSelectableTree(anisotropySubsections, null, null);

    const numOfSelectedAnisotropies = selectedLeafNodes.length;

    const { nameSuffix, nameSuffixValidationResult, onNameSuffixChange, onNameSuffixBlur } = useNameSuffix();

    const isValidNumOfItemsSelected = numOfSelectedAnisotropies > 0;

    const disableSubmit = isSubmitting || !nameSuffixValidationResult.isValid || !isValidNumOfItemsSelected;

    const { onExpandChange } = useExpandedNodes();

    const getUniqueDomainIds = () => {
        const domainIds = selectedLeafNodes
            .map((selectedLeafNode) => {
                return Object.values(anisotropies).find((anisotropy) => anisotropy.id === selectedLeafNode.id).domainId;
            })
            .filter((item, index, self) => {
                return self.indexOf(item) === index;
            });

        return domainIds;
    };

    const onSubmit = () => {
        if (!isValidNumOfItemsSelected || !nameSuffixValidationResult.isValid) {
            return;
        }

        const uniqueDomainIds = getUniqueDomainIds();

        void runAnisotropyGlobal(
            {
                anisotropyIds: selectedLeafNodes.map((anisotropy) => anisotropy.id),
                nameSuffix,
            },
            axiosDriverFlask,
            currentProjectId,
            currentOrgUuid,
            currentWorkspaceId
        )
            .then((projectUpdateJson) => {
                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'submit anisotropy grid',
                        tokenProvider
                    )
                );
                expandAnisotropyNodes(onExpandChange, uniqueDomainIds);
                props.handleClose();
            })
            .finally(() => {
                setIsSubmitting(false);
            });

        setIsSubmitting(true);
    };

    React.useEffect(() => {
        const anisotropyTreeNodeIds = getFirstLevelNodeIds(anisotropySubsections);
        onExpandChange(anisotropyTreeNodeIds, true, ProjectTreeSource.ANISOTROPY_GLOBAL, true);
    }, []);

    return (
        <GenericDialogShell
            title="New Global Anisotropy"
            dataCy={anisotropyGlobalDialogDataCy}
            maxWidth="sm"
            handleClose={props.handleClose}
        >
            <DialogContent>
                <Grid container>
                    <ProjectTree
                        checkboxTree
                        tree={anisotropySubsections}
                        dataCy={anisotropiesTreeDataCy}
                        onChange={onChange}
                        className={classes.tree}
                        nodeSelectionChecker={nodeSelectionChecker}
                        treeSource={ProjectTreeSource.ANISOTROPY_GLOBAL}
                    />
                    <NameSuffixField
                        className={classes.formField}
                        prefix={anisotropyGlobalSuffixPrefix}
                        value={nameSuffix}
                        onChange={onNameSuffixChange}
                        validationResult={nameSuffixValidationResult}
                        onBlur={onNameSuffixBlur}
                    />
                </Grid>
            </DialogContent>
            <GenericDialogActions
                onSubmit={onSubmit}
                onCancel={props.handleClose}
                showSpinner={isSubmitting}
                disabled={disableSubmit}
            />
        </GenericDialogShell>
    );
}
