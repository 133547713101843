import { Point3D } from 'Common/types/geometryTypes';

export function getBoundingBoxVerticesSegments(minCorner: Point3D, maxCorner: Point3D) {
    const vertices = [
        [1, 1, 1],
        [1, 1, 0],
        [1, 0, 1],
        [1, 0, 0],
        [0, 1, 0],
        [0, 1, 1],
        [0, 0, 0],
        [0, 0, 1],
    ];

    const segments = [
        [0, 1],
        [0, 2],
        [0, 5],
        [1, 3],
        [1, 4],
        [2, 7],
        [2, 3],
        [3, 6],
        [4, 6],
        [4, 5],
        [5, 7],
        [6, 7],
    ];

    const size = maxCorner.map((vMax, i) => vMax - minCorner[i]);

    const retVertices = vertices.map((v) => [
        v[0] * size[0] + minCorner[0],
        v[1] * size[1] + minCorner[1],
        v[2] * size[2] + minCorner[2],
    ]);

    return {
        vertices: new Float64Array(retVertices.flat()),
        segments: new Float64Array(segments.flat()),
    };
}
