import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import {
    selectActiveAnisotropyEstimations,
    selectActiveAnisotropyGlobals,
    selectActiveAnisotropyGrids,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { ZonesFromAnisotropySectionMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { zonesFromAnisotropyDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import CreateZonesFromAnisotropy from 'App/MainApp/Dialogs/ZonesFromAnisotropy/CreateZonesFromAnisotropy';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function CreateZonesFromAnisotropyButton(props: GeneralMenuItemProps) {
    const activeAnisotropyEstimations = useAppSelector(selectActiveAnisotropyEstimations);
    const activeAnisotropyGrids = useAppSelector(selectActiveAnisotropyGrids);
    const activeAnisotropyGlobals = useAppSelector(selectActiveAnisotropyGlobals);

    const disabled =
        !Object.values(activeAnisotropyEstimations).length &&
        !Object.values(activeAnisotropyGrids).length &&
        !Object.values(activeAnisotropyGlobals).length;
    const tooltipText = disabled && 'Requires at least one anisotropy object';

    const zonesFromAnisotropy = useFlags()?.zonesFromAnisotropy;

    if (!zonesFromAnisotropy) {
        return null;
    }

    return (
        <DialogOpenerMenuItem
            name={ZonesFromAnisotropySectionMenuItems.ZoneFromAnisotropy}
            key={ZonesFromAnisotropySectionMenuItems.ZoneFromAnisotropy}
            dataCy={zonesFromAnisotropyDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            tooltipText={tooltipText}
            dialogComponent={CreateZonesFromAnisotropy}
        />
    );
}
