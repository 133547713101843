import { selectXyzTraces, setXyzTraces } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { produce } from 'immer';

export abstract class XyzReduxSnapshot {
    protected snapshotPath? = (): string[] => {
        throw `Classes extending HasReduxSnapshotInXyzTraces must define the snapshotPath() function. It seems like the ${this.constructor.name} class has not defined the HasReduxSnapshotInXyzTraces function.`;
    };

    /**
     * Use this function to update the Redux snapshot of a XyzTrace when you don't need the updated redux state.
     * @param producer
     * @returns
     */
    protected updateReduxState? = <T extends XyzReduxSnapshot = this>(producer: (draft: T) => T) => {
        const snapshotPath = this.snapshotPath();
        return function updateReduxStateThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
            const state = getState();
            const xyzTraces = selectXyzTraces(state);
            const newXyzTraces = produce(xyzTraces, (oldXyzTraces) => {
                let oldObject: any = oldXyzTraces;
                snapshotPath.slice(0, -1).forEach((path) => {
                    oldObject = oldObject[path]; // find the object under tha path specified by snapshotPath
                });
                const lastSnapshotPath = snapshotPath[snapshotPath.length - 1];
                const oldSnapshot: T = oldObject[lastSnapshotPath];
                const newSnapshot = producer(oldSnapshot);
                oldObject[lastSnapshotPath] = newSnapshot;
            });
            dispatch(setXyzTraces(newXyzTraces));
        };
    };
}
