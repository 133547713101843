import SpotlightButton from 'App/MainApp/TreeView/components/spotlight/SpotlightButton';
import { ProjectTreeObjectNode, ProjectTreeSource } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { tss } from 'tss-react/mui';
import AddToViewButton from '../addToView/AddToViewButton';

const useStyles = tss.create(({ theme }) => ({
    button: {
        color: theme.palette.primary.main,
    },
    quickActions: {
        opacity: 0,
    },
}));

export default function QuickActions(props: {
    spotLightOpened: boolean;
    displayable: boolean;
    displayed: boolean;
    node: ProjectTreeObjectNode;
    className?: string;
    treeSource?: ProjectTreeSource;
    checkBoxNode?: boolean;
}) {
    const { classes } = useStyles();

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    let showSpotlight: boolean;

    if (props.checkBoxNode) {
        if (props.treeSource !== ProjectTreeSource.OVERLAPS) {
            showSpotlight = false;
        } else if (props.treeSource === ProjectTreeSource.OVERLAPS && props.displayable) {
            showSpotlight = true;
        }
    } else {
        showSpotlight = props.displayable;
    }

    const showAddToView = props.displayable && !props.checkBoxNode;

    return (
        <span className={props.className} onClick={onClick}>
            {showSpotlight && (
                <SpotlightButton
                    nodeData={props.node}
                    visible
                    className={classes.button}
                    forceHover={props.spotLightOpened}
                />
            )}
            {showAddToView && (
                <AddToViewButton
                    nodeData={props.node}
                    visible
                    className={classes.button}
                    forceHover={props.displayed}
                />
            )}
        </span>
    );
}
