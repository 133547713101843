/* eslint-disable no-underscore-dangle */
import { AnisotropyEstimationType } from 'App/util/ProjectDataTypes/AnisotropyEstimation';
import { AnisotropyGridType } from 'App/util/ProjectDataTypes/AnisotropyGrid';
import { AnisotropyGlobalType } from 'App/util/ProjectDataTypes/AnisotropyGlobal';
import { DomainType } from 'App/util/ProjectDataTypes/Domain';
import {
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
    ObjectIDType,
} from '../../util/ProjectDataTypes/ProjectObjectsDataTypes';

export function domainForAnisotropy(
    anisotropy: AnisotropyEstimationType | AnisotropyGridType | AnisotropyGlobalType,
    allObjects: ObjectClassName2Id2Obj
): DomainType {
    let domainId: ObjectIDType;

    if (
        anisotropy.object_class_name === OBJECT_CLASS_NAMES.AnisotropyGrid ||
        anisotropy.object_class_name === OBJECT_CLASS_NAMES.AnisotropyGlobal
    ) {
        const { anisotropyEstimationId } = anisotropy;
        const anisotropyEstimation = allObjects[OBJECT_CLASS_NAMES.AnisotropyEstimation][anisotropyEstimationId];
        domainId = anisotropyEstimation.domainId;
    } else {
        domainId = anisotropy.domainId;
    }

    return allObjects[OBJECT_CLASS_NAMES.Domain][domainId];
}
