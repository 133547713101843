import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { ProjectTreeNode, ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { OBJECT_CLASS_NAMES, ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { produce } from 'immer';

export const ZONE_SUFFIX = '_z';

export function filterInterpolationsSubTree(
    interpolationsSection: ProjectTreeNode,
    objectClassName: OBJECT_CLASS_NAMES.PointEstimation
) {
    return produce(interpolationsSection, (oldInterpolationsSection) => {
        Object.keys(oldInterpolationsSection.subsections).forEach((gridId) => {
            const gridSubtree = oldInterpolationsSection.subsections[gridId];
            Object.keys(gridSubtree.subsections).forEach((domainId) => {
                const domainSubtree = gridSubtree.subsections[domainId];
                if (domainSubtree.subsections) {
                    domainSubtree.subsections = Object.fromEntries(
                        Object.entries(domainSubtree.subsections).filter(
                            ([, interpolationTreeNode]) =>
                                (interpolationTreeNode as ProjectTreeObjectNode).className === objectClassName
                        )
                    );
                }
            });
        });
    });
}

export const getDomainGridIdsAndGridIds = (selectedLeafNodes: ProjectTreeNode[]) => {
    const gridIds = [];
    const domainGridDefinitionIds = [];

    selectedLeafNodes.map((node) => {
        const nodeId = node.nodeId;
        const gridDefinitionId = nodeId.split('_')[1];
        const domainGridDefintionId = nodeId.split('_')[2];
        gridIds.push(gridDefinitionId);
        domainGridDefinitionIds.push(domainGridDefintionId);
    });

    return { gridIds, domainGridDefinitionIds };
};

export const expandZoneNodes = (
    onExpandChange: (nodes: ObjectIDType[], expand: boolean) => void,
    gridIds: string[],
    domainGridDefinitionIds: string[]
) => {
    const zoneSectionId = SectionIds[SectionNames.Zones];
    const gridIdsToExpand = [];
    let domainIdsToExpand = [];

    gridIds.map((gridId) => {
        const gridNodeId = `${zoneSectionId}_${gridId}`;
        const domainGridDefintionNodeIdArr = domainGridDefinitionIds.map((domainId) => `${gridNodeId}_${domainId}`);
        gridIdsToExpand.push(gridNodeId);
        domainIdsToExpand = domainIdsToExpand.concat(domainGridDefintionNodeIdArr);
    });

    const nodesToExpand = [zoneSectionId, ...gridIdsToExpand, ...domainIdsToExpand];

    onExpandChange(nodesToExpand, true);
};
