import Grid from '@mui/material/Grid';
import * as React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { BASE_Z_INDEX, useZIndex } from 'App/MainApp/Visualization/hooks/useZIndex';
import { useDragTabStyles } from 'App/MainApp/Visualization/components/DragTab';
import DragTab from 'App/MainApp/Visualization/components/DragTab/DragTab';
import { useStyles } from './GenericDraggableStyles';
import { ControlPosition } from 'react-draggable';
import clsx from 'clsx';
import Divider from '@mui/material/Divider';
import ErrorBoundary from '../ErrorHandling/ErrorBoundary';
import PanelHeader from 'App/MainApp/Visualization/overlays/PanelHeader/PanelHeader';

type PropTypes = {
    zIndexContext?: boolean;
    dataCy?: string;
    children: React.ReactNode;
    defaultPosition: ControlPosition;
    headerName: string;
    divider?: boolean;
    classes?: any;
    onCloseClicked: () => void;
    onPositionChange: (x: number, y: number) => void;
    onAnimationEnd?: () => void;
};

export default function GenericDraggableShell(props: PropTypes) {
    const {
        zIndexContext,
        dataCy,
        classes,
        children,
        defaultPosition,
        divider,
        onAnimationEnd,
        onCloseClicked,
        onPositionChange,
        headerName,
    } = props;

    const { classes: genericDraggableShellClasses } = useStyles();
    const { classes: dragTabStyle } = useDragTabStyles();

    const [zIndex, elevate] = useZIndex(BASE_Z_INDEX);
    const draggableChildRef = React.useRef(null);

    React.useEffect(() => {
        elevate();
    }, []);

    const handleOnStart = React.useCallback(() => {
        elevate();
    }, [elevate]);

    const handleOnStop = React.useCallback((_e: DraggableEvent, data: DraggableData) => {
        onPositionChange(data.x, data.y);
    }, []);

    return (
        <ErrorBoundary>
            <Draggable
                nodeRef={draggableChildRef}
                handle={`#${dragTabStyle.dragTab}`}
                bounds="body"
                defaultPosition={defaultPosition}
                onStart={handleOnStart}
                onStop={handleOnStop}
            >
                <Grid
                    ref={draggableChildRef}
                    data-cy={dataCy}
                    onAnimationEnd={onAnimationEnd}
                    onClick={elevate}
                    item
                    style={!zIndexContext ? { zIndex } : {}}
                    className={clsx(classes.root, genericDraggableShellClasses.root)}
                >
                    <DragTab />
                    <Grid className={clsx(classes.container, genericDraggableShellClasses.container)}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            wrap="nowrap"
                            className={genericDraggableShellClasses.header}
                        >
                            <PanelHeader title={headerName} onClose={onCloseClicked} />
                        </Grid>
                        {divider && <Divider />}
                        {children}
                    </Grid>
                </Grid>
            </Draggable>
        </ErrorBoundary>
    );
}
