import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { uploadFileMenuIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function UploadFileMenuIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={uploadFileMenuIconDataCy}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.3298 14.671L1.66889 14.671C1.48302 14.671 1.33268 14.498 1.33268 14.2841C1.33268 14.0701 1.48302 13.8971 1.66889 13.8971L14.3298 13.8971C14.5157 13.8971 14.666 14.0701 14.666 14.2841C14.666 14.498 14.5157 14.671 14.3298 14.671ZM10.7176 5.35855C10.8689 5.20755 10.8689 4.96533 10.7176 4.81433L8.29984 2.3984C8.22734 2.32605 8.12647 2.28516 8.0256 2.28516C7.92157 2.28516 7.82386 2.32605 7.75136 2.3984L5.33049 4.81433C5.25483 4.88983 5.21701 4.98735 5.21701 5.08801C5.21701 5.18868 5.25483 5.2862 5.33049 5.36169C5.48179 5.51269 5.72451 5.51269 5.87581 5.36169L7.63788 3.60322L7.63788 11.6414C7.63788 11.8522 7.81125 12.0252 8.0256 12.0252C8.23679 12.0252 8.41016 11.8553 8.41016 11.6414L8.41016 3.60008L10.1722 5.35855C10.3235 5.50954 10.5662 5.50954 10.7176 5.35855Z"
                    fill="black"
                />
            </svg>
        </SvgIcon>
    );
}

export default UploadFileMenuIcon;
