import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { ProjectObjectIdentifier } from 'App/util/ProjectDataTypes/ProjectObjectIdentifier';
import { ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { SourceFileType } from 'App/util/ProjectDataTypes/SourceFile';
import { DrillTrace, DrillTraceSnapshot } from 'Common/Xyz/XyzTraces/DrillTrace';
import { InValidateAllColorValueSummaries, XyzTraceClassNames, XyzTraceId } from 'Common/Xyz/XyzTraces/XyzTrace';

function createInitialDrillSnapshot(
    xyzTraceId: string,
    drillingObject: SourceFileType,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    drillingAttribute?: string
): DrillTraceSnapshot {
    const { dataAttribute2info } = drillingObject.meta_data;
    const drillAttributeTraces = {} as DrillTraceSnapshot['colorAttributeTraces'];

    const projectObjectIdentifier = new ProjectObjectIdentifier({
        objectClassName: drillingObject.object_class_name,
        id: drillingObject.id,
    });
    const jobUrl = projectObjectIdentifier.getObjectUrl(currentOrgUuid, objectClassName2id2obj);

    drillAttributeTraces[drillingObject.original_file_name] = {
        colorAttributeName: drillingObject.original_file_name,
        useSingleColor: true,
        colorValueSummariesValid: false,
        colorValueSummaries: undefined,
        colorArrayUrl: undefined,
    };
    Object.keys(dataAttribute2info).forEach((drillingAttributeName) => {
        drillAttributeTraces[drillingAttributeName] = {
            colorAttributeName: drillingAttributeName,
            spotLightAttributeName: drillingAttributeName,
            useSingleColor: false,
            colorValueSummariesValid: false,
            colorValueSummaries: undefined,
            colorArrayUrl: `${jobUrl}?bytes_array_for_assay=${drillingAttributeName}`,
        };
        return drillAttributeTraces;
    });

    return {
        id: xyzTraceId,
        className: XyzTraceClassNames.DrillTrace,
        colorAttributeTraces: drillAttributeTraces,
        enabled: true,
        visible: true,
        projectObjectIdentifier: {
            objectClassName: drillingObject.object_class_name,
            id: drillingObject.id,
        },
        selectedColorAttribute: drillingAttribute ?? drillingObject.original_file_name,
        verticesUrl: `${jobUrl}?xyz_bytes_array=true`,
        objectHash: drillingObject.updated_at,
    };
}

async function createInitialXyzDrillTrace(
    xyzTraceId: string,
    dispatch: AppDispatch,
    drillingObject: SourceFileType,
    xyz: UseXyz,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    drillingAttribute?: string,
    tokenProvider?: () => Promise<string>
) {
    const initialDrillSnapshot = createInitialDrillSnapshot(
        xyzTraceId,
        drillingObject,
        objectClassName2id2obj,
        currentOrgUuid,
        drillingAttribute
    );

    const drillTrace = new DrillTrace(xyz, initialDrillSnapshot, tokenProvider);
    await dispatch(drillTrace.plotAndSave());
}

export function plotDrillObject(
    drillingObject: SourceFileType,
    xyzTraceId: XyzTraceId,
    xyz: UseXyz,
    currentOrgUuid: string,
    drillingAttribute?: string,
    tokenProvider?: () => Promise<string>
) {
    return async function plotDrillObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshot = selectXyzTrace(state, xyzTraceId);
        if (xyzTraceSnapshot) {
            const drillTrace = new DrillTrace(xyz, xyzTraceSnapshot as DrillTraceSnapshot, tokenProvider);
            const drillAttributeFinal =
                drillingAttribute ??
                Object.values(drillTrace.colorAttributeTraces).find(
                    (drillAttributeTrace) => drillAttributeTrace.useSingleColor
                ).colorAttributeName;
            const newDrillTrace = await drillTrace.updateSelectedAttribute(dispatch, drillAttributeFinal);
            if (drillTrace.isEnabled() && drillAttributeFinal === drillTrace.selectedColorAttribute) {
                await newDrillTrace.incrementObjectHash(dispatch, true, InValidateAllColorValueSummaries.No); // if the object was already enabled, we will increment the objectHash to force redownload of the data.
            }
        } else {
            const objectClassName2id2obj = selectObjectClassname2Id2Obj(state);
            await createInitialXyzDrillTrace(
                xyzTraceId,
                dispatch,
                drillingObject,
                xyz,
                objectClassName2id2obj,
                currentOrgUuid,
                drillingAttribute,
                tokenProvider
            );
        }
    };
}
