import { Draft } from 'immer';
import update, { Spec } from 'immutability-helper';
import { TreeNodeSelectorPathType, TreePathType } from 'Common/types/MainTreePathType';
import {
    MainTreeType,
    ProjectTreeNode,
    ProjectTreeObjectNode,
    ProjectTreeSectionNode,
    Subsections,
} from '../../util/ProjectDataTypes/MainTreeDataTypes';
import {
    FailedOrProcessingProjectObjectStatusTypes,
    FailedProjectObjectStatusTypes,
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
    ObjectIDType,
    ObjectStatusTypes,
    ProjectObject,
} from '../../util/ProjectDataTypes/ProjectObjectsDataTypes';

const TREE_PATH_SEPARATOR = '//';

export const objectsHavingColorAttributes = [OBJECT_CLASS_NAMES.SourceFile, OBJECT_CLASS_NAMES.Domain];

export type NodeTypeToNodeIdsMap = { [nodeType: string]: ObjectIDType[] };

export enum NodeType {
    PARENTS = 'PARENTS',
    CHILDREN = 'CHILDREN',
    SIBLINGS = 'SIBLINGS',
}

// type Spec<T, C extends CustomCommands<object> = never> = {
//     [K in keyof T]?: Spec<T[K], C> | { $set: any };
// };

/**
 * Example: mainTree is the projectTree, treePathString = "0,Setup,2,"
 *
 * if the input is input = ['1,Anisotropy,0,global', '1,Anisotropy,0,global,0,Al_PCT_global_', '1,Anisotropy,0,global,1,As_PPM_global_', '1,Anisotropy,0,global,2,Ba_PPM_global_']:
 *  then do this:
 *  nodeDatas = input.map((treePathString) => get_nodeData_from_treePathString(mainTree, treePathString))
 * or you can say:
 *  nodeData0 = get_nodeData_from_treePathString(mainTree, input[0])
 *
 * @param mainTree
 * @param treePathString
 * @returns
 */
export function get_nodeData_from_treePathString(mainTree: MainTreeType, treePathString: string): ProjectTreeNode {
    const treePath = treePathString.split(TREE_PATH_SEPARATOR);
    let nodeData: MainTreeType | ProjectTreeNode = mainTree;
    for (let i = 0; i < treePath.length - 1; i++) {
        if (i % 2 === 0) {
            const isdigit = /^\d+$/.test(treePath[i]);
            const index = isdigit ? parseInt(treePath[i]) : treePath[i];
            const currentNode = nodeData;
            nodeData = currentNode[index];
        } else {
            const currentNode = nodeData;
            nodeData = currentNode.subsections;
        }
    }
    return nodeData as ProjectTreeNode;
}

/**
 * This function mutates the object. It doesn't return anything.
 * @param sections
 * @param level. needs to be >1
 */
export function dropDescendentsAtLevelMutating(sections: Draft<Subsections>, level: number) {
    for (const k of Object.keys(sections)) {
        const section = sections[k];
        if (!section) continue;
        if (level - 1 <= 0) {
            section.subsections = {};
        } else {
            dropDescendentsAtLevelMutating(section.subsections ?? {}, level - 1);
        }
    }
}

export function findSectionAmongSubsections<Type, Key extends keyof Type>(
    sections: { [id: number | string]: Type } | Type[],
    section: string | number | object | boolean,
    key?: Key,
    returnIndex?: false
): Type;
export function findSectionAmongSubsections<Type, Key extends keyof Type>(
    sections: { [id: number | string]: Type } | Type[],
    section: string | number | object | boolean,
    key?: Key,
    returnIndex?: true
): number;

export function findSectionAmongSubsections<Type, Key extends keyof Type>(
    sections: { [id: number | string]: Type } | Type[],
    section: string | number | object | boolean,
    key: Key = 'section' as Key,
    returnIndex = false
) {
    for (const k of Object.keys(sections)) {
        if (!sections[k]) continue;
        if (sections[k][key] === section) {
            if (returnIndex) {
                return k;
            }
            return sections[k];
        }
    }
    if (returnIndex) {
        return -1;
    }
    return null;
}

export function findNodeUnderPath(
    mainNode: ProjectTreeNode | Subsections,
    path: TreeNodeSelectorPathType,
    throwError = true
) {
    let subNode = mainNode;
    try {
        for (const o of path) {
            if (o.constructor === Array && o.length === 2) {
                const key = o[0];
                const val = o[1] as string;

                const subTree = subNode as Subsections;
                subNode = findSectionAmongSubsections(subTree, val, key, false);
            } else {
                const treeNode = subNode;
                const key = o as number | string;
                subNode = treeNode[key];
            }
        }
        return subNode;
    } catch (error: unknown) {
        if (throwError === true) {
            throw error;
        } else {
            return undefined;
        }
    }
}

/**
 *
 * @param tree
 * @param treePath
 */
export function findNodeAtTreePath(tree: Subsections, treePath: TreePathType): ProjectTreeNode {
    let subTree = tree;
    for (let i = 0; i < treePath.length - 1; i++) {
        const p = treePath[i];
        subTree = subTree[p[0]].subsections;
    }
    const i = treePath.length - 1;
    const p = treePath[i];
    return subTree[p[0]];
}

export function updateTreeNode(tree: Subsections, path: TreePathType, updateCommand: Spec<ProjectTreeNode>) {
    const updatePath: Spec<Subsections> = {};
    let up: Spec<ProjectTreeNode> | Spec<Subsections> = updatePath;
    for (let i = 0; i < path.length; i++) {
        const p = path[i];
        up[p[0]] = {};
        up = up[p[0]];
        if (i !== path.length - 1) {
            // @ts-ignore
            up.subsections = {};
            // @ts-ignore
            up = up.subsections;
        }
    }

    // e.g.: up['leftCheckBoxChecked'] = {$set: e.target.checked};
    Object.keys(updateCommand).forEach((key) => (up[key] = updateCommand[key]));

    return update(tree, updatePath);
}

/**
 *
 * @param mainTree must be a list of objects that may or may not have subsections
 * @param updateCommand
 * @param nodeCheckboxKey
 * @returns
 *  - True: if all children are checked
 *  - False: if there is any child that is unchecked
 *  - undefined: there is no childs that are checked or unchecked. (it could have some children that are all all running or failed)
 */
export function allChildrenSetParentCheckedMutating(
    mainTree: Draft<Subsections>,
    nodeCheckboxKey: string,
    allObjects: ObjectClassName2Id2Obj
): boolean {
    let all_subsections_are_checked: boolean;
    if (!mainTree) {
        return undefined;
    }
    if (Object.keys(mainTree).length > 0) {
        for (const id of Object.keys(mainTree)) {
            const node = mainTree[id];
            if (!node) continue;

            let projectObject: ProjectObject = null;

            if ('id' in node) {
                projectObject = allObjects[node.className][node.id];
            }

            const all_were_checked = allChildrenSetParentCheckedMutating(node.subsections, nodeCheckboxKey, allObjects);
            switch (all_were_checked) {
                case undefined:
                    if (
                        node[nodeCheckboxKey] !== true &&
                        (!projectObject || !FailedOrProcessingProjectObjectStatusTypes.includes(projectObject.status))
                    ) {
                        all_subsections_are_checked = false;
                    }
                    if (all_subsections_are_checked === undefined) {
                        if (node[nodeCheckboxKey] === true) {
                            all_subsections_are_checked = true;
                        }
                    }
                    break;
                case true:
                    if (all_subsections_are_checked === undefined) {
                        all_subsections_are_checked = true;
                    }
                    node[nodeCheckboxKey] = true;
                    break;
                case false:
                    all_subsections_are_checked = false;
                    break;
            }
        }
    }
    return all_subsections_are_checked;
}

/**
 *
 * @param mainTree must be a list of objects that may or may not have subsections
 * @param updateCommand
 * @param nodeCheckboxKey
 * @returns
 *  - True: if all children are checked
 *  - False: if there is any child that is unchecked
 *  - undefined: there is no childs that are checked or unchecked. (it could have some children that are all all running or failed)
 */
export function allChildrenSetParentChecked(
    mainTree: Subsections,
    updateCommand: Spec<ProjectTreeNode>,
    nodeCheckboxKey: string,
    allObjects: ObjectClassName2Id2Obj
): boolean {
    let all_subsections_are_checked: boolean;
    if (!mainTree) {
        return undefined;
    }
    if (Object.keys(mainTree).length > 0) {
        for (const k of Object.keys(mainTree)) {
            const node = mainTree[k];
            if (!node) continue;

            let projectObject: ProjectObject = null;

            if ('id' in node) {
                projectObject = allObjects[node.className][node.id];
            }

            if (!updateCommand[k]) {
                updateCommand[k] = {};
            }
            if (!updateCommand[k].subsections) {
                updateCommand[k].subsections = {};
            }

            const section_updateCommand = updateCommand[k];
            const subsections_updateCommand = updateCommand[k].subsections;

            const all_were_checked = allChildrenSetParentChecked(
                node.subsections,
                subsections_updateCommand,
                nodeCheckboxKey,
                allObjects
            );
            switch (all_were_checked) {
                case undefined:
                    if (
                        node[nodeCheckboxKey] !== true &&
                        (!projectObject || !FailedOrProcessingProjectObjectStatusTypes.includes(projectObject.status))
                    ) {
                        all_subsections_are_checked = false;
                    }
                    if (all_subsections_are_checked === undefined) {
                        if (node[nodeCheckboxKey] === true) {
                            all_subsections_are_checked = true;
                        }
                    }
                    break;
                case true:
                    if (all_subsections_are_checked === undefined) {
                        all_subsections_are_checked = true;
                    }
                    section_updateCommand[nodeCheckboxKey] = { $set: true };
                    break;
                case false:
                    all_subsections_are_checked = false;
                    break;
            }
        }
    }
    return all_subsections_are_checked;
}

/**
 *
 * @param mainTree must be a list of objects that may or may not have subsections
 * @param subPath
 * @param updateCommand
 * @param nodeCheckboxKey
 * @param checked
 * @returns
 */
export function setAllAncestorsCheckedMutating(
    mainTree: Subsections,
    subPath: TreePathType,
    nodeCheckboxKey: string,
    checked: boolean
): void {
    if (!mainTree) {
        return;
    }
    let subTree = mainTree;
    for (const p of subPath) {
        subTree[p[0]][nodeCheckboxKey] = checked;

        subTree = subTree[p[0]].subsections;
    }
}

/**
 * @Depricated Please use set_all_ancestors_checked__mutable and immer instead of this function
 *
 * @param mainTree must be a list of objects that may or may not have subsections
 * @param subPath
 * @param updateCommand
 * @param nodeCheckboxKey
 * @param checked
 * @returns
 */
export function setAllAncestorsChecked(
    mainTree: Subsections,
    subPath: TreePathType,
    updateCommand: Spec<ProjectTreeNode>,
    nodeCheckboxKey: string,
    checked: boolean
): void {
    if (!mainTree) {
        return;
    }
    let up: Spec<ProjectTreeNode> | Spec<Subsections> = updateCommand;
    let subTree: Subsections | ProjectTreeNode = mainTree;
    for (let i = 0; i < subPath.length; i++) {
        const p = subPath[i];
        if (!up[p[0]]) {
            up[p[0]] = {};
        }
        up = up[p[0]];
        up[nodeCheckboxKey] = { $set: checked };
        subTree = subTree[p[0]];

        // @ts-ignore
        if (!up.subsections) {
            // @ts-ignore
            up.subsections = {};
        }
        // @ts-ignore
        up = up.subsections;
        subTree = subTree.subsections;
    }
}

/**
 *
 * @param mainTree must be a list of objects that may or may not have subsections
 * @param subPath
 * @param updateCommand
 * @param nodeCheckboxKey
 * @param checked
 * @returns
 */
export function setAllDescendantsCheckedMutating(
    mainTree: Draft<Subsections>,
    subPath: TreePathType,
    nodeCheckboxKey: string,
    checked: boolean,
    allObjects: ObjectClassName2Id2Obj
): boolean {
    if (!mainTree) {
        return;
    }
    let subTree = mainTree;
    for (let i = 0; i < subPath.length; i++) {
        const p = subPath[i];
        subTree = subTree[p[0]].subsections;
    }
    for (const i in subTree) {
        const node = subTree[i];
        if (!node) continue;

        let projectObject: ProjectObject = null;

        if ('id' in node) {
            projectObject = allObjects[node.className][node.id];
        }

        if (!projectObject || !FailedOrProcessingProjectObjectStatusTypes.includes(projectObject.status)) {
            node[nodeCheckboxKey] = checked;
        }
        setAllDescendantsCheckedMutating(node.subsections, [], nodeCheckboxKey, checked, allObjects);
    }
}

/**
 *
 * @param mainTree must be a list of objects that may or may not have subsections
 * @param subPath
 * @param updateCommand
 * @param nodeCheckboxKey
 * @param checked
 * @returns
 */
export function setAllDescendentsChecked(
    mainTree: ProjectTreeNode | Subsections,
    subPath: TreePathType,
    updateCommand: Spec<ProjectTreeNode>,
    nodeCheckboxKey: string,
    checked: boolean,
    allObjects: ObjectClassName2Id2Obj
): boolean {
    if (!mainTree) {
        return;
    }
    let up: Spec<ProjectTreeNode> | Spec<Subsections> = updateCommand;
    let subTree: ProjectTreeNode | Subsections = mainTree;
    for (let i = 0; i < subPath.length; i++) {
        const p = subPath[i];
        if (!up[p[0]]) {
            up[p[0]] = {};
        }
        up = up[p[0]];
        subTree = subTree[p[0]];

        // @ts-ignore
        if (!up.subsections) {
            // @ts-ignore
            up.subsections = {};
        }
        // @ts-ignore
        up = up.subsections;
        subTree = subTree.subsections;
    }
    for (const k in subTree) {
        const node: ProjectTreeNode = subTree[k];
        if (!node) continue;

        if (!up[k]) {
            up[k] = {};
        }
        if (!up[k].subsections) {
            up[k].subsections = {};
        }

        let projectObject: ProjectObject = null;

        if ('id' in node) {
            projectObject = allObjects[node.className][node.id];
        }

        const section_updateCommand = up[k];
        const subsections_updateCommand = up[k].subsections;
        if (!projectObject || !FailedOrProcessingProjectObjectStatusTypes.includes(projectObject.status)) {
            section_updateCommand[nodeCheckboxKey] = { $set: checked };
        }
        setAllDescendentsChecked(node.subsections, [], subsections_updateCommand, nodeCheckboxKey, checked, allObjects);
    }
}

export function copyLeavesCheckedState(
    sourceNodes: Subsections,
    newNodes: Subsections,
    allObjects: ObjectClassName2Id2Obj
) {
    if (!sourceNodes || !newNodes) {
        return;
    }

    if (Object.keys(newNodes).length === 0) {
        return;
    }

    Object.keys(newNodes).forEach((nodeId) => {
        const newNode = newNodes[nodeId];

        if (newNode) {
            const sourceNode = findSectionAmongSubsections(sourceNodes, newNode.section);

            if (sourceNode) {
                const checkedState = sourceNode.leftCheckBoxChecked;

                if (checkedState && Object.keys(newNode.subsections ?? {}).length === 0) {
                    let object;

                    if ('id' in newNode) {
                        object = allObjects[newNode.className][newNode.id];
                    }

                    if (!object || object.status === ObjectStatusTypes.SUCCESS) {
                        newNode.leftCheckBoxChecked = checkedState;
                    } else {
                        newNode.leftCheckBoxChecked = false;
                    }
                } else {
                    copyLeavesCheckedState(sourceNode.subsections, newNode.subsections, allObjects);
                }
            }
        }
    });
}

/**
 * Deep clones a tree. It doesn't deep clone the keys of the nodes. ( For example, it doesn't deep clone meta_data or result_metadata in the objects)
 */
export function deepCloneTree<T extends Subsections>(tree: T): T {
    if (!tree) {
        return undefined;
    }
    const clone = {} as T;
    for (const k in tree) {
        const node = tree[k];
        if (node) {
            clone[k] = {
                ...node,
                subsections: deepCloneTree(node.subsections),
            };
        }
    }
    return clone;
}

/**
 *
 * @param subTree must be a list of objects that may or may not have subsections
 * @param nodeCheckboxKey
 * @returns
 */
export function getCheckedLeafNodes(subTree: Subsections, nodeCheckboxKey: string) {
    const selections_list: ProjectTreeNode[] = [];
    for (const k in subTree) {
        const section = subTree[k];
        if (!section) continue;
        if (Object.keys(section.subsections ?? {}).length > 0) {
            const checked_in_section = getCheckedLeafNodes(section.subsections, nodeCheckboxKey);
            selections_list.push(...checked_in_section);
        } else if (section[nodeCheckboxKey] === true) {
            selections_list.push(section);
        }
    }
    return selections_list;
}

export function getAllChildrenNodeIds(tree: Subsections) {
    const ret = [];
    Object.keys(tree).forEach((subsectionId) => {
        const node = tree[subsectionId];
        if (!node) {
            return;
        }
        const nodeId = node.nodeId;
        ret.push(nodeId);
        if (node.subsections) {
            ret.push(...getAllChildrenNodeIds(node.subsections));
        }
    });
    return ret;
}

export function getNodeFromNodeId(tree: Subsections, nodeId: ObjectIDType): ProjectTreeNode {
    let resNode = null;

    for (const k in tree) {
        const node = tree[k];
        const existingNodeId = (node as ProjectTreeSectionNode).nodeId;
        if (existingNodeId === nodeId) {
            return node;
        }
        if (node.subsections) {
            resNode = getNodeFromNodeId(node.subsections, nodeId);
        }
        if (resNode) {
            return resNode;
        }
    }
    return resNode;
}

export function makeNodeIdFromTreePath(treePath: TreePathType) {
    return treePath.map((p) => p.join(TREE_PATH_SEPARATOR)).join(TREE_PATH_SEPARATOR);
}

export function makeTreePathForNode(parentTreePath: TreePathType, subsectionId: string, node: ProjectTreeNode) {
    return parentTreePath.concat([[subsectionId, node.section]]);
}

export function allTreeNodeIds(tree: Subsections, parentTreePath: TreePathType = []) {
    const ret = [];
    Object.keys(tree).forEach((subsectionId) => {
        const node = tree[subsectionId];
        if (!node) {
            return;
        }
        const treePath = makeTreePathForNode(parentTreePath, subsectionId, node);
        ret.push(makeNodeIdFromTreePath(treePath));
        if (node.subsections) {
            ret.push(...allTreeNodeIds(node.subsections, treePath));
        }
    });
    return ret;
}

export function getFirstLevelNodes(tree: Subsections) {
    return Object.values(tree);
}

export function getFirstLevelNodeIds(tree: Subsections) {
    return getFirstLevelNodes(tree).map((node) => node.nodeId);
}

export function getAllNonLeafTreeNodeIds(tree: Subsections) {
    let nodeIds = [];

    Object.values(tree).forEach((node) => {
        if (node.subsections) {
            nodeIds.push(node.nodeId);
            nodeIds = nodeIds.concat(getAllNonLeafTreeNodeIds(node.subsections));
        }
    });

    return nodeIds;
}

/**
 * This will recursively check if any node in the subtree has any of the search keywords.
 * @param searchKeywords
 * @param node
 * @returns
 */
export function anyoneInSubtreeHasSearchKeywords(searchKeywords: string[], node: ProjectTreeNode) {
    let nodeShouldBeAdded = true;
    if (searchKeywords && searchKeywords.length > 0 && !node.always_show_when_searching_in_MainTree) {
        const hasAnySearchKeyword =
            searchKeywords.findIndex(
                (searchKeyword) => node.section !== undefined && node.section.indexOf(searchKeyword) > -1
            ) >= 0;
        if (!hasAnySearchKeyword) {
            let subtreeHasAnySearchKeyword = false;
            Object.values(node.subsections ?? {}).some((subsection) => {
                if (anyoneInSubtreeHasSearchKeywords(searchKeywords, subsection)) {
                    subtreeHasAnySearchKeyword = true;
                    return true; // break from the some() loop
                }
            });
            if (!subtreeHasAnySearchKeyword) {
                nodeShouldBeAdded = false;
            }
        }
    }
    return nodeShouldBeAdded;
}

/**
 * This will recursively check for the nodeId in the tree.
 * @param objectId
 * @param objectClassName
 * @param node
 * @returns
 */
export function getNodeIdFromSubtree(
    objectId: ObjectIDType,
    objectClassName: OBJECT_CLASS_NAMES,
    node: ProjectTreeNode,
    colorAttribute?: string
) {
    let nodeId = null;

    const existingId = (node as ProjectTreeObjectNode).id;
    const existingClassName = (node as ProjectTreeObjectNode).className;

    if (colorAttribute && objectsHavingColorAttributes.includes(objectClassName)) {
        if (
            existingId &&
            existingClassName &&
            existingId === objectId &&
            existingClassName === objectClassName &&
            node.section === colorAttribute
        ) {
            nodeId = node.nodeId;
        }
    } else {
        if (existingId && existingId === objectId && existingClassName === objectClassName) {
            nodeId = node.nodeId;
        }
    }

    if (!nodeId) {
        let subTreeNodeId = null;
        Object.values(node.subsections ?? {}).some((subsection) => {
            subTreeNodeId = getNodeIdFromSubtree(objectId, objectClassName, subsection, colorAttribute);
            if (subTreeNodeId) {
                nodeId = subTreeNodeId;
                return true;
            }
        });
        if (!subTreeNodeId) {
            nodeId = null;
        }
    }

    return nodeId;
}
/**
 * This will recursively check if any node in the subtree has any of the search keywords.
 * @param searchKeywords
 * @param node
 * @returns
 */
export function anyoneInSubtreeIsFailed(allObjects: ObjectClassName2Id2Obj, node: ProjectTreeNode) {
    let projectObject: ProjectObject;

    if ('id' in node) {
        projectObject = allObjects[node.className][node.id];
    }

    const IsFailed = FailedProjectObjectStatusTypes.includes(projectObject?.status);

    let hasFailedDecendent = IsFailed;
    if (!IsFailed) {
        Object.values(node.subsections ?? {}).some((subsection) => {
            if (anyoneInSubtreeIsFailed(allObjects, subsection)) {
                hasFailedDecendent = true;
                return true; // break from the some() loop
            }
        });
    }
    return hasFailedDecendent;
}

export const getChildrenAndParentsNodeIdsMap = (tree: Subsections) => {
    const childrenAndParentsNodeIdsMap: { [nodeId: ObjectIDType]: NodeTypeToNodeIdsMap } = {};

    const parentNodeIdsMap = {};
    const childrenNodeIdsMap = {};
    const siblingNodeIdsMap = {};
    const nodeIdsMap = {};

    Object.values(tree).forEach((node) => {
        getAllNodesToParentNodeIdsMap(node, null, parentNodeIdsMap);
        getAllNodeToChildrenNodeIdsMap(node, childrenNodeIdsMap);
        getAllNodesToSiblingNodeIdsMap(node, null, siblingNodeIdsMap);
        getAllNodeToNodeIdsMap(node, nodeIdsMap);
        childrenAndParentsNodeIdsMap[node.nodeId] = {
            [NodeType.PARENTS]: parentNodeIdsMap[node.nodeId],
            [NodeType.CHILDREN]: childrenNodeIdsMap[node.nodeId],
            [NodeType.SIBLINGS]: siblingNodeIdsMap[node.nodeId],
        };
    });

    Object.keys(parentNodeIdsMap).forEach((nodeId) => {
        if (!childrenAndParentsNodeIdsMap[nodeId]) {
            childrenAndParentsNodeIdsMap[nodeId] = {};
        }
        childrenAndParentsNodeIdsMap[nodeId][NodeType.PARENTS] = parentNodeIdsMap[nodeId];
        childrenAndParentsNodeIdsMap[nodeId][NodeType.CHILDREN] = childrenNodeIdsMap[nodeId];
        childrenAndParentsNodeIdsMap[nodeId][NodeType.SIBLINGS] = siblingNodeIdsMap[nodeId];
    });

    return { childrenAndParentsNodeIdsMap, nodeIdsMap };
};

export function getAllNodeToChildrenNodeIdsMap(
    node: ProjectTreeNode,
    childrenNodeIdsMap: { [nodeId: ObjectIDType]: ObjectIDType[] }
) {
    const subsections = node.subsections ? node.subsections : [];

    Object.values(subsections).forEach((childNode) => {
        if (!childrenNodeIdsMap[node.nodeId]) {
            childrenNodeIdsMap[node.nodeId] = [];
        }
        childrenNodeIdsMap[node.nodeId].push(childNode.nodeId);
        childrenNodeIdsMap[node.nodeId].push(...getAllNodeToChildrenNodeIdsMap(childNode, childrenNodeIdsMap));
    });

    return childrenNodeIdsMap[node.nodeId] ? childrenNodeIdsMap[node.nodeId] : [];
}

export function getAllNodesToParentNodeIdsMap(
    node: ProjectTreeNode,
    parent: ProjectTreeNode | null,
    parentNodeIdsMap: { [nodeId: ObjectIDType]: ObjectIDType[] }
) {
    if (parent) {
        if (!parentNodeIdsMap[node.nodeId]) {
            parentNodeIdsMap[node.nodeId] = [];
        }
        if (parent.nodeId) {
            parentNodeIdsMap[node.nodeId].push(parent.nodeId);
        }
        parentNodeIdsMap[node.nodeId] = parentNodeIdsMap[node.nodeId].concat(parentNodeIdsMap[parent.nodeId]);
    }

    const subsections = node.subsections ? node.subsections : [];

    Object.values(subsections).forEach((childNode) => {
        getAllNodesToParentNodeIdsMap(childNode, node, parentNodeIdsMap);
    });
}

export function getAllNodesToSiblingNodeIdsMap(
    node: ProjectTreeNode,
    parent: ProjectTreeNode | null,
    siblingNodeIdsMap: { [nodeId: ObjectIDType]: ObjectIDType[] }
) {
    if (parent) {
        const siblings = Object.values(parent.subsections);
        siblings.forEach((sibling) => {
            if (!siblingNodeIdsMap[node.nodeId]) {
                siblingNodeIdsMap[node.nodeId] = [];
            }
            if (node.nodeId !== sibling.nodeId) {
                siblingNodeIdsMap[node.nodeId].push(sibling.nodeId);
            }
        });
    }

    const subsections = node.subsections ? node.subsections : [];

    Object.values(subsections).forEach((childNode) => {
        getAllNodesToSiblingNodeIdsMap(childNode, node, siblingNodeIdsMap);
    });
}

export const getAllNodeToNodeIdsMap = (
    node: ProjectTreeNode,
    nodeIdMap: { [nodeId: ObjectIDType]: ProjectTreeNode }
) => {
    if (node) {
        nodeIdMap[node.nodeId] = node;
        const subsections = node.subsections ? node.subsections : [];
        Object.values(subsections).forEach((childNode) => {
            getAllNodeToNodeIdsMap(childNode, nodeIdMap);
        });
    }

    return nodeIdMap;
};

export const getChildrenLengthMap = (
    node: ProjectTreeNode,
    node2ChildrenLengthMap: { [nodeId: ObjectIDType]: number }
) => {
    const subsections = node.subsections ? node.subsections : [];

    const childrenLength = Object.keys(subsections).length;

    node2ChildrenLengthMap[node.nodeId] = childrenLength;

    Object.values(subsections).forEach((childNode) => {
        getChildrenLengthMap(childNode, node2ChildrenLengthMap);
    });
};

export const recursivelyCheckAllChildren = (
    node: ProjectTreeNode,
    node2ChildrenLengthMap: { [nodeId: ObjectIDType]: number }
) => {
    const childrenLength = node2ChildrenLengthMap[node.nodeId];

    const subsections = node.subsections ? node.subsections : [];

    let isSkewedTree = true;

    Object.values(subsections).forEach((childNode) => {
        isSkewedTree = recursivelyCheckAllChildren(childNode, node2ChildrenLengthMap);
    });

    if (childrenLength > 1) {
        isSkewedTree = false;
    }

    return isSkewedTree;
};
