import React from 'react';
import {
    backendProjectUrl,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { DataContext } from '../../../../DataContext';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import DrillingSettings from 'App/MainApp/Dialogs/Uploads/UploadDrilling/DrillingSettings';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import { parseDrillingFile } from 'App/MainApp/Dialogs/Uploads/UploadDrilling/uploadDrillingService';
import { uploadDrillingDialogDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { UploadedFileData } from 'App/MainApp/Dialogs/Uploads/UploadDrilling/UploadDrillingFromEvo';
import LinearProgress from '@mui/material/LinearProgress';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useSessionContext } from 'App/context/SessionContext';

interface UploadingFileData {
    file_name: string;
}

enum steps {
    UPLOAD_FILE = 'upload-file',
    CONFIGURE_SETTINGS = 'configure-settings',
}

export default function UploadDrillingDialog(props: { handleClose: () => void; file: File }) {
    const { addMessage } = useDriverMessagesContext();
    const [step, setStep] = React.useState(steps.UPLOAD_FILE);
    const [uploadedFileData, setUploadedFileData] = React.useState<UploadedFileData>(null);
    const [uploadingFileData, setUploadingFileData] = React.useState<UploadingFileData>(null);
    const { currentOrgUuid } = React.useContext(DataContext);
    const { axiosDriverFlask } = useSessionContext();
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const unCanceledRequestsRef = React.useRef<symbol[]>([]);

    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const [progress, setProgress] = React.useState<boolean | number>(false);

    // upload file on dialog open
    React.useEffect(() => {
        setUploadingFileData({
            file_name: props.file.name,
        });

        const requestId = Symbol();
        unCanceledRequestsRef.current.push(requestId);

        const projectUrlString = backendProjectUrl(currentOrgUuid, currentWorkspaceId, currentProjectId);

        parseDrillingFile(props.file, axiosDriverFlask, projectUrlString, unCanceledRequestsRef, setProgress)
            .then((res) => {
                if (unCanceledRequestsRef.current.findIndex((item) => item === requestId) >= 0) {
                    // request was not cancelled
                    const { data } = res;
                    const { columns } = data;

                    if (columns) {
                        setUploadedFileData({
                            columns,
                            file_name: props.file.name,
                            file: props.file,
                        });

                        setUploadingFileData(null);

                        setStep(steps.CONFIGURE_SETTINGS);
                        setProgress(0);
                    } else {
                        props.handleClose();

                        addMessage({
                            message: `Error uploading file: There are no columns.`,
                            type: NotificationType.ERROR,
                        });
                    }
                }
            })
            .catch(() => {
                props.handleClose();

                addMessage({
                    message: `Error while uploading a file`,
                    type: NotificationType.ERROR,
                });
            });
    }, [props.file]);

    const onCancelUpload = () => {
        unCanceledRequestsRef.current.splice(0, 1);
        props.handleClose();
    };

    const title =
        uploadedFileData !== null
            ? 'Upload Drilling'
            : !uploadingFileData
              ? `Upload In Progress`
              : `Upload In Progress: ${uploadingFileData.file_name}`;

    return step === steps.CONFIGURE_SETTINGS ? (
        <DrillingSettings uploadedFileData={uploadedFileData} handleClose={props.handleClose} />
    ) : (
        <GenericDialogShell
            title={title}
            maxWidth="xs"
            handleClose={props.handleClose}
            onCancel={onCancelUpload}
            dataCy={uploadDrillingDialogDataCy}
            noSubmit
        >
            <LinearProgress variant={progress === false ? 'indeterminate' : 'determinate'} value={Number(progress)} />
        </GenericDialogShell>
    );
}
