import { TreeContext } from 'App/MainApp/TreeView/components/ProjectTree/TreeContext';
import TreeNode from 'App/MainApp/TreeView/components/ProjectTree/TreeNode';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { useMultiselect } from 'App/MainApp/TreeView/components/ProjectTree/useMultiselect';
import {
    selectCurrentProjectId,
    selectObjectClassname2Id2Obj,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { topParentTreeDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import { tss } from 'tss-react/mui';
import { anyoneInSubtreeHasSearchKeywords } from '../../treeUtils';
import { sortProjectTreeNodesByCreatedAt } from './ProjectTreeNode_util';
import {
    MainTreeType,
    ProjectTreeNode,
    ProjectTreeObjectNode,
    ProjectTreeSource,
} from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { getActiveObjects } from '../../treeData/treeDataUtils';
import { OBJECT_CLASS_NAMES } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { SectionIds, SectionNames } from '../../treeData/treeConsts';
import { SelectableTreeOnChange } from '../checkboxTree/useSelectableTree';

const useStyles = tss.create(() => ({
    root: {
        width: '100%',
        marginTop: '15px',
        paddingLeft: '16px',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '85%',
    },
    subtree: {
        marginBottom: '8px',
    },
}));

export type ProjectTreeProps = {
    tree: MainTreeType;
    dataCy?: string;
    className?: string;
    searchKeywords?: string[];
    checkboxTree?: boolean;
    onChange?: SelectableTreeOnChange;
    nodeSelectionChecker?: (node: ProjectTreeNode, treeSource: ProjectTreeSource) => boolean;
    treeSource?: ProjectTreeSource;
};

export default function ProjectTree(props: ProjectTreeProps) {
    const { classes } = useStyles();

    const treeDataCy = props.dataCy ? props.dataCy : topParentTreeDataCy;

    const treeClassName = props.className ? props.className : classes.root;

    const currentProjectId = useAppSelector(selectCurrentProjectId);

    const treeSource = props.treeSource ? props.treeSource : ProjectTreeSource.MAIN_TREE;

    const { onExpandChange, isExpanded, collapseAll } = useExpandedNodes();

    const allProjectObjects = useAppSelector(selectObjectClassname2Id2Obj);

    const {
        selectNode,
        unselectNode,
        unselectAll,
        isSelectedNode,
        selectedNodes,
        toggleNodeSelect,
        selectOnlyNode,
        selectConsecutiveNodes,
    } = useMultiselect(props.tree);

    const handleExpandChange = (nodeIds: ObjectIDType[], isExpanded: boolean) => {
        onExpandChange(nodeIds, isExpanded, treeSource);

        // mainly to prevent from selected nodes to be hidden in collapsed tree
        unselectAll();
    };

    const drillingObject = getActiveObjects(allProjectObjects, OBJECT_CLASS_NAMES.SourceFile)[0];

    const openDrillSection = () => {
        const drillSectionId = SectionIds[SectionNames.Drilling];
        if (!drillingObject) {
            onExpandChange([drillSectionId], true);
        }
    };

    React.useEffect(() => {
        if (treeSource === ProjectTreeSource.MAIN_TREE) {
            collapseAll();
            openDrillSection();
        }
    }, [currentProjectId]);

    return (
        <>
            {!!props.tree && (
                <div data-cy={treeDataCy} className={treeClassName}>
                    <TreeContext.Provider
                        value={{
                            onExpandChange: handleExpandChange,
                            isExpanded,
                            selectNode,
                            unselectAll,
                            unselectNode,
                            isSelectedNode,
                            selectedNodes,
                            toggleNodeSelect,
                            selectOnlyNode,
                            selectConsecutiveNodes,
                            searchKeywords: props.searchKeywords,
                        }}
                    >
                        {Object.values(props.tree)
                            .filter((subsection) => anyoneInSubtreeHasSearchKeywords(props.searchKeywords, subsection))
                            .sort((treeNode1, treeNode2) =>
                                sortProjectTreeNodesByCreatedAt(
                                    treeNode1,
                                    allProjectObjects?.[(treeNode1 as ProjectTreeObjectNode)?.className]?.[
                                        (treeNode1 as ProjectTreeObjectNode)?.id
                                    ],
                                    treeNode2,
                                    allProjectObjects?.[(treeNode2 as ProjectTreeObjectNode)?.className]?.[
                                        (treeNode2 as ProjectTreeObjectNode)?.id
                                    ]
                                )
                            )
                            .map((subsection) => {
                                return (
                                    <div key={subsection.section} className={classes.subtree}>
                                        <TreeNode
                                            key={subsection.nodeId}
                                            node={subsection}
                                            checkBoxNode={props.checkboxTree}
                                            treeSource={treeSource}
                                            nodeSelectionChecker={props.nodeSelectionChecker}
                                            onChange={props.onChange}
                                        />
                                    </div>
                                );
                            })}
                    </TreeContext.Provider>
                </div>
            )}
        </>
    );
}
