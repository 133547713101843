import { formatTo6SignificantDigits } from './core';

export const TEXTBOX_ERROR_MESSAGES = {
    cannotBeEmpty: 'This field cannot be empty',
    shouldBeInteger: 'Should be an integer',
    getShouldBeGreaterThan: (value: string | number) => `Should be > ${value}`,
    getShouldBeGreaterThanOrEqual: (value: string | number) => `Should be ≥ ${value}`,
    getShouldBeSmallerThan: (value: string | number) => `Should be < ${value}`,
    getShouldBeSmallerThanOrEqual: (value: string | number) => `Should be ≤ ${value}`,
    getShouldBeShorterThanOrEqual: (value: string | number) => `Can contain at most ${value} characters`,
    getShouldOnlyContain: (value: string) => `Can only contain ${value}`,
};

export type ValidationResult = {
    isValid: boolean;
    error: string;
};

export function getDefaultValidationResult(): ValidationResult {
    return {
        isValid: true,
        error: '',
    };
}

export function isObjectValid(someObject: { [key: string]: ValidationResult }) {
    return !Object.keys(someObject).some((key) => !someObject[key].isValid);
}

export function isInt(value: string): boolean {
    if (!value.trim()) {
        return false;
    }

    const numberValue = Number(value);
    return !isNaN(numberValue) && Number.isInteger(numberValue);
}

export function validateIsInteger(value: number, validationResult: ValidationResult) {
    if (!Number.isInteger(value)) {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.shouldBeInteger;
    }
}

export function validateNotEmpty(value: string, validationResult: ValidationResult) {
    if (!value) {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.cannotBeEmpty;
    }
}

export function validateGreaterThan(
    value: number,
    referenceValue: number,
    validationResult: ValidationResult,
    message?: string
) {
    if (value <= referenceValue) {
        validationResult.isValid = false;
        validationResult.error =
            message || TEXTBOX_ERROR_MESSAGES.getShouldBeGreaterThan(formatTo6SignificantDigits(referenceValue));
    }
}

export function validateSmallerThan(
    value: number,
    referenceValue: number,
    validationResult: ValidationResult,
    message?: string
) {
    if (value >= referenceValue) {
        validationResult.isValid = false;
        validationResult.error =
            message || TEXTBOX_ERROR_MESSAGES.getShouldBeSmallerThan(formatTo6SignificantDigits(referenceValue));
    }
}

export function validateGreaterOrEqual(
    value: number,
    referenceValue: number,
    validationResult: ValidationResult,
    message?: string
) {
    if (value < referenceValue) {
        validationResult.isValid = false;
        validationResult.error =
            message || TEXTBOX_ERROR_MESSAGES.getShouldBeGreaterThanOrEqual(formatTo6SignificantDigits(referenceValue));
    }
}

export function validateSmallerOrEqual(
    value: number,
    referenceValue: number,
    validationResult: ValidationResult,
    message?: string
) {
    if (value > referenceValue) {
        validationResult.isValid = false;
        validationResult.error =
            message || TEXTBOX_ERROR_MESSAGES.getShouldBeSmallerThanOrEqual(formatTo6SignificantDigits(referenceValue));
    }
}

export function validateStringLength(
    value: string,
    maxLength: number,
    validationResult: ValidationResult,
    message?: string
) {
    if (value.length > maxLength) {
        validationResult.isValid = false;
        validationResult.error = message || TEXTBOX_ERROR_MESSAGES.getShouldBeShorterThanOrEqual(maxLength);
    }
}

export function validateFileSystemRelatedString(value: string, validationResult: ValidationResult, message?: string) {
    const veryMaxLength = 255;

    validateStringLength(value, veryMaxLength, validationResult);

    if (!validationResult.isValid) {
        return;
    }

    const whitelistDisplayString = `letters, numbers, !, @, #, %, ^, &, (, ), +, =, -, _ and '`;
    const whitelistRegex = /^[a-zA-Z0-9!@#%^&)'(+=_-]*$/;
    const isValid = whitelistRegex.test(value);

    if (!isValid) {
        validationResult.isValid = false;
        validationResult.error = message || TEXTBOX_ERROR_MESSAGES.getShouldOnlyContain(whitelistDisplayString);
    }
}
