import { OBJECT_CLASS_NAMES, ObjectIDType } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { Dependants } from './utilClasses/Dependants';

export interface AnisotropyGridResultMetadata {
    num_centers: number;
    color_attributes: string[];
}

export interface AnisotropyGridType extends BackendJsonifiedProjectObject {
    name: string;
    sourceFileId: ObjectIDType;
    anisotropyEstimationId: ObjectIDType;
    parameters: AnisotropyGridParameters;
    result_meta_data: AnisotropyGridResultMetadata;
    object_class_name: OBJECT_CLASS_NAMES.AnisotropyGrid;
}

export interface AnisotropyGridParameters {
    name_suffix: string;
    x_spacing: number;
    y_spacing: number;
    z_spacing: number;
    keep_outside_domain: boolean;
}

export class AnisotropyGrid extends BaseProjectObject implements AnisotropyGridType {
    sourceFileId: ObjectIDType;

    anisotropyEstimationId: ObjectIDType;

    parameters: AnisotropyGridParameters;

    result_meta_data: AnisotropyGridResultMetadata;

    object_class_name: OBJECT_CLASS_NAMES.AnisotropyGrid;

    getDependants = () => {
        const pointEstimations = this.allObjects[OBJECT_CLASS_NAMES.PointEstimation];

        const dependants = new Dependants();

        Object.values(pointEstimations).forEach((pointEstimation) => {
            if (pointEstimation.anisotropyGridId === this.id) {
                dependants.addDependantAndItsDependantsRaw(pointEstimation, this.axiosDriverFlask, this.allObjects);
            }
        });

        return dependants;
    };

    getAnisotropyEstimaton = () => this.projectObjects.getAnisotropyEstimation(this.anisotropyEstimationId);

    getDomain = () => {
        const anisotropyEstimation = this.getAnisotropyEstimaton();
        return anisotropyEstimation.getDomain();
    };

    getDomainName = () => this.getDomain().getName();
}
