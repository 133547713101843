import { Anisotropy, AnisotropyEstimationType } from 'App/util/ProjectDataTypes/AnisotropyEstimation';
import { AnisotropyGrid, AnisotropyGridType } from 'App/util/ProjectDataTypes/AnisotropyGrid';
import { AnisotropyGlobal, AnisotropyGlobalType } from 'App/util/ProjectDataTypes/AnisotropyGlobal';
import { ProjectType } from './ProjectMigrations';
import { PointEstimation, PointEstimationType } from './PointEstimation';
import { Zone, ZoneType } from './Zone';
import { Drilling, SourceFileType } from './SourceFile';
import { Grid, GridDefinitionType } from './GridDefinition';
import { DomainGrid, DomainGridDefinitionType } from './DomainGridDefinition';
import { Domain, DomainType } from './Domain';
import { Mesh, MeshFileType } from './MeshFile';
import { OverlapFolder, OverlapFolderType } from './OverlapFolder';
import { OverlapType, Overlap } from './Overlap';
import { BackendJsonifiedProjectObject } from './BackendJsonifiedProjectObject';
import { ZoneFromAnisotropy, ZoneFromAnisotropyType } from './ZoneFromAnisotropy';

export enum ObjectStatusTypes {
    CREATED = 'CREATED',
    RUNNING = 'RUNNING',
    SUCCESS = 'SUCCESS',
    CANCELED = 'CANCELED',
    PARENT_CANCELED = 'PARENT_CANCELED',
    FAILED = 'FAILED',
    PARENT_FAILED = 'PARENT_FAILED',
    DISPATCHED = 'DISPATCHED',
    RESTART = 'RESTART',
    WAITING_FOR_DRILL_FILE = 'WAITING_FOR_DRILL_FILE',
    WAITING_FOR_GRID_DEFINITION = 'WAITING_FOR_GRID_DEFINITION',
    UPLOADING = 'UPLOADING',
    UPLOADED = 'UPLOADED',
    PUBLISHING = 'PUBLISHING',
    PUBLISHED = 'PUBLISHED',
}

export type ObjectStatus = ObjectStatusTypes;

export const FailedProjectObjectStatusTypes = [
    ObjectStatusTypes.FAILED,
    ObjectStatusTypes.CANCELED,
    ObjectStatusTypes.PARENT_CANCELED,
    ObjectStatusTypes.PARENT_FAILED,
];

export const ProcessingProjectObjectStatusTypes = [
    ObjectStatusTypes.CREATED,
    ObjectStatusTypes.RUNNING,
    ObjectStatusTypes.DISPATCHED,
    ObjectStatusTypes.RESTART,
    ObjectStatusTypes.UPLOADING,
    ObjectStatusTypes.PUBLISHING,
];

export const FailedOrProcessingProjectObjectStatusTypes = FailedProjectObjectStatusTypes.concat(
    ProcessingProjectObjectStatusTypes
);

export enum OBJECT_CLASS_NAMES {
    SourceFile = 'SourceFile',
    GridDefinition = 'GridDefinition',
    Domain_GridDefinition = 'Domain_GridDefinition',
    Domain = 'Domain',
    MeshFile = 'MeshFile',
    AnisotropyEstimation = 'AnisotropyEstimation',
    AnisotropyGrid = 'AnisotropyGrid',
    AnisotropyGlobal = 'AnisotropyGlobal',
    PointEstimation = 'PointEstimation',
    Zone = 'Zone',
    OverlapFolder = 'OverlapProfile',
    Overlap_Domain_GridDefinition = 'Overlap_Domain_GridDefinition',
    Overlap_Zone = 'Overlap_Zone',
    Overlap = 'Overlap',
    ZoneFromAnisotropy = 'ZoneFromAnisotropy',
    GroupProject = 'GroupProject',
    Project = 'Project',
}

export type ObjectIDType = string;

export type OBJECT_CLASS_NAME = OBJECT_CLASS_NAMES;

export enum SOURCE_FILE_KINDS {
    Drilling = 'drill',
    // need to add the rest
}

export type SourceFileKind = SOURCE_FILE_KINDS;

export interface CreationReason {
    objectUpdated: {
        description: string;
    };
}
export type Id2Object<T> = {
    [id: ObjectIDType]: T;
};

export interface Id2BackendJsonifiedObject<T extends BackendJsonifiedProjectObject = BackendJsonifiedProjectObject> {
    [id: ObjectIDType]: T;
}

export type Id2SourceFileObject = Id2BackendJsonifiedObject<SourceFileType>;
export type Id2GridObject = Id2BackendJsonifiedObject<GridDefinitionType>;
export type Id2DomainObject = Id2BackendJsonifiedObject<DomainType>;
export type Id2DomainGridObject = Id2BackendJsonifiedObject<DomainGridDefinitionType>;
export type Id2MeshObject = Id2BackendJsonifiedObject<MeshFileType>;
export type Id2AnisotropyEstimationObject = Id2BackendJsonifiedObject<AnisotropyEstimationType>;
export type Id2AnisotropyGridObject = Id2BackendJsonifiedObject<AnisotropyGridType>;
export type Id2AnisotropyGlobalObject = Id2BackendJsonifiedObject<AnisotropyGlobalType>;
export type Id2AnisotropyObject = Id2BackendJsonifiedObject<
    AnisotropyEstimationType | AnisotropyGlobalType | AnisotropyGlobalType
>;
export type Id2PointEstimationObject = Id2BackendJsonifiedObject<PointEstimationType>;
export type Id2ZoneObject = Id2BackendJsonifiedObject<ZoneType>;
export type Id2OverlapObject = Id2BackendJsonifiedObject<OverlapType>;
export type Id2OverlapFolderObject = Id2BackendJsonifiedObject<OverlapFolderType>;
export type Id2ProjectObject = Id2BackendJsonifiedObject<ProjectType>;
export type Id2ZoneFromAnisotropyObject = Id2BackendJsonifiedObject<ZoneFromAnisotropyType>;

export type ObjectClassName2Id2Obj = {
    [OBJECT_CLASS_NAMES.SourceFile]?: Id2SourceFileObject;
    [OBJECT_CLASS_NAMES.GridDefinition]?: Id2GridObject;
    [OBJECT_CLASS_NAMES.Domain_GridDefinition]?: Id2DomainGridObject;
    [OBJECT_CLASS_NAMES.Domain]?: Id2DomainObject;
    [OBJECT_CLASS_NAMES.MeshFile]?: Id2MeshObject;
    [OBJECT_CLASS_NAMES.AnisotropyEstimation]?: Id2AnisotropyEstimationObject;
    [OBJECT_CLASS_NAMES.AnisotropyGrid]?: Id2AnisotropyGridObject;
    [OBJECT_CLASS_NAMES.AnisotropyGlobal]?: Id2AnisotropyGlobalObject;
    [OBJECT_CLASS_NAMES.PointEstimation]?: Id2PointEstimationObject;
    [OBJECT_CLASS_NAMES.Zone]?: Id2ZoneObject;
    [OBJECT_CLASS_NAMES.ZoneFromAnisotropy]?: Id2ZoneFromAnisotropyObject;
    [OBJECT_CLASS_NAMES.OverlapFolder]?: Id2OverlapFolderObject;
    [OBJECT_CLASS_NAMES.Overlap]?: Id2OverlapObject;
    [OBJECT_CLASS_NAMES.Project]?: never;
};

export interface CurrentProjectType extends ProjectType {
    object_class_name2id2obj: ObjectClassName2Id2Obj;
}

export type ProjectObject =
    | SourceFileType
    | MeshFileType
    | GridDefinitionType
    | DomainType
    | DomainGridDefinitionType
    | AnisotropyEstimationType
    | AnisotropyGridType
    | AnisotropyGlobalType
    | PointEstimationType
    | ZoneType
    | ZoneFromAnisotropyType
    | OverlapFolderType
    | OverlapType;

export type AnyAnisotropyType = AnisotropyEstimationType | AnisotropyGlobalType | AnisotropyGridType;

export type AnyProjectObject =
    | Drilling
    | Mesh
    | Grid
    | Domain
    | DomainGrid
    | Anisotropy
    | AnisotropyGrid
    | AnisotropyGlobal
    | PointEstimation
    | Zone
    | ZoneFromAnisotropy
    | OverlapFolder
    | Overlap;
