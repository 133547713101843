import { ElementClass, BlockmodelViewState, UpdateSnapshot, ViewClass } from '@seequent/xyz';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { addNewXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { AppDispatch } from 'App/Redux/store';
import { objectMap } from 'Common/utils/ObjectUtils';
import { Point3D } from 'Common/types/geometryTypes';
import { hexToRgbArray } from 'App/MainApp/Plot/util';
import { getNextMeshColor } from 'App/MainApp/Plot/Colors_Util/colors_helper';
import { XyzTraceSnapshotWithColorAttributes, XyzTraceWithColorAttributes } from './XyzTraceWithColorAttributes';
import { InValidateAllColorValueSummaries, XyzTraceClassNames } from './XyzTrace';
import { DEFAULT_XYZ_OPACITY } from './utils/defaults';
import { XyzColorTrace, XyzColorTraceSnapshot } from './XyzColorTrace';

export interface BlockModelTraceSnapshot extends XyzTraceSnapshotWithColorAttributes {
    /**
     * Snapshot is saved in Redux state. Must not duplicate XYZ state that is already stored in XYZ.
     * I mean when a plot is saved into XYZ, then basically all the currrent state of the plot is saved into XYZ.
     * Therefore there is a blurry line between what should and should not be saved in Redux through Snapshot.
     * We mainly want to store data that can help us easily extract all required information from XYZ state.
     */
    readonly className: XyzTraceClassNames.BlockModelTrace;

    readonly colorAttributeTraces: {
        [colorAttribute: string]: XyzColorTraceSnapshot;
    };

    readonly selectedColorAttribute: string;

    readonly hidePlotWhenColorsPending?: boolean;

    readonly blockSize: Point3D;

    readonly blockCount: Point3D;

    readonly origin: Point3D;

    readonly blockSizeUrl: string;

    readonly blockCountUrl: string;

    readonly originUrl: string;
}

export class BlockModelTrace extends XyzTraceWithColorAttributes implements BlockModelTraceSnapshot {
    readonly className = XyzTraceClassNames.BlockModelTrace;

    readonly colorAttributeTraces: {
        [colorAttribute: string]: XyzColorTrace;
    };

    readonly selectedColorAttribute: string;

    readonly hidePlotWhenColorsPending = true;

    readonly blockSize: Point3D;

    readonly blockCount: Point3D;

    readonly origin: Point3D;

    readonly blockSizeUrl: string;

    readonly blockCountUrl: string;

    readonly originUrl: string;

    constructor(xyz: UseXyz, snapshot: BlockModelTraceSnapshot, tokenProvider: () => Promise<string>) {
        if (snapshot.className !== XyzTraceClassNames.BlockModelTrace) {
            throw `An incorrect spanshot is being used to create this instance of BlockModelTrace: ${snapshot}`;
        }
        if (!snapshot.id) {
            throw 'Id must be provided.';
        }
        super(xyz, snapshot, tokenProvider);

        this.colorAttributeTraces = {};

        Object.keys(snapshot.colorAttributeTraces).forEach((colorAttribute) => {
            this.colorAttributeTraces[colorAttribute] = new XyzColorTrace(
                snapshot.colorAttributeTraces[colorAttribute],
                this,
                xyz
            );
        });
    }

    private createInitialXyzEntities = async (opacity: number, wireframe: boolean): Promise<UpdateSnapshot> => {
        const elementEntitySnapshot = await this.createInitialElementEntitySnapshot();
        const viewEntitySnapshot = this.createInitialViewEntitySnapshot(opacity, wireframe);
        const colorEntitiesSnapshot = this.selectedColorAttributeTrace().createInitialColorDataSnapshot();
        return {
            ...colorEntitiesSnapshot,
            ...viewEntitySnapshot,
            ...elementEntitySnapshot,
        };
    };

    createInitialElementEntitySnapshot = async (): Promise<UpdateSnapshot> => ({
        [this.elementEntityId()]: {
            block_count: await this.getBlockCount(),
            block_size: await this.getBlockSize(),
            origin: await this.getOrigin(),
            axis_u: [1, 0, 0],
            axis_v: [0, 1, 0],
            axis_w: [0, 0, 1],
            __class__: ElementClass.RegularBlockmodel,
        },
    });

    createInitialViewEntitySnapshot = (opacity = DEFAULT_XYZ_OPACITY, wireframe = true) => {
        if (this.selectedColorAttributeTrace().useSingleColor) {
            const viewEntitySnapshot: UpdateSnapshot = {
                [this.viewEntityId()]: {
                    // color: getNextMeshColor(),
                    element: this.elementEntityId(),
                    opacity: opacity,
                    wireframe: wireframe,
                    visible: this.isVisible(),
                    __class__: ViewClass.Blockmodel,
                },
            };
            const state = this.xyz.getState();
            const viewEntity = state[this.viewEntityId()] as BlockmodelViewState;
            if (!viewEntity?.color) {
                // Set color if it wasn't set before
                const newViewEntity = viewEntitySnapshot[this.viewEntityId()] as BlockmodelViewState;
                newViewEntity.color = hexToRgbArray(getNextMeshColor());
            }
            return {
                ...viewEntitySnapshot,
            };
        } else {
            const viewEntitySnapshot: UpdateSnapshot = {
                [this.viewEntityId()]: {
                    element: this.elementEntityId(),
                    color_data: this.selectedColorAttributeTrace().colorDataEntityId(),
                    opacity: opacity,
                    visible: this.isVisible(),
                    __class__: ViewClass.Blockmodel,
                },
            };
            return {
                ...viewEntitySnapshot,
            };
        }
    };

    snapshotPath = (): string[] => [this.id];

    getLvaAttributes = () => Object.keys(this.colorAttributeTraces);

    entityIdPrefix = (): string => this.id;

    updateSelectedAttribute = async (dispatch: AppDispatch, colorAttribute: string, enabled = true) => {
        const newBlockModelTrace = new BlockModelTrace(
            this.xyz,
            {
                ...this.takeSnapshot(),
                selectedColorAttribute: colorAttribute,
            },
            this.tokenProvider
        ); // We need access to the new BlockModelTraceSnapshot in order to display it as a new trace. That's why we can't use this.updateReduxState() because it doesn't return the new snapshot
        await dispatch(newBlockModelTrace.plotAndSave(this.getOpacity(), enabled));
        if (this.selectedColorAttribute !== colorAttribute) {
            await this.addOrRemoveElementFromPlotView(false); // Remove this object from plotTrace only after the new trace is displayed so that the camera is not disabled.
        }
    };

    updateOpacity = async (opacity: number) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                opacity: opacity,
            },
        });
    };

    updateColor = async (color: Point3D) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                color: color,
            },
        });
    };

    plotAndSave = (
        opacity?: number,
        enabled = true,
        wireframe = false,
        inValidateAllColorValueSummaries = InValidateAllColorValueSummaries.No
    ) => {
        const plotAndSaveThunk = async (dispatch: AppDispatch) => {
            const initialXyzEntities = await this.createInitialXyzEntities(opacity, wireframe);
            await this.xyz.updateVisualizationWithoutTween(initialXyzEntities);

            const BlockModelTraceSnapshot = this.takeSnapshot();
            dispatch(addNewXyzTrace(BlockModelTraceSnapshot));

            await this.setEnabled(dispatch, enabled);

            await dispatch(
                this.selectedColorAttributeTrace().downloadColorArrayUrlAndSetColorArray(
                    inValidateAllColorValueSummaries,
                    this.tokenProvider
                )
            );

            await dispatch(this.resetViewVisibilityBasedOnLatestVisibility(this.tokenProvider));
        };
        return plotAndSaveThunk;
    };

    getName = () => this.selectedColorAttribute;

    showEmptyColorMapLegend = () => !this.hasColorMap();

    getBlockSize = async () => this.getJsonDataWithUrl('blockSize');

    getOrigin = async () => this.getJsonDataWithUrl('origin');

    getBlockCount = async () => this.getJsonDataWithUrl('blockCount');

    // updateMeshMode = (dispatch: AppDispatch, newMeshMode: boolean) => {
    //     if (newMeshMode === this.meshMode) {
    //         return;
    //     }

    //     const newBlockModelTrace = new BlockModelTrace(this.xyz, {
    //         ...this.takeSnapshot(),
    //         meshMode: newMeshMode,
    //     });

    //     if (newMeshMode) {
    //         // TODO: change to mesh mode.
    //     } else {
    //         newBlockModelTrace.plotAndSave(newBlockModelTrace.getOpacity(), true, true);
    //     }

    //     dispatch(
    //         this.updateReduxState(
    //             produce<BlockModelTraceSnapshot>((oldBlockModelTraceSnapshot) => {
    //                 oldBlockModelTraceSnapshot.meshMode = newMeshMode;
    //             })
    //         )
    //     );
    // };

    updateWireframe = async (newShowWireframe: boolean) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                wireframe: newShowWireframe,
            },
        });
    };

    wireframeEnabled = () => {
        return this.viewEntity().wireframe;
    };

    takeSnapshot = (): BlockModelTraceSnapshot => {
        const colorAttributeTraces = objectMap(this.colorAttributeTraces, (colorAttributeTrace) =>
            colorAttributeTrace.takeSnapshot()
        );

        const superSnapshot = super.takeSnapshot();

        return {
            ...superSnapshot,
            className: this.className,
            selectedColorAttribute: this.selectedColorAttribute,
            colorAttributeTraces: colorAttributeTraces,
            blockSize: this.blockSize,
            blockCount: this.blockCount,
            origin: this.origin,
            blockSizeUrl: this.blockSizeUrl,
            blockCountUrl: this.blockCountUrl,
            originUrl: this.originUrl,
        };
    };

    elementEntityId = () => `${this.entityIdPrefix()}:ElementBlockmodel`;

    viewEntityId = (): string => `${this.selectedColorAttributeTrace().entityIdPrefix()}:ViewBlockmodel`;

    viewEntity = (): BlockmodelViewState => this.xyz.getEntityState(this.viewEntityId()) as BlockmodelViewState;

    updateObjectHash = (
        objectHash: string,
        enabled: boolean,
        inValidateAllColorValueSummaries: InValidateAllColorValueSummaries
    ) => {
        const updateObjectHashThunk = async (dispatch: AppDispatch) => {
            const newBlockModelTrace = new BlockModelTrace(
                this.xyz,
                {
                    ...this.takeSnapshot(),
                    objectHash: objectHash,
                },
                this.tokenProvider
            ); // We need access to the new TraceSnapshot right now. That's why we can't use this.updateReduxState() because it doesn't return the new snapshot.
            await dispatch(
                newBlockModelTrace.plotAndSave(
                    this.getOpacity(),
                    enabled,
                    newBlockModelTrace.wireframeEnabled(),
                    inValidateAllColorValueSummaries
                )
            );
        };
        return updateObjectHashThunk;
    };
}
