import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { AppState } from 'App/Redux/store';
import { AnyXyzTraceSnapshot, createXyzTraceObjectFromSnapshot } from 'Common/Xyz/XyzTraces/utils/AnyXyzTrace';
import { XyzTraceId } from 'Common/Xyz/XyzTraces/XyzTrace';

export type XyzTraces = {
    [Id: XyzTraceId]: AnyXyzTraceSnapshot;
};

const initialState: XyzTraces = {};

export const XYZTracesSlice = createSlice({
    name: 'xyzTraces',
    initialState,
    reducers: {
        setXyzTraces: (state, action: PayloadAction<XyzTraces>) => {
            Object.freeze(action.payload); // Freeze large object to improve immer performance
            return action.payload;
        },
        addNewXyzTrace: (state, action: PayloadAction<AnyXyzTraceSnapshot>) => {
            state[action.payload.id] = action.payload;
        },
        deleteXyzTrace: (state, action: PayloadAction<XyzTraceId>) => {
            const xyzTraceId = action.payload;
            if (state[xyzTraceId]) {
                state[xyzTraceId].enabled = false;
            }
        },
    },
});

export default XYZTracesSlice.reducer;

export const { setXyzTraces, addNewXyzTrace, deleteXyzTrace } = XYZTracesSlice.actions;

export const selectXyzTraces = (state: AppState) => state.xyzTraces;
export const selectEnabledXyzTraces = createSelector(selectXyzTraces, (xyzTraces: XyzTraces) =>
    Object.fromEntries(Object.entries(xyzTraces).filter(([, xyzTrace]) => xyzTrace.enabled))
);
export const selectEnabledXyzTraceIds = createSelector(selectEnabledXyzTraces, (xyzTraces: XyzTraces) =>
    Object.keys(xyzTraces)
);
export const selectEnabledXyzTraceObjectIdentifiers = createSelector(selectEnabledXyzTraces, (xyzTraces: XyzTraces) =>
    Object.values(xyzTraces)
        .map((xyzTrace) => xyzTrace.projectObjectIdentifier)
        .filter((identifier) => !!identifier)
);
export const selectXyzTrace = (state: AppState, traceId: XyzTraceId) => state.xyzTraces[traceId];
export const selectEnabledXyzTrace = createSelector(
    selectEnabledXyzTraces,
    (state, traceId: XyzTraceId) => traceId,
    (xyzTraces: XyzTraces, traceId: XyzTraceId) => xyzTraces[traceId]
);
export const selectEnabledXyzTracesLength = (state: AppState) => Object.keys(state.xyzTraces).length;

export const makeSelectXyzTraceObjects = (xyz: UseXyz, tokenProvider: () => Promise<string>) =>
    createSelector(
        selectXyzTraces,
        (state, traceIds: XyzTraceId[]) => traceIds,
        (xyzTraces: XyzTraces, traceIds: XyzTraceId[]) =>
            Object.fromEntries(
                traceIds.map((traceId) => [
                    traceId,
                    createXyzTraceObjectFromSnapshot(xyz, xyzTraces[traceId], tokenProvider),
                ])
            )
    );
