// import { ErrorScreen } from '@local/svgs/dist/pageState';
// import { SearchSvg } from '@local/svgs/dist/svg';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { Tile } from '@local/web-design-system/dist/components/Tile';
import Grid from '@mui/material/Grid';
import { useInView } from 'react-intersection-observer';

// import { useGetThumbnailQuery } from '../../../apiClients/genericApi/thumbnailApi';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { tss } from 'tss-react/mui';
// import { common as designStyles } from '@local/web-design-system/dist/styles/common';

const useStyles = tss.create(({ theme }) => ({
    list: {
        // ...designStyles(theme).thinScrollBar,
        // maxHeight: `calc(100vh - ${theme.spacing(36)})`,
        paddingTop: theme.spacing(1),
    },
    root: {
        padding: theme.spacing(1.5),
        '& > div > button': {
            border: '1px solid transparent',
            '&:focus': {
                border: `1px solid  ${theme.palette.primary.main}`,
            },
        },
    },
    wrapper: {
        paddingTop: '8px',
    },
    fakebox: {
        height: '244px',
        width: '264px',
    },
    noSearchResults: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(14),
    },
    loadingStateBody: {
        paddingTop: theme.spacing(2),
        width: '100%',
    },
}));

export interface TileItem {
    id: ObjectIDType;
    imageUrl: string;
    component: React.FC<object>;
}

export default function TileView(props: {
    items: TileItem[];
    isLoading: boolean;
    isError: boolean;
    noItemsMessage: string;
    selectedItem?: ObjectIDType;
    onItemClick: (itemId: ObjectIDType) => void;
}) {
    const { classes } = useStyles();

    if (props.isError) {
        return (
            <div>
                error
                {/* <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} /> */}
            </div>
        );
    }

    if (props.isLoading) {
        return (
            <Grid className={classes.loadingStateBody}>
                <Skeleton variant="rectangle" width="100%" height="40vh" />
            </Grid>
        );
    }

    if (props.items.length === 0) {
        return (
            <EmptyState
                title={props.noItemsMessage}
                message=""
                // image={<SearchSvg />}
                classes={{ root: classes.noSearchResults }}
            />
        );
    }

    return (
        <div className={classes.wrapper}>
            <Grid container justifyContent="center" className={classes.list}>
                {props.items.map((item: TileItem) => (
                    <TileInView
                        key={item.id}
                        item={item}
                        selected={item.id === props.selectedItem}
                        onClick={() => props.onItemClick(item.id)}
                    />
                ))}
            </Grid>
        </div>
    );
}

function TileInView(props: { item: TileItem; onClick: () => void; selected: boolean }) {
    const { classes } = useStyles();

    const { ref, inView } = useInView();

    // const { data: thumbnail, isLoading: thumbnailLoading } = useGetThumbnailQuery(
    //     `https://loremflickr.com/640/640/geology?random=${hash(workspace.id)}&lock=${hash(workspace.id)}`
    // );

    return (
        <Grid ref={ref} item className={classes.root}>
            {inView ? (
                <Tile
                    // title={props.item.title}
                    // title="title"
                    noImageTitle
                    // subheader="subheader"
                    // image={props.item.imageUrl}
                    image={props.item.imageUrl}
                    onClick={props.onClick}
                    selected={props.selected}
                    CardHeaderProps={
                        props.item?.component
                            ? {
                                  ...props.item,
                                  // @ts-ignore : I don't know why it doesn't like this "component"
                                  component: props.item?.component,
                              }
                            : {}
                    }
                />
            ) : (
                <div className={classes.fakebox}>loading...</div>
            )}
        </Grid>
    );
}

// Temporary method while we are using loremflickr placeholders to ensure that the images used are unique and relatively stable.
// const hash = (input: string) => {
//     let result = 0;
//     for (let i = 0; i < input.length; i += 1) {
//         const char = input.charCodeAt(i);
//         // eslint-disable-next-line no-bitwise
//         result = (result << 5) - result + char;
//         // eslint-disable-next-line no-bitwise
//         result &= result;
//     }
//     return result;
// };
