import { buildAnisotropySubtree } from 'App/MainApp/TreeView/treeData/anisotropySectionService';
import { buildInterpolationsSubtree } from 'App/MainApp/TreeView/treeData/interpolationsSectionService';
import { buildOverlapsSubtree } from 'App/MainApp/TreeView/treeData/overlapsSectionService';
import { buildSetupSubtree } from 'App/MainApp/TreeView/treeData/setupSectionService';
import { buildZonesSubtree } from 'App/MainApp/TreeView/treeData/zonesSectionService';
import { Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { buildZonesFromAnisotropySubtree } from './zonesFromAnisotropySectionService';

export function buildProjectTree(allObjects: ObjectClassName2Id2Obj): Subsections {
    const tree: Subsections = {};

    buildSetupSubtree(allObjects, tree);

    buildAnisotropySubtree(allObjects, tree);

    buildInterpolationsSubtree(allObjects, tree);

    buildZonesSubtree(allObjects, tree);

    buildZonesFromAnisotropySubtree(allObjects, tree);

    buildOverlapsSubtree(allObjects, tree);

    return tree;
}
