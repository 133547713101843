import React from 'react';
import DrillingSettings from 'App/MainApp/Dialogs/Uploads/UploadDrilling/DrillingSettings';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';

export interface UploadedFileData {
    columns: string[];
    attributes?: string[];
    file_name: string;
    file: File;
}

export default function UploadDrillingFromEvo(props: {
    uploadedFileData: UploadedFileData;
    handleClose: () => void;
    gooseObjectId: ObjectIDType;
}) {
    return (
        <DrillingSettings
            uploadedFileData={props.uploadedFileData}
            disableDropdowns
            handleClose={props.handleClose}
            gooseObjectId={props.gooseObjectId}
        />
    );
}
