import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { useSessionContext } from 'App/context/SessionContext';
import {
    XYZ_BOUNDING_BOX_TRACE_ID,
    BoundingBoxTrace,
    BoundingBoxTraceSnapshot,
} from 'Common/Xyz/XyzTraces/BoundingBoxTrace';
import { XyzTraceClassNames } from 'Common/Xyz/XyzTraces/XyzTrace';
import { useXyzTrace } from 'Common/Xyz/XyzTraces/utils/AnyXyzTrace';
import { Point3D } from 'Common/types/geometryTypes';
import React from 'react';

function createInitialBoundingBoxTraceSnapshot(minCorner: Point3D, maxCorner: Point3D): BoundingBoxTraceSnapshot {
    return {
        id: XYZ_BOUNDING_BOX_TRACE_ID,
        className: XyzTraceClassNames.BoundingBoxTrace,
        enabled: true,
        visible: true,
        name: 'Hidden Bounding Box',
        projectObjectIdentifier: undefined,
        verticesUrl: undefined,
        segmentsUrl: undefined,
        vertices: undefined,
        segments: undefined,
        minCorner: minCorner,
        maxCorner: maxCorner,
        objectHash: '1',
    };
}

async function createInitialXyzBoundingBoxTrace(
    dispatch: AppDispatch,
    xyz: UseXyz,
    minCorner: Point3D,
    maxCorner: Point3D
) {
    const initialMeshSnapshot = createInitialBoundingBoxTraceSnapshot(minCorner, maxCorner);

    const boundingBoxTrace = new BoundingBoxTrace(xyz, initialMeshSnapshot);
    await dispatch(boundingBoxTrace.plotAndSave(true, 2, [80, 80, 80]));
}

function plotBoundingBoxTrace(minCorner: Point3D, maxCorner: Point3D, xyz: UseXyz) {
    return async function plotBoundingBoxTraceThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshot = selectXyzTrace(state, XYZ_BOUNDING_BOX_TRACE_ID) as BoundingBoxTraceSnapshot;
        if (xyzTraceSnapshot) {
            const boundingBoxTrace = new BoundingBoxTrace(xyz, xyzTraceSnapshot);
            await dispatch(boundingBoxTrace.plotAndSave(true, 2, [80, 80, 80]));
            await boundingBoxTrace.setMinMaxCorners(dispatch, minCorner, maxCorner);
        } else {
            await createInitialXyzBoundingBoxTrace(dispatch, xyz, minCorner, maxCorner);
        }
    };
}

function deleteBoundingBoxTrace(xyz: UseXyz) {
    return async function deleteBoundingBoxTraceThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshot = selectXyzTrace(state, XYZ_BOUNDING_BOX_TRACE_ID);
        if (xyzTraceSnapshot) {
            const boundingBoxTrace = new BoundingBoxTrace(xyz, xyzTraceSnapshot as BoundingBoxTraceSnapshot);
            await boundingBoxTrace.setEnabled(dispatch, false);
        }
    };
}

export function useXyzBoundingBox(
    xyz: UseXyz,
    dispatch: AppDispatch,
    initialMinCorner: Point3D,
    initialMaxCorner: Point3D
) {
    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);
    const boundingBoxTrace = useXyzTrace(xyz, XYZ_BOUNDING_BOX_TRACE_ID, tokenProvider) as BoundingBoxTrace;

    React.useEffect(() => {
        if (!xyz) {
            return;
        }
        if (!boundingBoxTrace || !boundingBoxTrace.isEnabled() || !boundingBoxTrace.isVisible()) {
            void dispatch(plotBoundingBoxTrace(initialMinCorner, initialMaxCorner, xyz));
        }

        return () => {
            void dispatch(deleteBoundingBoxTrace(xyz));
        };
    }, [xyz]);

    const updateBoundingBoxCorners = React.useCallback(
        async (minCorner: Point3D, maxCorner: Point3D) => {
            await boundingBoxTrace.setMinMaxCorners(dispatch, minCorner, maxCorner);
        },
        [boundingBoxTrace]
    );

    return {
        updateBoundingBoxCorners,
    };
}
