import { Accordion } from '@local/web-design-system/dist/components/Accordion';
import NodeLabel from 'App/MainApp/TreeView/components/ProjectTree/nodeLabel/NodeLabel';
import { TreeContext } from 'App/MainApp/TreeView/components/ProjectTree/TreeContext';
import { ProjectTreeNode, ProjectTreeObjectNode, ProjectTreeSource } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import React, { ChangeEvent } from 'react';
import { tss } from 'tss-react/mui';
import { anyoneInSubtreeHasSearchKeywords } from '../../treeUtils';
import { sortProjectTreeNodesByCreatedAt } from './ProjectTreeNode_util';
import { useAppSelector } from 'App/Redux/hooks';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { SelectableTreeOnChange } from '../checkboxTree/useSelectableTree';
import Grid from '@mui/material/Grid';

const useStyles = tss.create(() => ({
    leafContainer: {
        paddingRight: '8px',
    },
    accordionContainer: {
        width: '100%',
    },
    nonLeafContainer: {
        display: 'grid', // a hack to make the title container to span the whole row
    },
}));

export type TreeNodeProps = {
    node: ProjectTreeNode;
    onChange?: SelectableTreeOnChange;
    nodeSelectionChecker?: (node: ProjectTreeNode, treeSource: ProjectTreeSource) => boolean;
    checkBoxNode?: boolean;
    treeSource: ProjectTreeSource;
};

export default function TreeNode(props: TreeNodeProps) {
    const { classes } = useStyles();

    const { onExpandChange, isExpanded, searchKeywords } = React.useContext(TreeContext);

    const allProjectObjects = useAppSelector(selectObjectClassname2Id2Obj);

    const onExpansion = (e: ChangeEvent<Element>, expanded: boolean) => {
        onExpandChange([props.node.nodeId], expanded, props.treeSource);
    };

    const thisNodeExpanded = isExpanded(props.node.nodeId, props.treeSource);

    const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // prevent from tree to expand-collapse while clicking on checkboxes
        event.stopPropagation();
        props.onChange(event, props.node);
    };

    const isChecked = props.nodeSelectionChecker ? props.nodeSelectionChecker(props.node, props.treeSource) : false;

    const Title = (
        <NodeLabel
            treeSource={props.treeSource}
            node={props.node}
            checkBoxNode={props.checkBoxNode}
            checked={isChecked}
            onChange={onCheckBoxChange}
        />
    );

    return props.node.subsections && Object.keys(props.node.subsections).length ? (
        <div data-cy={props.node.dataCy} className={classes.accordionContainer}>
            <Accordion
                title={Title}
                expanded={thisNodeExpanded}
                onChange={onExpansion}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore because that's the only way to pass className through */
                draggableProps={{ className: classes.nonLeafContainer }}
            >
                {thisNodeExpanded &&
                    Object.values(props.node.subsections || [])
                        .filter((subsection) => anyoneInSubtreeHasSearchKeywords(searchKeywords, subsection))
                        .sort((treeNode1, treeNode2) =>
                            sortProjectTreeNodesByCreatedAt(
                                treeNode1,
                                allProjectObjects?.[(treeNode1 as ProjectTreeObjectNode)?.className]?.[
                                    (treeNode1 as ProjectTreeObjectNode)?.id
                                ],
                                treeNode2,
                                allProjectObjects?.[(treeNode2 as ProjectTreeObjectNode)?.className]?.[
                                    (treeNode2 as ProjectTreeObjectNode)?.id
                                ]
                            )
                        )
                        .map((childNode) => {
                            return (
                                <TreeNode
                                    key={childNode.nodeId}
                                    node={childNode}
                                    checkBoxNode={props.checkBoxNode}
                                    treeSource={props.treeSource}
                                    nodeSelectionChecker={props.nodeSelectionChecker}
                                    onChange={props.onChange}
                                />
                            );
                        })}
            </Accordion>
        </div>
    ) : (
        <Grid container className={classes.leafContainer} data-cy={props.node.dataCy} component="span">
            {Title}
        </Grid>
    );
}
