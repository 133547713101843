import { SecondaryMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { uploadDrillingFromLocalDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import DialogOpenerMenuItem from '../../../DialogOpenerMenuItem';
import { UploadButtons } from './UploadDrillingConfirmation';
import UploadDrillingConfirmation from './UploadDrillingConfirmation';

export function UploadDrillingFromLocalButton(props: { handleMenuClose(): void }) {
    return (
        <DialogOpenerMenuItem
            name={SecondaryMenuItems.FromLocal}
            tooltipText=""
            dataCy={uploadDrillingFromLocalDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={false}
            dialogComponent={UploadDrillingConfirmation}
            dialogProps={{
                source: UploadButtons.LOCAL,
            }}
        />
    );
}
