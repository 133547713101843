import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { SelectMenu, SelectMenuItem } from '@local/web-design-system/dist/components/SelectMenu';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { DataContext } from 'App/DataContext';
import { UploadedFileData } from 'App/MainApp/Dialogs/Uploads/UploadDrilling/UploadDrillingFromEvo';
import {
    expandDrillNodes,
    findAttributesColumnNames,
    findDrillholeIdColumnName,
    findXColumnName,
    findYColumnName,
    findZColumnName,
    uploadDrilling,
} from 'App/MainApp/Dialogs/Uploads/UploadDrilling/uploadDrillingService';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import {
    applyWebsocketModificationsToCurrentProject,
    backendProjectUrl,
    deleteMockDrillObject,
    addMockDrillObject,
    selectActiveDrilling,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import { OBJECT_CLASS_NAMES, ObjectIDType, ObjectStatusTypes } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import SelectItemsList, { SelectedItems } from 'Common/components/SelectItemsList/SelectItemsList';
import {
    drillholeIdColumnDropdownDataCy,
    uploadDrillingDialogDataCy,
    xColumnDropdownDataCy,
    yColumnDropdownDataCy,
    zColumnDropdownDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import { tss } from 'tss-react/mui';
import { setProjectTreeExpandedNodes } from 'App/Redux/features/globalContext/projectTreeExpandedNodesSlice';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useSessionContext } from 'App/context/SessionContext';
import {
    ProjectObjectActionType,
    setProjectObjectUploadingOrPublishing,
} from 'App/Redux/features/globalContext/projectObjectSlice';
import { showWarnings } from '../../Shared/warningsUtil';
import {
    handleUploadOrPublishFileProgress,
    resetUploadOrPublishFileProgress,
} from '../../Shared/uploadOrPublishFileUtil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create(() => ({
    root: {
        flexDirection: 'column',
        rowGap: '25px',
    },

    section: {
        flexDirection: 'column',
        rowGap: '10px',
    },

    fileName: {
        wordBreak: 'break-word',
    },

    attributesList: {
        maxHeight: '350px',
        flexWrap: 'nowrap',
        overflowY: 'scroll',
    },

    xyzColumnsSection: {
        display: 'grid',
        gridTemplateColumns: '30% 30% 30%',
        columnGap: '5%',
    },

    xyzColumnContainer: {
        rowGap: '10px',
    },

    drillholeIdContainer: {
        marginTop: '10px',
        rowGap: '10px',
    },
}));

export default function DrillingSettings(props: {
    uploadedFileData: UploadedFileData;
    disableDropdowns?: boolean;
    handleClose: () => void;
    gooseObjectId?: ObjectIDType;
}) {
    const { classes } = useStyles();

    const [selectedAttributes, setSelectedAttributes] = React.useState<SelectedItems>({});
    const { onExpandChange } = useExpandedNodes();

    const [xColumn, setXColumn] = React.useState('');
    const [yColumn, setYColumn] = React.useState('');
    const [zColumn, setZColumn] = React.useState('');
    const [drillholeIdColumn, setDrillholeIdColumn] = React.useState('');
    const { addMessage } = useDriverMessagesContext();
    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const unCanceledRequestsRef = React.useRef<symbol[]>([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const scmCoreUpdate = useFlags()?.scmCoreUpdate;
    const existingDrillingObject = useAppSelector(selectActiveDrilling);

    const currentProjectId = useAppSelector(selectCurrentProjectId);

    const dispatch = useAppDispatch();
    const xyz = useXyz();

    const columnsOptions: SelectMenuItem[] = (props.uploadedFileData.columns || []).map((column) => {
        return {
            label: column,
            key: column,
        };
    });

    const columnNames = props.uploadedFileData.columns || [];
    const attributes = props.uploadedFileData.attributes || columnNames;

    const isGoose = !!props.gooseObjectId;

    // pre-select columns names
    React.useEffect(() => {
        const columnNames = props.uploadedFileData.columns || [];

        setXColumn(findXColumnName(columnNames));
        setYColumn(findYColumnName(columnNames));
        setZColumn(findZColumnName(columnNames));

        let drillHoleIdColumnName = findDrillholeIdColumnName(columnNames);

        if (!drillHoleIdColumnName) {
            drillHoleIdColumnName = '';
        }

        setDrillholeIdColumn(drillHoleIdColumnName);

        const selectedAttributes: SelectedItems = {};

        findAttributesColumnNames(attributes).forEach((attribute) => {
            selectedAttributes[attribute] = true;
        });

        setSelectedAttributes(selectedAttributes);
    }, [props.uploadedFileData.columns]);

    const getColumnValidationConditionsAndMsgs = (isGoose: boolean, scmCoreUpdate: boolean) => {
        let xyzColumnSet = new Set([]);
        let columnSetSizeCondition = false;
        let columnSetSelectedCondition = false;
        let columnSetSizeConditionMsg = '';
        let columnSetSelectedConditionMsg = '';

        if (!scmCoreUpdate) {
            xyzColumnSet = new Set([xColumn, yColumn, zColumn, drillholeIdColumn]);
            columnSetSizeCondition = xyzColumnSet.size < 4;
            columnSetSelectedCondition = !xColumn || !yColumn || !zColumn || !drillholeIdColumn;
            columnSetSizeConditionMsg = "x, y, z and holeid can't be the same!";
            columnSetSelectedConditionMsg = 'x, y, z and holeid must all be the selected!';
        } else {
            xyzColumnSet = new Set([xColumn, yColumn, zColumn]);
            if (isGoose) {
                xyzColumnSet.add(drillholeIdColumn);
                columnSetSizeCondition = xyzColumnSet.size < 4;
                columnSetSelectedCondition = !xColumn || !yColumn || !zColumn || !drillholeIdColumn;
                columnSetSizeConditionMsg = "x, y, z and holeid can't be the same!";
                columnSetSelectedConditionMsg = 'x, y, z and holeid must all be the selected!';
            } else {
                columnSetSizeCondition = xyzColumnSet.size < 3;
                columnSetSelectedCondition = !xColumn || !yColumn || !zColumn;
                columnSetSizeConditionMsg = "x, y and z can't be the same!";
                columnSetSelectedConditionMsg = 'x, y and z must all be the selected!';
            }
        }

        return {
            columnSetSizeCondition,
            columnSetSelectedCondition,
            columnSetSizeConditionMsg,
            columnSetSelectedConditionMsg,
        };
    };

    const submitUploadFile = () => {
        const {
            columnSetSizeCondition,
            columnSetSelectedCondition,
            columnSetSizeConditionMsg,
            columnSetSelectedConditionMsg,
        } = getColumnValidationConditionsAndMsgs(isGoose, scmCoreUpdate);

        if (columnSetSizeCondition) {
            addMessage({
                message: columnSetSizeConditionMsg,
                type: NotificationType.ERROR,
            });
            return;
        }

        if (columnSetSelectedCondition) {
            addMessage({
                message: columnSetSelectedConditionMsg,
                type: NotificationType.ERROR,
            });
            return;
        }

        const mockDrillId = 'mock-drill-id';

        const updateExisting = !!(existingDrillingObject && existingDrillingObject.url);

        const url = existingDrillingObject
            ? existingDrillingObject.url
            : `${backendProjectUrl(currentOrgUuid, currentWorkspaceId, currentProjectId)}/sourcefiles`;

        const dataAttributes = Object.keys(selectedAttributes).filter((attribute) => selectedAttributes[attribute]);

        const projectUploadObject: { [id: string]: ProjectObjectActionType } = {};
        let objectId = '';

        if (isGoose) {
            const gooseName = props.uploadedFileData.file_name;
            objectId = props.gooseObjectId;
            const updatedProjectUploadObject = handleUploadOrPublishFileProgress(
                objectId,
                OBJECT_CLASS_NAMES.SourceFile,
                ObjectStatusTypes.UPLOADING,
                30,
                gooseName,
                projectUploadObject,
                true
            );
            dispatch(setProjectObjectUploadingOrPublishing(updatedProjectUploadObject));
        }

        uploadDrilling(
            axiosDriverFlask,
            {
                dataAttributes,
                xColumn,
                yColumn,
                zColumn,
                drillholeIdColumn,
                file: isGoose ? null : props.uploadedFileData.file,
                fileName: props.uploadedFileData.file_name,
                isGoose,
                gooseObjectId: props.gooseObjectId,
            },
            url,
            updateExisting
        )
            .then((projectUpdateJson) => {
                if (!updateExisting) {
                    dispatch(deleteMockDrillObject(mockDrillId));
                }

                showWarnings(addMessage, projectUpdateJson.data);

                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'upload drilling',
                        tokenProvider
                    )
                );

                expandDrillNodes(onExpandChange);

                dispatch(setProjectTreeExpandedNodes());

                props.handleClose();

                addMessage({
                    message: 'Drilling data was uploaded successfully.',
                    type: NotificationType.SUCCESS,
                });

                if (isGoose) {
                    const updatedProjectUploadObject = resetUploadOrPublishFileProgress(
                        objectId,
                        projectUploadObject,
                        ObjectStatusTypes.UPLOADED,
                        100
                    );
                    dispatch(setProjectObjectUploadingOrPublishing(updatedProjectUploadObject));
                }
            })
            .catch(() => {
                if (!updateExisting) {
                    dispatch(deleteMockDrillObject(mockDrillId));
                }
            })
            .finally(() => {
                setIsSubmitDisabled(false);
            });

        setIsSubmitDisabled(true);

        if (!updateExisting) {
            dispatch(addMockDrillObject(mockDrillId, props.uploadedFileData.file_name));
        }
    };

    const onCancelUpload = () => {
        unCanceledRequestsRef.current.splice(0, 1);
        props.handleClose();
    };

    const optionalColumnsOptions: SelectMenuItem[] = [
        {
            label: 'Unselected',
            key: '',
        } as SelectMenuItem,
    ].concat(columnsOptions);

    return (
        <GenericDialogShell
            title="Upload Drilling"
            maxWidth="xs"
            handleClose={props.handleClose}
            onCancel={onCancelUpload}
            onSubmit={submitUploadFile}
            disabled={isSubmitDisabled || !props.uploadedFileData}
            dataCy={uploadDrillingDialogDataCy}
        >
            <Grid container className={classes.root}>
                <Grid container item className={classes.section}>
                    <Typography variant="h3">Select Data Attributes</Typography>
                    <Typography className={classes.fileName}>File: {props.uploadedFileData.file_name}</Typography>
                    <SelectItemsList
                        items={attributes}
                        selectedItems={selectedAttributes}
                        setSelectedItems={setSelectedAttributes}
                        className={classes.attributesList}
                    />
                </Grid>

                <Grid container item className={classes.section}>
                    <Typography variant="h3">Drillhole Columns</Typography>
                    <Grid container item className={classes.xyzColumnsSection}>
                        <Grid container item className={classes.xyzColumnContainer} data-cy={xColumnDropdownDataCy}>
                            <Typography>Easting</Typography>
                            <SelectMenu
                                onSelect={(key) => setXColumn(`${key}`)}
                                options={columnsOptions}
                                selected={xColumn}
                                disabled={props.disableDropdowns}
                                menuType="1"
                            />
                        </Grid>

                        <Grid container item className={classes.xyzColumnContainer} data-cy={yColumnDropdownDataCy}>
                            <Typography>Northing</Typography>
                            <SelectMenu
                                onSelect={(key) => setYColumn(`${key}`)}
                                options={columnsOptions}
                                selected={yColumn}
                                disabled={props.disableDropdowns}
                                menuType="1"
                            />
                        </Grid>

                        <Grid container item className={classes.xyzColumnContainer} data-cy={zColumnDropdownDataCy}>
                            <Typography>Elevation</Typography>
                            <SelectMenu
                                onSelect={(key) => setZColumn(`${key}`)}
                                options={columnsOptions}
                                selected={zColumn}
                                disabled={props.disableDropdowns}
                                menuType="1"
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        className={classes.drillholeIdContainer}
                        data-cy={drillholeIdColumnDropdownDataCy}
                    >
                        <Typography>Drillhole Id Column{scmCoreUpdate && '(Optional)'}</Typography>
                        <SelectMenu
                            onSelect={(key) => setDrillholeIdColumn(`${key}`)}
                            options={scmCoreUpdate ? optionalColumnsOptions : columnsOptions}
                            selected={drillholeIdColumn}
                            disabled={props.disableDropdowns}
                            menuType="1"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </GenericDialogShell>
    );
}
