import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { anisotropyGlobalIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function AnisotropyGlobalIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={anisotropyGlobalIconDataCy}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <path
                    fill="currentColor"
                    d="M18.9,1L18.9,1c0.88,0,1.59,0.23,2.11,0.68c2.19,1.91,0.91,8.1-4.48,14.28C12.85,20.17,8.26,23,5.1,23
	c-0.89,0-1.6-0.23-2.11-0.68c-2.19-1.91-0.91-8.1,4.48-14.28C11.15,3.83,15.74,1,18.9,1 M18.9,0c-3.37,0-8.16,2.78-12.18,7.39
	c-5.34,6.12-7.3,13.14-4.38,15.69C3.05,23.7,4,24,5.1,24c3.37,0,8.16-2.78,12.18-7.39c5.34-6.12,7.3-13.14,4.38-15.69
	C20.95,0.3,20,0,18.9,0L18.9,0z"
                />
            </svg>
        </SvgIcon>
    );
}

export default AnisotropyGlobalIcon;
