import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTooltip from 'Common/components/InfoTooltip/InfoTooltip';
import WidgetTextField from 'Common/components/Core/WidgetTextField';
import { tss } from 'tss-react/mui';
import { ValidationResult } from '../../../util/validationUtils';
import GenericCheckbox from './GenericCheckbox';
import { commonStyles } from 'styles/commonStyles';

const useStyles = tss.create(({ theme }) => ({
    textbox: {
        flexGrow: 1,
    },
    checkbox: {
        padding: '0 9px 0 0',
    },
    defaultText: {
        ...commonStyles({ theme }).defaultText,
    },
}));

export type TextFieldProps = {
    title?: string;
    validationResult?: ValidationResult;
    dataCy: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    value: string;
    boundBounds?: {
        min?: number;
        max?: number;
    } /** applicable to number inputs only */;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    type?: React.InputHTMLAttributes<unknown>['type'];
    infoTooltip?: string;
    prefix?: string;
    InputProps?: MuiTextFieldProps['InputProps'];
    errorLabelOnRight?: boolean;
    showCheckbox?: boolean;
    checkboxChecked?: boolean;
    onCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checkboxDataCy?: string;
    incrementStep?: number; // for number fields
};

function LabeledTextField(props: TextFieldProps) {
    const { classes } = useStyles();
    let inputProps = { step: props.incrementStep };
    let finalInputProps = {
        ...props.InputProps,
    };

    if (props.boundBounds) {
        inputProps = { ...inputProps, ...props.boundBounds };
    }

    if (props.prefix) {
        finalInputProps = {
            ...inputProps,
            startAdornment: (
                <InputAdornment position="start" style={{ fontSize: 13 }}>
                    {props.prefix}
                </InputAdornment>
            ),
        };
    }

    const disableTextbox = props.showCheckbox ? props.disabled || !props.checkboxChecked : props.disabled;

    return (
        <Grid item container className={props.className} flexWrap="nowrap" alignItems="center">
            {props.title && (
                <Grid item container alignItems="center">
                    {props.title ? <Typography className={classes.defaultText}>{props.title}</Typography> : null}
                    {props.infoTooltip ? <InfoTooltip text={props.infoTooltip} /> : null}
                </Grid>
            )}
            <Grid item container alignItems="center" flexWrap="nowrap">
                {props.showCheckbox && (
                    <GenericCheckbox
                        checked={props.checkboxChecked}
                        onChange={props.onCheckboxChange}
                        disabled={props.disabled}
                        className={classes.checkbox}
                        data-cy={props.checkboxDataCy}
                    />
                )}
                <WidgetTextField
                    placeholder={props.placeholder}
                    variant="outlined"
                    error={props.validationResult && !props.validationResult?.isValid}
                    helperText={props.validationResult?.error}
                    data-cy={props.dataCy}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    value={props.value}
                    size="small"
                    type={props.type}
                    inputProps={inputProps}
                    InputProps={finalInputProps}
                    disabled={disableTextbox}
                    errorLabelOnRight={props.errorLabelOnRight}
                    className={classes.textbox}
                />
            </Grid>
        </Grid>
    );
}

export default LabeledTextField;
