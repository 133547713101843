import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { createObjectMenuIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function CreateObjectMenuIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={createObjectMenuIconDataCy}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.2396 3.37902L13.6449 2.22036C13.128 1.8456 12.4027 1.96047 12.0274 2.47655L6.46643 10.1305C6.18946 10.5118 6.28158 11.1484 6.30225 11.2679L6.63591 13.3745C6.6542 13.49 6.72131 13.5922 6.81962 13.6549C6.90429 13.7086 7.00548 13.7291 7.10358 13.7136C7.11971 13.711 7.13614 13.7075 7.15243 13.7032L9.19013 13.1128C9.54743 13.0435 9.87408 12.8743 10.1349 12.6219L15.4962 4.99642C15.8706 4.48006 15.7557 3.75434 15.2396 3.37902ZM14.2818 5.60447L14.9712 4.62209C15.1401 4.38857 15.0896 4.06704 14.8608 3.90086L13.2661 2.74221C13.1515 2.65893 13.0142 2.63061 12.8843 2.65119C12.7544 2.67177 12.6322 2.74161 12.5485 2.85629L11.8405 3.83075L14.2818 5.60447ZM9.03954 12.4863C9.29108 12.4364 9.49874 12.3321 9.66546 12.1782L13.911 6.13203L11.4613 4.35266L6.98834 10.5097C6.90357 10.6259 6.90533 10.9695 6.93861 11.1632L7.23098 13.0091L9.03954 12.4863ZM0.666016 10.485C0.666016 12.961 1.96002 14.331 4.51135 14.557L4.58135 13.7597C2.42668 13.569 1.46602 12.559 1.46602 10.485C1.46602 8.29575 3.07788 7.17908 4.49918 6.19445L4.49935 6.19433L4.83935 5.95899C5.46402 5.53166 6.31802 4.94699 6.30068 4.09633C6.28335 3.18966 5.46668 2.13899 3.22735 2.13899C3.21718 2.13899 3.20684 2.13915 3.19635 2.13932C3.18552 2.13949 3.17452 2.13966 3.16335 2.13966L3.17002 2.93966H3.22935C4.88802 2.93966 5.49135 3.56833 5.50135 4.11233C5.50935 4.53098 4.86073 4.97496 4.3874 5.29895L4.38735 5.29899L4.04402 5.53699C2.61868 6.52366 0.666016 7.87633 0.666016 10.485Z"
                    fill="black"
                />
            </svg>
        </SvgIcon>
    );
}

export default CreateObjectMenuIcon;
