import { Point3D } from 'Common/types/geometryTypes';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj, ObjectIDType, ObjectStatusTypes } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { Dependants } from './utilClasses/Dependants';
import { DomainGrid } from './DomainGridDefinition';
import { AnisotropyGrid } from './AnisotropyGrid';
import { AnisotropyGlobal } from './AnisotropyGlobal';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { Anisotropy } from './AnisotropyEstimation';

export interface PointEstimationResultMetadata {
    num_samples: number;
    hist: number[];
    hist_first_two_bin_edges: [number, number];
    hist_NUM_BINS: number;
    mean: number;
    std: number;
    subblock_origin: Point3D;
    subblock_block_count: Point3D;
    optimized_power: number;
    optimized_sample_limits: [number, number];
}

export enum PointEstimationTypes {
    from_anisotropy = 'from_anisotropy',
    merged = 'merged',
}

export interface PointEstimationType extends BackendJsonifiedProjectObject {
    name: string;
    anisotropyGridId?: ObjectIDType;
    anisotropyGlobalId?: ObjectIDType;
    anisotropyEstimationId?: ObjectIDType;
    domain_gridDefinitionId?: ObjectIDType;
    madeFrom_interpolation_combination_ids: ObjectIDType[];
    type: PointEstimationTypes;
    status: ObjectStatusTypes;
    parameters: PointEstimationParameters;
    result_meta_data: PointEstimationResultMetadata;
    dataAttribute_in_drill: string;
    object_class_name: OBJECT_CLASS_NAMES.PointEstimation;
}

export interface PointEstimationParameters {
    namesSuffix: string;
    power: string;
    max_samples_per_octant: string;
    max_samples_per_drill_hole: string;
    anisotropy_analysis_type: string;
    blocksize: [string, string, string];
    pointEstimation_method: 'OK' | 'ID';
}

export class PointEstimation extends BaseProjectObject implements PointEstimationType {
    anisotropyGridId?: ObjectIDType;

    anisotropyGlobalId?: ObjectIDType;

    anisotropyEstimationId?: ObjectIDType;

    domain_gridDefinitionId?: ObjectIDType;

    madeFrom_interpolation_combination_ids: ObjectIDType[];

    type: PointEstimationTypes;

    parameters: PointEstimationParameters;

    result_meta_data: PointEstimationResultMetadata;

    dataAttribute_in_drill: string;

    object_class_name: OBJECT_CLASS_NAMES.PointEstimation;

    isMerged: boolean;

    constructor(
        object: BackendJsonifiedProjectObject,
        axiosDriverFlask: AxiosDriverFlaskInstance,
        allObjects: ObjectClassName2Id2Obj
    ) {
        super(object, axiosDriverFlask, allObjects);
        this.isMerged = this.type === PointEstimationTypes.merged;
    }

    isMergedDomain = () => {
        return this.isMerged;
    };

    getDependantsViaZones = () => {
        const dependants = new Dependants();

        const zones = this.allObjects[OBJECT_CLASS_NAMES.Zone];

        Object.values(zones).forEach((zone) => {
            if (zone.pointEstimationId === this.id) {
                dependants.addDependantAndItsDependantsRaw(zone, this.axiosDriverFlask, this.allObjects);
            }
        });

        return dependants;
    };

    getDependantsViaPointEstimations = () => {
        const dependants = new Dependants();

        const pointEstimations = this.allObjects[OBJECT_CLASS_NAMES.PointEstimation];

        Object.values(pointEstimations)
            .filter((pointEstimation) => pointEstimation.madeFrom_interpolation_combination_ids?.length) // leave only merged point estimations
            .forEach((pointEstimation) => {
                const pointEstimationInstance = new PointEstimation(
                    pointEstimation,
                    this.axiosDriverFlask,
                    this.allObjects
                );

                const parentPointEstimations = pointEstimationInstance.getParentPointEstimations();

                if (parentPointEstimations.some((parent) => this.equals(parent))) {
                    dependants.addDependantAndItsDependants(pointEstimationInstance);
                }
            });

        return dependants;
    };

    getDependants = () => {
        const dependants = this.getDependantsViaZones();
        dependants.addDependants(this.getDependantsViaPointEstimations());

        return dependants;
    };

    getParentPointEstimations = () => {
        if (this.type !== PointEstimationTypes.merged) {
            throw new Error(
                'PointEstimation.getAnisotropy() can only be called for pointEstimations with type merged.'
            );
        }

        return this.madeFrom_interpolation_combination_ids.map((madeFromInterpolationCombinationId) =>
            this.projectObjects.getPointEstimation(madeFromInterpolationCombinationId)
        );
    };

    getDirectParentAnisotropyGlobalOrGridOrEstimation = (): AnisotropyGrid | AnisotropyGlobal | Anisotropy => {
        if (this.type !== PointEstimationTypes.from_anisotropy) {
            throw new Error(
                'PointEstimation.getAnisotropy() can only be called for pointEstimations with type from_anisotropy.'
            );
        }

        return this.anisotropyGlobalId
            ? this.projectObjects.getAnisotropyGlobal(this.anisotropyGlobalId)
            : this.anisotropyGridId
              ? this.projectObjects.getAnisotropyGrid(this.anisotropyGridId)
              : this.projectObjects.getAnisotropyEstimation(this.anisotropyEstimationId);
    };

    getAnisotropyEstimation = () => {
        const directParentAnisotropy = this.getDirectParentAnisotropyGlobalOrGridOrEstimation();
        if (directParentAnisotropy instanceof Anisotropy) {
            return directParentAnisotropy;
        }
        return directParentAnisotropy.getAnisotropyEstimaton();
    };

    getDomainGridDefinition = (): DomainGrid => {
        if (this.isMerged) {
            const parentPointEstimations = this.getParentPointEstimations();
            return parentPointEstimations[0].getDomainGridDefinition();
        } else {
            return this.projectObjects.getDomainGrid(this.domain_gridDefinitionId);
        }
    };

    getGridDefinition = () => {
        if (this.isMerged) {
            const parentPointEstimations = this.getParentPointEstimations();
            const domainGridDefinition = parentPointEstimations[0].getDomainGridDefinition();
            return domainGridDefinition.getGridDefinition();
        } else {
            const domainGridDefinition = this.getDomainGridDefinition();
            return domainGridDefinition.getGridDefinition();
        }
    };

    getDomain = () => {
        if (this.isMerged) {
            return null;
        } else {
            return this.getDomainGridDefinition().getDomain();
        }
    };

    getDomainName = () => {
        if (this.isMerged) {
            return 'merged';
        } else {
            return this.getDomain().getName();
        }
    };

    getBlockSize = () => this.parameters.blocksize.map((v) => Number(v)) as Point3D;

    getBlockCount = () => this.result_meta_data?.subblock_block_count?.map?.((v) => Number(v)) as Point3D;

    getOrigin = () => this.result_meta_data?.subblock_origin?.map?.((v) => Number(v)) as Point3D;
}
