import {
    Selections,
    getRequiredNodeObjectsRecursive,
    getSelectedLeafNodesRecursive,
    isSelected,
    selectNodeRecursive,
    syncParentsSelectionRecursive,
    unselectNodeRecursive,
} from 'App/MainApp/TreeView/components/checkboxTree/selectableTreeUtils';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { Subsections, ProjectTreeNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { produce } from 'immer';
import React from 'react';

export type SelectableTreeOnChange = (event: React.ChangeEvent<HTMLInputElement>, node: ProjectTreeNode) => void;

export function useSelectableTree(tree: Subsections, requiredObjects?: string[], sectionName?: string) {
    const [selections, setSelections] = React.useState<Selections>({});

    const allObjects = useAppSelector(selectObjectClassname2Id2Obj);

    // update the tree if Redux is updated
    React.useEffect(() => {
        const newSelections = produce(selections, (oldSelections) => {
            syncParentsSelectionRecursive(tree, oldSelections, allObjects);
        });

        setSelections(newSelections);
    }, [tree]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, node: ProjectTreeNode) => {
        if (event.target.checked) {
            selectNode(node);
        } else {
            unselectNode(node);
        }
    };

    const selectNode = (node: ProjectTreeNode) => {
        const newSelections = produce(selections, (oldSelections) => {
            selectNodeRecursive(node, oldSelections, tree, allObjects);
        });

        setSelections(newSelections);
    };

    const unselectNode = (node: ProjectTreeNode) => {
        const newSelections = produce(selections, (oldSelections) => {
            unselectNodeRecursive(node, oldSelections, tree, allObjects);
        });

        setSelections(newSelections);
    };

    const selectedLeafNodes = React.useMemo(() => {
        return getSelectedLeafNodesRecursive(selections, tree, allObjects);
    }, [selections, tree, allObjects]);

    const requiredNodeObjects = React.useMemo(() => {
        return getRequiredNodeObjectsRecursive(tree, allObjects, requiredObjects, sectionName);
    }, [tree, allObjects, requiredObjects]);

    const nodeSelectionChecker = (node: ProjectTreeNode) => {
        return isSelected(node, selections, allObjects);
    };

    return {
        onChange,
        selectedLeafNodes,
        requiredNodeObjects,
        nodeSelectionChecker,
    };
}
