import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import DeletionConfirmation from 'App/MainApp/TreeView/components/DeletionConfirmation';
import { ObjectMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { isDeleteDisabled } from 'App/MainApp/services/objectDeleteService';
import { selectCurrentWorkspace } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { deleteMenuItemDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export default function DeleteButton(props: ObjectMenuItemProps) {
    const currentWorkspace = useAppSelector(selectCurrentWorkspace);

    const selectedNodes = props.multipleNodes || [];

    const multipleNodesSelected = selectedNodes.length > 1;

    if (!multipleNodesSelected && props.nodeData) {
        selectedNodes.push({
            id: props.nodeData.id,
            className: props.nodeData.className,
            section: '',
            dataCy: '',
            nodeId: props.nodeData.id,
            path: [],
        });
    }

    const { isValid, error } = isDeleteDisabled(currentWorkspace);

    const dialogProps = {
        nodes: selectedNodes,
    };

    return (
        <DialogOpenerMenuItem
            name={multipleNodesSelected ? 'Delete items' : 'Delete'}
            dataCy={deleteMenuItemDataCy}
            disabled={!isValid}
            dialogComponent={DeletionConfirmation}
            dialogProps={dialogProps}
            handleMenuClose={props.handleMenuClose}
            tooltipText={error}
        />
    );
}
