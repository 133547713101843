export const anisotropySuffixPrefix = '';
export const anisotropyGridSuffixPrefix = '_ani_grid';
export const anisotropyGlobalSuffixPrefix = '_ani_global';

export enum EpsilonTypes {
    AUTO = 'auto',
    VALUE = 'value',
}

export enum ThresholdTypes {
    MEAN = 'mean',
    MEDIAN = 'median',
    PERCENTILE = 'percentile',
    CONCENTRATION = 'concentration',
}

export enum ResolutionTypes {
    VERY_LOW = 'very_low',
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
    VERY_HIGH = 'very_high',
    ALL = 'all',
}

export enum OutputFormatTypes {
    ELLIPSOID = 'ellipsoid',
    OBLATE_SPHEROID = 'oblate_spheroid',
    PROLATE_SPHEROID = 'prolate_spheroid',
}

export const defaultEpsilonValue = '';

export const defaultConcentrationThreshold = '';
export const defaultPercentileThreshold = '50';

export const epsilonOptions = [
    { label: 'Auto', value: EpsilonTypes.AUTO },
    { label: 'Value', value: EpsilonTypes.VALUE },
];
export const thresholdOptions = [
    { label: 'Mean', value: ThresholdTypes.MEAN },
    { label: 'Median', value: ThresholdTypes.MEDIAN },
    { label: 'Percentile', value: ThresholdTypes.PERCENTILE },
    { label: 'Concentration', value: ThresholdTypes.CONCENTRATION },
];

export const resolutionOptions = [
    { label: 'Very low', value: ResolutionTypes.VERY_LOW },
    { label: 'Low', value: ResolutionTypes.LOW },
    { label: 'Medium', value: ResolutionTypes.MEDIUM },
    { label: 'High', value: ResolutionTypes.HIGH },
    { label: 'Very high', value: ResolutionTypes.VERY_HIGH },
    { label: 'All', value: ResolutionTypes.ALL },
];

export const outputFormatOptions = [
    { label: 'Ellipsoid', value: OutputFormatTypes.ELLIPSOID },
    { label: 'Planar ellipsoid', value: OutputFormatTypes.OBLATE_SPHEROID },
    { label: 'Linear ellipsoid', value: OutputFormatTypes.PROLATE_SPHEROID },
];
