import { CreateAnisotropyGrid } from 'App/MainApp/Dialogs/SCMAnisotropy/CreateAnisotropyGrid';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import {
    selectActiveDomains,
    selectActiveAnisotropyEstimations,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { AnisotropySectionMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { createAnisotropyGridDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function CreateAnisotropyGridButton(props: GeneralMenuItemProps) {
    const domains = useAppSelector(selectActiveDomains);
    const anisotropies = useAppSelector(selectActiveAnisotropyEstimations);
    const disabled = !Object.keys(domains).length || !Object.keys(anisotropies).length;
    const tooltipText = disabled ? 'At least one domain and anisotropy estimation required' : '';

    return (
        <DialogOpenerMenuItem
            name={AnisotropySectionMenuItems.NewAnisotropyGrid}
            dataCy={createAnisotropyGridDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            dialogComponent={CreateAnisotropyGrid}
            tooltipText={tooltipText}
        />
    );
}
