import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useDialogOpener } from '../../Dialogs/Shared/useDialogOpenerHook';
import { DataContext } from 'App/DataContext';
import MenuItemIcon from './MenuItemIcon';

type Props<ComponentPropsType extends object> = {
    dialogComponent?: React.FC<ComponentPropsType>;
    dialogProps?: Omit<ComponentPropsType, 'handleClose'>;
    handleMenuClose: () => void;
    name: string;
    tooltipText?: string;
    disabled: boolean;
    dataCy: string;
};

export default function DialogOpenerMenuItem<ComponentPropsType extends object>(props: Props<ComponentPropsType>) {
    const { openDialog } = useDialogOpener();

    const dialogProps = { ...props.dialogProps };

    const { menuItemClass } = React.useContext(DataContext);

    const onClick = () => {
        openDialog({
            dialogComponent: props.dialogComponent,
            dialogProps: dialogProps,
        });
        props.handleMenuClose();
    };

    return (
        <Tooltip disableInteractive title={props.tooltipText || ''} placement="right">
            <div>
                <MenuItem
                    className={menuItemClass.menuItem}
                    data-cy={props.dataCy}
                    onClick={onClick}
                    disabled={props.disabled}
                >
                    <MenuItemIcon name={props.name} />
                    {props.name}
                </MenuItem>
            </div>
        </Tooltip>
    );
}
