import { ProjectTreeNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { ObjectClassName2Id2Obj, ObjectIDType, ProjectObject } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { isInactiveObject } from 'App/util/ProjectDataTypes/projectObjectUtils';

export function getNodePath(parentNode: ProjectTreeNode) {
    return [...(parentNode?.path || []), parentNode?.nodeId];
}

export function getCommonData(object: ProjectObject, parentNode: ProjectTreeNode) {
    return {
        id: object.id,
        className: object.object_class_name,
        nodeId: `${parentNode?.nodeId}_${object.id}`,
        path: getNodePath(parentNode),
    };
}

export function getActiveObjects<ClassName extends keyof ObjectClassName2Id2Obj, Id extends ObjectIDType>(
    allObjects: ObjectClassName2Id2Obj,
    className: ClassName
) {
    const objects = Object.values(allObjects?.[className] ?? {}) as ObjectClassName2Id2Obj[ClassName][Id][];
    return objects.filter((object) => {
        return !isInactiveObject(object);
    });
}

export function findParentNode(tree: Subsections, node: ProjectTreeNode) {
    if (!node.path || !node.path.length) {
        throw new Error(`Search by path failed: node with id ${node.nodeId} has empty path`);
    }

    let currentNode: ProjectTreeNode = null;
    let currentSubsections = tree;

    node.path.forEach((currentNodeId) => {
        // because we map nodes not by nodeId...
        const nextNode = Object.values(currentSubsections).find((node) => node.nodeId === currentNodeId);

        if (!nextNode) {
            throw new Error(`Search by path failed: node with id ${currentNodeId} wasn't found`);
        }

        currentNode = nextNode;
        currentSubsections = nextNode.subsections;
    });

    return currentNode;
}
