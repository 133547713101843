import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'App/Redux/store';

type xyzPlotOverlaysSlice = {
    sliceToolOpen: boolean;
    slicer: boolean;
};

const initialState: xyzPlotOverlaysSlice = {
    sliceToolOpen: false,
    slicer: true,
};

export const xyzPlotOverlaysSlice = createSlice({
    name: 'xyzPlotOverlaysSlice',
    initialState,
    reducers: {
        setXyzSliceToolOpen: (state, action: PayloadAction<boolean>) => {
            state.sliceToolOpen = action.payload;
        },
        setXyzSlicer: (state, action: PayloadAction<boolean>) => {
            state.slicer = action.payload;
        },
    },
});

export default xyzPlotOverlaysSlice.reducer;

export const { setXyzSliceToolOpen, setXyzSlicer } = xyzPlotOverlaysSlice.actions;

export const selectXyzSliceToolOpen = (state: AppState) => state.xyzPlotOverlays.sliceToolOpen;

export const selectXyzSlicer = (state: AppState) => state.xyzPlotOverlays.slicer;
