import React from 'react';

export function mergeRefs(
    ...refs: (React.RefCallback<unknown> | React.MutableRefObject<unknown> | ((instance: unknown) => void))[]
) {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    return (inst: HTMLElement) => {
        for (const ref of filteredRefs) {
            if (typeof ref === 'function') {
                ref(inst);
            } else if (ref) {
                ref.current = inst;
            }
        }
    };
}
