import {
    closedMeshesSectionMenu,
    getDrillingSectionMenu,
    getEmptyProjectDrillingSectionMenu,
    meshesSectionMenu,
    openMeshesSectionMenu,
} from 'App/MainApp/TreeView/menu/sectionMenus';
import { getDrillingNode, getMeshAndDomainNode } from 'App/MainApp/TreeView/treeData/setupNodesService';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { getActiveObjects, getNodePath } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import {
    ProjectTreeNode,
    ProjectTreeSectionNode,
    RightClickMenu,
    Subsections,
} from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { MeshFileType, MeshTypes } from 'App/util/ProjectDataTypes/MeshFile';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { SourceFileType } from 'App/util/ProjectDataTypes/SourceFile';
import {
    closedMeshesSubsectionDataCy,
    drillingSubsectionDataCy,
    uploadDrillingSubsectionDataCy,
    meshesSubsectionDataCy,
    openMeshesSubsectionDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function buildSetupSubtree(allObjects: ObjectClassName2Id2Obj, tree: Subsections) {
    const drillingObject = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.SourceFile)[0];
    const meshes = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.MeshFile);

    const drillingRightClickMenu = drillingObject ? getDrillingSectionMenu() : [];

    tree[SectionNames.Drilling] = {
        section: SectionNames.Drilling,
        dataCy: drillingSubsectionDataCy,
        rightClickMenu: drillingRightClickMenu,
        subsections: {},
        nodeId: SectionIds[SectionNames.Drilling],
        path: getNodePath(tree[SectionNames.Drilling]),
    };

    tree[SectionNames.Meshes] = {
        section: SectionNames.Meshes,
        dataCy: meshesSubsectionDataCy,
        rightClickMenu: meshesSectionMenu,
        nodeId: SectionIds[SectionNames.Meshes],
        path: getNodePath(tree[SectionNames.Meshes]),
    };

    tree[SectionNames.Meshes].subsections = {};

    buildDrillingSubtree(allObjects, tree[SectionNames.Drilling], drillingObject);

    buildMeshesSubtree(allObjects, tree[SectionNames.Meshes], meshes);
}

function buildDrillingSubtree(
    allObjects: ObjectClassName2Id2Obj,
    subtree: ProjectTreeSectionNode,
    drillingObject: SourceFileType
) {
    const drillingRightClickMenu = drillingObject ? getDrillingSectionMenu() : getEmptyProjectDrillingSectionMenu();

    if (drillingObject) {
        const drillingNode = getDrillingNode(drillingObject, subtree);
        subtree.subsections[drillingObject.original_file_name] = drillingNode;
    } else {
        subtree.subsections[SectionNames.Drilling] = {
            section: SectionNames.UploadDrilling,
            dataCy: uploadDrillingSubsectionDataCy,
            rightClickMenu: drillingRightClickMenu,
            nodeId: SectionIds[SectionNames.UploadDrilling],
            path: getNodePath(subtree),
        };
    }
}

function buildMeshesSubtree(
    allObjects: ObjectClassName2Id2Obj,
    subtree: ProjectTreeSectionNode,
    meshes: MeshFileType[]
) {
    meshes.forEach((mesh) => {
        let section: string;
        let dataCy: string;
        let rightClickMenu: RightClickMenu;
        let nodeId: string;

        if (mesh.type === MeshTypes.closed) {
            section = SectionNames.ClosedMeshes;
            dataCy = closedMeshesSubsectionDataCy;
            rightClickMenu = closedMeshesSectionMenu;
            nodeId = SectionIds[SectionNames.ClosedMeshes];
        } else {
            section = SectionNames.OpenMeshes;
            dataCy = openMeshesSubsectionDataCy;
            rightClickMenu = openMeshesSectionMenu;
            nodeId = SectionIds[SectionNames.OpenMeshes];
        }

        const parentNode = buildSubtreeForOpenOrClosedMeshes(subtree, section, dataCy, rightClickMenu, nodeId);

        const meshNode = getMeshAndDomainNode(mesh, allObjects, parentNode);

        parentNode.subsections[mesh.id] = meshNode;
    });
}

function buildSubtreeForOpenOrClosedMeshes(
    subtree: ProjectTreeSectionNode,
    section: string,
    dataCy: string,
    rightClickMenu: RightClickMenu,
    nodeId: string
) {
    if (!subtree.subsections.hasOwnProperty(section)) {
        subtree.subsections[section] = {
            section: section,
            dataCy: dataCy,
            rightClickMenu: rightClickMenu,
            nodeId: nodeId,
            subsections: {},
            path: getNodePath(subtree),
        };
    }

    const parentNode: ProjectTreeNode = subtree.subsections[section];

    return parentNode;
}
