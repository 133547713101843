import {
    ArrayClass,
    FilterClass,
    MappingClass,
    SceneClass,
    ContinuousMappingState,
    DataState,
    NumericFilterState,
    UpdateSnapshot,
} from '@seequent/xyz';

import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { MinMaxRange } from 'Common/utils/validationTypeUtils';
import {
    ArrayScalarState,
    LocalEllipsoidsViewState,
    PointsViewState,
    StructuralViewState,
    Vector3,
} from '@seequent/xyz/lib/types/src/types';
import { produce } from 'immer';
import {
    XYZ_MAPCONTINUOUS_GRADIENT_CONTROL_VALUES,
    XYZ_MAPCONTINUOUS_VISIBILITY,
    XyzPredefinedColorMaps,
    colorMapRange2dataControlValues,
    dataControlValues2colorMapRange,
} from '../XyzColorMaps';
import { XyzReduxSnapshot } from './XyzReduxSnapshot';
import {
    DEFAULT_XYZ_COLORMAP,
    DEFAULT_XYZ_DATA_CONTROL_VALUES,
    DEFAULT_XYZ_GRADIENT_CONTROL_VALUES,
} from './utils/defaults';
import { XyzTraceWithColorAttributes } from './XyzTraceWithColorAttributes';
import { InValidateAllColorValueSummaries, XyzTraceStatusDetails, mostSevereXYZStatus } from './XyzTrace';
import { fetchBinaryArray } from './XyzNetwork';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { createXyzTraceObjectFromSnapshot } from './utils/AnyXyzTrace';
import { AnisotropyDisplayShapes } from 'App/MainApp/Plot/PlotRowTypes/PlotRowComponents/AnisotropyDisplayShape/types';
import { LvaTrace } from 'Common/Xyz/XyzTraces/LvaTrace';

export interface ColorValueSummaries {
    min: number;
    max: number;
    percentiles: {
        value: number;
        percentile: number;
    }[];
}

export interface XyzColorTraceSnapshot extends XyzReduxSnapshot {
    /**
     * Snapshot is saved in Redux state. Must not duplicate XYZ state that is already stored in XYZ.
     * I mean when a plot is saved into XYZ, then basically all the currrent state of the plot is saved into XYZ.
     * Therefore there is a blurry line between what should and should not be saved in Redux through Snapshot.
     * We mainly want to store data that can help us easily extract all required information from XYZ state.
     */
    readonly colorAttributeName: string;

    readonly spotLightAttributeName?: string;

    readonly useSingleColor: boolean;

    readonly colorArrayUrl: string;

    readonly colorValueSummaries: ColorValueSummaries;

    readonly colorValueSummariesValid: boolean;

    readonly manuallyDownloadColorArrayUrlAndSetArray?: boolean; // This is needed because with blockmodels, XYZ doesn't expose the downloaded array, so we will download it manually and set the array so the rest of Driver code has access to the data array.

    readonly lastManuallydownloadedColorArrayUrl?: string;
}

export type XyzViewStateWithColorData = PointsViewState | LocalEllipsoidsViewState | StructuralViewState;
export class XyzColorTrace extends XyzReduxSnapshot implements XyzColorTraceSnapshot {
    readonly colorAttributeName: string;

    readonly spotLightAttributeName?: string;

    readonly useSingleColor: boolean;

    readonly xyz: UseXyz;

    readonly parentTrace: XyzTraceWithColorAttributes;

    readonly colorArrayUrl: string;

    readonly colorValueSummaries: ColorValueSummaries;

    readonly colorValueSummariesValid: boolean;

    readonly manuallyDownloadColorArrayUrlAndSetArray?: boolean; // This is needed because with blockmodels, XYZ doesn't expose the downloaded array, so we will download it manually and set the array so the rest of Driver code has access to the data array.

    readonly lastManuallydownloadedColorArrayUrl?: string;

    constructor(snapshot: XyzColorTraceSnapshot, parentTrace: XyzTraceWithColorAttributes, xyz: UseXyz) {
        super();
        Object.assign(this, snapshot);
        this.parentTrace = parentTrace;
        this.xyz = xyz;
        this.manuallyDownloadColorArrayUrlAndSetArray = true; // This is needed because with blockmodels, XYZ doesn't expose the downloaded array, so we will download it manually and set the array so the rest of Driver code has access to the data array. Also other object types also don't handle the color arrayUrl update correctly.
    }

    createInitialColorDataSnapshot = (): UpdateSnapshot => {
        if (this.useSingleColor) {
            return {};
        } else {
            const xyzState = this.xyz.getState();

            const newArrayUrl = this.parentTrace.urlWithObjectHash(this.colorArrayUrl);
            const retSnapshot: UpdateSnapshot = {
                [this.colorDataEntityId()]: {
                    array: this.colorDataArrayEntityId(),
                    // filter: undefined,
                    // mapping: undefined,
                    __class__: SceneClass.Data,
                },
                [this.colorDataArrayEntityId()]: {
                    arrayUrl: undefined,
                    array: undefined,
                    __class__: ArrayClass.Scalar,
                },
                [this.colorDataFilterEntityId()]: {
                    // min: undefined,
                    // max: undefined,
                    __class__: FilterClass.Numeric,
                },
                [this.colorDataMappingEntityId()]: {
                    __class__: MappingClass.Continuous,
                    // gradient: DEFAULT_XYZ_COLORMAP,
                    gradient_control_values: XYZ_MAPCONTINUOUS_GRADIENT_CONTROL_VALUES,
                    visibility: XYZ_MAPCONTINUOUS_VISIBILITY,
                },
            };

            if (this.manuallyDownloadColorArrayUrlAndSetArray) {
                const oldColorDataArrayEntity = xyzState[this.colorDataArrayEntityId()] as ArrayScalarState;
                if (newArrayUrl !== this.lastManuallydownloadedColorArrayUrl || !oldColorDataArrayEntity.array) {
                    // void dispatch(this.downloadColorArrayUrlAndSetColorArray(newArrayUrl));
                    const newColorDataArrayEntity = retSnapshot[this.colorDataArrayEntityId()] as ArrayScalarState;
                    newColorDataArrayEntity.array = null;
                    newColorDataArrayEntity.arrayUrl = null;
                } else {
                    // There is already an array and the arrayUrl is not different so we don't do anything.
                }
            } else {
                const newColorDataArrayEntity = retSnapshot[this.colorDataArrayEntityId()] as ArrayScalarState;
                newColorDataArrayEntity.array = null;
                newColorDataArrayEntity.arrayUrl = newArrayUrl;
            }

            if (!xyzState[this.colorDataMappingEntityId()]) {
                // Set default gradient if it hasn't been set before.
                const newColorDataMappingEntity = retSnapshot[
                    this.colorDataMappingEntityId()
                ] as ContinuousMappingState;
                newColorDataMappingEntity.gradient = DEFAULT_XYZ_COLORMAP;
            }
            return retSnapshot;
        }
    };

    getColor = (): Vector3 => {
        if (!this.getUseSingleColor()) {
            return undefined;
        }
        if ((this.parentTrace as LvaTrace).selectedDisplayShape === AnisotropyDisplayShapes.DISCS) {
            return (this.viewEntity() as StructuralViewState)?.positiveColor;
        }
        return (this.viewEntity() as LocalEllipsoidsViewState)?.color;
    };

    getStatus = (): XyzTraceStatusDetails => {
        const status = this.viewEntity()?.status ?? {};
        return {
            status: mostSevereXYZStatus({ ...status }),
            statuses: { ...status },
        };
    };

    getDrillAttribute = () => this.colorAttributeName;

    getSpotLightAttribute = () => this.spotLightAttributeName;

    getUseSingleColor = () => this.useSingleColor;

    hasColorMap = () => !this.useSingleColor;

    getColorMap = () => {
        if (this.getUseSingleColor()) {
            return undefined;
        }
        return this.colorDataMappingEntity()?.gradient;
    };

    getColorMapRange = () => {
        if (this.getUseSingleColor()) {
            return undefined;
        }
        const dataControlValues = this.colorDataMappingEntity()?.data_control_values;

        if (!dataControlValues) {
            return dataControlValues2colorMapRange(DEFAULT_XYZ_DATA_CONTROL_VALUES);
        }

        return dataControlValues2colorMapRange(dataControlValues);
    };

    getDisplayRange = () => {
        if (this.getUseSingleColor()) {
            return undefined;
        }

        const colorDataFilterEntity = this.colorDataFilterEntity();

        if (!colorDataFilterEntity) {
            return {
                min: 0,
                max: 1,
            };
        }

        return {
            min: colorDataFilterEntity.min,
            max: colorDataFilterEntity.max,
        };
    };

    getOpacity = () => this.viewEntity()?.opacity;

    getGradientControlValues = () =>
        this.colorDataMappingEntity?.()?.gradient_control_values ?? DEFAULT_XYZ_GRADIENT_CONTROL_VALUES;

    entityIdPrefix = (): string => `${this.parentTrace.entityIdPrefix()}:${this.colorAttributeName}`;

    viewEntityId = () => this.parentTrace.viewEntityId();

    private viewEntity = (): XyzViewStateWithColorData => {
        return this.xyz?.getEntityState?.(this.viewEntityId()) as XyzViewStateWithColorData;
    };

    colorDataEntityId = (): string => (this.hasColorMap() ? `${this.entityIdPrefix()}:ColorData` : undefined);

    private colorDataEntity = (): DataState =>
        this.hasColorMap() ? (this.xyz.getEntityState(this.colorDataEntityId()) as DataState) : undefined;

    colorDataArrayEntityId = (): string =>
        this.hasColorMap() ? `${this.entityIdPrefix()}:ColorData:Array` : undefined;

    colorDataArrayEntity = (): ArrayScalarState =>
        this.hasColorMap() ? (this.xyz.getEntityState(this.colorDataArrayEntityId()) as ArrayScalarState) : undefined;

    colorDataFilterEntityId = (): string =>
        this.hasColorMap() ? `${this.entityIdPrefix()}:ColorData:Filter` : undefined;

    private colorDataFilterEntity = (): NumericFilterState =>
        this.hasColorMap()
            ? (this.xyz.getEntityState(this.colorDataFilterEntityId()) as NumericFilterState)
            : undefined;

    colorDataMappingEntityId = (): string =>
        this.hasColorMap() ? `${this.entityIdPrefix()}:ColorData:Mapping` : undefined;

    private colorDataMappingEntity = (): ContinuousMappingState =>
        this.hasColorMap()
            ? (this.xyz.getEntityState(this.colorDataMappingEntityId()) as ContinuousMappingState)
            : undefined;

    isVisible = () => this.parentTrace.isVisible();

    updateDisplayRange = async (dispatch: AppDispatch, displayRange: Partial<MinMaxRange>) => {
        const newDataFilterEntity: Partial<MinMaxRange> = {};
        if (displayRange.min !== undefined) {
            newDataFilterEntity.min = displayRange.min;
        }
        if (displayRange.max !== undefined) {
            newDataFilterEntity.max = displayRange.max;
        }
        const updateSnapshot: UpdateSnapshot = {
            [this.colorDataFilterEntityId()]: {
                ...newDataFilterEntity,
                __class__: FilterClass.Numeric,
            },
        };
        const colorDataEntity = this.colorDataEntity();
        if (colorDataEntity?.filter !== this.colorDataFilterEntityId()) {
            updateSnapshot[this.colorDataEntityId()] = {
                ...colorDataEntity,
                filter: this.colorDataFilterEntityId(),
            };
        }
        await this.xyz.updateVisualizationWithoutTween(updateSnapshot);
    };

    updateColorMap = async (colorMap: XyzPredefinedColorMaps) => {
        const updateSnapshot: UpdateSnapshot = {
            [this.colorDataMappingEntityId()]: {
                gradient: colorMap,
            },
        };
        await this.xyz.updateVisualizationWithoutTween(updateSnapshot);
    };

    updateColorMapRange = async (dispatch: AppDispatch, colorMapRange: Partial<MinMaxRange>) => {
        const dataControlValues = this.getColorMapRange();

        const min = colorMapRange?.min ?? dataControlValues.min;
        const max = colorMapRange?.max ?? dataControlValues.max;

        const updateSnapshot: UpdateSnapshot = {
            [this.colorDataMappingEntityId()]: {
                data_control_values: colorMapRange2dataControlValues({ min, max }),
            },
        };

        const colorDataEntity = this.colorDataEntity();
        if (colorDataEntity?.mapping !== this.colorDataMappingEntityId()) {
            updateSnapshot[this.colorDataEntityId()] = {
                ...colorDataEntity,
                mapping: this.colorDataMappingEntityId(),
            };
        }

        await this.xyz.updateVisualizationWithoutTween(updateSnapshot);
    };

    updateAndValidateColorValueSummaries = async (dispatch: AppDispatch, colorValueSummaries: ColorValueSummaries) => {
        dispatch(
            this.updateReduxState(
                produce<XyzColorTraceSnapshot>((oldColorAttributeTraceSnapshot) => {
                    oldColorAttributeTraceSnapshot.colorValueSummaries = colorValueSummaries;
                    oldColorAttributeTraceSnapshot.colorValueSummariesValid = true;
                })
            )
        );
        await this.updateDisplayRange(dispatch, {
            min: colorValueSummaries.min,
            max: colorValueSummaries.max,
        });
        await this.updateColorMapRange(dispatch, {
            min: colorValueSummaries.min,
            max: colorValueSummaries.max,
        });
    };

    inValidateColorValueSummaries = async (dispatch: AppDispatch) => {
        if (!this.colorValueSummariesValid) {
            return;
        }
        dispatch(
            this.updateReduxState(
                produce<XyzColorTraceSnapshot>((oldColorAttributeTraceSnapshot) => {
                    oldColorAttributeTraceSnapshot.colorValueSummariesValid = false;
                })
            )
        );
    };

    getColorValueSummaries = () => (this.getColorValueSummariesValid() ? this.colorValueSummaries : undefined);

    getColorValueSummariesValid = () => this.colorValueSummariesValid;

    downloadColorArrayUrlAndSetColorArray = (
        inValidateAllColorValueSummaries: InValidateAllColorValueSummaries,
        tokenProvider: () => Promise<string>
    ) => {
        return async (dispatch: AppDispatch, getState: AppStoreStateGetter) => {
            if (this.useSingleColor) {
                return;
            }

            const parentTrace = createXyzTraceObjectFromSnapshot(
                this.xyz,
                selectXyzTrace(getState(), this.parentTrace.id),
                tokenProvider
            ) as XyzTraceWithColorAttributes; // parent trace might have changed before we call this! So we can't just use this.parentTrace!

            if (!this.manuallyDownloadColorArrayUrlAndSetArray) {
                return;
            }

            const arrayUrl = parentTrace.urlWithObjectHash(this.colorArrayUrl);
            const oldColorDataArrayEntity = this.colorDataArrayEntity() as ArrayScalarState;
            if (arrayUrl === this.lastManuallydownloadedColorArrayUrl && oldColorDataArrayEntity.array) {
                return;
            }

            if (parentTrace.hidePlotWhenColorsPending) {
                await parentTrace.updateViewVisiblity(false);
            }

            const headers = { Authorization: `Bearer ${await tokenProvider()}` };

            const array = await fetchBinaryArray(arrayUrl, undefined, headers);
            const updateSnapshot: UpdateSnapshot = {
                [this.colorDataArrayEntityId()]: {
                    array: array,
                    __class__: ArrayClass.Scalar,
                },
            };
            await this.xyz.updateVisualizationWithoutTween(updateSnapshot);

            await dispatch(parentTrace.resetViewVisibilityBasedOnLatestVisibility(parentTrace.tokenProvider));
            if (inValidateAllColorValueSummaries === InValidateAllColorValueSummaries.Yes) {
                parentTrace.inValidateAllColorValueSummaries(dispatch);
            }

            dispatch(
                this.updateReduxState(
                    produce<XyzColorTraceSnapshot>((oldXyzColorTraceSnapshot) => {
                        oldXyzColorTraceSnapshot.lastManuallydownloadedColorArrayUrl = arrayUrl;
                    })
                )
            );
        };
    };

    snapshotPath = (): string[] => [this.parentTrace.id, 'colorAttributeTraces', this.colorAttributeName];

    takeSnapshot = (): XyzColorTraceSnapshot => ({
        colorAttributeName: this.colorAttributeName,
        spotLightAttributeName: this.spotLightAttributeName,
        useSingleColor: this.useSingleColor,
        colorArrayUrl: this.colorArrayUrl,
        colorValueSummaries: this.colorValueSummaries,
        colorValueSummariesValid: this.colorValueSummariesValid,
        manuallyDownloadColorArrayUrlAndSetArray: this.manuallyDownloadColorArrayUrlAndSetArray,
        lastManuallydownloadedColorArrayUrl: this.lastManuallydownloadedColorArrayUrl,
    });
}
