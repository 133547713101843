import { BackendJsonifiedProjectObject } from 'App/util/ProjectDataTypes/BackendJsonifiedProjectObject';
import { ProjectTreeNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';

export const sortProjectTreeNodesByCreatedAt = (
    projectTreeNode1: ProjectTreeNode,
    projectObject1: BackendJsonifiedProjectObject,
    projectTreeNode2: ProjectTreeNode,
    projectObject2: BackendJsonifiedProjectObject
) => {
    if (projectObject1?.created_at && projectObject2?.created_at) {
        const x = Date.parse(projectObject1?.created_at);
        const y = Date.parse(projectObject2?.created_at);
        const m = projectTreeNode1?.section;
        const n = projectTreeNode2?.section;
        return x < y ? -1 : x > y ? 1 : m < n ? -1 : 1;
    }
};
