import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { anisotropyIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function AnisotropyIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={anisotropyIconDataCy}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <path
                    fill="currentColor"
                    d="M11.81,0.96L11.81,0.96c0.47,0,1.31,0.08,1.67,0.59c0.82,1.17-0.87,4.3-4.36,6.73
		c-2.12,1.49-4.42,2.37-6.14,2.37c-0.47,0-1.31-0.08-1.67-0.59C0.5,8.89,2.19,5.77,5.67,3.33C7.79,1.85,10.09,0.96,11.81,0.96
		 M11.81-0.04c-1.85,0-4.34,0.89-6.72,2.55c-3.81,2.67-5.87,6.3-4.6,8.12c0.48,0.69,1.37,1.02,2.49,1.02c1.85,0,4.34-0.89,6.72-2.55
		c3.81-2.67,5.87-6.3,4.6-8.12C13.83,0.29,12.94-0.04,11.81-0.04L11.81-0.04z"
                />
                <path
                    fill="currentColor"
                    d="M7.9,15.44L7.9,15.44c0.08,0,0.11,0.02,0.12,0.02c0.17,0.08,0.35,0.5,0.35,1.22
		c-0.01,0.92-0.31,2.08-0.84,3.18C6.49,22,5.11,23,4.51,23c-0.08,0-0.11-0.02-0.12-0.02c-0.17-0.08-0.35-0.5-0.35-1.22
		c0.01-0.92,0.31-2.08,0.84-3.18C5.92,16.44,7.3,15.44,7.9,15.44 M7.9,14.44c-1.22,0-2.85,1.49-3.92,3.7
		c-1.25,2.57-1.26,5.14-0.03,5.73C4.12,23.96,4.31,24,4.51,24c1.22,0,2.85-1.49,3.92-3.7c1.25-2.57,1.26-5.14,0.03-5.73
		C8.29,14.48,8.1,14.44,7.9,14.44L7.9,14.44z"
                />
                <path
                    fill="currentColor"
                    d="M14.87,10.5c1.18,0,2.58,0.34,3.94,0.95c3.08,1.39,4.5,3.49,4.13,4.33c-0.2,0.45-0.98,0.72-2.04,0.72
		c-1.18,0-2.58-0.34-3.94-0.95c-1.43-0.65-2.7-1.55-3.46-2.49c-0.6-0.74-0.85-1.42-0.66-1.84C13.04,10.78,13.82,10.5,14.87,10.5
		 M14.87,9.5c-1.45,0-2.56,0.44-2.95,1.31c-0.74,1.64,1.33,4.17,4.63,5.65c1.54,0.69,3.08,1.04,4.35,1.04
		c1.45,0,2.56-0.44,2.95-1.31c0.74-1.64-1.33-4.17-4.63-5.65C17.68,9.84,16.15,9.5,14.87,9.5L14.87,9.5z"
                />
            </svg>
        </SvgIcon>
    );
}

export default AnisotropyIcon;
