import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { tss } from 'tss-react/mui';
import { plusAddToViewIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

const useStyles = tss.create(() => ({
    root: {
        // fill: '#265C7F',
    },
}));
export const PlusAddToViewIcon = (props: SvgIconProps) => {
    const { classes } = useStyles();

    return (
        <SvgIcon className={classes.root} {...props} data-cy={plusAddToViewIconDataCy}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.15039 11.4004C9.18796 11.4004 11.6504 8.93796 11.6504 5.90039C11.6504 2.86282 9.18796 0.400391 6.15039 0.400391C3.11282 0.400391 0.650391 2.86282 0.650391 5.90039C0.650391 8.93796 3.11282 11.4004 6.15039 11.4004ZM6.15039 10.7754C8.84278 10.7754 11.0254 8.59278 11.0254 5.90039C11.0254 3.208 8.84278 1.02539 6.15039 1.02539C3.458 1.02539 1.27539 3.208 1.27539 5.90039C1.27539 8.59278 3.458 10.7754 6.15039 10.7754ZM6.52539 5.40039V3.27539H5.77539V5.40039H3.65039L3.65039 6.15039H5.77539V8.27539H6.52539V6.15039H8.65039L8.65039 5.40039H6.52539Z"
                    fill="#767375"
                />
            </svg>
        </SvgIcon>
    );
};

export default PlusAddToViewIcon;
