import NewOrdinaryKriging from 'App/MainApp/Dialogs/PointEstimations/NewOrdinaryKriging';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { checkInterpolationItemsDisabled } from 'App/MainApp/TreeView/menu/menuItems/interpolation/pointEstimationButtonsService';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { InterpolationSectionMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { ordinaryKrigingDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function CreateOrdinaryKrigingButton(props: GeneralMenuItemProps) {
    const allObjects = useAppSelector(selectObjectClassname2Id2Obj);
    const validationResult = checkInterpolationItemsDisabled(allObjects);

    const hideInterpolations = useFlags()?.hideInterpolations;

    if (hideInterpolations) {
        return null;
    }

    return (
        <DialogOpenerMenuItem
            name={InterpolationSectionMenuItems.NewOrdinaryKriging}
            key={InterpolationSectionMenuItems.NewOrdinaryKriging}
            dataCy={ordinaryKrigingDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={!validationResult.isValid}
            tooltipText={validationResult.error}
            dialogComponent={NewOrdinaryKriging}
        />
    );
}
