import Grid from '@mui/material/Grid';
import * as React from 'react';

import { useStyles } from './DragTab.styles';
import { DragTabProps } from './DragTab.types';

export default function DragTab({ className, direction = 'row' }: DragTabProps) {
    const { classes } = useStyles();
    const dragTabClassName = className || classes.dragTab;
    const dragTabId = classes.dragTab;

    return (
        <Grid
            item
            container
            direction={direction}
            className={dragTabClassName}
            justifyContent="center"
            alignItems="center"
            id={dragTabId}
        >
            <Grid item className={classes.circle} />
            <Grid item className={classes.circle} />
            <Grid item className={classes.circle} />
            <Grid item className={classes.circle} />
        </Grid>
    );
}
