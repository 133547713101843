import { ObjectIDType, OBJECT_CLASS_NAMES } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';

export interface DomainGridDefinitionType extends BackendJsonifiedProjectObject {
    gridDefinitionId: ObjectIDType;
    domainId: ObjectIDType;
    object_class_name: OBJECT_CLASS_NAMES.Domain_GridDefinition;
}

export class DomainGrid extends BaseProjectObject implements DomainGridDefinitionType {
    gridDefinitionId: ObjectIDType;

    domainId: ObjectIDType;

    object_class_name: OBJECT_CLASS_NAMES.Domain_GridDefinition;

    getName = () => {
        const grid = this.allObjects[OBJECT_CLASS_NAMES.GridDefinition][this.gridDefinitionId];
        const domain = this.allObjects[OBJECT_CLASS_NAMES.Domain][this.domainId];
        return `${grid.name} D[${domain.name}]`;
    };

    getGridDefinition = () => this.projectObjects.getGrid(this.gridDefinitionId);

    getDomain = () => this.projectObjects.getDomain(this.domainId);

    getDomainName = () => this.getDomain().getName();

    getBlockSize = () => this.getGridDefinition().getBlockSize();

    getBlockCount = () => this.getGridDefinition().getBlockCount();

    getOrigin = () => this.getGridDefinition().getOrigin();
}
