import CreateNewZones from 'App/MainApp/Dialogs/Zone/CreateNewZones';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { selectActivePointEstimations } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { ZonesSectionMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { newZonesDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function CreateZonesButton(props: GeneralMenuItemProps) {
    const activePointEstimations = useAppSelector(selectActivePointEstimations);
    const disabled = !Object.values(activePointEstimations).length;
    const tooltipText = disabled && 'Requires at least one interpolation object';

    const hideInterpolations = useFlags()?.hideInterpolations;

    if (hideInterpolations) {
        return null;
    }

    return (
        <DialogOpenerMenuItem
            name={ZonesSectionMenuItems.NewZone}
            key={ZonesSectionMenuItems.NewZone}
            dataCy={newZonesDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            tooltipText={tooltipText}
            dialogComponent={CreateNewZones}
        />
    );
}
