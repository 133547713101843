import { Message } from '@local/messages/dist/Messages.types';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { useSelectProjectTreeUnderTreePath } from 'App/Redux/features/globalContext/projectTreeSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import {
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
    ObjectStatusTypes,
    ProjectObject,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { getAddToViewAdditionalParams } from 'App/util/projectObjectsUtils.ts/objectUtils';
import { plotJobObject } from 'Common/DRIVER2XYZ/plotJobObject';
import { TreePathType } from 'Common/types/MainTreePathType';

export function displayObject(
    projectObject: ProjectObject,
    dispatch: AppDispatch,
    xyz: UseXyz,
    currentOrgUuid: string,
    nodeData: ProjectTreeObjectNode,
    addMessage: (message: Message) => void,
    anisotropyClustering: boolean,
    tokenProvider: () => Promise<string>
) {
    if (projectObject.status !== ObjectStatusTypes.SUCCESS) {
        addMessage({
            message: `Cannot display object with status ${projectObject.status}`,
            type: NotificationType.ERROR,
        });
        return;
    }
    const additionalParams = getAddToViewAdditionalParams(projectObject, nodeData);

    if (projectObject.object_class_name === OBJECT_CLASS_NAMES.Zone) {
        const domainResultMetaData = projectObject.result_meta_data;
        if (domainResultMetaData?.num_samples === 0) {
            addMessage({
                message: `Cannot display an empty zone!`,
                type: NotificationType.ERROR,
            });
            return;
        }
    }

    return dispatch(
        plotJobObject(projectObject, xyz, currentOrgUuid, additionalParams, anisotropyClustering, tokenProvider)
    );
}

export function displayObjectFromDrop(
    addMessage: (message: Message) => void,
    allObjects: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    xyz: UseXyz,
    treePath: TreePathType,
    nodeData: ProjectTreeObjectNode,
    anisotropyClustering: boolean,
    tokenProvider: () => Promise<string>
) {
    return async function addObjectToViewThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        let node = nodeData;

        if (!nodeData) {
            node = useSelectProjectTreeUnderTreePath(treePath)(getState()) as ProjectTreeObjectNode;
        }

        const projectObject = allObjects[node.className][node.id];
        await displayObject(
            projectObject,
            dispatch,
            xyz,
            currentOrgUuid,
            node,
            addMessage,
            anisotropyClustering,
            tokenProvider
        );
    };
}
