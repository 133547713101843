import { hasSessionChanged } from 'App/Login/Session';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { setLaunchDarklyContext } from 'Common/api/launchDarkly';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React from 'react';

/*
scenarios:

in this tab:
-  user just logged in in this tab. they are redirected to the main app page
   and on main page load we set user in Launch Darkly
-  user logs out in this tab. then user is redirected to another page and
   Launch Darkly context doesn't matter anymore
-  token expired (and can't be refreshed); need to set user to anonymous
   in Launch Darkly - this is taken care of outside of this hook

in another tab:
-  token expired (and can't be refreshed) in this tab; user signs in in another tab and
   we need to react to local storage change and set user in Launch Darkly
-  user logs out in another tab. we need to react to local storage change and
   set user in Launch Darkly to anonymous
 */
export function useLaunchDarkly(currentOrgUuid: ObjectIDType | null) {
    const ldClient = useLDClient();

    const setAnonymousUser = React.useCallback(() => {
        void setLaunchDarklyContext(ldClient, null);
    }, [ldClient]);

    const setUser = React.useCallback(() => {
        void setLaunchDarklyContext(ldClient, currentOrgUuid);
    }, [ldClient, currentOrgUuid]);

    // set launch darkly user on mount
    // and listen for session changes in other tabs
    React.useEffect(() => {
        if (!ldClient) {
            return;
        }

        setUser();

        function onStorageChangeInOtherTab(event: StorageEvent) {
            if (hasSessionChanged(event)) {
                if (event.newValue === null) {
                    // session was cleared in another tab
                    setAnonymousUser();
                } else {
                    // session has changed in another tab
                    // we don't have guarantees that the new session user has permissions
                    // to the current org, but chances for feature flags to be exploited
                    // are quite low given it's the same browser, so assume it's fine
                    // and worst case user can't modify the project anyway
                    setUser();
                }
            }
        }
        // only reacts to storage changes in other tabs, see MDN
        window.addEventListener('storage', onStorageChangeInOtherTab);

        return () => {
            window.removeEventListener('storage', onStorageChangeInOtherTab);
        };
    }, [ldClient]); // in Launch Darkly, ldClient is a singelton so once initialized it won't change

    return { setAnonymousUser };
}
