import React from 'react';
import { IntlProvider } from 'react-intl';
import setupReduxStore, { AppStore } from 'App/Redux/store';
import { Provider } from 'react-redux';
import { DataContext, UploadInputProps } from 'App/DataContext';
import { ThemeProvider } from '@mui/material/styles';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { darkTheme, theme } from '@local/web-design-system/dist/styles/theme';
import { Messages } from '@local/messages/dist/Messages';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { DisplaySettingsType } from 'App/Settings/DisplaySettings';
import { Manager as SocketIOClientManager } from 'socket.io-client';
import { SocketIORequestProgressSocket } from 'App/MainApp/SocketIO/RequestProgressSocketConnection/RequestProgressSocketConnection';
import { globalDownloadAnchorDataCy, globalUploadInputDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { SocketIOCloneProjectSocket } from 'App/MainApp/SocketIO/CloneProjectSocketConnection/CloneProjectSocketConnection';
import { openURLWithGlobalAnchor } from 'App/MainApp/services/appService';
import { XyzInstanceContextValue } from 'App/MainApp/Visualization/context/XyzInstanceContextValue';
import dynamic from 'next/dynamic';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { appConfig } from 'Common/config';
import { anonymousLDContext } from 'Common/api/launchDarkly';
import { useLoggedInButNoOrgAccess } from 'Common/customHooks/useLoggedInButNoOrgAccess';
import { getCurrentOrgId } from 'Common/api/AuthUtils';
import { tss } from 'tss-react/mui';
import { MessagesProvider } from '@local/messages/dist/MessagesContext';
import { SessionProvider } from '../App/context/SessionContext';

const mainReduxStore = setupReduxStore();
export interface MyAppPropsType {
    Component: React.ComponentType;
    reduxStore?: AppStore;
    pageProps: object;
    axiosDriverFlask?: AxiosDriverFlaskInstance;
    defaultSocketIOClientManager?: SocketIOClientManager;
    defaultSocketIORequestProgressSocket?: SocketIORequestProgressSocket;
    defaultSocketIOCloneProjectSocket?: SocketIOCloneProjectSocket;
    defaultXyzInstanceContextValue?: XyzInstanceContextValue;
    dataContextProps: object;
}

const useStyles = tss.create({
    menuItem: {
        fontWeight: 400,
        lineHeight: '21px',
        fontSize: '13px',
        height: '21px',
        padding: '0px 5px',
        margin: '0px',
    },
});

function MyApp({
    Component,
    reduxStore = mainReduxStore,
    axiosDriverFlask,
    defaultSocketIOClientManager,
    defaultSocketIORequestProgressSocket,
    defaultSocketIOCloneProjectSocket,
    defaultXyzInstanceContextValue,
    pageProps,
    dataContextProps,
}: MyAppPropsType) {
    // we don't want to change currentOrgUuid unless user
    // explicitly changes it to allow different orgs in different tabs
    const currentOrgUuid = getCurrentOrgId();
    const { classes } = useStyles();

    const loggedInButNoAccessToOrg = useLoggedInButNoOrgAccess();

    const globalDownloadHiddenAnchorRef = React.useRef<HTMLAnchorElement>(null);

    const openURL = React.useCallback((url: string, target = '_blank') => {
        openURLWithGlobalAnchor(url, target, globalDownloadHiddenAnchorRef);
    }, []);

    const globalUploadHiddenInputRef = React.useRef<HTMLInputElement>(null);

    const uploadFiles = React.useCallback((uploadInputProps: UploadInputProps) => {
        const onChange = (event: Event) => {
            uploadInputProps.onChange(event.target as HTMLInputElement);
            globalUploadHiddenInputRef.current.value = null;
        };
        globalUploadHiddenInputRef.current.accept = uploadInputProps.accept;
        globalUploadHiddenInputRef.current.onchange = onChange;
        globalUploadHiddenInputRef.current.multiple = uploadInputProps.allowMultipleFiles;
        globalUploadHiddenInputRef.current.click();
    }, []);

    const onUploadHiddenInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
    };

    const [displaySettings, setDisplaySettings] = React.useState<DisplaySettingsType>({
        compass: true,
        inputBackground: '#334c66',
        inputFontColor: '#000000',
        background: [1, 1, 1],
    });

    const [darkMode, setDarkMode] = React.useState(false);

    const [socketIOClientManager, setSocketIOClientManager] =
        React.useState<SocketIOClientManager>(defaultSocketIOClientManager);

    const [socketIORequestProgressSocket, setSocketIORequestProgressSocket] =
        React.useState<SocketIORequestProgressSocket>(defaultSocketIORequestProgressSocket);

    const [socketIOCloneProjectSocket, setSocketIOCloneProjectSocket] = React.useState<SocketIOCloneProjectSocket>(
        defaultSocketIOCloneProjectSocket
    );

    const [xyzInstanceContextValue, setXyzInstanceContextValue] =
        React.useState<XyzInstanceContextValue>(defaultXyzInstanceContextValue);

    return (
        <ThemeProvider theme={darkMode ? darkTheme : theme}>
            <DataContext.Provider
                value={{
                    currentOrgUuid,
                    displaySettings,
                    setDisplaySettings,
                    darkMode,
                    setDarkMode,
                    socketIOClientManager,
                    setSocketIOClientManager,
                    socketIORequestProgressSocket,
                    setSocketIORequestProgressSocket,
                    socketIOCloneProjectSocket,
                    setSocketIOCloneProjectSocket,
                    openURL,
                    uploadFiles,
                    loggedInButNoAccessToOrg,
                    xyzInstanceContextValue,
                    setXyzInstanceContextValue,
                    menuItemClass: classes,
                    ...dataContextProps,
                }}
            >
                <div>
                    <Provider store={reduxStore}>
                        <DndProvider backend={HTML5Backend}>
                            <MessagesProvider>
                                <SessionProvider axiosDriverFlask={axiosDriverFlask}>
                                    <Messages />
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a
                                        hidden
                                        ref={globalDownloadHiddenAnchorRef}
                                        data-cy={globalDownloadAnchorDataCy}
                                    />
                                    <input
                                        type="file"
                                        hidden
                                        multiple
                                        ref={globalUploadHiddenInputRef}
                                        onClick={onUploadHiddenInputClick}
                                        data-cy={globalUploadInputDataCy}
                                    />
                                    <IntlProvider locale="en-us" defaultLocale="en-us">
                                        <Component {...pageProps} />
                                    </IntlProvider>
                                </SessionProvider>
                            </MessagesProvider>
                        </DndProvider>
                    </Provider>
                </div>
            </DataContext.Provider>
        </ThemeProvider>
    );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

// tried to do this like other Evo apps but that causes infinite render loop in App
// ended up going with docs specific for next.js
const AppWithLaunchDarkly = withLDProvider({
    clientSideID: appConfig.launchDarklyClientID,
    context: anonymousLDContext,
})(MyApp) as React.ComponentType<MyAppPropsType>; // launch darkly types are weird/they don't fully support typescript

const MyAppNoSsr = dynamic(() => Promise.resolve(AppWithLaunchDarkly), {
    ssr: false,
});
export default MyAppNoSsr;
