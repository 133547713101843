import { DataContext } from 'App/DataContext';
import { GenericDialogTitles } from 'App/MainApp/Dialogs/Shared/consts';
import { useDialogOpener } from 'App/MainApp/Dialogs/Shared/useDialogOpenerHook';
import SelectObjectsFromEvo from 'App/MainApp/Dialogs/Uploads/Evo/SelectObjectsFromEvo';
import UpdateDrillingPrompt from 'App/MainApp/Dialogs/Uploads/UploadDrilling/UpdateDrillingPrompt';
import UploadDrillingDialog from 'App/MainApp/Dialogs/Uploads/UploadDrilling/UploadDrilling';
import { GooseKnownSchemas } from 'App/MainApp/TreeView/treeData/treeConsts';
import { selectActiveDrilling } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { updateDrillingDialogDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React, { useEffect } from 'react';

export enum UploadButtons {
    EVO = 'Evo',
    LOCAL = 'Local',
}

export interface UploadDrillingConfirmationProps {
    handleClose: () => void;
    source: UploadButtons;
}

function UploadDrillingConfirmation({ handleClose, source }: UploadDrillingConfirmationProps) {
    const [updateConfirmed, setUpdateConfirmed] = React.useState<boolean>(false);
    const drillingObject = useAppSelector(selectActiveDrilling);

    const { openDialog } = useDialogOpener();
    const { uploadFiles } = React.useContext(DataContext);

    const onFileChange = (target: HTMLInputElement) => {
        const { files } = target;
        openDialog({
            dialogComponent: UploadDrillingDialog,
            dialogProps: { file: files[0] },
        });
    };

    const openSelecFile = () => {
        handleClose();
        uploadFiles({ accept: '.csv', onChange: onFileChange, allowMultipleFiles: false });
        setUpdateConfirmed((prev) => !prev);
    };

    const confirmUpdate = () => setUpdateConfirmed(true);

    const handleOnConfirm = source === UploadButtons.EVO ? confirmUpdate : openSelecFile;

    useEffect(() => {
        if (source === UploadButtons.LOCAL && (!drillingObject || (drillingObject && updateConfirmed))) {
            handleClose();
            uploadFiles({ accept: '.csv', onChange: onFileChange, allowMultipleFiles: false });
        }
    }, []);

    if (drillingObject && !updateConfirmed) {
        return (
            <UpdateDrillingPrompt
                title="Upload Drilling"
                submitText="Yes"
                onConfirm={handleOnConfirm}
                handleClose={handleClose}
                onCancel={handleClose}
                dataCy={updateDrillingDialogDataCy}
            />
        );
    }
    return source === UploadButtons.EVO ? (
        <SelectObjectsFromEvo
            gooseObjectAttributes={{
                objectType: GooseKnownSchemas.DownholeIntervals,
            }}
            title={GenericDialogTitles.UploadDrilling}
            handleClose={handleClose}
        />
    ) : (
        <></>
    );
}

export default React.memo(UploadDrillingConfirmation);
