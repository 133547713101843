import { Message } from '@local/messages/dist/Messages.types';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { APIResponseWithProjectUpdate, ProjectUpdateMessageType } from 'App/util/ProjectDataTypes/APIResponseTypes';

export const showWarnings = (
    addMessage: (message: Message) => void,
    projectUpdateJson: APIResponseWithProjectUpdate
) => {
    if (!projectUpdateJson) {
        return;
    }

    let projectUpdateMessage: ProjectUpdateMessageType;

    const projectUpdateMessageStr = projectUpdateJson;
    if (typeof projectUpdateMessageStr === 'string') {
        try {
            projectUpdateMessage = JSON.parse(projectUpdateMessageStr);
            // eslint-disable-next-line no-empty
        } catch {}
    } else {
        projectUpdateMessage = projectUpdateMessageStr as ProjectUpdateMessageType;
    }

    const warnings = projectUpdateMessage.warnings;
    if (warnings && warnings.length > 0) {
        for (let i = 0; i < warnings.length; i++) {
            addMessage({
                message: warnings[i],
                type: NotificationType.WARNING,
            });
        }
    }
};
