import { getSession, hasSessionChanged } from 'App/Login/Session';
import { checkLoggedInButNoAccessToOrg } from 'Common/components/ErrorHandling/NoAccessToOrgDialog';
import React from 'react';

export function useLoggedInButNoOrgAccess() {
    const [loggedInButNoOrgAccess, setLoggedInButNoOrgAccess] = React.useState(false);

    // check current org initially and then listen for changes in other tabs
    React.useEffect(() => {
        setLoggedInButNoOrgAccess(checkLoggedInButNoAccessToOrg());

        function onStorageChangeInOtherTab(event: StorageEvent) {
            if (hasSessionChanged(event)) {
                const session = getSession();

                if (event.newValue === null || !session?.organizations) {
                    // session was cleared in another tab
                    // or session was changed but the organizations were not set yet
                    setLoggedInButNoOrgAccess(false);
                } else {
                    setLoggedInButNoOrgAccess(checkLoggedInButNoAccessToOrg());
                }
            }
        }

        window.addEventListener('storage', onStorageChangeInOtherTab);

        return () => {
            window.removeEventListener('storage', onStorageChangeInOtherTab);
        };
    }, []);

    return loggedInButNoOrgAccess;
}
