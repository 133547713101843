import { AnisotropyCommonCentersData } from 'App/util/ProjectDataTypes/AnisotropyEstimation';
import { OBJECT_CLASS_NAMES, ObjectIDType } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { Dependants } from './utilClasses/Dependants';

export interface AnisotropyGlobalResultMetadata {
    final_parameters?: {};
}

export interface AnisotropyGlobalType extends BackendJsonifiedProjectObject {
    name: string;
    anisotropyEstimationId: ObjectIDType;
    parameters: AnisotropyGlobalParameters;
    result_meta_data: AnisotropyGlobalResultMetadata;
    object_class_name: OBJECT_CLASS_NAMES.AnisotropyGlobal;
}

export type AnisotropyGlobalCentersData = AnisotropyCommonCentersData;

export interface AnisotropyGlobalParameters {
    name_suffix: string;
}

export class AnisotropyGlobal extends BaseProjectObject implements AnisotropyGlobalType {
    anisotropyEstimationId: ObjectIDType;

    parameters: AnisotropyGlobalParameters;

    result_meta_data: AnisotropyGlobalResultMetadata;

    object_class_name: OBJECT_CLASS_NAMES.AnisotropyGlobal;

    getDependants = () => {
        const pointEstimations = this.allObjects[OBJECT_CLASS_NAMES.PointEstimation];

        const dependants = new Dependants();

        Object.values(pointEstimations).forEach((pointEstimation) => {
            if (pointEstimation.anisotropyGlobalId === this.id) {
                dependants.addDependantAndItsDependantsRaw(pointEstimation, this.axiosDriverFlask, this.allObjects);
            }
        });

        return dependants;
    };

    getAnisotropyEstimaton = () => this.projectObjects.getAnisotropyEstimation(this.anisotropyEstimationId);

    getDomain = () => {
        const anisotropyEstimation = this.getAnisotropyEstimaton();
        return anisotropyEstimation.getDomain();
    };

    getDomainName = () => this.getDomain().getName();
}
