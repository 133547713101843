import { tss } from 'tss-react/mui';

const DRAG_TAB_HEIGHT = 12;

const styles = ({ theme }) =>
    ({
        circle: {
            height: 4,
            width: 4,
            borderRadius: 4,
            backgroundColor: theme.palette.grey[500],
            margin: theme.spacing(0.25),
        },
        dragTab: {
            width: '100%',
            height: DRAG_TAB_HEIGHT,
            background: theme.palette.grey[100],
            cursor: 'move',
            border: `1px solid ${theme.palette.grey[300]}`,
        },
    }) as const;

export const useStyles = tss.create(styles);
