import {
    ProjectTreeExpandedNodesType,
    selectProjectTreeExpandedNodes,
    setProjectTreeExpandedNodes,
} from 'App/Redux/features/globalContext/projectTreeExpandedNodesSlice';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import { ProjectTreeSource } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { produce } from 'immer';
import React from 'react';

export default function useExpandedNodes() {
    const [forceTreeOpen, setForceTreeOpen] = React.useState(false);

    const expandedNodesMap = useAppSelector(selectProjectTreeExpandedNodes);

    // const mainTree = useAppSelector(selectProjectTree);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        setForceTreeOpen(!!new URLSearchParams(window.location.search).get('force_all_trees_open'));
    }, []);

    // const closeAllChildren = (
    //     nodeId: ObjectIDType,
    //     oldExpandedNodesMap: ProjectTreeExpandedNodesType,
    //     isExpanded: boolean
    // ) => {
    //     const node = getNodeFromNodeId(mainTree, nodeId);
    //     const childrenNodeIds = getAllChildrenNodeIds(node);
    //     for (const childNodeId of childrenNodeIds) {
    //         oldExpandedNodesMap[childNodeId] = isExpanded;
    //     }
    //     return oldExpandedNodesMap;
    // };

    const onExpandChange = (
        nodeIds: ObjectIDType[],
        isExpanded: boolean,
        treeSource: ProjectTreeSource = ProjectTreeSource.MAIN_TREE,
        reset: boolean = false
    ) => {
        const newExpandedNodesMap = produce(expandedNodesMap, (oldExpandedNodesMap: ProjectTreeExpandedNodesType) => {
            if (!oldExpandedNodesMap[treeSource]) {
                oldExpandedNodesMap[treeSource] = {};
            }

            if (reset) {
                Object.keys(oldExpandedNodesMap[treeSource]).forEach((nodeId) => {
                    oldExpandedNodesMap[treeSource][nodeId] = false;
                });
            }

            for (const nodeId of nodeIds) {
                // if the node is being collapsed, close all its children
                // if(!isExpanded){
                //     oldExpandedNodesMap = closeAllChildren(nodeId, oldExpandedNodesMap, isExpanded);
                // }else{

                oldExpandedNodesMap[treeSource][nodeId] = isExpanded;
                // }
            }
        });
        dispatch(setProjectTreeExpandedNodes(newExpandedNodesMap));
    };

    const isExpanded = (nodeId: ObjectIDType, treeSource: ProjectTreeSource = ProjectTreeSource.MAIN_TREE) => {
        if (forceTreeOpen) {
            return true;
        }

        if (expandedNodesMap[treeSource] && expandedNodesMap[treeSource][nodeId]) {
            return expandedNodesMap[treeSource][nodeId];
        }

        return false;
    };

    const collapseAll = (treeSource: ProjectTreeSource = ProjectTreeSource.MAIN_TREE) => {
        const newExpandedNodesMap = produce(expandedNodesMap, (oldExpandedNodesMap: ProjectTreeExpandedNodesType) => {
            if (oldExpandedNodesMap[treeSource]) {
                oldExpandedNodesMap[treeSource] = {};
            }
        });

        dispatch(setProjectTreeExpandedNodes(newExpandedNodesMap));
    };

    return {
        onExpandChange,
        isExpanded,
        collapseAll,
    };
}
