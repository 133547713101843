import React from 'react';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import {
    selectActiveGrids,
    selectCurrentProjectId,
    selectObjectByClassnameAndId,
    selectObjectClassname2Id2Obj,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { PlusIcon, SaveIcon } from '@local/web-design-system/dist/icons';
import IconButton from '@mui/material/IconButton';
import { tss } from 'tss-react/mui';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import { selectProjectTreeUnderPath } from 'App/Redux/features/globalContext/projectTreeSlice';
import WidgetTextField from 'Common/components/Core/WidgetTextField';
import WidgetSelect from 'Common/components/Core/WidgetSelect';
import ErrorLabel from 'Common/components/ErrorHandling/ErrorLabel';
import { zonesGridsSelector } from 'App/Redux/utils';
import { cancelButtonDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { alphabeticSorter } from 'Common/utils/utils';
import SecondaryButton from 'Common/components/Core/SecondaryButton';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import {
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
    ObjectIDType,
    ObjectStatusTypes,
} from '../../../util/ProjectDataTypes/ProjectObjectsDataTypes';
import { ProjectTreeSource, Subsections } from '../../../util/ProjectDataTypes/MainTreeDataTypes';
import {
    ObjectsVolumesHvolumesType,
    addOverlapToView,
    formatOverlaps,
    getCombinations,
    saveOverlapToFolder,
} from './OverlapPanelUtils';
import { fromHumanReadableNumber } from '../../../util/core';
import { DataContext } from '../../../DataContext';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import GenericDraggableShell from 'Common/components/GenericDraggable/GenericDraggableShell';
import { useDefaultPosition } from '../Shared/useDefaultPositionHook';
import { getActiveObjects } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { useSelectableTree } from 'App/MainApp/TreeView/components/checkboxTree/useSelectableTree';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useSessionContext } from 'App/context/SessionContext';
import ProjectTree from 'App/MainApp/TreeView/components/ProjectTree/ProjectTree';
import { getFirstLevelNodeIds } from 'App/MainApp/TreeView/treeUtils';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create(() => ({
    // style for zones tree
    tree: {
        height: 400,
        flexGrow: 1,
        overflowY: 'auto',
    },
    tooSmallOverlapMenuItem: {
        color: 'red',
    },
    textAlignRight: {
        textAlign: 'right',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    root: {
        position: 'absolute',
        width: '750px',
        zIndex: '1300',
        top: 30,
        left: 30,
    },
}));

function getZonesDomainGridsTree(
    selectedGridId: ObjectIDType,
    allObjects: ObjectClassName2Id2Obj,
    zonesSection: Subsections
) {
    const zonesInSelectedGrid = zonesSection?.[selectedGridId];

    const domainGridDefinitions = getDomainGridDefinitionsForGrid(allObjects, selectedGridId);

    const newZonesAndDomainGridDefinitionsTree: Subsections = {
        [SectionIds[SectionNames.Zones]]: {
            section: SectionNames.Zones,
            dataCy: SectionNames.Zones,
            nodeId: SectionIds[SectionNames.Zones],
            path: [],
            subsections: {
                [selectedGridId]: {
                    ...zonesInSelectedGrid,
                    canDisplaySpotlight: false,
                },
            },
        },
        [SectionIds[SectionNames.ClosedMeshes]]: {
            section: SectionNames.ClosedMeshes,
            dataCy: SectionNames.ClosedMeshes,
            nodeId: SectionIds[SectionNames.ClosedMeshes],
            subsections: domainGridDefinitions,
            path: [],
        },
    };

    return newZonesAndDomainGridDefinitionsTree;
}

function getDomainGridDefinitionsForGrid(allObjects: ObjectClassName2Id2Obj, gridId: ObjectIDType): Subsections {
    const domainGrids = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.Domain_GridDefinition);

    const domainGridDefinitions = {};

    domainGrids.forEach((domainGrid) => {
        if (domainGrid.gridDefinitionId === gridId) {
            const domainGridInstance = wrapObjectInClass(domainGrid, null, allObjects);
            const domain = domainGridInstance.getDomain();
            domainGridDefinitions[domainGrid.id] = {
                dataCy: domain.name,
                rightClickMenu: [],
                section: domain.name,
                nodeId: `domain-grid-${domain.id}`,
                id: domainGrid.id,
                className: domainGrid.object_class_name,
            };
        }
    });

    return domainGridDefinitions;
}

const MAX_CHECKED_ITEMS_ALLOWED = 8;
const TOO_MANY_ITEMS_ERROR_MESSAGE = `At most ${MAX_CHECKED_ITEMS_ALLOWED} items can be selected`;

export type CreateOverlapsProps = { handleClose: () => void; overlapsFolderId: ObjectIDType };

export default function OverlapsContent(props: CreateOverlapsProps) {
    const { addMessage } = useDriverMessagesContext();
    const { classes } = useStyles();
    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const xyz = useXyz();

    const [saveToFolderDisabled, setSaveToFolderDisabled] = React.useState<boolean>(false);

    const activeGrids = Object.values(useAppSelector(selectActiveGrids)).sort((a, b) =>
        alphabeticSorter(a.name, b.name)
    );

    const dispatch = useAppDispatch();

    const [objectsVolumesHvolumes, setObjectsVolumesHvolumes] = React.useState<ObjectsVolumesHvolumesType>([]);
    const [selectedOverlapCombination, setSelectedOverlapCombination] = React.useState('');
    const [selectedGridId, setSelectedGridId] = React.useState<string>(activeGrids?.[0].id || '');
    const [minOverlapVolume, setMinOverlapVolume] = React.useState(0);

    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const allObjects = useAppSelector(selectObjectClassname2Id2Obj);
    const overlapsFolder = useAppSelector(
        selectObjectByClassnameAndId(OBJECT_CLASS_NAMES.OverlapFolder, props.overlapsFolderId)
    );

    const zonesSection = useAppSelector(selectProjectTreeUnderPath(zonesGridsSelector)) as Subsections;

    const zonesDomainGridsTree = React.useMemo(() => {
        return getZonesDomainGridsTree(selectedGridId, allObjects, zonesSection);
    }, [selectedGridId, allObjects, zonesSection]);

    const { onChange, nodeSelectionChecker, selectedLeafNodes } = useSelectableTree(zonesDomainGridsTree, null, null);

    const { onExpandChange } = useExpandedNodes();

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const { selectedZonesMap, selectedDomainGridsMap, selectedZoneIds, selectedDomainGridIds } = React.useMemo(() => {
        const selectedZonesMap = {};
        const selectedDomainGridsMap = {};

        selectedLeafNodes.forEach((node) => {
            if (node.className === OBJECT_CLASS_NAMES.Zone) {
                selectedZonesMap[node.id] = allObjects[OBJECT_CLASS_NAMES.Zone][node.id];
            } else if (node.className === OBJECT_CLASS_NAMES.Domain_GridDefinition) {
                selectedDomainGridsMap[node.id] = allObjects[OBJECT_CLASS_NAMES.Domain_GridDefinition][node.id];
            }
        });

        const selectedZoneIds = Object.keys(selectedZonesMap);
        const selectedDomainGridIds = Object.keys(selectedDomainGridsMap);

        return { selectedZonesMap, selectedDomainGridsMap, selectedZoneIds, selectedDomainGridIds };
    }, [selectedLeafNodes, allObjects]);

    const tooManyZonesSelected = selectedZoneIds.length + selectedDomainGridIds.length > MAX_CHECKED_ITEMS_ALLOWED;

    /* updates the combinations */
    React.useEffect(() => {
        if (tooManyZonesSelected || selectedGridId === '') {
            return;
        }

        if (selectedZoneIds.length === 0 && selectedDomainGridIds.length === 0) {
            if (objectsVolumesHvolumes.length) {
                setObjectsVolumesHvolumes([]);
            }
            return;
        }

        const abortController = new AbortController();
        getCombinations(
            axiosDriverFlask,
            currentProjectId,
            overlapsFolder.id,
            selectedGridId,
            selectedZoneIds,
            selectedDomainGridIds,
            abortController.signal,
            currentOrgUuid,
            currentWorkspaceId
        )
            .then((res) => {
                setObjectsVolumesHvolumes(res.data.objects_volumes_hvolumes);
            })
            .catch((error: Error | AxiosError) => {
                if (axios.isCancel(error)) {
                    // Aborting a fetch throws an error
                    // So we can't update state afterwards
                    return;
                }

                setObjectsVolumesHvolumes([]);
                addMessage({
                    message: 'Overlap combinations could not be calculated!',
                    type: NotificationType.ERROR,
                });
            });

        return () => {
            abortController.abort();
        };
    }, [selectedZoneIds, selectedDomainGridIds, tooManyZonesSelected]);

    const handleOverlapCombinationChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOverlapCombination(event.target.value);
    }, []);

    const handleGridSelectChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedGridId(event.target.value);
    }, []);

    const effectiveMinOverlapVolume = minOverlapVolume || -Number.MAX_VALUE;

    let numFilteredObjectsVolumesHvolumes = 0;
    if (objectsVolumesHvolumes.length > 0) {
        numFilteredObjectsVolumesHvolumes = objectsVolumesHvolumes.reduce(
            (previousValue: number, currentValue) =>
                previousValue + Number(currentValue[1] >= effectiveMinOverlapVolume && currentValue[1] > 0),
            0
        );
    }

    const minOverlapVolumeTextFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const v = e.target.value;
        if (v === '') {
            setMinOverlapVolume(-Number.MAX_VALUE);
        } else {
            setMinOverlapVolume(fromHumanReadableNumber(v));
        }
    };

    const addToViewButtonClick = async () => {
        if (selectedOverlapCombination) {
            await addOverlapToView(
                overlapsFolder,
                selectedOverlapCombination.split(','),
                selectedGridId,
                dispatch,
                xyz,
                currentOrgUuid
            );
        }
    };
    const saveToFolderButtonClick = async () => {
        if (!selectedOverlapCombination) {
            addMessage({
                message: 'No overlap combination has been selected!',
                type: NotificationType.ERROR,
            });
            return;
        }
        const overlapIndex = objectsVolumesHvolumes.findIndex((v) => v[0].join(',') === selectedOverlapCombination);
        const overlapVolume = objectsVolumesHvolumes[overlapIndex][1];

        setSaveToFolderDisabled(true);
        try {
            await saveOverlapToFolder(
                xyz,
                dispatch,
                axiosDriverFlask,
                overlapsFolder,
                addMessage,
                selectedOverlapCombination.split(','),
                selectedGridId,
                overlapVolume,
                onExpandChange,
                tokenProvider
            );
        } finally {
            setSaveToFolderDisabled(false);
        }
    };

    const title = `Create overlaps: ${overlapsFolder ? `folder ${overlapsFolder.name}` : ''}`;

    const formattedOverlaps = formatOverlaps(
        objectsVolumesHvolumes,
        selectedZonesMap,
        selectedDomainGridsMap,
        allObjects[OBJECT_CLASS_NAMES.Domain]
    );

    const [defaultPosition, createPositionChangeHandler] = useDefaultPosition(null);

    React.useEffect(() => {
        const zoneNodeIds = getFirstLevelNodeIds(zonesDomainGridsTree);
        onExpandChange(zoneNodeIds, true, ProjectTreeSource.OVERLAPS, true);
    }, []);

    return (
        <GenericDraggableShell
            zIndexContext={false}
            divider
            defaultPosition={defaultPosition}
            onCloseClicked={props.handleClose}
            dataCy="overlaps-dialog"
            headerName={title}
            onPositionChange={createPositionChangeHandler}
            classes={{
                root: {
                    [classes.root]: true,
                },
            }}
        >
            <DialogContent>
                <Grid
                    container
                    sx={{
                        paddingTop: 1,
                        paddingBottom: 3,
                    }}
                >
                    <Grid item xs={3}>
                        Choose grid:
                    </Grid>
                    <Grid item xs={9}>
                        <WidgetSelect
                            labelId="grid-select-label"
                            id="grid-select"
                            value={selectedGridId}
                            onChange={handleGridSelectChange}
                            style={{ width: '100%' }}
                            data-cy="overlaps-grid-dropdown"
                        >
                            {activeGrids.map((gridNode) => {
                                const isSuccessfulGrid = gridNode.status === ObjectStatusTypes.SUCCESS;
                                return (
                                    <MenuItem
                                        key={gridNode.id}
                                        value={gridNode.id}
                                        sx={{
                                            color: isSuccessfulGrid ? null : 'red',
                                        }}
                                        disabled={!isSuccessfulGrid}
                                    >
                                        {gridNode.name}
                                    </MenuItem>
                                );
                            })}
                        </WidgetSelect>
                    </Grid>
                </Grid>
                <ProjectTree
                    checkboxTree
                    onChange={onChange}
                    nodeSelectionChecker={nodeSelectionChecker}
                    tree={zonesDomainGridsTree}
                    className={classes.tree}
                    treeSource={ProjectTreeSource.OVERLAPS}
                />
                <Divider />
                <ErrorLabel
                    errorText={TOO_MANY_ITEMS_ERROR_MESSAGE}
                    show={tooManyZonesSelected}
                    dataCy="zones-error-label"
                />
                <Grid container alignItems="center">
                    <Grid item xs={5}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Minimum volumetric size
                            <Tooltip
                                disableInteractive
                                arrow
                                placement="right"
                                title={
                                    <div>
                                        Values are in cubic meters(M3). <br />
                                        Examples: <br />
                                        2000 = 2K = 2k <br />
                                        2000000 = 2M = 2m
                                    </div>
                                }
                            >
                                <IconButton disableTouchRipple size="small">
                                    <InfoOutlinedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item xs={7}>
                        <WidgetTextField
                            inputProps={{
                                autoComplete: 'off',
                            }}
                            error={!minOverlapVolume && minOverlapVolume !== 0}
                            fullWidth
                            id="outlined-required"
                            placeholder="NA"
                            onChange={minOverlapVolumeTextFieldChange}
                            data-cy="overlaps-min-volume-input"
                        />
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={0} alignItems="center">
                    <Grid item flexGrow={1} marginRight="10px">
                        <div>Overlap combinations</div>
                        <WidgetSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedOverlapCombination}
                            onChange={handleOverlapCombinationChange}
                            style={{ width: '100%' }}
                            data-cy="overlaps-dropdown"
                            disabled={tooManyZonesSelected}
                        >
                            {formattedOverlaps.map((overlap) => {
                                const isTooSmallVolume =
                                    overlap.volume < effectiveMinOverlapVolume || overlap.volume === 0;

                                return (
                                    <MenuItem
                                        key={overlap.objectsString}
                                        value={overlap.objectsString}
                                        className={clsx({
                                            [classes.tooSmallOverlapMenuItem]: isTooSmallVolume,
                                        })}
                                    >
                                        {overlap.name}
                                    </MenuItem>
                                );
                            })}
                        </WidgetSelect>
                        <FormHelperText>
                            {' '}
                            {numFilteredObjectsVolumesHvolumes}/{objectsVolumesHvolumes.length} combinations filtered
                        </FormHelperText>
                    </Grid>
                    <Grid item className={classes.textAlignRight}>
                        <Tooltip disableInteractive title="Add to view">
                            <IconButton size="small" onClick={addToViewButtonClick} data-cy="add-to-view-button">
                                <PlusIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item className={classes.textAlignCenter}>
                        <Tooltip disableInteractive title="Save to folder">
                            <IconButton
                                disabled={saveToFolderDisabled}
                                size="small"
                                onClick={saveToFolderButtonClick}
                                data-cy="save-to-folder-button"
                            >
                                <SaveIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogContent>
            <Grid item xs={3} className={classes.textAlignRight} padding="0 15px 15px 0">
                <SecondaryButton onClick={props.handleClose} data-cy={cancelButtonDataCy}>
                    Close
                </SecondaryButton>
            </Grid>
        </GenericDraggableShell>
    );
}
