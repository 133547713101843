/* eslint-disable no-console */
import Logger, { ILogLevel } from 'js-logger';

export function printWithLogLevel(logLevel: ILogLevel, ...args) {
    let hdlr = console.log;
    if (logLevel === Logger.WARN && console.warn) {
        hdlr = console.warn;
    } else if (logLevel === Logger.ERROR && console.error) {
        hdlr = console.error;
    } else if (logLevel === Logger.INFO && console.info) {
        hdlr = console.info;
    } else if (logLevel === Logger.DEBUG && console.debug) {
        hdlr = console.debug;
    } else if (logLevel === Logger.TRACE && console.trace) {
        hdlr = console.trace;
    }
    hdlr(...args);
}

export const LogLevels = {
    TRACE: Logger.TRACE,
    DEBUG: Logger.DEBUG,
    INFO: Logger.INFO,
    WARN: Logger.WARN,
    ERROR: Logger.ERROR,
    OFF: Logger.OFF,
};

export enum Severity {
    Fatal = 'fatal',
    Error = 'error',
    Warning = 'warning',
    Log = 'log',
    Info = 'info',
    Debug = 'debug',
}
