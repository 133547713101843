import { getCommonData } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { ProjectTreeNode, ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { ZoneFromAnisotropyType } from 'App/util/ProjectDataTypes/ZoneFromAnisotropy';
import { zoneObjectMenu } from '../menu/objectMenus';

export function getZoneFromAnisotropyNode(zone: ZoneFromAnisotropyType, parentNode: ProjectTreeNode) {
    const zoneFromAnisotropyNode: ProjectTreeObjectNode = {
        ...getCommonData(zone, parentNode),
        section: zone.name,
        dataCy: zone.name,
        rightClickMenu: zoneObjectMenu,
        canDisplaySpotlight: true,
    };

    return zoneFromAnisotropyNode;
}
