import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    input: {
        backgroundColor: 'white',
        fontSize: '0.75rem',
    },

    errorLabel: {
        position: 'absolute',
        top: '28px',
        background: 'white',
        zIndex: '1',
        margin: '0',
        padding: '6px',
        width: '200px',
        maxWidth: '100%',
        boxShadow: '#c0c0c0 0px 0px 7px',
        borderRadius: '3px',
    },
    rightErrorLabel: {
        top: '-3px',
        left: 'calc(100% + 5px)',
    },
}));

type WidgetTextFieldProps = TextFieldProps & {
    errorLabelOnRight?: boolean;
    incrementStep?: number;
};

function WidgetTextField(props: WidgetTextFieldProps) {
    const { classes } = useStyles();
    const { inputProps, title, errorLabelOnRight, ...restProps } = props;

    let errorLabelClasses = classes.errorLabel;
    if (errorLabelOnRight) {
        errorLabelClasses += ` ${classes.rightErrorLabel}`;
    }

    const errorLabelProps = {
        className: errorLabelClasses,
        'data-cy': 'error-label',
    };

    return (
        <TextField
            variant="outlined"
            size="small"
            autoComplete="off"
            title={title ?? ''}
            FormHelperTextProps={errorLabelProps}
            {...restProps}
            inputProps={{
                className: classes.input,
                step: props.incrementStep,
                ...(inputProps ?? {}),
            }}
        />
    );
}

export default WidgetTextField;
