import { tss } from 'tss-react/mui';

import { fonts } from '@local/web-design-system/dist/styles/fonts';

const styles = ({ theme }) =>
    ({
        root: {
            height: theme.spacing(5),
            padding: theme.spacing(0, 1.5),
        },
        filterHeader: {
            fontSize: 12,
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            fontWeight: fonts.weights.bold,
            letterSpacing: '0.82px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        adornmentCloseButtonWrapper: { flex: 1 },
        closeButton: {
            fontSize: 10,
            padding: theme.spacing(0.5),
        },
    }) as const;

export const useStyles = tss.create(styles);
