import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import React from 'react';
import {
    GooseFile,
    getAllGooseObjects,
    getGooseObjectColumns,
    getGooseObjectType,
} from './SelectObjectsFromEvoService';
import { DataContext } from 'App/DataContext';
import { useAppSelector } from 'App/Redux/hooks';
import { useDialogOpener } from '../../Shared/useDialogOpenerHook';
import UploadDrillingFromEvo from '../UploadDrilling/UploadDrillingFromEvo';
import { importGoDialogDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import List, { ListViewModes } from 'Common/components/List/List';
import { FieldDefinition } from '@local/web-design-system/dist/components/GenericListing/types';
import { DateCell, StringCell } from '@local/web-design-system/dist/components/GenericListing/ListCells';
import { alphabeticSorter } from 'Common/utils/utils';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';

import { tss } from 'tss-react/mui';
import {
    selectCurrentWorkspaceId,
    selectCurrentWorkspaceName,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { GooseKnownSchemas } from 'App/MainApp/TreeView/treeData/treeConsts';
import UploadMeshDialog from '../UploadMesh/UploadMesh';
import { MeshTypes } from 'App/util/ProjectDataTypes/MeshFile';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useSessionContext } from 'App/context/SessionContext';

const useStyles = tss.create(() => ({
    root: {
        minHeight: '450px',
    },
}));

export type GooseObjectAttributes = {
    objectType: GooseKnownSchemas;
    isClosedMesh?: boolean;
};

export default function (props: {
    handleClose: () => void;
    gooseObjectAttributes: GooseObjectAttributes;
    title: string;
}) {
    const { classes } = useStyles();
    const { addMessage } = useDriverMessagesContext();
    const [files, setFiles] = React.useState<GooseFile[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [gooseColumnsLoading, setGooseColumnsLoading] = React.useState(false);
    const [selectedFileId, setSelectedFileId] = React.useState<ObjectIDType>(null);
    const [selectedFileName, setSelectedFileName] = React.useState<string>('');

    const { currentOrgUuid } = React.useContext(DataContext);
    const { axiosDriverFlask } = useSessionContext();
    const { openDialog } = useDialogOpener();

    const gooseObjectAttributes = props.gooseObjectAttributes;
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const currentWorkspaceName = useAppSelector(selectCurrentWorkspaceName);

    const { isDrilling, isTriangleMesh } = getGooseObjectType(gooseObjectAttributes.objectType);

    React.useEffect(() => {
        async function getAllGooseData() {
            setIsLoading(true);
            const filesFromGoose = await getAllGooseObjects(
                axiosDriverFlask,
                currentOrgUuid,
                currentWorkspaceId,
                currentWorkspaceName,
                gooseObjectAttributes.objectType
            );
            setIsLoading(false);
            setFiles(filesFromGoose);
        }
        void getAllGooseData();
    }, []);

    const onFileClick = (fileId: ObjectIDType) => {
        setSelectedFileId(fileId);
        const selectedFile = files.find((file) => file.id === fileId);
        setSelectedFileName(selectedFile.name);
    };

    const selectGooseObject = async () => {
        if (isDrilling) {
            getDrillingGooseObjectColumns();
        } else if (isTriangleMesh) {
            getMeshGooseObject();
        }
    };

    const getMeshGooseObject = () => {
        const isClosedMesh = gooseObjectAttributes.isClosedMesh;

        props.handleClose();

        openDialog({
            dialogComponent: UploadMeshDialog,
            dialogProps: {
                meshType: isClosedMesh ? MeshTypes.closed : MeshTypes.open,
                gooseObjectId: selectedFileId,
                gooseObjectName: selectedFileName,
                handleClose: props.handleClose,
            },
        });
    };

    const getDrillingGooseObjectColumns = () => {
        getGooseObjectColumns(axiosDriverFlask, currentOrgUuid, currentWorkspaceId, selectedFileId)
            .then((response) => {
                if (response.data.success) {
                    const uploadedFileData = response.data;

                    props.handleClose();

                    openDialog({
                        dialogComponent: UploadDrillingFromEvo,
                        dialogProps: {
                            open: true,
                            uploadedFileData: uploadedFileData,
                            workspaceId: currentWorkspaceId,
                            gooseObjectId: selectedFileId,
                            handleClose: props.handleClose,
                        },
                    });
                } else {
                    addMessage({
                        message: 'Upload Drilling from Seequent EVO was unsuccessful. Please contact customer support.',
                        type: NotificationType.ERROR,
                    });
                }
            })
            .catch(() => {})
            .finally(() => {
                setGooseColumnsLoading(false);
            });
        setGooseColumnsLoading(true);
    };

    let disableSubmit = false;
    let showSpinner = false;

    if (isDrilling) {
        showSpinner = gooseColumnsLoading;
        disableSubmit = !selectedFileId || gooseColumnsLoading;
    } else if (isTriangleMesh) {
        showSpinner = false;
        disableSubmit = false;
    }

    return (
        <GenericDialogShell
            title={props.title}
            subtitle="Select a file to import from an Evo Workspace."
            maxWidth="md"
            dataCy={importGoDialogDataCy}
            handleClose={props.handleClose}
        >
            <List
                items={files}
                listFields={fields}
                onItemClick={onFileClick}
                noItemsMessage="No files found"
                searchPlaceholder="Search files"
                selectedItem={selectedFileId}
                title={currentWorkspaceName}
                isLoading={isLoading}
                className={classes.root}
                viewMode={ListViewModes.list}
            />
            <GenericDialogActions
                onSubmit={selectGooseObject}
                onCancel={props.handleClose}
                disabled={disableSubmit}
                showSpinner={showSpinner}
                submitText="Import"
            />
        </GenericDialogShell>
    );
}

const fields: FieldDefinition[] = [
    {
        key: 'name',
        label: 'Name',
        component: StringCell,
        sortFunction: alphabeticSorter,
    } as FieldDefinition<string>,
    {
        key: 'type',
        label: 'Type',
        component: StringCell,
        sortFunction: alphabeticSorter,
        styles: {
            width: '20%',
        },
    } as FieldDefinition<string>,
    {
        key: 'createdAt',
        label: 'Modified on',
        component: DateCell,
        sortFunction: alphabeticSorter,
        styles: {
            width: '15%',
        },
    } as FieldDefinition<string>,
    {
        key: 'createdBy',
        label: 'Modified by',
        component: StringCell,
        sortFunction: alphabeticSorter,
        styles: {
            width: '20%',
        },
    } as FieldDefinition<string>,
];
