import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CoordinateKeys } from 'Common/types/geometryTypes';
import { blocksSizeResetButtonDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { BlockSizesValidationResult, StringCoordinatesObject } from './gridLimitsHelpers';
import ResetButton from './ResetButton';
import LabeledNumberField from '../Shared/LabeledNumberField';

type BlockSizeProps = {
    blockSizes: StringCoordinatesObject;
    generateOnChange: (CoordinateKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    generateOnBlur: (CoordinateKey: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
    blockSizesValidationResult: BlockSizesValidationResult;
    originalBlockSizes: StringCoordinatesObject;
    onBlockSizesReset: () => void;
    classes: Record<'title' | 'boundsRow' | 'textField', string>;
};

const coordinates = [CoordinateKeys.x, CoordinateKeys.y, CoordinateKeys.z];
const titlesMap = {
    [CoordinateKeys.x]: 'X:',
    [CoordinateKeys.y]: 'Y:',
    [CoordinateKeys.z]: 'Z:',
};

function BlockSize({
    blockSizes,
    generateOnBlur,
    generateOnChange,
    blockSizesValidationResult,
    originalBlockSizes,
    onBlockSizesReset,
    classes,
}: BlockSizeProps) {
    return (
        <Grid item container direction="column" data-cy="block-size-form">
            <Grid item container className={classes.title}>
                <Typography fontWeight="bold">Grid spacing</Typography>
                <ResetButton
                    tooltipText="Reset grid spacing to last saved values"
                    onClick={onBlockSizesReset}
                    disabled={false}
                    dataCy={blocksSizeResetButtonDataCy}
                />
            </Grid>

            <Grid item container className={classes.boundsRow}>
                {coordinates.map((coordinateKey) => (
                    <LabeledNumberField
                        title={titlesMap[coordinateKey]}
                        dataCy={`${coordinateKey}-block-size-textbox`}
                        onChange={generateOnChange(coordinateKey)}
                        onBlur={generateOnBlur(coordinateKey)}
                        value={blockSizes[coordinateKey]}
                        boundBounds={{ min: 0 }}
                        placeholder={originalBlockSizes[coordinateKey]}
                        className={classes.textField}
                        validationResult={blockSizesValidationResult[coordinateKey]}
                        key={coordinateKey}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

export default BlockSize;
