import { ElementClass, LinesElementState, LinesViewState, UpdateSnapshot, ViewClass } from '@seequent/xyz';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { addNewXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { AppDispatch } from 'App/Redux/store';
import { Point3D } from 'Common/types/geometryTypes';
import { hexToRgbArray } from 'App/MainApp/Plot/util';
import { XyzTrace, XyzTraceClassNames, XyzTraceSnapshot, XyzTraceStatusDetails, mostSevereXYZStatus } from './XyzTrace';
import { Color } from '@seequent/xyz/lib/types/src/types';
import { getNextMeshColor } from 'App/MainApp/Plot/Colors_Util/colors_helper';
import { LinesMode } from '../XyzShamefullCopy';

export interface LinesTraceSnapshot extends XyzTraceSnapshot {
    /**
     * Snapshot is saved in Redux state. Must not duplicate XYZ state that is already stored in XYZ.
     * I mean when a plot is saved into XYZ, then basically all the currrent state of the plot is saved into XYZ.
     * Therefore there is a blurry line between what should and should not be saved in Redux through Snapshot.
     * We mainly want to store data that can help us easily extract all required information from XYZ state.
     */
    readonly className: XyzTraceClassNames.LinesTrace | XyzTraceClassNames.BoundingBoxTrace;

    readonly name: string;

    readonly verticesUrl: string;

    readonly vertices: Float64Array;

    readonly segmentsUrl: string;

    readonly segments: Float64Array;
}

export class LinesTrace extends XyzTrace implements LinesTraceSnapshot {
    readonly className: XyzTraceClassNames.LinesTrace | XyzTraceClassNames.BoundingBoxTrace;

    readonly name: string;

    readonly verticesUrl: string;

    readonly vertices: Float64Array;

    readonly segmentsUrl: string;

    readonly segments: Float64Array;

    constructor(xyz: UseXyz, snapshot: LinesTraceSnapshot) {
        if (![XyzTraceClassNames.LinesTrace, XyzTraceClassNames.BoundingBoxTrace].includes(snapshot.className)) {
            throw `An incorrect spanshot is being used to create this instance of LinesTrace: ${snapshot}`;
        }
        if (!snapshot.id) {
            throw 'Id must be provided.';
        }
        super(xyz, snapshot);
    }

    snapshotPath = (): string[] => [this.id];

    entityIdPrefix = (): string => this.id;

    private createInitialXyzEntities = (width: number, color: Color): UpdateSnapshot => {
        const ret: UpdateSnapshot = {
            [this.viewEntityId()]: {
                color: color,
                element: this.linesElementEntityId(),
                visible: true,
                radius: width,
                width: width,
                mode: LinesMode.Tubes,
                __class__: ViewClass.Lines,
            },
            [this.linesElementEntityId()]: {
                verticesUrl: this.vertices ? undefined : this.urlWithObjectHash(this.verticesUrl),
                vertices: this.vertices ? this.vertices : undefined,
                segmentsUrl: this.segments ? undefined : this.urlWithObjectHash(this.segmentsUrl),
                segments: this.segments ? this.segments : undefined,
                __class__: ElementClass.Lines,
            },
        };
        const state = this.xyz?.getState?.();
        const viewEntity = state?.[this.viewEntityId()] as LinesViewState;
        const newviewEntity = ret[this.viewEntityId()] as LinesViewState;
        if (!newviewEntity.color && !viewEntity?.color) {
            // Set color if it wasn't set before
            newviewEntity.color = hexToRgbArray(getNextMeshColor());
        }
        if (!viewEntity?.opacity) {
            newviewEntity.opacity = 1;
        }
        return ret;
    };

    getOpacity = () => this.viewEntity()?.opacity;

    getStatus = (): XyzTraceStatusDetails => {
        const status = this.viewEntity()?.status ?? {};
        return {
            status: mostSevereXYZStatus({ ...status }),
            statuses: { ...status },
        };
    };

    updateOpacity = async (opacity: number) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                opacity: opacity,
                __class__: ViewClass.Lines,
            },
        });
    };

    updateColor = async (color: Point3D) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                color: color,
                __class__: ViewClass.Lines,
            },
        });
    };

    getColor = (): Point3D => this.viewEntity()?.color;

    plotAndSave(enabled = true, width = 1, color: Color = undefined) {
        const plotAndSaveThunk = async (dispatch: AppDispatch) => {
            const initialXyzEntities = this.createInitialXyzEntities(width, color);
            await this.xyz.updateVisualizationWithoutTween(initialXyzEntities);

            const linesTraceSnapshot = this.takeSnapshot();
            dispatch(addNewXyzTrace(linesTraceSnapshot));

            await this.setEnabled(dispatch, enabled);
        };
        return plotAndSaveThunk;
    }

    getName = () => this.name;

    viewEntityId = () => `${this.entityIdPrefix()}:LinesView`;

    viewEntity = (): LinesViewState => this.xyz.getEntityState(this.viewEntityId()) as LinesViewState;

    linesElementEntityId = () => `${this.entityIdPrefix()}:LinesElement`;

    linesElementEntity = (): LinesElementState =>
        this.xyz.getEntityState(this.linesElementEntityId()) as LinesElementState;

    // eslint-disable-next-line class-methods-use-this
    colorDataMappingEntityId = () => undefined;

    // eslint-disable-next-line class-methods-use-this
    showEmptyColorMapLegend = () => true;

    takeSnapshot(): LinesTraceSnapshot {
        const superSnapshot = super.takeSnapshot();

        return {
            ...superSnapshot,
            className: this.className,
            name: this.name,
            verticesUrl: this.verticesUrl,
            segmentsUrl: this.segmentsUrl,
            vertices: this.vertices,
            segments: this.segments,
        };
    }

    updateObjectHash = (objectHash: string, enabled: boolean) => {
        const updateObjectHashThunk = async (dispatch: AppDispatch) => {
            const newLinesTrace = new LinesTrace(this.xyz, {
                ...this.takeSnapshot(),
                objectHash: objectHash,
            }); // We need access to the new TraceSnapshot right now. That's why we can't use this.updateReduxState() because it doesn't return the new snapshot.
            await dispatch(newLinesTrace.plotAndSave(enabled));
        };
        return updateObjectHashThunk;
    };
}
