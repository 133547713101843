import {
    AnyProjectObject,
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
    ProjectObject,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import type { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { BackendJsonifiedProjectObject } from '../BackendJsonifiedProjectObject';
import { Anisotropy } from '../AnisotropyEstimation';
import { AnisotropyGlobal } from '../AnisotropyGlobal';
import { AnisotropyGrid } from '../AnisotropyGrid';
import { Domain } from '../Domain';
import { DomainGrid } from '../DomainGridDefinition';
import { Drilling } from '../SourceFile';
import { Grid } from '../GridDefinition';
import { Mesh } from '../MeshFile';
import { Overlap } from '../Overlap';
import { OverlapFolder } from '../OverlapFolder';
import { PointEstimation } from '../PointEstimation';
import { Zone } from '../Zone';
import { useAppSelector } from 'App/Redux/hooks';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import React from 'react';
import { useSessionContext } from 'App/context/SessionContext';
import { ZoneFromAnisotropy } from '../ZoneFromAnisotropy';

function getClass(className: OBJECT_CLASS_NAMES) {
    const map = {
        [OBJECT_CLASS_NAMES.SourceFile]: Drilling,
        [OBJECT_CLASS_NAMES.MeshFile]: Mesh,
        [OBJECT_CLASS_NAMES.Domain]: Domain,
        [OBJECT_CLASS_NAMES.GridDefinition]: Grid,
        [OBJECT_CLASS_NAMES.Domain_GridDefinition]: DomainGrid,
        [OBJECT_CLASS_NAMES.AnisotropyEstimation]: Anisotropy,
        [OBJECT_CLASS_NAMES.AnisotropyGrid]: AnisotropyGrid,
        [OBJECT_CLASS_NAMES.AnisotropyGlobal]: AnisotropyGlobal,
        [OBJECT_CLASS_NAMES.PointEstimation]: PointEstimation,
        [OBJECT_CLASS_NAMES.Zone]: Zone,
        [OBJECT_CLASS_NAMES.ZoneFromAnisotropy]: ZoneFromAnisotropy,
        [OBJECT_CLASS_NAMES.Overlap]: Overlap,
        [OBJECT_CLASS_NAMES.OverlapFolder]: OverlapFolder,
    };

    return map[className];
}

type PossibleClasses = AnyProjectObject['object_class_name'];

export function wrapObjectInClass<C extends PossibleClasses>(
    object: Extract<ProjectObject, { object_class_name: C }>,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    allObjects: ObjectClassName2Id2Obj
): Extract<AnyProjectObject, { object_class_name: C }>;
export function wrapObjectInClass(
    object: BackendJsonifiedProjectObject,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    allObjects: ObjectClassName2Id2Obj
): AnyProjectObject {
    const Class = getClass(object.object_class_name);

    if (Class) {
        return new Class(object, axiosDriverFlask, allObjects);
    } else {
        throw `Cannot wrap unknown object in class: ${object.object_class_name}`;
    }
}

export function useWrappedObjectInClass<C extends PossibleClasses>(
    object: Extract<ProjectObject, { object_class_name: C }>
): Extract<AnyProjectObject, { object_class_name: C }>;
export function useWrappedObjectInClass<C extends PossibleClasses>(
    objectSnapshot: Extract<ProjectObject, { object_class_name: C }>
): AnyProjectObject {
    const { axiosDriverFlask } = useSessionContext();
    const allObjects = useAppSelector(selectObjectClassname2Id2Obj);

    const object = React.useMemo(
        () => wrapObjectInClass(objectSnapshot, axiosDriverFlask, allObjects),
        [objectSnapshot, axiosDriverFlask, allObjects]
    );

    return object;
}
