export enum GeneralMenuItems {
    delete = 'Delete',
    download = 'Download',
    addToView = 'Add to scene',
    publish = 'Publish',
    publishToLocal = 'Publish to local file',
    publishToEvo = 'Publish to Seequent Evo',
}

export enum PublishToEvoMenuItems {
    StructuralData = 'Planar structural data',
    LineationData = 'Lineations',
    Ellipsoids = 'Ellipsoids',
}

export enum SecondaryMenuItems {
    FromLocal = 'From local file',
    ToLocal = 'To local',
    ToEvo = 'To Seequent Evo',
    FromEvo = 'From Seequent Evo',
}

export enum DrillingSectionMenuItemNames {
    UploadDrilling = 'Upload drilling',
    UpdateDrilling = 'Update drilling',
}

export enum GridsSectionMenuItemNames {
    CreateGrid = 'Create grid',
}

export enum GridMenuItemNames {
    UpdateGrid = 'Update grid',
}

export enum ClosedMeshesMenuItemNames {
    UploadClosedMesh = 'Upload closed mesh',
    MeshFromRegion = 'Create mesh from region box',
}

export enum OpenMeshesMenuItemNames {
    UploadOpenMesh = 'Upload open mesh',
}

export const MeshesSectionMenuItemNames = {
    ...ClosedMeshesMenuItemNames,
    ...OpenMeshesMenuItemNames,
    ClipRegionWithSurface: 'Clip region with surface',
};

export enum DomainSectionMenuItems {
    DefineDomains = 'Define domains',
}

export enum OverlapFolderMenuItems {
    CreateOverlaps = 'Create overlaps',
    DeleteFolder = 'Delete folder',
    RenameFolder = 'Rename folder',
}

export enum OverlapSectionMenuItems {
    CreateFolder = 'Create new overlap folder',
}

export enum AnisotropySectionMenuItems {
    NewAnisotropyEstimation = 'Create new anisotropy estimation',
    NewAnisotropyGrid = 'Create new anisotropy grid',
    NewAnisotropyGlobal = 'Create new global anisotropy',
}

export enum InterpolationSectionMenuItems {
    NewInverseDistance = 'Create new inverse distance',
    NewOrdinaryKriging = 'Create new ordinary kriging',
    NewMergedInterpolation = 'Create new merged interpolation',
}

export enum ZonesSectionMenuItems {
    NewZone = 'Create zones',
}

export enum ZonesFromAnisotropySectionMenuItems {
    ZoneFromAnisotropy = 'Create zones from anisotropy',
}

export enum GooseKnownSchemas {
    DownholeIntervals = 'downhole-intervals',
    TriangleMesh = 'triangle-mesh',
}

export enum SectionNames {
    Setup = 'Setup',
    Drilling = 'Drilling',
    UploadDrilling = 'Upload drilling',
    Meshes = 'Meshes',
    ClosedMeshes = 'Closed meshes',
    OpenMeshes = 'Open meshes',
    Domains = 'Domains',
    Samples = 'Samples',
    Grids = 'Grids',
    Anisotropy = 'Anisotropy',
    Interpolations = 'Interpolations',
    MergedInterpolations = 'Merged interpolations',
    Zones = 'Zones',
    ZonesFromAnisotropy = 'Zones from anisotropy',
    Overlaps = 'Overlaps',
}

export const TreeSections = {};

export const mergedDomainId = 'merged-domain-id';

export const SectionIds = {
    [SectionNames.Setup]: 'setup-section-id',
    [SectionNames.Drilling]: 'drilling-section-id',
    [SectionNames.UploadDrilling]: 'upload-drilling-section-id',
    [SectionNames.Meshes]: 'meshes-section-id',
    [SectionNames.ClosedMeshes]: 'closed-meshes-section-id',
    [SectionNames.OpenMeshes]: 'open-meshes-section-section-id',
    [SectionNames.Domains]: 'domains-section-id',
    [SectionNames.Samples]: 'samples-section-id',
    [SectionNames.Grids]: 'grids-section-id',
    [SectionNames.Anisotropy]: 'anisotropy-section-id',
    [SectionNames.Interpolations]: 'interpolations-section-id',
    [SectionNames.MergedInterpolations]: 'merged-interpolations-section-id',
    [SectionNames.Zones]: 'zones-section-id',
    [SectionNames.ZonesFromAnisotropy]: 'zones-from-anisotropy-section-id',
    [SectionNames.Overlaps]: 'overlaps-section-id',
};
