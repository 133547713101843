import MeshFromRegion from 'App/MainApp/Dialogs/Meshes/MeshFromRegion/MeshFromRegion';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { selectActiveDrilling } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { ObjectStatusTypes } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { ClosedMeshesMenuItemNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { meshFromRegionDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function MeshFromRegionButton(props: GeneralMenuItemProps) {
    const drilling = useAppSelector(selectActiveDrilling);
    const disabled = drilling?.status !== ObjectStatusTypes.SUCCESS;
    const tooltip = disabled && 'Requires drilling data';

    return (
        <DialogOpenerMenuItem
            name={ClosedMeshesMenuItemNames.MeshFromRegion}
            tooltipText={tooltip}
            dataCy={meshFromRegionDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            dialogComponent={MeshFromRegion}
        />
    );
}
