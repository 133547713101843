import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { gridIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },
}));

export function GridIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={gridIconDataCy}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <path
                    fill="currentColor"
                    d="M23.99,6.42C24.02,6.21,23.92,6,23.73,5.9l-11.5-5.85c-0.01-0.01-0.03-0.01-0.04-0.01
	c-0.03-0.01-0.06-0.02-0.09-0.03C12.06,0.01,12.03,0,12,0c-0.03,0-0.06,0.01-0.09,0.01c-0.03,0.01-0.07,0.01-0.1,0.03
	c-0.01,0.01-0.03,0-0.04,0.01L0.27,5.9C0.08,6-0.02,6.21,0.01,6.41C0.01,6.43,0,6.44,0,6.45v11.18c0,0.19,0.11,0.36,0.27,0.45
	l11.44,5.82c0.01,0.01,0.01,0.02,0.02,0.03C11.82,23.97,11.91,24,12,24c0.08,0,0.16-0.02,0.23-0.05l11.5-5.85
	c0.17-0.09,0.27-0.26,0.27-0.45V6.47C24,6.45,23.99,6.44,23.99,6.42z M12,1.06l4.52,2.3l-4.53,2.3l-4.52-2.3L12,1.06z M12.02,11.74
	l-4.9-2.49l4.87-2.48l4.89,2.49L12.02,11.74z M17.99,8.71L13.1,6.22l4.53-2.3l4.89,2.49L17.99,8.71z M10.89,6.22L6.02,8.69L1.5,6.4
	l4.87-2.48L10.89,6.22z M1,7.27l4.53,2.3v4.44L1,11.71V7.27z M6.53,10.08l4.96,2.52v4.44l-4.96-2.52V10.08z M11.5,18.17v4.5
	l-4.96-2.52v-4.5L11.5,18.17z M12.5,12.62l5.06-2.57v4.48L12.5,17.1V12.62z M18.57,9.53L23,7.28v4.48l-4.43,2.25V9.53z M1,12.83
	l4.53,2.3v4.5L1,17.33V12.83z M12.5,18.22l5.06-2.57v4.46l-5.06,2.57V18.22z M18.57,19.6v-4.46L23,12.88v4.46L18.57,19.6z"
                />
            </svg>
        </SvgIcon>
    );
}

export default GridIcon;
