import React from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ArrowRight from '@local/web-design-system/dist/icons/Arrows/ArrowRight';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { tss } from 'tss-react/mui';
import { secondaryMenuDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { DataContext } from 'App/DataContext';
import MenuItemIcon from './MenuItemIcon';

const useStyles = (isMenuPositionTooLow: boolean) =>
    tss.create({
        secondaryMenu: {
            position: 'absolute',
            left: 'calc(100% + 1px)',
            bottom: isMenuPositionTooLow ? '0px' : null,
        },
        arrowRight: {
            fontSize: '10px',
            margin: '3px 0px 6px 0px',
        },
    });

type SecondaryItemMenuProps = {
    disabled?: boolean;
    itemName: string;
    dataCy: string;
    children: JSX.Element | JSX.Element[];
};

function SecondaryItemMenu(props: SecondaryItemMenuProps) {
    const { menuItemClass } = React.useContext(DataContext);

    const { disabled, itemName, dataCy, children } = props;

    const secondaryMenuAnchorRef = React.useRef(null);
    const [secondaryMenuOpenBool, setSecondaryMenuOpenBool] = React.useState<boolean>(false);

    const handleSecondaryMenu = () => {
        setSecondaryMenuOpenBool(!secondaryMenuOpenBool);
    };

    let isMenuPositionTooLow = false;
    if (secondaryMenuAnchorRef && secondaryMenuAnchorRef.current) {
        isMenuPositionTooLow = window.innerHeight - secondaryMenuAnchorRef.current.getBoundingClientRect().bottom < 100;
    }

    const { classes } = useStyles(isMenuPositionTooLow)();

    const handleClickOutside = () => {
        setSecondaryMenuOpenBool(false);
    };

    return (
        <ClickAwayListener
            disableReactTree
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickOutside}
        >
            <Grid style={{ width: '80%' }} container ref={secondaryMenuAnchorRef}>
                <Grid item>
                    <MenuItem
                        className={menuItemClass.menuItem}
                        id={dataCy}
                        data-cy={dataCy}
                        onClick={handleSecondaryMenu}
                        disabled={disabled}
                    >
                        <MenuItemIcon name={itemName} />
                        {itemName}
                        <Button
                            style={{ minWidth: '10px', paddingTop: '10px', margin: '4px 0px 4px 0px', height: '21px' }}
                        >
                            <ArrowRight className={classes.arrowRight} />
                        </Button>
                    </MenuItem>
                </Grid>
                {secondaryMenuOpenBool && (
                    <Paper data-cy={secondaryMenuDataCy} className={classes.secondaryMenu}>
                        <MenuList>{children}</MenuList>
                    </Paper>
                )}
            </Grid>
        </ClickAwayListener>
    );
}

export default SecondaryItemMenu;
