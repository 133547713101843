import { CreateAnisotropyGlobal } from 'App/MainApp/Dialogs/SCMAnisotropy/CreateAnisotropyGlobal';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { selectActiveAnisotropyEstimations } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { AnisotropySectionMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { createAnisotropyGlobalDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function CreateAnisotropyGlobalButton(props: GeneralMenuItemProps) {
    const anisotropies = useAppSelector(selectActiveAnisotropyEstimations);
    const disabled = !Object.keys(anisotropies).length;
    const tooltipText = disabled ? 'At least one anisotropy estimation required' : '';

    return (
        <DialogOpenerMenuItem
            name={AnisotropySectionMenuItems.NewAnisotropyGlobal}
            dataCy={createAnisotropyGlobalDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            dialogComponent={CreateAnisotropyGlobal}
            tooltipText={tooltipText}
        />
    );
}
