import { BoundsKeys } from 'Common/types/geometryTypes';

export const minMaxKeysToTitles = {
    [BoundsKeys.minX]: 'Min X:',
    [BoundsKeys.minY]: 'Min Y:',
    [BoundsKeys.minZ]: 'Min Z:',
    [BoundsKeys.maxX]: 'Max X:',
    [BoundsKeys.maxY]: 'Max Y:',
    [BoundsKeys.maxZ]: 'Max Z:',
};

export const minKeys = [BoundsKeys.minX, BoundsKeys.minY, BoundsKeys.minZ];
export const maxKeys = [BoundsKeys.maxX, BoundsKeys.maxY, BoundsKeys.maxZ];

export enum GenericDialogTitles {
    UploadDrilling = 'Upload Drill from Seequent Evo',
    UploadClosedMesh = 'Upload Closed Mesh from Seequent Evo',
    UploadOpenMesh = 'Upload Open Mesh from Seequent Evo',
}
