import { revertObject } from 'Common/utils/ObjectUtils';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { produce } from 'immer';
import { AppDispatch, AppStoreStateGetter, AppThunk } from 'App/Redux/store';
import { ComputeStatus } from '@seequent/xyz';
import {
    ProjectObjectIdentifier,
    ProjectObjectIdentifierSnapshot,
} from 'App/util/ProjectDataTypes/ProjectObjectIdentifier';
import { urlWithParams } from 'Common/UrlUtils';
import { XyzReduxSnapshot } from './XyzReduxSnapshot';
import { fetchJSON } from './XyzNetwork';
import { createXyzTraceObjectFromSnapshot } from './utils/AnyXyzTrace';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { XyzTraceWithColorAttributes } from './XyzTraceWithColorAttributes';

export enum InValidateAllColorValueSummaries {
    Yes = 'Yes',
    No = 'No',
}

export interface XyzTraceStatusDetails {
    status: ComputeStatus;
    statuses: { [key: string]: ComputeStatus };
}

const XyzComputeStatus2XyzStatusSeverity = {
    [ComputeStatus.Complete]: 1,
    [ComputeStatus.Pending]: 2,
    [ComputeStatus.Failed]: 3,
    [ComputeStatus.FailedTooBig]: 4,
};

const XyzStatusSeverity2XyzComputeStatus = revertObject(XyzComputeStatus2XyzStatusSeverity);

export function mostSevereXYZStatus(xyzStatusObject: { [key: string]: ComputeStatus }): ComputeStatus {
    if (!xyzStatusObject) {
        return ComputeStatus.Pending;
    }

    const maxSeverity = Math.max(
        ...Object.values(xyzStatusObject).map((computeStatus) => XyzComputeStatus2XyzStatusSeverity[computeStatus])
    );

    const mostSevereStatus = XyzStatusSeverity2XyzComputeStatus[String(maxSeverity)];

    return mostSevereStatus as ComputeStatus;
}

export type XyzTraceId = string;

export enum XyzTraceClassNames {
    DrillTrace = 'DrillTrace',
    MeshTrace = 'MeshTrace',
    LvaTrace = 'LvaTrace',
    BlockModelTrace = 'BlockModelTrace',
    LinesTrace = 'LinesTrace',
    BoundingBoxTrace = 'BoundingBoxTrace',
}

export interface XyzTraceSnapshot extends XyzReduxSnapshot {
    /**
     * Snapshot is saved in Redux state. Must not duplicate XYZ state that is already stored in XYZ.
     * I mean when a plot is saved into XYZ, then basically all the currrent state of the plot is saved into XYZ.
     * Therefore there is a blurry line between what should and should not be saved in Redux through Snapshot.
     * We mainly want to store data that can help us easily extract all required information from XYZ state.
     */
    readonly className: XyzTraceClassNames;
    readonly id: XyzTraceId;
    readonly enabled: boolean;
    readonly visible: boolean;
    readonly projectObjectIdentifier: ProjectObjectIdentifierSnapshot;
    readonly objectHash: string;
}

export abstract class XyzTrace extends XyzReduxSnapshot implements XyzTraceSnapshot {
    readonly className: XyzTraceClassNames;

    readonly id: XyzTraceId;

    readonly xyz: UseXyz;

    readonly enabled: boolean;

    readonly visible: boolean;

    readonly projectObjectIdentifier: ProjectObjectIdentifier;

    readonly objectHash: string;

    constructor(xyz, snapshot: XyzTraceSnapshot) {
        super();
        Object.assign(this, snapshot);
        this.projectObjectIdentifier =
            snapshot.projectObjectIdentifier?.id && snapshot.projectObjectIdentifier?.objectClassName
                ? new ProjectObjectIdentifier({
                      id: snapshot.projectObjectIdentifier.id,
                      objectClassName: snapshot.projectObjectIdentifier.objectClassName,
                  })
                : undefined;
        this.xyz = xyz;
    }

    setEnabled = async (dispatch: AppDispatch, enabled: boolean) => {
        await this.addOrRemoveElementFromPlotView(enabled);

        if (enabled === this.enabled) {
            return;
        }
        dispatch(
            this.updateReduxState(
                produce<XyzTraceSnapshot>((oldXyzTraceSnapshot) => {
                    oldXyzTraceSnapshot.enabled = enabled;
                })
            )
        );
    };

    setEnabledById = async (dispatch: AppDispatch, enabled: boolean) => {
        if (enabled === this.enabled) {
            return;
        }
        dispatch(
            this.updateReduxState(
                produce<XyzTraceSnapshot>((oldXyzTraceSnapshot) => {
                    oldXyzTraceSnapshot.enabled = enabled;
                })
            )
        );
    };

    isEnabled = () => this.enabled;

    takeSnapshot(): XyzTraceSnapshot {
        return {
            id: this.id,
            className: this.className,
            enabled: this.enabled,
            visible: this.visible,
            projectObjectIdentifier: this.projectObjectIdentifier?.takeSnapshot?.(),
            objectHash: this.objectHash,
        };
    }

    isVisible = () => this.visible;

    /**
     * Use this function to add the object to the scene manager in the beginning and remove the object from the scene manager.
     * To temporarily hide the object, use updateViewVisiblity() instead.
     * When we call this function with false value, XYZ will stop tracking changes to it. So for example, if the urls change while we have removed the object from PlotView, then when the object comes back online, it will not redownload the urls
     * @param visible
     * @returns
     */
    addOrRemoveElementFromPlotView = (visible: boolean) => {
        if (visible) {
            return this.xyz.addViewToPlot(this.viewEntityId());
        } else {
            return this.xyz.removeViewsFromPlot([this.viewEntityId()]);
        }
    };

    addIdToPlot = (id: string) => {
        return this.xyz.addViewToPlot(id);
    };

    /**
     * Updates the actual visibility of the XYZ element. XYZ will still keep track of changes in the element and will download the changed urls, ...
     * Use this to temporarily make an object invisible.
     * @param visible
     */
    updateViewVisiblity = async (visible: boolean) => {
        const xyzState = this.xyz.getState();
        const oldViewEntity = xyzState[this.viewEntityId()];
        if (oldViewEntity) {
            await this.xyz.updateVisualizationWithoutTween({
                [this.viewEntityId()]: {
                    visible: visible,
                },
            });
        }
    };

    setVisible = async (dispatch: AppDispatch, visible: boolean, isSwitchingAnisotrphy = false) => {
        await this.updateViewVisiblity(visible);
        if (!isSwitchingAnisotrphy) {
            dispatch(
                this.updateReduxState(
                    produce<XyzTraceSnapshot>((oldXyzTraceSnapshot) => {
                        oldXyzTraceSnapshot.visible = visible;
                    })
                )
            );
        }
    };

    abstract getName(): string; // All traces must support a "getName()". This name can be used in several places including a on colorbar (aka. legend)

    abstract getStatus(): XyzTraceStatusDetails;

    abstract viewEntityId(): string; // The id of the main view entity which holds the status and its value can be put in XyzState.plot.views array to make it visible.

    // eslint-disable-next-line class-methods-use-this
    colorDataMappingEntityId = (): string => undefined; // The id of the entity that holds the 'gradient' (aka colorMap e.g. JET).

    abstract showEmptyColorMapLegend(): boolean; // This should be true if the current displayed data doesn't have a color map, or if the color map ranges in xyz are not yet correctly set.

    getElementColorKey = () => 'color';

    urlWithObjectHash = (url: string) =>
        this.objectHash ? urlWithParams(url, { 'object-hash': this.objectHash }) : url;

    abstract updateObjectHash: (
        objectHash: string,
        enabled: boolean,
        inValidateAllColorValueSummaries: InValidateAllColorValueSummaries
    ) => AppThunk<Promise<void>>;

    incrementObjectHash = (
        dispatch: AppDispatch,
        enabled: boolean,
        inValidateAllColorValueSummaries: InValidateAllColorValueSummaries
    ) => {
        const newObjectHash = `${this.objectHash}1`;
        return dispatch(this.updateObjectHash(newObjectHash, enabled, inValidateAllColorValueSummaries));
    };

    protected getJsonDataWithUrl = async (dataName: string) => {
        if (this[dataName]) {
            return Promise.resolve(this[dataName]);
        }
        if (this[`${dataName}Url`]) {
            const val = await fetchJSON(this[`${dataName}Url`]);
            return val;
        }
        throw new Error(
            "getJsonDataWithUrl() can only be called for values that have url attribute! For example, for the blockmodel objects, there is a blockSize and a blockSizeUrl attribute, so we can call: getJsonDataWithUrl('blockSize')"
        );
    };

    showInSceneManager = () => true;

    resetViewVisibilityBasedOnLatestVisibility = (tokenProvider: () => Promise<string>) => {
        return async (dispatch: AppDispatch, getState: AppStoreStateGetter) => {
            const trace = createXyzTraceObjectFromSnapshot(
                this.xyz,
                selectXyzTrace(getState(), this.id),
                tokenProvider
            ) as XyzTraceWithColorAttributes; // trace might have changed before we call this! So we can't just use "this"!
            await trace.updateViewVisiblity(trace.isVisible());
        };
    };
}
