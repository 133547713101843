export function objectMap<ObjectType, MapReturn>(
    object: ObjectType,
    mapFn: (val: ObjectType[keyof ObjectType]) => MapReturn
) {
    const result: {
        [key: string]: MapReturn;
    } = {};
    Object.keys(object).forEach((key) => {
        result[key] = mapFn(object[key]);
        return result;
    });

    return result;
}

export function revertObject<ObjectType>(object: ObjectType): { [key: string]: string } {
    return Object.fromEntries(Object.keys(object).map((key) => [String(object[key]), key]));
}
