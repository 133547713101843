export enum EnvNames {
    PROD = 'production',
    STAGING = 'staging',
    TESTING = 'development',
    BRANCH = 'branch',
    INTEGRATION = 'integration',
    LOCAL = 'driver.local',
}

export function isProductionEnv() {
    return process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvNames.PROD;
}

export function isTestingEnv() {
    return (
        process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvNames.TESTING ||
        process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvNames.BRANCH
    );
}

export function isStagingEnv() {
    return process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvNames.STAGING;
}

export function isIntegrationEnv() {
    return process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === EnvNames.INTEGRATION;
}

export function getImageUrl(imageName: string) {
    return `${process.env.NEXT_PUBLIC_REACT_BASE_PATH || ''}/${imageName}`;
}

// for use in sort
export function alphabeticSorter(stringA: string, stringB: string): number {
    return stringA.localeCompare(stringB);
}

// round number to the nearest 0.1, 1, 10, 100
export function getClosestBase10(value: number) {
    const power = Math.round(Math.log10(value));
    return Math.pow(10, power);
}
