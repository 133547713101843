import {
    BoundsArray,
    BoundsKey,
    BoundsKeys,
    BoundsObject,
    BoundsStringsObject,
    CoordinatesObject,
    Point3D,
} from '../types/geometryTypes';

export function boundsArrayToObject(coordinatesArray: BoundsArray): BoundsObject {
    return {
        minX: coordinatesArray[0],
        minY: coordinatesArray[2],
        minZ: coordinatesArray[4],
        maxX: coordinatesArray[1],
        maxY: coordinatesArray[3],
        maxZ: coordinatesArray[5],
    };
}

export function boundsObjectToArray(coordinatesObject: BoundsObject): BoundsArray {
    const { minX, minY, minZ, maxX, maxY, maxZ } = coordinatesObject;
    return [minX, maxX, minY, maxY, minZ, maxZ];
}

export function stringsObjectToBoundsObject(stringsObject: BoundsStringsObject): BoundsObject {
    return {
        minX: Number(stringsObject.minX),
        minY: Number(stringsObject.minY),
        minZ: Number(stringsObject.minZ),
        maxX: Number(stringsObject.maxX),
        maxY: Number(stringsObject.maxY),
        maxZ: Number(stringsObject.maxZ),
    };
}

export function boundsObjectToStringsObject(boundsObject: BoundsObject): BoundsStringsObject {
    return {
        minX: `${boundsObject.minX}`,
        minY: `${boundsObject.minY}`,
        minZ: `${boundsObject.minZ}`,
        maxX: `${boundsObject.maxX}`,
        maxY: `${boundsObject.maxY}`,
        maxZ: `${boundsObject.maxZ}`,
    };
}

export function coordinatesArrayToObject(coordinates: Point3D): CoordinatesObject {
    return { x: coordinates[0], y: coordinates[1], z: coordinates[2] };
}

export function getDistances(bounds: BoundsObject): CoordinatesObject {
    return {
        x: bounds.maxX - bounds.minX,
        y: bounds.maxY - bounds.minY,
        z: bounds.maxZ - bounds.minZ,
    };
}

export function isMinBound(key: BoundsKey): boolean {
    return key.includes('min');
}

export const OppositeBoundsMap = {
    minX: BoundsKeys.maxX,
    minY: BoundsKeys.maxY,
    minZ: BoundsKeys.maxZ,
    maxX: BoundsKeys.minX,
    maxY: BoundsKeys.minY,
    maxZ: BoundsKeys.minZ,
};

export function getDistanceBetweenPoints(bounds: BoundsObject) {
    const { x, y, z } = getDistances(bounds);
    return Math.sqrt(x * x + y * y + z * z);
}

export function getMinPoint(bounds: BoundsObject): CoordinatesObject {
    return {
        x: bounds.minX,
        y: bounds.minY,
        z: bounds.minZ,
    };
}

export const DEG2RAD = Math.PI / 180;
export const RAD2DEG = 180 / Math.PI;

export function toRadians(degrees: number): number {
    return degrees * DEG2RAD;
}
