import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import { tss } from 'tss-react/mui';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import {
    applyWebsocketModificationsToCurrentProject,
    selectActiveDrilling,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import React from 'react';
import { BoundsKey, BoundsStringsObject } from 'Common/types/geometryTypes';
import { meshFromRegionDialogDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { boundsObjectToStringsObject, stringsObjectToBoundsObject } from 'Common/utils/geometryHelpers';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import BoundsRow from '../../Shared/BoundsRow';
import { maxKeys, minKeys, minMaxKeysToTitles } from '../../Shared/consts';
import { getDrillingBounds } from '../../DefineGrid/defineGridService';
import { getInitialValidationResults, isAllBoundsValid, isValidBounds } from '../../Shared/boundsValidationService';
import { BoundsValidationResults } from '../../DefineGrid/gridLimitsHelpers';
import { createMeshFromRegion } from './meshFromRegionService';
import { DataContext } from '../../../../DataContext';
import LabeledTextField from '../../Shared/LabeledTextField';
import { ValidationResult, getDefaultValidationResult } from '../../../../util/validationUtils';
import { isValidName } from './MeshFromregionValidationService';
import { useCalculateBoundsIncrements } from '../../Shared/useCalculateBoundsIncrementsHook';
import { useXyzBoundingBox } from 'Common/DRIVER2XYZ/useXyzBoundingBox';
import GenericDraggableShell from 'Common/components/GenericDraggable/GenericDraggableShell';
import { useDefaultPosition } from '../../Shared/useDefaultPositionHook';
import { useSessionContext } from 'App/context/SessionContext';
import { commonStyles } from 'styles/commonStyles';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create(({ theme }) => ({
    textField: {
        flex: '0 0 29%',
        gridTemplateColumns: '27% auto',
        display: 'grid',
    },

    boundsRow: {
        columnGap: '19px',
        marginTop: '15px',
        alignItems: 'center',
    },

    title: {
        marginTop: '25px',
        alignItems: 'center',
    },
    wrapper: {
        marginBottom: '15px',
    },
    root: {
        position: 'absolute',
        width: '750px',
        zIndex: '1300',
        top: 30,
        left: 30,
    },
    defaultText: {
        ...commonStyles({ theme }).defaultText,
    },
}));

export default function MeshFromRegion(props: { handleClose: () => void }) {
    const { classes } = useStyles();
    const xyz = useXyz();

    const { currentOrgUuid } = React.useContext(DataContext);
    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const currentProjectId = useAppSelector(selectCurrentProjectId);

    const dispatch = useAppDispatch();

    const drillingData = useAppSelector(selectActiveDrilling);
    const drillingBounds = getDrillingBounds(drillingData);

    if (!drillingData) {
        return null;
    }

    const initialRawData = {
        name: 'box',
    };

    const originalName = initialRawData.name;

    const [bounds, setBounds] = React.useState<BoundsStringsObject>(boundsObjectToStringsObject(drillingBounds));
    const [boundsValidationResults, setBoundsValidationResults] =
        React.useState<BoundsValidationResults>(getInitialValidationResults());
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [name, setName] = React.useState(originalName);
    const [nameValidationResult, setNameValidationResult] =
        React.useState<ValidationResult>(getDefaultValidationResult());

    const { minIncrements, maxIncrements } = useCalculateBoundsIncrements(drillingBounds);
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const { updateBoundingBoxCorners } = useXyzBoundingBox(
        xyz,
        dispatch,
        [drillingBounds.minX, drillingBounds.minY, drillingBounds.minZ],
        [drillingBounds.maxX, drillingBounds.maxY, drillingBounds.maxZ]
    );

    const generateOnBoundsChange = (key: BoundsKey) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newBounds = { ...bounds, [key]: event.target.value };
        setBounds(newBounds);
        const boundsNumbers = stringsObjectToBoundsObject(newBounds);
        void updateBoundingBoxCorners(
            [boundsNumbers.minX, boundsNumbers.minY, boundsNumbers.minZ],
            [boundsNumbers.maxX, boundsNumbers.maxY, boundsNumbers.maxZ]
        );
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const runBoundsValidation = () => {
        const boundsValidationResult = isValidBounds(bounds, drillingBounds);
        setBoundsValidationResults(boundsValidationResult);
        return boundsValidationResult;
    };

    const runValidation = () => {
        const nameValidationResult = isValidName(name);
        const boundsValidationResult = runBoundsValidation();

        setNameValidationResult(nameValidationResult);
        return {
            boundsValidationResult,
            nameValidationResult,
        };
    };

    React.useEffect(() => {
        runValidation();
    }, [bounds, name]);

    const onSubmit = async () => {
        const { boundsValidationResult, nameValidationResult } = runValidation();
        if (!(isAllBoundsValid(boundsValidationResult) && nameValidationResult.isValid)) {
            return;
        }

        setIsLoading(true);
        const result = await createMeshFromRegion(
            axiosDriverFlask,
            currentProjectId,
            stringsObjectToBoundsObject(bounds),
            name,
            currentOrgUuid,
            currentWorkspaceId
        );

        if (result) {
            dispatch(
                applyWebsocketModificationsToCurrentProject(
                    xyz,
                    axiosDriverFlask,
                    result,
                    currentProjectId,
                    'define mesh from box',
                    tokenProvider
                )
            );
            props.handleClose();
        }
        setIsLoading(false);
    };

    const onCancel = () => {
        props.handleClose();
    };

    const isSubmitDisabled = isLoading || !isAllBoundsValid(boundsValidationResults) || !nameValidationResult.isValid;

    const [defaultPosition, createPositionChangeHandler] = useDefaultPosition(null);

    return (
        <GenericDraggableShell
            zIndexContext={false}
            divider
            defaultPosition={defaultPosition}
            onCloseClicked={props.handleClose}
            dataCy={meshFromRegionDialogDataCy}
            headerName="Create mesh from region box"
            onPositionChange={createPositionChangeHandler}
            classes={{
                root: {
                    [classes.root]: true,
                },
            }}
        >
            <DialogContent>
                <Grid container direction="column">
                    <LabeledTextField
                        className={classes.textField}
                        title="Name:"
                        placeholder=""
                        onChange={onNameChange}
                        value={name}
                        dataCy="grid-name-textbox"
                        validationResult={nameValidationResult}
                    />
                    <Grid item container direction="column" className={classes.wrapper}>
                        <Grid item container className={classes.title}>
                            <Typography className={classes.defaultText} fontWeight="bold">
                                Origin (base)
                            </Typography>
                        </Grid>
                        <BoundsRow
                            keys={minKeys}
                            keysToTitles={minMaxKeysToTitles}
                            values={bounds}
                            validationResults={boundsValidationResults}
                            generateOnChange={generateOnBoundsChange}
                            increments={minIncrements}
                            placeholders={drillingBounds}
                            className={classes.boundsRow}
                            textFieldClassName={classes.textField}
                        />

                        <Grid item container className={classes.title}>
                            <Typography className={classes.defaultText} fontWeight="bold">
                                End (top)
                            </Typography>
                        </Grid>
                        <BoundsRow
                            keys={maxKeys}
                            keysToTitles={minMaxKeysToTitles}
                            values={bounds}
                            validationResults={boundsValidationResults}
                            generateOnChange={generateOnBoundsChange}
                            increments={maxIncrements}
                            placeholders={drillingBounds}
                            className={classes.boundsRow}
                            textFieldClassName={classes.textField}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <GenericDialogActions
                onCancel={onCancel}
                onSubmit={onSubmit}
                disabled={isSubmitDisabled}
                showSpinner={isLoading}
            />
        </GenericDraggableShell>
    );
}
