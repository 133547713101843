import AnisotropyIcon from 'Common/components/icons/objects/AnisotropyIcon';
import DiscIcon from 'Common/components/icons/objects/DiscIcon';
import LineationIcon from 'Common/components/icons/objects/LineationIcon';

enum AnisotropyDisplayShapes {
    ELLIPSOIDS = 'ellipsoids',
    DISCS = 'discs',
    LINEATIONS = 'lineations',
}

const ICONS = {
    [AnisotropyDisplayShapes.ELLIPSOIDS]: <AnisotropyIcon />,
    [AnisotropyDisplayShapes.DISCS]: <DiscIcon />,
    [AnisotropyDisplayShapes.LINEATIONS]: <LineationIcon />,
};

export type NormalizedRangeResponse = { default_display_range: number };

export { ICONS, AnisotropyDisplayShapes };
