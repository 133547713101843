import { UpdateSnapshot } from '@seequent/xyz';
import { DataContext } from 'App/DataContext';
import React from 'react';
import { DEFAULT_TWEEN } from '../constants';
import { UseBaseXyz } from './UseBaseXyzType';

/**
 * Interface to an xyz instance which does not incorporate any redux state
 */
export function useBaseXyz(): UseBaseXyz {
    const { xyzInstanceContextValue: context } = React.useContext(DataContext);

    const { addListener, setStateFromSnapshot, entityExists } = context ?? {};

    const setXyzStateDirectly = React.useCallback(
        (snapshot: UpdateSnapshot, tween = DEFAULT_TWEEN) =>
            /**
             * Call xyz instance setStateFromSnapshot directly without storing the result in redux
             * This is useful for cases where frequent updates are sent to xyz which do not need to
             * be read from redux (e.g. updating grid spacing on zoom)
             */
            setStateFromSnapshot?.(snapshot, { tween }),
        [setStateFromSnapshot]
    );

    const useXyzListener = React.useCallback(
        (entityId: string, prop: string, listener: (state: any) => void) => {
            React.useEffect(() => {
                if (!entityExists?.(entityId)) {
                    return;
                }
                const removeListener = addListener(entityId, prop, listener);
                return () => {
                    removeListener();
                };
            }, [addListener]);
        },
        [entityExists, addListener]
    );

    const ret = React.useMemo(
        () => ({
            ...context,
            setXyzStateDirectly,
            useXyzListener,
        }),
        [context, setXyzStateDirectly, useXyzListener]
    );

    return ret;
}
