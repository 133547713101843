import { getDefaultValidationResult } from 'App/util/validationUtils';
import { validateNameSuffix } from 'Common/components/NameSuffixField/nameSuffixValidationService';
import React from 'react';

export function useNameSuffix() {
    const [nameSuffix, setNamesSuffix] = React.useState('');
    const [nameSuffixValidationResult, setNamesSuffixValidationResult] = React.useState(getDefaultValidationResult());

    const onNameSuffixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNamesSuffix(event.target.value);
    };
    const onNameSuffixBlur = () => {
        setNamesSuffixValidationResult(validateNameSuffix(nameSuffix));
    };

    return {
        nameSuffix,
        nameSuffixValidationResult,
        onNameSuffixChange,
        onNameSuffixBlur,
    };
}
