import { ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';
import { BackendJsonifiedProjectObject, BaseProjectObject } from '../BackendJsonifiedProjectObject';
import { wrapObjectInClass } from './objectWrapper';

export class Dependants {
    dependantsMap: { [key: string]: BaseProjectObject } = {};

    addDependantAndItsDependants = (object: BaseProjectObject) => {
        this.dependantsMap[object.getUniqueId()] = object;

        object
            .getDependants()
            .list()
            .forEach((dependant) => {
                this.dependantsMap[dependant.getUniqueId()] = dependant;
            });
    };

    addDependantAndItsDependantsRaw = (
        projectObject: BackendJsonifiedProjectObject,
        axiosDriverFlask: AxiosDriverFlaskInstance,
        allObjects: ObjectClassName2Id2Obj
    ) => {
        // @ts-ignore : What is this error about?
        const object = wrapObjectInClass(projectObject, axiosDriverFlask, allObjects);

        this.addDependantAndItsDependants(object);
    };

    addDependants = (dependants: Dependants) => {
        this.dependantsMap = {
            ...this.dependantsMap,
            ...dependants.dependantsMap,
        };
    };

    list = () => Object.values(this.dependantsMap);
}
