import React from 'react';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import PointEstimationDialog from './PointEstimationDialog';
import { PointEstimationMethods } from './pointEstimationsService';

export default function NewInverseDistance(props: { handleClose: () => void }) {
    return (
        <GenericDialogShell
            title="New Inverse Distance"
            dataCy="inverse-distance-dialog"
            maxWidth="md"
            handleClose={props.handleClose}
        >
            <PointEstimationDialog
                pointEstimationMethod={PointEstimationMethods.InverseDistance}
                namesSuffixPrefix="_id"
                handleClose={props.handleClose}
                includePowerParameter
            />
        </GenericDialogShell>
    );
}
