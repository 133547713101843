import { anisotropySuffixPrefix } from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyConsts';
import { backendProjectUrl } from 'App/Redux/features/globalContext/currentProjectSlice';
import { APIResponseWithProjectUpdate } from 'App/util/ProjectDataTypes/APIResponseTypes';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';

type AnisotropyJobData = {
    drilling_attributes: string[];
    domains: ObjectIDType[];
    name_suffix: string;
    threshold_type: string;
    threshold_value: number;
    resolution: string;
    epsilon_type: string;
    epsilon_value: number;
    output_format: string;
    user_supplied_locations: boolean;
    ellipsoid_smoothing: number;
    featureFlags?: {
        anisotropyClustering?: boolean;
        scmCoreUpdate?: boolean;
    };
    cluster_level?: number;
};

export function runAnisotropy(
    data: {
        drillingAttrs: string[];
        domains: ObjectIDType[];
        nameSuffix: string;
        thresholdType: string;
        thresholdValue: number;
        resolution: string;
        epsilonType: string;
        epsilonValue: number;
        outputFormat: string;
        centersFile: File;
    },
    axiosDriverFlask: AxiosDriverFlaskInstance,
    projectId: ObjectIDType,
    drillSourceId: ObjectIDType,
    orgId: string,
    workspaceId: string,
    anisotropyClustering: boolean,
    scmCoreUpdate: boolean,
    runJobOnLambda: boolean
) {
    const dataForServer: AnisotropyJobData = {
        drilling_attributes: data.drillingAttrs,
        domains: data.domains,
        name_suffix: `${anisotropySuffixPrefix}${data.nameSuffix}`,
        threshold_type: data.thresholdType,
        threshold_value: data.thresholdValue,
        resolution: data.resolution,
        epsilon_type: data.epsilonType,
        epsilon_value: data.epsilonValue,
        output_format: data.outputFormat,
        user_supplied_locations: false,
        ellipsoid_smoothing: scmCoreUpdate ? 0 : 3, // in the meantime no user control, just a default value
        featureFlags: {
            anisotropyClustering: anisotropyClustering,
            scmCoreUpdate: scmCoreUpdate,
        },
        cluster_level: 5,
    };

    const formData = new FormData();
    formData.append('parameters', JSON.stringify(dataForServer));
    formData.append('drill_source_id', String(drillSourceId));

    if (data.centersFile) {
        formData.append('files[]', data.centersFile);
    }

    if (runJobOnLambda) {
        formData.append('feature_flag_prefer_run_in_lambdas', 'true');
    }

    return axiosDriverFlask.post<APIResponseWithProjectUpdate>(
        `${backendProjectUrl(orgId, workspaceId, projectId)}/anisotropyEstimation`,
        formData,
        {
            withCredentials: true,
            responseType: 'json',
        }
    );
}
