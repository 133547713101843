import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { setOpenWorkSpace } from 'App/Redux/features/plot/openWorkspaceSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { UnsavedOverlap, UnsavedOverlapType } from 'App/util/ProjectDataTypes/UnsavedOverlap';
import { MeshTrace, MeshTraceSnapshot } from 'Common/Xyz/XyzTraces/MeshTrace';
import { XyzTraceClassNames } from 'Common/Xyz/XyzTraces/XyzTrace';

function createInitialMeshSnapshot(
    xyzTraceId: string,
    currentOrgUuid: string,
    unsavedOverlap: UnsavedOverlap
): MeshTraceSnapshot {
    const jobUrl = unsavedOverlap.getPlotDataUrl(currentOrgUuid);

    return {
        id: xyzTraceId,
        className: XyzTraceClassNames.MeshTrace,
        enabled: true,
        visible: true,
        name: unsavedOverlap.getName(),
        projectObjectIdentifier: {
            objectClassName: unsavedOverlap.object_class_name,
            id: unsavedOverlap.id,
        },
        verticesUrl: `${jobUrl}&vertices_bytes_array=true`,
        trianglesUrl: `${jobUrl}&triangles_bytes_array=true`,
        vertices: undefined,
        triangles: undefined,
        objectHash: unsavedOverlap.updated_at,
    };
}

async function createInitialXyzMeshTrace(
    xyzTraceId: string,
    dispatch: AppDispatch,
    currentOrgUuid: string,
    unsavedOverlap: UnsavedOverlap,
    xyz: UseXyz
) {
    const initialMeshSnapshot = createInitialMeshSnapshot(xyzTraceId, currentOrgUuid, unsavedOverlap);

    const meshTrace = new MeshTrace(xyz, initialMeshSnapshot);
    await dispatch(meshTrace.plotAndSave());
}

export function plotUnsavedOverlapObject(
    xyz: UseXyz,
    currentOrgUuid: string,
    overlapedObjIds: string[],
    gridDefinitionId: ObjectIDType,
    overlapFolderId: ObjectIDType
) {
    return async function plotMeshObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const objectClassName2id2obj = selectObjectClassname2Id2Obj(state);
        const unsavedOverlap = new UnsavedOverlap(
            {
                parentObjIds: overlapedObjIds,
                gridDefinitionId,
                overlapFolderId,
            } as UnsavedOverlapType,
            undefined,
            objectClassName2id2obj
        );
        const xyzTraceId = JSON.stringify([overlapedObjIds, 'UnsavedOverlap']);

        const xyzTraceSnapshot = selectXyzTrace(state, xyzTraceId);
        if (xyzTraceSnapshot) {
            const meshTrace = new MeshTrace(xyz, xyzTraceSnapshot as MeshTraceSnapshot);
            await dispatch(meshTrace.plotAndSave());
        } else {
            await createInitialXyzMeshTrace(xyzTraceId, dispatch, currentOrgUuid, unsavedOverlap, xyz);
        }
        dispatch(setOpenWorkSpace('open'));
    };
}
