import { overlapFolderObjectMenu, overlapObjectMenu } from 'App/MainApp/TreeView/menu/objectMenus';
import { getCommonData } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { ProjectTreeNode, ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { OverlapType } from 'App/util/ProjectDataTypes/Overlap';
import { OverlapFolderType } from 'App/util/ProjectDataTypes/OverlapFolder';
import { ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';

export function getOverlapFolderNode(overlapFolder: OverlapFolderType, parentNode: ProjectTreeNode) {
    const overlapFolderNode: ProjectTreeObjectNode = {
        ...getCommonData(overlapFolder, parentNode),
        dataCy: overlapFolder.name,
        section: overlapFolder.name,
        rightClickMenu: overlapFolderObjectMenu,
        subsections: {},
    };

    return overlapFolderNode;
}

export function getOverlapNode(overlap: OverlapType, allObjects: ObjectClassName2Id2Obj, parentNode: ProjectTreeNode) {
    const overlapInstance = wrapObjectInClass(overlap, null, allObjects);

    const overlapName = overlapInstance.getName();

    const overlapNode: ProjectTreeObjectNode = {
        ...getCommonData(overlap, parentNode),
        dataCy: overlapName,
        section: overlapName,
        rightClickMenu: overlapObjectMenu,
        canDisplaySpotlight: true,
    };

    return overlapNode;
}
