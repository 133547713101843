import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import MenuItem from '@mui/material/MenuItem';
import { DataContext } from 'App/DataContext';
import { StringCoordinatesObject, StringCoordinatesToNumbers } from 'App/MainApp/Dialogs/DefineGrid/gridLimitsHelpers';
import {
    buildAnisotropySubsections,
    calculateNumberOfCenters,
    runAnisotropyGrid,
    validateNumberOfCenters,
    validateSpacings,
} from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyGridService';

import BoundsRow from 'App/MainApp/Dialogs/Shared/BoundsRow';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import {
    selectCurrentProjectId,
    selectActiveDomains,
    applyWebsocketModificationsToCurrentProject,
    selectObjectByClassnameAndId,
    selectActiveDrilling,
    selectActiveAnisotropyEstimations,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector, useAppDispatch } from 'App/Redux/hooks';
import { OBJECT_CLASS_NAMES } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { ValidationResult, getDefaultValidationResult } from 'App/util/validationUtils';
import WidgetSelect from 'Common/components/Core/WidgetSelect';
import ErrorLabel from 'Common/components/ErrorHandling/ErrorLabel';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import { NameSuffixField } from 'Common/components/NameSuffixField/NameSuffixField';
import { useNameSuffix } from 'Common/components/NameSuffixField/useNameSuffix';
import {
    anisotropiesTreeDataCy,
    anisotropyGridDialogDataCy,
    centersErrorLabelDataCy,
    domainsDropdownDataCy,
    emptyAnisotropiesMessageDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { CoordinateKeys } from 'Common/types/geometryTypes';
import React from 'react';
import { tss } from 'tss-react/mui';
import { anisotropyGridSuffixPrefix } from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyConsts';
import { useSelectableTree } from 'App/MainApp/TreeView/components/checkboxTree/useSelectableTree';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { expandAnisotropyNodes } from '../Shared/dialogUtils';
import { useSessionContext } from 'App/context/SessionContext';
import ProjectTree from 'App/MainApp/TreeView/components/ProjectTree/ProjectTree';
import { ProjectTreeSource } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { getFirstLevelNodeIds } from 'App/MainApp/TreeView/treeUtils';
import { commonStyles } from 'styles/commonStyles';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create(({ theme }) => {
    const boundsColumnGap = 3;
    const boundsFieldWidth = (100 - 2 * boundsColumnGap) / 3;
    const fieldWidth = boundsFieldWidth * 2 + boundsColumnGap;
    const labelWidth = 100 - fieldWidth;
    return {
        defaultText: {
            ...commonStyles({ theme }).defaultText,
        },
        tree: {
            height: 240,
            flexGrow: 1,
            overflowY: 'auto',
            marginBottom: 0,
            marginTop: 20,
        },
        formField: {
            display: 'grid',
            gridTemplateColumns: `${labelWidth}% ${fieldWidth}%`,
            '&:last-child': {
                marginBottom: '20px',
            },
            alignItems: 'center',
        },
        gridSpacingTitle: {
            marginTop: '20px',
            marginBottom: '5px',
        },
        boundsField: {
            display: 'grid',
            gridTemplateColumns: '13% auto',
        },
        boundsContainer: {
            display: 'grid',
            gridTemplateColumns: `${boundsFieldWidth}% ${boundsFieldWidth}% ${boundsFieldWidth}%`,
            columnGap: `${boundsColumnGap}%`,
        },
    };
});

export type SpacingValidationResults = {
    [CoordinateKeys.x]: ValidationResult;
    [CoordinateKeys.y]: ValidationResult;
    [CoordinateKeys.z]: ValidationResult;
};

const spacingKeysToLabels = {
    [CoordinateKeys.x]: 'X:',
    [CoordinateKeys.y]: 'Y:',
    [CoordinateKeys.z]: 'Z:',
};

export function CreateAnisotropyGrid(props: { handleClose: () => void }) {
    const { classes } = useStyles();
    const xyz = useXyz();
    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const dispatch = useAppDispatch();

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const domains = useAppSelector(selectActiveDomains);
    const [selectedDomainId, setSelectedDomainId] = React.useState(Object.values(domains)[0].id);

    const anisotropies = useAppSelector(selectActiveAnisotropyEstimations);
    const anisotropySubsections = React.useMemo(
        () => buildAnisotropySubsections(anisotropies, selectedDomainId),
        [anisotropies, selectedDomainId]
    );

    const { onChange, nodeSelectionChecker, selectedLeafNodes } = useSelectableTree(anisotropySubsections, null, null);

    const numOfSelectedAnisotropies = selectedLeafNodes.length;

    const { nameSuffix, nameSuffixValidationResult, onNameSuffixChange, onNameSuffixBlur } = useNameSuffix();

    const [spacing, setSpacing] = React.useState<StringCoordinatesObject>({
        [CoordinateKeys.x]: '100',
        [CoordinateKeys.y]: '100',
        [CoordinateKeys.z]: '100',
    });

    const [spacingValidationResults, setSpacingValidationResults] = React.useState({
        [CoordinateKeys.x]: getDefaultValidationResult(),
        [CoordinateKeys.y]: getDefaultValidationResult(),
        [CoordinateKeys.z]: getDefaultValidationResult(),
    });

    const [numberOfCentersValidationResult, setNumberOfCentersValidationResult] =
        React.useState(getDefaultValidationResult());

    const selectedDomain = domains[selectedDomainId];
    const mesh = useAppSelector(selectObjectByClassnameAndId(OBJECT_CLASS_NAMES.MeshFile, selectedDomain.meshFileId));
    const drilling = useAppSelector(selectActiveDrilling);

    const isSpacingValid =
        spacingValidationResults.x.isValid && spacingValidationResults.y.isValid && spacingValidationResults.z.isValid;
    const numberOfCenters = isSpacingValid
        ? calculateNumberOfCenters(StringCoordinatesToNumbers(spacing), mesh, drilling)
        : NaN;
    const numberOfCentersDisplay = isNaN(numberOfCenters) ? '-' : numberOfCenters.toLocaleString();

    const onSelectedDomainChange = (event: SelectChangeEvent) => {
        setSelectedDomainId(event.target.value);
    };

    const generateOnSpacingChange = (key: CoordinateKeys) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSpacing({ ...spacing, [key]: event.target.value });
    };

    const generateOnSpacingBlur = () => () => {
        const validationResults = validateSpacings(spacing, mesh, drilling);
        setSpacingValidationResults(validationResults);
        setNumberOfCentersValidationResult(validateNumberOfCenters(numberOfCenters, validationResults));
    };

    const { onExpandChange } = useExpandedNodes();

    const isValidNumOfItemsSelected = numOfSelectedAnisotropies > 0;

    const disableSubmit =
        isSubmitting ||
        !nameSuffixValidationResult.isValid ||
        !isValidNumOfItemsSelected ||
        !isSpacingValid ||
        !numberOfCentersValidationResult.isValid;

    const numberOfFilteredAnisotropies = React.useMemo(() => {
        let numberOfFilteredAnisotropies = 0;

        Object.values(anisotropySubsections).forEach((anisotropy) => {
            numberOfFilteredAnisotropies += Object.keys(anisotropy.subsections ?? {}).length;
        });

        return numberOfFilteredAnisotropies;
    }, [anisotropySubsections]);

    const onSubmit = () => {
        if (!isValidNumOfItemsSelected || !nameSuffixValidationResult.isValid) {
            return;
        }

        const anisotropyIds = selectedLeafNodes.map((anisotropy) => anisotropy.id);

        void runAnisotropyGrid(
            {
                anisotropyIds: anisotropyIds,
                nameSuffix,
                xSpacing: Number(spacing.x),
                ySpacing: Number(spacing.y),
                zSpacing: Number(spacing.z),
            },
            axiosDriverFlask,
            currentProjectId,
            currentOrgUuid,
            currentWorkspaceId
        )
            .then((projectUpdateJson) => {
                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'submit anisotropy grid',
                        tokenProvider
                    )
                );
                expandAnisotropyNodes(onExpandChange, [selectedDomain.id]);
                props.handleClose();
            })
            .finally(() => {
                setIsSubmitting(false);
            });

        setIsSubmitting(true);
    };

    React.useEffect(() => {
        const anisotropyTreeNodeIds = getFirstLevelNodeIds(anisotropySubsections);
        onExpandChange(anisotropyTreeNodeIds, true, ProjectTreeSource.ANISOTROPY_GRID, true);
    }, []);

    return (
        <GenericDialogShell
            title="New Anisotropy Grid"
            dataCy={anisotropyGridDialogDataCy}
            maxWidth="sm"
            handleClose={props.handleClose}
        >
            <DialogContent>
                <Grid container flexDirection="column">
                    <Grid item container className={classes.formField}>
                        <Typography className={classes.defaultText}>Select closed mesh:</Typography>
                        <WidgetSelect
                            value={selectedDomainId}
                            onChange={onSelectedDomainChange}
                            data-cy={domainsDropdownDataCy}
                        >
                            {Object.values(domains).map((domain) => (
                                <MenuItem key={domain.id} value={domain.id} data-cy={domain.name}>
                                    {domain.name}
                                </MenuItem>
                            ))}
                        </WidgetSelect>
                    </Grid>
                    {numberOfFilteredAnisotropies ? (
                        <ProjectTree
                            checkboxTree
                            tree={anisotropySubsections}
                            dataCy={anisotropiesTreeDataCy}
                            onChange={onChange}
                            className={classes.tree}
                            nodeSelectionChecker={nodeSelectionChecker}
                            treeSource={ProjectTreeSource.ANISOTROPY_GRID}
                        />
                    ) : (
                        <Grid
                            item
                            container
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.tree}
                        >
                            <Typography data-cy={emptyAnisotropiesMessageDataCy} variant="caption">
                                No anisotropies for the chosen closed mesh have been found.
                            </Typography>
                        </Grid>
                    )}
                    <NameSuffixField
                        className={classes.formField}
                        prefix={anisotropyGridSuffixPrefix}
                        value={nameSuffix}
                        onChange={onNameSuffixChange}
                        validationResult={nameSuffixValidationResult}
                        onBlur={onNameSuffixBlur}
                    />

                    <Typography className={`${classes.gridSpacingTitle} ${classes.defaultText}`}>
                        Grid spacing
                    </Typography>
                    <BoundsRow
                        className={classes.boundsContainer}
                        keys={Object.values(CoordinateKeys)}
                        values={spacing}
                        keysToTitles={spacingKeysToLabels}
                        generateOnChange={generateOnSpacingChange}
                        textFieldClassName={classes.boundsField}
                        generateOnBlur={generateOnSpacingBlur}
                        validationResults={spacingValidationResults}
                    />
                    <Grid container className={classes.formField}>
                        <Typography className={`${classes.gridSpacingTitle} ${classes.defaultText}`}>
                            Number of centers:
                        </Typography>
                        <Typography className={classes.gridSpacingTitle}>{numberOfCentersDisplay}</Typography>
                        <ErrorLabel
                            errorText={numberOfCentersValidationResult.error}
                            show={!numberOfCentersValidationResult.isValid}
                            dataCy={centersErrorLabelDataCy}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <GenericDialogActions
                onSubmit={onSubmit}
                onCancel={props.handleClose}
                showSpinner={isSubmitting}
                disabled={disableSubmit}
            />
        </GenericDialogShell>
    );
}
