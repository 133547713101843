import { AnisotropyDisplayShapes } from 'App/MainApp/Plot/PlotRowTypes/PlotRowComponents/AnisotropyDisplayShape/types';
import { domainForAnisotropy } from 'App/MainApp/TreeView/convertProjectDataToTree';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { AnisotropyGlobalType } from 'App/util/ProjectDataTypes/AnisotropyGlobal';
import { ProjectObjectIdentifier } from 'App/util/ProjectDataTypes/ProjectObjectIdentifier';
import { ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { LvaTrace, LvaTraceSnapshot } from 'Common/Xyz/XyzTraces/LvaTrace';
import { InValidateAllColorValueSummaries, XyzTraceClassNames, XyzTraceId } from 'Common/Xyz/XyzTraces/XyzTrace';

function getplotName(anisotropyObject: AnisotropyGlobalType, objectClassName2id2obj: ObjectClassName2Id2Obj) {
    const domain = domainForAnisotropy(anisotropyObject, objectClassName2id2obj);
    const basicName = `${anisotropyObject.name} D[${domain?.name}]`;
    return basicName;
}

function createInitialLvaSnapshot(
    xyzTraceId: string,
    anisotropyObject: AnisotropyGlobalType,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string
): LvaTraceSnapshot {
    const lvaAttributeTraces = {} as LvaTraceSnapshot['colorAttributeTraces'];

    const basePlotName = getplotName(anisotropyObject, objectClassName2id2obj);
    lvaAttributeTraces[basePlotName] = {
        colorAttributeName: basePlotName,
        useSingleColor: true,
        colorValueSummariesValid: false,
        colorValueSummaries: undefined,
        colorArrayUrl: undefined,
    };

    const projectObjectIdentifier = new ProjectObjectIdentifier({
        objectClassName: anisotropyObject.object_class_name,
        id: anisotropyObject.id,
    });
    const jobUrl = projectObjectIdentifier.getObjectUrl(currentOrgUuid, objectClassName2id2obj);

    return {
        id: xyzTraceId,
        className: XyzTraceClassNames.LvaTrace,
        colorAttributeTraces: lvaAttributeTraces,
        enabled: true,
        visible: true,
        projectObjectIdentifier: {
            objectClassName: anisotropyObject.object_class_name,
            id: anisotropyObject.id,
        },
        selectedColorAttribute: basePlotName,
        userCanModifyNormalizeRanges: false,
        normalizedRanges: false,
        verticesUrl: `${jobUrl}?download_bytes_array=centers`,
        dipsUrl: `${jobUrl}?download_bytes_array=dips`,
        dipAzimuthsUrl: `${jobUrl}?download_bytes_array=dipAzimuths`,
        pitchesUrl: `${jobUrl}?download_bytes_array=pitches`,
        rangesUrl: `${jobUrl}?download_bytes_array=ranges`,
        normalizedRangesUrl: undefined,
        objectHash: anisotropyObject.updated_at,
        selectedDisplayShape: AnisotropyDisplayShapes.ELLIPSOIDS,
        normalizedRangeUrl: `${jobUrl}?default_display_range=true`,
        polaritiesUrl: `${jobUrl}?download_bytes_array=polarities`,
        plungeAzimuthsUrl: `${jobUrl}?download_bytes_array=plungeAzimuths`,
        plungesUrl: `${jobUrl}?download_bytes_array=plunges`,
    };
}

async function createInitialXyzLvaTrace(
    xyzTraceId: string,
    dispatch: AppDispatch,
    anisotropyObject: AnisotropyGlobalType,
    xyz: UseXyz,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    tokenProvider: () => Promise<string>
) {
    const initialLvaSnapshot = createInitialLvaSnapshot(
        xyzTraceId,
        anisotropyObject,
        objectClassName2id2obj,
        currentOrgUuid
    );

    const lvaTrace = new LvaTrace(xyz, initialLvaSnapshot, tokenProvider);
    await dispatch(lvaTrace.plotAndSave());
}

export function plotAnisotropyGlobalObject(
    anisotropyObject: AnisotropyGlobalType,
    xyzTraceId: XyzTraceId,
    xyz: UseXyz,
    currentOrgUuid: string,
    tokenProvider: () => Promise<string>
) {
    return async function plotLvaObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshot = selectXyzTrace(state, xyzTraceId);
        if (xyzTraceSnapshot) {
            const lvaTrace = new LvaTrace(xyz, xyzTraceSnapshot as LvaTraceSnapshot, tokenProvider);
            await lvaTrace.incrementObjectHash(dispatch, true, InValidateAllColorValueSummaries.No); // if the object was already enabled, we will increment the objectHash to force redownload of the data.
        } else {
            const objectClassName2id2obj = selectObjectClassname2Id2Obj(state);
            await createInitialXyzLvaTrace(
                xyzTraceId,
                dispatch,
                anisotropyObject,
                xyz,
                objectClassName2id2obj,
                currentOrgUuid,
                tokenProvider
            );
        }
    };
}
