import { ViewClass } from '@seequent/xyz';
import { Color } from '@seequent/xyz/lib/types/src/types';

export const XYZ_FIXED_RADIUS = '';
export const XYZ_FLAT_COLOR = '';
export const DEFAULT_TWEEN = { duration: 750 };

export const GREY_COLOR: Color = [128, 128, 128];

export const VIEW_TYPES_WITH_LABELS = [ViewClass.Points, ViewClass.Lines, ViewClass.Lineation, ViewClass.Structural];

export const SCENE_FLAT_COLOR_KEY = 'flat:attribute';
