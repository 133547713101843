import { getMenuIcon } from 'Common/utils/objectIconUtils';
import { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { tss } from 'tss-react/mui';
import React from 'react';

const useStyles = tss.create({
    icon: {
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        height: '16px',
        width: '16px',
        svg: {
            fontSize: '16px',
        },
    },
});

export default function MenuItemIcon(props: { name: string; iconOverride?: React.FC }) {
    const { classes } = useStyles();
    let Icon: null | ReactElement = null;
    if (props.iconOverride) {
        Icon = <props.iconOverride />;
    } else {
        Icon = getMenuIcon(props.name, classes.icon);
    }

    return (
        <>
            {Icon && (
                <Tooltip PopperProps={{ style: { zIndex: 10000 } }} title={props.name}>
                    {Icon}
                </Tooltip>
            )}
        </>
    );
}
