import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import * as React from 'react';

export function ChevronRightIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <path
                    fill="currentColor"
                    d="M18.24,13.57l-9.16,9.71v0c-0.64,0.68-1.64,0.9-2.51,0.56c-0.87-0.35-1.44-1.19-1.44-2.13V2.29
	c0-0.94,0.57-1.78,1.44-2.13c0.87-0.35,1.87-0.13,2.51,0.56l9.16,9.71v0C19.07,11.31,19.07,12.69,18.24,13.57L18.24,13.57z"
                />
            </svg>
        </SvgIcon>
    );
}

export default ChevronRightIcon;
