import { ProjectObject } from '../../../util/ProjectDataTypes/ProjectObjectsDataTypes';

export function fill_in_unmodified_keys_in_websocket_updated_object(
    updated_obj: ProjectObject,
    oldObject: ProjectObject,
    in_place = false
) {
    let updated_obj_copy: typeof updated_obj;
    if (in_place) {
        updated_obj_copy = updated_obj;
    } else {
        updated_obj_copy = { ...updated_obj };
    }
    for (const key in updated_obj) {
        if (updated_obj[key] === '$unmodified') {
            updated_obj_copy[key] = oldObject[key];
        }
    }

    for (const key in oldObject) {
        if (updated_obj[key] === undefined || updated_obj[key] === null) {
            updated_obj_copy[key] = oldObject[key];
        }
    }

    return updated_obj_copy;
}

export function newObjectHasMoreDataThanOldObject(newObject: ProjectObject, oldObject: ProjectObject) {
    for (const key of Object.keys(newObject)) {
        if (oldObject[key] === '$unmodified' && newObject[key] !== '$unmodified') {
            return true;
        }
    }
    return false;
}
