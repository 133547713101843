import { Point3D } from 'Common/types/geometryTypes';
import { ObjectIDType, ObjectStatusTypes, OBJECT_CLASS_NAMES } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { Dependants } from './utilClasses/Dependants';

export enum MeshTypes {
    closed = 'closed',
    open = 'open',
}

interface MeshFileMetaData {
    faces_count?: number;
    metadata_version?: number;
    vertices_count?: number;
    vertices_max?: Point3D;
    vertices_min?: Point3D;
}

export interface MeshFileType extends BackendJsonifiedProjectObject {
    id: ObjectIDType;
    original_file_name: string;
    type: MeshTypes;
    status: ObjectStatusTypes;
    meta_data?: MeshFileMetaData;
    object_class_name: OBJECT_CLASS_NAMES.MeshFile;
}

export class Mesh extends BaseProjectObject implements MeshFileType {
    original_file_name: string;

    type: MeshTypes;

    status: ObjectStatusTypes;

    meta_data?: MeshFileMetaData;

    object_class_name: OBJECT_CLASS_NAMES.MeshFile;

    getName = () => this.original_file_name;

    getDependants = () => {
        const domains = this.allObjects[OBJECT_CLASS_NAMES.Domain];

        const dependants = new Dependants();

        Object.values(domains).forEach((domain) => {
            if (domain.meshFileId === this.id) {
                dependants.addDependantAndItsDependantsRaw(domain, this.axiosDriverFlask, this.allObjects);
            }
        });

        return dependants;
    };
}
