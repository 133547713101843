import { backendProjectUrl } from 'App/Redux/features/globalContext/currentProjectSlice';
import { APIResponseWithProjectUpdate } from '../../../util/ProjectDataTypes/APIResponseTypes';
import { AxiosDriverFlaskInstance } from '../../../util/axiosErrorHandlers';
import { ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { OBJECT_CLASS_NAMES, ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';

export enum PointEstimationMethods {
    OrdinaryKriging = 'OK',
    InverseDistance = 'ID',
}

export type PointEstimationMethod = PointEstimationMethods;

export function runPointEstimations(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    projectId: string,
    namesSuffix: string,
    namesSuffixPrefix: string,
    sampleLimitsMin: string,
    sampleLimitsMax: string,
    maxSamplesPerDrillHole: string,
    maxSamplesPerOctant: string,
    pointEstimationMethod: PointEstimationMethod,
    anisotropyGlobalIds: (string | number)[],
    anisotropyGridIds: (string | number)[],
    anisotropyEstimationIds: (string | number)[],
    gridIds: (string | number)[],
    drillSourceId: string | number,
    orgId: string,
    workspaceId: string,
    powerParameter?: string
) {
    const formData = new FormData();

    const parameters: any = {
        pointEstimation_method: pointEstimationMethod,
        namesSuffixMandatory: namesSuffixPrefix,
        namesSuffix,
        sample_limits: [sampleLimitsMin, sampleLimitsMax],
        max_samples_per_drill_hole: maxSamplesPerDrillHole,
        max_samples_per_octant: maxSamplesPerOctant,
    };

    if (powerParameter !== undefined) {
        parameters.power = powerParameter;
    }

    formData.append('parameters', JSON.stringify(parameters));
    formData.append('anisotropyGlobalIDs', JSON.stringify(anisotropyGlobalIds));
    formData.append('anisotropyGridIDs', JSON.stringify(anisotropyGridIds));
    formData.append('anisotropyEstimationIDs', JSON.stringify(anisotropyEstimationIds));
    formData.append('grid_ids', JSON.stringify(gridIds));
    formData.append('drill_source_id', `${drillSourceId}`);

    return axiosDriverFlask.post<APIResponseWithProjectUpdate>(
        `${backendProjectUrl(orgId, workspaceId, projectId)}/pointEstimationRequest`,
        formData,
        {
            withCredentials: true,
            responseType: 'json',
        }
    );
}

export const getParamsForPointEstimation = (
    selectedAnisotropies: ProjectTreeObjectNode[],
    gridIds: ObjectIDType[],
    requiredNodeObjects
) => {
    const anisotropyGlobalIds = selectedAnisotropies
        .filter((anisotropy: ProjectTreeObjectNode) => anisotropy.className === OBJECT_CLASS_NAMES.AnisotropyGlobal)
        .map((anisotropy: ProjectTreeObjectNode) => anisotropy.id);

    const anisotropyGridIds = selectedAnisotropies
        .filter((anisotropy: ProjectTreeObjectNode) => anisotropy.className === OBJECT_CLASS_NAMES.AnisotropyGrid)
        .map((anisotropy: ProjectTreeObjectNode) => anisotropy.id);

    const anisotropyEstimationIds = selectedAnisotropies
        .filter((anisotropy: ProjectTreeObjectNode) => anisotropy.className === OBJECT_CLASS_NAMES.AnisotropyEstimation)
        .map((anisotropy: ProjectTreeObjectNode) => anisotropy.id);

    const domainIdsArray = selectedAnisotropies.map((anisotropy: ProjectTreeObjectNode) => {
        const nodeId = anisotropy.nodeId;
        const domainId = nodeId.split('_')[1];
        return domainId;
    });

    const domainGrid2DomainGridDefintionId = {};
    const domainGridDefinitionIds = [];

    const domainIds = domainIdsArray.filter((domainId: ObjectIDType, index: number) => {
        return domainIdsArray.indexOf(domainId) === index;
    });

    requiredNodeObjects.map((requiredNodeObject) => {
        const key = `${requiredNodeObject.domainId}_${requiredNodeObject.gridDefinitionId}`;
        domainGrid2DomainGridDefintionId[key] = requiredNodeObject.id;
    });

    domainIds.map((domainId) => {
        gridIds.map((gridId) => {
            const key = `${domainId}_${gridId}`;
            const domainGridDefintionId = domainGrid2DomainGridDefintionId[key];
            domainGridDefinitionIds.push(domainGridDefintionId);
        });
    });

    return { anisotropyGlobalIds, anisotropyGridIds, anisotropyEstimationIds, domainGridDefinitionIds };
};

export const expandPointEstimationNodes = (
    onExpandChange: (nodes: ObjectIDType[], expand: boolean) => void,
    gridIds: string[],
    domainGridDefinitionIds: string[]
) => {
    const interpolationSectionId = SectionIds[SectionNames.Interpolations];
    const gridIdsToExpand = [];
    let domainIdsToExpand = [];

    gridIds.map((gridId) => {
        const gridNodeId = `${interpolationSectionId}_${gridId}`;
        const domainGridDefintionNodeIdArr = domainGridDefinitionIds.map((domainId) => `${gridNodeId}_${domainId}`);
        gridIdsToExpand.push(gridNodeId);
        domainIdsToExpand = domainIdsToExpand.concat(domainGridDefintionNodeIdArr);
    });

    const nodesToExpand = [interpolationSectionId, ...gridIdsToExpand, ...domainIdsToExpand];

    onExpandChange(nodesToExpand, true);
};
