import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';

export type ExpandedTreeNodeMap = {
    [nodeId: ObjectIDType]: boolean;
};

export type ProjectTreeExpandedNodesType = {
    [treeSource: string]: ExpandedTreeNodeMap;
};
const initialState: ProjectTreeExpandedNodesType = {};

export const projectTreeExpandedNodesSlice = createSlice({
    name: 'projectTreeExpandedNodes',
    initialState,
    reducers: {
        setProjectTreeExpandedNodes: (state, action: PayloadAction<ProjectTreeExpandedNodesType>) => {
            Object.freeze(action.payload); // Freeze large object to improve immer performance
            return action.payload;
        },
    },
});

export const { setProjectTreeExpandedNodes } = projectTreeExpandedNodesSlice.actions;

export const selectProjectTreeExpandedNodes = (state: AppState): ProjectTreeExpandedNodesType => {
    return state.projectTreeExpandedNodes;
};

export default projectTreeExpandedNodesSlice.reducer;
