import {
    BoundsKey,
    BoundsKeys,
    BoundsObject,
    CoordinateKey,
    CoordinateKeys,
    CoordinatesObject,
} from 'Common/types/geometryTypes';
import { ValidationResult } from '../../../util/validationUtils';

export type BoundsValidationResults = {
    minX: ValidationResult;
    minY: ValidationResult;
    minZ: ValidationResult;
    maxX: ValidationResult;
    maxY: ValidationResult;
    maxZ: ValidationResult;
};

export type BlockSizesValidationResult = {
    x: ValidationResult;
    y: ValidationResult;
    z: ValidationResult;
};

export type BlocksDimensionsValidationResult = {
    xBlocks: ValidationResult;
    yBlocks: ValidationResult;
    zBlocks: ValidationResult;
};

export type BlocksDimensions = {
    xBlocks: number;
    yBlocks: number;
    zBlocks: number;
    totalBlocks: number;
};

export type BlocksDimensionsStrings = {
    xBlocks: string;
    yBlocks: string;
    zBlocks: string;
    totalBlocks: string;
};

export function blocksDimensionsToStrings(blocksDimensions: BlocksDimensions): BlocksDimensionsStrings {
    return {
        xBlocks: `${blocksDimensions.xBlocks}`,
        yBlocks: `${blocksDimensions.yBlocks}`,
        zBlocks: `${blocksDimensions.zBlocks}`,
        totalBlocks: `${blocksDimensions.totalBlocks}`,
    };
}

export function blocksStringsToNumbers(blocksStrings: BlocksDimensionsStrings): BlocksDimensions {
    return {
        xBlocks: Number(blocksStrings.xBlocks),
        yBlocks: Number(blocksStrings.yBlocks),
        zBlocks: Number(blocksStrings.zBlocks),
        totalBlocks: Number(blocksStrings.totalBlocks),
    };
}

export function getSizeInBlocks(bounds: BoundsObject, blockSizes: CoordinatesObject): BlocksDimensions {
    const xLength = bounds.maxX - bounds.minX;
    const yLength = bounds.maxY - bounds.minY;
    const zLength = bounds.maxZ - bounds.minZ;

    const xBlocks = Math.floor(xLength / blockSizes.x);
    const yBlocks = Math.floor(yLength / blockSizes.y);
    const zBlocks = Math.floor(zLength / blockSizes.z);

    const totalBlocks = xBlocks * yBlocks * zBlocks;

    return {
        xBlocks,
        yBlocks,
        zBlocks,
        totalBlocks,
    };
}

export function getUpperBoundsFromBlocks(
    oldBounds: BoundsObject,
    blockSizes: CoordinatesObject,
    sizeInBlocks: BlocksDimensions
): BoundsObject {
    const xLength = blockSizes.x * sizeInBlocks.xBlocks;
    const yLength = blockSizes.y * sizeInBlocks.yBlocks;
    const zLength = blockSizes.z * sizeInBlocks.zBlocks;

    const maxX = oldBounds.minX + xLength;
    const maxY = oldBounds.minY + yLength;
    const maxZ = oldBounds.minZ + zLength;

    return {
        ...oldBounds,
        maxX,
        maxY,
        maxZ,
    };
}

export function getUpperBoundFromNumOfBlocks(minBound: number, numOfBlocks: number, blockSize: number): number {
    return minBound + numOfBlocks * blockSize;
}

export function getNumOfBlocksFromBounds(minBound: number, maxBound: number, blockSize: number): number {
    return Math.floor((maxBound - minBound) / blockSize);
}

export enum BlocksKeys {
    xBlocks = 'xBlocks',
    yBlocks = 'yBlocks',
    zBlocks = 'zBlocks',
}

export type BlocksKey = BlocksKeys;

type Keys = Partial<{
    maxBound: BoundsKey;
    minBound: BoundsKey;
    coordinate: CoordinateKey;
    blocks: BlocksKey;
}>;

type KeysToKeys = { [key: string]: Keys };

export const blocksKeyToKeys: KeysToKeys = {
    [BlocksKeys.xBlocks]: {
        maxBound: BoundsKeys.maxX,
        minBound: BoundsKeys.minX,
        coordinate: CoordinateKeys.x,
    },
    [BlocksKeys.yBlocks]: {
        maxBound: BoundsKeys.maxY,
        minBound: BoundsKeys.minY,
        coordinate: CoordinateKeys.y,
    },
    [BlocksKeys.zBlocks]: {
        maxBound: BoundsKeys.maxZ,
        minBound: BoundsKeys.minZ,
        coordinate: CoordinateKeys.z,
    },
};

export const minBoundKeyToKeys: KeysToKeys = {
    [BoundsKeys.minX]: {
        maxBound: BoundsKeys.maxX,
        blocks: BlocksKeys.xBlocks,
        coordinate: CoordinateKeys.x,
    },
    [BoundsKeys.minY]: {
        maxBound: BoundsKeys.maxY,
        blocks: BlocksKeys.yBlocks,
        coordinate: CoordinateKeys.y,
    },
    [BoundsKeys.minZ]: {
        maxBound: BoundsKeys.maxZ,
        blocks: BlocksKeys.zBlocks,
        coordinate: CoordinateKeys.z,
    },
};

export const maxBoundKeyToKeys: KeysToKeys = {
    [BoundsKeys.maxX]: {
        minBound: BoundsKeys.minX,
        blocks: BlocksKeys.xBlocks,
        coordinate: CoordinateKeys.x,
    },
    [BoundsKeys.maxY]: {
        minBound: BoundsKeys.minY,
        blocks: BlocksKeys.yBlocks,
        coordinate: CoordinateKeys.y,
    },
    [BoundsKeys.maxZ]: {
        minBound: BoundsKeys.minZ,
        blocks: BlocksKeys.zBlocks,
        coordinate: CoordinateKeys.z,
    },
};

export const coordinateKeyToKeys: KeysToKeys = {
    [CoordinateKeys.x]: {
        maxBound: BoundsKeys.maxX,
        minBound: BoundsKeys.minX,
        blocks: BlocksKeys.xBlocks,
    },
    [CoordinateKeys.y]: {
        maxBound: BoundsKeys.maxY,
        minBound: BoundsKeys.minY,
        blocks: BlocksKeys.yBlocks,
    },
    [CoordinateKeys.z]: {
        maxBound: BoundsKeys.maxZ,
        minBound: BoundsKeys.minZ,
        blocks: BlocksKeys.zBlocks,
    },
};

export type StringCoordinatesObject = {
    x: string;
    y: string;
    z: string;
};

export function coordinatesToStrings(coordinates: CoordinatesObject): StringCoordinatesObject {
    return {
        x: `${coordinates.x}`,
        y: `${coordinates.y}`,
        z: `${coordinates.z}`,
    };
}

export function StringCoordinatesToNumbers(stringCoordinates: StringCoordinatesObject): CoordinatesObject {
    return {
        x: Number(stringCoordinates.x),
        y: Number(stringCoordinates.y),
        z: Number(stringCoordinates.z),
    };
}

export function getTotalBlocksNumber(sizesInBlocks: BlocksDimensionsStrings) {
    const { xBlocks, yBlocks, zBlocks } = sizesInBlocks;

    if (!xBlocks || !yBlocks || !zBlocks) {
        return NaN;
    }

    return Number(xBlocks) * Number(yBlocks) * Number(zBlocks);
}
