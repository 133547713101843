import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        // fill: '#265C7F',
    },
}));
export const SpotlightIcon = (props: SvgIconProps) => {
    const { classes } = useStyles();

    return (
        <SvgIcon className={classes.root} {...props}>
            <svg version="1.1" width="24px" height="24px" viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M21.99,19.17L14.76,0.32C14.69,0.13,14.5,0,14.29,0H9.25c-0.21,0-0.4,0.13-0.47,0.33L1.97,19.23
	c-0.2,0.31-0.32,0.63-0.32,0.99C1.64,22.67,6.98,24,12,24s10.36-1.33,10.36-3.79C22.36,19.84,22.22,19.49,21.99,19.17z M9.61,1.01
	h4.34l6.5,16.95c-2-1.01-5.29-1.53-8.45-1.53c-3.2,0-6.52,0.54-8.52,1.57L9.61,1.01z M12,22.99c-5.79,0-9.35-1.62-9.35-2.78
	s3.56-2.78,9.35-2.78s9.35,1.62,9.35,2.78S17.79,22.99,12,22.99z"
                />
            </svg>
        </SvgIcon>
    );
};

export default SpotlightIcon;
