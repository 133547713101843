import { getAccessToken } from 'App/Login/Session';

const ARRAY_TYPE_HEADER = 'data-type';
const ARRAY_SHAPE_HEADER = 'shape';
const CONTENT_TYPE_HEADER = 'content-type';
const OPAQUE_HEADER = 'opaque';

const AZURE_ARRAY_TYPE_HEADER = 'x-ms-meta-data_type';
const AZURE_ARRAY_SHAPE_HEADER = 'x-ms-meta-shape';
const AZURE_CONTENT_TYPE_HEADER = 'x-ms-meta-content_type';
const AZURE_OPAQUE_HEADER = 'x-ms-meta-opaque';

const DTYPE_MAPPING = {
    float64: Float64Array,
    float32: Float32Array,
    int32: Int32Array,
    uint32: Uint32Array,
    int8: Int8Array,
    uint8: Uint8Array,
};

function getLicenceToken() {
    return getAccessToken();
}

function buildHeaders(): HeadersInit | undefined {
    const licenceToken = getLicenceToken();
    if (!licenceToken) {
        return undefined;
    }
    return {
        authorization: `Bearer ${licenceToken}`,
    };
}

const bufferToTypedArray = (
    dataType: string,
    buffer: ArrayBuffer
): (typeof DTYPE_MAPPING)[keyof typeof DTYPE_MAPPING] => {
    const TypedArrayCtor = DTYPE_MAPPING[dataType];
    if (TypedArrayCtor) {
        return new TypedArrayCtor(buffer);
    }
    throw new Error(`Unknown data type: ${dataType}`);
};

export async function fetchBinaryArray(url: string, abortSignal?: AbortSignal, headers: HeadersInit = {}) {
    const response = await fetch(url, { signal: abortSignal, headers }); // .then((response) => response.arrayBuffer())
    const { headers: responseHeaders } = response;
    const { dataType } = getImageMetadataHeaders(responseHeaders);
    return bufferToTypedArray(dataType, await response.arrayBuffer());
}

function getImageMetadataHeaders(headers: Headers) {
    return {
        dataType: headers.get(AZURE_ARRAY_TYPE_HEADER) ?? headers.get(ARRAY_TYPE_HEADER),
        shape: headers.get(AZURE_ARRAY_SHAPE_HEADER) ?? headers.get(ARRAY_SHAPE_HEADER),
        contentType: headers.get(AZURE_CONTENT_TYPE_HEADER) ?? headers.get(CONTENT_TYPE_HEADER),
        opaque: headers.get(AZURE_OPAQUE_HEADER) ?? headers.get(OPAQUE_HEADER),
    };
}

export async function fetchNormalizedRange(url: string, headers: HeadersInit = {}) {
    return fetch(url, { headers }).then((response) => response.json());
}

export async function fetchJSON(url: string) {
    return fetch(url, { headers: buildHeaders() }).then((response) => response.json());
}
