import SecondaryItemMenu from 'App/MainApp/TreeView/menu/SecondaryItemMenu';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { UploadDrillingFromEvoButton } from 'App/MainApp/TreeView/menu/menuItems/setup/uploadDrilling/UploadDrillingFromEvoButton';
import { UploadDrillingFromLocalButton } from 'App/MainApp/TreeView/menu/menuItems/setup/uploadDrilling/UploadDrillingFromLocalButton';
import { selectActiveDrilling } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { DrillingSectionMenuItemNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { uploadDrillingDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function UploadDrillingButton(props: GeneralMenuItemProps) {
    const drillingObject = useAppSelector(selectActiveDrilling);
    const name = drillingObject
        ? DrillingSectionMenuItemNames.UpdateDrilling
        : DrillingSectionMenuItemNames.UploadDrilling;

    return (
        <SecondaryItemMenu itemName={name} dataCy={uploadDrillingDataCy}>
            <div>
                <UploadDrillingFromLocalButton handleMenuClose={props.handleMenuClose} />
                <UploadDrillingFromEvoButton handleMenuClose={props.handleMenuClose} />
            </div>
        </SecondaryItemMenu>
    );
}
