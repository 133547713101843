import { anisotropyObjectMenu } from 'App/MainApp/TreeView/menu/objectMenus';
import { getCommonData } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { AnisotropyEstimationType } from 'App/util/ProjectDataTypes/AnisotropyEstimation';
import { AnisotropyGlobalType } from 'App/util/ProjectDataTypes/AnisotropyGlobal';
import { AnisotropyGridType } from 'App/util/ProjectDataTypes/AnisotropyGrid';
import { ProjectTreeNode, ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';

export function getAnisotropyNode(
    anisotropy: AnisotropyEstimationType | AnisotropyGridType | AnisotropyGlobalType,
    parentNode: ProjectTreeNode
) {
    const anisotropyNode: ProjectTreeObjectNode = {
        ...getCommonData(anisotropy, parentNode),
        section: anisotropy.name,
        dataCy: anisotropy.name,
        rightClickMenu: anisotropyObjectMenu,
        canDisplaySpotlight: true,
    };

    return anisotropyNode;
}
