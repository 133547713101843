import { generateUUID } from 'Common/uids';
import { createContext, useCallback, useContext, useState } from 'react';

type ZIndexContextWrapper = [string, (uuid: string) => void];
type UseZIndex = [number, () => void];

export const BASE_Z_INDEX = 2;

export function useZIndexContext() {
    return useState('');
}

export const ZIndexContext = createContext<ZIndexContextWrapper | null>(null);
export default ZIndexContext;

export function useZIndex(baseZIndex: number = BASE_Z_INDEX): UseZIndex {
    const [uuid] = useState(generateUUID());
    const context = useContext(ZIndexContext);
    if (!context) {
        return [null, () => {}];
        // const error = new Error('useZIndex must be used within an ZIndexContext provider');
        // logException(error);
        // throw error;
    }
    const [elevatedUuid, setElevatedUuid] = context;

    const elevate = useCallback(() => {
        setElevatedUuid(uuid);
    }, [uuid, setElevatedUuid]);

    const zIndex = uuid === elevatedUuid ? baseZIndex + 1 : baseZIndex;

    return [zIndex, elevate];
}
