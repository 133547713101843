import { ObjectIDType, OBJECT_CLASS_NAMES } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { Dependants } from 'App/util/ProjectDataTypes/utilClasses/Dependants';

export interface OverlapFolderType extends BackendJsonifiedProjectObject {
    name: string;
    id: ObjectIDType;
    object_class_name: OBJECT_CLASS_NAMES.OverlapFolder;
}

export class OverlapFolder extends BaseProjectObject implements OverlapFolderType {
    object_class_name: OBJECT_CLASS_NAMES.OverlapFolder;

    getDependants = () => {
        const allOverlaps = this.allObjects[OBJECT_CLASS_NAMES.Overlap];
        const dependants = new Dependants();

        Object.values(allOverlaps).forEach((overlap) => {
            if (overlap.overlapProfileId === this.id) {
                dependants.addDependantAndItsDependantsRaw(overlap, this.axiosDriverFlask, this.allObjects);
            }
        });

        return dependants;
    };

    delete = () =>
        this.axiosDriverFlask.delete(this.url, {
            responseType: 'json',
        });
}
