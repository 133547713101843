import React from 'react';
import { DialogComponentData, closeDialog, openDialog } from 'App/Redux/features/DraggableWindows/dialogsSlice';
import { useAppDispatch } from 'App/Redux/hooks';
import { generateRandomId } from '../../../util/core';

export function useDialogOpener() {
    const [dialogId] = React.useState(generateRandomId(6)); // needs to be unique per each instance
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(closeDialog({ dialogId }));
    };

    return {
        openDialog: (dialogData: DialogComponentData) => {
            dispatch(
                openDialog({
                    dialogComponent: dialogData.dialogComponent,
                    dialogId,
                    dialogProps: {
                        ...dialogData.dialogProps,
                        handleClose,
                    },
                })
            );
        },
    };
}
