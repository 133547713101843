import AddToViewMenuItem from 'App/MainApp/TreeView/menu/menuItems/generic/AddToViewMenuItem';
import DeleteButton from 'App/MainApp/TreeView/menu/menuItems/generic/DeleteButton';
import DownloadButton from 'App/MainApp/TreeView/menu/menuItems/generic/publish/DownloadButton';
import { PublishButton } from 'App/MainApp/TreeView/menu/menuItems/generic/publish/PublishButton';
import { OverlapsDialogButton } from 'App/MainApp/TreeView/menu/menuItems/overlaps/OverlapsDialogButton';
import { RenameOverlapsFolderDialogButton } from 'App/MainApp/TreeView/menu/menuItems/overlaps/OverlapsFolderDialogButtons';
import { UpdateGridDialogButton } from 'App/MainApp/TreeView/menu/menuItems/setup/UpdateGridDialogButton';
import { RightClickMenu } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { UploadDrillingButton } from './menuItems/setup/uploadDrilling/UploadDrillingButton';
import CreateInverseDistanceButton from './menuItems/interpolation/CreateInverseDistanceButton';
import CreateOrdinaryKrigingButton from './menuItems/interpolation/CreateOrdinaryKrigingButton';
import CreateMergedInterpolationsButton from './menuItems/interpolation/CreateMergedInterpolationsButton';

export function getMakeInterpolationsMenuItems() {
    return [CreateInverseDistanceButton, CreateOrdinaryKrigingButton, CreateMergedInterpolationsButton];
}

function getCommonObjectMenu(allowDelete = true, allowDownload = true, publishToEvo = false) {
    const menu = [AddToViewMenuItem];

    if (allowDelete) {
        menu.push(DeleteButton);
    }

    if (publishToEvo) {
        menu.push(PublishButton);
    } else if (allowDownload) {
        menu.push(DownloadButton);
    }

    return menu;
}

export const drillingAttributesMenu = getCommonObjectMenu(false);
export const drillingObjectMenu = [UploadDrillingButton, ...drillingAttributesMenu];
export const gridObjectMenu = [UpdateGridDialogButton, DeleteButton, ...getMakeInterpolationsMenuItems()];
export const meshObjectMenu = getCommonObjectMenu(true, true, true);
export const domainSamplesMenu = getCommonObjectMenu(false);
export const domainGridMenu = getCommonObjectMenu(false);
export const anisotropyObjectMenu = getCommonObjectMenu(true, true, true);
export const interpolationObjectMenu = getCommonObjectMenu();
export const zoneObjectMenu = getCommonObjectMenu(true, true, true);
export const overlapFolderObjectMenu = [OverlapsDialogButton, DeleteButton, RenameOverlapsFolderDialogButton];
export const overlapObjectMenu = getCommonObjectMenu(true, true, true);

export function doesSupportAddToView(menu: RightClickMenu) {
    return (menu || []).filter((component) => component === AddToViewMenuItem).length > 0;
}
