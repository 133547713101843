import React from 'react';

export function openURLWithGlobalAnchor(
    url: string,
    target = '_blank',
    globalDownloadHiddenAnchorRef: React.MutableRefObject<HTMLAnchorElement>
) {
    globalDownloadHiddenAnchorRef.current.target = target;
    globalDownloadHiddenAnchorRef.current.href = url;
    globalDownloadHiddenAnchorRef.current.click();
}

export default {
    openURLWithGlobalAnchor,
};
