import { Point3D } from 'Common/types/geometryTypes';
import { OBJECT_CLASS_NAMES, SourceFileKind } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';

export type DrillingAttributeType = {
    avg_horizontal_drill_hole_spacing: number;
    hist: number[];
    hist_NUM_BINS: number;
    hist_first_two_bin_edges: [number, number];
    mean: number;
    median: number;
    num_samples: number;
    std: number;
};

export type XYZColumns = {
    holeid: string;
    x: string;
    y: string;
    z: string;
    xyzMax: Point3D;
    xyzMin: Point3D;
};

export type SourceFileMetaDataType = {
    dataAttribute2info: { [key: string]: DrillingAttributeType };
    dataAttributes: string[];
    metadata_version: number;
    xyzColumns: XYZColumns;
    avg_horizontal_drill_hole_spacing: number;
};

export interface SourceFileType extends BackendJsonifiedProjectObject {
    name: string;
    object_class_name: OBJECT_CLASS_NAMES.SourceFile;
    locked: false;
    meta_data: SourceFileMetaDataType;
    original_file_name: string;
    type: SourceFileKind;
}

export class Drilling extends BaseProjectObject implements SourceFileType {
    name: string;

    object_class_name: OBJECT_CLASS_NAMES.SourceFile;

    locked: false;

    meta_data: SourceFileMetaDataType;

    original_file_name: string;

    type: SourceFileKind;

    getName = () => this.name || this.original_file_name;
}
