import { anisotropyGlobalSuffixPrefix } from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyConsts';
import { backendProjectUrl } from 'App/Redux/features/globalContext/currentProjectSlice';
import { APIResponseWithProjectUpdate } from 'App/util/ProjectDataTypes/APIResponseTypes';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';

type AnisotropyGlobalJobData = {
    anisotropy_estimation_ids: ObjectIDType[];
    name_suffix: string;
};

export function runAnisotropyGlobal(
    data: {
        anisotropyIds: ObjectIDType[];
        nameSuffix: string;
    },
    axiosDriverFlask: AxiosDriverFlaskInstance,
    projectId: ObjectIDType,
    orgId: string,
    workspaceId: string
) {
    const dataForServer: AnisotropyGlobalJobData = {
        anisotropy_estimation_ids: data.anisotropyIds,
        name_suffix: `${anisotropyGlobalSuffixPrefix}${data.nameSuffix}`,
    };

    const formData = new FormData();
    formData.append('parameters', JSON.stringify(dataForServer));

    return axiosDriverFlask.post<APIResponseWithProjectUpdate>(
        `${backendProjectUrl(orgId, workspaceId, projectId)}/anisotropyGlobal`,
        formData,
        {
            withCredentials: true,
            responseType: 'json',
        }
    );
}
