import { overlapsSectionMenu } from 'App/MainApp/TreeView/menu/sectionMenus';
import { getOverlapFolderNode, getOverlapNode } from 'App/MainApp/TreeView/treeData/overlapNodesService';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { getActiveObjects } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { ProjectTreeNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { overlapsSectionDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function buildOverlapsSubtree(allObjects: ObjectClassName2Id2Obj, tree: Subsections) {
    const nodeId = SectionIds[SectionNames.Overlaps];

    tree[SectionNames.Overlaps] = {
        section: SectionNames.Overlaps,
        dataCy: overlapsSectionDataCy,
        rightClickMenu: overlapsSectionMenu,
        subsections: {},
        nodeId,
        path: [],
    };

    buildOverlapFoldersSubtree(allObjects, tree[SectionNames.Overlaps]);
}

function buildOverlapFoldersSubtree(allObjects: ObjectClassName2Id2Obj, parentNode: ProjectTreeNode) {
    const overlapFolders = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.OverlapFolder);

    overlapFolders.forEach((overlapFolder) => {
        parentNode.subsections[overlapFolder.id] = getOverlapFolderNode(overlapFolder, parentNode);
    });

    const overlaps = getActiveObjects(allObjects, OBJECT_CLASS_NAMES.Overlap);

    overlaps.forEach((overlap) => {
        const overlapFolderNode = parentNode.subsections[overlap.overlapProfileId];
        overlapFolderNode.subsections[overlap.id] = getOverlapNode(overlap, allObjects, overlapFolderNode);
    });
}
