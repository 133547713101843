import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { Overlap, OverlapType } from 'App/util/ProjectDataTypes/Overlap';
import { ProjectObjectIdentifier } from 'App/util/ProjectDataTypes/ProjectObjectIdentifier';
import { ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';
import { MeshTrace, MeshTraceSnapshot } from 'Common/Xyz/XyzTraces/MeshTrace';
import { XyzTraceClassNames, XyzTraceId } from 'Common/Xyz/XyzTraces/XyzTrace';

function createInitialMeshSnapshot(
    xyzTraceId: string,
    overlapObject: OverlapType,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string
): MeshTraceSnapshot {
    const projectObjectIdentifier = new ProjectObjectIdentifier({
        objectClassName: overlapObject.object_class_name,
        id: overlapObject.id,
    });
    const jobUrl = projectObjectIdentifier.getObjectUrl(currentOrgUuid, objectClassName2id2obj);

    const overlap = wrapObjectInClass(overlapObject, undefined, objectClassName2id2obj) as Overlap;

    return {
        id: xyzTraceId,
        className: XyzTraceClassNames.MeshTrace,
        enabled: true,
        visible: true,
        name: overlap.getName(),
        projectObjectIdentifier: {
            objectClassName: overlapObject.object_class_name,
            id: overlapObject.id,
        },
        verticesUrl: `${jobUrl}?vertices_bytes_array=true`,
        trianglesUrl: `${jobUrl}?triangles_bytes_array=true`,
        vertices: undefined,
        triangles: undefined,
        objectHash: overlapObject.updated_at,
    };
}

async function createInitialXyzMeshTrace(
    xyzTraceId: string,
    dispatch: AppDispatch,
    overlapObject: OverlapType,
    xyz: UseXyz,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string
) {
    const initialMeshSnapshot = createInitialMeshSnapshot(
        xyzTraceId,
        overlapObject,
        objectClassName2id2obj,
        currentOrgUuid
    );

    const meshTrace = new MeshTrace(xyz, initialMeshSnapshot);
    await dispatch(meshTrace.plotAndSave());
}

export function plotOverlapObject(
    overlapObject: OverlapType,
    xyzTraceId: XyzTraceId,
    xyz: UseXyz,
    currentOrgUuid: string
) {
    return async function plotMeshObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshot = selectXyzTrace(state, xyzTraceId);
        if (xyzTraceSnapshot) {
            const meshTrace = new MeshTrace(xyz, xyzTraceSnapshot as MeshTraceSnapshot);
            await dispatch(meshTrace.plotAndSave());
        } else {
            const objectClassName2id2obj = selectObjectClassname2Id2Obj(state);
            await createInitialXyzMeshTrace(
                xyzTraceId,
                dispatch,
                overlapObject,
                xyz,
                objectClassName2id2obj,
                currentOrgUuid
            );
        }
    };
}
