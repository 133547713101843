import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import { SearchSvg } from '@local/svgs/dist/svg/SearchSvg';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';
import { FieldDefinition, ListItem } from '@local/web-design-system/dist/components/GenericListing';
import { SortedList } from '@local/web-design-system/dist/components/PaginatedList';
import { TableLoadingSkeleton } from '@local/web-design-system/dist/components/TableLoadingSkeleton';
import classnames from 'classnames';
import { tss } from 'tss-react/mui';
import React from 'react';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';

const LOADING_STATE_HEIGHT_OFFSET = 240;

export const useStyles = tss.create(({ theme }) => ({
    fullHeight: {
        height: `calc(100vh - ${LOADING_STATE_HEIGHT_OFFSET}px)`,
    },
    noSearchResults: {
        paddingTop: theme.spacing(6),
    },
    error: {
        minHeight: theme.spacing(72),
    },
}));

export type ListItem = {
    id: ObjectIDType;
};

function SortedListItem(props: {
    item: ListItem;
    // The way that SortedList is implemented requires sortKey to be a property on all the children it sorts.
    // eslint-disable-next-line react/no-unused-prop-types
    sortKey: string;
    registerForSorting?: (sortFieldsFunc: () => any) => void;
    onClick: () => void;
    isSelected: boolean;
    fields: FieldDefinition[];
}) {
    props.registerForSorting(() => props.item);

    return (
        <ListItem
            key={props.item.id}
            item={props.item}
            fields={props.fields}
            clickHandler={props.onClick}
            selected={props.isSelected}
        />
    );
}

export default function ListView(props: {
    items: ListItem[];
    fields: FieldDefinition[];
    isLoading?: boolean;
    selectedItem?: ObjectIDType;
    onItemClick: (itemId: ObjectIDType) => void;
    noItemsMessage: string;
}) {
    const { classes } = useStyles();

    if (!props.items) {
        return (
            <div className={classnames(classes.fullHeight, classes.error)}>
                <ErrorScreen msg="Error" details="There was an error" />
            </div>
        );
    }

    return (
        <SortedList
            emptyMessage={
                <EmptyState
                    title={props.noItemsMessage}
                    message=""
                    image={<SearchSvg />}
                    classes={{ root: classes.noSearchResults }}
                />
            }
            isEmpty={!props.isLoading && props.items.length === 0}
            itemDef={props.fields}
            stickyHeader
        >
            {props.isLoading ? (
                <TableLoadingSkeleton rows={3} columns={4} />
            ) : (
                props.items.map((item) => (
                    <SortedListItem
                        key={item.id}
                        item={item}
                        sortKey={item.id}
                        onClick={() => props.onItemClick(item.id)}
                        isSelected={props.selectedItem === item.id}
                        fields={props.fields}
                    />
                ))
            )}
        </SortedList>
    );
}
