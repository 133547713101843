import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { DataContext } from 'App/DataContext';
import { downloadObject, isPublishDisabled } from 'App/MainApp/services/objectDownloadService';
import {
    selectObjectClassname2Id2Obj,
    selectcurrentProjectUrl,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { ProjectObject } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { GeneralMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { downloadMenuItemDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import Spinner from 'Common/components/Core/Spinner';
import { ObjectMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import MenuItemIcon from '../../../MenuItemIcon';
import { useSessionContext } from 'App/context/SessionContext';

export default function DownloadButton(
    props: ObjectMenuItemProps & {
        itemName?: string;
    }
) {
    const { menuItemClass, currentOrgUuid } = React.useContext(DataContext);
    const { axiosDriverFlask } = useSessionContext();

    const itemName = props.itemName ? props.itemName : GeneralMenuItems.download;

    const allProjectObjects = useAppSelector(selectObjectClassname2Id2Obj);
    const projectObject = allProjectObjects[props.nodeData.className][props.nodeData.id] as ProjectObject;

    const currentProjectUrl = useAppSelector(selectcurrentProjectUrl);

    const onClick = async () => {
        setIsLoading(true);
        await downloadObject(projectObject, axiosDriverFlask, currentProjectUrl, currentOrgUuid);
        setIsLoading(false);
        props.handleMenuClose();
    };

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const { isValid, error } = isPublishDisabled(projectObject, allProjectObjects, GeneralMenuItems.download);

    return (
        <Tooltip disableInteractive title={error} placement="right">
            <div>
                <MenuItem
                    id={downloadMenuItemDataCy}
                    onClick={onClick}
                    disabled={!isValid}
                    data-cy={downloadMenuItemDataCy}
                    className={menuItemClass.menuItem}
                >
                    <MenuItemIcon name={itemName} />
                    {itemName}
                    <Spinner size={18} show={isLoading} style={{ marginLeft: '15px' }} />
                </MenuItem>
            </div>
        </Tooltip>
    );
}
