import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { tss } from 'tss-react/mui';
import WidgetTooltip from '../Core/WidgetTooltip';

const useStyles = tss.create(() => ({
    root: {
        // fill: '#265C7F',
    },
}));

export const DEFAULT_ERROR_MESSAGE = 'Unexpected error. Please contact support...';

export const FailedStatusIcon = (props: SvgIconProps & { errorMessage: string }) => {
    const { classes } = useStyles();

    const { errorMessage, ...restProps } = props;

    return (
        <WidgetTooltip title={errorMessage}>
            <SvgIcon className={classes.root} {...restProps}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.3346 8.00033C15.3346 12.0504 12.0514 15.3337 8.0013 15.3337C3.95121 15.3337 0.667969 12.0504 0.667969 8.00033C0.667969 3.95024 3.95121 0.666992 8.0013 0.666992C12.0514 0.666992 15.3346 3.95024 15.3346 8.00033ZM14.5013 8.00033C14.5013 11.5902 11.5912 14.5003 8.0013 14.5003C4.41145 14.5003 1.5013 11.5902 1.5013 8.00033C1.5013 4.41047 4.41145 1.50033 8.0013 1.50033C11.5912 1.50033 14.5013 4.41047 14.5013 8.00033ZM7.36338 9.33366H8.58903L8.69178 3.66699H7.26797L7.36338 9.33366ZM7.40222 10.7089C7.26827 10.8638 7.2013 11.0556 7.2013 11.2841C7.2013 11.5127 7.26827 11.7019 7.40222 11.8518C7.53616 12.0016 7.73463 12.0765 7.99765 12.0765C8.26066 12.0765 8.46036 12.0016 8.59674 11.8518C8.73311 11.7019 8.8013 11.5127 8.8013 11.2841C8.8013 11.0556 8.73311 10.8638 8.59674 10.7089C8.46036 10.554 8.26066 10.4765 7.99765 10.4765C7.73463 10.4765 7.53616 10.554 7.40222 10.7089Z"
                        fill="#1D1F23"
                    />
                    <mask
                        id="mask0_2320_89991"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="16"
                        height="16"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.3346 8.00033C15.3346 12.0504 12.0514 15.3337 8.0013 15.3337C3.95121 15.3337 0.667969 12.0504 0.667969 8.00033C0.667969 3.95024 3.95121 0.666992 8.0013 0.666992C12.0514 0.666992 15.3346 3.95024 15.3346 8.00033ZM14.5013 8.00033C14.5013 11.5902 11.5912 14.5003 8.0013 14.5003C4.41145 14.5003 1.5013 11.5902 1.5013 8.00033C1.5013 4.41047 4.41145 1.50033 8.0013 1.50033C11.5912 1.50033 14.5013 4.41047 14.5013 8.00033ZM7.36338 9.33366H8.58903L8.69178 3.66699H7.26797L7.36338 9.33366ZM7.40222 10.7089C7.26827 10.8638 7.2013 11.0556 7.2013 11.2841C7.2013 11.5127 7.26827 11.7019 7.40222 11.8518C7.53616 12.0016 7.73463 12.0765 7.99765 12.0765C8.26066 12.0765 8.46036 12.0016 8.59674 11.8518C8.73311 11.7019 8.8013 11.5127 8.8013 11.2841C8.8013 11.0556 8.73311 10.8638 8.59674 10.7089C8.46036 10.554 8.26066 10.4765 7.99765 10.4765C7.73463 10.4765 7.53616 10.554 7.40222 10.7089Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask0_2320_89991)">
                        <rect x="-8" y="-8.66699" width="33.3333" height="33.3333" fill="#E63B38" />
                    </g>
                </svg>
            </SvgIcon>
        </WidgetTooltip>
    );
};

export default FailedStatusIcon;
