import { CreationReason, ObjectIDType } from './ProjectObjectsDataTypes';

export interface JobVersionPointer {
    id: ObjectIDType;
    projectId: ObjectIDType;
    url: string;
    creationReason: CreationReason;
}

export interface JobHistorySnapshot {
    nextVersions: JobVersionPointer[];
    previousVersions: JobVersionPointer[];
}

export class ProjectObjectHistory implements JobHistorySnapshot {
    readonly nextVersions: JobHistorySnapshot['nextVersions'];

    readonly previousVersions: JobHistorySnapshot['previousVersions'];

    constructor(snapshot: JobHistorySnapshot) {
        this.nextVersions = snapshot?.nextVersions;
        this.previousVersions = snapshot?.previousVersions;
    }

    numOfNewerVersions = () => this.nextVersions?.length || 0;

    takeSnapshot = (): JobHistorySnapshot => ({
        nextVersions: this.nextVersions,
        previousVersions: this.previousVersions,
    });
}
