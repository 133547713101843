import {
    applyWebsocketModificationsToCurrentProject,
    backendProjectUrl,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import { AppDispatch } from 'App/Redux/store';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import {
    ProjectTreeObjectNode,
    ProjectTreeSectionNode,
    Subsections,
} from '../../../../util/ProjectDataTypes/MainTreeDataTypes';
import { AxiosDriverFlaskInstance } from '../../../../util/axiosErrorHandlers';
import { APIResponseWithProjectUpdate } from '../../../../util/ProjectDataTypes/APIResponseTypes';
import { Id2PointEstimationObject, ObjectIDType } from '../../../../util/ProjectDataTypes/ProjectObjectsDataTypes';
import { getNodePath } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { SectionIds, SectionNames, mergedDomainId } from 'App/MainApp/TreeView/treeData/treeConsts';
import { GridsSubtreeType } from 'App/MainApp/TreeView/treeData/commonNodesService';

/* filter the interpolations tree by grid and element name */
export function getFilteredPointEstimationsTree(
    interpolationsSection: ProjectTreeSectionNode,
    gridId: number | string,
    elementName: string,
    allPointEstimations: Id2PointEstimationObject
): Subsections {
    const chosenGridSubtree = interpolationsSection.subsections?.[gridId];

    if (!chosenGridSubtree) {
        // no interpolations belong to this grid
        return {};
    }

    const domainsSubsection = Object.keys(chosenGridSubtree.subsections).reduce((domainsSubsection, domainId) => {
        const domain = chosenGridSubtree.subsections[domainId];
        const pointEstimationsSubsection = Object.keys(domain.subsections).reduce(
            (pointEstimationsSubsection: Subsections, pointEstimationId) => {
                const interpolation = allPointEstimations[pointEstimationId];
                if (interpolation.dataAttribute_in_drill === elementName) {
                    pointEstimationsSubsection[pointEstimationId] = {
                        section: interpolation.name,
                        dataCy: interpolation.name,
                        id: pointEstimationId,
                        nodeId: pointEstimationId,
                        className: interpolation.object_class_name,
                        path: getNodePath(domain),
                    };
                }
                return pointEstimationsSubsection;
            },
            {}
        );

        const filteredDomain = {
            ...domain,
            subsections: pointEstimationsSubsection,
        };

        if (Object.keys(pointEstimationsSubsection).length) {
            domainsSubsection[domainId] = filteredDomain;
        }

        return domainsSubsection;
    }, {} as Subsections);

    return domainsSubsection;
}

export async function createMergedPointEstimation(
    xyz: UseXyz,
    interpolations: ProjectTreeObjectNode[],
    gridId: number | string,
    elementName: string,
    mergedPointEstimationName: string,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentProjectId: string,
    dispatch: AppDispatch,
    orgId: string,
    workspaceId: string,
    tokenProvider: () => Promise<string>
) {
    const formData = new FormData();

    const pointEstimationIds = interpolations.map((interpolation) => interpolation.id);

    const parameters = {
        name: mergedPointEstimationName,
    };

    formData.append('pointEstimationIDs', JSON.stringify(pointEstimationIds));
    formData.append('dataAttribute', elementName);
    formData.append('parameters', JSON.stringify(parameters));
    formData.append('grid_id', gridId.toString());

    try {
        const result = await axiosDriverFlask.post<APIResponseWithProjectUpdate>(
            `${backendProjectUrl(orgId, workspaceId, currentProjectId)}/mergedPointEstimationRequest`,
            formData,
            {
                withCredentials: true,
                responseType: 'json',
            }
        );

        dispatch(
            applyWebsocketModificationsToCurrentProject(
                xyz,
                axiosDriverFlask,
                result.data,
                currentProjectId,
                'submit merged interpolation',
                tokenProvider
            )
        );
        return result;
    } catch {
        return null;
    }
}

export const expandMergedPointEstimationNodes = (
    onExpandChange: (nodes: ObjectIDType[], expand: boolean) => void,
    gridIds: string[]
) => {
    const interpolationSectionId = SectionIds[SectionNames.Interpolations];

    const gridIdsToExpand = gridIds.map((gridId) => `${interpolationSectionId}_${gridId}`);
    const mergedInterpolationsToExpand = gridIds.map(
        (gridId) => `${GridsSubtreeType.Interpolation}-${gridId}-${mergedDomainId}`
    );

    const nodesToExpand = [interpolationSectionId, ...gridIdsToExpand, ...mergedInterpolationsToExpand];

    onExpandChange(nodesToExpand, true);
};
