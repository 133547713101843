import { isEqual } from 'lodash';
import React from 'react';
import { ControlPosition } from 'react-draggable';

const BASE_DEFAULT_POSITION = { x: 0, y: 0 };
type UseDefaultPosition = [ControlPosition, (x: number, y: number) => void];

export function useDefaultPosition(defaultPos, DEFAULT_POSITION = BASE_DEFAULT_POSITION): UseDefaultPosition {
    const [defaultPanelPosition, setDefaultPanelPosition] = React.useState<ControlPosition>(null);

    React.useEffect(() => {
        defaultPosition();
    }, [defaultPos]);

    const defaultPosition = () => {
        const currentPosition = defaultPos;
        if (currentPosition && !isEqual(currentPosition, DEFAULT_POSITION)) {
            setDefaultPanelPosition(currentPosition);
        }
        const position = {
            x: DEFAULT_POSITION.x,
            y: DEFAULT_POSITION.y,
        };
        setDefaultPanelPosition(position);
    };

    const positionChangeHandler = React.useCallback(
        (x: number, y: number) => {
            setDefaultPanelPosition({ x, y });
        },
        [setDefaultPanelPosition]
    );

    const position = defaultPanelPosition || BASE_DEFAULT_POSITION;
    return [position, positionChangeHandler];
}
