import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { ProjectObjectIdentifier } from 'App/util/ProjectDataTypes/ProjectObjectIdentifier';
import { ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { BlockModelTrace, BlockModelTraceSnapshot } from 'Common/Xyz/XyzTraces/BlockModelTrace';
import { InValidateAllColorValueSummaries, XyzTraceClassNames, XyzTraceId } from 'Common/Xyz/XyzTraces/XyzTrace';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';
import { DomainGrid, DomainGridDefinitionType } from 'App/util/ProjectDataTypes/DomainGridDefinition';

// const COLOR_ATTRIBUTE_ID2DISPLAY_NAME = {
//     drillhole_support: 'drillhole support',
//     misclassification_score: 'error rate',
//     enabled: 'enabled',
// };

function getplotName(domainGrid: DomainGrid) {
    return domainGrid.getName();
}

function createInitialBlockModelSnapshot(
    xyzTraceId: string,
    domainGridDefinition: DomainGrid,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string
): BlockModelTraceSnapshot {
    const blockModelAttributeTraces = {} as BlockModelTraceSnapshot['colorAttributeTraces'];

    const projectObjectIdentifier = new ProjectObjectIdentifier({
        objectClassName: domainGridDefinition.object_class_name,
        id: domainGridDefinition.id,
    });
    const jobUrl = projectObjectIdentifier.getObjectUrl(currentOrgUuid, objectClassName2id2obj);

    const basePlotName = getplotName(domainGridDefinition);
    blockModelAttributeTraces[basePlotName] = {
        colorAttributeName: basePlotName,
        useSingleColor: false,
        colorValueSummariesValid: false,
        colorValueSummaries: undefined,
        colorArrayUrl: `${jobUrl}?download_bytes_array=in`,
    };

    const blockSize = domainGridDefinition.getBlockSize();
    const blockCount = domainGridDefinition.getBlockCount();
    const origin = domainGridDefinition.getOrigin();

    return {
        id: xyzTraceId,
        className: XyzTraceClassNames.BlockModelTrace,
        colorAttributeTraces: blockModelAttributeTraces,
        enabled: true,
        visible: true,
        projectObjectIdentifier: {
            objectClassName: domainGridDefinition.object_class_name,
            id: domainGridDefinition.id,
        },
        selectedColorAttribute: basePlotName,
        objectHash: domainGridDefinition.updated_at,
        blockSize: blockSize,
        blockCount: blockCount,
        origin: origin,
        blockSizeUrl: undefined,
        blockCountUrl: undefined,
        originUrl: undefined,
    };
}

async function createInitialXyzBlockModelTrace(
    xyzTraceId: string,
    dispatch: AppDispatch,
    domainGridDefinition: DomainGrid,
    xyz: UseXyz,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    tokenProvider: () => Promise<string>
) {
    const initialLvaSnapshot = createInitialBlockModelSnapshot(
        xyzTraceId,
        domainGridDefinition,
        objectClassName2id2obj,
        currentOrgUuid
    );

    const blockModelTrace = new BlockModelTrace(xyz, initialLvaSnapshot, tokenProvider);
    await dispatch(blockModelTrace.plotAndSave());
}

export function plotDomainGridDefinitionBlockModel(
    domainGridDefinitionSnapshot: DomainGridDefinitionType,
    xyzTraceId: XyzTraceId,
    xyz: UseXyz,
    currentOrgUuid: string,
    tokenProvider: () => Promise<string>
) {
    return async function plotLvaObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshot = selectXyzTrace(state, xyzTraceId);
        const objectClassName2id2obj = selectObjectClassname2Id2Obj(state);

        const domainGridDefinition = wrapObjectInClass(
            domainGridDefinitionSnapshot,
            undefined,
            objectClassName2id2obj
        ) as DomainGrid;
        if (xyzTraceSnapshot) {
            const blockModelTrace = new BlockModelTrace(
                xyz,
                xyzTraceSnapshot as BlockModelTraceSnapshot,
                tokenProvider
            );
            await blockModelTrace.incrementObjectHash(dispatch, true, InValidateAllColorValueSummaries.No); // if the object was already enabled, we will increment the objectHash to force redownload of the data.
        } else {
            await createInitialXyzBlockModelTrace(
                xyzTraceId,
                dispatch,
                domainGridDefinition,
                xyz,
                objectClassName2id2obj,
                currentOrgUuid,
                tokenProvider
            );
        }
    };
}
