import LabeledTextField from 'App/MainApp/Dialogs/Shared/LabeledTextField';
import { ValidationResult } from 'App/util/validationUtils';
import { nameSuffixTextboxDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function NameSuffixField(props: {
    className?: string;
    prefix?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    validationResult: ValidationResult;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}) {
    return (
        <LabeledTextField
            className={props.className}
            dataCy={nameSuffixTextboxDataCy}
            title="Name suffix:"
            prefix={props.prefix}
            value={props.value}
            onChange={props.onChange}
            validationResult={props.validationResult}
            onBlur={props.onBlur}
        />
    );
}
