import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { drillingIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import clsx from 'clsx';
import * as React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
    root: {
        color: '#265C7F',
    },

    style1: {
        fillRule: 'evenodd',
        clipRuleRule: 'evenodd',
        fill: '#9CB7C7',
    },

    style2: {
        fillRule: 'evenodd',
        clipRuleRule: 'evenodd',
        fill: '#D1DFE7',
    },
}));

export function DrillingIcon(props: SvgIconProps) {
    const { classes } = useStyles();
    const className = clsx(classes.root, props.className);

    return (
        <SvgIcon {...props} className={className} data-cy={drillingIconDataCy}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <g>
                    <path
                        className={classes.style1}
                        d="M2.89,2.93L3,10.43c0,0,4,2.57,7-0.43c0.71-0.71,0-9,0-9S7,1,6,1S2,1,2.89,2.93z"
                    />
                    <path className={classes.style2} d="M3,11v7c0,0,4,2.71,7,0c0.71-0.64,0-7,0-7S7,11,3,11z" />
                    <path className={classes.style1} d="M3,19v3.01c0,0,3,3.01,7,0v-2.97C10,19.04,8,19,3,19z" />
                </g>
                <g>
                    <path className={classes.style1} d="M14,16.2v3.01c0,0,3,3.01,7,0v-2.97C21,16.24,19,16.2,14,16.2z" />
                    <path className={classes.style2} d="M14,8v8c0,0,7,1,7-1c0-1,0-7,0-7S18,8,14,8z" />
                    <path className={classes.style1} d="M21,8V3.54c0,0-3-3.01-7,0V8C14,8,17,9,21,8z" />
                </g>
                <path
                    fill="currentColor"
                    d="M21.94,3.56c-0.32-1.11-2.44-1.63-4.44-1.63c-2.05,0-4.21,0.54-4.45,1.71C13.02,3.7,13,3.77,13,3.84v15.52
	c0,0.16,0.07,0.3,0.2,0.4c1.09,0.83,2.66,1.31,4.3,1.31s3.22-0.48,4.3-1.31c0.12-0.09,0.2-0.24,0.2-0.4v-4.03c0,0,0-0.01,0-0.01
	V3.79c0,0,0,0,0,0c0,0,0,0,0,0C22,3.7,21.97,3.63,21.94,3.56z M17.5,2.93c2.3,0,3.5,0.68,3.5,0.91c0,0.23-1.2,0.91-3.5,0.91
	S14,4.07,14,3.84l0,0C14,3.61,15.2,2.93,17.5,2.93z M21,19.1c-0.91,0.62-2.17,0.97-3.5,0.97s-2.59-0.35-3.5-0.97v-2.87
	c1.99,1.04,5.01,1.04,7,0V19.1z M21,15.07c-0.91,0.62-2.17,0.97-3.5,0.97s-2.59-0.35-3.5-0.97v-6.5c1.99,1.03,5.01,1.03,7,0V15.07z
	 M17.5,8.38c-1.33,0-2.59-0.35-3.5-0.97l0-2.32c0.88,0.45,2.21,0.66,3.5,0.66c1.29,0,2.62-0.22,3.5-0.66v2.32
	C20.09,8.03,18.83,8.38,17.5,8.38z"
                />
                <path
                    fill="currentColor"
                    d="M10.95,1.79C10.71,0.63,8.55,0.09,6.5,0.09S2.29,0.63,2.05,1.79C2.02,1.86,2,1.93,2,2v20.26
	c0,0.15,0.07,0.3,0.19,0.39C3.28,23.51,4.85,24,6.5,24s3.22-0.49,4.31-1.35c0.12-0.09,0.19-0.24,0.19-0.39v-4.13c0,0,0-0.01,0-0.01
	V2C11,1.93,10.98,1.86,10.95,1.79z M6.5,1.09C8.8,1.09,10,1.77,10,2S8.8,2.91,6.5,2.91S3,2.23,3,2S4.2,1.09,6.5,1.09z M10,22.01
	C9.09,22.64,7.83,23,6.5,23S3.91,22.64,3,22.01v-2.97c1.99,1.06,5.01,1.06,7,0V22.01z M10,17.87c-0.91,0.63-2.17,0.99-3.5,0.99
	S3.91,18.51,3,17.87v-6.69c1.99,1.06,5.01,1.06,7,0V17.87z M6.5,11.01c-1.33,0-2.59-0.36-3.5-0.99V3.25
	c0.88,0.45,2.21,0.66,3.5,0.66S9.12,3.69,10,3.25v6.77C9.09,10.65,7.83,11.01,6.5,11.01z"
                />
            </svg>
        </SvgIcon>
    );
}

export default DrillingIcon;
