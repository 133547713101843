import { APPBAR_HEIGHT_UNITS } from '@local/web-design-system/dist/components/AppBar/AppBar.styles';
import { fonts } from '@local/web-design-system/dist/styles/fonts';
import { Theme } from '@mui/material/styles';
import { tss } from 'tss-react/mui';

export const commonStyles = ({ theme }: { theme: Theme }) =>
    ({
        root: {
            display: 'flex',
            minHeight: '100vh',
            backgroundColor: theme.palette.grey[100],
        },
        content: {
            width: '100%',
            backgroundColor: theme.palette.grey[100],
            marginTop: theme.spacing(APPBAR_HEIGHT_UNITS),
            minWidth: 0,
        },
        contentWhite: {
            backgroundColor: theme.palette.common.white,
        },
        appContentAreaRoot: {
            backgroundColor: theme.palette.grey[100],
        },
        contentTitle: {
            ...fonts.h2,
            color: theme.palette.primary.main,
        },
        fullHeight: {
            height: '100%',
        },
        leftIcon: {
            marginRight: theme.spacing(),
        },
        row: {
            flexBasis: 0,
            flexGrow: 0,
        },
        rowGrow: {
            flexBasis: 0,
            flexGrow: 1,
            display: 'flex',
        },
        buttonIconText: {
            paddingLeft: theme.spacing(2),
        },
        linkAction: {
            ...fonts.button,
            textDecoration: 'underline',
            paddingLeft: 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        emptyStateFullPage: {
            padding: `${theme.spacing(12.5)} 0 0 0`,
        },
        emptyStateTable: {
            padding: `${theme.spacing(2.5)} 0`,
        },
        emptyStateTableCell: {
            borderBottom: 0,
        },
        deleteSecondaryButton: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey[50],
        },
        drawerOpen: {
            width: theme.spacing(25),
        },
        overlayDialog: {
            zIndex: 1,
            position: 'absolute',
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
        },
        overlayBackground: {
            backgroundColor: 'white',
            boxShadow: '0 0 6px 0 rgb(0 0 0 / 10%)',
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        defaultText: {
            fontSize: 14,
            fontSmooth: 'antialiased',
        },
    }) as const;

export const useStyles = tss.create(commonStyles);
