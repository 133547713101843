import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import MenuItem from '@mui/material/MenuItem';
import { DataContext } from 'App/DataContext';
import { isPublishDisabled } from 'App/MainApp/services/objectDownloadService';
import { publishObject } from 'App/MainApp/services/objectPublishService';
import {
    selectCurrentWorkspaceId,
    selectObjectClassname2Id2Obj,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import {
    selectPublishToEvoSpinnerActiveById,
    setCurrentPublishToEvoSpinnerObject,
} from 'App/Redux/features/rightClickMenu/publishToEvoSpinnerActiveSlice';
import { useAppSelector, useAppDispatch } from 'App/Redux/hooks';
import {
    OBJECT_CLASS_NAMES,
    ObjectStatusTypes,
    ProjectObject,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { GeneralMenuItems, PublishToEvoMenuItems, SecondaryMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import {
    publishEllipsoidsToEvoDataCy,
    publishLineationDataToEvoDataCy,
    publishMenuItemDataCy,
    publishStructuralDataToEvoDataCy,
    publishToEvoMenuItemDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import Spinner from 'Common/components/Core/Spinner';
import { ObjectMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import SecondaryItemMenu from '../../../SecondaryItemMenu';
import { PublishToEvoFormats } from 'App/util/projectObjectsUtils.ts/objectUtils';
import MenuItemIcon from '../../../MenuItemIcon';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useSessionContext } from 'App/context/SessionContext';

import {
    ProjectObjectActionType,
    setProjectObjectUploadingOrPublishing,
} from 'App/Redux/features/globalContext/projectObjectSlice';
import { MeshFileType } from 'App/util/ProjectDataTypes/MeshFile';
import { AnisotropyEstimationType } from 'App/util/ProjectDataTypes/AnisotropyEstimation';
import { AnisotropyGridType } from 'App/util/ProjectDataTypes/AnisotropyGrid';
import { AnisotropyGlobalType } from 'App/util/ProjectDataTypes/AnisotropyGlobal';
import { ZoneType } from 'App/util/ProjectDataTypes/Zone';
import {
    handleUploadOrPublishFileProgress,
    resetUploadOrPublishFileProgress,
} from 'App/MainApp/Dialogs/Shared/uploadOrPublishFileUtil';
import { ZoneFromAnisotropyType } from 'App/util/ProjectDataTypes/ZoneFromAnisotropy';

export function PublishToEvoButton(props: ObjectMenuItemProps) {
    const { menuItemClass, currentOrgUuid } = React.useContext(DataContext);
    const { axiosDriverFlask } = useSessionContext();
    const { addMessage } = useDriverMessagesContext();
    const isAnisotropy =
        props.nodeData.className === OBJECT_CLASS_NAMES.AnisotropyEstimation ||
        props.nodeData.className === OBJECT_CLASS_NAMES.AnisotropyGrid ||
        props.nodeData.className === OBJECT_CLASS_NAMES.AnisotropyGlobal;

    const allProjectObjects = useAppSelector(selectObjectClassname2Id2Obj);
    const projectObject = allProjectObjects[props.nodeData.className][props.nodeData.id] as ProjectObject;
    const spinner = useAppSelector(selectPublishToEvoSpinnerActiveById(props.nodeData.className, props.nodeData.id));

    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const dispatch = useAppDispatch();

    const onClick = async (event: React.MouseEvent<HTMLElement>, publishObjectFormat?: PublishToEvoFormats) => {
        dispatch(
            setCurrentPublishToEvoSpinnerObject(props.nodeData.className, props.nodeData.id, true, publishObjectFormat)
        );

        const objectId = props.nodeData.id;
        const objectClassName = props.nodeData.className;

        const projectUploadObject = setObjectInProgressTray(objectId, objectClassName);

        let newProjectUploadObject: { [id: string]: ProjectObjectActionType } = {};

        try {
            await publishObject(
                projectObject,
                axiosDriverFlask,
                currentOrgUuid,
                currentWorkspaceId,
                publishObjectFormat
            );
            addMessage({
                message: 'Exported Successfully',
                type: NotificationType.SUCCESS,
            });
            newProjectUploadObject = resetUploadOrPublishFileProgress(
                objectId,
                projectUploadObject,
                ObjectStatusTypes.PUBLISHED,
                100
            );
        } catch {
            // addMessage({
            //     message: 'Publish to Seequent EVO was unsuccessful. Please contact customer support.',
            //     type: NotificationType.ERROR,
            // });
            newProjectUploadObject = resetUploadOrPublishFileProgress(
                objectId,
                projectUploadObject,
                ObjectStatusTypes.FAILED,
                100
            );
        }

        dispatch(
            setCurrentPublishToEvoSpinnerObject(props.nodeData.className, props.nodeData.id, false, publishObjectFormat)
        );

        props.handleMenuClose();

        dispatch(setProjectObjectUploadingOrPublishing(newProjectUploadObject));
    };

    const setObjectInProgressTray = (objectId: string, objectClassName: OBJECT_CLASS_NAMES) => {
        let gooseName: string = '';
        const objectsWithNames = [
            OBJECT_CLASS_NAMES.AnisotropyEstimation,
            OBJECT_CLASS_NAMES.AnisotropyGrid,
            OBJECT_CLASS_NAMES.AnisotropyGlobal,
            OBJECT_CLASS_NAMES.Zone,
            OBJECT_CLASS_NAMES.ZoneFromAnisotropy,
        ];
        let projectUploadObject: { [id: string]: ProjectObjectActionType } = {};

        if (projectObject.object_class_name === OBJECT_CLASS_NAMES.MeshFile) {
            gooseName = (projectObject as MeshFileType).original_file_name;
        } else if (objectsWithNames.includes(projectObject.object_class_name)) {
            gooseName = (
                projectObject as
                    | AnisotropyEstimationType
                    | AnisotropyGridType
                    | AnisotropyGlobalType
                    | ZoneType
                    | ZoneFromAnisotropyType
            ).name;
        }

        projectUploadObject = handleUploadOrPublishFileProgress(
            objectId,
            objectClassName,
            ObjectStatusTypes.PUBLISHING,
            30,
            gooseName,
            projectUploadObject,
            true
        );

        dispatch(setProjectObjectUploadingOrPublishing(projectUploadObject));

        return projectUploadObject;
    };

    const { isValid, error } = isPublishDisabled(projectObject, allProjectObjects, GeneralMenuItems.publish);

    const itemName = isAnisotropy ? GeneralMenuItems.publishToEvo : GeneralMenuItems.publish;
    const itemDataCy = isAnisotropy ? publishToEvoMenuItemDataCy : publishMenuItemDataCy;

    return (
        <>
            {isAnisotropy ? (
                <SecondaryItemMenu disabled={!isValid} itemName={itemName} dataCy={itemDataCy}>
                    <div>
                        <MenuItem
                            title={error}
                            disabled={!isValid}
                            onClick={(event) => onClick(event, PublishToEvoFormats.structuralPoints)}
                            data-cy={publishStructuralDataToEvoDataCy}
                            className={menuItemClass.menuItem}
                        >
                            <MenuItemIcon name={PublishToEvoMenuItems.StructuralData} />
                            {PublishToEvoMenuItems.StructuralData}
                            <Spinner
                                size={18}
                                show={spinner?.[PublishToEvoFormats.structuralPoints]}
                                style={{ marginLeft: '15px' }}
                            />
                        </MenuItem>
                        <MenuItem
                            title={error}
                            disabled={!isValid}
                            onClick={(event) => onClick(event, PublishToEvoFormats.lineationPoints)}
                            data-cy={publishLineationDataToEvoDataCy}
                            className={menuItemClass.menuItem}
                        >
                            <MenuItemIcon name={PublishToEvoMenuItems.LineationData} />
                            {PublishToEvoMenuItems.LineationData}
                            <Spinner
                                size={18}
                                show={spinner?.[PublishToEvoFormats.lineationPoints]}
                                style={{ marginLeft: '15px' }}
                            />
                        </MenuItem>
                        <MenuItem
                            title={error}
                            disabled={!isValid}
                            onClick={(event) => onClick(event, PublishToEvoFormats.ellipsoids)}
                            data-cy={publishEllipsoidsToEvoDataCy}
                            className={menuItemClass.menuItem}
                        >
                            <MenuItemIcon name={PublishToEvoMenuItems.Ellipsoids} />
                            {PublishToEvoMenuItems.Ellipsoids}
                            <Spinner
                                size={18}
                                show={spinner?.[PublishToEvoFormats.ellipsoids]}
                                style={{ marginLeft: '15px' }}
                            />
                        </MenuItem>
                    </div>
                </SecondaryItemMenu>
            ) : (
                <div>
                    <MenuItem
                        className={menuItemClass.menuItem}
                        title={error}
                        disabled={!isValid}
                        onClick={onClick}
                        data-cy={publishToEvoMenuItemDataCy}
                    >
                        <MenuItemIcon name={SecondaryMenuItems.ToEvo} />
                        {SecondaryMenuItems.ToEvo}
                        <Spinner size={18} show={spinner} style={{ marginLeft: '15px' }} />
                    </MenuItem>
                </div>
            )}
        </>
    );
}
