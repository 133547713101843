import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import { tss } from 'tss-react/mui';
import SecondaryButton from 'Common/components/Core/SecondaryButton';
import PrimaryButton from 'Common/components/Core/PrimaryButton';
import { cancelButtonDataCy, submitButtonDataCy } from '../../testUtils/genericTestUtils/dataCyConsts';
import classNames from 'classnames';

const useStyles = tss.create(() => ({
    container: {
        columnGap: '15px',
    },
}));

export interface GenericDialogActionsProps {
    onCancel?: React.MouseEventHandler<HTMLButtonElement>;
    onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    showSpinner?: boolean;
    tooltipText?: string;
    submitText?: string;
    cancelText?: string;
    className?: string;
    noSubmit?: boolean;
    noCancel?: boolean;
    sx?: DialogActionsProps['sx'];
    centerBottons?: boolean;
}

function GenericDialogActions({
    onCancel,
    onSubmit,
    disabled = false,
    showSpinner = true,
    tooltipText = '',
    submitText = '',
    cancelText = '',
    className = '',
    noSubmit = false,
    noCancel = false,
    sx = undefined,
    centerBottons = false,
}: GenericDialogActionsProps) {
    const { classes } = useStyles();

    return (
        <DialogActions sx={sx}>
            <Grid
                container
                alignItems="center"
                justifyContent={centerBottons ? 'center' : 'flex-end'}
                className={classNames(classes.container, className)}
                sx={{ paddingRight: centerBottons ? undefined : '16px' }}
            >
                {disabled && showSpinner ? (
                    <CircularProgress color="primary" size={20} style={{ marginRight: '5px' }} />
                ) : null}
                {!noCancel && (
                    <SecondaryButton
                        autoFocus
                        onClick={onCancel}
                        data-cy={cancelButtonDataCy}
                        sx={{ minWidth: '156px' }}
                    >
                        {cancelText || 'Cancel'}
                    </SecondaryButton>
                )}
                {!noSubmit && (
                    <Tooltip disableInteractive placement="bottom" title={tooltipText}>
                        <span>
                            <PrimaryButton
                                onClick={onSubmit}
                                disabled={disabled}
                                data-cy={submitButtonDataCy}
                                sx={{ minWidth: '156px' }}
                            >
                                {submitText || 'Submit'}
                            </PrimaryButton>
                        </span>
                    </Tooltip>
                )}
            </Grid>
        </DialogActions>
    );
}

export default GenericDialogActions;
