import { anisotropySectionMenu } from 'App/MainApp/TreeView/menu/sectionMenus';
import { getAnisotropyNode } from 'App/MainApp/TreeView/treeData/anisotropyNodesService';
import { getDomainGroupNode } from 'App/MainApp/TreeView/treeData/commonNodesService';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { getActiveObjects } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { ProjectTreeNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import {
    AnyAnisotropyType,
    Id2DomainObject,
    OBJECT_CLASS_NAMES,
    ObjectClassName2Id2Obj,
} from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { wrapObjectInClass } from 'App/util/ProjectDataTypes/utilClasses/objectWrapper';
import { anisotropySectionDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function buildAnisotropySubtree(allObjects: ObjectClassName2Id2Obj, tree: Subsections) {
    const nodeId = SectionIds[SectionNames.Anisotropy];

    tree[nodeId] = {
        section: SectionNames.Anisotropy,
        dataCy: anisotropySectionDataCy,
        rightClickMenu: anisotropySectionMenu,
        subsections: {},
        nodeId,
        path: [],
    };

    buildAnisotropyDomainSubtrees(allObjects, tree[nodeId]);
}

function getAllAnisotropies(allObjects: ObjectClassName2Id2Obj) {
    const anisotropies: AnyAnisotropyType[] = [
        ...getActiveObjects(allObjects, OBJECT_CLASS_NAMES.AnisotropyEstimation),
        ...getActiveObjects(allObjects, OBJECT_CLASS_NAMES.AnisotropyGrid),
        ...getActiveObjects(allObjects, OBJECT_CLASS_NAMES.AnisotropyGlobal),
    ];

    return anisotropies;
}

function getDomainsHavingAnisotropies(anisotropies: AnyAnisotropyType[], allObjects: ObjectClassName2Id2Obj) {
    const domainsMap: Id2DomainObject = {};

    anisotropies.forEach((anisotropy) => {
        const anisotropyInstance = wrapObjectInClass(anisotropy, null, allObjects);
        const domain = anisotropyInstance.getDomain();
        domainsMap[domain.id] = domain;
    });

    return Object.values(domainsMap);
}

export function buildAnisotropyDomainNodes(
    anisotropies: AnyAnisotropyType[],
    allObjects: ObjectClassName2Id2Obj,
    subtree: Subsections,
    parentNode: ProjectTreeNode
) {
    const domains = getDomainsHavingAnisotropies(anisotropies, allObjects);

    domains.forEach((domain) => {
        const domainNode = getDomainGroupNode(domain, parentNode);
        subtree[domain.id] = domainNode;
    });
}

function buildAnisotropyNodes(
    anisotropies: AnyAnisotropyType[],
    allObjects: ObjectClassName2Id2Obj,
    subtree: Subsections
) {
    anisotropies.forEach((anisotropy) => {
        const anisotropyInstance = wrapObjectInClass(anisotropy, null, allObjects);

        const domainNode = subtree[anisotropyInstance.getDomain().id];

        const anisotropyNode = getAnisotropyNode(anisotropy, domainNode);

        domainNode.subsections[anisotropy.id] = anisotropyNode;
    });
}

export function buildAnisotropyDomainSubtrees(allObjects: ObjectClassName2Id2Obj, parentNode: ProjectTreeNode) {
    const anisotropies = getAllAnisotropies(allObjects);
    buildAnisotropyDomainNodes(anisotropies, allObjects, parentNode.subsections, parentNode);
    buildAnisotropyNodes(anisotropies, allObjects, parentNode.subsections);
}
