import {
    ElementClass,
    FillStyle,
    SurfaceElementState,
    SurfaceViewState,
    UpdateSnapshot,
    ViewClass,
} from '@seequent/xyz';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { addNewXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { AppDispatch } from 'App/Redux/store';
import { Point3D } from 'Common/types/geometryTypes';
import { getNextMeshColor } from 'App/MainApp/Plot/Colors_Util/colors_helper';
import { hexToRgbArray } from 'App/MainApp/Plot/util';
import { XyzTrace, XyzTraceClassNames, XyzTraceSnapshot, XyzTraceStatusDetails, mostSevereXYZStatus } from './XyzTrace';
import { Color } from '@seequent/xyz/lib/types/src/types';
import { isDefined } from 'App/util/core';

export interface MeshTraceSnapshot extends XyzTraceSnapshot {
    /**
     * Snapshot is saved in Redux state. Must not duplicate XYZ state that is already stored in XYZ.
     * I mean when a plot is saved into XYZ, then basically all the currrent state of the plot is saved into XYZ.
     * Therefore there is a blurry line between what should and should not be saved in Redux through Snapshot.
     * We mainly want to store data that can help us easily extract all required information from XYZ state.
     */
    readonly className: XyzTraceClassNames.MeshTrace;

    readonly name: string;

    readonly verticesUrl: string;

    readonly vertices: number[];

    readonly trianglesUrl: string;

    readonly triangles: number[];
}

export class MeshTrace extends XyzTrace implements MeshTraceSnapshot {
    readonly className: XyzTraceClassNames.MeshTrace;

    readonly name: string;

    readonly verticesUrl: string;

    readonly vertices: number[];

    readonly trianglesUrl: string;

    readonly triangles: number[];

    constructor(xyz: UseXyz, snapshot: MeshTraceSnapshot) {
        if (![XyzTraceClassNames.MeshTrace].includes(snapshot.className)) {
            throw `An incorrect spanshot is being used to create this instance of MeshTrace: ${snapshot}`;
        }
        if (!snapshot.id) {
            throw 'Id must be provided.';
        }
        super(xyz, snapshot);
    }

    snapshotPath = (): string[] => [this.id];

    entityIdPrefix = (): string => this.id;

    private createInitialXyzEntities = (wireframe: boolean, showFaces: boolean, color: Color): UpdateSnapshot => {
        const ret: UpdateSnapshot = {
            [this.viewEntityId()]: {
                element: this.surfaceElementEntityId(),
                visible: true,
                smooth: true,
                __class__: ViewClass.Surface,
            },
            [this.surfaceElementEntityId()]: {
                verticesUrl: this.vertices ? undefined : this.urlWithObjectHash(this.verticesUrl),
                vertices: this.vertices ? this.vertices : undefined,
                trianglesUrl: this.triangles ? undefined : this.urlWithObjectHash(this.trianglesUrl),
                triangles: this.triangles ? this.triangles : undefined,
                __class__: ElementClass.Surface,
            },
        };
        const state = this.xyz.getState();
        const viewEntity = state[this.viewEntityId()] as SurfaceViewState;
        const newViewEntity = ret[this.viewEntityId()] as SurfaceViewState;
        if (isDefined(color)) {
            newViewEntity.color = color;
        }
        if (isDefined(wireframe)) {
            newViewEntity.wireframe = wireframe;
        }
        if (isDefined(showFaces)) {
            newViewEntity.showFaces = showFaces;
        }
        if (!newViewEntity.color && !viewEntity?.color) {
            // Set color if it wasn't set before
            newViewEntity.color = hexToRgbArray(getNextMeshColor());
            newViewEntity.color_back = newViewEntity.color;
        }
        if (viewEntity?.fill_style === undefined) {
            newViewEntity.fill_style = FillStyle.Fill;
        }
        if (!viewEntity?.opacity) {
            newViewEntity.opacity = 1;
        }
        return ret;
    };

    getOpacity = () => this.viewEntity()?.opacity;

    getStatus = (): XyzTraceStatusDetails => {
        const status = this.viewEntity()?.status ?? {};
        return {
            status: mostSevereXYZStatus({ ...status }),
            statuses: { ...status },
        };
    };

    updateOpacity = async (opacity: number) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                opacity: opacity,
                __class__: ViewClass.Surface,
            },
        });
    };

    updateColor = async (color: Point3D) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                color: color,
                color_back: color,
                __class__: ViewClass.Surface,
            },
        });
    };

    getColor = (): Point3D => this.viewEntity()?.color;

    plotAndSave(enabled = true, wireframe = false, showFaces = true, color: Color = undefined) {
        const plotAndSaveThunk = async (dispatch: AppDispatch) => {
            const initialXyzEntities = this.createInitialXyzEntities(wireframe, showFaces, color);
            await this.xyz.updateVisualizationWithoutTween(initialXyzEntities);

            const meshTraceSnapshot = this.takeSnapshot();
            dispatch(addNewXyzTrace(meshTraceSnapshot));

            await this.setEnabled(dispatch, enabled);
        };
        return plotAndSaveThunk;
    }

    getName = () => this.name;

    viewEntityId = () => `${this.entityIdPrefix()}:SurfaceView`;

    viewEntity = (): SurfaceViewState => this.xyz.getEntityState(this.viewEntityId()) as SurfaceViewState;

    surfaceElementEntityId = () => `${this.entityIdPrefix()}:SurfaceElement`;

    surfaceElementEntity = (): SurfaceElementState =>
        this.xyz.getEntityState(this.surfaceElementEntityId()) as SurfaceElementState;

    // eslint-disable-next-line class-methods-use-this
    colorDataMappingEntityId = () => undefined;

    // eslint-disable-next-line class-methods-use-this
    showEmptyColorMapLegend = () => true;

    takeSnapshot(): MeshTraceSnapshot {
        const superSnapshot = super.takeSnapshot();

        return {
            ...superSnapshot,
            className: this.className,
            name: this.name,
            verticesUrl: this.verticesUrl,
            trianglesUrl: this.trianglesUrl,
            vertices: this.vertices,
            triangles: this.triangles,
        };
    }

    updateObjectHash = (objectHash: string, enabled: boolean) => {
        const updateObjectHashThunk = async (dispatch: AppDispatch) => {
            const newMeshTrace = new MeshTrace(this.xyz, {
                ...this.takeSnapshot(),
                objectHash: objectHash,
            }); // We need access to the new TraceSnapshot right now. That's why we can't use this.updateReduxState() because it doesn't return the new snapshot.
            await dispatch(newMeshTrace.plotAndSave(enabled));
        };
        return updateObjectHashThunk;
    };
}
