import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import {
    ClosedMeshesMenuItemNames,
    OpenMeshesMenuItemNames,
    SecondaryMenuItems,
} from 'App/MainApp/TreeView/treeData/treeConsts';
import SecondaryItemMenu from '../../../SecondaryItemMenu';
import { UploadMeshFromLocalButton } from './UploadMeshFromLocalButton';
import { uploadClosedMeshDataCy, uploadOpenMeshDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { UploadMeshFromEvoButton } from './UploadMeshFromEvoButton';

export function UploadClosedMeshButton(props: GeneralMenuItemProps) {
    return uploadMeshButton({ handleMenuClose: props.handleMenuClose, isClosedMesh: true });
}

export function UploadOpenMeshButton(props: GeneralMenuItemProps) {
    return uploadMeshButton({ handleMenuClose: props.handleMenuClose, isClosedMesh: false });
}

export function uploadMeshButton(props: { handleMenuClose(): void; isClosedMesh: boolean }) {
    const menuItemName = props.isClosedMesh
        ? ClosedMeshesMenuItemNames.UploadClosedMesh
        : OpenMeshesMenuItemNames.UploadOpenMesh;
    const secondaryMenuDataCy = props.isClosedMesh ? uploadClosedMeshDataCy : uploadOpenMeshDataCy;

    return (
        <SecondaryItemMenu itemName={menuItemName} dataCy={secondaryMenuDataCy}>
            <div>
                <UploadMeshFromLocalButton
                    handleMenuClose={props.handleMenuClose}
                    isClosedMesh={props.isClosedMesh}
                    menuItemName={SecondaryMenuItems.FromLocal}
                />
                <UploadMeshFromEvoButton isClosedMesh={props.isClosedMesh} handleMenuClose={props.handleMenuClose} />
            </div>
        </SecondaryItemMenu>
    );
}
