import { BoundsArray, BoundsObject, BoundsStringsObject, CoordinatesObject } from 'Common/types/geometryTypes';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { boundsArrayToObject } from 'Common/utils/geometryHelpers';
import { GridDefinitionType } from 'App/util/ProjectDataTypes/GridDefinition';
import { SourceFileType } from 'App/util/ProjectDataTypes/SourceFile';
import { StringCoordinatesObject } from './gridLimitsHelpers';
import { AxiosDriverFlaskInstance } from '../../../util/axiosErrorHandlers';
import { APIResponseWithProjectUpdate } from '../../../util/ProjectDataTypes/APIResponseTypes';
import { backendProjectUrl } from 'App/Redux/features/globalContext/currentProjectSlice';
import { SectionIds, SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';

export async function updateGridDefinition(
    formResults: GridRawData,
    existingGridData: GridDefinitionType,
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentProjectId: string,
    orgId: string,
    workspaceId: string
): Promise<AxiosResponse<APIResponseWithProjectUpdate>> {
    const formData = new FormData();

    const formResultsCopy = _.cloneDeep(formResults);

    formData.append('parameters', JSON.stringify(formResultsCopy));

    if (existingGridData) {
        return axiosDriverFlask.put(existingGridData.url, formData, {
            withCredentials: true,
            responseType: 'json',
        });
    }
    return axiosDriverFlask.post<APIResponseWithProjectUpdate>(
        `${backendProjectUrl(orgId, workspaceId, currentProjectId)}/gridDefinition`,
        formData,
        {
            withCredentials: true,
            responseType: 'json',
        }
    );
}

type GridCoordinatesData = [string, string, string];

type GridRawData = {
    minXYZ: GridCoordinatesData;
    maxXYZ: GridCoordinatesData;
    blocksize: GridCoordinatesData;
    name: string;
};

export function formResultsFromData(
    bounds: BoundsStringsObject,
    blockSizes: StringCoordinatesObject,
    gridName: string
): GridRawData {
    const formData = {
        minXYZ: [bounds.minX, bounds.minY, bounds.minZ] as GridCoordinatesData,
        maxXYZ: [bounds.maxX, bounds.maxY, bounds.maxZ] as GridCoordinatesData,
        blocksize: [blockSizes.x, blockSizes.y, blockSizes.z] as GridCoordinatesData,
        name: gridName,
    };

    return formData;
}

export function getBoundsFromGridData(gridData: GridRawData): BoundsObject {
    return boundsArrayToObject(
        [
            gridData.minXYZ[0],
            gridData.maxXYZ[0],
            gridData.minXYZ[1],
            gridData.maxXYZ[1],
            gridData.minXYZ[2],
            gridData.maxXYZ[2],
        ].map((item) => Number(item)) as BoundsArray
    );
}

export function getBlockSizesFromGridData(gridData: GridRawData): CoordinatesObject {
    return {
        x: Number(gridData.blocksize[0]),
        y: Number(gridData.blocksize[1]),
        z: Number(gridData.blocksize[2]),
    };
}

export function getDrillingBounds(drillingData: SourceFileType): BoundsObject {
    return boundsArrayToObject(
        [
            getDrillingXYZMin(drillingData)[0],
            getDrillingXYZMax(drillingData)[0],
            getDrillingXYZMin(drillingData)[1],
            getDrillingXYZMax(drillingData)[1],
            getDrillingXYZMin(drillingData)[2],
            getDrillingXYZMax(drillingData)[2],
        ].map((item) => Number(item)) as BoundsArray
    );
}

export function getGridNameFromGridData(gridData: GridRawData): string {
    return gridData.name;
}

function getDrillingXYZMin(drillingData: SourceFileType): GridCoordinatesData {
    return (
        (drillingData?.meta_data?.xyzColumns?.xyzMin.map((value) => `${value}`) as GridCoordinatesData) ?? ['', '', '']
    );
}

function getDrillingXYZMax(drillingData: SourceFileType): GridCoordinatesData {
    return (
        (drillingData?.meta_data?.xyzColumns?.xyzMax.map((value) => `${value}`) as GridCoordinatesData) ?? ['', '', '']
    );
}

export function getInitialGridData(drillingData: SourceFileType, grid: GridDefinitionType): GridRawData {
    let initialData = {
        minXYZ: getDrillingXYZMin(drillingData),
        maxXYZ: getDrillingXYZMax(drillingData),
        blocksize: ['20', '20', '20'] as GridCoordinatesData,
        name: 'grid',
    };

    if (grid) {
        initialData = {
            name: grid.name,
            minXYZ: grid.parameters.minXYZ.map((item) => `${item}`) as GridCoordinatesData,
            maxXYZ: grid.parameters.maxXYZ.map((item) => `${item}`) as GridCoordinatesData,
            blocksize: grid.parameters.blocksize,
        };
    }

    return initialData;
}

export const expandGridNodes = (onExpandChange: (nodes: ObjectIDType[], expand: boolean) => void) => {
    const interpolationSectionId = SectionIds[SectionNames.Interpolations];
    const nodesToExpand = [interpolationSectionId];

    onExpandChange(nodesToExpand, true);
};
