import { AxiosDriverFlaskInstance } from 'App/util/axiosErrorHandlers';

interface EvoUserInfo {
    id: string;
    name?: string;
    email?: string;
}

export enum WorkspaceRole {
    OWNER = 'owner',
    EDITOR = 'editor',
    VIEWER = 'viewer',
}

export interface Workspace {
    // backend has a lot more info than this. i've kept here just what i needed
    id: string;
    name: string;
    current_user_role: WorkspaceRole;
    org_id: string;
}

export interface GooseObject {
    name: string;
    object_id: string;
    schema: string;
    version_id: string;
    created_at: string;
    created_by: EvoUserInfo;
    etag: string;
    path: string;
    links: { [k: string]: string };
}

export class WorkspaceApi {
    static list = async (axiosDriverFlask: AxiosDriverFlaskInstance) => {
        return (await axiosDriverFlask.get(`/api/workspaces`)) as Workspace[];
    };

    static listDownholeIntervals = async (
        axiosDriverFlask: AxiosDriverFlaskInstance,
        workspaceUuid: string
    ): Promise<GooseObject[]> => {
        return axiosDriverFlask.get(`/api/workspaces/${workspaceUuid}/downholes`);
    };

    static parseIntervalDownhole = async (
        axiosDriverFlask: AxiosDriverFlaskInstance,
        workspaceUuid: string,
        downholeUuid: string
    ): Promise<any> => {
        return axiosDriverFlask.get(`/api/workspaces/${workspaceUuid}/downhole/${downholeUuid}/parse`);
    };
}
