import { AppDispatch } from 'App/Redux/store';
import { MinMaxRange } from 'Common/utils/validationTypeUtils';
import { produce } from 'immer';
import { XyzTrace, XyzTraceSnapshot } from './XyzTrace';
import { ColorValueSummaries, XyzColorTrace, XyzColorTraceSnapshot } from './XyzColorTrace';
import { XyzPredefinedColorMaps } from '../XyzColorMaps';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';

export interface XyzTraceSnapshotWithColorAttributes extends XyzTraceSnapshot {
    readonly colorAttributeTraces: {
        [colorAttribute: string]: XyzColorTraceSnapshot;
    };

    readonly selectedColorAttribute: string;

    readonly hidePlotWhenColorsPending?: boolean;
}

export abstract class XyzTraceWithColorAttributes extends XyzTrace implements XyzTraceSnapshotWithColorAttributes {
    readonly colorAttributeTraces: {
        [colorAttribute: string]: XyzColorTrace;
    };

    readonly selectedColorAttribute: string;

    readonly hidePlotWhenColorsPending?: boolean;

    readonly tokenProvider: () => Promise<string>;

    abstract entityIdPrefix: () => string;

    abstract elementEntityId: () => string;

    constructor(xyz: UseXyz, snapshot: XyzTraceSnapshot, tokenProvider: () => Promise<string>) {
        super(xyz, snapshot);
        if (!this.selectedColorAttribute) {
            throw `XyzTraceWithColorAttributes: selectedColorAttribute can't be undefined!: ${this}`;
        }
        this.tokenProvider = tokenProvider;
    }

    protected selectedColorAttributeTrace = (): XyzColorTrace => this.colorAttributeTraces[this.selectedColorAttribute];

    updateDisplayRange = (dispatch: AppDispatch, displayRange: Partial<MinMaxRange>) =>
        this.selectedColorAttributeTrace().updateDisplayRange(dispatch, displayRange);

    updateColorMap = (colorMap: XyzPredefinedColorMaps) => this.selectedColorAttributeTrace().updateColorMap(colorMap);

    updateColorMapRange = async (dispatch: AppDispatch, colorMapRange: Partial<MinMaxRange>) => {
        await this.selectedColorAttributeTrace().updateColorMapRange(dispatch, colorMapRange);
    };

    updateAndValidateColorValueSummaries = async (dispatch: AppDispatch, colorValueSummaries: ColorValueSummaries) => {
        await this.selectedColorAttributeTrace().updateAndValidateColorValueSummaries(dispatch, colorValueSummaries);
    };

    inValidateColorValueSummaries = async (dispatch: AppDispatch) => {
        await this.selectedColorAttributeTrace().inValidateColorValueSummaries(dispatch);
    };

    inValidateAllColorValueSummaries = (dispatch: AppDispatch) => {
        dispatch(
            this.updateReduxState(
                produce<XyzTraceSnapshotWithColorAttributes>((oldSnapshot) => {
                    Object.values(oldSnapshot.colorAttributeTraces).forEach((colorAttributeTrace) => {
                        colorAttributeTrace.colorValueSummariesValid = false;
                    });
                })
            )
        );
    };

    getColorValueSummaries = () => this.selectedColorAttributeTrace().getColorValueSummaries();

    getColorValueSummariesValid = () => this.selectedColorAttributeTrace().getColorValueSummariesValid();

    // getDataRange = () => this.selectedColorAttributeTrace().getDataRange();

    getStatus = () => this.selectedColorAttributeTrace().getStatus();

    colorDataMappingEntityId = () => this.selectedColorAttributeTrace().colorDataMappingEntityId();

    hasColorMap = () => this.selectedColorAttributeTrace().hasColorMap();

    getUseSingleColor = () => this.selectedColorAttributeTrace().getUseSingleColor();

    // getDisplayValuePercentiles = () => this.selectedColorAttributeTrace().getDisplayValuePercentiles();

    // getConcentrationValueRange = () => this.selectedColorAttributeTrace().getConcentrationValueRange();

    getOpacity = () => this.selectedColorAttributeTrace().getOpacity();

    colorDataArrayEntityId = () => this.selectedColorAttributeTrace().colorDataArrayEntityId();

    colorDataFilterEntityId = () => this.selectedColorAttributeTrace().colorDataFilterEntityId();
}
