import {
    TEXTBOX_ERROR_MESSAGES,
    ValidationResult,
    getDefaultValidationResult,
    isInt,
} from '../../../util/validationUtils';
import {
    BlockSizesValidationResult,
    BlocksDimensionsStrings,
    BlocksDimensionsValidationResult,
    StringCoordinatesObject,
    getTotalBlocksNumber,
} from './gridLimitsHelpers';

const MAX_BLOCKS = 5 * 1000 * 1000;

export function isValidGridName(gridName: string): ValidationResult {
    const validationResult = getDefaultValidationResult();

    if (gridName === '') {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.cannotBeEmpty;
    }

    return validationResult;
}

export function isValidBlockSize(blockSize: string): ValidationResult {
    const validationResult = getDefaultValidationResult();

    if (blockSize === '') {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.cannotBeEmpty;
    } else if (Number(blockSize) <= 0) {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.getShouldBeGreaterThan(0);
    }

    return validationResult;
}

export function isValidBlockSizes(blockSizes: StringCoordinatesObject): BlockSizesValidationResult {
    return {
        x: isValidBlockSize(blockSizes.x),
        y: isValidBlockSize(blockSizes.y),
        z: isValidBlockSize(blockSizes.z),
    };
}

export function getInitialBlockSizesValidationResults(): BlockSizesValidationResult {
    return {
        x: getDefaultValidationResult(),
        y: getDefaultValidationResult(),
        z: getDefaultValidationResult(),
    };
}

export function isValidSizeInBlocks(sizeInBlocks: string): ValidationResult {
    const validationResult = getDefaultValidationResult();

    if (!isInt(sizeInBlocks)) {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.shouldBeInteger;
    }

    return validationResult;
}

export function isValidSizesInBlocks(sizesInBlocks: BlocksDimensionsStrings): BlocksDimensionsValidationResult {
    return {
        xBlocks: isValidSizeInBlocks(sizesInBlocks.xBlocks),
        yBlocks: isValidSizeInBlocks(sizesInBlocks.yBlocks),
        zBlocks: isValidSizeInBlocks(sizesInBlocks.zBlocks),
    };
}

export function getSizesInBlocksInitialValidationResult(): BlocksDimensionsValidationResult {
    return {
        xBlocks: getDefaultValidationResult(),
        yBlocks: getDefaultValidationResult(),
        zBlocks: getDefaultValidationResult(),
    };
}

export function isValidTotalSizeInBlocks(sizesInBlocks: BlocksDimensionsStrings): ValidationResult {
    const validationResult = getDefaultValidationResult();
    const totalBlocks = getTotalBlocksNumber(sizesInBlocks);

    if (isNaN(totalBlocks) || totalBlocks > MAX_BLOCKS) {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.getShouldBeSmallerThanOrEqual(MAX_BLOCKS.toLocaleString());
    }

    return validationResult;
}
