import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import {
    applyWebsocketModificationsToCurrentProject,
    selectActiveDrilling,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
    selectGridById,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import React from 'react';
import { DataContext } from 'App/DataContext';
import _ from 'lodash';
import { BoundsKey, BoundsStringsObject, CoordinateKey } from 'Common/types/geometryTypes';
import { ValidationResult, getDefaultValidationResult, isObjectValid } from 'App/util/validationUtils';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import { tss } from 'tss-react/mui';
import { ObjectIDType, ObjectStatusTypes } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { AxiosError } from 'axios';
import { gridDialogDataCy, gridNameTextBoxDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { boundsObjectToStringsObject, stringsObjectToBoundsObject } from 'Common/utils/geometryHelpers';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import BlockSize from './BlockSize';
import {
    expandGridNodes,
    formResultsFromData,
    getBlockSizesFromGridData,
    getBoundsFromGridData,
    getDrillingBounds,
    getGridNameFromGridData,
    getInitialGridData,
    updateGridDefinition,
} from './defineGridService';
import {
    BlockSizesValidationResult,
    BlocksDimensionsStrings,
    BlocksDimensionsValidationResult,
    BlocksKey,
    BoundsValidationResults,
    StringCoordinatesObject,
    StringCoordinatesToNumbers,
    blocksDimensionsToStrings,
    blocksKeyToKeys,
    blocksStringsToNumbers,
    coordinateKeyToKeys,
    coordinatesToStrings,
    getNumOfBlocksFromBounds,
    getSizeInBlocks,
    getTotalBlocksNumber,
    getUpperBoundFromNumOfBlocks,
    getUpperBoundsFromBlocks,
    maxBoundKeyToKeys,
    minBoundKeyToKeys,
} from './gridLimitsHelpers';
import {
    getInitialBlockSizesValidationResults,
    getSizesInBlocksInitialValidationResult,
    isValidBlockSizes,
    isValidGridName,
    isValidSizesInBlocks,
    isValidTotalSizeInBlocks,
} from './defineGridValidationService';
import GridBounds, { Modes } from './GridBounds';
import LabeledTextField from '../Shared/LabeledTextField';
import { getInitialValidationResults, getReferenceBounds, isValidBounds } from '../Shared/boundsValidationService';
import { useXyzBoundingBox } from 'Common/DRIVER2XYZ/useXyzBoundingBox';
import GenericDraggableShell from 'Common/components/GenericDraggable/GenericDraggableShell';
import { useDefaultPosition } from '../Shared/useDefaultPositionHook';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { useSessionContext } from 'App/context/SessionContext';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create(() => ({
    textField: {
        flex: '0 0 29%',
        gridTemplateColumns: '27% auto',
        display: 'grid',
    },

    boundsRow: {
        columnGap: '19px',
        marginTop: '15px',
        alignItems: 'center',
    },

    title: {
        marginTop: '25px',
        alignItems: 'center',
    },
    radio: {
        marginLeft: '-9px',
    },
    root: {
        position: 'absolute',
        width: '750px',
        zIndex: '1300',
        top: 30,
        left: 30,
    },
}));

function getTotalBlocksFormatted(sizesInBlocks: BlocksDimensionsStrings) {
    // TODO: deprecate the sizeInBlocks.totalBlocks
    const totalBlocks = getTotalBlocksNumber(sizesInBlocks);

    if (isNaN(totalBlocks)) {
        return '-';
    }

    return totalBlocks.toLocaleString();
}

export type DefineGridDialogProps = {
    gridId?: ObjectIDType;
    handleClose(): void;
};

export default function DefineGridDialog(props: DefineGridDialogProps) {
    const xyz = useXyz();

    const { handleClose } = props;
    const { classes } = useStyles();

    const gridData = useAppSelector(selectGridById(props.gridId));

    const drillingData = useAppSelector(selectActiveDrilling);

    const { onExpandChange } = useExpandedNodes();

    if (!drillingData) {
        return null;
    }

    const initialRawData = getInitialGridData(drillingData, gridData);

    const originalBounds = getBoundsFromGridData(initialRawData);
    const originalBlockSizes = getBlockSizesFromGridData(initialRawData);
    const originalGridName = getGridNameFromGridData(initialRawData);
    const drillingBounds = getDrillingBounds(drillingData);

    const initialSizeInBlocks = getSizeInBlocks(originalBounds, originalBlockSizes);

    const [bounds, setBounds] = React.useState<BoundsStringsObject>(boundsObjectToStringsObject(originalBounds));
    const [blockSizes, setBlockSizes] = React.useState<StringCoordinatesObject>(
        coordinatesToStrings(originalBlockSizes)
    );
    const [gridName, setGridName] = React.useState(originalGridName);
    const [nameValidationResult, setNameValidationResult] =
        React.useState<ValidationResult>(getDefaultValidationResult());
    const [blockSizesValidationResult, setBlockSizesValidationResult] = React.useState<BlockSizesValidationResult>(
        getInitialBlockSizesValidationResults()
    );
    const [boundsValidationResult, setBoundsValidationResult] =
        React.useState<BoundsValidationResults>(getInitialValidationResults());
    const [totalSizeInBlocksValidationResult, setTotalSizeInBlocksValidationResult] =
        React.useState<ValidationResult>(getDefaultValidationResult());
    const [sizesInBlocks, setSizesInBlocks] = React.useState<BlocksDimensionsStrings>(
        blocksDimensionsToStrings(initialSizeInBlocks)
    );
    const [isBlocksMode, setIsBlocksMode] = React.useState(false);
    const [sizesInBlocksValidationResult, setSizesInBlocksValidationResult] =
        React.useState<BlocksDimensionsValidationResult>(getSizesInBlocksInitialValidationResult());

    const { currentOrgUuid } = React.useContext(DataContext);
    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);

    const [waitingForSubmit, setWaitingForSubmit] = React.useState(false);

    const currentProjectId = useAppSelector(selectCurrentProjectId);

    const dispatch = useAppDispatch();

    const { updateBoundingBoxCorners } = useXyzBoundingBox(
        xyz,
        dispatch,
        [drillingBounds.minX, drillingBounds.minY, drillingBounds.minZ],
        [drillingBounds.maxX, drillingBounds.maxY, drillingBounds.maxZ]
    );

    const hasDataChanged = !(
        _.isEqual(bounds, boundsObjectToStringsObject(originalBounds)) &&
        _.isEqual(blockSizes, coordinatesToStrings(originalBlockSizes)) &&
        gridName === originalGridName
    );

    const isSubmitDisabled = () => {
        if (
            nameValidationResult.isValid &&
            isObjectValid(blockSizesValidationResult) &&
            isObjectValid(boundsValidationResult) &&
            isObjectValid(sizesInBlocksValidationResult) &&
            totalSizeInBlocksValidationResult.isValid
        ) {
            if (gridData) {
                if (gridData.status === ObjectStatusTypes.FAILED) {
                    return false;
                }
                return !hasDataChanged;
            }
            return false;
        }
        return true;
    };

    const onSubmit = async () => {
        // maybe onBlur didn't have a chance to run yet, so we run validation manually
        const {
            nameValidationResult,
            blockSizesValidationResult,
            boundsValidationResult,
            sizesInBlocksValidationResult,
            totalSizeInBlocksValidationResult,
        } = getAllValidationResults();

        setBlockSizesValidationResult(blockSizesValidationResult);
        setNameValidationResult(nameValidationResult);
        setBoundsValidationResult(boundsValidationResult);
        setSizesInBlocksValidationResult(sizesInBlocksValidationResult);
        setTotalSizeInBlocksValidationResult(totalSizeInBlocksValidationResult);

        if (
            nameValidationResult.isValid &&
            isObjectValid(blockSizesValidationResult) &&
            isObjectValid(boundsValidationResult) &&
            isObjectValid(sizesInBlocksValidationResult) &&
            totalSizeInBlocksValidationResult.isValid
        ) {
            const formResults = formResultsFromData(bounds, blockSizes, gridName);
            updateGridDefinition(
                formResults,
                gridData,
                axiosDriverFlask,
                currentProjectId,
                currentOrgUuid,
                currentWorkspaceId
            )
                .then((projectUpdateJson) => {
                    dispatch(
                        applyWebsocketModificationsToCurrentProject(
                            xyz,
                            axiosDriverFlask,
                            projectUpdateJson.data,
                            currentProjectId,
                            gridData ? 'update grid' : 'post grid',
                            tokenProvider
                        )
                    );
                    expandGridNodes(onExpandChange);
                    setWaitingForSubmit(false);
                    onClose();
                })
                .catch((error: Error | AxiosError) => {
                    setWaitingForSubmit(false);
                    throw error;
                });

            setWaitingForSubmit(true);
        }
    };

    const onClose = () => {
        handleClose();
    };

    const generateOnBlockSizeUpdate = (key: CoordinateKey) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setBlockSizes({ ...blockSizes, [key]: event.target.value });
    };

    const generateOnBlockSizeBlur = (coordinateKey: CoordinateKey) => () => {
        const minBoundKey = coordinateKeyToKeys[coordinateKey].minBound;
        const blocksKey = coordinateKeyToKeys[coordinateKey].blocks;
        const maxBoundKey = coordinateKeyToKeys[coordinateKey].maxBound;
        calculateBoundsOrBlocks(minBoundKey, coordinateKey, maxBoundKey, blocksKey);
    };

    const onGridNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGridName(event.target.value);
    };

    const onGridNameBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setNameValidationResult(isValidGridName(event.target.value));
    };

    const generateOnBoundsChange = (key: BoundsKey) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newBounds = { ...bounds, [key]: event.target.value };
        setBounds(newBounds);
        const boundsNumbers = stringsObjectToBoundsObject(newBounds);
        void updateBoundingBoxCorners(
            [boundsNumbers.minX, boundsNumbers.minY, boundsNumbers.minZ],
            [boundsNumbers.maxX, boundsNumbers.maxY, boundsNumbers.maxZ]
        );
    };

    const getAllValidationResults = (
        newBounds?: BoundsStringsObject,
        newBlockSizes?: StringCoordinatesObject,
        newSizesInBlocks?: BlocksDimensionsStrings
    ) => {
        const nameValidationResult = isValidGridName(gridName);
        const blockSizesValidationResult = isValidBlockSizes(newBlockSizes || blockSizes);
        const boundsValidationResult = isValidBounds(newBounds || bounds, drillingBounds);
        const sizesInBlocksValidationResult = isValidSizesInBlocks(newSizesInBlocks || sizesInBlocks);
        const totalSizeInBlocksValidationResult = isValidTotalSizeInBlocks(newSizesInBlocks || sizesInBlocks);

        return {
            nameValidationResult,
            blockSizesValidationResult,
            boundsValidationResult,
            sizesInBlocksValidationResult,
            totalSizeInBlocksValidationResult,
        };
    };

    // we don't want to run the validation on every state update
    // to avoid annoying user experience of errors appearing while typing.
    // only want to run the validation in onBlur of the textboxes
    const setValidationResults = (args: {
        nameValidationResult: ValidationResult;
        blockSizesValidationResult: BlockSizesValidationResult;
        boundsValidationResult: BoundsValidationResults;
        sizesInBlocksValidationResult: BlocksDimensionsValidationResult;
        totalSizeInBlocksValidationResult: ValidationResult;
    }) => {
        setNameValidationResult(args.nameValidationResult);
        setBoundsValidationResult(args.boundsValidationResult);
        setBlockSizesValidationResult(args.blockSizesValidationResult);
        setSizesInBlocksValidationResult(args.sizesInBlocksValidationResult);
        setTotalSizeInBlocksValidationResult(args.totalSizeInBlocksValidationResult);
    };

    const calculateBoundsOrBlocks = (
        minBoundKey: BoundsKey,
        coordinateKey: CoordinateKey,
        maxBoundKey: BoundsKey,
        blocksKey: BlocksKey,
        newMinBoundValue?: string,
        newMaxBoundValue?: string,
        newBlockSize?: string,
        newSizeInBlocks?: string
    ) => {
        setValidationResults(getAllValidationResults()); // if anything is empty, we want to show error

        const minBoundValue = newMinBoundValue || bounds[minBoundKey];
        const maxBoundValue = newMaxBoundValue || bounds[maxBoundKey];
        const blockSize = newBlockSize || blockSizes[coordinateKey];
        const sizeInBlocks = newSizeInBlocks || sizesInBlocks[blocksKey];

        if (isBlocksMode) {
            if (minBoundValue && sizeInBlocks && blockSize) {
                const newUpperBound = getUpperBoundFromNumOfBlocks(
                    Number(minBoundValue),
                    Number(sizeInBlocks),
                    Number(blockSize)
                );

                const newBounds = {
                    ...bounds,
                    [maxBoundKey]: `${newUpperBound}`,
                };
                setBounds(newBounds);
                setValidationResults(getAllValidationResults(newBounds));
            }
        } else if (minBoundValue && maxBoundValue && blockSize) {
            const newNumOfBlocks = getNumOfBlocksFromBounds(
                Number(minBoundValue),
                Number(maxBoundValue),
                Number(blockSize)
            );

            const newSizesInBlocks = {
                ...sizesInBlocks,
                [blocksKey]: `${newNumOfBlocks}`,
            };
            setSizesInBlocks(newSizesInBlocks);
            setValidationResults(getAllValidationResults(null, null, newSizesInBlocks));
        }
    };

    const generateOnMinBoundsBlur = (minBoundKey: BoundsKey) => () => {
        const blocksKey = minBoundKeyToKeys[minBoundKey].blocks;
        const coordinateKey = minBoundKeyToKeys[minBoundKey].coordinate;
        const maxBoundKey = minBoundKeyToKeys[minBoundKey].maxBound;
        calculateBoundsOrBlocks(minBoundKey, coordinateKey, maxBoundKey, blocksKey);
    };

    const generateOnMaxBoundsBlur = (maxBoundKey: BoundsKey) => () => {
        const blocksKey = maxBoundKeyToKeys[maxBoundKey].blocks;
        const coordinateKey = maxBoundKeyToKeys[maxBoundKey].coordinate;
        const minBoundKey = maxBoundKeyToKeys[maxBoundKey].minBound;
        calculateBoundsOrBlocks(minBoundKey, coordinateKey, maxBoundKey, blocksKey);
    };

    const onBlocksChange = (key: BlocksKey) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSizesInBlocks({ ...sizesInBlocks, [key]: event.target.value });
    };

    const generateOnSizeInBlocksBlur = (blocksKey: BlocksKey) => () => {
        const coordinateKey = blocksKeyToKeys[blocksKey].coordinate;
        const maxBoundKey = blocksKeyToKeys[blocksKey].maxBound;
        const minBoundKey = blocksKeyToKeys[blocksKey].minBound;
        calculateBoundsOrBlocks(minBoundKey, coordinateKey, maxBoundKey, blocksKey);
    };

    const onModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsBlocksMode(event.target.value === Modes.blocksMode);
    };

    const minBoundsNotEmpty = bounds.minX && bounds.minY && bounds.minZ;
    const maxBoundsNotEmpty = bounds.maxX && bounds.maxY && bounds.maxZ;
    const blockSizesNotEmpty = blockSizes.x && blockSizes.y && blockSizes.z;
    const sizesInBlocksNotEmpty = sizesInBlocks.xBlocks && sizesInBlocks.yBlocks && sizesInBlocks.zBlocks;

    // assuming originalBounds aren't empty
    const onMinBoundsReset = () => {
        const newBounds = {
            ...bounds,
            minX: `${originalBounds.minX}`,
            minY: `${originalBounds.minY}`,
            minZ: `${originalBounds.minZ}`,
        };
        let validationResults = getAllValidationResults(newBounds);

        if (isBlocksMode) {
            if (blockSizesNotEmpty && sizesInBlocksNotEmpty) {
                const newBoundsFromBlocks = boundsObjectToStringsObject(
                    getUpperBoundsFromBlocks(
                        stringsObjectToBoundsObject(newBounds),
                        StringCoordinatesToNumbers(blockSizes),
                        blocksStringsToNumbers(sizesInBlocks)
                    )
                );
                setBounds(newBoundsFromBlocks);
                validationResults = getAllValidationResults(newBoundsFromBlocks);
            } else {
                setBounds(newBounds);
            }
        } else {
            setBounds(newBounds);

            if (maxBoundsNotEmpty && blockSizesNotEmpty) {
                const newSizesInBlocks = blocksDimensionsToStrings(
                    getSizeInBlocks(stringsObjectToBoundsObject(newBounds), StringCoordinatesToNumbers(blockSizes))
                );
                setSizesInBlocks(newSizesInBlocks);
                validationResults = getAllValidationResults(newBounds, null, newSizesInBlocks);
            }
        }

        setValidationResults(validationResults);
    };

    // assuming originalBounds aren't empty
    const onMaxBoundsReset = () => {
        const newBounds = {
            ...bounds,
            maxX: `${originalBounds.maxX}`,
            maxY: `${originalBounds.maxY}`,
            maxZ: `${originalBounds.maxZ}`,
        };
        setBounds(newBounds);
        let validationResults = getAllValidationResults(newBounds);

        if (minBoundsNotEmpty && blockSizesNotEmpty) {
            const newSizesInBlocks = blocksDimensionsToStrings(
                getSizeInBlocks(stringsObjectToBoundsObject(newBounds), StringCoordinatesToNumbers(blockSizes))
            );
            setSizesInBlocks(newSizesInBlocks);
            validationResults = getAllValidationResults(newBounds, null, newSizesInBlocks);
        }

        setValidationResults(validationResults);
    };

    // assuming originalBlockSizes aren't empty
    const onBlockSizesReset = () => {
        const newBlocksSizes = coordinatesToStrings(originalBlockSizes);
        setBlockSizes(newBlocksSizes);
        let validationResults = getAllValidationResults(null, newBlocksSizes);

        if (isBlocksMode) {
            if (minBoundsNotEmpty && sizesInBlocksNotEmpty) {
                const newBoundsFromBlocks = boundsObjectToStringsObject(
                    getUpperBoundsFromBlocks(
                        stringsObjectToBoundsObject(bounds),
                        StringCoordinatesToNumbers(newBlocksSizes),
                        blocksStringsToNumbers(sizesInBlocks)
                    )
                );
                setBounds(newBoundsFromBlocks);
                validationResults = getAllValidationResults(newBoundsFromBlocks, newBlocksSizes);
            }
        } else if (minBoundsNotEmpty && maxBoundsNotEmpty) {
            const newSizesInBlocks = blocksDimensionsToStrings(
                getSizeInBlocks(stringsObjectToBoundsObject(bounds), StringCoordinatesToNumbers(newBlocksSizes))
            );
            setSizesInBlocks(newSizesInBlocks);
            validationResults = getAllValidationResults(null, newBlocksSizes, newSizesInBlocks);
        }

        setValidationResults(validationResults);
    };

    const [defaultPosition, createPositionChangeHandler] = useDefaultPosition(null);

    return (
        <GenericDraggableShell
            zIndexContext={false}
            divider
            defaultPosition={defaultPosition}
            onCloseClicked={onClose}
            dataCy={gridDialogDataCy}
            headerName="Create Grid"
            onPositionChange={createPositionChangeHandler}
            classes={{
                root: {
                    [classes.root]: true,
                },
            }}
        >
            <DialogContent>
                <Grid container spacing={1}>
                    <LabeledTextField
                        className={classes.textField}
                        title="Name:"
                        placeholder={originalGridName}
                        onChange={onGridNameChange}
                        value={gridName}
                        dataCy={gridNameTextBoxDataCy}
                        onBlur={onGridNameBlur}
                        validationResult={nameValidationResult}
                    />

                    <BlockSize
                        blockSizes={blockSizes}
                        generateOnChange={generateOnBlockSizeUpdate}
                        generateOnBlur={generateOnBlockSizeBlur}
                        blockSizesValidationResult={blockSizesValidationResult}
                        originalBlockSizes={coordinatesToStrings(originalBlockSizes)}
                        onBlockSizesReset={onBlockSizesReset}
                        classes={classes}
                    />

                    <GridBounds
                        originalBounds={originalBounds}
                        bounds={bounds}
                        originalSizeInBlocks={blocksDimensionsToStrings(initialSizeInBlocks)}
                        generateOnBoundsChange={generateOnBoundsChange}
                        boundsValidationResult={boundsValidationResult}
                        onModeChange={onModeChange}
                        referenceBounds={getReferenceBounds(drillingBounds)}
                        generateOnBlocksChange={onBlocksChange}
                        sizeInBlocks={sizesInBlocks}
                        generateOnSizeInBlocksBlur={generateOnSizeInBlocksBlur}
                        isBlocksMode={isBlocksMode}
                        generateOnMinBoundsBlur={generateOnMinBoundsBlur}
                        generateOnMaxBoundsBlur={generateOnMaxBoundsBlur}
                        onMinBoundsReset={onMinBoundsReset}
                        onMaxBoundsReset={onMaxBoundsReset}
                        classes={classes}
                        totalBlocks={getTotalBlocksFormatted(sizesInBlocks)}
                        sizesInBlocksValidationResult={sizesInBlocksValidationResult}
                        totalSizeInBlocksValidationResult={totalSizeInBlocksValidationResult}
                    />
                </Grid>
            </DialogContent>
            <GenericDialogActions
                onCancel={onClose}
                onSubmit={onSubmit}
                disabled={isSubmitDisabled() || waitingForSubmit}
                showSpinner={waitingForSubmit}
            />
        </GenericDraggableShell>
    );
}
