import CreateOverlaps from 'App/MainApp/Dialogs/OverlapsPanel/CreateOverlaps';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { ObjectMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { selectActiveZones } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { OverlapFolderMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { createOverlapsDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function OverlapsDialogButton(props: ObjectMenuItemProps) {
    const zones = useAppSelector(selectActiveZones);
    const disabled = !Object.values(zones).length;
    const tooltip = disabled && 'Requires at least one zone';

    const dialogProps = {
        overlapsFolderId: props.nodeData.id,
    };

    const depreciateOverlaps = useFlags()?.depreciateOverlaps;

    if (depreciateOverlaps) {
        return null;
    }

    return (
        <DialogOpenerMenuItem
            name={OverlapFolderMenuItems.CreateOverlaps}
            dataCy={createOverlapsDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            dialogComponent={CreateOverlaps}
            dialogProps={dialogProps}
            tooltipText={tooltip}
        />
    );
}
