import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import { tss } from 'tss-react/mui';
import { useAppSelector } from 'App/Redux/hooks';
import {
    selectActiveDrilling,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
    selectMeshById,
    selectSuccessfulOpenMeshes,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import React from 'react';
import { BoundsKey, BoundsStringsObject } from 'Common/types/geometryTypes';
import WidgetSelect from 'Common/components/Core/WidgetSelect';
import {
    aboveMeshNameDataCy,
    belowMeshNameDataCy,
    clipRegionDialogDataCy,
    openMeshesDropdownDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { boundsObjectToStringsObject, stringsObjectToBoundsObject } from 'Common/utils/geometryHelpers';
import BoundsRow from '../Shared/BoundsRow';
import { maxKeys, minKeys, minMaxKeysToTitles } from '../Shared/consts';
import { getDrillingBounds } from '../DefineGrid/defineGridService';
import { getInitialValidationResults, isAllBoundsValid, isValidBounds } from '../Shared/boundsValidationService';
import { BoundsValidationResults } from '../DefineGrid/gridLimitsHelpers';
import { DataContext } from '../../../DataContext';
import {
    clipRegionWithSurface,
    convertBoundKeys,
    getDefaultResolution,
    isResolutionValid,
} from './clipRegionWithSurfaceService';
import { ValidationResult, getDefaultValidationResult } from '../../../util/validationUtils';
import LabeledTextField from '../Shared/LabeledTextField';
import LabeledNumberField from '../Shared/LabeledNumberField';
import { ObjectIDType } from '../../../util/ProjectDataTypes/ProjectObjectsDataTypes';
import { useCalculateBoundsIncrements } from '../Shared/useCalculateBoundsIncrementsHook';
import GenericDraggableShell from 'Common/components/GenericDraggable/GenericDraggableShell';
import { useDefaultPosition } from '../Shared/useDefaultPositionHook';
import { useSessionContext } from 'App/context/SessionContext';
import { commonStyles } from 'styles/commonStyles';

const useStyles = tss.create(({ theme }) => ({
    textField: {
        flex: '0 0 29%',
        gridTemplateColumns: '27% auto',
        display: 'grid',
    },

    boundsRow: {
        columnGap: '19px',
        // marginTop: '15px',
        alignItems: 'center',
    },

    title: {
        alignItems: 'center',
        fontWeight: 'bold',
    },

    subtitle: {
        alignItems: 'center',
        margin: '10px 0',
    },

    subWrapper: {
        rowGap: '10px',
    },

    subWrapperNotFirst: {
        marginTop: '25px',
    },

    radio: {
        marginLeft: '-9px',
    },

    textFieldNearCheckbox: {
        display: 'grid',
        gridTemplateColumns: '17% auto',
    },

    formRow: {
        display: 'grid',
        gridTemplateColumns: '35% auto',
        alignItems: 'center',
    },

    meshNameRow: {
        flexWrap: 'nowrap',
    },
    root: {
        position: 'absolute',
        width: '750px',
        zIndex: '1300',
        top: 30,
        left: 30,
    },
    defaultText: {
        ...commonStyles({ theme }).defaultText,
    },
}));

enum RetainAboveBelowValues {
    retainAbove = 'retainAbove',
    retainBelow = 'retainBelow',
}

const detailResolutionTooltip =
    'Clipping surface will use a linear RBF interpolant to recalculate and extend the surface to the limits of the region box. This parameter is the resolution of the clip surface';

export default function ClipRegionWithSurface(props: { handleClose: () => void }) {
    const { classes } = useStyles();
    const { currentOrgUuid } = React.useContext(DataContext);
    const { axiosDriverFlask } = useSessionContext();
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const openMeshes = Object.values(useAppSelector(selectSuccessfulOpenMeshes));

    const drillingData = useAppSelector(selectActiveDrilling);
    const drillingBounds = getDrillingBounds(drillingData);
    const defaultResolution = getDefaultResolution(drillingBounds);

    const [bounds, setBounds] = React.useState<BoundsStringsObject>(boundsObjectToStringsObject(drillingBounds));
    const [boundsValidationResults, setBoundsValidationResults] =
        React.useState<BoundsValidationResults>(getInitialValidationResults());
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [chosenOpenMesh, setChosenOpenMesh] = React.useState<ObjectIDType>(openMeshes[0].id);
    const [resolution, setResolution] = React.useState<string>(`${defaultResolution}`);
    const [resolutionValidationResult, setResolutionValidationResult] =
        React.useState<ValidationResult>(getDefaultValidationResult());
    const [retainBelow, setRetainBelow] = React.useState<boolean>(false);
    const [retainAbove, setRetainAbove] = React.useState<boolean>(true);
    const [belowMeshName, setBelowMeshName] = React.useState<string>('');
    const [aboveMeshName, setAboveMeshName] = React.useState<string>('');

    const { minIncrements, maxIncrements } = useCalculateBoundsIncrements(drillingBounds);

    const onClose = () => {
        props.handleClose();
    };

    const generateOnBoundsChange = (key: BoundsKey) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setBounds({ ...bounds, [key]: event.target.value });
    };

    const runBoundsValidation = () => {
        const validationResultsForBounds = isValidBounds(bounds, drillingBounds);
        setBoundsValidationResults(validationResultsForBounds);
        return validationResultsForBounds;
    };

    const runValidation = () => {
        const validationResultsForBounds = runBoundsValidation();

        const validationResultForResolution = isResolutionValid(resolution, defaultResolution);
        setResolutionValidationResult(validationResultForResolution);

        const isEverythingValid = isAllBoundsValid(validationResultsForBounds) && validationResultForResolution.isValid;

        return isEverythingValid;
    };

    const generateOnBlur = () => () => {
        runValidation();
    };

    const onSubmit = async () => {
        setIsLoading(true);
        const isEverythingValid = runValidation();

        if (isEverythingValid) {
            const data = {
                bounds: convertBoundKeys(stringsObjectToBoundsObject(bounds)),
                chosenOpenMesh,
                resolution: parseInt(resolution),
                retainBelow,
                retainAbove,
                retainBox: false,
                belowMeshName: `${belowMeshPrefix}${belowMeshName}`,
                aboveMeshName: `${aboveMeshPrefix}${aboveMeshName}`,
                boxMeshName: `${boxMeshPrefix}`,
            };

            const result = await clipRegionWithSurface(
                data,
                axiosDriverFlask,
                currentProjectId,
                currentOrgUuid,
                currentWorkspaceId
            );

            if (result) {
                props.handleClose();
            }
        }
        setIsLoading(false);
    };

    const onOpenMeshChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChosenOpenMesh(event.target.value);
    };

    const onResolutionChange = (event: React.ChangeEvent<HTMLInputElement>) => setResolution(event.target.value);

    const onResolutionBlur = () => {
        runValidation();
    };

    const onRetainAboveBelowChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setRetainAbove(event.target.value === RetainAboveBelowValues.retainAbove);
        setRetainBelow(event.target.value === RetainAboveBelowValues.retainBelow);
    };

    const onBelowMeshNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setBelowMeshName(event.target.value);
    const onAboveMeshNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setAboveMeshName(event.target.value);

    const chosenOpenMeshName = useAppSelector(selectMeshById(chosenOpenMesh))?.original_file_name;
    const belowMeshPrefix = `below_${chosenOpenMeshName}_`;
    const aboveMeshPrefix = `above_${chosenOpenMeshName}_`;
    const boxMeshPrefix = `box_`;

    const isSubmitDisabled =
        !isAllBoundsValid(boundsValidationResults) || isLoading || !resolutionValidationResult.isValid;

    const [defaultPosition, createPositionChangeHandler] = useDefaultPosition(null);

    return (
        <GenericDraggableShell
            zIndexContext={false}
            divider
            defaultPosition={defaultPosition}
            onCloseClicked={onClose}
            dataCy={clipRegionDialogDataCy}
            headerName="Clip region with surface"
            onPositionChange={createPositionChangeHandler}
            classes={{
                root: {
                    [classes.root]: true,
                },
            }}
        >
            <DialogContent>
                <Grid item container direction="column">
                    <Grid item container direction="column" className={classes.subWrapper}>
                        <Typography className={`${classes.title} ${classes.defaultText}`}>Region bounds</Typography>

                        <Grid item container className={classes.subtitle}>
                            <Typography className={classes.defaultText}>Origin (base)</Typography>
                        </Grid>
                        <BoundsRow
                            keys={minKeys}
                            keysToTitles={minMaxKeysToTitles}
                            values={bounds}
                            validationResults={boundsValidationResults}
                            generateOnChange={generateOnBoundsChange}
                            generateOnBlur={generateOnBlur}
                            placeholders={drillingBounds}
                            className={classes.boundsRow}
                            textFieldClassName={classes.textField}
                            increments={minIncrements}
                        />

                        <Grid item container className={classes.subtitle}>
                            <Typography className={classes.defaultText}>End (top)</Typography>
                        </Grid>
                        <BoundsRow
                            keys={maxKeys}
                            keysToTitles={minMaxKeysToTitles}
                            values={bounds}
                            validationResults={boundsValidationResults}
                            generateOnChange={generateOnBoundsChange}
                            generateOnBlur={generateOnBlur}
                            placeholders={drillingBounds}
                            className={classes.boundsRow}
                            textFieldClassName={classes.textField}
                            increments={maxIncrements}
                        />
                    </Grid>

                    <Grid
                        item
                        container
                        direction="column"
                        className={`${classes.subWrapper} ${classes.subWrapperNotFirst}`}
                    >
                        <Typography className={`${classes.title} ${classes.defaultText}`}>Clipping surface</Typography>

                        <Grid item container className={classes.formRow}>
                            <Typography className={classes.defaultText}>Select clipping surface</Typography>
                            <WidgetSelect
                                value={chosenOpenMesh}
                                onChange={onOpenMeshChange}
                                data-cy={openMeshesDropdownDataCy}
                            >
                                {openMeshes.map((mesh) => (
                                    <MenuItem key={mesh.id} value={mesh.id}>
                                        {mesh.original_file_name}
                                    </MenuItem>
                                ))}
                            </WidgetSelect>
                        </Grid>

                        <LabeledNumberField
                            value={resolution}
                            onChange={onResolutionChange}
                            onBlur={onResolutionBlur}
                            type="number"
                            infoTooltip={detailResolutionTooltip}
                            title="Detail resolution"
                            className={classes.formRow}
                            validationResult={resolutionValidationResult}
                            dataCy="resolution-textbox"
                        />
                    </Grid>

                    <Grid
                        item
                        container
                        direction="column"
                        className={`${classes.subWrapper} ${classes.subWrapperNotFirst}`}
                    >
                        <Typography className={`${classes.title} ${classes.defaultText}`}>Output meshes</Typography>

                        <Grid item container className={classes.meshNameRow}>
                            <LabeledTextField
                                value={aboveMeshName}
                                onChange={onAboveMeshNameChange}
                                title="Retain above"
                                dataCy={aboveMeshNameDataCy}
                                className={classes.textFieldNearCheckbox}
                                prefix={aboveMeshPrefix}
                                disabled={!retainAbove}
                            />
                            <Radio
                                checked={retainAbove}
                                onChange={onRetainAboveBelowChange}
                                value={RetainAboveBelowValues.retainAbove}
                            />
                        </Grid>

                        <Grid item container className={classes.meshNameRow}>
                            <LabeledTextField
                                value={belowMeshName}
                                onChange={onBelowMeshNameChange}
                                title="Retain below"
                                dataCy={belowMeshNameDataCy}
                                className={classes.textFieldNearCheckbox}
                                prefix={belowMeshPrefix}
                                disabled={!retainBelow}
                            />
                            <Radio
                                checked={retainBelow}
                                onChange={onRetainAboveBelowChange}
                                value={RetainAboveBelowValues.retainBelow}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <GenericDialogActions
                onCancel={onClose}
                onSubmit={onSubmit}
                disabled={isSubmitDisabled}
                showSpinner={isLoading}
            />
        </GenericDraggableShell>
    );
}
