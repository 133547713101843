import { CreateAnisotropy } from 'App/MainApp/Dialogs/SCMAnisotropy/CreateAnisotropy';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { selectActiveDomains } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { AnisotropySectionMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { createAnisotropyDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';

export function CreateAnisotropyButton(props: GeneralMenuItemProps) {
    const domains = useAppSelector(selectActiveDomains);
    const disabled = !Object.keys(domains).length;
    const tooltipText = disabled ? 'Drilling and at least one domain required' : '';

    return (
        <DialogOpenerMenuItem
            name={AnisotropySectionMenuItems.NewAnisotropyEstimation}
            dataCy={createAnisotropyDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            dialogComponent={CreateAnisotropy}
            tooltipText={tooltipText}
        />
    );
}
