import { commonStyles } from 'styles/commonStyles';
import { tss } from 'tss-react/mui';

const styles = ({ theme }) =>
    ({
        root: {
            ...commonStyles({ theme }).overlayBackground,
        },
        header: {
            padding: theme.spacing(0.1, 0, 0.1, 0),
        },
        container: {
            overflow: 'auto',
        },
        removeButton: {
            fontSize: '12px',
            padding: theme.spacing(0.5),
        },
        title: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
            paddingTop: '4px',
            margin: '5px',
            textAlign: 'center',
        },
        tooltip: {
            maxWidth: 'none',
        },
        divider: {
            paddingBottom: '3px',
        },
    }) as const;

export const useStyles = tss.create(styles);
