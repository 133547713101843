import Grid from '@mui/material/Grid';
import { ValidationResult } from '../../../util/validationUtils';
import LabeledNumberField from './LabeledNumberField';

type BoundsRowProps = {
    className?: string;
    textFieldClassName?: string;
    getTextFieldBounds?: (key: string) => { min: number; max: number };
    keys: string[];
    values: { [key: string]: string };
    keysToTitles: { [key: string]: string };
    validationResults?: { [key: string]: ValidationResult };
    generateOnChange: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    generateOnBlur?: (key: string) => (event: React.FocusEvent<HTMLInputElement>) => void;
    placeholders?: { [key: string]: string | number };
    isRowDisabled?: boolean;
    increments?: { [key: string]: number };
};

export default function BoundsRow(props: BoundsRowProps) {
    return (
        <Grid item container className={props.className}>
            {props.keys.map((key) => (
                <LabeledNumberField
                    key={key}
                    title={props.keysToTitles[key]}
                    validationResult={props.validationResults?.[key]}
                    value={props.values[key]}
                    onChange={props.generateOnChange(key)}
                    onBlur={props.generateOnBlur && props.generateOnBlur(key)}
                    dataCy={`${key.toLowerCase()}-textbox`}
                    boundBounds={props.getTextFieldBounds && props.getTextFieldBounds(key)}
                    placeholder={`${props.placeholders?.[key] || ''}`}
                    className={props.textFieldClassName}
                    disabled={props.isRowDisabled}
                    incrementStep={props.increments?.[key]}
                />
            ))}
        </Grid>
    );
}
