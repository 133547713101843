import { Dependants } from 'App/util/ProjectDataTypes/utilClasses/Dependants';
import { OBJECT_CLASS_NAMES, ObjectIDType } from './ProjectObjectsDataTypes';
import { BackendJsonifiedProjectObject, BaseProjectObject } from './BackendJsonifiedProjectObject';
import { ThresholdTypes } from 'App/MainApp/Dialogs/SCMAnisotropy/anisotropyConsts';

export interface AnisotropyEstimationResultMetadata {
    num_centers: number;
    calculated_parameters: {
        epsilon?: number;
        threshold_value: number;
    };
    default_display_range: number;
    color_attributes: string[];
}

export interface AnisotropyEstimationType extends BackendJsonifiedProjectObject {
    name: string;
    dataAttribute_in_drill: string;
    domainId: ObjectIDType;
    sourceFileId: ObjectIDType;
    parameters: {
        name_suffix: string;

        user_supplied_locations: boolean;

        threshold_type: string;
        threshold_value: number;
        resolution: string;
        output_format: string;

        ellipsoid_smoothing: number;
        cluster_level?: number;
        featureFlags?: {
            anisotropyClustering?: boolean;
        };
    }; // Please put the parameters here.
    result_meta_data: AnisotropyEstimationResultMetadata;
    object_class_name: OBJECT_CLASS_NAMES.AnisotropyEstimation;
}

export interface AnisotropyCentersCoordinates {
    x: number[];
    y: number[];
    z: number[];
}

export interface AnisotropyCommonCentersData {
    drill_hole_support: number[];
    error: number[];
    ellipticity: number[];
}

export interface AnisotropyCentersData extends AnisotropyCommonCentersData {
    enabled: boolean[];
}

export interface AnisotropyStereonetData {
    major_vectors: [x: number, y: number, z: number][];
    normal_vectors: [x: number, y: number, z: number][];
    dip: number[];
    dip_azimuth: number[];
    plunge: number[];
    plunge_azimuth: number[];
}

export interface AnisotropyParameters {
    name_suffix: string;
    user_supplied_locations: boolean;
    threshold_type: ThresholdTypes;
    threshold_value: number;
    resolution: string;
    output_format: string;
    ellipsoid_smoothing: number;
}

export class Anisotropy extends BaseProjectObject implements AnisotropyEstimationType {
    dataAttribute_in_drill: string;

    domainId: ObjectIDType;

    sourceFileId: ObjectIDType;

    parameters: AnisotropyParameters;

    result_meta_data: AnisotropyEstimationResultMetadata;

    object_class_name: OBJECT_CLASS_NAMES.AnisotropyEstimation;

    getDependants = () => {
        const gridAnisotropies = this.allObjects[OBJECT_CLASS_NAMES.AnisotropyGrid];
        const globalAnisotropies = this.allObjects[OBJECT_CLASS_NAMES.AnisotropyGlobal];
        const pointEstimations = this.allObjects[OBJECT_CLASS_NAMES.PointEstimation];

        const dependants = new Dependants();

        Object.values(gridAnisotropies).forEach((anisotropy) => {
            if (anisotropy.anisotropyEstimationId === this.id) {
                dependants.addDependantAndItsDependantsRaw(anisotropy, this.axiosDriverFlask, this.allObjects);
            }
        });

        Object.values(globalAnisotropies).forEach((anisotropy) => {
            if (anisotropy.anisotropyEstimationId === this.id) {
                dependants.addDependantAndItsDependantsRaw(anisotropy, this.axiosDriverFlask, this.allObjects);
            }
        });

        Object.values(pointEstimations).forEach((pointEstimation) => {
            if (pointEstimation.anisotropyEstimationId === this.id) {
                dependants.addDependantAndItsDependantsRaw(pointEstimation, this.axiosDriverFlask, this.allObjects);
            }
        });

        return dependants;
    };

    getDomain = () => this.projectObjects.getDomain(this.domainId);

    getDomainName = () => this.getDomain().getName();
}
