import { ProjectUpdateMessageType } from '../../../util/ProjectDataTypes/APIResponseTypes';
import { ObjectIDType } from '../../../util/ProjectDataTypes/ProjectObjectsDataTypes';

interface RecentlyReceivedProjectUpdatesType {
    projectId: ObjectIDType;
    broadCastTimes: string[];
}

const recentlyReceivedProjectUpdates: RecentlyReceivedProjectUpdatesType = {
    projectId: undefined,
    broadCastTimes: [],
};

export function addRecentlyReceivedProjectUpdate(projectUpdateMessage: ProjectUpdateMessageType) {
    if (!projectUpdateMessage?.project_update?.id) {
        return;
    }
    if (projectUpdateMessage?.project_update?.id !== recentlyReceivedProjectUpdates.projectId) {
        recentlyReceivedProjectUpdates.broadCastTimes = [];
    }
    recentlyReceivedProjectUpdates.broadCastTimes.push(projectUpdateMessage?.broadCastTime);
}

export function getRecentlyReceivedProjectUpdateBroadcastTimes(projectId: ObjectIDType): string[] {
    if (projectId !== recentlyReceivedProjectUpdates.projectId) {
        return [];
    }
    return recentlyReceivedProjectUpdates.broadCastTimes;
}
