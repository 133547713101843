import { TEXTBOX_ERROR_MESSAGES, ValidationResult, getDefaultValidationResult } from '../../../../util/validationUtils';

export function isValidName(name: string): ValidationResult {
    const validationResult = getDefaultValidationResult();

    if (name === '') {
        validationResult.isValid = false;
        validationResult.error = TEXTBOX_ERROR_MESSAGES.cannotBeEmpty;
    }

    return validationResult;
}
