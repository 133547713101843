import UpdateGrid from 'App/MainApp/Dialogs/DefineGrid/UpdateGrid';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { ObjectMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { GridMenuItemNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { defineGridDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function UpdateGridDialogButton(props: ObjectMenuItemProps) {
    const dialogProps = {
        gridId: props.nodeData.id,
    };

    const hideInterpolations = useFlags()?.hideInterpolations;

    if (hideInterpolations) {
        return null;
    }

    return (
        <DialogOpenerMenuItem
            name={GridMenuItemNames.UpdateGrid}
            dataCy={defineGridDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={false}
            dialogComponent={UpdateGrid}
            dialogProps={dialogProps}
        />
    );
}
