import { jobUrl2BackendUrl } from 'Common/UrlUtils';
import { OBJECT_CLASS_NAME, ObjectClassName2Id2Obj, ObjectIDType } from './ProjectObjectsDataTypes';
import { ProjectObjectHistory } from './JobHistory';

export interface ProjectObjectIdentifierSnapshot {
    readonly id: ObjectIDType;

    readonly objectClassName: OBJECT_CLASS_NAME;
}

export class ProjectObjectIdentifier implements ProjectObjectIdentifierSnapshot {
    readonly id: ObjectIDType;

    readonly objectClassName: OBJECT_CLASS_NAME;

    constructor(snapshot: ProjectObjectIdentifierSnapshot) {
        this.id = snapshot.id;
        this.objectClassName = snapshot.objectClassName;
    }

    getObject = (objectClassName2id2obj: ObjectClassName2Id2Obj) => {
        const obj = objectClassName2id2obj?.[this.objectClassName]?.[this.id];
        return obj;
    };

    getObjectUrl = (currentOrgUuid: string, objectClassName2id2obj: ObjectClassName2Id2Obj) => {
        const obj = this.getObject(objectClassName2id2obj);
        return jobUrl2BackendUrl(currentOrgUuid, obj?.url);
    };

    getVersionsHistory = (objectClassName2id2obj: ObjectClassName2Id2Obj): ProjectObjectHistory => {
        const obj = this.getObject(objectClassName2id2obj);
        if (!obj) {
            return undefined;
        }
        return new ProjectObjectHistory(obj.versionsHistory);
    };

    takeSnapshot = (): ProjectObjectIdentifierSnapshot => ({
        id: this.id,
        objectClassName: this.objectClassName,
    });
}
