const default_colors = [
    '#1f77b4', // muted blue
    '#ff7f0e', // safety orange
    '#2ca02c', // cooked asparagus green
    '#d62728', // brick red
    '#9467bd', // muted purple
    '#8c564b', // chestnut brown
    '#e377c2', // raspberry yogurt pink
    '#7f7f7f', // middle gray
    '#bcbd22', // curry yellow-green
    '#17becf', // blue-teal
];

export function getNextMeshColor() {
    const color_idx = getNextMeshColor.next_color_index_to_return;
    getNextMeshColor.next_color_index_to_return =
        (getNextMeshColor.next_color_index_to_return + 1) % default_colors.length;
    return default_colors[color_idx];
}
getNextMeshColor.next_color_index_to_return = 0;

export function resetMeshColors() {
    getNextMeshColor.next_color_index_to_return = 0;
}
