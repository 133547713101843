import { ProjectTreeObjectNode } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { addToViewIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import GenericIconButton from 'Common/components/Core/GenericIconButton';
import React from 'react';
import { DataContext } from 'App/DataContext';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import { displayObject } from 'App/MainApp/services/objectDisplayService';
import PlusAddToViewIcon from 'Common/components/icons/menuIcons/PlusAddToViewIcon';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useSessionContext } from 'App/context/SessionContext';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

export default function AddToViewButton(props: {
    nodeData: ProjectTreeObjectNode;
    visible: boolean;
    forceHover?: boolean;
    className?: string;
}) {
    const { addMessage } = useDriverMessagesContext();
    const allObjects = useAppSelector(selectObjectClassname2Id2Obj);
    const object = allObjects?.[props.nodeData.className]?.[props.nodeData.id];
    const tooltip = 'Add to scene';
    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();

    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const { currentOrgUuid } = React.useContext(DataContext);
    const xyz = useXyz();
    const dispatch = useAppDispatch();

    const anisotropyClustering = useFlags()?.anisotropyClustering;

    const onClick = async () => {
        await displayObject(
            object,
            dispatch,
            xyz,
            currentOrgUuid,
            props.nodeData,
            addMessage,
            anisotropyClustering,
            tokenProvider
        );
    };

    return (
        <GenericIconButton
            dataCy={addToViewIconDataCy}
            Icon={PlusAddToViewIcon}
            iconProps={{ sx: { fontSize: 12 } }}
            tooltipText={tooltip}
            onClick={onClick}
            style={{
                visibility: props.visible ? 'visible' : 'hidden',
                height: '100%',
                padding: '0.4rem',
            }}
            disablePortal
            forceHover={props.forceHover}
            buttonClassName={props.className}
        />
    );
}
