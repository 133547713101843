import { BoundsObject } from 'Common/types/geometryTypes';
import { AxiosDriverFlaskInstance } from '../../../../util/axiosErrorHandlers';
import { APIResponseWithProjectUpdate } from '../../../../util/ProjectDataTypes/APIResponseTypes';
import { backendProjectUrl } from 'App/Redux/features/globalContext/currentProjectSlice';

export async function createMeshFromRegion(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentProjectId: string,
    bounds: BoundsObject,
    name: string,
    orgId: string,
    workspaceId: string
) {
    const data = {
        name,
        box_limits: {
            xmin: bounds.minX,
            xmax: bounds.maxX,
            ymin: bounds.minY,
            ymax: bounds.maxY,
            zmin: bounds.minZ,
            zmax: bounds.maxZ,
        },
    };

    try {
        const response = await axiosDriverFlask.post<APIResponseWithProjectUpdate>(
            `${backendProjectUrl(orgId, workspaceId, currentProjectId)}/meshfiles/from_box`,
            data,
            {
                withCredentials: true,
                responseType: 'json',
            }
        );
        return response.data;
    } catch {
        return null;
    }
}
