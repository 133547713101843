import { Point3D } from 'Common/types/geometryTypes';

export enum OverlapState {
    SavedOverlap = 'savedOverlap',
    UnsavedOverlap = 'UnsavedOverlap',
}

export function getPercentileValues(
    arr: number[],
    percentileStart = 0,
    percentileStop = 100,
    percentileStep = 10
): {
    value: number;
    percentile: number;
}[] {
    const arrSorted = arr.slice().sort((o1, o2) => o1 - o2);
    const percentiles = [];
    for (let p = percentileStart; p < percentileStop; p += percentileStep) {
        const value = arrSorted[Math.round((p * arrSorted.length) / 100)];
        const isEqualToLastValue = percentiles[percentiles.length - 1]?.value === value;
        if (value && !isEqualToLastValue) {
            percentiles.push({
                value: value,
                percentile: p,
            });
        }
    }
    return percentiles;
}

export function hexToRgb(hex: string): {
    r: number;
    g: number;
    b: number;
} {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

export function hexToRgbArray(hex: string): Point3D {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : undefined;
}

function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
}

export function rgbToHex(color_object: { r: number; g: number; b: number }) {
    const { r, g, b } = color_object;
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export function rgbArrayToHex(color: Point3D) {
    const [r, g, b] = color;
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export const getFetchMock = (mockResponse) => {
    const originalFetch = global.fetch;
    global.fetch = jest.fn().mockImplementation(() => Promise.resolve(mockResponse));
    return originalFetch;
};

export default {
    hexToRgb,
};
