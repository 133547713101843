export type BoundsObject = {
    minX: number;
    minY: number;
    minZ: number;
    maxX: number;
    maxY: number;
    maxZ: number;
};

export type BoundsArray = [minX: number, maxX: number, minY: number, maxY: number, minZ: number, maxZ: number];

export type BoundsStringsObject = {
    minX: string;
    minY: string;
    minZ: string;
    maxX: string;
    maxY: string;
    maxZ: string;
};

export enum BoundsKeys {
    minX = 'minX',
    minY = 'minY',
    minZ = 'minZ',
    maxX = 'maxX',
    maxY = 'maxY',
    maxZ = 'maxZ',
}

export const minBoundsKeys = [BoundsKeys.minX, BoundsKeys.minY, BoundsKeys.minZ];
export const maxBoundsKeys = [BoundsKeys.maxX, BoundsKeys.maxY, BoundsKeys.maxZ];

export type BoundsKey = BoundsKeys;

export type CoordinatesObject = {
    x: number;
    y: number;
    z: number;
};

export enum CoordinateKeys {
    x = 'x',
    y = 'y',
    z = 'z',
}

export type Point3D = [number, number, number];

export type CoordinateKey = CoordinateKeys;

export type Color = [number, number, number];
