import {
    ElementClass,
    LineationViewState,
    LocalEllipsoidsViewState,
    StructuralViewState,
    UpdateSnapshot,
    ViewClass,
} from '@seequent/xyz';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { addNewXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { AppDispatch } from 'App/Redux/store';
import { objectMap } from 'Common/utils/ObjectUtils';
import { Point3D } from 'Common/types/geometryTypes';
import { hexToRgbArray } from 'App/MainApp/Plot/util';
import { getNextMeshColor } from 'App/MainApp/Plot/Colors_Util/colors_helper';
import { produce } from 'immer';
import { XyzTraceSnapshotWithColorAttributes, XyzTraceWithColorAttributes } from './XyzTraceWithColorAttributes';
import { InValidateAllColorValueSummaries, XyzTraceClassNames } from './XyzTrace';
import { DEFAULT_XYZ_OPACITY } from './utils/defaults';
import { XyzColorTrace, XyzColorTraceSnapshot } from './XyzColorTrace';
import {
    NormalizedRangeResponse,
    AnisotropyDisplayShapes,
} from 'App/MainApp/Plot/PlotRowTypes/PlotRowComponents/AnisotropyDisplayShape/types';
import { fetchNormalizedRange } from 'Common/Xyz/XyzTraces/XyzNetwork';
import { getLighterColor } from 'Common/utils/getInverseColor';

type DisplayShapeUpdates = {
    discRadius: number;
    lineationRadius: number;
    discHeight: number;
    lineationHeight: number;
    opacity: number;
};

export interface LvaTraceSnapshot extends XyzTraceSnapshotWithColorAttributes {
    /**
     * Snapshot is saved in Redux state. Must not duplicate XYZ state that is already stored in XYZ.
     * I mean when a plot is saved into XYZ, then basically all the currrent state of the plot is saved into XYZ.
     * Therefore there is a blurry line between what should and should not be saved in Redux through Snapshot.
     * We mainly want to store data that can help us easily extract all required information from XYZ state.
     */
    readonly className: XyzTraceClassNames.LvaTrace;

    readonly colorAttributeTraces: {
        [colorAttribute: string]: XyzColorTraceSnapshot;
    };

    readonly selectedColorAttribute: string;

    readonly hidePlotWhenColorsPending?: boolean;

    readonly userCanModifyNormalizeRanges: boolean;

    readonly normalizedRanges: boolean;

    readonly verticesUrl: string;

    readonly dipsUrl: string;

    readonly dipAzimuthsUrl: string;

    readonly polaritiesUrl: string;

    readonly pitchesUrl: string;

    readonly rangesUrl: string;

    readonly normalizedRangesUrl: string;

    readonly normalizedRangeUrl: string;

    readonly plungeAzimuthsUrl: string;

    readonly plungesUrl: string;

    readonly selectedDisplayShape: AnisotropyDisplayShapes;
}

export class LvaTrace extends XyzTraceWithColorAttributes implements LvaTraceSnapshot {
    DISC_HEIGHT_RATIO = 0.05;

    LINEATION_RADIUS_RATIO = 0.2;

    RGB_BLUE = [86, 163, 229];

    RGB_RED = [242, 91, 89];

    readonly className = XyzTraceClassNames.LvaTrace;

    readonly colorAttributeTraces: {
        [colorAttribute: string]: XyzColorTrace;
    };

    readonly selectedColorAttribute: string;

    readonly hidePlotWhenColorsPending = true;

    readonly userCanModifyNormalizeRanges: boolean;

    readonly normalizedRanges: boolean;

    readonly verticesUrl: string;

    readonly dipsUrl: string;

    readonly polaritiesUrl: string;

    readonly dipAzimuthsUrl: string;

    readonly pitchesUrl: string;

    readonly rangesUrl: string;

    readonly normalizedRangesUrl: string;

    readonly normalizedRangeUrl: string;

    readonly plungeAzimuthsUrl: string;

    readonly plungesUrl: string;

    readonly selectedDisplayShape: AnisotropyDisplayShapes;

    constructor(xyz: UseXyz, snapshot: LvaTraceSnapshot, tokenProvider: () => Promise<string>) {
        if (snapshot.className !== XyzTraceClassNames.LvaTrace) {
            throw `An incorrect spanshot is being used to create this instance of LvaTrace: ${snapshot}`;
        }
        if (!snapshot.id) {
            throw 'Id must be provided.';
        }

        super(xyz, snapshot, tokenProvider);

        this.selectedColorAttribute = snapshot.selectedColorAttribute;
        this.colorAttributeTraces = {};

        Object.keys(snapshot.colorAttributeTraces).forEach((colorAttribute) => {
            this.colorAttributeTraces[colorAttribute] = new XyzColorTrace(
                snapshot.colorAttributeTraces[colorAttribute],
                this,
                xyz
            );
        });
        this.userCanModifyNormalizeRanges = snapshot.userCanModifyNormalizeRanges;
        this.normalizedRanges = snapshot.normalizedRanges;
        this.verticesUrl = snapshot.verticesUrl;
        this.dipsUrl = snapshot.dipsUrl;
        this.dipAzimuthsUrl = snapshot.dipAzimuthsUrl;
        this.pitchesUrl = snapshot.pitchesUrl;
        this.rangesUrl = snapshot.rangesUrl;
        this.normalizedRangesUrl = snapshot.normalizedRangesUrl;
        this.selectedDisplayShape = snapshot.selectedDisplayShape || AnisotropyDisplayShapes.ELLIPSOIDS;
    }

    private createInitialXyzEntities = (opacity: number): UpdateSnapshot => {
        const elementEntitySnapshot = this.createInitialElementEntitySnapshot();
        const viewEntitySnapshot = this.createInitialViewEntitySnapshot(opacity);
        const colorEntitiesSnapshot = this.selectedColorAttributeTrace().createInitialColorDataSnapshot();
        return {
            ...colorEntitiesSnapshot,
            ...viewEntitySnapshot,
            ...elementEntitySnapshot,
        };
    };

    getElementColorKey = () => {
        switch (this.selectedDisplayShape) {
            case AnisotropyDisplayShapes.LINEATIONS:
            case AnisotropyDisplayShapes.ELLIPSOIDS:
                return 'color';
            case AnisotropyDisplayShapes.DISCS:
                return 'positiveColor';
            default:
                throw new Error('No Anisotrpohy selected.');
        }
    };

    createInitialElementEntitySnapshot = (): UpdateSnapshot => ({
        [this.elementEntityId()]: {
            verticesUrl: this.urlWithObjectHash(this.verticesUrl),
            dipsUrl: this.urlWithObjectHash(this.dipsUrl),
            dipAzimuthsUrl: this.urlWithObjectHash(this.dipAzimuthsUrl),
            pitchesUrl: this.urlWithObjectHash(this.pitchesUrl),
            rangesUrl: this.normalizedRanges
                ? this.urlWithObjectHash(this.normalizedRangesUrl)
                : this.urlWithObjectHash(this.rangesUrl),
            __class__: ElementClass.LocalEllipsoids,
        },
    });

    createInitialViewEntitySnapshot = (opacity) => {
        if (this.selectedColorAttributeTrace().useSingleColor) {
            const viewEntitySnapshot: UpdateSnapshot = {
                [this.viewEntityId(AnisotropyDisplayShapes.ELLIPSOIDS)]: {
                    // color: getNextMeshColor(),
                    element: this.elementEntityId(AnisotropyDisplayShapes.ELLIPSOIDS),
                    visible: this.isVisible(),
                    __class__: ViewClass.LocalEllipsoids,
                },
            };
            const state = this.xyz.getState();
            const oldViewEntity = state[
                this.viewEntityId(AnisotropyDisplayShapes.ELLIPSOIDS)
            ] as LocalEllipsoidsViewState;
            const newViewEntity = viewEntitySnapshot[
                this.viewEntityId(AnisotropyDisplayShapes.ELLIPSOIDS)
            ] as LocalEllipsoidsViewState;
            if (!oldViewEntity?.color) {
                // Set color if it wasn't set before
                newViewEntity.color = hexToRgbArray(getNextMeshColor());
            }
            if (opacity) {
                newViewEntity.opacity = opacity;
            } else if (!oldViewEntity?.opacity) {
                newViewEntity.opacity = DEFAULT_XYZ_OPACITY;
            }
            return {
                ...viewEntitySnapshot,
            };
        } else {
            const viewEntitySnapshot: UpdateSnapshot = {
                [this.viewEntityId(AnisotropyDisplayShapes.ELLIPSOIDS)]: {
                    element: this.elementEntityId(AnisotropyDisplayShapes.ELLIPSOIDS),
                    color_data: this.selectedColorAttributeTrace().colorDataEntityId(),
                    visible: this.isVisible(),
                    __class__: ViewClass.LocalEllipsoids,
                },
            };
            const state = this.xyz.getState();
            const oldViewEntity = state[
                this.viewEntityId(AnisotropyDisplayShapes.ELLIPSOIDS)
            ] as LocalEllipsoidsViewState;
            const newViewEntity = viewEntitySnapshot[
                this.viewEntityId(AnisotropyDisplayShapes.ELLIPSOIDS)
            ] as LocalEllipsoidsViewState;
            if (opacity) {
                newViewEntity.opacity = opacity;
            } else if (!oldViewEntity?.opacity) {
                newViewEntity.opacity = DEFAULT_XYZ_OPACITY;
            }
            return {
                ...viewEntitySnapshot,
            };
        }
    };

    snapshotPath = (): string[] => [this.id];

    getLvaAttributes = () => Object.keys(this.colorAttributeTraces);

    entityIdPrefix = (): string => this.id;

    updateSelectedAttribute = async (dispatch: AppDispatch, colorAttribute: string) => {
        const updates = {
            selectedColorAttribute: colorAttribute,
        };

        // We need access to the new LvaTraceSnapshot in order to display it as a new trace. That's why we can't use this.updateReduxState() because it doesn't return the new snapshot
        if (this.selectedColorAttribute !== colorAttribute) {
            await this.updateViewVisiblity(false); // We don't want LVA to show some weird colors while we are downloading the new color array, so we hide it in the beginning.
        }

        const newLvaTrace = new LvaTrace(this.xyz, { ...this.takeSnapshot(), ...updates }, this.tokenProvider);
        const discState = this.xyz.getState()[this.viewEntityId(AnisotropyDisplayShapes.DISCS)] as StructuralViewState;
        const lineationState = this.xyz.getState()[
            this.viewEntityId(AnisotropyDisplayShapes.LINEATIONS)
        ] as LineationViewState;
        await dispatch(
            newLvaTrace.plotAndSave({
                opacity: this.getOpacity(),
                discRadius: discState?.radius,
                lineationRadius: lineationState?.radius,
                discHeight: discState?.height,
                lineationHeight: lineationState?.height,
            })
        );

        if (this.selectedColorAttribute !== colorAttribute) {
            await this.addOrRemoveElementFromPlotView(false); // Remove this object from plotTrace only after the new trace is displayed so that the camera is not disabled.
        }
    };

    updateNormalizedRanges = async (dispatch: AppDispatch, newNormalizedRanges: boolean) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.elementEntityId()]: {
                rangesUrl: newNormalizedRanges
                    ? this.urlWithObjectHash(this.normalizedRangesUrl)
                    : this.urlWithObjectHash(this.rangesUrl),
            },
        });
        const producer = produce<LvaTrace>((oldLvaTrace) => {
            oldLvaTrace.normalizedRanges = newNormalizedRanges;
        });
        await dispatch(this.updateReduxState(producer));
    };

    updateOpacity = async (opacity: number) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId(AnisotropyDisplayShapes.DISCS)]: { opacity },
            [this.viewEntityId(AnisotropyDisplayShapes.ELLIPSOIDS)]: { opacity },
            [this.viewEntityId(AnisotropyDisplayShapes.LINEATIONS)]: { opacity },
        });
    };

    updateColor = async (color: Point3D) => {
        if (this.selectedDisplayShape === AnisotropyDisplayShapes.DISCS) {
            await this.xyz.updateVisualizationWithoutTween({
                [this.viewEntityId()]: {
                    positiveColor: color,
                    negativeColor: getLighterColor(color),
                },
            });
        } else {
            await this.xyz.updateVisualizationWithoutTween({
                [this.viewEntityId(AnisotropyDisplayShapes.ELLIPSOIDS)]: {
                    color,
                },
                [this.viewEntityId(AnisotropyDisplayShapes.LINEATIONS)]: {
                    color,
                },
            });
        }
    };

    updatePointSize = async (value: number) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId(AnisotropyDisplayShapes.DISCS)]: {
                radius: value,
            },
            [this.viewEntityId(AnisotropyDisplayShapes.LINEATIONS)]: {
                height: value,
                radius: value * this.LINEATION_RADIUS_RATIO,
            },
        });
    };

    plotAndSave = (
        updates?: DisplayShapeUpdates,
        enabled = true,
        inValidateAllColorValueSummaries = InValidateAllColorValueSummaries.No
    ) => {
        const plotAndSaveThunk = async (dispatch: AppDispatch) => {
            const colorEntitiesSnapshot = this.selectedColorAttributeTrace().createInitialColorDataSnapshot();

            const initialXyzEntities = {
                ...this.createInitialXyzEntities(updates?.opacity),
                ...(await this.createInitialAnisotropyViewObject(updates)),
                ...this.createInitialAnisotropyElementObject(),
                ...colorEntitiesSnapshot,
            };

            await this.xyz.updateVisualizationWithoutTween(initialXyzEntities);
            const lvaTraceSnapshot = this.takeSnapshot();
            dispatch(addNewXyzTrace(lvaTraceSnapshot));

            await this.setEnabled(dispatch, enabled);
            await dispatch(
                this.selectedColorAttributeTrace().downloadColorArrayUrlAndSetColorArray(
                    inValidateAllColorValueSummaries,
                    this.tokenProvider
                )
            );
            await dispatch(this.resetViewVisibilityBasedOnLatestVisibility(this.tokenProvider));
        };
        return plotAndSaveThunk;
    };

    getName = () => this.selectedColorAttribute;

    getSelectedColor = () => this.selectedColorAttributeTrace().getColor();

    showEmptyColorMapLegend = () => !this.hasColorMap();

    takeSnapshot = (): LvaTraceSnapshot => {
        const colorAttributeTraces = objectMap(this.colorAttributeTraces, (colorAttributeTrace) =>
            colorAttributeTrace.takeSnapshot()
        );

        const superSnapshot = super.takeSnapshot();

        return {
            ...superSnapshot,
            className: this.className,
            selectedColorAttribute: this.selectedColorAttribute,
            colorAttributeTraces: colorAttributeTraces,
            userCanModifyNormalizeRanges: this.userCanModifyNormalizeRanges,
            normalizedRanges: this.normalizedRanges,
            verticesUrl: this.verticesUrl,
            dipsUrl: this.dipsUrl,
            dipAzimuthsUrl: this.dipAzimuthsUrl,
            pitchesUrl: this.pitchesUrl,
            rangesUrl: this.rangesUrl,
            normalizedRangesUrl: this.normalizedRangesUrl,
            polaritiesUrl: this.polaritiesUrl,
            normalizedRangeUrl: this.normalizedRangeUrl,
            plungeAzimuthsUrl: this.plungeAzimuthsUrl,
            plungesUrl: this.plungesUrl,
            selectedDisplayShape: this.selectedDisplayShape,
        };
    };

    elementEntityId = (elementId: string = this.selectedDisplayShape) => {
        switch (elementId) {
            case AnisotropyDisplayShapes.ELLIPSOIDS:
                return `${this.entityIdPrefix()}:${ElementClass.LocalEllipsoids}`;
            case AnisotropyDisplayShapes.DISCS:
                return `${this.entityIdPrefix()}:${ElementClass.Structural}`;
            case AnisotropyDisplayShapes.LINEATIONS:
                return `${this.entityIdPrefix()}:${ElementClass.Lineation}`;
        }
    };

    viewEntityId = (shape: AnisotropyDisplayShapes = this.selectedDisplayShape): string => {
        switch (shape) {
            case AnisotropyDisplayShapes.ELLIPSOIDS:
                return `${this.selectedColorAttributeTrace().entityIdPrefix()}:${ViewClass.LocalEllipsoids}`;
            case AnisotropyDisplayShapes.DISCS:
                return `${this.selectedColorAttributeTrace().entityIdPrefix()}:${ViewClass.Structural}`;
            case AnisotropyDisplayShapes.LINEATIONS:
                return `${this.selectedColorAttributeTrace().entityIdPrefix()}:${ViewClass.Lineation}`;
            default:
                return null;
        }
    };

    viewEntity = (): LocalEllipsoidsViewState =>
        this.xyz.getEntityState(this.viewEntityId()) as LocalEllipsoidsViewState;

    updateObjectHash = (
        objectHash: string,
        enabled: boolean,
        inValidateAllColorValueSummaries: InValidateAllColorValueSummaries
    ) => {
        const updateObjectHashThunk = async (dispatch: AppDispatch) => {
            const newLvaTrace = new LvaTrace(
                this.xyz,
                {
                    ...this.takeSnapshot(),
                    objectHash: objectHash,
                },
                this.tokenProvider
            ); // We need access to the new TraceSnapshot right now. That's why we can't use this.updateReduxState() because it doesn't return the new snapshot.
            const discState = this.xyz.getState()[
                this.viewEntityId(AnisotropyDisplayShapes.DISCS)
            ] as StructuralViewState;
            const lineationState = this.xyz.getState()[
                this.viewEntityId(AnisotropyDisplayShapes.LINEATIONS)
            ] as LineationViewState;
            await dispatch(
                newLvaTrace.plotAndSave(
                    {
                        opacity: this.getOpacity(),
                        discRadius: discState?.radius,
                        lineationRadius: lineationState?.radius,
                        discHeight: discState?.height,
                        lineationHeight: lineationState?.height,
                    },
                    enabled,
                    inValidateAllColorValueSummaries
                )
            );
        };
        return updateObjectHashThunk;
    };

    private downloadNormalizedRange = async (): Promise<NormalizedRangeResponse> => {
        const headers = { Authorization: `Bearer ${await this.tokenProvider()}` };
        return fetchNormalizedRange(this.normalizedRangeUrl, headers);
    };

    createInitialAnisotropyViewObject = async (updates: DisplayShapeUpdates): Promise<UpdateSnapshot> => {
        const normalizedRange = await this.downloadNormalizedRange();

        const discViewEntity = {
            radius: updates?.discRadius || normalizedRange.default_display_range,
            height: updates?.discHeight || normalizedRange.default_display_range * this.DISC_HEIGHT_RATIO,
            positiveColor: this.RGB_BLUE,
            negativeColor: this.RGB_RED,
            __class__: ViewClass.Structural,
            element: this.elementEntityId(AnisotropyDisplayShapes.DISCS),
        };

        const lineationViewEntity = {
            radius: updates?.lineationRadius || normalizedRange.default_display_range * this.LINEATION_RADIUS_RATIO,
            height: updates?.lineationHeight || normalizedRange.default_display_range,
            color: hexToRgbArray(getNextMeshColor()),
            __class__: ViewClass.Lineation,
            element: this.elementEntityId(AnisotropyDisplayShapes.LINEATIONS),
        };

        const sharedProperties = {
            color_data: this.selectedColorAttributeTrace().colorDataEntityId() || '',
            opacity: updates?.opacity || 1,
        };

        const viewEntity: any = {
            [this.viewEntityId(AnisotropyDisplayShapes.DISCS)]: {
                ...sharedProperties,
                ...discViewEntity,
            },
            [this.viewEntityId(AnisotropyDisplayShapes.LINEATIONS)]: {
                ...sharedProperties,
                ...lineationViewEntity,
            },
        };

        return viewEntity;
    };

    createInitialAnisotropyElementObject = (): UpdateSnapshot => {
        const sharedProperties = {
            verticesUrl: this.urlWithObjectHash(this.verticesUrl),
        };
        return {
            [this.elementEntityId(AnisotropyDisplayShapes.DISCS)]: {
                ...sharedProperties,
                dipDirectionsUrl: this.urlWithObjectHash(this.dipAzimuthsUrl),
                dipsUrl: this.urlWithObjectHash(this.dipsUrl),
                polaritiesUrl: this.urlWithObjectHash(this.polaritiesUrl),
                __class__: ElementClass.Structural,
            },
            [this.elementEntityId(AnisotropyDisplayShapes.LINEATIONS)]: {
                ...sharedProperties,
                dipDirectionsUrl: this.urlWithObjectHash(this.plungeAzimuthsUrl),
                dipsUrl: this.urlWithObjectHash(this.plungesUrl),
                __class__: ElementClass.Lineation,
            },
        };
    };

    updateDisplayShape = async (dispatch, structure: AnisotropyDisplayShapes) => {
        await this.setEnabled(dispatch, false);

        switch (structure) {
            case AnisotropyDisplayShapes.ELLIPSOIDS:
                await this.addIdToPlot(this.viewEntityId(AnisotropyDisplayShapes.ELLIPSOIDS));
                dispatch(
                    addNewXyzTrace({ ...this.takeSnapshot(), selectedDisplayShape: AnisotropyDisplayShapes.ELLIPSOIDS })
                );
                break;
            case AnisotropyDisplayShapes.DISCS:
                await this.addIdToPlot(this.viewEntityId(AnisotropyDisplayShapes.DISCS));
                dispatch(
                    addNewXyzTrace({ ...this.takeSnapshot(), selectedDisplayShape: AnisotropyDisplayShapes.DISCS })
                );
                break;
            case AnisotropyDisplayShapes.LINEATIONS:
                await this.addIdToPlot(this.viewEntityId(AnisotropyDisplayShapes.LINEATIONS));
                dispatch(
                    addNewXyzTrace({ ...this.takeSnapshot(), selectedDisplayShape: AnisotropyDisplayShapes.LINEATIONS })
                );
                break;
            default:
                throw new Error('Invalid anisotrophy selected');
        }
    };
}
