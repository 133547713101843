import { SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { ProjectTreeObjectNode } from '../ProjectDataTypes/MainTreeDataTypes';
import { OBJECT_CLASS_NAMES, ProjectObject } from '../ProjectDataTypes/ProjectObjectsDataTypes';
import { Option } from 'prelude-ts';

export type AdditionalActionParams = { drillingAttribute?: string; plotName?: string };

export function getAddToViewAdditionalParams(projectObject: ProjectObject, node: ProjectTreeObjectNode) {
    const additionalParams: AdditionalActionParams = {};

    if (projectObject.object_class_name === OBJECT_CLASS_NAMES.SourceFile) {
        const drillingAttribute = projectObject.original_file_name === node.section ? null : node.section;
        additionalParams.drillingAttribute = drillingAttribute;
    } else if (projectObject.object_class_name === OBJECT_CLASS_NAMES.Domain) {
        const drillingAttribute = SectionNames.Samples === node.section ? null : node.section;
        additionalParams.drillingAttribute = drillingAttribute;
    } else if (projectObject.object_class_name === OBJECT_CLASS_NAMES.Domain_GridDefinition) {
        additionalParams.plotName = node.section;
    }

    return additionalParams;
}

export function showMainTreeRowAsDisplayed(projectObject: Option<ProjectObject>, node: ProjectTreeObjectNode) {
    if (projectObject.isNone()) {
        return false;
    }
    const projectObjectVal = projectObject.get();
    if (
        projectObjectVal.object_class_name === OBJECT_CLASS_NAMES.SourceFile &&
        projectObjectVal.original_file_name !== node.section
    ) {
        return false;
    } else if (
        projectObjectVal.object_class_name === OBJECT_CLASS_NAMES.Domain &&
        SectionNames.Samples !== node.section
    ) {
        return false;
    }

    return true;
}

export enum PublishToEvoFormats {
    structuralPoints = 'structural_points',
    lineationPoints = 'lineation_points',
    ellipsoids = 'ellipsoids',
}
