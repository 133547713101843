import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import {
    applyWebsocketModificationsToCurrentProject,
    backendProjectUrl,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import React from 'react';
import { tss } from 'tss-react/mui';
import InputAdornment from '@mui/material/InputAdornment';
import WidgetTextField from 'Common/components/Core/WidgetTextField';
import GenericDialogActions from 'Common/components/GenericDialog/GenericDialogActions';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import {
    anisotropiesTreeDataCy,
    minConcentrationThresholdDataCy,
    nameSuffixTextboxDataCy,
    zonesFromAnisotropyDialogDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import { APIResponseWithProjectUpdate } from '../../../util/ProjectDataTypes/APIResponseTypes';
import MultiUnitTextField, {
    MultiUnitTextFieldOptionsType,
    onMultiUnitTextFieldChange,
} from '../Shared/MultiUnitTextField';
import { findSectionAmongSubsections, getFirstLevelNodeIds } from '../../TreeView/treeUtils';
import { DataContext } from '../../../DataContext';
import { ZONE_SUFFIX, expandZoneNodes, getDomainGridIdsAndGridIds } from './CreateZonesFromAnisotropyService';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { useSelectableTree } from 'App/MainApp/TreeView/components/checkboxTree/useSelectableTree';
import { SectionNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { ProjectTreeObjectNode, ProjectTreeSource } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useSessionContext } from 'App/context/SessionContext';
import { anisotropySectionSelector } from 'App/Redux/utils';
import { selectProjectTreeUnderPath } from 'App/Redux/features/globalContext/projectTreeSlice';
import { produce } from 'immer';
import { OBJECT_CLASS_NAMES } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import ProjectTree from 'App/MainApp/TreeView/components/ProjectTree/ProjectTree';
import { commonStyles } from 'styles/commonStyles';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

const useStyles = tss.create(({ theme }) => ({
    treeTopParent: {
        height: 240,
        flexGrow: 1,
        overflowY: 'auto',
        marginBottom: 20,
    },
    multiUnitTextField_MenuPaper: {
        width: '18em',
    },
    defaultText: {
        ...commonStyles({ theme }).defaultText,
    },
}));

function CreateZonesFromAnisotropy(props: { handleClose: () => void }) {
    const { addMessage } = useDriverMessagesContext();
    const { classes } = useStyles();
    const xyz = useXyz();
    const { currentOrgUuid } = React.useContext(DataContext);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
    const [allowOnlyOneDataAttribute, setAllowOnlyOneDataAttribute] = React.useState(true);
    const dispatch = useAppDispatch();
    const DEFAULTS = {
        namesSuffix: '',
        namesSuffixMandatory: ZONE_SUFFIX,
        manual_minimum_concentration_threshold: '0',
        manual_minimum_concentration_threshold_type: 'Concentration',
        identify_depleted_zones: false,
        min_cluster_points: '3',
    };
    const [formResults, setFormResults] = React.useState(DEFAULTS);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const { onExpandChange } = useExpandedNodes();

    enum ZoneFromAnisotropyAnalysisType {
        CUTOFF = 'cutoff',
    }

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const [manualMinimumConcentrationThresholdOptions, setManualMinimumConcentrationThresholdOptions] =
        React.useState<MultiUnitTextFieldOptionsType>([
            {
                typeDisplayText: 'Percentile',
                typeValue: 'Percentile',
                value: '50',
            },
            {
                typeDisplayText: 'Concentration',
                typeValue: 'Concentration',
                value: '0',
            },
            {
                typeDisplayText: 'Mean',
                typeValue: 'Mean',
                value: 'N/A',
                showTextField: false,
                selected: true,
            },
        ]);

    // required for node expansion after job submit
    const requiredObjects = [OBJECT_CLASS_NAMES.Domain_GridDefinition];

    const currentProjectId = useAppSelector(selectCurrentProjectId);
    // const allObjects = useAppSelector(selectObjectClassname2Id2Obj);

    const anisotropySectionTemp = useAppSelector(
        selectProjectTreeUnderPath(anisotropySectionSelector)
    ) as ProjectTreeObjectNode;

    const anisotropySection = React.useMemo(
        () =>
            produce(anisotropySectionTemp, (oldAnisotropySection) => {
                Object.keys(oldAnisotropySection.subsections).forEach((domainId) => {
                    const domainSubtree = oldAnisotropySection.subsections[domainId];
                    if (domainSubtree.subsections) {
                        domainSubtree.subsections = Object.fromEntries(
                            Object.entries(domainSubtree.subsections).map(([id, anisotropyNode]) => {
                                return [id, { ...anisotropyNode, canDisplaySpotlight: false }];
                            })
                        );
                    }
                });
            }),
        [anisotropySectionTemp]
    );

    const anisotropySubsections = React.useMemo(() => ({ Anisotropy: anisotropySection }), [anisotropySection]);

    const {
        nodeSelectionChecker: anisotropyNodesSelectionChecker,
        selectedLeafNodes: selectedAnisotropies,
        onChange: onAnisotropiesCheckboxChange,
    } = useSelectableTree(anisotropySubsections, requiredObjects, SectionNames.Anisotropy);

    const numOfSelectedAnisotropies = selectedAnisotropies.length;

    React.useEffect(() => {
        let shouldAllowOnlyOneDataAttribute = false;
        const concentrationOptionForMinThrehsold = findSectionAmongSubsections(
            manualMinimumConcentrationThresholdOptions,
            'Concentration',
            'typeValue'
        );
        if (concentrationOptionForMinThrehsold.selected) {
            shouldAllowOnlyOneDataAttribute = true;
        }
        if (shouldAllowOnlyOneDataAttribute !== allowOnlyOneDataAttribute) {
            setAllowOnlyOneDataAttribute(shouldAllowOnlyOneDataAttribute);
        }
    }, [manualMinimumConcentrationThresholdOptions]);

    const onSubmit = () => {
        const formData = new FormData();

        if (numOfSelectedAnisotropies === 0) {
            addMessage({
                message: 'You must select at least one Anisotropy!',
                type: NotificationType.ERROR,
            });
            return;
        }

        if (allowOnlyOneDataAttribute && numOfSelectedAnisotropies > 1) {
            addMessage({
                message:
                    'At most one anisotropy can be selected because you are applying a concentration-based threshold!',
                type: NotificationType.ERROR,
            });
            return;
        }

        const anisotropyEstimationIDs = selectedAnisotropies
            .filter((node) => node.className === OBJECT_CLASS_NAMES.AnisotropyEstimation)
            .map((node) => node.id);
        const anisotropyGridIDs = selectedAnisotropies
            .filter((node) => node.className === OBJECT_CLASS_NAMES.AnisotropyGrid)
            .map((node) => node.id);
        const anisotropyGlobalIDs = selectedAnisotropies
            .filter((node) => node.className === OBJECT_CLASS_NAMES.AnisotropyGlobal)
            .map((node) => node.id);

        formData.append('anisotropyEstimationIDs', JSON.stringify(anisotropyEstimationIDs));
        formData.append('anisotropyGridIDs', JSON.stringify(anisotropyGridIDs));
        formData.append('anisotropyGlobalIDs', JSON.stringify(anisotropyGlobalIDs));

        const selectedOptionForMinThrehsold = findSectionAmongSubsections(
            manualMinimumConcentrationThresholdOptions,
            true,
            'selected'
        );

        if (selectedOptionForMinThrehsold.error) {
            addMessage({
                message: 'Error in one of the inputs!',
                type: NotificationType.ERROR,
            });
            return;
        }

        formData.append('namesSuffix', formResults.namesSuffix);
        formData.append('namesSuffixMandatory', formResults.namesSuffixMandatory);
        formData.append('manual_minimum_concentration_threshold', selectedOptionForMinThrehsold.value);
        formData.append(
            'manual_minimum_concentration_threshold_type',
            JSON.stringify(selectedOptionForMinThrehsold.typeValue)
        );
        formData.append('identify_depleted_zones', JSON.stringify(formResults.identify_depleted_zones));
        formData.append('min_cluster_points', formResults.min_cluster_points);
        formData.append('analysis_type', ZoneFromAnisotropyAnalysisType.CUTOFF);

        const { gridIds, domainGridDefinitionIds } = getDomainGridIdsAndGridIds(selectedAnisotropies);

        void axiosDriverFlask
            .post<APIResponseWithProjectUpdate>(
                `${backendProjectUrl(currentOrgUuid, currentWorkspaceId, currentProjectId)}/zoneFromAnisotropyRequest`,
                formData,
                {
                    withCredentials: true,
                    responseType: 'json',
                }
            )
            .then((projectUpdateJson) => {
                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'submit cutoff zone',
                        tokenProvider
                    )
                );

                expandZoneNodes(onExpandChange, gridIds, domainGridDefinitionIds);

                props.handleClose();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
        setIsSubmitting(true);
    };

    React.useEffect(() => {
        const anisotropyNodeIds = getFirstLevelNodeIds(anisotropySubsections);
        onExpandChange(anisotropyNodeIds, true, ProjectTreeSource.ZONES_FROM_ANISOTROPY, true);
    }, []);

    return (
        <GenericDialogShell
            aria-labelledby="draggable-dialog-title"
            dataCy={zonesFromAnisotropyDialogDataCy}
            title="Create Zones from Anisotropy"
            maxWidth="sm"
            handleClose={props.handleClose}
        >
            <ProjectTree
                checkboxTree
                dataCy={anisotropiesTreeDataCy}
                className={classes.treeTopParent}
                tree={anisotropySubsections}
                onChange={onAnisotropiesCheckboxChange}
                nodeSelectionChecker={anisotropyNodesSelectionChecker}
                treeSource={ProjectTreeSource.ZONES_FROM_ANISOTROPY}
            />
            <Grid container spacing={1} sx={{ paddingTop: 1 }}>
                <Grid container item spacing={3}>
                    <Grid className={classes.defaultText} item xs={7}>
                        Name suffix:
                    </Grid>
                    <Grid item xs={5}>
                        <WidgetTextField
                            id="outlined-basic"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">{formResults.namesSuffixMandatory}</InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setFormResults({
                                    ...formResults,
                                    ...{ namesSuffix: e.target.value },
                                });
                            }}
                            size="small"
                            value={formResults.namesSuffix}
                            data-cy={nameSuffixTextboxDataCy}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={7} container alignItems="center">
                        <Grid className={classes.defaultText} item>
                            Threshold
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <MultiUnitTextField
                            dataCy={minConcentrationThresholdDataCy}
                            classes={{
                                menuPaper: classes.multiUnitTextField_MenuPaper,
                            }}
                            optionInfos={manualMinimumConcentrationThresholdOptions}
                            onChange={(optionInfoIndex, newValue) =>
                                onMultiUnitTextFieldChange(
                                    setManualMinimumConcentrationThresholdOptions,
                                    optionInfoIndex,
                                    newValue
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <GenericDialogActions
                onCancel={props.handleClose}
                onSubmit={onSubmit}
                disabled={isSubmitting || numOfSelectedAnisotropies === 0}
                showSpinner={isSubmitting}
                tooltipText={
                    isSubmitting
                        ? 'Submitting...'
                        : numOfSelectedAnisotropies === 0
                          ? 'You need to select at least one anisotropy.'
                          : ''
                }
            />
        </GenericDialogShell>
    );
}

export default CreateZonesFromAnisotropy;
