import React from 'react';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import PointEstimationDialog from './PointEstimationDialog';
import { PointEstimationMethods } from './pointEstimationsService';

function NewOrdinaryKriging(props: { handleClose: () => void }) {
    return (
        <GenericDialogShell
            title="New Ordinary Kriging"
            dataCy="ordinary-kriging-dialog"
            maxWidth="md"
            handleClose={props.handleClose}
        >
            <PointEstimationDialog
                pointEstimationMethod={PointEstimationMethods.OrdinaryKriging}
                namesSuffixPrefix="_ok"
                handleClose={props.handleClose}
            />
        </GenericDialogShell>
    );
}

export default NewOrdinaryKriging;
