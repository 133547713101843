import MergePointEstimations from 'App/MainApp/Dialogs/PointEstimations/MergePointEstimations/MergePointEstimations';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { selectActivePointEstimations } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { InterpolationSectionMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { mergedInterpolationDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function CreateMergedInterpolationsButton(props: GeneralMenuItemProps) {
    const activePointEstimations = useAppSelector(selectActivePointEstimations);
    const disabled = Object.values(activePointEstimations).length < 2;
    const tooltipText = disabled && 'Requires at least 2 interpolations from anisotropy grid or anisoptropy global';

    const hideInterpolations = useFlags()?.hideInterpolations;

    if (hideInterpolations) {
        return null;
    }

    return (
        <DialogOpenerMenuItem
            name={InterpolationSectionMenuItems.NewMergedInterpolation}
            key={InterpolationSectionMenuItems.NewMergedInterpolation}
            dataCy={mergedInterpolationDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            tooltipText={tooltipText}
            dialogComponent={MergePointEstimations}
        />
    );
}
