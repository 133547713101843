import { GooseKnownSchemas } from 'App/MainApp/TreeView/treeData/treeConsts';
import { AxiosDriverFlaskInstance } from '../../../../util/axiosErrorHandlers';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { GooseObject } from 'Common/api/WorkspaceApi';

export type GooseFile = {
    id: ObjectIDType;
    name: string;
    createdBy: string;
    createdAt: string;
};

export type GooseObjects = GooseObject[];

const gooseObjectName = {
    [GooseKnownSchemas.DownholeIntervals]: 'Downhole Intervals',
    [GooseKnownSchemas.TriangleMesh]: 'Triangle Mesh',
};

export async function getAllGooseObjects(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentOrgUuid: string,
    workspaceId: string,
    workspaceName: string,
    objectType: GooseKnownSchemas
): Promise<GooseFile[]> {
    const params = {
        gooseObjectType: objectType,
    };

    const response = await axiosDriverFlask.get<GooseObjects>(
        `/orgs/${currentOrgUuid}/workspaces/${workspaceId}/goose`,
        {
            params,
            responseType: 'json',
            withCredentials: true,
        }
    );

    const files = Object.values(response.data).map((file) => {
        const name = getGooseName(file, workspaceName);

        return {
            id: file.object_id,
            name,
            createdBy: file.created_by.name,
            createdAt: file.created_at,
            type: gooseObjectName[objectType],
        };
    });

    return files;
}

export async function getGooseObjectColumns(
    axiosDriverFlask: AxiosDriverFlaskInstance,
    currentOrgUuid: string,
    workspaceId: string,
    gooseObjectId: string
) {
    const params = {
        fetchColumns: true,
        objectType: GooseKnownSchemas.DownholeIntervals,
    };

    return axiosDriverFlask.get(`/orgs/${currentOrgUuid}/workspaces/${workspaceId}/goose/${gooseObjectId}`, {
        params,
        responseType: 'json',
        withCredentials: true,
    });
}

export const getGooseName = (gooseObject: GooseObject, workspaceName: string) => {
    const gooseName = `${workspaceName}/${gooseObject.path}/${gooseObject.name}`;
    return gooseName.replace(/\/\//g, '/');
};

export const getGooseObjectType = (objectType: GooseKnownSchemas) => {
    let isDrilling = false;
    let isTriangleMesh = false;

    if (objectType === GooseKnownSchemas.DownholeIntervals) {
        isDrilling = true;
    } else if (objectType === GooseKnownSchemas.TriangleMesh) {
        isTriangleMesh = true;
    }

    return { isDrilling, isTriangleMesh };
};
