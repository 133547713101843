import SecondaryItemMenu from 'App/MainApp/TreeView/menu/SecondaryItemMenu';
import { ObjectMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import DownloadButton from 'App/MainApp/TreeView/menu/menuItems/generic/publish/DownloadButton';
import { PublishToEvoButton } from 'App/MainApp/TreeView/menu/menuItems/generic/publish/PublishToEvoButton';
import { GeneralMenuItems, SecondaryMenuItems } from 'App/MainApp/TreeView/treeData/treeConsts';
import { publishMenuItemDataCy, publishToLocalMenuItemDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { OBJECT_CLASS_NAMES } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';

export function PublishButton(props: ObjectMenuItemProps) {
    const isAnisotropy =
        props.nodeData.className === OBJECT_CLASS_NAMES.AnisotropyEstimation ||
        props.nodeData.className === OBJECT_CLASS_NAMES.AnisotropyGrid ||
        props.nodeData.className === OBJECT_CLASS_NAMES.AnisotropyGlobal;

    const itemName = isAnisotropy ? GeneralMenuItems.publishToLocal : GeneralMenuItems.publish;
    const itemDataCy = isAnisotropy ? publishToLocalMenuItemDataCy : publishMenuItemDataCy;

    return (
        <>
            {isAnisotropy ? (
                <div>
                    <DownloadButton
                        itemName={GeneralMenuItems.publishToLocal}
                        nodeData={props.nodeData}
                        handleMenuClose={props.handleMenuClose}
                    />
                    <PublishToEvoButton nodeData={props.nodeData} handleMenuClose={props.handleMenuClose} />
                </div>
            ) : (
                <SecondaryItemMenu itemName={itemName} dataCy={itemDataCy}>
                    <div>
                        <DownloadButton
                            itemName={SecondaryMenuItems.ToLocal}
                            nodeData={props.nodeData}
                            handleMenuClose={props.handleMenuClose}
                        />
                        <PublishToEvoButton nodeData={props.nodeData} handleMenuClose={props.handleMenuClose} />
                    </div>
                </SecondaryItemMenu>
            )}
        </>
    );
}
