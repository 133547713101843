import { sendSoftDeleteRequest } from 'App/MainApp/services/objectDeleteService';
import { JobHistorySnapshot } from './JobHistory';
import {
    ObjectIDType,
    ObjectStatusTypes,
    OBJECT_CLASS_NAME,
    CreationReason,
    ObjectClassName2Id2Obj,
} from './ProjectObjectsDataTypes';
import { AxiosDriverFlaskInstance } from '../axiosErrorHandlers';
import type { Dependants } from './utilClasses/Dependants';
import type ProjectObjects from './utilClasses/ProjectObjects';
import { jobUrl2BackendUrl } from 'Common/UrlUtils';
import { isInactiveObject } from './projectObjectUtils';

type ObjectResourceUsage = {
    'cgroups::memory.max_usage_in_bytes': number;
    'cgroups::cpuacct_usage': {
        cpu_usage_user: number[];
        cpu_usage_sys: number[];
        cpu_usage_timestamps: number[];
    };
};

type WorkerTierInfo = {
    tierId2info: {
        [tierId: number]: object;
    };
};

export interface BackendJsonifiedProjectObject {
    // These objects are returned from the as_dict() function in the backend.
    // The backend tries to always use the as_dict() function when jsonifying a database object.
    id: ObjectIDType; // id can be a string for fake objects created in the UI that are supposed to mimic the backend objects.
    worker_tier_info?: WorkerTierInfo;
    status: ObjectStatusTypes;
    resource_usage: ObjectResourceUsage;
    hidden?: boolean;
    progress_status?: number;
    error_reason?: string;
    error_message_for_users?: string;
    created_at?: string;
    updated_at?: string;
    finish_time?: string;
    object_class_name: OBJECT_CLASS_NAME;
    previousVersionId?: ObjectIDType;
    projectId?: string;
    running_worker_pod_environment_name?: string;
    running_worker_pod_name?: string;
    running_worker_pod_uid?: string;
    finished_worker_pod_name?: string;
    start_time?: string;
    versionsHistory?: JobHistorySnapshot;
    creationReason?: CreationReason;
    url?: string;
    __jsonified_at?: Date;
    $deletedObject?: boolean;
}

let ProjectObjectsClass: typeof ProjectObjects;

export class BaseProjectObject implements BackendJsonifiedProjectObject {
    id: ObjectIDType; // id can be a string for fake objects created in the UI that are supposed to mimic the backend objects.

    workspace_id: ObjectIDType;

    worker_tier_info?: WorkerTierInfo;

    status: ObjectStatusTypes;

    resource_usage: ObjectResourceUsage;

    hidden?: boolean;

    progress_status?: number;

    error_reason?: string;

    created_at?: string;

    updated_at?: string;

    finish_time?: string;

    object_class_name: OBJECT_CLASS_NAME;

    previousVersionId?: ObjectIDType;

    projectId?: string;

    running_worker_pod_environment_name?: string;

    running_worker_pod_name?: string;

    running_worker_pod_uid?: string;

    finished_worker_pod_name?: string;

    start_time?: string;

    versionsHistory?: JobHistorySnapshot;

    creationReason?: CreationReason;

    url?: string;

    __jsonified_at?: Date;

    $deletedObject?: boolean;

    axiosDriverFlask: AxiosDriverFlaskInstance;

    allObjects: ObjectClassName2Id2Obj;

    projectObjects: ProjectObjects;

    name: string;

    constructor(
        object: BackendJsonifiedProjectObject,
        axiosDriverFlask: AxiosDriverFlaskInstance,
        allObjects: ObjectClassName2Id2Obj
    ) {
        Object.assign(this, object);
        this.axiosDriverFlask = axiosDriverFlask;
        this.allObjects = allObjects;

        if (ProjectObjectsClass === undefined) {
            // eslint-disable-next-line global-require
            ProjectObjectsClass = require('./utilClasses/ProjectObjects').default; // This is to stop the circular dependency.
        }
        this.projectObjects = new ProjectObjectsClass(axiosDriverFlask, allObjects);
    }

    getUniqueId = () => `${this.object_class_name}_${this.id}`;

    delete = async () => sendSoftDeleteRequest(this.url, this.axiosDriverFlask);

    getName = () => {
        if (this.name) {
            return this.name;
        } else {
            throw 'getName not implemented';
        }
    };

    getDependants: () => Dependants;

    equals = (other: BaseProjectObject) => this.id === other.id && this.object_class_name === other.object_class_name;

    getFullBackendUrl = (currentOrgUuid: string) => jobUrl2BackendUrl(currentOrgUuid, this.url);

    /** some objects like deleted ones or previous versions won't be in Redux */
    isInStore = () => {
        return !!this.allObjects[this.object_class_name][this.id];
    };

    isInactive = () => {
        return isInactiveObject(this);
    };
}
