/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { Manager as SocketIOClientManager } from 'socket.io-client';
import { DisplaySettingsType } from './Settings/DisplaySettings';
import { SocketIORequestProgressSocket } from './MainApp/SocketIO/RequestProgressSocketConnection/RequestProgressSocketConnection';
import { SocketIOCloneProjectSocket } from './MainApp/SocketIO/CloneProjectSocketConnection/CloneProjectSocketConnection';
import { XyzInstanceContextValue } from './MainApp/Visualization/context/XyzInstanceContextValue';

export type UploadInputProps = {
    accept: string; // file types
    onChange: (target: HTMLInputElement) => void;
    allowMultipleFiles: boolean;
};

export const DataContext = React.createContext<{
    currentOrgUuid: string;
    displaySettings: DisplaySettingsType;
    setDisplaySettings: React.Dispatch<React.SetStateAction<DisplaySettingsType>>;
    darkMode: boolean;
    setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
    socketIOClientManager: SocketIOClientManager;
    setSocketIOClientManager: React.Dispatch<React.SetStateAction<SocketIOClientManager>>;
    socketIORequestProgressSocket: SocketIORequestProgressSocket;
    setSocketIORequestProgressSocket: React.Dispatch<React.SetStateAction<SocketIORequestProgressSocket>>;
    socketIOCloneProjectSocket: SocketIOCloneProjectSocket;
    setSocketIOCloneProjectSocket: React.Dispatch<React.SetStateAction<SocketIOCloneProjectSocket>>;
    openURL: (url: string, target?: string) => void;
    uploadFiles: (uploadInputProps: UploadInputProps) => void;
    loggedInButNoAccessToOrg: boolean;
    xyzInstanceContextValue: XyzInstanceContextValue;
    setXyzInstanceContextValue: React.Dispatch<React.SetStateAction<XyzInstanceContextValue>>;
    menuItemClass: Record<string, string>;
}>(null);
