import DefineGridDialog from 'App/MainApp/Dialogs/DefineGrid/DefineGrid';
import DialogOpenerMenuItem from 'App/MainApp/TreeView/menu/DialogOpenerMenuItem';
import { GeneralMenuItemProps } from 'App/MainApp/TreeView/menu/menuItems/menuItemsTypes';
import { selectSuccessfulActiveDrills } from 'App/Redux/features/globalContext/currentProjectSlice';
import { useAppSelector } from 'App/Redux/hooks';
import { GridsSectionMenuItemNames } from 'App/MainApp/TreeView/treeData/treeConsts';
import { defineGridDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function CreateGridDialogButton(props: GeneralMenuItemProps) {
    const successfulActiveDrills = useAppSelector(selectSuccessfulActiveDrills);

    const disabled = !Object.values(successfulActiveDrills).length;
    const tooltipText = disabled ? 'Requires drilling data' : '';

    const hideInterpolations = useFlags()?.hideInterpolations;

    if (hideInterpolations) {
        return null;
    }

    return (
        <DialogOpenerMenuItem<React.ComponentProps<typeof DefineGridDialog>>
            name={GridsSectionMenuItemNames.CreateGrid}
            dataCy={defineGridDataCy}
            handleMenuClose={props.handleMenuClose}
            disabled={disabled}
            tooltipText={tooltipText}
            dialogComponent={DefineGridDialog}
        />
    );
}
