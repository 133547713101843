import { AnisotropyDisplayShapes } from 'App/MainApp/Plot/PlotRowTypes/PlotRowComponents/AnisotropyDisplayShape/types';
import { domainForAnisotropy } from 'App/MainApp/TreeView/convertProjectDataToTree';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { selectXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';
import { AppDispatch, AppStoreStateGetter } from 'App/Redux/store';
import { AnisotropyEstimationType } from 'App/util/ProjectDataTypes/AnisotropyEstimation';
import { AnisotropyGridType } from 'App/util/ProjectDataTypes/AnisotropyGrid';
import { ProjectObjectIdentifier } from 'App/util/ProjectDataTypes/ProjectObjectIdentifier';
import { OBJECT_CLASS_NAMES, ObjectClassName2Id2Obj } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { LvaTrace, LvaTraceSnapshot } from 'Common/Xyz/XyzTraces/LvaTrace';
import { InValidateAllColorValueSummaries, XyzTraceClassNames, XyzTraceId } from 'Common/Xyz/XyzTraces/XyzTrace';

const COLOR_ATTRIBUTE_ID2DISPLAY_NAME = {
    drillhole_support: 'data support',
    misclassification_score: 'confidence',
    enabled: 'enabled',
    anisotropyClustering: 'clusters',
    sample_values: 'sample values',
    ellipticity: 'ellipticity',
    linearity: 'linearity',
};

function getplotName(
    anisotropyObject: AnisotropyEstimationType | AnisotropyGridType,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    colorAttribute?: string
) {
    const domain = domainForAnisotropy(anisotropyObject, objectClassName2id2obj);
    const basicName = `${anisotropyObject.name} D[${domain?.name}]`;
    if (colorAttribute) {
        return `${basicName} - ${colorAttribute}`;
    }
    return basicName;
}

function createInitialLvaSnapshot(
    xyzTraceId: string,
    anisotropyObject: AnisotropyEstimationType | AnisotropyGridType,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    anisotropyClustering: boolean
): LvaTraceSnapshot {
    const lvaAttributeTraces = {} as LvaTraceSnapshot['colorAttributeTraces'];

    const basePlotName = getplotName(anisotropyObject, objectClassName2id2obj);
    lvaAttributeTraces[basePlotName] = {
        colorAttributeName: basePlotName,
        useSingleColor: true,
        colorValueSummariesValid: false,
        colorValueSummaries: undefined,
        colorArrayUrl: undefined,
    };

    const projectObjectIdentifier = new ProjectObjectIdentifier({
        objectClassName: anisotropyObject.object_class_name,
        id: anisotropyObject.id,
    });
    const jobUrl = projectObjectIdentifier.getObjectUrl(currentOrgUuid, objectClassName2id2obj);

    Object.keys(COLOR_ATTRIBUTE_ID2DISPLAY_NAME).forEach((colorAttributeId) => {
        if (colorAttributeId === 'anisotropyClustering') {
            if (anisotropyObject.object_class_name === OBJECT_CLASS_NAMES.AnisotropyGrid) {
                return;
            }
            if (
                anisotropyObject.object_class_name === OBJECT_CLASS_NAMES.AnisotropyEstimation &&
                (!anisotropyObject.parameters?.featureFlags?.anisotropyClustering || !anisotropyClustering)
            ) {
                return;
            }
        }
        if (colorAttributeId === 'sample_values') {
            if (anisotropyObject.object_class_name === OBJECT_CLASS_NAMES.AnisotropyGrid) {
                return;
            }
        }

        const colorAttributeName = COLOR_ATTRIBUTE_ID2DISPLAY_NAME[colorAttributeId];
        const plotName = getplotName(anisotropyObject, objectClassName2id2obj, colorAttributeName);
        lvaAttributeTraces[plotName] = {
            colorAttributeName: plotName,
            useSingleColor: false,
            colorValueSummariesValid: false,
            colorValueSummaries: undefined,
            colorArrayUrl: `${jobUrl}?download_bytes_array=${colorAttributeId}`,
        };
    });
    return {
        id: xyzTraceId,
        className: XyzTraceClassNames.LvaTrace,
        colorAttributeTraces: lvaAttributeTraces,
        enabled: true,
        visible: true,
        projectObjectIdentifier: {
            objectClassName: anisotropyObject.object_class_name,
            id: anisotropyObject.id,
        },
        selectedColorAttribute: basePlotName,
        userCanModifyNormalizeRanges: true,
        normalizedRanges: true,
        verticesUrl: `${jobUrl}?download_bytes_array=centers`,
        dipsUrl: `${jobUrl}?download_bytes_array=dips`,
        dipAzimuthsUrl: `${jobUrl}?download_bytes_array=dipAzimuths`,
        pitchesUrl: `${jobUrl}?download_bytes_array=pitches`,
        rangesUrl: `${jobUrl}?download_bytes_array=ranges`,
        normalizedRangesUrl: `${jobUrl}?download_bytes_array=normalizedRanges`,
        polaritiesUrl: `${jobUrl}?download_bytes_array=polarities`,
        normalizedRangeUrl: `${jobUrl}?default_display_range=true`,
        plungeAzimuthsUrl: `${jobUrl}?download_bytes_array=plungeAzimuths`,
        plungesUrl: `${jobUrl}?download_bytes_array=plunges`,
        objectHash: anisotropyObject.updated_at,
        selectedDisplayShape: AnisotropyDisplayShapes.ELLIPSOIDS,
    };
}

async function createInitialXyzLvaTrace(
    xyzTraceId: string,
    dispatch: AppDispatch,
    anisotropyObject: AnisotropyEstimationType | AnisotropyGridType,
    xyz: UseXyz,
    objectClassName2id2obj: ObjectClassName2Id2Obj,
    currentOrgUuid: string,
    anisotropyClustering: boolean,
    tokenProvider: () => Promise<string>
) {
    const initialLvaSnapshot = createInitialLvaSnapshot(
        xyzTraceId,
        anisotropyObject,
        objectClassName2id2obj,
        currentOrgUuid,
        anisotropyClustering
    );

    const lvaTrace = new LvaTrace(xyz, initialLvaSnapshot, tokenProvider);
    await dispatch(lvaTrace.plotAndSave());
}

export function plotAnisotropyEstimationOrGridObject(
    anisotropyObject: AnisotropyEstimationType | AnisotropyGridType,
    xyzTraceId: XyzTraceId,
    xyz: UseXyz,
    currentOrgUuid: string,
    anisotropyClustering: boolean,
    tokenProvider: () => Promise<string>
) {
    return async function plotLvaObjectThunk(dispatch: AppDispatch, getState: AppStoreStateGetter) {
        const state = getState();
        const xyzTraceSnapshot = selectXyzTrace(state, xyzTraceId);
        if (xyzTraceSnapshot) {
            const lvaTrace = new LvaTrace(xyz, xyzTraceSnapshot as LvaTraceSnapshot, tokenProvider);
            await lvaTrace.incrementObjectHash(dispatch, true, InValidateAllColorValueSummaries.No); // if the object was already enabled, we will increment the objectHash to force redownload of the data.
        } else {
            const objectClassName2id2obj = selectObjectClassname2Id2Obj(state);
            await createInitialXyzLvaTrace(
                xyzTraceId,
                dispatch,
                anisotropyObject,
                xyz,
                objectClassName2id2obj,
                currentOrgUuid,
                anisotropyClustering,
                tokenProvider
            );
        }
    };
}
