// import * as moment from 'moment';

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const DAY_BEFORE_GREGORIAN_START = '0000-12-31';

export function getNextDayStartDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
}

export function getNextMonthStartDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1);
}

export function datesDiffInDays(dateStart: Date, dateEnd: Date): number {
    const utc1 = Date.UTC(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate());
    const utc2 = Date.UTC(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate());

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

// export function datesDiffInMonth(dateStart: Date, dateEnd: Date): number {
//     return Math.floor(moment(dateEnd).diff(moment(dateStart), 'months', true));
// }

export function isWeekend(date: Date): boolean {
    const day = date.getDay();
    return day === 0 || day === 6;
}

export function getDateWithoutTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function addTimezone(date: Date): Date {
    const getUTC = date.getTime();
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(getUTC + offset);
}

export function getNormalizedDateTime(date: Date): number {
    return getDateWithoutTime(addTimezone(date)).getTime();
}

export function getDailyTimeseriesDatesList(dateStart: Date, dateEnd: Date): Date[] {
    const datesList: Date[] = [];

    let date = dateStart;
    while (date <= dateEnd) {
        datesList.push(date);
        date = getNextDayStartDate(date);
    }

    return datesList;
}

export function getMonthlyTimeseriesDatesList(dateStart: Date, dateEnd: Date): Date[] {
    const datesList: Date[] = [];

    let date = new Date(dateStart.getFullYear(), dateStart.getMonth());
    while (date <= dateEnd) {
        datesList.push(date);
        date = getNextMonthStartDate(date);
    }

    return datesList;
}

export function isDateWithinDay(targetDate: Date, dayDate: Date): boolean {
    const dayStart = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate());
    const dayEnd = getNextDayStartDate(dayStart);
    return targetDate >= dayStart && targetDate < dayEnd;
}

export function isDateWithinMonth(targetDate: Date, monthDate: Date): boolean {
    const monthStart = new Date(monthDate.getFullYear(), monthDate.getMonth());
    const monthEnd = getNextMonthStartDate(monthStart);
    return targetDate >= monthStart && targetDate < monthEnd;
}

export function isoStringFromOrdinal(value: number) {
    const aDayBeforeGregorian = new Date(DAY_BEFORE_GREGORIAN_START);
    const ordinalTime = aDayBeforeGregorian.getTime();
    const date = new Date(value * MS_PER_DAY + ordinalTime).toISOString();
    return date;
}

export function dateFromOrdinal(value: number) {
    return isoStringFromOrdinal(value).split('T')[0];
}

export function dateAndTimeFromOrdinal(value: number) {
    const dateArray = isoStringFromOrdinal(value).split('T');
    const time = dateArray[1].split('.')[0];
    return `${dateArray[0]} ${time}`;
}

export function toOrdinal(date: Date) {
    const zeroDate = new Date(DAY_BEFORE_GREGORIAN_START);
    const zeroDays = Math.abs(zeroDate.getTime() / MS_PER_DAY);
    return zeroDays + date.getTime() / MS_PER_DAY;
}

// export function diffInDaysFromOrdinal(value: number): number {
//     const dateNow = moment();
//     const date = moment(isoStringFromOrdinal(value));
//     return dateNow.diff(date, 'days');
// }

export function secToMs(seconds: number) {
    return seconds * 1000;
}

export function msToSec(milliseconds: number) {
    return milliseconds / 1000;
}

export function minToSec(minutes: number) {
    return minutes * 60;
}

export function secToMin(seconds: number) {
    return seconds / 60;
}

export function minToMs(minutes: number) {
    return secToMs(minToSec(minutes));
}

export function msToMin(milliseconds: number) {
    return secToMin(msToSec(milliseconds));
}
