import React from 'react';
import { useAppDispatch, useAppSelector } from 'App/Redux/hooks';
import {
    applyWebsocketModificationsToCurrentProject,
    backendProjectUrl,
    selectCurrentProjectId,
    selectCurrentWorkspaceId,
} from 'App/Redux/features/globalContext/currentProjectSlice';
import LinearProgress from '@mui/material/LinearProgress';
import { AxiosProgressEvent } from 'axios';
import { useXyz } from 'App/MainApp/Visualization/context/hooks/useXyz';
import { DataContext } from '../../../../DataContext';
import { MeshFileData, expandMeshNodes, uploadMeshFile } from './uploadMeshService';
import GenericDialogShell from 'Common/components/GenericDialog/GenericDialogShell';
import { uploadMeshDialogDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import { OBJECT_CLASS_NAMES, ObjectIDType, ObjectStatusTypes } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import useExpandedNodes from 'App/MainApp/TreeView/components/ProjectTree/useExpandedNodes';
import { MeshTypes } from 'App/util/ProjectDataTypes/MeshFile';
import { useSessionContext } from 'App/context/SessionContext';
import {
    ProjectObjectActionType,
    setProjectObjectUploadingOrPublishing,
} from 'App/Redux/features/globalContext/projectObjectSlice';
import { showWarnings } from '../../Shared/warningsUtil';
import {
    handleUploadOrPublishFileProgress,
    resetUploadOrPublishFileProgress,
} from '../../Shared/uploadOrPublishFileUtil';
import { useDriverMessagesContext } from 'App/Messages/DriverMessages';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeTokenProvider } from 'App/MainApp/Visualization/Plot/initializeVisualization';

export default function UploadMeshDialog(props: {
    handleClose: () => void;
    meshType: MeshTypes;
    gooseObjectId?: ObjectIDType;
    gooseObjectName?: string;
    files?: File[];
}) {
    const xyz = useXyz();
    const { meshType, files, gooseObjectId } = props;
    const [uploading, setUploading] = React.useState<{
        file_name: string;
    }>(null);
    const [progress, setProgress] = React.useState<boolean | number>(false);
    const uploadAbortControllerRef = React.useRef<AbortController>(null);
    const gridDefinitionIdRef = React.useRef<string>(null);
    const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);

    const currentProjectId = useAppSelector(selectCurrentProjectId);
    const { currentOrgUuid } = React.useContext(DataContext);
    const { addMessage } = useDriverMessagesContext();

    const { axiosDriverFlask, setLoginSessionTerminated } = useSessionContext();
    const tokenProvider = makeTokenProvider(axiosDriverFlask, setLoginSessionTerminated);

    const { onExpandChange } = useExpandedNodes();

    const runJobOnLambda = useFlags()?.runJobOnLambda;

    const dispatch = useAppDispatch();

    const url = `${backendProjectUrl(currentOrgUuid, currentWorkspaceId, currentProjectId)}/meshfiles`;

    const isGoose = !!gooseObjectId;

    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total !== 0) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
        } else {
            setProgress(false);
        }
    };

    React.useEffect(() => {
        const onFileChange = async () => {
            if (files.length > 0) {
                const fileName = files[0].name;

                const abortController = new AbortController();

                uploadAbortControllerRef.current = abortController;

                const data = {
                    files: files,
                    lastMeshType: meshType,
                    gridDefinitionId: gridDefinitionIdRef.current,
                    abortController: abortController,
                    isGoose: isGoose,
                };

                uploadMeshFileObject(data, onUploadProgress);

                setUploading({
                    file_name: fileName,
                });
            }
        };

        if (isGoose) {
            const abortController = new AbortController();

            uploadAbortControllerRef.current = abortController;

            const data = {
                lastMeshType: meshType,
                gridDefinitionId: gridDefinitionIdRef.current,
                abortController: abortController,
                isGoose: isGoose,
                gooseObjectId: gooseObjectId,
            };

            uploadMeshFileObject(data, onUploadProgress);
        } else {
            void onFileChange();
        }
    }, [files, gooseObjectId]);

    const uploadMeshFileObject = (
        data: MeshFileData,
        onUploadProgress: (progressEvent: AxiosProgressEvent) => void
    ) => {
        const projectUploadObject: { [id: string]: ProjectObjectActionType } = {};
        let objectId: string = '';

        if (data.isGoose) {
            const gooseName = props.gooseObjectName.split('/').at(-1);
            objectId = data.gooseObjectId;
            const updatedProjectUploadObject = handleUploadOrPublishFileProgress(
                objectId,
                OBJECT_CLASS_NAMES.MeshFile,
                ObjectStatusTypes.UPLOADING,
                30,
                gooseName,
                projectUploadObject,
                true
            );
            dispatch(setProjectObjectUploadingOrPublishing(updatedProjectUploadObject));
        }

        uploadMeshFile(axiosDriverFlask, data, url, onUploadProgress, runJobOnLambda)
            .then((projectUpdateJson) => {
                if (uploadAbortControllerRef.current !== data.abortController) {
                    // This means that a possibly old and/or canceled upload has finished.
                    return;
                }

                showWarnings(addMessage, projectUpdateJson.data);

                dispatch(
                    applyWebsocketModificationsToCurrentProject(
                        xyz,
                        axiosDriverFlask,
                        projectUpdateJson.data,
                        currentProjectId,
                        'upload mesh',
                        tokenProvider
                    )
                );

                expandMeshNodes(onExpandChange, meshType);

                setUploading(null);
                setProgress(0);

                if (data.isGoose) {
                    const updatedProjectUploadObject = resetUploadOrPublishFileProgress(
                        objectId,
                        projectUploadObject,
                        ObjectStatusTypes.UPLOADED,
                        100
                    );
                    dispatch(setProjectObjectUploadingOrPublishing(updatedProjectUploadObject));
                }
            })
            .catch(() => {
                if (uploadAbortControllerRef.current !== data.abortController) {
                    // This means that a possibly old and/or canceled upload has finished.
                    return;
                }

                setUploading(null);
                setProgress(0);
            })
            .finally(() => {
                props.handleClose();
            });
    };

    return (
        <GenericDialogShell
            maxWidth="sm"
            title={!uploading ? 'Mesh Upload In Progress' : `Mesh Upload In Progress: ${uploading.file_name}`}
            onCancel={() => {
                uploadAbortControllerRef.current?.abort('Operation canceled by user.');
                setUploading(null);
            }}
            dataCy={uploadMeshDialogDataCy}
            handleClose={props.handleClose}
            noSubmit
        >
            <LinearProgress
                variant={progress === false ? 'indeterminate' : 'determinate'}
                value={Number(progress)}
                style={{ margin: '30px 0 30px' }}
            />
        </GenericDialogShell>
    );
}
