import { ElementClass, PointsViewState, UpdateSnapshot, ViewClass } from '@seequent/xyz';
import { UseXyz } from 'App/MainApp/Visualization/context/hooks/UseXyzType';
import { addNewXyzTrace } from 'App/Redux/features/Xyz/xyzTracesSlice';
import { AppDispatch } from 'App/Redux/store';
import { objectMap } from 'Common/utils/ObjectUtils';
import { Point3D } from 'Common/types/geometryTypes';
import { hexToRgbArray } from 'App/MainApp/Plot/util';
import { getNextMeshColor } from 'App/MainApp/Plot/Colors_Util/colors_helper';
import { XyzTraceSnapshotWithColorAttributes, XyzTraceWithColorAttributes } from './XyzTraceWithColorAttributes';
import { InValidateAllColorValueSummaries, XyzTraceClassNames } from './XyzTrace';
import { DEFAULT_XYZ_OPACITY, DEFAULT_XYZ_POINT_SIZE } from './utils/defaults';
import { XyzColorTrace, XyzColorTraceSnapshot } from './XyzColorTrace';

export interface DrillTraceSnapshot extends XyzTraceSnapshotWithColorAttributes {
    /**
     * Snapshot is saved in Redux state. Must not duplicate XYZ state that is already stored in XYZ.
     * I mean when a plot is saved into XYZ, then basically all the currrent state of the plot is saved into XYZ.
     * Therefore there is a blurry line between what should and should not be saved in Redux through Snapshot.
     * We mainly want to store data that can help us easily extract all required information from XYZ state.
     */
    readonly className: XyzTraceClassNames.DrillTrace;

    readonly colorAttributeTraces: {
        [colorAttribute: string]: XyzColorTraceSnapshot;
    };

    readonly selectedColorAttribute: string;

    readonly verticesUrl: string;
}

export class DrillTrace extends XyzTraceWithColorAttributes implements DrillTraceSnapshot {
    readonly className = XyzTraceClassNames.DrillTrace;

    readonly colorAttributeTraces: {
        [colorAttribute: string]: XyzColorTrace;
    };

    readonly selectedColorAttribute: string;

    readonly verticesUrl: string;

    constructor(xyz: UseXyz, snapshot: DrillTraceSnapshot, tokenProvider: () => Promise<string>) {
        if (snapshot.className !== XyzTraceClassNames.DrillTrace) {
            throw `An incorrect spanshot is being used to create this instance of DrillTrace: ${snapshot}`;
        }
        if (!snapshot.id) {
            throw 'Id must be provided.';
        }
        super(xyz, snapshot, tokenProvider);

        this.colorAttributeTraces = {};

        Object.keys(snapshot.colorAttributeTraces).forEach((colorAttribute) => {
            this.colorAttributeTraces[colorAttribute] = new XyzColorTrace(
                snapshot.colorAttributeTraces[colorAttribute],
                this,
                xyz
            );
        });
    }

    private createInitialXyzEntities = (size: number, opacity: number): UpdateSnapshot => {
        const elementEntitySnapshot = this.createInitialElementEntitySnapshot();
        const viewEntitySnapshot = this.createInitialViewEntitySnapshot(size, opacity);
        const colorEntitiesSnapshot = this.selectedColorAttributeTrace().createInitialColorDataSnapshot();
        return {
            ...colorEntitiesSnapshot,
            ...viewEntitySnapshot,
            ...elementEntitySnapshot,
        };
    };

    createInitialElementEntitySnapshot = (): UpdateSnapshot => ({
        [this.elementEntityId()]: {
            verticesUrl: this.urlWithObjectHash(this.verticesUrl),
            __class__: ElementClass.Points,
        },
    });

    createInitialViewEntitySnapshot = (size = DEFAULT_XYZ_POINT_SIZE, opacity = DEFAULT_XYZ_OPACITY) => {
        if (this.selectedColorAttributeTrace().useSingleColor) {
            const viewEntitySnapshot: UpdateSnapshot = {
                [this.viewEntityId()]: {
                    // color: getNextMeshColor(),
                    element: this.elementEntityId(),
                    size: size,
                    opacity: opacity,
                    visible: this.isVisible(),
                    __class__: ViewClass.Points,
                },
            };
            const state = this.xyz.getState();
            const pointsViewEntity = state[this.viewEntityId()] as PointsViewState;
            if (!pointsViewEntity?.color) {
                // Set color if it wasn't set before
                const newPointsViewEntity = viewEntitySnapshot[this.viewEntityId()] as PointsViewState;
                newPointsViewEntity.color = hexToRgbArray(getNextMeshColor());
            }
            return {
                ...viewEntitySnapshot,
            };
        } else {
            const viewEntitySnapshot: UpdateSnapshot = {
                [this.viewEntityId()]: {
                    element: this.elementEntityId(),
                    color_data: this.selectedColorAttributeTrace().colorDataEntityId(),
                    size: size,
                    opacity: opacity,
                    visible: this.isVisible(),
                    __class__: ViewClass.Points,
                },
            };
            return {
                ...viewEntitySnapshot,
            };
        }
    };

    snapshotPath = (): string[] => [this.id];

    getDrillAttributes = () => Object.keys(this.colorAttributeTraces);

    entityIdPrefix = (): string => this.id;

    updateSelectedAttribute = async (dispatch: AppDispatch, colorAttribute: string, enabled = true) => {
        const newDrillTrace = new DrillTrace(
            this.xyz,
            {
                ...this.takeSnapshot(),
                selectedColorAttribute: colorAttribute,
            },
            this.tokenProvider
        ); // We need access to the new DrillTraceSnapshot right now. That's why we can't use this.updateReduxState() because it doesn't return the new snapshot.
        await dispatch(newDrillTrace.plotAndSave(this.getPointSize(), this.getOpacity(), enabled));
        if (this.selectedColorAttribute !== colorAttribute) {
            await this.addOrRemoveElementFromPlotView(false); // Remove this object from plotTrace only after the new trace is displayed so that the camera is not disabled.
        }
        return newDrillTrace;
    };

    updateOpacity = async (opacity: number) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                opacity: opacity,
            },
        });
    };

    updateColor = async (color: Point3D) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                color: color,
            },
        });
    };

    plotAndSave = (
        size?: number,
        opacity?: number,
        enabled = true,
        inValidateAllColorValueSummaries = InValidateAllColorValueSummaries.No
    ) => {
        const plotAndSaveThunk = async (dispatch: AppDispatch) => {
            const initialXyzEntities = this.createInitialXyzEntities(size, opacity);
            await this.xyz.updateVisualizationWithoutTween(initialXyzEntities);

            const drillTraceSnapshot = this.takeSnapshot();
            await dispatch(addNewXyzTrace(drillTraceSnapshot));

            await this.setEnabled(dispatch, enabled);

            await dispatch(
                this.selectedColorAttributeTrace().downloadColorArrayUrlAndSetColorArray(
                    inValidateAllColorValueSummaries,
                    this.tokenProvider
                )
            );
            await dispatch(this.resetViewVisibilityBasedOnLatestVisibility(this.tokenProvider));
        };
        return plotAndSaveThunk;
    };

    getName = () => this.selectedColorAttribute;

    showEmptyColorMapLegend = () => !this.hasColorMap();

    takeSnapshot = (): DrillTraceSnapshot => {
        const colorAttributeTraces = objectMap(this.colorAttributeTraces, (colorAttributeTrace) =>
            colorAttributeTrace.takeSnapshot()
        );

        const superSnapshot = super.takeSnapshot();

        return {
            ...superSnapshot,
            className: this.className,
            selectedColorAttribute: this.selectedColorAttribute,
            colorAttributeTraces: colorAttributeTraces,
            verticesUrl: this.verticesUrl,
        };
    };

    elementEntityId = () => `${this.entityIdPrefix()}:ElementPoints`;

    viewEntityId = (): string => `${this.selectedColorAttributeTrace().entityIdPrefix()}:ViewPoints`;

    viewEntity = (): PointsViewState => this.xyz.getEntityState(this.viewEntityId()) as PointsViewState;

    getPointSize = () => this.viewEntity()?.size;

    updatePointSize = async (pointSize: number) => {
        await this.xyz.updateVisualizationWithoutTween({
            [this.viewEntityId()]: {
                size: pointSize,
                __class__: ViewClass.Points,
            },
        });
    };

    updateObjectHash = (
        objectHash: string,
        enabled: boolean,
        inValidateAllColorValueSummaries: InValidateAllColorValueSummaries
    ) => {
        const updateObjectHashThunk = async (dispatch: AppDispatch) => {
            const newDrillTrace = new DrillTrace(
                this.xyz,
                {
                    ...this.takeSnapshot(),
                    objectHash: objectHash,
                },
                this.tokenProvider
            ); // We need access to the new TraceSnapshot right now. That's why we can't use this.updateReduxState() because it doesn't return the new snapshot.
            await dispatch(
                newDrillTrace.plotAndSave(
                    this.getPointSize(),
                    this.getOpacity(),
                    enabled,
                    inValidateAllColorValueSummaries
                )
            );
        };
        return updateObjectHashThunk;
    };
}
