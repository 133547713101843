import { findParentNode } from 'App/MainApp/TreeView/treeData/treeDataUtils';
import { ProjectTreeObjectNode, Subsections } from 'App/util/ProjectDataTypes/MainTreeDataTypes';
import { ObjectIDType } from 'App/util/ProjectDataTypes/ProjectObjectsDataTypes';
import { produce } from 'immer';
import React from 'react';
import { sortProjectTreeNodesByCreatedAt } from './ProjectTreeNode_util';
import { useAppSelector } from 'App/Redux/hooks';
import { selectObjectClassname2Id2Obj } from 'App/Redux/features/globalContext/currentProjectSlice';

export function useMultiselect(tree: Subsections) {
    const [selectedNodesMap, setSelectedNodesMap] = React.useState<{ [nodeId: string]: ProjectTreeObjectNode }>({});
    const [lastSelectedNode, setLastSelectedNode] = React.useState(null);

    const allProjectObjects = useAppSelector(selectObjectClassname2Id2Obj);

    const setNodeSelection = (node: ProjectTreeObjectNode, isSelected: boolean, unselectOthers = false) => {
        const newNodesMap = produce(selectedNodesMap, (oldNodesMap) => {
            let newNodesMap = {};

            if (!unselectOthers) {
                newNodesMap = oldNodesMap;
            }

            if (isSelected) {
                newNodesMap[node.nodeId] = node;
            } else {
                delete newNodesMap[node.nodeId];
            }

            return newNodesMap;
        });

        setSelectedNodesMap(newNodesMap);

        if (isSelected) {
            setLastSelectedNode(node);
        } else {
            setLastSelectedNode(null);
        }
    };

    const selectMultipleNodes = (nodes: ProjectTreeObjectNode[]) => {
        const newNodesMap = {};
        nodes.forEach((node) => {
            newNodesMap[node.nodeId] = node;
        });
        setSelectedNodesMap(newNodesMap);
    };

    // to keep it simple but still useful, consecutive node selection will only work under same parent
    const selectConsecutiveNodes = (node: ProjectTreeObjectNode) => {
        if (lastSelectedNode) {
            if (node.nodeId === lastSelectedNode.nodeId) {
                // do nothing
            } else {
                const parentNode = findParentNode(tree, node);
                const siblings = Object.values(parentNode.subsections).sort((treeNode1, treeNode2) =>
                    sortProjectTreeNodesByCreatedAt(
                        treeNode1,
                        allProjectObjects?.[(treeNode1 as ProjectTreeObjectNode)?.className]?.[
                            (treeNode1 as ProjectTreeObjectNode)?.id
                        ],
                        treeNode2,
                        allProjectObjects?.[(treeNode2 as ProjectTreeObjectNode)?.className]?.[
                            (treeNode2 as ProjectTreeObjectNode)?.id
                        ]
                    )
                ); // assuming it's the same order as rendered in the tree ui

                const nodeIndex = siblings.indexOf(node);
                const lastSelectedNodeIndex = siblings.indexOf(lastSelectedNode);

                if (lastSelectedNodeIndex === -1) {
                    // last selected node is in another subtree, so we can just regular-select
                    selectNode(node);
                } else {
                    let nodesToSelect = [];
                    if (nodeIndex > lastSelectedNodeIndex) {
                        nodesToSelect = siblings.slice(lastSelectedNodeIndex, nodeIndex + 1);
                    } else {
                        nodesToSelect = siblings.slice(nodeIndex, lastSelectedNodeIndex + 1);
                    }

                    selectMultipleNodes(nodesToSelect);
                }
            }
        } else {
            selectNode(node);
        }
    };

    const toggleNodeSelect = (node: ProjectTreeObjectNode) => {
        const previousState = !!selectedNodesMap[node.nodeId];
        setNodeSelection(node, !previousState);
    };

    const selectNode = (node: ProjectTreeObjectNode) => {
        setNodeSelection(node, true);
    };

    const selectOnlyNode = (node: ProjectTreeObjectNode) => {
        setNodeSelection(node, true, true);
    };

    const unselectNode = (node: ProjectTreeObjectNode) => {
        setNodeSelection(node, false);
    };

    const unselectAll = () => {
        setSelectedNodesMap({});
    };

    const isSelectedNode = (nodeId: ObjectIDType) => {
        return !!selectedNodesMap[nodeId];
    };

    const selectedNodes = React.useMemo(() => {
        return Object.values(selectedNodesMap);
    }, [selectedNodesMap]);

    return {
        selectNode,
        unselectNode,
        unselectAll,
        isSelectedNode,
        selectedNodes,
        toggleNodeSelect,
        selectOnlyNode,
        selectConsecutiveNodes,
    };
}
