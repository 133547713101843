import Logger from 'js-logger';
import { handleTelemetryLogs } from './TelemetryLogs';

global.Logger = Logger; // type-coverage:ignore-line

const consoleHandler = Logger.createDefaultHandler();

Logger.setHandler((messages, context) => {
    switch (context.name) {
        case 'telemetryLogs':
            handleTelemetryLogs(messages, context);
            consoleHandler(messages, context);
            break;
        default:
            consoleHandler(messages, context);
            break;
    }
});
