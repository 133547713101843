import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function InfoTooltip(props: { text: string }) {
    return (
        <Tooltip title={props.text} disableInteractive>
            <IconButton disableTouchRipple size="small">
                <InfoOutlinedIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
}
