import { string2bool } from 'App/util/core';

export class AppConfig {
    /** Base URL for all (authorized) IDP requests */
    readonly idpBaseUrl: string;

    /** URL used for the user to input their credentials and authorize the application */
    readonly idpAuthzUrl: string;

    readonly idpClientId: string;

    /** URL the IDP will redirect to after the user logs in or logs out */
    readonly idpRedirectUri: string;

    /** Extra idp scopes */
    readonly idpExtraScopes: string[];

    /** Base URL for (authorized) EVO reqeusts */
    readonly evoBaseUrl: string = process.env.NEXT_PUBLIC_EVO_BASE_URL;

    readonly evoServiceCodes: string[] = (process.env.NEXT_PUBLIC_EVO_SERVICE_CODE ?? '').split(',');

    readonly defaultAngleConvention: string = process.env.NEXT_PUBLIC_DEFAULT_ANGLE_CONVENTION;

    readonly forceBackendUrl: string | undefined;

    readonly loginPageUrl = '/';

    readonly insecureAllowAdminOperations = string2bool(process.env.NEXT_PUBLIC_INSECURE_ALLOW_ADMIN_OPERATIONS);

    readonly launchDarklyClientID = process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID;

    constructor() {
        if (typeof window === 'undefined') {
            this.forceBackendUrl = undefined;
        } else {
            const origin2backendUrlsStr = process.env.NEXT_PUBLIC_FORCE_ORIGIN_2_BACKEND_URL;
            const origin2backendUrl: { [origin: string]: string } = origin2backendUrlsStr
                ? Object.fromEntries(
                      origin2backendUrlsStr.split(',').map((origin2backendUrlStr) => origin2backendUrlStr.split(';'))
                  )
                : {};

            this.forceBackendUrl = origin2backendUrl[window.location?.origin];
        }

        this.idpClientId = process.env.NEXT_PUBLIC_BENTLEY_IDP_CLIENT_ID;
        this.idpBaseUrl = process.env.NEXT_PUBLIC_BENTLEY_IDP_BASE_URL;

        this.idpAuthzUrl =
            process.env.NEXT_PUBLIC_IDP_AUTHZ_URL ||
            `${process.env.NEXT_PUBLIC_BENTLEY_IDP_BASE_URL}/connect/authorize`;

        this.idpExtraScopes = [
            'evo.discovery',
            'evo.file',
            'evo.object',
            'evo.users:read',
            'evo.workspace',
            'offline_access',
        ];

        const redirectUris = process.env.NEXT_PUBLIC_IDP_REDIRECT_URI?.split(',') ?? [];
        if (redirectUris.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.idpRedirectUri = redirectUris[0];
        } else {
            this.idpRedirectUri =
                redirectUris.find((uri) => typeof window !== 'undefined' && uri.includes(window.location.origin)) ??
                redirectUris[0];
        }
    }
}

export const appConfig = new AppConfig();
