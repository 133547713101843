import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { interpolationIconDataCy } from 'Common/testUtils/genericTestUtils/dataCyConsts';
import * as React from 'react';

export function BlockModelIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} data-cy={interpolationIconDataCy}>
            <svg version="1.1" viewBox="0 0 24 24" width="24px" height="24px">
                <g>
                    <polygon
                        className="st2"
                        fill="#9CB7C7"
                        points="12,6.31 18.02,9.44 18.02,14.86 12.02,17.75 6.02,14.83 6.02,9.26 	"
                    />
                    <polygon
                        className="st3"
                        fill="#D1DFE7"
                        points="17.4,3.36 23.48,6.31 23.48,12.17 18.02,14.86 18.02,9.44 12,6.31 	"
                    />
                    <polygon
                        className="st3"
                        fill="#D1DFE7"
                        points="18.02,14.86 18.02,20.33 12,23.33 6.02,20.25 6.02,14.83 12.02,17.75 	"
                    />
                    <polygon
                        className="st3"
                        fill="#D1DFE7"
                        points="6.53,3.36 12,6.31 6.02,9.26 6.02,14.83 0.55,12 0.55,6.31 	"
                    />
                    <polygon className="st2" fill="#9CB7C7" points="18.02,14.86 23.44,12 23.44,17.75 18.02,20.33 	" />
                    <polygon className="st2" fill="#9CB7C7" points="12,0.55 17.4,3.36 12,6.31 6.53,3.36 	" />
                    <polygon className="st2" fill="#9CB7C7" points="0.55,12 6.02,14.83 6.02,20.25 0.44,18 	" />
                </g>
                <path
                    className="st4"
                    fill="#265C7F"
                    d="M23.99,6.42C24.02,6.21,23.92,6,23.73,5.9l-11.5-5.85c-0.01-0.01-0.03-0.01-0.04-0.01
	c-0.03-0.01-0.06-0.02-0.09-0.03C12.06,0.01,12.03,0,12,0c-0.03,0-0.06,0.01-0.09,0.01c-0.03,0.01-0.07,0.01-0.1,0.03
	c-0.01,0.01-0.03,0-0.04,0.01L0.27,5.9C0.08,6-0.02,6.21,0.01,6.41C0.01,6.43,0,6.44,0,6.45v11.18c0,0.19,0.11,0.36,0.27,0.45
	l11.44,5.82c0.01,0.01,0.01,0.02,0.02,0.03C11.82,23.97,11.91,24,12,24c0.08,0,0.16-0.02,0.23-0.05l11.5-5.85
	c0.17-0.09,0.27-0.26,0.27-0.45V6.47C24,6.45,23.99,6.44,23.99,6.42z M12,1.06l4.52,2.3l-4.53,2.3l-4.52-2.3L12,1.06z M12.02,11.74
	l-4.9-2.49l4.87-2.48l4.89,2.49L12.02,11.74z M17.99,8.71L13.1,6.22l4.53-2.3l4.89,2.49L17.99,8.71z M10.89,6.22L6.02,8.69L1.5,6.4
	l4.87-2.48L10.89,6.22z M1,7.27l4.53,2.3v4.44L1,11.71V7.27z M6.53,10.08l4.96,2.52v4.44l-4.96-2.52V10.08z M11.5,18.17v4.5
	l-4.96-2.52v-4.5L11.5,18.17z M12.5,12.62l5.06-2.57v4.48L12.5,17.1V12.62z M18.57,9.53L23,7.28v4.48l-4.43,2.25V9.53z M1,12.83
	l4.53,2.3v4.5L1,17.33V12.83z M12.5,18.22l5.06-2.57v4.46l-5.06,2.57V18.22z M18.57,19.6v-4.46L23,12.88v4.46L18.57,19.6z"
                />
                <path
                    className="st4"
                    fill="#265C7F"
                    d="M23.99,6.42C24.02,6.21,23.92,6,23.73,5.9l-11.5-5.85c-0.01-0.01-0.03-0.01-0.04-0.01
	c-0.03-0.01-0.06-0.02-0.09-0.03C12.06,0.01,12.03,0,12,0c-0.03,0-0.06,0.01-0.09,0.01c-0.03,0.01-0.07,0.01-0.1,0.03
	c-0.01,0.01-0.03,0-0.04,0.01L0.27,5.9C0.08,6-0.02,6.21,0.01,6.41C0.01,6.43,0,6.44,0,6.45v11.18c0,0.19,0.11,0.36,0.27,0.45
	l11.44,5.82c0.01,0.01,0.01,0.02,0.02,0.03C11.82,23.97,11.91,24,12,24c0.08,0,0.16-0.02,0.23-0.05l11.5-5.85
	c0.17-0.09,0.27-0.26,0.27-0.45V6.47C24,6.45,23.99,6.44,23.99,6.42z M12,1.06l4.52,2.3l-4.53,2.3l-4.52-2.3L12,1.06z M12.02,11.74
	l-4.9-2.49l4.87-2.48l4.89,2.49L12.02,11.74z M17.99,8.71L13.1,6.22l4.53-2.3l4.89,2.49L17.99,8.71z M10.89,6.22L6.02,8.69L1.5,6.4
	l4.87-2.48L10.89,6.22z M1,7.27l4.53,2.3v4.44L1,11.71V7.27z M6.53,10.08l4.96,2.52v4.44l-4.96-2.52V10.08z M11.5,18.17v4.5
	l-4.96-2.52v-4.5L11.5,18.17z M12.5,12.62l5.06-2.57v4.48L12.5,17.1V12.62z M18.57,9.53L23,7.28v4.48l-4.43,2.25V9.53z M1,12.83
	l4.53,2.3v4.5L1,17.33V12.83z M12.5,18.22l5.06-2.57v4.46l-5.06,2.57V18.22z M18.57,19.6v-4.46L23,12.88v4.46L18.57,19.6z"
                />
            </svg>
        </SvgIcon>
    );
}

export default BlockModelIcon;
