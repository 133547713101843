import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { DataContext } from 'App/DataContext';
import { useDialogOpener } from 'App/MainApp/Dialogs/Shared/useDialogOpenerHook';
import UploadMeshDialog from 'App/MainApp/Dialogs/Uploads/UploadMesh/UploadMesh';
import { MeshTypes } from 'App/util/ProjectDataTypes/MeshFile';
import {
    uploadClosedMeshFromLocalDataCy,
    uploadOpenMeshFromLocalDataCy,
} from 'Common/testUtils/genericTestUtils/dataCyConsts';
import React from 'react';
import MenuItemIcon from '../../../MenuItemIcon';

const uploadClosedMeshTooltip =
    'Closed meshes are recalculated into the 3D grid and can be used for domain definition.';

export function UploadMeshFromLocalButton(props: {
    handleMenuClose(): void;
    isClosedMesh: boolean;
    menuItemName: string;
}) {
    const { openDialog } = useDialogOpener();
    const { menuItemClass, uploadFiles } = React.useContext(DataContext);

    const onFileChange = (target: HTMLInputElement) => {
        const { files } = target;
        openDialog({
            dialogComponent: UploadMeshDialog,
            dialogProps: {
                files: Array.from(files || []),
                meshType: props.isClosedMesh ? MeshTypes.closed : MeshTypes.open,
            },
        });
    };

    const onClick = () => {
        props.handleMenuClose();
        uploadFiles({ accept: '.dxf, .obj', onChange: onFileChange, allowMultipleFiles: true });
    };

    return (
        <Tooltip disableInteractive title={props.isClosedMesh ? uploadClosedMeshTooltip : ''} placement="right">
            <div>
                <MenuItem
                    className={menuItemClass.menuItem}
                    data-cy={props.isClosedMesh ? uploadClosedMeshFromLocalDataCy : uploadOpenMeshFromLocalDataCy}
                    onClick={onClick}
                >
                    <MenuItemIcon name={props.menuItemName} />
                    {props.menuItemName}
                </MenuItem>
            </div>
        </Tooltip>
    );
}
